import React from 'react';

const SvgChat = props => (
  <svg width="1em" height="1em" viewBox="0 0 32 29" {...props}>
    <path
      d="M1.5 26.02V7A4.5 4.5 0 016 2.5h19A4.5 4.5 0 0129.5 7v9.97a4.5 4.5 0 01-4.5 4.5H7.763L1.5 26.02z"
      stroke="#1b1b1b"
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgChat;
