import React, { useState } from 'react';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';

import ProfilePlaceholder from 'assets/new/profileImagePlaceholder';

import { Text } from 'components';

import '../patientOverview.scss';

const PatientOverview = ({ patient, fetchingPatient }) => {
  const [isToggled, setIsToggled] = useState(false);

  return (
    <div className="order-patient-overview__details-wrapper">
      {!patient || fetchingPatient ? (
        <div className="order-patient-overview__details-wrapper__loader-wrapper">
          <Loader
            active
            className="order-patient-overview__details-wrapper__loader-wrapper__loader"
            size="small"
          />
        </div>
      ) : (
        <>
          <div className="order-patient-overview__mobile-overview">
            {patient.profileUrl ? (
              <div className="order-patient-overview__patient-photo">
                <img src={patient.profileUrl} alt="patient" />
              </div>
            ) : (
              <ProfilePlaceholder className="order-patient-overview__patient-photo" />
            )}
            <div className="ml-4">
              <Text size="big" bold className="mb-1">
                {patient.fullName}
              </Text>
              {patient.dateOfBirth && (
                <Text size="tiny" className="mb-1">
                  {moment(patient.dateOfBirth, 'YYYY-MM-DD').format(
                    'MMMM DD, YYYY'
                  )}{' '}
                  ({moment().diff(patient.dateOfBirth, 'years')} years old)
                </Text>
              )}
              {patient.friendlyId && (
                <Text color="blue" size="tiny">
                  {patient.friendlyId}
                </Text>
              )}
            </div>
          </div>
          {/* TODO: Implement a toggle that shows all patient data */}
          {isToggled && (
            <div className="order-patient-overview__details-wrapper__row">
              {patient.ssn && (
                <div className="order-patient-overview__detail">
                  <Text
                    className="order-patient-overview__detail__header"
                    color="grey"
                    size="small"
                    dark
                    id="react_single_overview.nric_fin"
                  >
                    NRIC/FIN
                  </Text>
                  <Text className="order-patient-overview__detail__data">
                    {patient.ssn}
                  </Text>
                </div>
              )}

              {patient.passNo && (
                <div className="order-patient-overview__detail">
                  <Text
                    className="order-patient-overview__detail__header"
                    color="grey"
                    size="small"
                    dark
                    id="add_patient.passport_no"
                  >
                    Passport No.
                  </Text>
                  <Text className="order-patient-overview__detail__data">
                    {patient.passNo}
                  </Text>
                </div>
              )}

              <div className="order-patient-overview__detail">
                <Text
                  className="order-patient-overview__detail__header"
                  color="grey"
                  size="small"
                  dark
                  id="add_patient.gender"
                >
                  Gender
                </Text>
                <Text
                  className="order-patient-overview__detail__data"
                  uppercase
                >
                  {patient.gender}
                </Text>
              </div>

              {patient.nationality && (
                <div className="order-patient-overview__detail">
                  <Text
                    className="order-patient-overview__detail__header"
                    color="grey"
                    size="small"
                    dark
                    id="add_patient.nationality"
                  >
                    Nationality
                  </Text>
                  <Text className="order-patient-overview__detail__data">
                    {patient.nationality}
                  </Text>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PatientOverview;
