import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { Button, Select, ErrorToast } from 'components';
import { useStaff } from 'hooks';

import DropdownOption from '../dropdownOption/DropdownOption';

import './assignStaffModal.scss';

const AssignStaffModal = ({
  show,
  hide,
  onAssign,
  isSaving,
  header = 'Assign Event To',
  staffId,
}) => {
  const [selectedStaff, setStaff] = useState(null);
  const [staffOptions, setStaffOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const staff = useStaff({ clientId: localStorage.getItem('DELTA_CLIENT_ID') });

  useEffect(() => {
    if (!Array.isArray(staff)) return setStaffOptions([]);
    setIsLoading(false);
    const result = staff.map(({ id, name, email, type }) => {
      const val = {
        value: id,
        text: name,
        type,
        content: <DropdownOption text={name} role={email} />,
      };
      if (staffId === id) setStaff(staffId);
      return val;
    });
    setStaffOptions(result);
  }, [staff]);

  const onAssignClick = (staffId) => {
    if (!staffId)
      return toast.error(<ErrorToast message="No person selected" />);
    const staffData = staffOptions.find((option) => option.value === staffId);
    const staff = {
      id: staffData?.value,
      fullName: staffData?.text,
      type: staffData?.type,
    };
    // 2nd argument is full staff data, some callbacks might need it
    onAssign(staffId, staff);
  };

  return (
    <>
      <Modal
        closeIcon
        open={show}
        onClose={() => hide(false)}
        size="tiny"
        className="assignswab-modal__modal"
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          <Select
            size="fullWidth"
            border
            search
            icon={false}
            placeholder="Select staff"
            options={staffOptions}
            value={selectedStaff}
            onChange={(e, { value }) => setStaff(value)}
            //   invalid={errors.template}
          />
          <Button
            color="blue"
            size="fullWidth"
            className="mt-4"
            onClick={() => onAssignClick(selectedStaff)}
            isLoading={isLoading || isSaving}
            disabled={isSaving}
            textId="react_main.assign"
          >
            Assign
          </Button>
        </Modal.Content>
      </Modal>
    </>
  );
};
export default AssignStaffModal;
