import React from 'react';

const SvgCertificate = props => (
  <svg width="1em" height="1em" viewBox="0 0 37 27" {...props}>
    <g fill="none" fillRule="evenodd" stroke={props.color} strokeWidth={3}>
      <g transform="translate(21 6)">
        <circle cx={4.5} cy={4.5} r={4.5} />
        <path d="M2 9v5l2.5-.976L7 14V9" />
      </g>
      <rect x={1.5} y={1.5} width={34} height={24} rx={2} />
      <path d="M7 11.5h7.67M7 16h5.77" strokeLinecap="round" />
    </g>
  </svg>
);

SvgCertificate.defaultProps = {
  color: '#1b1b1b',
};

export default SvgCertificate;
