import React from 'react';

const SvgEye = props => (
  <svg width="1em" height="1em" viewBox="0 0 22 12" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M11 11c3.314 0 6.314-1.667 9-5-2.686-3.333-5.686-5-9-5S4.686 2.667 2 6c2.686 3.333 5.686 5 9 5z"
        stroke="#1b1b1b"
        strokeWidth={2}
      />
      <circle fill="#1b1b1b" cx={11} cy={6} r={3} />
    </g>
  </svg>
);

export default SvgEye;
