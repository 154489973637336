import React from 'react';
import { Modal } from 'semantic-ui-react';
import startCompleteProfileSvg from 'assets/rhc/completeProfile/start-profile-change.svg';
import PlaceholderPage from 'features/patientApp/PlaceholderCard/PlaceholderCard';
import { Text } from 'components';

import './encouragementModal.scss';

const EncouragementModal = ({
  isToggled,
  setToggled,
  icon,
  header,
  headerSubText,
  text,
  buttonText,
  onButtonClick,
}) => {
  return (
    <>
      <Modal
        dimmer="inverted"
        open={isToggled}
        onClose={() => setToggled(false)}
        size="mini"
      >
        <Modal.Header>
          <Text type="header" className="mt-3">
            {header}
          </Text>
          <Text size="medium">{headerSubText}</Text>
        </Modal.Header>
        <div className="encouragement">
          <PlaceholderPage
            icon={icon}
            text={text}
            buttonText={buttonText}
            onButtonClick={onButtonClick}
          />
        </div>
      </Modal>
    </>
  );
};
export default EncouragementModal;
