export const SET_TEST_ORDER = 'TEST_ORDER/SET_TEST_ORDER';
export const SET_TEST_PROFILE_OPTIONS = 'TEST_ORDER/SET_TEST_PROFILE_OPTIONS';
export const SET_TEST_PANEL = 'TEST_ORDER/SET_TEST_PANEL';
export const SET_TEST_ORDER_PANEL = 'TEST_ORDER/SET_TEST_ORDER_PANEL';
export const ADD_TEST_ORDER_PANEL = 'TEST_ORDER/ADD_TEST_ORDER_PANEL';
export const REMOVE_TEST_ORDER_PANEL = 'TEST_ORDER/REMOVE_TEST_ORDER_PANEL';
export const CLEAR_TEST_ORDER = 'TEST_ORDER/CLEAR_TEST_ORDER';
export const SET_TEST_ORDER_STEP = 'TEST_ORDER/SET_TEST_ORDER_STEP';
export const SET_TEST_ORDER_PROGRESS = 'TEST_ORDER/SET_TEST_ORDER_PROGRESS';
export const RESET_TEST_ORDER = 'TEST_ORDER/RESET_TEST_ORDER';

export const setTestOrder = (testOrder) => ({
  type: SET_TEST_ORDER,
  payload: testOrder,
});

export const setTestProfileOptions = (testProfileOptions) => ({
  type: SET_TEST_PROFILE_OPTIONS,
  payload: testProfileOptions,
});

export const setTestOrderPanel = (id, testOrderPanel) => ({
  type: SET_TEST_ORDER_PANEL,
  payload: { id, testOrderPanel },
});

export const setTestPanel = (index, testPanel) => ({
  type: SET_TEST_PANEL,
  payload: { index, testPanel },
});

export const addTestOrderPanel = (testOrderPanel) => ({
  type: ADD_TEST_ORDER_PANEL,
  payload: { testOrderPanel },
});

export const removeTestOrderPanel = (id) => ({
  type: REMOVE_TEST_ORDER_PANEL,
  payload: { id },
});

export const clearTestOrder = () => ({
  type: CLEAR_TEST_ORDER,
  payload: {},
});

export const setTestOrderStep = (step) => ({
  type: SET_TEST_ORDER_STEP,
  payload: { step },
});

export const setTestOrderProgress = (progress) => ({
  type: SET_TEST_ORDER_PROGRESS,
  payload: { progress },
});

export const resetTestOrderState = () => ({
  type: RESET_TEST_ORDER,
});
