// https://ned.dev.demo.api.riverr.ai/v1/react-session
// import axios from './testAxios';
import axios from './axiosInstance';

const methods = {
  async fetchReactSessionById(id) {
    return axios.get(`react-session/${id}`);
  },
  async fetchReactSessions(data) {
    return axios.get('react-session', { params: data });
  },
  async createReactSession(data) {
    return axios.post('react-session', { ...data });
  },
  async bulkCreateReactSession(data) {
    return axios.post('react-session/bulk', { ...data });
  },
  async updateReactSession(id, data) {
    return axios.put(`react-session/${id}`, { ...data });
  },
  async assignStaff(id, staff) {
    return axios.put(`react-session/assign-staff/${id}`, { staff });
  },
  async fetchBulkSignedPdfs(reactSessionIds) {
    return axios.put('react-session/bulk-signed-react', { reactSessionIds });
  },
  async updateBulkSessionsAsReviewed(reactSessionIds) {
    return axios.get('react-session/bulk-reviewed', {
      params: { reactSessionIds },
    });
  },
  async platoReactSession(token) {
    return axios.post('plato/react-session', { token });
  },
};

export default methods;
