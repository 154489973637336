import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

import { ResultPdfUploadModal } from 'features';
import uploadSvg from 'assets/new/upload.svg';

import { Text, Button, ErrorToast } from 'components';
import { LabResultsTableFilter } from 'features'

import Placeholder from './Placeholder';
import AdditionalReportsTable from './AdditionalReports_Table';
import resultApi from 'api/result/resultApi';
import patientReactSessionApi from 'api/patientReactSession';

import history from '../../../../../../../history';

import './additionalReports.scss';

const CreateButton = ({ isLoading, toggleModal, className }) => {
  return (
    <Button
      disabled={isLoading}
      size="fluid"
      className={`add-reps__button ${className}`}
      color="blue"
      onClick={() => toggleModal(true)}
    >
      <img src={uploadSvg} className="mr-3" />
      <Text id="react_report.upload_document" color="white" bold>
        {' '}
        Upload Document
      </Text>
    </Button>
  );
};

const PlatoButton = ({ setIsLoading, isLoading, onPlatoClick, className, fetchAdditionalReports }) => {
  return (
    <Button
      inverted
      disabled={isLoading}
      size="fluid"
      className={`add-reps__button ${className} ml-3`}
      color="blue"
      onClick={async () => {
        setIsLoading(true);
        await onPlatoClick();
        await fetchAdditionalReports(true);
        setIsLoading(false);
      }}
    >
      Get result from Plato
    </Button>
  );
};

const SgimedButton = ({
  setIsLoading,
  isLoading,
  onSgimedClick,
  className,
  fetchAdditionalReports,
}) => {
  return (
    <Button
      inverted
      disabled={isLoading}
      size="fluid"
      className={`add-reps__button ${className} ml-3`}
      color="blue"
      onClick={async () => {
        setIsLoading(true);
        await onSgimedClick();
        await fetchAdditionalReports(true);
        setIsLoading(false);
      }}
    >
      Get result from SGiMED
    </Button>
  );
};

const AdditionalReports = ({
  match,
  patientDetails,
  onFetchAdditionalCallback = null,
  onPlatoClick,
  onSgimedClick,
  onDelete = null,
}) => {
  const clientInfo = useSelector((state) => state.general.clientInformation);

  const initailActiveList =
    new URLSearchParams(history.location.search).get('add-t-filter') ||
    'result';

  const [isLoading, setIsLoading] = useState(true);
  const [isModalToggled, toggleModal] = useState(false);
  const [additionalResults, setAdditionalResults] = useState([]);
  const [filteredAdditionalResults, setFilteredAdditionalResults] = useState(
    []
  );
  const [refreshTable, setRefreshTable] = useState(false);
  const [activeList, setActiveList] = useState(initailActiveList);

  useEffect(async () => {
    setIsLoading(true);
    await fetchAdditionalReports();
    setIsLoading(false);
  }, [patientDetails, refreshTable]);

  useEffect(() => {
    const filtered = additionalResults?.filter((result) => {
      if (activeList === 'result') {
        return result?.type === activeList || !result?.type;
      }
      return result?.type === activeList;
    });
    setFilteredAdditionalResults(filtered);
  }, [activeList]);

  const fetchAdditionalReports = async (isAfterUpload) => {
    try {
      if (!patientDetails?.id) return;
      const pSession = await patientReactSessionApi.getPatientReactSessionById(
        match.params.id
      );
      const resultIds = pSession?.data?.resultIds.map(
        (resultId) => resultId?.accNo
      );
      const resultsResponseData = await resultApi.getResultsByIds(resultIds);
      if (resultsResponseData) {
        setAdditionalResults(resultsResponseData);
      }
      const filtered =
        resultsResponseData &&
        resultsResponseData?.filter((result) => {
          if (activeList === 'result') {
            return result?.type === activeList || !result?.type;
          }
          return result?.type === activeList;
        });
      setFilteredAdditionalResults(filtered);
      if (isAfterUpload && onFetchAdditionalCallback)
        await onFetchAdditionalCallback();
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  return (
    <div className="add-reps">
      {isModalToggled && (
        <ResultPdfUploadModal
          isModalToggled={isModalToggled}
          toggleModal={toggleModal}
          patientDetails={patientDetails}
          refreshParent={setRefreshTable}
          patientReactSessionId={match.params?.id}
          selectedTab={activeList}
          fetchResults={() => fetchAdditionalReports(true)}
        />
      )}
      <Loader
        inline="centered"
        size="small"
        className="add-reps__loader"
        active={isLoading}
      />
      {!isLoading && (
        <>
          {additionalResults?.length > 0 ? (
            <>
              <LabResultsTableFilter
                hasBioOverview={false}
                setActiveList={setActiveList}
                activeList={activeList}
                results={additionalResults}
              />
              <CreateButton
                isLoading={isLoading}
                toggleModal={toggleModal}
                className={'mb-4'}
              />
              {clientInfo.platoApiKey && (
                <PlatoButton
                  fetchAdditionalReports={fetchAdditionalReports}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  onPlatoClick={onPlatoClick}
                />
              )}
              {clientInfo?.sgimedApiKey && (
                <SgimedButton
                  fetchAdditionalReports={fetchAdditionalReports}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  onSgimedClick={onSgimedClick}
                />
              )}
              {activeList !== 'overview' && (
                <AdditionalReportsTable
                  activeList={activeList}
                  reports={filteredAdditionalResults}
                  fetchAdditionalReports={fetchAdditionalReports}
                  pSessionId={match.params.id}
                  onDelete={onDelete}
                />
              )}
            </>
          ) : (
            <>
              <Placeholder />
              <CreateButton
                isLoading={isLoading}
                toggleModal={toggleModal}
                className={'mt-4'}
              />
              {clientInfo.platoApiKey && (
                <PlatoButton
                  fetchAdditionalReports={fetchAdditionalReports}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  onPlatoClick={onPlatoClick}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default withRouter(AdditionalReports);
