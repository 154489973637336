import gql from 'graphql-tag';

export const getAllCMSUsers = gql`
  query admins {
    admins {
      id
      email
      name
      createdAt
      isActivated
      centerId
    }
  }
`;

export const getUsersByClientId = gql`
  query usersByClientId($clientId: ID!) {
    usersByClientId(clientId: $clientId) {
      id
      firstName
      middleName
      lastName
      email
      currentEmployer
    }
  }
`;

export const getUserByID = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      firstName
      middleName
      lastName
      email
      fromCountry
      birthday
      currentEmployer
      profileImage
      positionOfInterest
      phoneNumber
      verificationStatus {
        status
      }
      certificates {
        id
        type
        name
        issuedAt
        validUntil
        isVerified
        issuedBy
        issuedByDLM
        clientId
      }
    }
  }
`;

export const getAdmin = gql`
  query admin($id: ID!) {
    admin(id: $id) {
      id
      name
      email
      role
      centerId
      instructorId
    }
  }
`;

export const connectedUsers = gql`
  query getConnectedUsers(
    $clientId: clientId!
    $status: String
    $isActive: Boolean
  ) {
    getConnectedUsers(
      clientId: $clientId
      status: $status
      isActive: $isActive
    ) {
      id
      name
      email
      rank
      isActive
      isVerified
      nationality
      status
      verificationStatus {
        status
      }
    }
  }
`;

export const getUserConnection = gql`
  query getUserConnection($id: ID!) {
    getUserConnection(id: $id) {
      userId
      company
      companyId
      firstName
      lastName
      phoneNumber
      birthday
      email
      isActive
      isAccepted
      profileAccess
      certificateAccess
      revokedDate
    }
  }
`;

export const getUserByEmail = gql`
  query userByEmail($email: String!) {
    userByEmail(email: $email) {
      id
      firstName
      middleName
      lastName
      email
      fromCountry
      currentEmployer
      profileImage
      isVerified
      birthday
      passNo
      certificates {
        id
        name
        issuedAt
        validUntil
        isClaimed
      }
    }
  }
`;

export const searchUser = gql`
  query userByEmail($email: String!) {
    userByEmail(email: $email) {
      id
      firstName
      middleName
      lastName
      email
      fromCountry
      currentEmployer
      phoneNumber
      profileImage
      isVerified
      birthday
      passNo
      countryCode
      isFemale
      seamanBookNo
      isMarried
      address
      addressCity
      addressCountry
    }
  }
`;
