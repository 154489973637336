import gql from 'graphql-tag';

export const getLabResults = gql`
  query getLabResults(
    $clientId: ID
    $clinicId: ID
    $filter: String
    $cursor: String
    $search: String
    $result: String
    $fromDate: String
    $toDate: String
    $type: String
  ) {
    getLabResults(
      clientId: $clientId
      clinicId: $clinicId
      filter: $filter
      result: $result
      cursor: $cursor
      search: $search
      fromDate: $fromDate
      toDate: $toDate
      type: $type
    ) {
      id
      holderName
      docIssuedAt
      createdAt
      clinicName
      collectionDate
      labName
      subType
      result
      passNo
      resultType
      resultsFilePath
      secondResultsFilePath
      status
      ssn
      isAllData
      docId
    }
  }
`;

export const getIssuedLabResultsRange = gql`
  query getIssuedLabResultsRange(
    $clientId: ID!
    $startDate: String!
    $endDate: String!
  ) {
    getIssuedLabResultsRange(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      _id {
        clientId
        issuerName
      }
      count
    }
  }
`;

export const getLabResult = gql`
  query getLabResult($id: ID!, $filter: String) {
    getLabResult(id: $id, filter: $filter) {
      id
      userId
      holderName
      email
      dateOfBirth
      ccEmails
      clinicId
      clientId
      docIssuedAt
      passNo
      createdAt
      phoneNumber
      type
      certType
      clinicName
      collectionDate
      nationality
      gender
      labPhoneNumber
      issuedAt
      labAddress
      labName
      birthDate
      acsn
      subType
      physicianId
      physicianName
      physicianLicense
      physicianSignature
      countryCode
      logoImage
      resultsFilePath
      secondResultsFilePath
      ssn
      result
      status
      docId
      transportName
      resultType
      secondLabName
      secondLabAddress
      secondLabPhoneNumber
      secondResult
      secondAcsn
      additionalData
    }
  }
`;

export const getLabResultPublic = gql`
  query getLabResult($id: ID!, $filter: String) {
    getLabResult(id: $id, filter: $filter) {
      id
    }
  }
`;

export const getLabResultsCount = gql`
  query getLabResultsCount($clientId: ID, $clinicId: ID, $filter: String) {
    getLabResultsCount(
      clientId: $clientId
      clinicId: $clinicId
      filter: $filter
    )
  }
`;

export const getLabResultsCountInMonth = gql`
  query labResultsAnalyticsData(
    $clientId: ID!
    $startMonth: Int
    $endMonth: Int
    $subType: [String]
    $isClientGroup: Boolean
    $groupByLab: Boolean
  ) {
    labResultsAnalyticsData(
      clientId: $clientId
      startMonth: $startMonth
      endMonth: $endMonth
      subType: $subType
      isClientGroup: $isClientGroup
      groupByLab: $groupByLab
    ) {
      _id {
        month
        subType
        labName
        date
      }
      count
    }
  }
`;
