import React from 'react';

const SvgLanguage = props => (
  <svg x="0px" y="0px" viewBox="0 0 384.97 384.97" {...props}>
    <g>
      <g id="More_Vertical">
        <path
          stroke={props.color}
          d="M192.485,96.242c26.575,0,48.121-21.546,48.121-48.121S219.06,0,192.485,0s-48.121,21.546-48.121,48.121
      S165.91,96.242,192.485,96.242z M192.485,24.061c13.293,0,24.061,10.779,24.061,24.061s-10.767,24.061-24.061,24.061
      s-24.061-10.767-24.061-24.061S179.191,24.061,192.485,24.061z"
        />
        <path
          d="M192.485,288.727c-26.575,0-48.121,21.546-48.121,48.121c0,26.575,21.546,48.121,48.121,48.121
      s48.121-21.546,48.121-48.121C240.606,310.274,219.06,288.727,192.485,288.727z M192.485,360.909
      c-13.293,0-24.061-10.767-24.061-24.061s10.767-24.061,24.061-24.061s24.061,10.779,24.061,24.061
      S205.778,360.909,192.485,360.909z"
        />
        <path
          d="M192.485,144.364c-26.575,0-48.121,21.546-48.121,48.121s21.546,48.121,48.121,48.121s48.121-21.546,48.121-48.121
      S219.06,144.364,192.485,144.364z M192.485,216.545c-13.293,0-24.061-10.767-24.061-24.061s10.767-24.061,24.061-24.061
      s24.061,10.779,24.061,24.061C216.545,205.766,205.778,216.545,192.485,216.545z"
        />
      </g>
    </g>
  </svg>
);

export default SvgLanguage;
