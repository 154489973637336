import * as yup from 'yup';
import moment from 'moment';

export const schema = yup.object().shape({
  // title: yup.string().required('required').max(255),
  gender: yup.string().required('required').max(255),
  firstName: yup.string().required('required').max(255),
  lastName: yup.string().required('required').max(255),
  dateOfBirth: yup
    .string()
    .test('age should be greater than 18', (val) => {
      const age =
        moment().toDate().getFullYear() -
        moment(val, 'DD-MM-YYYY').toDate().getFullYear();
      return age >= 18;
    })
    .required('required'),
  email: yup.string().email().required('required'),
  ssn: yup.string().notRequired().min(7).max(22).nullable().transform((value) => !!value ? value : null),
});
