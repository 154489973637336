import React from 'react';
import { withRouter } from 'react-router';

import Certificate from './Certificate';
import PcrCertificate from './pcrCertificate/PcrCertificate';
import SingPdt from './pcrCertificate/certs/SingPDT';
import SingPCR from './pcrCertificate/certs/SingPCR';
import PhPCR from './pcrCertificate/certs/FilipinoPCR';
import LampCert from './pcrCertificate/certs/LampCert';
import SerCert from './pcrCertificate/certs/SerologyCert';
import ChPCR from './pcrCertificate/certs/ChinesePCRAntiGen';
import ChPCRProtein from './pcrCertificate/certs/ChinesePCRAntProtein';
import SingRapid from './pcrCertificate/certs/SingRapidTest';
import PemeCertificate from './pemeCertificate/PhPemeCert';
import FftCertificate from './fftCertificate/FftCertificate';
import FftCovCertificateSg from './fftCovCertificateSg/FftCovCertificateSg';
import ChPdt from './pcrCertificate/certs/ChPDT';

import './certificate.scss';

const Cerficate = ({ certificate, qrPrint, certRef }) => {
  const renderCert = () => {
    if (certificate.certType === 'PCR_TEST') {
      if (!certificate.subType || certificate.subType === 'SG_PDT_PCR_TEST')
        return (
          <SingPdt
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );

      if (certificate.subType === 'SG_LAMP')
        return (
          <LampCert
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );

      if (certificate.subType === 'CH_PDT_PCR_TEST')
        return (
          <ChPdt
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );

        if (certificate.subType === 'SG_PCR_TEST')
        return (
          <SingPCR
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      if (
        certificate.subType === 'SEROLOGY' ||
        certificate.subType === 'SEROLOGY_IGG'
      )
        return (
          <SerCert
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      // Malaysian. Never ended up being used
      if (certificate.subType === 'MY_PCR_TEST')
        return (
          <SingPdt
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      if (
        certificate.subType === 'SG_ANTI_RAPID_TEST' ||
        certificate.subType === 'PA_SG_ANTI_RAPID_TEST' ||
        certificate.subType === 'RMSP_SG_ANTI_RAPID_TEST' ||
        certificate.subType === 'SP_SG_ANTI_RAPID_TEST'
      )
        return (
          <SingRapid
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      if (certificate.subType === 'CH_PCR_ANTIGEN_TEST')
        return (
          <ChPCR
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      if (
        certificate.subType === 'CH_PCR_PRO_ANTIBODY_TEST' ||
        certificate.subType === 'CH_PCR_PRO_S_ANTIBODY_TEST'
      )
        return (
          <ChPCRProtein
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      if (certificate.subType === 'PH_PCR_TEST')
        return (
          <PhPCR
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      if (certificate.subType === 'PH_PDT_SF_TEST')
        return (
          <PhPCR
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      else
        return (
          <PcrCertificate
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
    }

    if (certificate.certType === 'PEME') {
      return (
        <PemeCertificate
          showQrCode={qrPrint}
          certificate={certificate}
          certRef={certRef}
        />
      );
    }

    if (certificate.certType === 'FFT') {
      return (
        <FftCertificate
          showQrCode={qrPrint}
          certificate={certificate}
          certRef={certRef}
        />
      );
    }

    if (certificate.certType === 'FFT_COV_SG') {
      return (
        <FftCovCertificateSg
          showQrCode={qrPrint}
          certificate={certificate}
          certRef={certRef}
        />
      );
    }

    return (
      <Certificate
        showQrCode={qrPrint}
        certificate={certificate}
        certRef={certRef}
      />
    );
  };

  return renderCert();
};

export default withRouter(Cerficate);
