import React from 'react';
import { useMediaQuery } from 'react-responsive';

import PatientOverviewMobile from './components/PatientOverviewMobile';
import PatientOverviewWeb from './components/PatientOverviewWeb';

import './patientOverview.scss';

const PatientOverview = ({ patient, fetchingPatient, mobileOnly }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {isMobile || mobileOnly ? (
        <PatientOverviewMobile
          patient={patient}
          fetchingPatient={fetchingPatient}
        />
      ) : (
        <PatientOverviewWeb
          patient={patient}
          fetchingPatient={fetchingPatient}
        />
      )}
    </>
  );
};

export default PatientOverview;
