import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    width="inherit"
    height="inherit"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 80"
    {...props}
  >
    <circle cx={40} cy={40} r={40} fill="#EFEFEF" />
    <circle cx={40} cy={25} r={18} fill="gray" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.642 64.836C13.787 52.596 25.89 44 40 44s26.213 8.595 31.358 20.836C64.03 74.075 52.708 80 40 80c-12.707 0-24.03-5.925-31.358-15.164Z"
      fill="gray"
    />
  </svg>
);

export default SvgComponent;
