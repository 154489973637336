import React from 'react';

// import HeartHandIcon from 'assets/new/heartHand';
import NormalIcon from 'assets/range-icons/normal.png';
import HighIcon from 'assets/range-icons/high.png';
import LowIcon from 'assets/range-icons/low.png';
import infoBubbleSlantSvg from 'assets/new/infoBubbleSlant.svg';
import DetectedIcon from 'assets/range-icons/detected.png';
import NotDetectedIcon from 'assets/range-icons/not-detected.png';

import './simpleBiomarkerSection.scss';

const values = {
  borderline: { name: 'Borderline', color: 'orange', icon: HighIcon },
  desirable: { name: 'Desirable', color: 'green', icon: NormalIcon },
  high: { name: 'High', color: 'red', icon: HighIcon },
  microalbuminuria: { name: 'Microalbuminuria', color: 'red', icon: HighIcon },
  macroalbuminuria: { name: 'Macroalbuminuria', color: 'red2', icon: HighIcon },
  criticalhigh: { name: 'Very High', color: 'red2', icon: HighIcon },
  veryhigh: { name: 'Very High', color: 'red2', icon: HighIcon },
  verylow: { name: 'Very Low', color: 'orange', icon: LowIcon },
  lowrisk: { name: 'Low Risk', color: 'greenish2', icon: NormalIcon },
  averagerisk: { name: 'Average Risk', color: 'green', icon: NormalIcon },
  highrisk: { name: 'High Risk', color: 'red', icon: HighIcon },
  low: { name: 'Low', color: 'yellow', icon: LowIcon },
  optimal: { name: 'Optimal', color: 'greenish', icon: NormalIcon },
  suboptimal: { name: 'subOptimal', color: 'yellow', icon: LowIcon },
  normal: { name: 'Normal', color: 'green', icon: NormalIcon },
  sufficient: { name: 'Sufficient', color: 'green', icon: NormalIcon },
  ideal: { name: 'Ideal', color: 'greenish2', icon: NormalIcon },
  acceptable: { name: 'Acceptable', color: 'green', icon: DetectedIcon },
  unacceptable: { name: 'Unacceptable', color: 'red', icon: NotDetectedIcon },
  negative: { name: 'Negative', color: 'green', icon: NotDetectedIcon },
  positive: { name: 'Positive', color: 'red', icon: DetectedIcon },
  abnormal: { name: 'Abnormal', color: 'red', icon: HighIcon },
  'non-reactive': {
    name: 'Non-Reactive',
    color: 'green',
    icon: NotDetectedIcon,
  },
  reactive: { name: 'Reactive', color: 'red', icon: DetectedIcon },
  nil: { name: 'Nil', color: 'green', icon: NotDetectedIcon },
  trace: { name: 'Trace', color: 'orange', icon: DetectedIcon },
  deficient: { name: 'Trace', color: 'red', icon: DetectedIcon },
  insufficient: { name: 'Trace', color: 'orange', icon: DetectedIcon },
};

const SimpleBiomarkerSection = ({ observation }) => {
  return (
    <div className="s-bio-sec">
      <div className="s-bio-sec__display">{observation.display}</div>
      <div className="s-bio-sec__result">
        <img
          className="s-bio-sec__result__icon"
          src={observation?.isAbnormal ? HighIcon : NormalIcon}
          alt="icon"
        />
        {/* Yo alfred check this out */}
        {!!observation?.value || observation?.value === 0 ? observation.value : 'NA'}{' '}
        {observation?.unit ? <div className='s-bio-sec__unit'>{observation?.unit}</div> : ''}
      </div>
      {observation.description && (
        <div className="s-bio-sec__info">
          {/* <img
            className="s-bio-sec__info__icon"
            src={infoBubbleSlantSvg}
            alt="icon"
          /> */}
          <div className="s-bio-sec__info__text">{observation.description}</div>
        </div>
      )}
      {observation?.anomalyResult?.status?.toLowerCase() !== 'normal' && observation.status_description && (
        <div
          className={`s-bio-sec__info s-bio-sec__info--${observation.status}`}
        >
          <div className="s-bio-sec__info__text">
            <div className="s-bio-sec__info__header">
              <>
                What does it mean to have{' '}
                {observation?.anomalyResult?.status?.toLowerCase()}{' '}
                {observation.display.split('[')[0]}?
              </>
            </div>
            {observation.status_description.replace(
              'Discuss your test result with your doctor and find out what it means for your health.',
              ''
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SimpleBiomarkerSection;
