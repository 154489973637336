import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { pdfjs } from 'react-pdf';

import { ErrorToast, PDFViewer } from 'components';
import pdfnetApi from 'api/pdfnetApi/pdfnetApi';

import './smartReportPreview.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const SmartReportPreview = ({ reportId, patient, result }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  // const downloadPdf = async (url) => {
  //   try {
  //     axios
  //       .get(pdfUrl, {
  //         responseType: 'blob',
  //       })
  //       .then((res) => {
  //         downloadFile(
  //           res.data,
  //           `${patient.fullName}_${moment(result.createdAt).format(
  //             'DD-MM-YYYY'
  //           )}_ReACT-report.pdf`
  //         );
  //       });
  //   } catch (error) {
  //     console.log('error', error);
  //     toast.error(
  //       <ErrorToast message="Error downloading PDF, please try again later" />
  //     );
  //   }
  // };

  const getPdfUrl = async () => {
    try {
      const res = await pdfnetApi.getSignedUrl(result.smartReportUrl);
      setPdfUrl(res?.data);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      console.log('error', error);
    }
  };

  // const loading = (
  //   <Segment basic loading className="lab-report-pdf__loader-segment"></Segment>
  // );

  useEffect(() => {
    getPdfUrl();
  }, []);

  return (
    <div className="lab-report-pdf">
      <>
        {pdfUrl && <PDFViewer url={pdfUrl} />}
      </>
      {/* <Document
        onLoadSuccess={onDocumentLoadSuccess}
        file={pdfUrl}
        noData={loading}
        loading={loading}
      >
        <div className="lab-report-pdf__toolbar">
          <div className="lab-report-pdf__toolbar__download">
            <SButton basic onClick={() => downloadPdf()}>
              <SIcon name="download" />
              Download
            </SButton>
          </div>
        </div>
        {Array(numPages)
          .fill(0)
          .map((_, index) => (
            <Page
            scale={1.5}
            pageIndex={index}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            customTextRenderer={false} 
            />
          ))}
      </Document> */}
    </div>
  );
};

export default SmartReportPreview;
