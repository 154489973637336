import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createEditorStateWithText } from '@draft-js-plugins/editor';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import chatGptApi from 'api/chatGpt';

import {
  Text,
  Button,
  RichTextEditor,
  SuccessToast,
  CheckBox,
} from 'components';
import HeartHandIcon from 'assets/new/heartHand';

import './doctorsNotes.scss';

const DoctorsNotes = ({
  onChange = () => {},
  value = '',
  onSave = () => {},
  resultId,
  isExtractingData,
  hasResult = true
}) => {
  const [note, setNote] = useState(createEditorStateWithText(''));
  const [isSaving, setIsSaving] = useState(false);
  const [enabledAI, setEnabledAi] = useState(false);
  const [fetchingRec, setFetchingRec] = useState(false);

  useEffect(() => {
    let isEnabledAi = JSON.parse(localStorage.getItem('enabledNotesAI'));
    if (isEnabledAi === null) setEnabledAi(true);
    else setEnabledAi(isEnabledAi);

    if (!!value) {
      if(checkForJson(value))
        setNote(EditorState.createWithContent(convertFromRaw(JSON.parse(value))));
      else setNote(createEditorStateWithText(value));
    }
    else if (!isExtractingData && hasResult && (isEnabledAi || isEnabledAi === null)) fetchHealthRecommendation();
  }, []);

  useEffect(() => {
    localStorage.setItem('enabledNotesAI', enabledAI);
  }, [enabledAI]);

  const checkForJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  const onNoteChange = (value) => {
    setNote(value);
    onChange(value);
  };

  const fetchHealthRecommendation = async () => {
    try {
      setFetchingRec(true);
      const resp = await chatGptApi.getHealthRecommendations(resultId);
      setNote(createEditorStateWithText(resp.data));
    } catch (error) {
      console.log('error', error);
    } finally {
      setFetchingRec(false);
    }
  };

  const onSaveNote = async () => {
    try {
      setIsSaving(true);
      const value = JSON.stringify(convertToRaw(note.getCurrentContent()));
      await onSave({ doctorNote: value });
      toast.success(<SuccessToast message="Notes saved" />);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="lr-dn">
      <div className="lr-dn__header">
        <div className="lr-dn__header__text">
          <HeartHandIcon color="black" className="lr-dn__icon" />
          <Text id="react_report.doctorz_notes" dark size="big">
            Doctor's Notes
          </Text>
          {note.getCurrentContent().hasText() &&
            !fetchingRec &&
            JSON.stringify(convertToRaw(note.getCurrentContent())) !==
              value && (
              <div className="lr-dn__header__unsaved">
                <Text id="react_report.unsaved_changes" color="orange">
                  Unsaved changes
                </Text>
              </div>
            )}
        </div>
        <div className="lr-dn__ai">
          {fetchingRec && enabledAI && (
            <div className="lr-dn__header__loading-text">
              <Text id="react_report.minute" bold>
                This could take a minute or 2
              </Text>
            </div>
          )}
          {/* <div className="lr-dn__header__loading-text">
            This could take a minute or 2
          </div> */}
          {enabledAI && (
            <Button
              className={`lr-dn__ai__button ${
                fetchingRec && 'lr-dn__ai__button--loading'
              }`}
              inverted
              color="grey"
              onClick={() => fetchHealthRecommendation()}
              isLoading={fetchingRec}
              disabled={fetchingRec || isExtractingData}
            >
              <Icon name="redo" size="small" />
            </Button>
          )}
          <div
            className="lr-dn__ai__check-wrapper"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setEnabledAi(!enabledAI);
            }}
          >
            <CheckBox className="lr-dn__ai__check" checked={enabledAI} />
            <Text
              id="react_report.enable_ai"
              size="tiny"
              className="lr-dn__ai__text"
            >
              Enable AI generated notes
            </Text>
          </div>
        </div>
      </div>

      <RichTextEditor
        editorState={note}
        onChange={(value) => onNoteChange(value)}
      />
      <div className="lr-dn__buttons">
        <Button
          inverted
          size="fullWidth"
          color="blue"
          className="mr-3"
          isLoading={isSaving}
          disabled={isSaving}
          onClick={() => onSaveNote()}
        >
          <Text id="react_report.save_doctors_notes" size="tiny" color="blue">
            Save Doctor's Notes
          </Text>
        </Button>
      </div>
      <Text
        id="react_report.doctors_notes_desc"
        size="tinniest"
        color="lightGrey"
        italic
      >
        *Doctor’s notes will be automatically added into the generated ReACT
        report; if left empty the field will be omitted. Upon generating ReACT
        reports it will not be editable and will appear below the patient’s
        details.
      </Text>
    </div>
  );
};

export default DoctorsNotes;
