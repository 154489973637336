import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Button, Text } from 'components';

const DeleteModal = ({
  show,
  hide,
  confirmDelete,
  isDeleting,
}) => {
  return (
    <Modal closeIcon size="mini" open={show} onClose={() => hide(false)}>
      <Modal.Header>Cancel Session</Modal.Header>
      <Modal.Content>
        <Text size="small" className="mb-5">
          Are you sure you want to cancel this session?
        </Text>
          {/* <Button color="green" negative onClick={() => hide(false)}>
            Cancel
          </Button> */}
          <Button
            color="red"
            isLoading={isDeleting}
            positive
            size="fullWidth"
            onClick={() => confirmDelete()}
          >
            Cancel Session
          </Button>
      </Modal.Content>
    </Modal>
  );
};
export default DeleteModal;
