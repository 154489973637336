import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { debounce } from 'lodash';

import { Text, Select, Search, LanguageSelector } from 'components';
import {
  setSideBarState,
  setUser,
  setClinicOptions,
} from 'redux/generalState.actions';
import UserService from 'api/identity/user';
import ClientService from 'api/client/client';

import Logo from 'assets/images/riverr-logo.png';
import EfLogo from 'assets/images/eurofins-logo.png';
import hambugerNav from 'assets/new/hamburgerNav.svg';
// import ProfilePlaceholder from 'assets/new/profileImagePlaceholder';

import './headerBar.scss';

const HeaderBar = (props) => {
  const dropdownToggleRef = useRef();
  const dispatch = useDispatch();

  const {
    user: userInfo,
    clientInformation: clientInfo,
    isDevMode,
    showSideBar,
    customUrlClient,
    clinicOptions,
    labClinics,
  } = useSelector((state) => state.general);

  // --- Local state ---
  // const [notifs, setNotifs] = useState([]);
  // const [isNotificationsToggled, setIsNotificationsToggled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [logo, setLogo] = useState(null);
  const [labClinicOptions, setLabClinicOptions] = useState(null);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  
  useEffect(() => {
    if(userInfo.metadata?.role !== 'patient') fetchSubClients();
  }, [userInfo]);

  useEffect(() => {
    if (customUrlClient === 'sIdzwYsvwehphIHNG2ce') setLogo(EfLogo);
  }, [customUrlClient]);
  
  useEffect(() => {
    const labClinicOptionsResp = formatLabClinicOptions(labClinics);
    setLabClinicOptions(labClinicOptionsResp);
  }, [labClinics]);

  useEffect(() => {
    if (isMobile && isPortrait) {
      dispatch(setSideBarState(false));
    } else {
      dispatch(setSideBarState(true));
    }
  }, [dispatch, isPortrait, isMobile]);

  const formatLabClinicOptions = (clinics) => {
    return clinics.map((clin) => ({
        title: clin.name,
        text: clin.name,
        value: clin.clientId,
      }
    ));
  };

  const formatClinicOptions = (clientGroup) => {
    if (!clientGroup || clientGroup.length === 0) return;
    let clientGroups = [];

    clientGroup.map((clinic) => {
      const clinicz = clinic.clients.map((clin) => ({
        title: clin.name,
        text: clin.name,
        value: clin.clientId,
      }));
      clientGroups = [...clientGroups, ...clinicz];
    });
    dispatch(setClinicOptions(clientGroups));
  };

  // const hide = () => {
  //   setIsSettingsToggled(!isSettingsToggled);
  // };

  // const signUserOut = () => {
  //   console.log('er signUserOut');
  //   signOutUser(true);
  // };

  const setSelectedUserClinic = async (clientId) => {
    if (userInfo?.email?.includes('@riverr.ai'))
      await UserService.adminSwitchClient(
        clientId,
        userInfo.id,
        localStorage.getItem('USER_ID')
      );
    else await UserService.switchClient(clientId);
    dispatch(setUser({ ...userInfo, currentClientId: clientId }));
    window.location.assign('/results');
  };

  const hideShowSideBar = () => {
    setIsVisible(!showSideBar);
    dispatch(setSideBarState(!showSideBar));
  };

  const handleSearch = useCallback(
    debounce((search) => searchClinics({ searchValue: search }), 750),
    []
  );

  const onSearchChange = (searchValue) => {
    setSearch(searchValue);
    handleSearch(searchValue);
  };

  const searchClinics = async ({ searchValue = null }) => {
    try {
      setIsSearching(true);
      await fetchSubClients(searchValue);
      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
    }
  };

  const fetchSubClients = async (search) => {
    if (!userInfo?.email?.includes('@riverr.ai'))
      return formatClinicOptions(userInfo.clientGroup);

    try {
      const { data } = await ClientService.getAllClients({ search, limit: 20 });
      formatClinicOptions([{ clients: [...data.data.clients] }]);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <HeaderBarContainer className="header-bar">
        <LogoContainer className="header-bar__icon">
          {isMobile && (
            <img
              src={hambugerNav}
              className="header-bar__icon__burger"
              onClick={() => hideShowSideBar()}
            />
          )}
          {!isMobile && (
            <LogoImage alt="logo" src={logo || clientInfo.logo || Logo} />
          )}
        </LogoContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isDevMode && (
            <div className="header-bar__dev-wrapper">
              <Text bold color="green">
                Sandbox mode
              </Text>
            </div>
          )}
          {/* <NotificationsContainer>
          <Dropdown direction="left" icon="announcement">
            <Dropdown.Menu className="header-bar__notif">
              {notifs.length > 0 ? (
                <>{notifs}</>
              ) : (
                <Dropdown.Item className="header-bar__notif">
                  <Text size="tiny">No new notifications</Text>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </NotificationsContainer> */}
          {userInfo?.metadata?.role !== 'patient' && (
            <div style={{ marginRight: '20px' }}>
              {userInfo?.email?.includes('@riverr.ai') ? (
                <Search
                  placeholder="Change account"
                  dropdown={true}
                  onSearchChange={(e, { value }) => onSearchChange(value)}
                  onResultSelect={(e, data) =>
                    setSelectedUserClinic(data.result.value)
                  }
                  size={isMobile ? 'small' : 'medium'}
                  isFilter={true}
                  value={search}
                  results={clinicOptions}
                  isLoading={isSearching}
                  className="mr-3"
                />
              ) : (
                <>
                  {!!customUrlClient && (userInfo?.metadata?.clientId === customUrlClient) ? (
                    <>
                      {labClinicOptions && labClinicOptions.length > 0 && (
                        <Select
                          border
                          selectClassName="header-bar__select"
                          search
                          value={userInfo.currentClientId}
                          onChange={(e, { value }) => setSelectedUserClinic(value)}
                          options={labClinicOptions}
                        />
                      )}
                    </>
                  )
                    :
                  (
                    <>
                      {clinicOptions && clinicOptions.length > 0 && (
                        <Select
                          border
                          selectClassName="header-bar__select"
                          search
                          value={userInfo.currentClientId}
                          onChange={(e, { value }) => setSelectedUserClinic(value)}
                          options={clinicOptions}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
          <LanguageSelector />
          {/* <div className="profile-dropdown">
            <div id="name-avatar">
              <IconContainer ref={dropdownToggleRef} onClick={() => hide()}>
                <Letter className="profile-dropdown__abrv">
                  {userInfo.profilePicture || userInfo?.metadata?.profileUrl ? (
                    <div className="profile-dropdown__profile-photo">
                      <img
                        src={
                          userInfo.profilePicture ||
                          userInfo?.metadata?.profileUrl
                        }
                        alt="patient"
                      />
                    </div>
                  ) : (
                    <ProfilePlaceholder className="profile-dropdown__profile-photo" />
                  )}
                  <Text bold size="tiny" className="ml-3">
                    {userInfo?.metadata?.name || userInfo?.metadata?.firstName}
                  </Text>
                </Letter>
              </IconContainer>
            </div>
          </div> */}
          <div className="header-bar__welcome">
            <Text size="small" justify>
              Hello,
            </Text>
            <Text bold size="small" className="ml-2" justify>
              {userInfo?.metadata?.name || userInfo?.metadata?.firstName}
            </Text>
          </div>

          {/* {isSettingsToggled && (
            <div className="profile-dropdown profile-dropdown--toggled">
              <IconContainer ref={dropdownToggleRef} onClick={() => hide()}>
                <Letter className="profile-dropdown__abrv profile-dropdown__abrv--no-bg">
                  {userInfo.profilePicture || userInfo?.metadata?.profileUrl ? (
                    <div className="profile-dropdown__profile-photo">
                      <img
                        src={
                          userInfo.profilePicture ||
                          userInfo?.metadata?.profileUrl
                        }
                        alt="patient"
                      />
                    </div>
                  ) : (
                    <ProfilePlaceholder className="profile-dropdown__profile-photo" />
                  )}
                  <Text bold size="tiny" className="ml-3">
                    {userInfo?.metadata?.name || userInfo?.metadata?.firstName}
                  </Text>
                </Letter>
              </IconContainer>

              <HeaderDropdown
                closeModal={() => setIsSettingsToggled(false)}
                toggleButtonRef={dropdownToggleRef}
              >
                {userInfo?.metadata?.role !== 'patient' && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.riverr.ai/terms"
                  >
                    <IconDropdownItem icon="law" text="Terms & conditions" />
                  </a>
                )}
                <IconDropdownItem
                  icon="edit"
                  text="Reset password"
                  onClick={() => props.setIsModalToggled(true)}
                />
                <IconDropdownItem
                  icon="power off"
                  text="Sign out"
                  onClick={() => signUserOut()}
                />
              </HeaderDropdown>
            </div>
          )} */}
        </div>
      </HeaderBarContainer>
    </>
  );
};

const HeaderBarContainer = styled.div`
  width: 100vw;
  height: 55px;
  border: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoContainer = styled.div``;

const LogoImage = styled.img`
  width: auto;
  height: 40px !important;
  margin: auto;
  max-height: 50px;
  max-width: 300px;
  object-fit: contain;
`;

const IconContainer = styled.div`
  width: 100%;
  bottom: 0;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  position: relative;
`;

const Letter = styled.div`
  background: #efefef;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-right: 10px;
`;

export default withRouter(HeaderBar);
