import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    width="inherit"
    height="inherit"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    {...props}
  >
    <g fill={props.color}>
      <path d="M12 8.5a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z" />
      <path d="m21.294 14.4-.444-.256a9.1 9.1 0 0 0 0-3.29l.444-.256a3.003 3.003 0 0 0-1.891-5.576c-.39.051-.768.179-1.11.376l-.444.257a8.977 8.977 0 0 0-2.85-1.642V3.5a3 3 0 0 0-6 0v.513a8.977 8.977 0 0 0-2.847 1.646L5.705 5.4a3.002 3.002 0 0 0-3 5.2l.444.256a9.1 9.1 0 0 0 0 3.29l-.444.256a3.002 3.002 0 1 0 3 5.2l.445-.257A8.976 8.976 0 0 0 9 20.987v.513a3 3 0 0 0 6 0v-.513a8.977 8.977 0 0 0 2.848-1.646l.447.258a3.002 3.002 0 0 0 3-5.2l-.001.001Zm-2.548-3.776a7.049 7.049 0 0 1 0 3.75 1 1 0 0 0 .464 1.133l1.084.626a1 1 0 0 1-1 1.733l-1.086-.628a1 1 0 0 0-1.215.165 6.983 6.983 0 0 1-3.243 1.875 1 1 0 0 0-.751.969V21.5a1 1 0 0 1-2 0v-1.252a1 1 0 0 0-.751-.969A6.984 6.984 0 0 1 7.006 17.4a1 1 0 0 0-1.215-.165l-1.084.627a1 1 0 1 1-1-1.732l1.084-.626a1 1 0 0 0 .464-1.133 7.048 7.048 0 0 1 0-3.75 1 1 0 0 0-.465-1.129l-1.084-.626a1 1 0 0 1 1-1.733l1.086.628A1 1 0 0 0 7.006 7.6a6.984 6.984 0 0 1 3.243-1.875 1 1 0 0 0 .75-.973V3.5a1 1 0 0 1 2 0v1.252a1 1 0 0 0 .752.969A6.984 6.984 0 0 1 16.994 7.6a1 1 0 0 0 1.215.165l1.084-.627a1 1 0 1 1 1 1.732l-1.084.626a1 1 0 0 0-.463 1.129v-.001Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill={props.color}
          transform="translate(0 .5)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
