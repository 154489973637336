import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PageHeader from '../PageHeader/PageHeader';
import { Button, Text, ErrorToast } from 'components';
import { PatientEmergencyCard } from 'features';
import medicationApi from 'api/patientOverview/medicationApi';
import medicalProfileProviderApi from 'api/patientOverview/clientApi/medicalProlileProviderApi';

import './viewEmergencyContact.scss';

const ViewEmergencyContact = ({ details, onClickEdit }) => {
  const userDetails = useSelector((state) => state.general.user);
  const [allergyData, setData] = useState([]);
  const [conditionsData, setConditionsData] = useState([]);
  const [medicationData, setMedicationData] = useState([]);
  const [bmiData, setBmiData] = useState([]);
  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    fetchMedications();
    fetchMedicalProfile();
  }, []);

  const fetchMedications = async () => {
    try {
      const medications = await medicationApi.getMedicationsOfPatient();
      setMedicationData(medications.filter((med) => !med.isArchived));
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchMedicalProfile = async () => {
    try {
      const profile = await medicalProfileProviderApi.getMedicalProfile(
        userDetails.id
      );
      setData(profile?.allergyList);
      setConditionsData(profile?.conditionList);
      setBmiData({
        height: profile?.bmi?.height?.value,
        heightUnit: profile?.bmi?.height?.unit,
        weight: profile?.bmi?.weight?.value,
        weightUnit: profile?.bmi?.weight?.unit,
      });
      setProfileData(profile);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <div className="viewEmergencyContact__header">
        <PageHeader
          titleId="patient_app.emergency_id"
          title="Your Emergency ID"
          backPath="/settings"
        />
      </div>
      <PatientEmergencyCard
        userDetails={{ ...userDetails, bloodType: profileData?.bloodType }}
      />
      <div className="viewEmergencyContact__header">
        <PageHeader title="Information" />
      </div>
      <div className="viewEmergencyContact__form">
        <div className="viewEmergencyContact__form__grid-wrapper">
          <div className="viewEmergencyContact__form__grid">
            <Text size="tiny" color="lightGrey" id="patient_overview.height">
              Height
            </Text>
            <Text size="tiny" bold>
              {bmiData?.height || '-'} {bmiData?.heightUnit || ''}
            </Text>
          </div>
          <div className="viewEmergencyContact__form__grid">
            <Text size="tiny" color="lightGrey" id="patient_overview.weight">
              Weight
            </Text>
            <Text size="tiny" bold>
              {bmiData?.weight || '-'} {bmiData?.weightUnit || ''}
            </Text>
          </div>
          <div className="viewEmergencyContact__form__grid">
            <Text size="tiny" color="lightGrey" id="add_patient.smoker">
              Smoker
            </Text>
            <Text size="tiny" bold>
              {profileData?.isSmoker ? 'Yes' : 'No'}
            </Text>
          </div>
          {/* <div className="viewEmergencyContact__form__grid">
            <Text size="tiny" color="lightGrey">
              Family Medical History
            </Text>
            <Text size="tiny" bold>
              {userDetails?.metadata?.familyHistory?.join(', ') || 'none'}
            </Text>
          </div> */}
          <div className="viewEmergencyContact__form__grid">
            <Text
              size="tiny"
              color="lightGrey"
              id="patient_app.emergency_med_cond"
            >
              Medical Conditions
            </Text>
            <Text size="tiny" bold>
              {conditionsData?.length && conditionsData[0]?.name || 'none'}
            </Text>
          </div>
          <div className="viewEmergencyContact__form__grid">
            <Text
              size="tiny"
              color="lightGrey"
              id="patient_app.emergency_allergy_react"
            >
              Allergy & Reaction
            </Text>
            {allergyData?.length
              ? allergyData.map((allergy) => (
                  <Text size="tiny" bold>
                    {allergy?.name}
                  </Text>
                ))
              : '-'}
          </div>
          <div className="viewEmergencyContact__form__grid">
            <Text size="tiny" color="lightGrey" id="add_patient.medication">
              Medication
            </Text>
            {medicationData.length
              ? medicationData.map((med) => (
                  <Text size="tiny" bold>
                    {med?.name}
                  </Text>
                ))
              : 'none'}
          </div>
        </div>
      </div>
      <div className="viewEmergencyContact__header">
        <PageHeader title="Emergency Contact" />
      </div>
      <div className="viewEmergencyContact__form">
        <div className="viewEmergencyContact__form__grid-wrapper">
          <div className="viewEmergencyContact__form__grid">
            <div className="viewEmergencyContact__form__grid">
              <Text size="tiny" color="lightGrey" id="settings_acct.name">
                Name
              </Text>
              <Text size="tiny" bold>
                {details.fullName}
              </Text>
            </div>
            <div className="viewEmergencyContact__form__grid">
              <Text
                size="tiny"
                color="lightGrey"
                id="patient_app.settings.my_profile.contact"
              >
                Contact
              </Text>
              <Text size="tiny" bold>
                {details.emphoneNumber}
              </Text>
            </div>
            <div className="viewEmergencyContact__form__grid">
              <Text
                size="tiny"
                color="lightGrey"
                id="patient_app.settings.emergency_contact.primary_language"
              >
                Primary Language
              </Text>
              <Text size="tiny" bold>
                {details.emlanguage}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="viewEmergencyContact__button">
        <Button
          type="submit"
          // color="black"
          color="blue"
          className="rec-ph__button"
          size="fullWidth"
          onClick={onClickEdit}
        >
          <Text id="patient_overview.edit" color="inherit">
            Edit
          </Text>
        </Button>
      </div>
    </>
  );
};
export default ViewEmergencyContact;
