import React, { useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ReactPinField from 'react-pin-field';

import deltaLogo from '../../../../assets/images/riverr-logo.png';
import { Text } from '../../../../components';

import './publicDocLogin.scss';

const PublicDocLogin = (props) => {
  const pinRef = useRef();

  useEffect(() => {
    if (pinRef?.current?.inputs)
      pinRef.current.inputs.forEach((input) => (input.pattern = '[0-9]*'));
  }, [pinRef]);

  const login = async (pass) => {
    props.login(pass);
  };

  const onPinChange = (pin) => {
    if (pin.length === 6) login(pin.toUpperCase());
  };

  return (
    <div className="public-doc-login">
      <div className="public-doc-login__card">
        <img
          className="public-doc-login__card__img"
          src={deltaLogo}
          alt="logo"
        />
        <Text dark className="public-doc-login__card__header">
          Riverr Health Portal
        </Text>
        <div className="public-doc-login__card__info">
          <Text size="small">
            Enter your date of birth as <b>DDMMYY</b>
          </Text>
          <Text
            className="public-doc-login__card__info__ex"
            size="tiny"
            color="lightGrey"
          >
            Example: August 18, 1995 = 180895
          </Text>
        </div>
        <div>
          <ReactPinField
            ref={pinRef}
            length={6}
            className="public-doc-login__card__pin"
            onChange={(value) => onPinChange(value)}
          />
        </div>
        {props.wrongPass && (
          <Text
            size="tiny"
            color="red"
            className="public-doc-login__card__error-text"
          >
            *If you are sure you entered the correct pass code please contact
            support
          </Text>
        )}
      </div>
    </div>
  );
};

export default withRouter(PublicDocLogin);
