import React from 'react';

import { Text, Button, Icon } from 'components';
import history from '../../../history';

import './placeholderCard.scss';

const PlaceholderCard = ({
  icon,
  header,
  text,
  rows,
  buttonText,
  redirectPath,
  IconComponent,
  onButtonClick,
  textAlign,
  buttonIcon,
  buttonTextId,
  headerId,
  textId,
}) => {
  const onClick = () => {
    if (onButtonClick) return onButtonClick();
    history.push(redirectPath);
  };
  return (
    <>
      <div className="rec-ph">
        {icon && (
          <img
            src={icon}
            style={{
              objectFit: 'contain',
              height: '320px',
              width: '100%',
              margin: '0 -10px',
            }}
          />
        )}
        {IconComponent && <IconComponent height={100} width={100} />}

        {header && (
          <Text id={headerId || ''} size="bigger" bold className="mt-4 mb-3">
            {header}
          </Text>
        )}
        {text && (
          <div className="rec-ph__row">
            <Text
              id={textId || ''}
              className={textAlign ? 'rec-ph__row__text' : ''}
            >
              {text}
            </Text>
          </div>
        )}
        {rows &&
          rows.length > 0 &&
          rows.map((row) => (
            <div className="rec-ph__row">
              <img
                src={row.icon}
                style={{
                  objectFit: 'contain',
                  height: '20px',
                  width: '20px',
                }}
              />
              {/* <img className="rec-ph__row__icon" alt="meds" src={row.icon} /> */}
              <Text
                id={row?.textId || ''}
                className={row?.textAlign ? 'rec-ph__row__text' : ''}
              >
                {row.text}
              </Text>
            </div>
          ))}
        {buttonText && (
          <div className="rec-ph__button-wrapper">
            <Button
              // color="black"
              color="blue"
              className="rec-ph__button"
              size="fullWidth"
              // type="pill"
              onClick={onClick}
            >
              <Text id={buttonTextId} color="inherit">
                {buttonText}
              </Text>
              {buttonIcon && (
                <Icon name="arrowRight" className="ml-3" color="white" />
              )}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default PlaceholderCard;
