export const DownloadPdfStatus = Object.freeze({
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  RETRYABLE: 'RETRYABLE',
  FAILED: 'FAILED',
});

export const INTERVAL_DURATION = 5000;
export const INTERVAL_TIME = 6;
