export const SET_LAB_DOCUMENTS = 'LAB_DOCUMENTS/SET_LAB_DOCUMENTS';

/**
 * @function setLabDocuments
 * @description Set documents results sent by labs
 */
export const setLabDocuments = labDocuments => ({
  type: SET_LAB_DOCUMENTS,
  payload: labDocuments,
});
