import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    width="inherit"
    height="inherit"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    {...props}
  >
    <g>
      <path
        d="M18 .5H6a1 1 0 0 0 0 2h1v17a5 5 0 1 0 10 0v-17h1a1 1 0 1 0 0-2Zm-6 22a3 3 0 0 1-3-3v-10h6v10a3 3 0 0 1-3 3Zm3-15H9v-5h6v5Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill={props.color}
          transform="translate(0 .5)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
