import React from 'react';
import PropTypes from 'prop-types';
import { Text, Button, Switch } from 'components';
import { Icon } from 'semantic-ui-react'

import '../smartReport.scss';

const SmartReportWrapper = ({
  setHasConsent,
  setSendEmail,
  isSendEmail,
  hasDoctorsNotes,
  hasSmartReport,
  patientEmail,
  isReactSession
}) => {
  return (
    <>
      <div className="smart-rep-c__content">
        <Icon name="warning circle" className="smart-rep-c__content__icon" size="large" color='red' />
        <Text size="small" className="smart-rep-c__content__text">
          {hasSmartReport && (
            <>
              <Text bold>
                You are about to generate a new ReACT report
              </Text>
              <Text size="small" className="mb-3">
                It will replace the exisiting one and you will be charged for the new report.
              </Text>
            </>
          )}
          {(!hasSmartReport && hasDoctorsNotes) &&
          <>
            <Text bold>
              Check your doctor’s notes before generation!
            </Text>
            <Text size="small" className="mb-3">
              Once generated it will not be editable.
            </Text>
          </>
          }
          {(!hasSmartReport && !hasDoctorsNotes) && (
          <>
            <Text bold>
              Generate ReACT Report without any doctor’s notes?
            </Text>
            <Text size="small" className="mb-3">
              Once generated it will not be editable.
            </Text>
          </>
          )}
        </Text>
      </div>
      {isReactSession && (
      <div className="smart-rep-c__settings">
        <div className="smart-rep-c__settings__row">
          <div style={{ display: 'flex'}}>
            <Text bold>Send email to patient?</Text>
            <Switch className="ml-3 mt-1" checked={isSendEmail} onChange={() => setSendEmail(!isSendEmail)}/>
          </div>
          <Text className="mt-3" size="small">To: {patientEmail}</Text>
        </div>
      </div>
      )}
      <Button
        className="smart-rep-c__button"
        size="fullWidth"
        color="blue"
        onClick={() => setHasConsent(true)}
      >
        Preview ReACT Report
      </Button>
    </>
  );
};

SmartReportWrapper.propTypes = {
  label: PropTypes.string,
  src: PropTypes.string,
};

export default SmartReportWrapper;
