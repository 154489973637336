import headerBar from './HeaderBar/HeaderBar';
import sideBar from './SideBar/SideBar';
import labResultBasicInfo from './LabResultBasicInfo/LabResultBasicInfo';
import healthValueRange from './HealthValueRange/HealthValueRange';
import healthValueOverview from './HealthValueOverview/HealthValueOverview';
import patientSideBar from './PatientSideBar/PatientSideBar';
import patientOverview from './PatientOverview/PatientOverview';
import allergyInput from './inputs/allergyInput/AllergyInput';
import conditionInput from './inputs/conditionInput/ConditionInput';
import familyHistoryInput from './inputs/familyHistoryInput/FamilyHistoryInput';
import medicationInput from './inputs/medicationInput/MedicationInput';
import addMedication from './patientMedicalCreators/addMedication/AddMedication';
import addCondition from './patientMedicalCreators/addCondition/AddCondition';
import addFamilyHistory from './patientMedicalCreators/addFamilyHistory/AddFamilyHistory';
import addAllergy from './patientMedicalCreators/addAllergy/AddAllergy';
import resultPdfUploadModal from './ResultPdfUploadModal/ResultPdfUploadModal';
import labResultsTableFilter from './LabResultsTableFilter/LabResultsTableFilter';
import patientBiomarkerOverview from './PatientBiomarkerOverview/PatientBiomarkerOverview';
import patientEmergencyCard from './PatientEmergencyCard/PatientEmergencyCard';
import deleteResultsModal from './DeleteResultsModal/DeleteResultsModal';

export const HeaderBar = headerBar;
export const SideBar = sideBar;
export const LabResultBasicInfo = labResultBasicInfo;
export const HealthValueRange = healthValueRange;
export const HealthValueOverview = healthValueOverview;
export const PatientSideBar = patientSideBar;
export const PatientOverview = patientOverview;
export const AllergyInput = allergyInput;
export const ConditionInput = conditionInput;
export const FamilyHistoryInput = familyHistoryInput;
export const MedicationInput = medicationInput;
export const AddMedication = addMedication;
export const AddCondition = addCondition;
export const AddFamilyHistory = addFamilyHistory;
export const AddAllergy = addAllergy;
export const ResultPdfUploadModal = resultPdfUploadModal;
export const LabResultsTableFilter = labResultsTableFilter;
export const PatientBiomarkerOverview = patientBiomarkerOverview;
export const PatientEmergencyCard = patientEmergencyCard;
export const DeleteResultsModal = deleteResultsModal;
