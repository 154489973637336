import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { ErrorToast, Text, Button, CheckBox } from 'components';
import { PatientOverview } from 'features';

import './emergencyContactOverview.scss';

const EmergencyContactForm = ({ details, createUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [emergencyData, setEmergencyData] = useState([]);
  const [accepted, setAccepted] = useState(false);

  const clientInfo = useSelector((state) => state.general.clientBranding);

  useEffect(() => {
    const data = [
      // { label: 'Riverr ID', data: details.id },
      { label: 'Medical Conditions', data: details.preExisting, multi: true },
      // { label: 'Food Allergy', data: details.foodAllergy },
      { label: 'Allergies', data: details.drugAllergy, multi: true },
      { label: 'Medication', data: details.medication, multi: true },
      { label: 'Blood Type', data: details.bloodType },
      { label: 'Height', data: details.height },
      { label: 'Weight', data: details.weight },
      // { label: 'BMI', data: details.bmi },
      { label: 'Primary Language', data: details.language },
    ];
    setPatientData(data);

    const eData = [
      { label: 'Full Name', data: details.title + ' ' + details.fullName },
      { label: 'Contact', data: details.emphoneNumber },
      { label: 'Primary Language', data: details.emlanguage },
    ];
    setEmergencyData(eData);
  }, [details]);

  const onNextStep = async (shareDetails) => {
    if (!details.email)
      return toast.error(
        <ErrorToast message="Check that all required details from the previous steps are entered" />
      );
    setIsLoading(true);
    await createUser(shareDetails);
    setIsLoading(false);
  };
  console.log('details', details);
  return (
    <div className="emer-c-o">
      <Text size="tiny" color="lightGrey">
        Below is a preview of the information that is shared to your emergency
        contact and health provider.
      </Text>
      <div className="emer-c-o__form">
        <PatientOverview
          patient={{
            fullName: details.firstName + ' ' + details.lastName,
            dateOfBirth: moment(details.dateOfBirth, 'DD-MM-YYYY').format(
              'YYYY-MM-DD'
            ),
          }}
          mobileOnly
        />
        <div className="u-patient-form__form__grid-wrapper">
          <Text bold size="big">
            Medical ID
          </Text>
          <div className="u-patient-form__form__grid emer-c-o__grid">
            {patientData.map((data) => (
              <div className="emer-c-o__data-wrapper">
                <Text
                  size="small"
                  color="lightGrey"
                  className="emer-c-o__data-wrapper__label"
                >
                  {data.label}
                </Text>
                <Text className="emer-c-o__data-wrapper__data">
                  {data.multi && data.data
                    ? data.data.join(', ')
                    : data.data || 'None stated'}
                </Text>
              </div>
            ))}
          </div>
        </div>

        <div className="u-patient-form__form__grid-wrapper">
          <Text bold size="big">
            Emergency Contact
          </Text>
          <div className="u-patient-form__form__grid emer-c-o__grid">
            {emergencyData.map((data) => (
              <div className="emer-c-o__data-wrapper">
                <Text
                  size="small"
                  color="lightGrey"
                  className="emer-c-o__data-wrapper__label"
                >
                  {data.label}
                </Text>
                <Text className="emer-c-o__data-wrapper__data">
                  {data.data}
                </Text>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="emer-c-o__terms-wrapper">
        <CheckBox onClick={() => setAccepted(!accepted)} checked={accepted} />
        <Text size="small" className="ml-4">
          I accept the{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              clientInfo.termsConditionsPath || 'https://www.riverr.ai/terms'
            }
          >
            terms
          </a>{' '}
          and{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              clientInfo.privacyPolicy || 'https://www.riverr.ai/privacy-policy'
            }
          >
            privacy policy
          </a>
        </Text>
      </div>

      <Button
        size="fullWidth"
        color="blue"
        className="mb-3 mt-5"
        onClick={() => onNextStep(true)}
        isLoading={isLoading}
        disabled={isLoading || !accepted}
      >
        Confirm
      </Button>
      <Button
        size="fullWidth"
        color="blue"
        onClick={() => onNextStep(false)}
        inverted
        className="mb-2"
        isLoading={isLoading}
        disabled={isLoading || !accepted}
      >
        Skip
      </Button>
      <Text size="tiny" color="lightGrey">
        By pressing “Confirm” you acknowledge to share your full medical ID in
        an emergency, first responders and our approved healthcare providers to
        get life-saving information.
      </Text>
    </div>
  );
};

export default withRouter(EmergencyContactForm);
