import React from 'react';
import Button from './Button';
import PropTypes from 'prop-types';

import Plus from '../assets/icons/Plus';
import { Text } from '../components';

const AddButton = props => {
  return (
    <div
      style={{
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        ...props.style,
      }}
      onClick={props.onClick}
    >
      <Button circular color="blue" size="medium">
        <Plus
          fontSize="2em"
          color="white"
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
          }}
        />
      </Button>
      {props.label && (
        <Text style={{ marginLeft: '10px', userSelect: 'none' }}>
          {props.label}
        </Text>
      )}
    </div>
  );
};

AddButton.propTypes = {
  size: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  buttonStyle: PropTypes.object,
};

AddButton.defaultProps = {
  size: 'small',
  onChange: () => {},
  buttonStyle: {},
};

export default AddButton;
