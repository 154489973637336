import React from 'react';

const SvgCheckShield = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 32" {...props}>
    <g stroke={props.color} strokeWidth={3} fill="none" fillRule="evenodd">
      <path d="M15 29l-6.027-4.186a15.525 15.525 0 01-6.657-13.33l.28-7.521a1 1 0 011-.963h22.808a1 1 0 011 .963l.28 7.522a15.525 15.525 0 01-6.657 13.329L15 29z" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 14.5l3.75 4.5L20 10"
      />
    </g>
  </svg>
);

SvgCheckShield.defaultProps = {
  color: '#1b1b1b',
};

export default SvgCheckShield;
