import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';

import familyHistoryApi from 'api/patientOverview/familyHistoryApi';

import { Select } from 'components';

const FamilyHistoryInput = ({
  onChange = () => {},
  value = null,
  invalid,
  label = 'Family history',
  optional = false,
  multiple = false,
  placeholder = 'Search for your family history',
  labelTextId = '',
}) => {
  const [familyHistoryOptions, setFamilyHistoryOptions] = useState([]);

  useEffect(() => {
    fetchFamilyHistory({});
  }, []);

  const handleSearchChange = async (e, { searchQuery }) => {
    handleSearch(searchQuery);
  };

  const handleSearch = useCallback(
    debounce((search) => fetchFamilyHistory({ search }), 500),
    []
  );

  const fetchFamilyHistory = async ({ search = '' }) => {
    const allFamilyHistory = await familyHistoryApi.getAll({ limit: 50, search });
    const meds = allFamilyHistory.map((familyHistory) => {
      return {
        key: familyHistory.name,
        text: familyHistory.name,
        value: familyHistory.name,
      };
    });

    setFamilyHistoryOptions(meds);
  };

  return (
    <Select
      size="fullWidth"
      search
      optional={optional}
      label={label}
      labelTextId={labelTextId}
      multiple={multiple}
      placeholder={placeholder}
      onSearchChange={handleSearchChange}
      border
      value={value}
      onChange={(e, { value }) => {
        onChange(value);
      }}
      options={familyHistoryOptions}
      invalid={invalid}
      showErrorMessage={false}
    />
  );
};

export default FamilyHistoryInput;
