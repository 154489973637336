import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';

import { Text, ErrorToast } from 'components';

import ConsumablesService from 'api/consumables';

import RequestsOverview from './components/requestsOverview/RequestsOverview';
import RequestForm from './components/requestForm/RequestForm';

import './consumablesRequest.scss';

const ConsumablesRequest = (props) => {
  const clientInfo = useSelector((state) => state.general.clientInformation);
  const userInfo = useSelector(state => state.general.user);

  const [paginationInfo, setPaginationInfo] = useState({ totalPages: 1 });
  const [searchParams, setSearchParams] = useState({});
  const [consumableOrders, setConsumableOrders] = useState([]);
  
  useEffect(()=> {
    updateSearchParams(searchParams);
  }, [searchParams])

  const updateSearchParams = useCallback(
    debounce((params) => getConsumables(params), 500),
    [paginationInfo]
  );

  const removeEmptyFields = (obj) => {
    for (let prop in obj) {
        if (!obj[prop] || obj[prop] === undefined) {
            delete obj[prop];
        }
    }
    return obj;
}

  const getConsumables = async(params) => {
    try {
      const paramsToSend = removeEmptyFields({ ordererId: clientInfo.clientId, ...params });
      
      if(clientInfo.type === 'lab') {
        paramsToSend.receiver = paramsToSend.ordererId;
        delete paramsToSend.ordererId;
      }
      console.log("paramsToSend", paramsToSend)
      if(paramsToSend.toDate) paramsToSend.toDate = moment(paramsToSend.toDate).format('YYYY-MM-DD')
      if(paramsToSend.fromDate) paramsToSend.fromDate = moment(paramsToSend.fromDate).format('YYYY-MM-DD')
      if(!paramsToSend?.page) paramsToSend.page = 1;

      const { data } = await ConsumablesService.getConsumables(paramsToSend);
      setPaginationInfo({
        limit: data?.limit,
        page: data?.page,
        totalPages: data?.totalPages,
        totalResults: data?.totalResults,
      })
      setConsumableOrders(data?.results);
    } catch(error) {
      toast.error(<ErrorToast error={error} />);
    }
  }
  
  const createConsumable = async(orderDetails) => {
    const payload = {
      orderer: {
        clientId: clientInfo.clientId,
        clinicName: clientInfo.name,
        userId: toString(userInfo.id),
        name: userInfo?.metadata?.name
      },
      order: orderDetails
    };
    await ConsumablesService.createConsumable(payload);
    getConsumables(searchParams);
  }

  return (
    <div className='con-req'>
      <Text type="pageHeader" className='con-req__header'>
        Consumables Requisition
      </Text>
      <div className='con-req__content'>
        {clientInfo.type === 'clinic' && <RequestForm createConsumable={createConsumable} />}
        <RequestsOverview setPaginationInfo={setPaginationInfo} paginationInfo={paginationInfo} searchParams={searchParams} consumableOrders={consumableOrders} updateSearchParams={setSearchParams} />
      </div>
    </div>
  );
};

export default ConsumablesRequest;
