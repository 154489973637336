import React, { useEffect, useRef } from 'react';

import './riskAssessmentDonut.scss';

const RiskAssessmentDonut = ({
  graphSize = 200,
  wrapperSize = 200,
  barWidth = 16,
  numOfBars = Math.floor(((graphSize / 2 - barWidth) *  2 * Math.PI)/barWidth),
  barHeight = 54,
  riskPercentageValue = 0,
  actualRiskValue = 0,
}) => {
  // notes: aspect ratio is 1:1
  const BarsWrapperRef = useRef(null);

  useEffect(() => {
    const barsWrapperDiv = BarsWrapperRef.current;
    const barDivs = barsWrapperDiv.querySelectorAll(
      'div.risk-assessment-donut-graph__bar'
    );
    const radius = barsWrapperDiv.offsetWidth / 2;
    const angle = (2 * Math.PI) / numOfBars;
    const numManageableRiskBars = Math.ceil((1 - riskPercentageValue / 100) * numOfBars);

    for (let i = 0; i < numOfBars; i++) {
      const x =
        Math.round(radius * Math.cos(angle * i)) +
        radius -
        barDivs[i].offsetWidth / 2;
      const y =
        Math.round(radius * Math.sin(angle * i)) +
        radius -
        barDivs[i].offsetHeight / 2;
      barDivs[i].style.left = x + 'px';
      barDivs[i].style.top = y + 'px';
      barDivs[i].style.width = barWidth + 'px';
      barDivs[i].style.height = barHeight + 'px';
      barDivs[i].style.backgroundColor =
        i < numManageableRiskBars ? '#22d38f' : '#ff6666';
      barDivs[i].style.transform = `rotate(${angle * i + Math.PI / 2}rad)`;
    }
  }, [graphSize, numOfBars, barWidth, riskPercentageValue, actualRiskValue]);

  return (
    <div className="risk-assessment-donut-graph">
      <div className="risk-assessment-donut-graph__risk-factor-text">
        <span style={{ fontSize: '11px', fontWeight: '500', lineHeight: '12px' }}>Non-Manageable</span>
        <span
          style={{
            fontSize: '14px',
            fontWeight: 'bolder',
            color: 'rgb(255, 102, 102)',
          }}
        >
          {Math.round(
            Number((riskPercentageValue / 100) * actualRiskValue) * 100
          ) / 100}
          %
        </span>
      </div>
      <div
        className="risk-assessment-donut-graph__bars-wrapper"
        style={{
          width: `${wrapperSize || graphSize}px`,
          height: `${wrapperSize || graphSize}px`,
          margin: `${barHeight / 4}px`,
        }}
      >
        <div
          ref={BarsWrapperRef}
          className="risk-assessment-donut-graph__bars"
          style={{
            width: `${graphSize}px`,
            height: `${graphSize}px`,
            transformOrigin: 'center',
            transform: 'rotate(270deg)',
            position: 'absolute'
          }}
        >
          {Array(numOfBars)
            .fill(0)
            .map((_) => {
              return <div className="risk-assessment-donut-graph__bar"></div>;
            })}
        </div>
        <div className="risk-assessment-donut-graph__center">
          <div className="risk-assessment-donut-graph__center__label">
            Your Risk
          </div>
          <div
            className="risk-assessment-donut-graph__center__value"
            style={{ fontSize: `${(graphSize / 500) * 75}px` }}
          >
            <span
              className="risk-assessment-donut-graph__center__value__text"
              style={{ paddingRight: `${(graphSize / 500) * 50}px` }}
            >
              {Math.round(actualRiskValue * 100) / 100}
              <span
                className="risk-assessment-donut-graph__center__value__sup"
                style={{ fontSize: `${(graphSize / 500) * 50}px` }}
              >
                %
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="risk-assessment-donut-graph__risk-factor-text">
        <span style={{ fontSize: '11px', fontWeight: '500', lineHeight: '12px'  }}>Manageable</span>
        <span
          style={{
            fontSize: '14px',
            fontWeight: 'bolder',
            color: 'rgb(34, 211, 143)',
          }}
        >
          {Math.round(
            Number(((100 - riskPercentageValue) / 100) * actualRiskValue) * 100
          ) / 100}
          %
        </span>
      </div>
      
    </div>
  );
};

export default RiskAssessmentDonut;
