import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import general from './redux/generalState.reducer';
import patient from './redux/patient/patient.reducer';

import dashboard from './pages/dashboard/store/dashboard.reducer';
import charts from './pages/stats/charts/charts.reducer';
import stats from './pages/stats/store/stats.reducer';

// certificate management
import certificateManagement from './pages/certificatePages/certificateManagement/certificateManagement.reducer';
import templatesManagement from './pages/certificatePages/templatesManagement/templatesManagement.reducer';
import issuedCertificates from './pages/certificatePages/issuedCertificates/store/issuedCertificates.reducer';
import labDocuments from './pages/certificatePages/labDocuments/store/labDocuments.reducer';

// calendar
import medCalendar from './pages/webAppointments/calendar/state/calendar.reducer';

// staffManagement
import staffManagement from './pages/staffPages/staffManagement/store/staffManagement.reducer';

//Siderbar state management
import { setGlobalVariable } from 'helpers/windowHelpers';

//web appointments
import webAppointments from 'pages/webAppointments/scheduleAppointment/store/webAppointments.reducer';

//test order
import testOrder from 'pages/testOrderPages/store/testOrderPages.reducer';

const reducer = combineReducers({
  patient,
  certificateManagement,
  templatesManagement,
  charts,
  dashboard,
  medCalendar,
  issuedCertificates,
  labDocuments,
  general,
  staffManagement,
  stats,
  webAppointments,
  testOrder,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
if (process.env.NODE_ENV === 'development') {
  store.subscribe(() => {
    setGlobalVariable('redux', store.getState());
  });
}

export default store;
