import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import { Text, Button } from '../components';

const FileUpload = (props) => {
  const clientBranding = useSelector((state) => state.general.clientBranding);

  return (
    <Button
      color="blue"
      inverted
      size={props.size || 'fullWidth'}
      style={props.style}
      className={props.className}
      invalid={props.invalid}
      isIcon={props.icon}
      clientBranding={clientBranding}
      isLoading={props.isLoading}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '5px',
          width: '100%',
          margin: 'auto',
        }}
      >
        {props.icon && <Icon name="upload" color="#5b53f3" />}
        {props.isDropzone && props.fileNames
          ? props.fileNames?.map((name) => (
              <div>
                <Text
                  size="small"
                  bold
                  color="#5b53f3"
                  ellipsis
                  style={{ width: '100%' }}
                >
                  <Icon
                    name={props.isImage ? 'file image' : 'file pdf'}
                    color={props.isImage ? 'blue' : 'red'}
                    size="large"
                  />{' '}
                  {name}
                </Text>
                {!!props.fileSize && (
                  <Text center size="tiny" color="#727272">
                    {Number(props.fileSize).toFixed(2)} KB
                  </Text>
                )}
              </div>
            ))
          : (props.label && (
              <Text id={props?.labelTextId} color="inherit">
                {props.label}
              </Text>
            )) ||
            'Upload file'}
        <Input
          onChange={props.onSelect}
          accept={props.accept}
          multiple={props.multiple ? 'multiple' : undefined}
          type="file"
          disabled={props.disabled}
        />
      </div>
      {props.invalid && (
        <Text className="input-error-text" color="red" size="tiny">
          {props?.errorMessage || 'This field is required'}
        </Text>
      )}
    </Button>
  );
};

FileUpload.propTypes = {
  label: PropTypes.string,
  onSelect: PropTypes.func,
  style: PropTypes.object,
};

FileUpload.defaultProps = {
  onSelect: () => {},
};

const Input = styled.input`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0;
  width: 100%;
  z-index: 99999999;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }
`;

export default FileUpload;
