import { Text, Button, DatePicker, Select } from 'components';
import { Icon } from 'semantic-ui-react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

const Filter = ({
  filterDropdownRef,
  isShowFilters,
  updateSearchParams,
  setSearchParams,
  searchParams,
  clinicOptions,
  selectedClinics,
  setSelectedClinics,
  setShowFilters,
}) => {
  const { clientInformation } = useSelector((state) => state.general);
  return (
    <div className="results-table__custom-dropdown">
      <Button color="lightGrey" onClick={() => setShowFilters(true)} inverted>
        <Text id="results_inbox.filters">Filters</Text>
        <Icon
          style={{ position: 'absolute', right: '3px' }}
          name="filter"
          color="white"
        />
      </Button>
      <div
        className={cx('results-table__custom-dropdown__portal', {
          'results-table__custom-dropdown__portal--open': isShowFilters,
        })}
        ref={filterDropdownRef}
      >
        <>
          <div style={{ display: 'flex', marginTop: '15px' }}>
            <div className="mr-3" style={{ position: 'relative' }}>
              <Text
                id="results_inbox.from_"
                size="tinniest"
                color="lightGrey"
                className="results-table__search__date-tag"
              >
                From
              </Text>
              <DatePicker
                onChange={(date) =>
                  updateSearchParams({ ...searchParams, fromDate: date || '' })
                }
                value={searchParams.fromDate}
                maxDate={searchParams.toDate}
              />
            </div>

            <div style={{ position: 'relative' }}>
              <Text
                id="results_inbox.to"
                size="tinniest"
                color="lightGrey"
                className="results-table__search__date-tag"
              >
                To
              </Text>
              <DatePicker
                className="lab-documents__date"
                onChange={(date) =>
                  updateSearchParams({ ...searchParams, toDate: date || '' })
                }
                value={searchParams.toDate}
                minDate={searchParams.fromDate}
                clearIcon={true}
              />
            </div>
          </div>
          {clientInformation.type === 'lab' && clinicOptions?.length > 0 && (
            <div style={{ position: 'relative', marginTop: '30px' }}>
              <Text
                id="results_inbox.clinics"
                size="tinniest"
                color="lightGrey"
                className="results-table__search__date-tag"
              >
                Clinics
              </Text>
              <Select
                search
                multiple
                border
                size="fullWidth"
                icon={false}
                placeholder="All clinics"
                options={clinicOptions}
                value={searchParams.clients}
                onChange={(e, { value }) => {
                  setSelectedClinics(value);
                  updateSearchParams({...searchParams, clients: value});
                }}
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default Filter;
