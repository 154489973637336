import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useCustomForm } from 'hooks';
import * as yup from 'yup';
import { withRouter } from 'react-router';

import {
  ErrorToast,
  DatePicker,
  TextArea,
  Text,
  Button,
  Input,
  Select,
} from 'components';
import { ConditionInput } from 'features';

import countries from 'helpers/countries';

import '../patMedCreators.scss';
import clientConditionApi from '../../../api/patientOverview/clientApi/conditionApi';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
});

const AddConditionPage = ({ edit, location, onCreate = () => {}, patient }) => {
  const [isSaving, setIsSaving] = useState(false);

  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
  } = useCustomForm(schema, {
    name: edit ? location?.state?.data?.name : '',
    provider: edit ? location?.state?.data?.provider : '',
    country: edit ? location?.state?.data?.country : '',
    date: edit ? location?.state?.data?.date : moment().toDate(),
    remark: edit ? location?.state?.data?.remark : '',
  });

  const updatePatient = async (record) => {
    try {
      await clientConditionApi.addCondition(patient.id, record)
      onCreate();
    } catch (error) {
      console.log(error);
      toast.error(<ErrorToast error={error} />);
    }
  };

  return (
    <div className="pat-med-c">
      <form onSubmit={handleSubmit(updatePatient)}>
        <div className="pat-med-c__form-wrapper">
          <div className="pat-med-c__form-wrapper__row">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <ConditionInput
                  value={value}
                  onChange={(value) => onChange(value)}
                  label={
                    <Text
                      color="inherit"
                      type="label"
                      id="patient_overview.conditions"
                    >
                      Condition
                    </Text>
                  }
                  invalid={errors?.name}
                />
              )}
            />
          </div>
          <div className="pat-med-c__form-wrapper__row">
            <div className="pat-med-c__form-wrapper__input">
              <Controller
                name="severity"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    placeholder="Stages, Borderline, Severe, etc."
                    label={
                      <Text
                        type="label"
                        color="inherit"
                        id="patient_overview.severity"
                      >
                        Severity
                      </Text>
                    }
                    optional
                    border
                    size="fullWidth"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    invalid={errors?.severity}
                    showErrorMessage={false}
                  />
                )}
              />
            </div>
          </div>
          <div className="pat-med-c__form-wrapper__row">
            <div className="pat-med-c__form-wrapper__input">
              <Controller
                name="provider"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    placeholder="Synergy North Hospital"
                    optional
                    label={
                      <Text
                        type="label"
                        color="inherit"
                        id="patient_overview.diagnosis"
                      >
                        Hospital / Clinic Diagnosis
                      </Text>
                    }
                    border
                    size="fullWidth"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    showErrorMessage={false}
                    invalid={errors?.provider}
                  />
                )}
              />
            </div>
          </div>
          <div className="pat-med-c__form-wrapper__row">
            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  size="fullWidth"
                  search
                  optional
                  label={
                    <Text
                      type="label"
                      color="inherit"
                      id="patient_overview.country"
                    >
                      Country
                    </Text>
                  }
                  placeholder="Singapore"
                  border
                  value={value}
                  onChange={(e, { value }) => {
                    onChange(value);
                  }}
                  showErrorMessage={false}
                  options={countries}
                  invalid={errors?.country}
                />
              )}
            />
          </div>
          <div className="pat-med-c__form-wrapper__row">
            <Text type="label" id="patient_overview.date_diagnosed">
              Date Diagnosed
            </Text>
            <Controller
              name="date"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  className="pat-med-c__date"
                  onChange={(date) => onChange(date)}
                  value={value}
                  optional
                  showErrorMessage={false}
                  size="fullWidth"
                  invalid={errors?.date}
                  maxDate={moment().toDate()}
                />
              )}
            />
          </div>
          <div className="pat-med-c__form-wrapper__row">
            <Controller
              name="remark"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  label={
                    <Text type="label" id="patient_overview.remarks">
                      Remarks
                    </Text>
                  }
                  optional
                  showErrorMessage={false}
                  placeholder="Add a remark"
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  invalid={errors?.remark}
                  // disabled={isLabResult && !!issuedTime}
                />
              )}
            />
          </div>
        </div>
        <Button color="blue" size="fullWidth" className="mt-4" type="submit">
          <Text color="inherit" id="settings_lab_grps.create">
            Create
          </Text>
        </Button>
      </form>
    </div>
  );
};

export default withRouter(AddConditionPage);
