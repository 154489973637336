export const SET_ANALYTIC_DATA = 'DASHBOARD/SET_ANALYTIC_DATA';
export const SET_SELECTED_SUBTYPES = 'DASHBOARD/SET_SELECTED_SUBTYPES';
export const SET_SUB_TYPES_OPTIONS = 'DASHBOARD/SET_SUB_TYPES';

/**
 * @function setAnalyticData
 * @description Set Analytic Data of client for graphs
 */
export const setAnalyticData = payload => ({
  type: SET_ANALYTIC_DATA,
  payload,
});

export const setSubTypesOptions = payload => ({
  type: SET_SUB_TYPES_OPTIONS,
  payload,
});

export const doSetSelectedSubType = payload => ({
  type: SET_SELECTED_SUBTYPES,
  payload,
});
