import { patientOverviewClient } from '../patientOverviewClient';

const baseUrl = 'medicalProfile/provider/conditions';
async function addCondition(userId, condition) {
  const response = await patientOverviewClient.post(
    'medicalProfile/provider/create',
    {
      userId,
      condition,
    }
  );

  return response.data;
}

async function updateCondition(userId, condition) {
  const response = await patientOverviewClient.post(`${baseUrl}/update`, {
    ...condition,
    userId,
  });
  return response.data;
}

async function deleteCondition(userId, id) {
  const response = await patientOverviewClient.post(`${baseUrl}/delete`, {
    id,
    userId
  });
  return response.data;
}

const clientConditionApi = {
  addCondition,
  updateCondition,
  deleteCondition,
};

export default clientConditionApi;
