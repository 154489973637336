import {
  SET_STAFF,
  REMOVE_STAFF,
} from './staffManagement.actions';

const initialState = {
  staff: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STAFF:
      return { ...state, staff: action.payload };

    case REMOVE_STAFF:
      const filteredResult = state.staff.filter(template => {
        return template.id !== action.payload;
      });
      return { ...state, staff: filteredResult };

    default:
      return state;
  }
};
