export const getCourseLevelColor = level => {
  if (level === 'refresh') return 'blue';
  if (level === 'advanced') return 'yellow';
  if (level === 'basic') return 'green';
  return 'green';
};

export const splitTextByComma = text => {
  let splittedText = text.split(',');
  return splittedText;
};

export const splitTextBySpace = text => {
  let splittedText = text.split(' ');
  return splittedText;
};

export const generateRandomId = numOfChars => {
  return Math.random()
    .toString(36)
    .substr(2, numOfChars);
};

export const generateRandomNumber = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const convertDateToSeconds = date => {
  const newDate = new Date(date);
  const dateInSeconds = Math.round(newDate.getTime() / 1000); // Floor it
  return dateInSeconds;
};

export const sortAlphabetically = data => {
  return data.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });
};
