import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

import {
  observationsByCat,
  convertAnomalyResult,
} from 'api/fhir/observation/observationMapper';

import { ErrorToast } from 'components';

import BiomarkerOverview from '../../pages/diagnosticReport/BiomarkerOverview';
import PlaceholderCard from 'features/patientApp/PlaceholderCard/PlaceholderCard';

import biomakerReviewSvg from 'assets/rhc/emptyStates/biomarkerOverview-emptyState.svg';

import patientApi from 'api/patient/patientApi';

const PatientBiomarkerOverview = ({ patientId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [obsByCat, setObsByCat] = useState({});
  const [categoryDescriptions, setCategoryDescriptions] = useState({});

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    if (!!patientId) fetchPatientBiomarkers();
  }, [patientId]);

  const fetchPatientBiomarkers = async () => {
    try {
      setIsLoading(true);
      const { message: biomarkersById } =
        await patientApi.getPatientBiomarkerOverview(patientId);

      if(biomarkersById.client_id && biomarkersById.clinic_status) return;

      const biomarkerIds = Object.keys(biomarkersById);
      const categories = {};
      const displayedObservations = [];

      biomarkerIds.forEach((id) => {
        const biomarkerArr = biomarkersById[id];
        const filteredbiomarkerArr = biomarkerArr?.filter((biomarker) => moment(biomarker?.reported_date).isValid())
        const lastBiomarker = filteredbiomarkerArr?.at(-1);

        if (!categories[lastBiomarker?.category_id])
          categories[lastBiomarker?.category_id] = {
            category: lastBiomarker?.category,
          };

        const displayedObservation = {
          id: lastBiomarker?.key || '',
          display: lastBiomarker?.name || '',
          code: lastBiomarker?.loinc || '',
          effectiveDateTime: lastBiomarker?.created_date || '',
          unit:
            !lastBiomarker?.unit || lastBiomarker?.unit === 'NA'
              ? null
              : lastBiomarker?.unit,
          value: isNaN(lastBiomarker?.value)
            ? lastBiomarker?.value
            : Number(lastBiomarker?.value).toFixed(2),
          category: lastBiomarker?.category,
          categoryId: lastBiomarker?.category_id,
          anomalyResult: convertAnomalyResult(lastBiomarker),
        };

        if ((!Array.isArray(filteredbiomarkerArr) || filteredbiomarkerArr?.length === 0)) return;

        const dataPoints = filteredbiomarkerArr.map((biomarker) => {
          return {
            value: isNaN(biomarker?.value)
              ? biomarker?.value
              : Number(biomarker?.value).toFixed(2),
            date: biomarker?.reported_date,
          };
        });

        displayedObservation.dataPoints = dataPoints;

        displayedObservations.push(displayedObservation);
      });

      const byCat = observationsByCat(displayedObservations);
      console.log("byCat", byCat)
      setCategoryDescriptions(categories);
      setObsByCat(byCat);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
    {isLoading ?
      <div className='mt-5 mb-5'>
        <Loader size="medium" active inline="centered" />
      </div>
    :
      <>
        {(Object.keys(obsByCat).length > 0 || !isMobile) ? (
        // {false ? (
          <BiomarkerOverview
            hideTitle={true}
            viewType="table"
            typeSelector={false}
            filteredObservationsByCat={obsByCat}
            categoryDescriptions={categoryDescriptions}
          />
        ) : (
          <PlaceholderCard
            icon={biomakerReviewSvg}
            header="Empowers you with health prediction and contextual insights when you need them."
            text="At Riverr, we champion preventive health. Our expanding risk prediction models aim to join you on a journey towards early health awareness, enabling timely interventions and lifestyle adjustments for a better life."
            buttonText="Upload report now"
            redirectPath="/p/results/upload/files"
          />
        )}
      </>
    }
  </>
  )
};

export default PatientBiomarkerOverview;
