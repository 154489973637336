export const DATE_INPUT_TYPES = ['date', 'hour'];
export const OTHER_INPUT_TYPES = ['Others', 'Other', 'Other (specify)'];
export const CHOICES_INPUT_TYPES = ['choice', 'choiceAndText'];
export const MULTIPLE_CHOICES = ['multipleChoices'];

export const AUTO_TIME = ['F2-Q2', 'F3-Q2'];
export const AUTO_DATE = ['F2-Q1', 'F3-Q1'];
export const CLINICIAN_DETS = ['F2-Q3', 'F3-Q3'];
export const PAT_FIRST_NAME = ['F2-Q6', 'F3-Q6'];
export const PAT_LAST_NAME = ['F2-Q5', 'F3-Q5'];
export const PAT_GENDER = ['F2-Q7', 'F3-Q7'];
export const PAT_DOB = ['F2-Q8', 'F3-Q8'];
export const PAT_EMPLOYER = ['F2-Q9'];
export const PAT_HEIGHT = ['F2-Q54', 'F3-Q24'];
export const PAT_WEIGHT = ['F2-Q55', 'F3-Q25'];
export const PAT_SYS_PB = ['F2-Q56', 'F3-Q18'];
export const PAT_DIA_PB = ['F2-Q57', 'F3-Q19'];
export const PAT_HEART_RATE = ['F2-Q58', 'F3-Q20'];
export const PAT_PULSE = ['F2-Q59', 'F3-Q23'];
export const PAT_MEDS = ['F2-Q60', 'F3-Q73'];

export const DO_NOT_DISPLAY = [
  'F2-Q60',
  'F2-Q61',
  'F2-Q62',
  'F2-Q63',
  'F2-Q64',
];

export const FOR_MED_DISPOTION_VAL_2 = ['F2-Q37', 'F2-Q38', 'F3-Q70', 'F3-Q71'];
export const FOR_MED_DISPOTION_VAL_3 = ['F2-Q39', 'F2-Q40', 'F3-Q72', 'F3-Q73'];
export const FOR_REFERRAL_VAL_1 = [
  'F2-Q42',
  'F2-Q43',
  'F2-Q44',
  'F2-Q45',
  'F2-Q46',
  'F3-Q75',
  'F3-Q76',
  'F3-Q77',
  'F3-Q78',
  'F3-Q79',
];
export const FOR_REFERRAL_VAL_2 = [
  'F2-Q47',
  'F2-Q48',
  'F2-Q49',
  'F2-Q50',
  'F2-Q51',
  'F3-Q80',
  'F3-Q81',
  'F3-Q82',
  'F3-Q83',
];

export const MEDICAL_FORM_MEDS = [
  'F2-Q60',
  'F2-Q61',
  'F2-Q62',
  'F2-Q63',
  'F2-Q64',
  'F2-Q68',
];
export const MENTAL_FORM_MEDS = [
  'F3-Q67',
  'F3-Q90',
  'F3-Q91',
  'F3-Q92',
  'F3-Q93',
  'F3-Q94',
];

export const CONSULTATION_INTERMEDIARY = ['F2-Q10', 'F3-Q10'];

export const MED_QUESTS = ['F2-Q72', 'F3-Q98'];

export const FORM_OPTIONS = ['F2-Q61', 'F3-Q90'];
export const ROUTE_OPTIONS = ['F2-Q63', 'F3-Q92'];


export const FORM_IDS_FOR_PREFILL = [
  'medical-encounter-form',
  'mental-health-encounter-form',
];
