import { Table } from 'semantic-ui-react';
import cx from 'classnames';

import { dateTimeFormat, momentSg } from 'helpers/dateTime';
import { Text, CheckBox } from 'components';

const LegacyRow = ({
  result,
  isMobile,
  onRowClick,
  onRowSelect,
  hasPatientColumn,
  selectedRows,
  customUrlClient,
  clientInformation
}) => {
  return (
    <Table.Row
      key={result.id}
      className={cx({
        'table-row': !isMobile,
        'table-row--unread': result.isRead,
      })}
      onClick={(e) => onRowClick(e, result)}
    >
      {!isMobile && (
        <Table.Cell
          style={{ width: '30px' }}
          onClick={(e) => onRowSelect(e, result.id, result)}
        >
          <div>
            <CheckBox
              className="results-table__checkbox"
              checked={!!selectedRows[result.id]}
            />
          </div>
        </Table.Cell>
      )}
      {hasPatientColumn && (
        <Table.Cell>
          <Text dark={!result.isRead} size="small">
            {result.patient?.fullName}
          </Text>
        </Table.Cell>
      )}
      <Table.Cell>
        <Text size="small">{result.patient.ssn || result.patient.passNo}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text size="small">{result.order?.id}</Text>
      </Table.Cell>

      <Table.Cell>
        <Text size="small" uppercase dark={!result.isRead} bold={isMobile}>
          <div className="results-table__display">
            <div className="results-table__display__text">
              {result?.testProfile || result.pdf?.at(0)?.type}
            </div>
          </div>
        </Text>
      </Table.Cell>
      {clientInformation.type === 'lab' && (
        <Table.Cell>
          <Text size="small" uppercase dark={!result.isRead}>
            {result.clinic?.name}
          </Text>
        </Table.Cell>
      )}
      {/* {!isMobile && (
  <Table.Cell>
    <Text size="small" uppercase dark={!result.isRead}>
      {result.code}
    </Text>
  </Table.Cell>
)} */}
      <Table.Cell>
        <Text size="small">
          {momentSg(result.reportedDate).format(dateTimeFormat)}
        </Text>
      </Table.Cell>
      {!isMobile && (
        <Table.Cell>
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'left',
            }}
          >
            <div
              className={cx('results-table__status-dot', {
                'results-table__status-dot--green':
                  result.status === 'final' || result.status === 'completed',
                'results-table__status-dot--red':
                  result.status !== 'final' &&
                  result.status !== 'corrected' &&
                  result.status !== 'completed',
                'results-table__status-dot--corrected':
                  result.status === 'corrected',
              })}
              style={{ marginRight: '7px', marginLeft: '3px' }}
            />
            <Text size="small" uppercase>
              {result.status}
            </Text>
          </div>
        </Table.Cell>
      )}
      {customUrlClient !== 'sIdzwYsvwehphIHNG2ce' && (
        <Table.Cell>
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'left',
            }}
          >
            <div
              className={cx('results-table__status-dot', {
                'results-table__status-dot--red':
                  result.anomalyStatus === 'abnormal',
                'results-table__status-dot--green':
                  result.anomalyStatus !== 'abnormal',
              })}
              style={{ marginRight: '7px', marginLeft: '3px' }}
            />
            <Text size="small" uppercase>
              {result.anomalyStatus}
            </Text>
          </div>
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default LegacyRow;
