import React from 'react';
import moment from 'moment';

import './singPDT.scss';

const SingRapidTest = (props) => {
  const qrCodes = props.certificate.qrCodes
    ? JSON.parse(props.certificate.qrCodes)
    : null;

  const getText = (subType) => {
    if (subType === 'PA_SG_ANTI_RAPID_TEST')
      return 'Professionally Administered ';
    if (subType === 'RMSP_SG_ANTI_RAPID_TEST') return 'Remotely Supervised ';
    if (subType === 'SP_SG_ANTI_RAPID_TEST') return 'Supervised ';
    return '';
  };

  return (
    <div className="cert-wrapper" ref={props.certRef}>
      <div className="sing-pdt">
        {props.certificate.logoImage && (
          <div className="sing-pdt__logo-wrapper">
            <img
              className="sing-pdt__logo-wrapper__logo"
              src={props.certificate.logoImage}
              alt="logo"
            />
          </div>
        )}
        <h1 className="sing-pdt__header" style={{ marginBottom: '30px' }}>
          {props.certificate.name}
        </h1>

        <section>
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Name of Person:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.holderName}
            </div>
          </div>
          {props.certificate.passNo && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">Passport Number:</div>
              <div className="sing-pdt__row__data">
                {props.certificate.passNo}
              </div>
            </div>
          )}
          {props.certificate.ssn && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">NRIC / FIN Number:</div>
              <div className="sing-pdt__row__data">{props.certificate.ssn}</div>
            </div>
          )}
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Nationality:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.nationality}
            </div>
          </div>
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Date of Birth:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.issuedAt < '2021-02-18T09:03:00.000Z'
                ? moment(props.certificate.dateOfBirth).format('DD MMMM YYYY')
                : moment
                    .utc(props.certificate.dateOfBirth)
                    .format('DD MMMM YYYY')}
            </div>
          </div>
        </section>

        <section className="sing-pdt__info-text">
          <p>To whom it may concern,</p>
          <p>
            The above-mentioned has undergone{' '}
            {getText(props.certificate.subType)}
            Antigen Rapid Testing for COVID-19 using a{' '}
            {props.certificate.transportName} on{' '}
            {moment
              .utc(props.certificate.collectionDate)
              .clone()
              .tz('Asia/Singapore')
              .format('DD MMMM YYYY')}{' '}
            at{' '}
            {moment
              .utc(props.certificate.collectionDate)
              .clone()
              .tz('Asia/Singapore')
              .format('HH:mm')}{' '}
            SGT by {props.certificate.issuerName} and has tested{' '}
            <span className="sing-pdt__info-text__result">
              {props.certificate.result}.
            </span>
          </p>
          <p>
            This test result was conducted using{' '}
            {props.certificate.additionalData &&
              JSON.parse(props.certificate.additionalData).name}{' '}
            Rapid Antigen Test Kit on{' '}
            {moment
              .utc(props.certificate.issuedAt || props.certificate.createdAt)
              .clone()
              .tz('Asia/Singapore')
              .format('DD MMMM YYYY')}
            ,{' '}
            {moment
              .utc(props.certificate.issuedAt || props.certificate.createdAt)
              .clone()
              .tz('Asia/Singapore')
              .format('HH:mm')}{' '}
            SGT.
          </p>
          {/* <p>
            The{' '}
            {props.certificate.additionalData &&
              JSON.parse(props.certificate.additionalData).name}{' '}
            Rapid Antigen Test has a{' '}
            {props.certificate.additionalData &&
              JSON.parse(props.certificate.additionalData).accuracy}{' '}
            specificity for SARS-CoV-2.
          </p> */}
          <p>Regards</p>
        </section>
        <div
          className="sing-pdt__signature-wrapper"
          style={{ marginTop: '70px' }}
        >
          {props.certificate.physicianSignature ? (
            <img
              className="sing-pdt__signature-wrapper__sig"
              src={props.certificate.physicianSignature}
              alt="signature"
            />
          ) : (
            <div />
          )}
          {props.certificate.stampImage && (
            <img
              className="sing-pdt__stamp"
              src={props.certificate.stampImage}
              alt="stamp"
            />
          )}
        </div>
        <section className="sing-pdt__bottom-names">
          <p>
            <span style={{ fontWeight: 'bold' }}>Name of Doctor:</span>{' '}
            {props.certificate.physicianName}
            <br />
            <span style={{ fontWeight: 'bold' }}>MCR No.:</span>{' '}
            {props.certificate.physicianLicense}
          </p>
        </section>

        {props.certificate.qrCodeImage &&
          (!qrCodes ||
            Object.keys(qrCodes).length === 1 ||
            (qrCodes && Object.keys(qrCodes).length > 1 && !qrCodes.EU)) && (
            <div className="sing-pdt__qr-code">
              <img
                className="sing-pdt__qr-code__img"
                src={props.certificate.qrCodeImage}
                alt="qrcode"
              />
            </div>
          )}
        {qrCodes && Object.keys(qrCodes).length > 1 && qrCodes.EU && (
          <div className="sing-pdt__qr-codez">
            <div className="sing-pdt__qr-code-wrapper">
              <div className="sing-pdt__qr-code-wrapper__qr">
                <img
                  className="sing-pdt__qr-code-wrapper__qr__img"
                  src={
                    'https://riverr-trustroot.s3-ap-southeast-1.amazonaws.com/' +
                    qrCodes.EU
                  }
                  alt="qrcode"
                />
                <div className="sing-pdt__qr-code-wrapper__text">
                  Offline QR code (EU DCC-compatible)
                </div>
                <div className="sing-pdt__qr-code-wrapper__sub-text">
                  Requires a DCC QR scanner. Supported by the European Union
                  (EU) this may be used for public health measures beyond travel
                  within the EU.
                </div>
              </div>
              <div className="sing-pdt__qr-code-wrapper__qr">
                <img
                  className="sing-pdt__qr-code-wrapper__qr__img"
                  src={
                    'https://riverr-trustroot.s3-ap-southeast-1.amazonaws.com/' +
                    qrCodes.SG
                  }
                  alt="qrcode"
                />
                <div className="sing-pdt__qr-code-wrapper__text">
                  Singapore HealthCert
                </div>
              </div>
            </div>
          </div>
        )}
        {props.certificate.watermarkImage && (
          <img
            className="sing-pdt__watermark"
            src={props.certificate.watermarkImage}
            alt="watermark"
          />
        )}
      </div>
    </div>
  );
};

export default SingRapidTest;
