import { apolloClient } from '../apollo/apollo';
import {
  certificateTemplate,
  getCertificate,
  getCertificatesByClientId,
  publicDocAuthentication,
  getCertificatesCountInMonth,
  getCertificatesByUser,
  getMIDImage,
  getSignedFile,
  getBulkSignedFile,
  getCertificatesByCourseId,
  getOpenCert,
  getAntigenTypes,
  getCertLog,
  getVaccineCerts,
  getUserDocuments,
  getFailedReq,
  getDocumentTypes,
  getIssuedDocsRange,
  getCertificatesCountInRange,
  sendDocumentEmail,
} from './graphql/queries/certificates';
import {
  issueCertificate,
  issuePemeCertificate,
  revokeCertificate,
  bulkIssueCertificate,
  generatePdf,
  issueVaccineCert,
  notariseDoc,
  bulkIssueLabResults,
} from './graphql/mutations/certificates';

export default {
  async getAllCertificatesByUserId(userId) {
    return apolloClient.query({
      query: getCertificatesByUser,
      fetchPolicy: 'no-cache',
      variables: {
        userId,
      },
    });
  },

  async publicDocAuthentication(certId, password, login) {
    return apolloClient.query({
      query: publicDocAuthentication,
      fetchPolicy: 'no-cache',
      variables: {
        certId,
        password,
        login,
      },
    });
  },

  async getCertificatesByClientId(
    clientId,
    centerId,
    filter,
    limit,
    offset,
    fromDate,
    toDate,
    subType
  ) {
    return apolloClient.query({
      query: getCertificatesByClientId,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        centerId,
        filter,
        limit,
        offset,
        fromDate,
        toDate,
        subType,
      },
    });
  },

  async getCertificatesCountInMonth(
    clientId,
    startMonth,
    endMonth,
    subType,
    isClientGroup
  ) {
    return apolloClient.query({
      query: getCertificatesCountInMonth,
      fetchPolicy: 'no-cache',
      variables: { clientId, startMonth, endMonth, subType, isClientGroup },
    });
  },

  async getIssuedDocsRange(clientId, startDate, endDate, subType) {
    return apolloClient.query({
      query: getIssuedDocsRange,
      fetchPolicy: 'no-cache',
      variables: { clientId, startDate, endDate, subType },
    });
  },

  async getCertificatesCountInRange(clientId, startDate, endDate) {
    return apolloClient.query({
      query: getCertificatesCountInRange,
      fetchPolicy: 'no-cache',
      variables: { clientId, startDate, endDate },
    });
  },

  async getCertificatesByCourseId(courseId) {
    return apolloClient.query({
      query: getCertificatesByCourseId,
      fetchPolicy: 'no-cache',
      variables: {
        courseId,
      },
    });
  },

  async getVaccineCerts(clientId) {
    return apolloClient.query({
      query: getVaccineCerts,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
      },
    });
  },

  // Don't change fetchPolicy
  async getCertificate(certificateId) {
    return apolloClient.query({
      fetchPolicy: 'no-cache',
      query: getCertificate,
      variables: {
        id: certificateId,
      },
    });
  },

  async getOpenCert(certificateId) {
    return apolloClient.query({
      query: getOpenCert,
      variables: {
        id: certificateId,
      },
    });
  },

  async onRemoveCertificate(certificateId) {
    return apolloClient.mutate({
      mutation: revokeCertificate,
      variables: {
        certificateId,
      },
    });
  },

  async getCertificateTemplate(certificateTemplateId) {
    return apolloClient.query({
      query: certificateTemplate,
      variables: {
        certificateTemplateId,
      },
    });
  },

  async getMIDImage(imageKey) {
    return apolloClient.query({
      query: getMIDImage,
      variables: {
        imageKey,
      },
    });
  },

  async getSignedFile(filePath) {
    return apolloClient.query({
      query: getSignedFile,
      fetchPolicy: 'no-cache',
      variables: {
        filePath,
      },
    });
  },

  async sendDocumentEmail(clientId, certId, email, ccEmails) {
    return apolloClient.query({
      query: sendDocumentEmail,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        certId,
        email,
        ccEmails,
      },
    });
  },

  async getBulkSignedFile(filePath) {
    return apolloClient.query({
      query: getBulkSignedFile,
      fetchPolicy: 'no-cache',
      variables: {
        filePath,
      },
    });
  },

  async getCertLog(certId) {
    return apolloClient.query({
      query: getCertLog,
      variables: {
        certId,
      },
    });
  },

  async generatePdf(userId, certId, clientId, format) {
    return apolloClient.query({
      query: generatePdf,
      fetchPolicy: 'no-cache',
      variables: {
        userId,
        certId,
        clientId,
        format,
      },
    });
  },

  async onIssueCert(certificate) {
    return apolloClient.mutate({
      mutation: issueCertificate,
      variables: certificate,
    });
  },

  async getUserDocuments(userId) {
    return apolloClient.query({
      query: getUserDocuments,
      variables: { userId },
    });
  },

  async getFailedReq(id) {
    return apolloClient.query({
      query: getFailedReq,
      fetchPolicy: 'no-cache',
      variables: { id },
    });
  },

  async getDocumentTypes() {
    return apolloClient.query({
      query: getDocumentTypes,
    });
  },

  async getDocumentTypesByUserType(type) {
    return apolloClient.query({
      query: getDocumentTypes,
      variables: { type },
    });
  },

  async issueVaccineCert(certificates) {
    return apolloClient.mutate({
      mutation: issueVaccineCert,
      variables: certificates,
    });
  },

  async issuePemeCertificate(payload) {
    return apolloClient.mutate({
      mutation: issuePemeCertificate,
      variables: payload,
    });
  },

  async bulkIssueCertificates(certificates, clientId) {
    return apolloClient.mutate({
      mutation: bulkIssueCertificate,
      variables: { certificates, clientId },
    });
  },

  async getAntigenTypes() {
    return apolloClient.mutate({
      mutation: getAntigenTypes,
    });
  },

  async notariseDoc(id) {
    return apolloClient.mutate({
      mutation: notariseDoc,
      variables: { id },
    });
  },

  async bulkIssueLabResults(payload) {
    return apolloClient.mutate({
      mutation: bulkIssueLabResults,
      variables: payload,
    });
  },
};
