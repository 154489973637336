import React from 'react';

const SvgAngleDown = props => (
  <svg width="1em" height="1em" viewBox="0 0 27 14" {...props}>
    <path
      d="M23 3l-9.331 8.398a1 1 0 01-1.338 0L3 3"
      stroke={props.color}
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
);

SvgAngleDown.defaultProps = {
  color: '#1b1b1b',
};

export default SvgAngleDown;
