import React from 'react';
import moment from 'moment';

import './twPdt.scss';

const SingPDT = props => {
  const extraData = props.certificate.extraData
    ? JSON.parse(props.certificate.extraData)
    : null;

  const getChTransport = () => {
    if (props.certificate.transportName === 'Nasopharyngeal swab')
      return '鼻咽部';
    if (props.certificate.transportName === 'Oropharyngeal swab')
      return '口咽部';
    if (props.certificate.transportName === 'Sputum specimen') return '痰液';
    return '';
  };

  return (
    <div className="cert-wrapper" ref={props.certRef}>
      <div className="tw-pdt">
        {props.certificate.logoImage && (
          <div className="tw-pdt__logo-wrapper">
            <img
              className="tw-pdt__logo-wrapper__logo"
              src={props.certificate.logoImage}
              alt="logo"
            />
          </div>
        )}
        <h1 className="tw-pdt__header">
          <span className="tw-pdt__ch">新冠病毒核酸檢驗報告</span> COVID19
          RT-PCR TEST REPORT
        </h1>
        <h1 className="tw-pdt__header-bottom">
          <span className="tw-pdt__ch">反轉錄聚合酶連鎖反應</span> Reverse
          Transcription-PCR, RT-PCR
        </h1>

        <section>
          <div className="tw-pdt__row mr-3 ml-3">
            <div className="tw-pdt__data tw-pdt__data--full">
              <div className="tw-pdt__data__label">
                <span className="tw-pdt__ch">中文姓名</span> Chinese Name :
              </div>
              <div className="tw-pdt__data__data">
                <span className="tw-pdt__ch">
                  {extraData && extraData.name ? extraData.name : ''}
                </span>
              </div>
            </div>
            <div className="tw-pdt__data tw-pdt__data--full">
              <div className="tw-pdt__data__label">
                <span className="tw-pdt__ch">英文姓名</span> English Name :
              </div>
              <div className="tw-pdt__data__data">
                {props.certificate.holderName}
              </div>
            </div>
            <div className="tw-pdt__data">
              <div className="tw-pdt__data__label">
                <span className="tw-pdt__ch">性別</span> Gender :
              </div>
              <span className="tw-pdt__data__data tw-pdt__ch">
                {props.certificate.gender === 'Male' ? '男' : '女'}{' '}
                {props.certificate.gender}
              </span>
            </div>
            <div className="tw-pdt__data">
              <div className="tw-pdt__data__label">
                <span className="tw-pdt__ch">出生年月日</span> Date of Birth :
              </div>
              <div className="tw-pdt__data__data">
                {moment.utc(props.certificate.dateOfBirth).format('YYYY/MM/DD')}
              </div>
            </div>
            <div className="tw-pdt__data">
              <div className="tw-pdt__data__label">
                <span className="tw-pdt__ch">身份證/居留證號</span> I.D. No./ARC
                No. :
              </div>
              <div className="tw-pdt__data__data">{props.certificate.ssn}</div>
            </div>
            <div className="tw-pdt__data">
              <div className="tw-pdt__data__label">
                <span className="tw-pdt__ch">護照號碼</span> Passport No. :
              </div>
              <div className="tw-pdt__data__data">
                {props.certificate.passNo}
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="tw-pdt__test-info">
            <div className=" tw-pdt__row pl-3 pr-3 pt-2 pb-2">
              <div className="tw-pdt__data-box">
                <div className="tw-pdt__data-box__label">
                  <span className="tw-pdt__ch">檢體編號</span> Sample No. :
                </div>
                <div className="tw-pdt__data-box__data">
                  {props.certificate.acsn}
                </div>
              </div>
              <div className="tw-pdt__data-box">
                <div className="tw-pdt__data-box__label">
                  <span className="tw-pdt__ch">採檢日期</span> Sampling Date :
                </div>
                <div className="tw-pdt__data-box__data">
                  {moment
                    .utc(props.certificate.collectionDate)
                    .clone()
                    .tz('Asia/Taipei')
                    .format('YYYY/MM/DD HH:mm')}
                </div>
              </div>
              <div className="tw-pdt__data-box">
                <div className="tw-pdt__data-box__label">
                  <span className="tw-pdt__ch">採檢來源</span> Sample Collection
                  :
                </div>
                <div className="tw-pdt__data-box__data">
                  <span className="tw-pdt__ch">{getChTransport()}</span>
                  {props.certificate.transportName}
                </div>
              </div>
              <div className="tw-pdt__data-box">
                <div className="tw-pdt__data-box__label">
                  <span className="tw-pdt__ch">報告日期</span> Publish Date :
                </div>
                <div className="tw-pdt__data-box__data">
                  {moment
                    .utc(props.certificate.issuedAt)
                    .clone()
                    .tz('Asia/Taipei')
                    .format('YYYY/MM/DD HH:mm')}
                </div>
              </div>
            </div>
            <div className="tw-pdt__test-info__highlight">
              <span className="tw-pdt__ch">檢驗結果</span> Result of
              Quantitative Real time RT PCR assay
            </div>
            <div
              className={`tw-pdt__test-info__result ${
                props.certificate.result !== 'Positive'
                  ? 'tw-pdt__test-info__result--green'
                  : 'tw-pdt__test-info__result--red'
              }`}
            >
              {props.certificate.result !== 'Positive'
                ? 'Negative'
                : `Positive CT=${extraData ? extraData.ct : ''}`}
            </div>
          </div>
          <div className="tw-pdt__test-info__legend">
            <span className="tw-pdt__ch">備註</span> Note : CT=Cycle Threshold
            Value
          </div>
        </section>

        <section className="tw-pdt__info-text">
          <div className="tw-pdt__ch">說明 :</div>
          <div className="tw-pdt__ch">
            採檢結果判定：如為「Negative」，表示未偵測到新型冠狀病毒(SARS-CoV-2)。如為「Positive」，表示偵測到新型冠狀病毒(SARS-CoV-2)，將立即通報衛生主管機關進行處置
          </div>
          <div>Description :</div>
          <div>
            The result of examination shows &nbsp;&nbsp;“Negative”&nbsp;&nbsp;
            means SARS CoV 2 is undetected. The result of examination shows
            &nbsp;&nbsp;“Positive”&nbsp;&nbsp; means SARS CoV 2 is detected, the
            medical institution will inform the health authority immediately.
          </div>
        </section>
        <section>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              marginBottom: '35px',
            }}
          >
            <div className="tw-pdt__signature-wrapper">
              <div className="tw-pdt__ch">醫事檢驗師 :</div>
              <div>Signature of Medical Technologist :</div>
              <img
                className="tw-pdt__signature-wrapper__sig"
                src={props.certificate.physicianSignature}
                alt="signature"
              />
            </div>
            {props.certificate.stampImage && (
              <img
                className="tw-pdt__stamp"
                // src={props.certificate.stampImage}
                src={props.certificate.stampImage}
                alt="stamp"
              />
            )}
          </div>
        </section>

        <section>
          <div className="tw-pdt__footer-line">
            <span className="tw-pdt__ch">
              事檢驗所 Medical Institution :{' '}
              <span className="tw-pdt__ch">
                {props.certificate.additionalData}
              </span>
            </span>
          </div>
          <div className="tw-pdt__footer-line">
            <span className="tw-pdt__ch">事檢驗所地址</span> Address of Medical
            Institution :{' '}
            <span className="tw-pdt__ch">
              {props.certificate.issuerAddress}
            </span>
            <div>{props.certificate.location2}</div>
          </div>
          <div className="tw-pdt__footer-line">
            <span className="tw-pdt__ch">連絡話</span> :{' '}
            {props.certificate.telephone}
          </div>
        </section>

        {props.showQrCode && (
          <div className="tw-pdt__qr-code">
            <img
              className="tw-pdt__qr-code__img"
              src={props.certificate.qrCodeImage}
              alt="qrcode"
            />
          </div>
        )}
        {props.certificate.watermarkImage && (
          <img
            className="tw-pdt__watermark"
            src={props.certificate.watermarkImage}
            alt="watermark"
          />
        )}
      </div>
    </div>
  );
};

export default SingPDT;
