import {
  CardContent,
  CardHeader,
  CardLogo,
  CardLogoImage,
  CardNotice,
  CardTestType,
  ExtraContent,
  ExtraCost,
  ExtraCurrency,
  ExtraPrice,
  ExtraPriceTag,
  ExtraPriceWrapper,
  ExtraTotal,
  Extras,
  PackageTestHeader,
  PackageTestItem,
  PackageTestItemCatName,
  PackageTestItemDetials,
  PackageTestItemDetialsDot,
  PackageTestItemDetialsName,
  PackageTestToggle,
  PackageTestToggleDivider,
  PackageTestWrapper,
  PriceDescription,
  StyledLink,
  ExtraContainer,
  CardWrapper,
  CardNoticeToggle,
  CardTitle,
  CardTitleText,
} from './styles';
import { Icon, Popup, Transition } from 'semantic-ui-react';
import React, { useState } from 'react';
import { groupBy, mapValues, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { updateCart } from 'pages/webAppointments/scheduleAppointment/store/webAppointments.actions';
import SvgCardDropdown from 'assets/icons/CardDropdown';

const AppointmentCard = (props) => {
  const dispatch = useDispatch();
  const clientInfo = useSelector((state) => state.general.clientInformation);
  const [toggleTests, setToggleTests] = useState(false);
  const [toggleCardNotice, setToggleCardNotice] = useState(false);

  const categoryList =
    props.homeAppointmentsTestType?.packageTests?.length &&
    mapValues(
      groupBy(props.homeAppointmentsTestType.packageTests, 'category'),
      (_) => _.map((t) => omit(t, 'category'))
    );
  const isMultiple = props.homeAppointmentsTestType?.packageType === 'multiple';

  const onSelect = () => {
    dispatch(updateCart(props.homeAppointmentsTestType));
    if (props.closeModal) props.closeModal();
  };

  return (
    <CardWrapper>
      <CardContent bgColor={props.homeAppointmentBgColor}>
        <CardTitle>
          <StyledLink
            onClick={() => onSelect()}
            to={`/schedule`}
            key={props.homeAppointmentsTestType?.id}
          >
            {props?.homeAppointmentsTestType?.name && (
              <CardTitleText>
                <CardTestType>
                  <span>
                    {isMultiple
                      ? 'Package'
                      : `${props.homeAppointmentsTestType?.type}`}
                  </span>
                </CardTestType>
                <CardHeader>{props?.homeAppointmentsTestType?.name}</CardHeader>
              </CardTitleText>
            )}
          </StyledLink>
          {props.homeAppointmentsNotice && (
            <CardNoticeToggle
              onClick={() => setToggleCardNotice(!toggleCardNotice)}
              flipped={toggleCardNotice}
            >
              <SvgCardDropdown />
            </CardNoticeToggle>
          )}
        </CardTitle>
        {props.homeAppointmentsNotice && (
          <Transition
            visible={toggleCardNotice}
            unmountOnHide
            animation={toggleCardNotice ? 'fade up' : ''}
            duration={toggleCardNotice ? 500 : 0}
          >
            <CardNotice>
              {props.homeAppointmentsTestType?.description}
            </CardNotice>
          </Transition>
        )}

        {isMultiple && Object.keys(categoryList).length > 0 ? (
          <>
            <PackageTestToggleDivider borderColor={'#1fa97f'}>
              <PackageTestToggle
                onClick={() => setToggleTests(!toggleTests)}
                background={'#1fa97f'}
              >
                View Tests
              </PackageTestToggle>
            </PackageTestToggleDivider>

            <Transition
              visible={toggleTests}
              unmountOnHide
              animation={toggleTests ? 'fade up' : ''}
              duration={toggleTests ? 500 : 0}
            >
              <PackageTestWrapper>
                <PackageTestHeader>Tests conducted</PackageTestHeader>
                {Object.keys(categoryList).map((categoryKey) => (
                  <PackageTestItem key={categoryKey}>
                    <PackageTestItemCatName>
                      {categoryKey}
                    </PackageTestItemCatName>
                    {categoryList[categoryKey].map((test) => (
                      <PackageTestItemDetials key={test.id}>
                        <PackageTestItemDetialsDot color={'#1fa97f'} />
                        <PackageTestItemDetialsName>
                          {test.name}
                        </PackageTestItemDetialsName>
                      </PackageTestItemDetials>
                    ))}
                  </PackageTestItem>
                ))}
              </PackageTestWrapper>
            </Transition>
          </>
        ) : (
          ''
        )}
      </CardContent>
      <ExtraContainer>
        <ExtraContent>
          <ExtraTotal>
            {props.homeAppointmentsTotalText && props.homeAppointmentsTotalText}
          </ExtraTotal>
          <ExtraPriceWrapper>
            <ExtraPriceTag>
              {props.homeAppointmentsTestType?.price && (
                <ExtraPrice>
                  {`${Number(props.homeAppointmentsTestType?.price).toFixed(
                    2
                  )} ${props?.currency || 'SGD'}`}
                </ExtraPrice>
              )}
              {props.homeAppointmentsDescription && clientInfo?.taxI && (
                <PriceDescription>
                  ({props.homeAppointmentsDescription})
                </PriceDescription>
              )}
            </ExtraPriceTag>
          </ExtraPriceWrapper>
        </ExtraContent>
        <Extras>
          {!!props.homeAppointmentsTestType?.extraFee && (
            <ExtraCost>
              {`Extra Fee: ${Number(
                props.homeAppointmentsTestType?.extraFee
              ).toFixed(2)} ${props?.currency || 'SGD'}
            `}{' '}
              <Popup
                className="extra-fee__popup"
                trigger={<Icon circular name="info" size="small" />}
                content={props.homeAppointmentsTestType?.extraFeeDesc}
              />
            </ExtraCost>
          )}
        </Extras>
      </ExtraContainer>
    </CardWrapper>
  );
};

export default AppointmentCard;
