import React from 'react';
import styled from 'styled-components';

const Page = props => {
  return <PageContent style={props.style}>{props.children}</PageContent>;
};

const PageContent = styled.div`
  position: relative;
  margin: auto;
  max-width: 1400px;
  min-width: 800px;
  margin-top: 15px;
`;

export default Page;
