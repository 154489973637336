import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Icon, Dropdown, Popup } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import downloadFile from 'js-file-download';
import moment from 'moment';
import JSZip from 'jszip';
import { useSelector } from 'react-redux';

import TableRow from './RhdTableRow';

import patientReactSessionApi from 'api/patientReactSession';
import pdfnetApi from 'api/pdfnetApi/pdfnetApi';
import sessionApi from 'api/reactSession';

import {
  Text,
  Button,
  ErrorToast,
  CheckBox,
  SuccessToast,
  Search,
} from 'components';
import PatientModal from 'pages/reactHealthPages/ReachHealthOverview/components/PatientModal/PatientModal';

import fileCheckSvg from 'assets/new/blackFileCheck.svg';
import airplaneSvg from 'assets/new/blackPaperAirplane.svg';
import resendSvg from 'assets/new/resendBlack.svg';
import downloadSvg from 'assets/new/download.svg';
import locationSvg from 'assets/new/blackTestTube.svg';

import history from '../../../../history';

import '../reactHealthDetails.scss';

const ReactHealthDetails = ({ session, patientId, patientDetails, hasCreate }) => {
  let urlSearchParams = new URLSearchParams(window.location.search);
  const defaultSearchParams = {
    searchTerm: urlSearchParams.get(`rs-search-term`) || '',
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [filteredParts, setFilteredParts] = useState([]);
  const [participantHealthSessions, setParticipantHealthSessions] = useState(
    []
  );
  const [participants, setParticipants] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [searchValue, setSearchValue] = useState(
    defaultSearchParams.searchTerm || ''
  );
  const [selectedTestStatus, setSelectedTestStatus] = useState('all');
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const [isExternal, setIsExternal] = useState(false);
  const [isPatientModalToggled, togglePatientReactModal] = useState(false);

  useEffect(() => {
    const path = history.location.pathname;
    if(path.includes('/external/')) setIsExternal(true);
  }, []);
  
  useEffect(() => {
    fetchParticipantSessions();
  }, [session, patientId]);

  useEffect(() => {
    onSearchChange();
  }, [searchParams]);

  useEffect(() => {
    let filtered = [];
    if (selectedTestStatus !== 'all') {
      const filteredParticipants = participants.filter(
        (sess) => selectedTestStatus === sess.status
      );
      filtered = filteredParticipants;
    } else filtered = participants;

    const matches = filtered.filter(
      (item) =>
        item?.patientName
          ?.toLowerCase()
          ?.includes(searchParams.searchTerm?.toLowerCase()) ||
        item?.result?.acc
          ?.toLowerCase()
          ?.includes(searchParams.searchTerm?.toLowerCase())
    );
    setFilteredParts(matches);
  }, [selectedTestStatus, participants]);

  useEffect(() => {
    const parts = participantHealthSessions.map((part) => {
      part.addOns =
        part.addOns && Array.isArray(part.addOns) ? part.addOns.join(', ') : '';
      let status = 'final';
      if (part.vitalsStatus === 'pending') status = 'pending vitals';
      else if (
        part.vitalsStatus === 'collected' &&
        part?.resultIds?.length === 0
      )
        status = 'pending results';
      else if (
        part.vitalsStatus === 'collected' &&
        part?.resultIds?.length > 0 &&
        !part.review.status
      )
        status = 'pending review';
      return { ...part, status };
    });
    setParticipants(parts);
  }, [participantHealthSessions]);

  const fetchParticipantSessions = async () => {
    if (!session) return fetchParticipantSessionsByPatId();
    const patientReactSessions =
      await patientReactSessionApi.getPatientReactSessions(session.id);
    setParticipantHealthSessions(patientReactSessions.data);
  };

  const fetchParticipantSessionsByPatId = async () => {
    if (!patientId) return;
    const patientReactSessions =
      await patientReactSessionApi.getPReactSessionByPat(patientId);
    setParticipantHealthSessions(patientReactSessions.data);
  };

  const onDownloadPdf = async () => {
    const sessIds = Object.keys(selectedRows);
    if (!sessIds.length)
      toast.error(<ErrorToast message="No sessions have a ReACT report" />);
    const promises = [];
    sessIds.forEach((id) => {
      if (selectedRows[id]?.result?.id) {
        promises.push(
          getPdfUrl(selectedRows[id]?.result?.id, selectedRows[id].patientName)
        );
      }
    });
    const resp = await Promise.all(promises);
    if (resp.length > 0) {
      await downloadIndividually(resp);
    } else {
      toast.error(<ErrorToast message="No sessions have a ReACT report" />);
    }
  };

  const getPdfUrl = async (url, holderName) => {
    const { data } = await pdfnetApi.getSmartPdfDownloadUrl(url);
    return { pdfPath: data, holderName };
  };

  const downloadIndividually = async (urls) => {
    const pdfZip = new JSZip();
    const numDocs = urls.length;
    for (var i = 0; i < numDocs; i++) {
      const donorPdfBytes = await fetch(urls[i].pdfPath).then((res) =>
        res.blob()
      );

      let name = `${urls[i].holderName}.pdf`;
      if (pdfZip.file(name)) name = name.replace('.pdf', ` (${i}).pdf`);
      pdfZip.file(name, donorPdfBytes, { binary: true });
    }

    pdfZip.generateAsync({ type: 'blob' }).then(function (content) {
      downloadFile(
        content,
        `${moment().format('DD-MM-YYYY HH:mm')} - documents.zip`
      );
    });
  };

  const updateSearchParams = (params) => {
    setSearchParams(params);
    let url = new URL(window.location.href);
    let searchParams = url.searchParams;

    if (JSON.stringify(searchParams) !== JSON.stringify(params)) {
      searchParams.set(`rs-search-term`, params.searchTerm || '');
      window.history.replaceState({ path: url.toString() }, '', url.toString());
    }
  };

  const onRowClick = (e, sess) => {
    if (e.metaKey || e.ctrlKey) {
      if (!session)
        return window.open(`/react-health/${sess.reactSessionId}`, '_blank');

      return window.open(`/react-health/patient/${sess.id}`, '_blank');
    }

    if (!session)
      return history.push({
        pathname: isExternal ? `/external/react-health/${sess.reactSessionId}` : `/react-health/${sess.reactSessionId}`,
      });
    history.push({
      pathname: isExternal ? `/external/react-health/patient/${sess.id}` : `/react-health/patient/${sess.id}`,
    });
  };

  const selectAllRows = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let rows;

    if (Object.keys(selectedRows)?.length === participants?.length) {
      setSelectedRows({});
      rows = {};
    } else {
      rows = {};
      participants.forEach((element) => {
        rows[element.id] = element;
      });
      setSelectedRows(rows);
    }
  };

  const onRowSelect = (e, id, patient) => {
    e.stopPropagation();
    e.preventDefault();

    if (selectedRows[id]) delete selectedRows[id];
    else
      selectedRows[id] = {
        patient,
      };

    setSelectedRows({ ...selectedRows });
  };

  const markAsReviewed = async () => {
    try {
      const sessionIds = Object.keys(selectedRows);
      if (sessionIds.length === 0) return;
      await patientReactSessionApi.bulkReviewReactSession(sessionIds);
      await fetchParticipantSessions();
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const bulkIssueReports = async () => {
    try {
      const sessionIds = Object.keys(selectedRows);
      if (sessionIds.length === 0) return;

      await Promise.all(
        sessionIds.map((id) => patientReactSessionApi.issueReport({ id, isSendEmail: true }))
      );
      toast.success(<SuccessToast message="ReACT Reports issued" />);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const reSendEmail = async () => {
    try {
      const sessionIds = Object.keys(selectedRows);
      if (sessionIds.length === 0) return;
      patientReactSessionApi.resendQuestionnaire(sessionIds);
      toast.success(<SuccessToast message="Emails sent" />);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const bulkDownloadReports = async () => {
    try {
      await onDownloadPdf();
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  // const syncPlatoResults = async () => {
  //   try {
  //     const sessionIds = Object.keys(selectedRows);
  //     sessionIds.forEach(id => {
  //       patientReactSessionApi.syncPlatoLabResult({
  //         token: clientInfo.platoApiKey,
  //         db: clientInfo.platoDatabase,
  //         pReactSessionId:  id,
  //       })
  //     })
  //     toast.success(<SuccessToast message="This can take some time" />);
  //   } catch (error) {
  //     toast.error(<ErrorToast error={error} />);
  //   }
  // };

  const updateValue = (id, value) => {
    const parts = participants.map((part) => {
      if (part.id === id) return { ...part, addOns: value };
      return part;
    });
    setFilteredParts(parts);
  };

  const updatePatientReactSession = async (id, data) => {
    try {
      const payload = { ...data };
      if (
        typeof payload.addOns === 'string' ||
        payload.addOns instanceof String
      ) {
        payload.addOns = payload.addOns.split(',');
      }
      await patientReactSessionApi.updatePatientReactSession(id, payload);
      await fetchParticipantSessions();
      toast.success(<SuccessToast message="Session updated" />, {
        autoClose: 800,
      });
    } catch (error) {
      toast.error(<ErrorToast error={error} />, {
        toastId: 'sess-err',
      });
    }
  };

  const onBlur = (id, session) => {
    let isSame = false;
    participants.forEach((part) => {
      if (part.id === id && session.addOns === part.addOns) isSame = true;
    });
    if (isSame) return;
    updatePatientReactSession(id, { addOns: session.addOns });
  };

  const onSearchChange = () => {
    const matches = participants.filter(
      (item) =>
        item?.patientName
          ?.toLowerCase()
          ?.includes(searchParams.searchTerm?.toLowerCase()) ||
        item?.result?.acc
          ?.toLowerCase()
          ?.includes(searchParams.searchTerm?.toLowerCase())
    );
    setFilteredParts(matches);
  };

  const createReactSession = async (data) => {
    try {
      await sessionApi.createReactSession(data);
      togglePatientReactModal(false);
      await fetchParticipantSessions();
    } catch (error) {
      toast.error(<ErrorToast message="Error creating session" />);
    }
  };

  return (
    <div className="rhd">
      {isPatientModalToggled && (
        <PatientModal
          show={isPatientModalToggled}
          hide={(e) => togglePatientReactModal(e)}
          patient={patientDetails}
          onCreate={createReactSession}
        />
      )}
      <div className="rhd__search">
        {!isMobile && (
          <div className="rhd__search__section">
            <Dropdown
              floating
              direction="right"
              icon={null}
              trigger={
                <div className="rho__dropdown">
                  <Button color="blue">
                    <Text id="react_single.actions" color="inherit">
                      Actions
                    </Text>
                    <Icon
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: '5px',
                      }}
                      name="dropdown"
                      color="white"
                      className="rho__dropdown__icon"
                    />
                  </Button>
                </div>
              }
            >
              <Dropdown.Menu>
                {session && (
                  <>
                    <Dropdown.Item
                      disabled={!Object.keys(selectedRows)?.length}
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => reSendEmail()}
                    >
                      <img
                        className="rho__dropdown-icon"
                        src={resendSvg}
                        alt="file-check"
                      />
                      <Text
                        id="react_single.resend_questionnaire"
                        size="small"
                        bold
                      >
                        Re-send questionnaire email
                      </Text>
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={!Object.keys(selectedRows)?.length}
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => markAsReviewed()}
                    >
                      <img
                        className="rho__dropdown-icon"
                        src={fileCheckSvg}
                        alt="file-check"
                      />
                      <Text
                        id="react_single.mark_as_reviewed"
                        size="small"
                        bold
                      >
                        Mark as reviewed
                      </Text>
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={!Object.keys(selectedRows)?.length}
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => bulkIssueReports()}
                    >
                      <img
                        className="rho__dropdown-icon"
                        src={airplaneSvg}
                        alt="file-check"
                      />
                      <Text id="react_single.issue_report" size="small" bold>
                        Issue Report
                      </Text>
                    </Dropdown.Item>
                  </>
                )}
                {/* <Dropdown.Item
                  disabled={!Object.keys(selectedRows)?.length}
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => syncPlatoResults()}
                >
                  <img
                    className="rho__dropdown-icon"
                    src={locationSvg}
                    alt="file-check"
                  />
                  <Text size="small" bold>
                    Sync Plato results
                  </Text>
                </Dropdown.Item> */}
                <Dropdown.Item
                  disabled={!Object.keys(selectedRows)?.length}
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => bulkDownloadReports()}
                >
                  <img
                    className="rho__dropdown-icon"
                    src={downloadSvg}
                    alt="file-check"
                  />
                  <Text id="react_single.download_report" size="small" bold>
                    Download report
                  </Text>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {hasCreate && <Button className="ml-3" size="fluid" inverted color="green" onClick={() => togglePatientReactModal(true)}>Create new ReACT Session</Button>}
          </div>
        )}
        <Search
          size="large"
          onSearchChange={(e, { value }) => {
            setSearchValue(value);
            updateSearchParams({ ...searchParams, searchTerm: value });
          }}
          value={searchParams.searchTerm}
          dropdown={false}
        />
      </div>
      <div className="rhd__table-wrapper">
        <Table selectable unstackable striped>
          <Table.Header>
            <Table.Row>
              {!isMobile && (
                <Table.HeaderCell className="rhd__th">
                  <div onClick={(e) => selectAllRows(e)}>
                    <CheckBox
                      className="rhd__checkbox"
                      checked={
                        Object.keys(selectedRows)?.length ===
                        participants?.length
                      }
                    />
                  </div>
                </Table.HeaderCell>
              )}
              <Table.HeaderCell>
                <Text bold id="react_single.patient">
                  Patient
                </Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <div style={{ display: 'flex' }}>
                  <Text bold id="react_grp.test_add-ons">
                    Test Add-ons
                  </Text>{' '}
                  <Popup
                    content={
                      <Text id="react_grp.comma_separate">
                        Comma separate add-ons. E.g: 'Add on 1, Add on 2'
                      </Text>
                    }
                    key={0}
                    position="top right"
                    trigger={<Icon name="info circle" fontSize="17px" />}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text bold id="react_grp.accession_id">
                  Accession ID
                </Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text bold id="react_grp.updated_at">
                  Updated At
                </Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text bold id="react_grp.recommended_action">
                  Recommended Action
                </Text>
              </Table.HeaderCell>
              {/* <>
                {!isMobile ? (
                  <Table.HeaderCell style={{ padding: '6px 10px' }}>
                    <Select
                      border
                      size="fullWidth"
                      placeholder="Filter by status"
                      value={selectedTestStatus}
                      onChange={(e, { value }) => setSelectedTestStatus(value)}
                      search
                      selection
                      options={allTestStatus}
                      selectClassName="sample-collection__select-text"
                    />
                  </Table.HeaderCell>
                ) : (
                  <Table.HeaderCell className="results-table__th">
                    Status
                  </Table.HeaderCell>
                )}
              </> */}
              <Table.HeaderCell>
                <Text bold id="react_grp.questionnaire">
                  Questionnaire
                </Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text bold id="react_grp.status">
                  Status
                </Text>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!!participants?.length &&
              filteredParts.map((participant) => (
                <TableRow
                  participant={participant}
                  onRowClick={onRowClick}
                  onRowSelect={onRowSelect}
                  selectedRows={selectedRows}
                  isMobile={isMobile}
                  updateValue={updateValue}
                  onBlur={onBlur}
                />
              ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default withRouter(ReactHealthDetails);
