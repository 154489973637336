import React from 'react';

const SvgLanguage = props => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#1b1b1b" strokeWidth={3}>
      <circle cx={16} cy={16} r={14} />
      <ellipse transform="rotate(90 16 16)" cx={16} cy={16} rx={14} ry={6} />
      <path d="M4.5 10.5h23.083M3.52 20.5h24.063" strokeLinecap="square" />
    </g>
  </svg>
);

export default SvgLanguage;
