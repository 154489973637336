import React from 'react';
import { Table } from 'semantic-ui-react';
import { Text } from 'components';

import './failedPatientCreations.scss';

const FailedPatientCreations = ({ data = [] }) => {
  const hasActionColumn = data.some( pat =>!!pat.action)
  const hasStatusColumn =  data.some( pat =>!!pat.status)
  const renderTableData = () => {
    return data.map((pat, i) => (
      <Table.Row key={i} className="table-row failed-p-c__row">
        <Table.Cell>{pat.firstName} {pat.lastName}</Table.Cell>
        <Table.Cell>{pat.email}</Table.Cell>
        {hasActionColumn && <Table.Cell>{pat.action}</Table.Cell>}
        {hasStatusColumn && <Table.Cell>{pat.status}</Table.Cell>}
        <Table.Cell>{pat.error}</Table.Cell>
      </Table.Row>
    ));
  };

  return (
    <div className="failed-p-c">
    <Text bold >The following patient status</Text>
    <Table className="failed-p-c__table" unstackable>
    <Table.Header>
        <Table.Row>
        <Table.HeaderCell>Patient</Table.HeaderCell>
        <Table.HeaderCell>Email</Table.HeaderCell>
        {hasActionColumn && <Table.HeaderCell>Action</Table.HeaderCell>}
        {hasStatusColumn && <Table.HeaderCell>Status</Table.HeaderCell>}
        <Table.HeaderCell>Error</Table.HeaderCell>
        </Table.Row>
    </Table.Header>
    <Table.Body>
        {renderTableData()}
    </Table.Body>
    </Table>
    </div>
  );
};

export default FailedPatientCreations;
