import { createAxiosInstance } from '../axiosInstance';
import store from '../../store';
// eslint-disable-next-line no-undef
const FHIR_URL = process.env.REACT_APP_API_V1_FHIR_END_POINT;
const BASE_API_URL = `${FHIR_URL}/fhir`;

const fhirClient = createAxiosInstance(BASE_API_URL, {
  timeout: 60000,
});

// Step-2: Create request, response & error handlers
const requestHandler = async (request) => {
  const clientId = store?.getState()?.general?.user?.currentClientId;
  if (clientId)
    request.headers['x-client-id'] = request.headers['x-client-id'] || clientId;
  if (
    localStorage.getItem('AUTH_TOKEN') &&
    JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token
  ) {
    request.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token
    }`;
  }
  return request;
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

fhirClient.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

export { fhirClient };
