import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { BreadCrumbTrail } from 'components';

import CompleteProfileTriggered from './components/completeProfileTriggered/completeProfileTriggered';
import CompleteProfile from './components/completeProfile/completeProfile';
import CompleteAddress from './components/completeAddress/completeAddress';
import EncouragementModal from './components/encouragementModal/encouragementModal';

import nationalities from 'utils/nationalities';
import ethnicities from 'utils/ethnicities';
import languages from 'helpers/languages';

import startCompleteProfileSvg from 'assets/rhc/completeProfile/start-profile-change.svg';

import history from '../../../history';

import './patientSignup.scss';

const PatientSignup = ({
  details,
  updateDetails,
  saveChanges,
  backPath,
  toggle,
  setToggle,
  isSaving,
}) => {
  const searchParams = new URLSearchParams(history.location.search);
  const step = searchParams.get('step');

  const [profileStep, setProfileStep] = useState(Number(step) || -1);

  useEffect(() => {
    const fieldsToCheck = [
      'ethnicity',
      'nationality',
      'ssn',
      'passNo',
      'language',
      'country',
      'address',
      'city',
      'zip',
      'phoneNumber',
    ];
    const hasAny = fieldsToCheck.some((field) => !!details[field]);
    if (hasAny && !Number(step)) setProfileStep(1);
    else if(!hasAny && !Number(step)) setProfileStep(0);
  }, []);

  const BreadCrumb = useCallback(() => {
    return <BreadCrumbTrail steps={2} activeIndex={profileStep} />;
  }, [profileStep]);

  const updateProfileStep = (step) => {
    history.push(`?step=${step}`);
    setProfileStep(step);
  };

  const onCompleteProfileNextClick = async () => {
    history.push(`?step=2`);
    await saveChanges();
  };
  const onCompleteAddressConfirmClick = async () => {
    await saveChanges();
    history.push('/settings');
  };

  return (
    <>
      {toggle && (
        <EncouragementModal
          isToggled={toggle}
          setToggled={setToggle}
          header="Superb!"
          headerSubText="Your information has been recorded."
          icon={startCompleteProfileSvg}
          text="Thank you for filling up the questionnaire. We have a better understanding of your health
          profile now."
          buttonText="Set up your emergency ID"
          onButtonClick={() => history.push('/settings/emer')}
        />
      )}

      {profileStep === 0 && (
        <CompleteProfileTriggered
          backPath={backPath}
          name={details.firstName}
          onButtonClick={() => updateProfileStep(1)}
        />
      )}
      {profileStep === 1 && (
        <CompleteProfile
          updateDetails={updateDetails}
          nationalities={nationalities}
          ethnicities={ethnicities}
          languages={languages}
          onNextClick={onCompleteProfileNextClick}
          details={details}
          isSaving={isSaving}
          breadCrumb={BreadCrumb()}
        />
      )}
      {profileStep === 2 && (
        <CompleteAddress
          updateDetails={updateDetails}
          nationalities={nationalities}
          onBackClick={() => history.push('/settings/profile')}
          onConfirmClick={onCompleteAddressConfirmClick}
          details={details}
          isSaving={isSaving}
          breadCrumb={BreadCrumb()}
        />
      )}
    </>
  );
};

export default withRouter(PatientSignup);
