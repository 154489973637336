import S3 from 'aws-s3-pro';
import { uploadModes } from '../constants';
window.Buffer = window.Buffer || require("buffer").Buffer;

// random comment
export const s3UploadMixin = {
  data: () => ({
    fileName: '',
    folder: null,
    rules: {
      required: (value) => !!value || 'Required.',
    },
  }),
  config() {
    //TODO: figure out issue with GCP cloudbuild not reading env vars
    return {
      bucketName: 'riverr-trustroot',
      dirName: this.folder,
      region: 'ap-southeast-1',
      accessKeyId: 'AKIAWPJZVTVQFWLGLDUV',
      secretAccessKey: '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',
      s3Url: 'https://riverr-trustroot.s3-ap-southeast-1.amazonaws.com',
    };
  },
  S3Client() {
    return new S3(this.config());
  },
  newFileName() {
    return Math.random().toString().slice(2);
  },
  url() {
    return `https://riverr-trustroot.s3-ap-southeast-1.amazonaws.com/${this.folder}/${this.newFileName}`;
  },
  removeFile(type) {
    const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);
    // this.$emit(`remove${typeCapitalized}`);
  },
  onFileChange(files, type) {
    this.uploadFileToS3(type, files);
  },
  uploadFileToS3(type = 'image', files, id) {
    const client = id || localStorage.getItem('DELTA_CLIENT_ID');
    const file = files;
    let folder = null;

    switch (type) {
      case uploadModes.certificateLogo:
        folder = `${client}/logos`;
        break;
      case uploadModes.certificateInstructorSignature:
        folder = `${client}/instructorSignatures`;
        break;
      case uploadModes.certificateIssuerSignature:
        folder = `${client}/issuerSignatures`;
        break;
      case uploadModes.certificateWatermark:
        folder = `${client}/watermark`;
        break;
      case uploadModes.certAttachments:
        folder = `${client}/certAttachments`;
        break;
      case uploadModes.newAppointmentClientLogo:
        folder = `${client}/newAppointmentClientLogo`;
        break;
      case uploadModes.patientProfileImage:
        folder = `${client}/patientProfileImage`;
        break;
      default:
        folder = `${client}/images`;
    }
    this.folder = folder;
    console.log('file', file);
    return this.S3Client().uploadFile(file, this.newFileName());
  },
};
