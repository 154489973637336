import gql from 'graphql-tag';

export const setLabResults = gql`
  mutation setLabResults(
    $clientId: ID!
    $clinicId: ID!
    $results: [LabResultPayload]
  ) {
    setLabResults(results: $results, clinicId: $clinicId, clientId: $clientId)
  }
`;

export const updateLabResult = gql`
  mutation updateLabResult($id: ID!, $result: LabResultPayload) {
    updateLabResult(id: $id, result: $result)
  }
`;
