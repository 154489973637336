import { DownloadPdfStatus } from 'constant/downloadPdfStatus';

export const isExportingPdf = certificate => {
  return (
    certificate.exportPdfStatus === DownloadPdfStatus.PENDING ||
    certificate.exportPdfStatus === DownloadPdfStatus.PROCESSING ||
    (certificate.exportPdfStatus === DownloadPdfStatus.SUCCESS &&
      !certificate.pdfPath)
  );
};

export const isExportedPdf = certificate => {
  return !!certificate?.pdfPath; //download when has pdfPath, ignore queue-job status.
  // && certificate.exportPdfStatus === DownloadPdfStatus.SUCCESS
};
