import {
  SET_PROFILE_SUMMARY,
  SET_RISK_SUMMARY,
  SET_BIOMAKER_VALUES,
} from './patient.actions';
  
  const initialState = {
    profileSummary: {},
    riskModelsSummary: {},
    biomakerValues: {},
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case SET_PROFILE_SUMMARY:
        return { ...state, profileSummary: action.payload };
      case SET_RISK_SUMMARY:
        return { ...state, riskModelsSummary: action.payload };
      case SET_BIOMAKER_VALUES:
        return { ...state, biomakerValues: action.payload };
      default:
        return state;
    }
  };
  