import { patientOverviewClient } from '../patientOverviewClient';
// import patientOverviewClient from '../../testAxios';

const baseUrl = 'medicalProfile/provider/medications';
async function addMedication(userId, medication) {
  const response = await patientOverviewClient.post(
    'medicalProfile/provider/create',
    {
      userId,
      medication,
    }
  );

  return response.data;
}

async function updateMedication(userId, medication) {
  const response = await patientOverviewClient.post(`${baseUrl}/update`, {
    ...medication,
    userId,
  });
  return response.data;
}

async function deleteMedication(userId, id) {
  const response = await patientOverviewClient.post(`${baseUrl}/delete`, {
    id,
    userId
  });
  return response.data;
}

const clientMedicationApi = {
  addMedication,
  updateMedication,
  deleteMedication,
};

export default clientMedicationApi;
