import { patientOverviewClient } from '../patientOverviewClient';
import memoize from 'lodash/memoize';

// import patientOverviewClient from '../../testAxios';

import clientBmiApi from './bmiApi';
import { calculateAge } from '../../../utils';
import pick from '../../../utils/pick';
import { sortByDateFunction } from '../../../utils/sort';

const getMedicalProfileWithCache = memoize(async (userId) => {
  try {
    const profileResponse = await patientOverviewClient.post(
      'medicalProfile/provider/getByUser',
      { userId: userId }
    );
    return profileResponse.data?.data?.profile;
  } catch (error) {
    console.error(error);
    return null;
  }
}, (userId) => userId, 5000); // 5000 ms = 5 seconds

async function getMedicalProfile(userId) {
  return getMedicalProfileWithCache(userId);
}

function getLatestVital(vitalList = []) {
  if(!vitalList.length) return null;
  const sortedList = vitalList.sort(sortByDateFunction);
  return sortedList[0];
}

function extractLatestHealthDetails(profile) {
  // TODO sort by date ////////////////////////////////

  const height = getLatestVital(profile?.bmiList)?.height?.value;
  const weight = getLatestVital(profile?.bmiList)?.weight?.value;
  const latestBp = getLatestVital(profile?.bloodPressureList);
  const latestPulseOx = getLatestVital(profile?.pulseOxList);
  const latestVisualAcuity = getLatestVital(profile?.visualAcuityList);

  const heartRate = latestBp?.heartRate || latestPulseOx?.heartRate;
  const { systolicUpperNumber, diastolicLowerNumber } = latestBp || {};
  const { pulseOximeter, perfusionIndex } = latestPulseOx || {};

  return {
    height,
    weight,
    heartRate,
    systolicUpperNumber,
    diastolicLowerNumber,
    pulseOximeter,
    perfusionIndex,
    visualAcuity: latestVisualAcuity,
  };
}

async function addMultipleValues({
  patient,
  weight,
  height,
  diastolicLowerNumber,
  systolicUpperNumber,
  heartRate,
  pulseOximeter,
  perfusionIndex,
  visualAcuity,
}) {
  let bmi;
  let bloodPressure;
  let pulseOx;
  if (patient && (weight || height)) {
    const { dateOfBirth, race, gender } = patient;
    const age = calculateAge(new Date(dateOfBirth));
    bmi = {
      height: {
        value: Number(height),
        unit: 'cm',
      },
      weight: {
        value: Number(weight),
        unit: 'kg',
      },
      age,
      gender,
      ethnicity: race,
    };
  }

  if ([diastolicLowerNumber, systolicUpperNumber, heartRate].some(Boolean)) {
    bloodPressure = {
      diastolicLowerNumber: Number(diastolicLowerNumber),
      systolicUpperNumber: Number(systolicUpperNumber),
      heartRate: Number(heartRate),
    };
  }

  if (!!pulseOximeter && !!heartRate) {
    pulseOx = {
      pulseOximeter: Number(pulseOximeter),
      perfusionIndex: !!perfusionIndex ? Number(perfusionIndex) : null,
      heartRate: Number(heartRate),
    };
  }

  const body = pick.pickNotNull({
    userId: patient.id,
    bmi,
    pulseOx,
    bloodPressure,
    visualAcuity,
  });

  const response = await patientOverviewClient.post(
    'medicalProfile/provider/create',
    body
  );
  return response.data;
}

async function addHealthDetailsBaseOnUpdatedFields(
  updatedFields = {},
  currentFields = {},
  patient
) {
  const listFields = [
    'height',
    'weight',
    'diastolicLowerNumber',
    'systolicUpperNumber',
    'heartRate',
    'pulseOximeter',
    'perfusionIndex',
    'visualAcuity',
  ];

  const updateBody = { patient };
  listFields.forEach((field) => {
    updateBody[field] = updatedFields[field] || currentFields[field] || null;
  });

  const res = await addMultipleValues(updateBody);
  return res.data;
}

const medicalProfileProviderApi = {
  getMedicalProfile,
  extractLatestHealthDetails,
  addHealthDetailsBaseOnUpdatedFields,
};

export default medicalProfileProviderApi;
