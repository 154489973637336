import React from 'react';
import { Document } from 'react-pdf';
import { useMediaQuery } from 'react-responsive';

import {
  Button,
  Text,
  CheckBox,
  FileUpload,
  Switch,
  Select,
  TextArea,
  Icon,
  Input,
} from 'components';
import uploadSvg from 'assets/new/upload.svg';
import blueCheckCircle from 'assets/new/blueCheckCircle-no_border.svg';
import redCheckCircle from 'assets/new/redCheckCircle-no_border.svg';

import './resultPdfUploadModal.scss';

const warnings = [
  // 'Avoid uploading more than 1 file.',
  { text: 'Upload a PDF or an Image', id: 'patient_app.result_upload_pdf' },
  {
    text: 'Avoid uploading different types of medical records as a single file',
    id: 'patient_app.result_warn_avoid',
  },
  {
    text: 'PDFs exceeding 8MB will not undergo data extraction',
    id: 'patient_app.result_warn_exceed',
  },
  // {
  //   text: 'Photocopied / scanned PDFs will not undergo data extraction',
  //   id: 'patient_app.result_warn_photocopy',
  // },
];

const ResultsPdfUploadModalContent = ({
  isPatientApp,
  reportTypeOptions,
  isAbnormal,
  setIsAbnormal,
  uploadedPdf,
  setUploadedPdf,
  type,
  setType,
  doctorNote,
  setDoctorNote,
  reportFileType,
  setReportFileType,
  uploadStep,
  onNextUploadStep,
  isSaving,
  uploadPdf,
  onPasswordHandler,
  onLoadSuccessHandler,
  fileSelectHandler,
  isPdfNative,
  fileSize,
  isImage,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const categories = [
    {
      name: 'result',
      text: 'Lab Results',
      textId: 'react_report.lab_results_optn',
      description: 'Serology, Haematology, Pathology and Urinalysis and more',
      descriptionId: 'react_report.lab_results_desc',
    },
    {
      name: 'diagnostic',
      text: 'Diagnostic Imaging',
      textId: 'react_report.diagnostic_imaging_optn',
      description:
        'CT-scan, MRI, Scope Imaging, Ultrasound and X-ray(s) and more',
      descriptionId: 'react_report.diagnostic_imaging_desc',
    },
    {
      name: 'pulmonary',
      text: 'Pulmonary Function',
      textId: 'react_report.pulmonary_function_optn',
      description: 'Cardiopulmonary Exercise Test (CPET), Spirometry and more',
      descriptionId: 'react_report.cardiopulmonary_exercise_desc',
    },
    {
      name: 'audio',
      text: 'Audio & Visual Acuity',
      textId: 'react_report.audio_n_visual_acuity_optn',
      description: 'Audio and visual acuity assessments and more',
      descriptionId: 'react_report.audio_n_visual_acuity_desc',
    },
    {
      name: 'other',
      text: 'Other Reports',
      textId: 'react_report.other_reports',
      description: 'Other reports in the available report categories',
      descriptionId: 'patient_app.other_reports_desc',
    },
  ];

  return (
    <div>
      {/* {type !== 'result' && (
        <div className="r-pdf-um__step">
          <div
          onClick={() => onNextUploadStep(1)}
            className={`r-pdf-um__step__circle${
              uploadStep === 1 ? ' active' : ''
            }`}
          ></div>
          <div
           onClick={() => onNextUploadStep(2)}
            className={`r-pdf-um__step__circle${
              uploadStep === 2 ? ' active' : ''
            }`}
          ></div>
        </div>
      )} */}
      <div className="r-pdf-um__content">
        {uploadStep === 1 && (
          <>
            <div className="r-pdf-um__content__toggles">
              {categories.map((category) => (
                <div
                  className="r-pdf-um__content__toggle"
                  onClick={() => setType(category.name)}
                >
                  <CheckBox
                    size="large"
                    className="mb-3"
                    checked={type === category.name}
                  />
                  <div className="r-pdf-um__content__toggle__text">
                    <Text id={category.textId} bold>
                      {category.text}
                    </Text>
                    <Text
                      id={category.descriptionId}
                      size="tiny"
                      color="lightGrey"
                    >
                      {category.description}
                    </Text>
                  </div>
                </div>
              ))}
            </div>
            <FileUpload
              label="Drop file(s) here or click to upload."
              onSelect={(e) => fileSelectHandler(e, setUploadedPdf)}
              accept={'.pdf,image/*'}
              fileSize={fileSize}
              fileNames={uploadedPdf ? [uploadedPdf.name] : null}
              icon={false}
              isDropzone={true}
              className="uploadReport__dropzone"
              isImage={isImage}
              multiple
            />
            <div className="r-pdf-um__warning-wrapper">
              {warnings.map((txt, index) => (
                <>
                  {((type !== 'result' && index !== 2 && index !== 3) ||
                    type === 'result') && (
                    <div className="r-pdf-um__warning">
                      <img
                        src={
                          index === 2 && fileSize > 8000
                            ? // || (index === 3 && !isPdfNative)
                              redCheckCircle
                            : blueCheckCircle
                        }
                        className="r-pdf-um__warning__icon"
                      />
                      <Text className="r-pdf-um__warning__text" id={txt?.id}>
                        {txt?.text}
                      </Text>
                    </div>
                  )}
                </>
              ))}
            </div>
            {/* {(uploadedPdf && (!isPdfNative || fileSize > 8000) && type === 'result') && (
              <div className='r-pdf-um__info-wrapper'>
                <Text inline bold className="mb-1">The files medical data won't be extracted as it fails some conditions</Text>
                {isPatientApp && <Text>Dont worry! The file will still be saved and retrievable from your profile</Text>}
                {!isPatientApp && <Text>Dont worry! The file will still be saved and retrievable in the patient profile</Text>}
              </div>
            )} */}
          </>
        )}
        {/* <Text size="tinniest">Uploaded PDFs will be organized into their respective categories</Text> */}
        {type !== 'result' && uploadStep === 2 && (
          <div className="r-pdf-um__report-details mb-4">
            <div className="r-pdf-um__report-details__abnormal">
              <Text bold className="mb-2">
                Result has an abnormality?
              </Text>
              <Switch
                onChange={() => setIsAbnormal(!isAbnormal)}
                checked={isAbnormal}
                className="r-pdf-um__report-details__abnormal__toggle"
              />
            </div>
            <div className="r-pdf-um__report-details__type">
              <Text id="react_report.report_type" bold className="mb-2">
                Report Type
              </Text>
              {type !== 'other' ? (
                <Select
                  placeholder="Report Type"
                  border
                  search
                  size={isMobile ? 'fullWidth' : 'normal'}
                  options={reportTypeOptions[type]}
                  value={reportFileType}
                  onChange={(e, { value }) => setReportFileType(value)}
                />
              ) : (
                <Input
                  placeholder="Report Type"
                  border
                  size={isMobile ? 'fullWidth' : 'medium'}
                  value={reportFileType}
                  onChange={(e) => setReportFileType(e.target.value)}
                />
              )}
            </div>
            {!isPatientApp && (
              <div className="r-pdf-um__report-details__notes">
                <Text id="react_report.doctor_notes" bold>
                  Doctors Notes
                </Text>
                <Text size="small">
                  <TextArea
                    placeholder="Write your notes here..."
                    onChange={(e) => setDoctorNote(e.target.value)}
                    showErrorMessage={false}
                    value={doctorNote}
                  />
                </Text>
              </div>
            )}
          </div>
        )}
        {uploadStep === 1 && type !== 'result' && (
          <Button
            className="r-pdf-um__button"
            size="fullWidth"
            onClick={() => onNextUploadStep(2)}
            color="blue"
            disabled={!uploadedPdf}
          >
            <Text id="react_report.continue" color="white" bold>
              Continue
            </Text>
            <Icon name="arrowRight" color="white" className="ml-3" />
          </Button>
        )}
        {(uploadStep === 2 || type === 'result') && (
          <Button
            className="r-pdf-um__button"
            size="fullWidth"
            onClick={uploadPdf}
            isLoading={isSaving}
            color="blue"
            disabled={!uploadedPdf}
          >
            <img src={uploadSvg} className="mr-3" />
            {uploadedPdf &&
            // (!isPdfNative || fileSize > 8000) &&
            fileSize > 8000 &&
            type === 'result' ? (
              <Text color="white" bold>
                Upload PDF (without data extraction)
              </Text>
            ) : (
              <Text id="react_report.upload_pdf" color="white" bold>
                Upload PDF
              </Text>
            )}
          </Button>
        )}
      </div>
      <div className="r-pdf-um__pdf-preview">
        {uploadedPdf && (
          <Document
            file={uploadedPdf}
            onLoadSuccess={onLoadSuccessHandler}
            onPassword={onPasswordHandler}
          />
        )}
      </div>
    </div>
  );
};

export default ResultsPdfUploadModalContent;
