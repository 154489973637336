import React from 'react';

const SvgAlert = props => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#1b1b1b">
      <path
        d="M17.72 4.903L30.21 25.98A2 2 0 0128.49 29H3.51a2 2 0 01-1.72-3.02L14.28 4.903a2 2 0 013.44 0z"
        strokeWidth={3}
      />
      <path d="M16 12.25v7.5" strokeWidth={3} strokeLinecap="round" />
      <circle fill="#1b1b1b" cx={16} cy={24} r={1} />
    </g>
  </svg>
);

export default SvgAlert;
