import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { Text, ErrorToast } from 'components';
import ethnicities from 'utils/ethnicities';

import ReactHealthQuestionsForm from './components/ReactHealthQuestionsForm';

import medicalProfileApi from 'api/patientOverview/medicalProfileApi';
import patientReactSessionApi from 'api/patientReactSession';

import history from '../../../history';

import './reactHealthQuestions.scss';

const ReactHealthQuestions = (props) => {
  const urlParams = new URLSearchParams(props.history.location.search);
  const sessionId = urlParams.get('sessionId');
  const patientId = urlParams.get('patientId');

  const [isSaving, setIsSaving] = useState(false);

  const updateRiskAssessment = async (details) => {
    try {
      setIsSaving(true);
      const formattedData = {
        gender: details.gender,
        height: details.height,
        weight: details.weight,
        ethnicity: ethnicities.find((eth) => eth.value === details.ethnicity),
        basicHealthInformation: {
          gender: details.gender,
          height: details.height,
          weight: details.weight,
          ethnicity: details.ethnicity,
          smoke: details?.smoker,
          isDiabetic: Boolean(details?.diabetic),
          alcoholicDrinkPerWeek: details?.alcoholicDrinkPerWeek,
          isLipidLoweringMedication: Boolean(details?.lipidMeds),
          isAntiHypertensiveMedication: Boolean(details?.hypertensiveMeds),
          parentalHyperTension: details?.parentalHyperTension,
          isFamilyHistoryOfHeartAttack: Boolean(details?.familyHeartAttack),
          isFamilyHistoryOfDiabetes: Boolean(details?.diabetesFamilyHistory),
          isHistoryOfTreatedHypertension: Boolean(
            details?.historyTreatedHypertension
          ),
          isHistoryOfCardiovascularDisease: Boolean(
            details?.historyCardiovascularDisease
          ),
          isHistoryOfGestationalDiabetes: Boolean(
            details?.historyGestationalDiabetes
          ),
          isHistoryOfPolycysticOvarySyndrome: Boolean(
            details?.historyPolycysticOvarySyndrome
          ),
          isHistoryOfLearningDifficulties: Boolean(
            details?.historyLearningDifficulties
          ),
          isHistoryOfSchizophreniaOrBipolarAffectiveDisorder: Boolean(
            details?.historySchizophreniaBipolar
          ),
          isHistoryOf2ndGenerationAntipsychoticUse: Boolean(
            details?.historyOf2ndGenerationAntipsychoticUse
          ),
          isHistoryOfCorticosteroidUse: Boolean(
            details?.historyOfCorticosteroidUse
          ),
          isHistoryOfStatinUse: Boolean(details?.historyOfStatinUse),
        },
      };

      if (!sessionId && patientId) {
        console.log(
          'update questionnaire',
          formattedData.basicHealthInformation
        );
        const body = {
          ...formattedData.basicHealthInformation,
          completedOn: moment().utc().toISOString(),
        };
        await medicalProfileApi.updateMedProfileQuestionnaire(patientId, body);

        history.push({
          pathname: `/public/react-questionnaire/patient/${patientId}/success`,
        });
      } else if (sessionId && !patientId) {
        await patientReactSessionApi.completeQuestionnaire(
          sessionId,
          formattedData
        );

        history.push({
          pathname: `/public/react-questionnaire/session/${sessionId}/success`,
        });
      } else {
        return toast.error(
          <ErrorToast message="Your link is invalid, please check and try again." />
        );
      }
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className=" r-health-questions__public-w">
      <div className="r-health-questions">
        <Text dark size="bigger" className="mb-3">
          ReACT Questionnaire
        </Text>
        <div className="r-health-questions__info">
          <Text bold>
            Completing the questionnaire as accurately as possible will help with
            the accuracy of your health risk assessment.
          </Text>
        </div>
        <ReactHealthQuestionsForm
          riskInfo={{}}
          isSaving={isSaving}
          onSubmit={updateRiskAssessment}
        />
      </div>
    </div>
  );
};

export default withRouter(ReactHealthQuestions);
