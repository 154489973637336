import React from 'react';
import moment from 'moment';

import HealthValueLine from 'features/HealthValueLine/HealthValueLine';
import HealthValueLineWrapper from 'features/HealthValueLine/HealthValueLinesWrapper';

import history from '../../../../history';

import '../bmiPage.scss';

const ranges = [
  { text: 'Underweight', range: [0, 18.5], subText: '<18.5', color: 'grey' },
  {
    text: 'Normal',
    range: [18.5, 24.9],
    subText: '18.5-24.9',
    color: 'green',
  },
  {
    text: 'Overweight',
    range: [24.9, 29.9],
    subText: '25-29.9',
    color: 'yellow',
  },
  { text: 'Obese', range: [30, 34.9], subText: '30-34.9', color: 'orange' },
  { text: 'Very Obese', range: [35, 40], subText: '≥35', color: 'red' },
];

const bmiColors = {
  underweight: { color: 'grey' },
  normal: { color: 'green' },
  overweight: { color: 'yellow' },
  obese: { color: 'orange' },
  'very obese': { color: 'red' },
};

const BmiVisual = ({ row, index, isClient }) => {
  return (
    <div key={index + row.id} className="bmi-page__body__list__item">
      <HealthValueLineWrapper
        title="BMI -"
        onClick={
          isClient
            ? () => {}
            : () =>
                history.push({
                  pathname: `/p/bmi/edit/${row.id}`,
                  state: { data: row },
                })
        }
        createdAt={moment(row.date).format('DD MMM YYYY, HH:mm')}
        subValues={[
          {
            value: `${row.height} ${row.heightUnit}`,
            text: 'Height',
          },
          {
            value: `${row.weight} ${row.weightUnit}`,
            text: 'Weight',
          },
        ]}
        value={{
          value: row.bmi,
          text: row.status,
        }}
        color={bmiColors[row.status]?.color || 'green'}
      >
        <HealthValueLine
          value={{
            value: row.bmi,
            text: row.status,
          }}
          ranges={ranges}
        />
      </HealthValueLineWrapper>
    </div>
  );
};

export default BmiVisual;
