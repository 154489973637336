import { patientOverviewClient } from '../patientOverviewClient';
import { calculateAge } from '../../../utils';

const baseUrl = 'medicalProfile/provider/bmis';
async function addBmi(patient, weight, height) {
  const { id, dateOfBirth, race, gender } = patient;
  const age = calculateAge(new Date(dateOfBirth));
  const response = await patientOverviewClient.post(
    'medicalProfile/provider/create',
    {
      userId: id,
      bmi: {
        height: {
          value: Number(height),
          unit: 'cm',
        },
        weight: {
          value: Number(weight),
          unit: 'kg',
        },
        age,
        gender,
        ethnicity: race,
      },
    }
  );
  return response.data;
}

async function updateBmi(userId, bmi) {
  const response = await patientOverviewClient.post(`${baseUrl}/update`, {
    ...bmi,
    userId,
  });
  return response.data;
}

async function deleteBmi(userId, id) {
  const response = await patientOverviewClient.post(`${baseUrl}/delete`, {
    id,
    userId,
  });
  return response.data;
}

const clientBmiApi = {
  addBmi,
  updateBmi,
  deleteBmi,
};

export default clientBmiApi;
