const ethinicities = [
  {
    text: 'Asian',
    value: 'Asian',
    standard: 'http://terminology.hl7.org/CodeSystem/v3-Race',
    code: '2028-9',
  },
  {
    text: 'Asian Indian',
    value: 'Asian Indian',
    standard: 'http://terminology.hl7.org/CodeSystem/v3-Race',
    code: '2029-7',
  },
  {
    text: 'African',
    value: 'African',
    standard: 'http://terminology.hl7.org/CodeSystem/v3-Race',
    code: '2060-2',
  },
  {
    text: 'European',
    value: 'European',
    standard: 'http://terminology.hl7.org/CodeSystem/v3-Race',
    code: '2108-9',
  },
  {
    text: 'Hispanic or Latino',
    value: 'Hispanic or Latino',
    standard: 'http://terminology.hl7.org/CodeSystem/v3-Ethnicity',
    code: '2135-2',
  },
  {
    text: 'Middle Eastern or North African',
    value: 'Middle Eastern or North African',
    standard: 'http://terminology.hl7.org/CodeSystem/v3-Race',
    code: '2118-8',
  },
  {
    text: 'Native Hawaiian or Other Pacific Islander',
    value: 'Native Hawaiian or Other Pacific Islander',
    standard: 'http://terminology.hl7.org/CodeSystem/v3-Race',
    code: '2076-8',
  },
  {
    text: 'Other Race',
    value: 'Other Race',
    standard: 'http://terminology.hl7.org/CodeSystem/v3-Race',
    code: '2131-1',
  },
  {
    text: 'White',
    value: 'White',
    standard: 'http://terminology.hl7.org/CodeSystem/v3-Race',
    code: '2106-3',
  },
];

export default ethinicities;
