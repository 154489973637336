import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import './modal.scss';

const Modal = props => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClick = e => {
      if (modalRef.current.contains(e.target)) {
        return;
      }
      props.closeModal();
    };
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []); // eslint-disable-line

  return (
    <div className="custom-modal-wrapper">
      <div className="custom-modal-backdrop" />
      <div
        className={
          props.className ? `custom-modal ${props.className}` : 'custom-modal'
        }
        ref={modalRef}
      >
        {props.header && (
          <div className="custom-modal__header">{props.header}</div>
        )}
        {props.text && <div className="custom-modal__text">{props.text}</div>}
        {props.children && props.children}
        {props.button && (
          <div className="custom-modal__button">{props.button}</div>
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  children: PropTypes.node,
  header: PropTypes.string,
  text: PropTypes.string,
  button: PropTypes.node,
};

export default Modal;
