import React from 'react';

const SvgHome = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 23" {...props}>
    <path
      d="M1.596 8.13a.75.75 0 00-.346.632V21c0 .414.336.75.75.75h20a.75.75 0 00.75-.75V8.762a.75.75 0 00-.346-.632l-10-6.389a.75.75 0 00-.808 0l-10 6.39z"
      stroke={props.color}
      strokeWidth={2.5}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

SvgHome.defaultProps = {
  color: '#1b1b1b',
};

export default SvgHome;
