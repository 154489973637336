import { apolloClient } from '../apollo/apollo';
import {
  getAccountDetails,
  getPaymentGateWays,
} from './graphql/queries/paymentService';
import {
  createPaymentAccount,
  editPaymentAccount,
  onAccountSuccess,
} from './graphql/mutations/paymentService';

const methods = {
  async getPaymentGateWays(payload) {
    return apolloClient.query({
      query: getPaymentGateWays,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async createPaymentAccount(payload) {
    return apolloClient.mutate({
      mutation: createPaymentAccount,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async editPaymentAccount(payload) {
    return apolloClient.mutate({
      mutation: editPaymentAccount,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async getAccountDetails(payload) {
    return apolloClient.query({
      query: getAccountDetails,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async onAccountSuccess(payload) {
    return apolloClient.mutate({
      mutation: onAccountSuccess,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
};
export default methods;