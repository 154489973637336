import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import CircleLoader from 'react-spinners/CircleLoader';

import { ErrorToast } from 'components';

import CertificatesService from 'api-delta/certificates';
import CertificateView from '../certificateView/CertificateView';
import PublicDocLogin from './components/PublicDocLogin';

import './publicCertificateView.scss';

const PublicCertificateView = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);

  useEffect(() => {
    authenticate();
  }, []); // eslint-disable-line

  const authenticate = async (password) => {
    setIsLoading(true);
    const certificateId = props.match.params.certificateId;
    const storedPass = localStorage.getItem(`DELTA_PA-${certificateId}`);

    const urlParams = new URLSearchParams(props.history.location.search);
    const token = urlParams.get('pdf');
    // Bypass auth for pdf generator
    // TODO: Find a more secure implementation
    if (token === 'tMLVLYIgbymkdW9yGGSdUrVHA9snWPdY') {
      setIsAuthenticated(true);
      setIsLoading(false);
      return;
    }

    try {
      const {
        data: { publicDocAuthentication },
      } = await CertificatesService.publicDocAuthentication(
        certificateId,
        password || storedPass,
        !!password
      );
      const parsedResp = JSON.parse(publicDocAuthentication);

      localStorage.setItem(`DELTA_PA-${certificateId}`, parsedResp?.token);
      setIsAuthenticated(parsedResp.authenticated);
      setIsLoading(false);

      if (!!password && !parsedResp.authenticated) {
        setWrongPass(true);
        toast.error(<ErrorToast message="Wrong passcode" />);
      }
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsLoading(false);
    }
  };

  // const renderCertificateViewComponent = useCallback(() => {
  //   if (clientType === MEDICAL && !isAuthenticated) {
  //     return (
  //       <PublicDocLogin
  //         idType={idString}
  //         login={authenticate}
  //         wrongPass={wrongPass}
  //       />
  //     );
  //   }

  //   return (
  //     <>
  //       <div className="public-certificate-view">
  //         <CertificateView
  //           publicView
  //           className="public-certificate-view__certificate"
  //         />
  //       </div>
  //     </>
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [clientType, isAuthenticated]);

  return (
    <div>
      {isLoading ? (
        <div className="public-certificate-view__loader-wrapper">
          <CircleLoader color="#455af7" />
        </div>
      ) : (
        <>
          {isAuthenticated ? (
            <div className="public-certificate-view">
              <CertificateView
                publicView
                className="public-certificate-view__certificate"
              />
            </div>
          ) : (
            <PublicDocLogin login={authenticate} wrongPass={wrongPass} />
          )}
        </>
      )}
    </div>
  );
};

export default withRouter(PublicCertificateView);
