import * as React from "react"

const TestTubes = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="inherit"
  height="inherit"
  fill="none"
  viewBox="0 0 25 25"
  {...props}>
    <g fill={props.color} clipPath="url(#a)">
      <path d="M10.75 5h-9a.761.761 0 0 0-.53.214.719.719 0 0 0-.22.515.72.72 0 0 0 .22.516c.14.137.331.213.53.213h.75v12.396c0 .967.395 1.894 1.098 2.578A3.805 3.805 0 0 0 6.25 22.5c.995 0 1.948-.384 2.652-1.068A3.595 3.595 0 0 0 10 18.854V6.458h.75a.76.76 0 0 0 .53-.213.719.719 0 0 0 0-1.031.76.76 0 0 0-.53-.214Zm-4.5 16.042c-.597 0-1.169-.23-1.591-.641A2.157 2.157 0 0 1 4 18.854v-7.291h4.5v7.291c0 .58-.237 1.137-.659 1.547-.422.41-.994.64-1.591.64ZM8.5 10.104H4V6.458h4.5v3.646Z" />
      <path
        fillRule="evenodd"
        d="M3.013 2.638v2.1h6.475v-2.1H3.013ZM2.75 1.5a.875.875 0 0 0-.875.875v3.5h8.75v-3.5A.875.875 0 0 0 9.75 1.5h-7Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M4.5 2.813c.242 0 .438.195.438.437V5a.438.438 0 0 1-.875 0V3.25c0-.242.195-.438.437-.438ZM6.25 2.813c.242 0 .438.195.438.437V5a.438.438 0 0 1-.875 0V3.25c0-.242.195-.438.437-.438ZM8 2.813c.242 0 .438.195.438.437V5a.438.438 0 0 1-.876 0V3.25c0-.242.196-.438.438-.438Z"
        clipRule="evenodd"
      />
      <path d="M7.125 8.5c0-.483.392-.875.875-.875h1.75V19H8a.875.875 0 0 1-.875-.875V8.5ZM21.75 5h-9a.761.761 0 0 0-.53.214.72.72 0 0 0 0 1.03c.14.138.331.214.53.214h.75v12.396c0 .967.395 1.894 1.098 2.578A3.805 3.805 0 0 0 17.25 22.5c.994 0 1.948-.384 2.652-1.068A3.595 3.595 0 0 0 21 18.854V6.458h.75c.199 0 .39-.076.53-.213a.72.72 0 0 0 .22-.516.72.72 0 0 0-.22-.515.761.761 0 0 0-.53-.214Zm-4.5 16.042c-.597 0-1.17-.23-1.591-.641A2.157 2.157 0 0 1 15 18.854v-7.291h4.5v7.291c0 .58-.237 1.137-.66 1.547-.421.41-.993.64-1.59.64Zm2.25-10.938H15V6.458h4.5v3.646Z" />
      <path
        fillRule="evenodd"
        d="M14.012 2.638v2.1h6.476v-2.1h-6.476ZM13.75 1.5a.875.875 0 0 0-.875.875v3.5h8.75v-3.5a.875.875 0 0 0-.875-.875h-7Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M15.5 2.813c.242 0 .438.195.438.437V5a.438.438 0 0 1-.876 0V3.25c0-.242.196-.438.438-.438ZM17.25 2.813c.242 0 .438.195.438.437V5a.438.438 0 0 1-.875 0V3.25c0-.242.195-.438.437-.438ZM19 2.813c.242 0 .438.195.438.437V5a.438.438 0 0 1-.875 0V3.25c0-.242.195-.438.437-.438Z"
        clipRule="evenodd"
      />
      <path d="M18.125 8.5c0-.483.392-.875.875-.875h1.75V19H19a.875.875 0 0 1-.875-.875V8.5Z" />
      <path d="M13 14.5a1 1 0 1 0-2 0v5a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4v-5a1 1 0 1 0-2 0v9a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1v-9a1 1 0 1 0-2 0v5a4 4 0 0 1-4 4h-1a4 4 0 0 1-4-4v-5Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={props.color} d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default TestTubes
