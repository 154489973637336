import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    width="inherit"
    height="inherit"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    {...props}
  >
    <g>
      <path
        d="M24 9.5a3 3 0 1 0-4 2.816V16.5a6 6 0 1 1-12 0v-.08a7.006 7.006 0 0 0 6-6.92v-4a5.006 5.006 0 0 0-5-5 1 1 0 0 0 0 2 3 3 0 0 1 3 3v4a5 5 0 1 1-10 0v-4a3 3 0 0 1 3-3 1 1 0 0 0 0-2 5.006 5.006 0 0 0-5 5v4a7.006 7.006 0 0 0 6 6.92v.08a8 8 0 0 0 16 0v-4.184A3 3 0 0 0 24 9.5Zm-3 1a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill={props.color}
          transform="translate(0 .5)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
