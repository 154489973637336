export const SET_ISSUED_CERTIFICATES = 'ISSUED_CERTS/SET_ISSUED_CERTIFICATES';

/**
 * @function setLabDocuments
 * @description Set documents results sent by labs
 */
export const setIssuedCertificates = labDocuments => ({
  type: SET_ISSUED_CERTIFICATES,
  payload: labDocuments,
});
