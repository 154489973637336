import * as React from 'react';

const SvgApproved = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '17px', marginRight: '10px' }}
    {...props}
  >
    <g clipPath="url(#clip0_99_509)">
      <path
        d="M18.5811 2.14073L12.3161 0.0517291C12.111 -0.016592 11.8893 -0.016592 11.6841 0.0517291L5.41912 2.14073C4.42303 2.47161 3.55654 3.10799 2.94277 3.95944C2.329 4.81089 1.99916 5.83412 2.00012 6.88373V12.0007C2.00012 19.5637 11.2001 23.7407 11.5941 23.9147C11.7219 23.9715 11.8603 24.0009 12.0001 24.0009C12.14 24.0009 12.2783 23.9715 12.4061 23.9147C12.8001 23.7407 22.0001 19.5637 22.0001 12.0007V6.88373C22.0011 5.83412 21.6713 4.81089 21.0575 3.95944C20.4437 3.10799 19.5772 2.47161 18.5811 2.14073ZM20.0001 12.0007C20.0001 17.4557 13.6811 21.0337 12.0001 21.8897C10.3171 21.0367 4.00012 17.4697 4.00012 12.0007V6.88373C4.00018 6.25401 4.1984 5.64027 4.56669 5.12947C4.93498 4.61868 5.45467 4.23672 6.05212 4.03773L12.0001 2.05473L17.9481 4.03773C18.5456 4.23672 19.0653 4.61868 19.4336 5.12947C19.8018 5.64027 20.0001 6.25401 20.0001 6.88373V12.0007Z"
        fill={props?.color || '#25282C'}
      />
      <path
        d="M15.2999 8.30032L11.1119 12.5003L8.86792 10.1603C8.77786 10.0619 8.66901 9.98242 8.54778 9.92667C8.42654 9.87092 8.29538 9.84001 8.16201 9.83573C8.02864 9.83146 7.89577 9.85393 7.77122 9.9018C7.64667 9.94968 7.53296 10.022 7.43679 10.1145C7.34062 10.207 7.26393 10.3178 7.21125 10.4404C7.15856 10.563 7.13095 10.6949 7.13003 10.8283C7.12911 10.9618 7.1549 11.094 7.20589 11.2173C7.25688 11.3407 7.33203 11.4525 7.42692 11.5463L9.73291 13.9463C9.90489 14.1321 10.1128 14.281 10.3439 14.3842C10.5751 14.4873 10.8248 14.5425 11.0779 14.5463H11.1109C11.359 14.5472 11.6047 14.4987 11.8339 14.4038C12.0631 14.3088 12.2711 14.1693 12.4459 13.9933L16.7179 9.72132C16.8112 9.62822 16.8853 9.51764 16.9358 9.39592C16.9864 9.27419 17.0125 9.14369 17.0127 9.01188C17.0128 8.88007 16.987 8.74952 16.9367 8.62769C16.8863 8.50585 16.8125 8.39512 16.7194 8.30182C16.6263 8.20852 16.5157 8.13446 16.394 8.08389C16.2723 8.03332 16.1418 8.00722 16.01 8.00708C15.8782 8.00694 15.7476 8.03277 15.6258 8.08308C15.5039 8.13339 15.3932 8.20721 15.2999 8.30032Z"
        fill={props?.color || '#25282C'}
      />
    </g>
    <defs>
      <clipPath id="clip0_99_509">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgApproved;
