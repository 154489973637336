import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PcrCertificate from './components/IssuePcrCert';
import LoadPage from '../../../pages/loaderPage';
import PemeCertificate from '../certificateCreation/certificates/PemeCertificate';
import FftCertificate from '../certificateCreation/certificates/FftCertificate';
import CovidVac from './components/covidVac/CovidVac';

import UserService from 'api-delta/user';
import CertService from 'api-delta/certificates';
import PublicBeTcm from 'api/publicBeTcm';

import PlatoLogo from 'assets/images/plato-logo.png';
import RiverrLogo from 'assets/images/riverr-logo.png';
import {
  setClientInformation,
  setDocumentTypes,
} from 'redux/generalState.actions';

import { PageHeader, Text, Select } from 'components';

import './issueCertififcate.scss';
import './platoIssue.scss';

const PlatoIssue = (props) => {
  const dispatch = useDispatch();
  const documentTypes = useSelector((state) => state.general.documentTypes);
  const documentTypesObj = useSelector((state) => state.general.docTypesObj);

  const [subType, setSubType] = useState('SG_PDT_PCR_TEST');
  const [documentOptions, setDocumentOptions] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [patient, setPatient] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [clients, setClients] = useState([]);
  const [clientDocz, setClientDocs] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [loadingClinic, setLoadingClinic] = useState(false);

  useEffect(() => {
    if (clientDocz && clientDocz.length > 0) setLocalDocumentTypes(clientDocz);
  }, [documentTypesObj, clientDocz]);

  const fetchDocumentTypes = async () => {
    try {
      const {
        data: { getDocumentTypes },
      } = await CertService.getDocumentTypes();
      await dispatch(setDocumentTypes(getDocumentTypes));
    } catch (error) {
      console.log('router on start error', error);
    }
  };

  const selectDocType = (type) => {
    let selectedDoc;
    documentTypes.forEach((doc) => {
      if (doc.subType === type) selectedDoc = doc;
    });
    setSelectedDoc(selectedDoc);
    setSubType(selectedDoc.subType);
  };

  const setLocalDocumentTypes = (docs) => {
    const clientDocs = docs.map((doc) => ({
      text: documentTypesObj[doc],
      value: doc,
    }));
    setDocumentOptions(clientDocs);
    selectDocType(clientDocs[0].value);
  };

  useEffect(() => {
    const loadPlato = async () => {
      try {
        const urlParams = new URLSearchParams(props.history.location.search);
        const token = urlParams.get('token');
        await fetchDocumentTypes();
        const { data } = await PublicBeTcm.platoLogin(token);
        setClients(data.clients);
        dispatch(setClientInformation(data.clients[0]));
        setSelectedClient(data.clients[0].id);
        localStorage.setItem('ISSUER', data.clients[0].name);
        localStorage.setItem('ROOT_CLIENT_ID', data.clients[0].id);
        localStorage.setItem('CLIENT_LOGO', data.clients[0].logo);
        localStorage.setItem('AUTH_TOKEN', data.client?.token);
        setClientDocs(data.clients[0].documents);
        // setLocalDocumentTypes(data.clients[0].documents);
        setPatient(data.patient);
        setLoading(false);
      } catch (error) {
        console.log('error', error);
        setIsError(true);
        setLoading(false);
      }
    };

    loadPlato();
  }, []);

  const selectClient = (clientId) => {
    setLoadingClinic(true);
    const client = clients.filter((cli) => cli.id === clientId);
    dispatch(setClientInformation(client[0]));
    setSelectedClient(clientId);
    localStorage.setItem('ISSUER', client[0].name);
    localStorage.setItem('ROOT_CLIENT_ID', client[0].id);
    localStorage.setItem('CLIENT_LOGO', client[0].logo);
    // setLocalDocumentTypes(client[0].documents);
    setClientDocs(client[0].documents);
    // hack to make new client data load
    setTimeout(() => setLoadingClinic(false), 300);
  };

  return (
    <div className="plato-issue">
      {isLoading ? (
        <LoadPage />
      ) : (
        <div className="plato-issue__content">
          {isError ? (
            <div className="plato-issue__error">
              <div className="plato-issue__error__images">
                <img
                  className="plato-issue__error__image"
                  alt="Riverr-logo"
                  src={RiverrLogo}
                />
                <img
                  className="plato-issue__error__image"
                  alt="Plato-logo"
                  src={PlatoLogo}
                />
              </div>
              <Text type="header" className="mb-3" dark>
                You are not signed up to Riverr yet
              </Text>
              <Text bold>
                Reach out to Riverr in order to get acceess to this app.
              </Text>
              <Text bold className="mt-4">
                Email: support@riverr.ai
              </Text>
              <Text bold className="mt-3">
                Phone number: +65 83443290
              </Text>
            </div>
          ) : (
            <>
              <div className="plato-issue__header">
                <img
                  className="plato-issue__header__image"
                  alt="Riverr-logo"
                  src={RiverrLogo}
                />
                <Text dark size="bigger">
                  Issue a Riverr Document
                </Text>
              </div>
              <div className="plato-issue__inputs-wrapper">
                {clients && clients.length > 1 && (
                  <Select
                    size="fullWidth"
                    label="Issuing clinic"
                    style={{ marginBottom: 15 }}
                    border
                    search
                    icon={false}
                    className="plato-issue__select"
                    placeholder="Select clinic"
                    options={clients.map((cli) => ({
                      text: cli.name,
                      value: cli.id,
                    }))}
                    value={selectedClient}
                    onChange={(e, { value }) => selectClient(value)}
                  />
                )}
                {!loadingClinic && (
                  <>
                    <Select
                      size="fullWidth"
                      label="Type"
                      border
                      search
                      icon={false}
                      className="plato-issue__select"
                      options={documentOptions}
                      value={subType}
                      onChange={(e, { value }) => selectDocType(value)}
                    />
                    {selectedDoc && (
                      <>
                        {selectedDoc.category === 'COVID' &&
                          selectedDoc.subCategory === 'TEST_RESULT' && (
                            <PcrCertificate
                              patient={patient}
                              subType={selectedDoc.subType}
                            />
                          )}
                        {selectedDoc.category === 'COVID' &&
                          selectedDoc.subCategory === 'VACCINE' && (
                            <CovidVac patient={patient} />
                          )}
                        {selectedDoc.category === 'MARITIME' &&
                          selectedDoc.subCategory === 'PEME' && (
                            <PemeCertificate />
                          )}
                        {selectedDoc.category === 'MARITIME' &&
                          selectedDoc.subCategory === 'FFT' && (
                            <FftCertificate />
                          )}
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(PlatoIssue);
