import gql from 'graphql-tag';

export const createTrainingCenter = gql`
  mutation trainingCenterSet(
    $clientId: ID!
    $name: String!
    $email: String!
    $phoneNumber: String!
    $address: String!
    $city: String!
    $country: String!
    $contactName: String!
    $zip: String
    $geoLocation: String
    $code: String
  ) {
    trainingCenterSet(
      clientId: $clientId
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      address: $address
      city: $city
      country: $country
      contactName: $contactName
      zip: $zip
      geoLocation: $geoLocation
      code: $code
    ) {
      success
      message
    }
  }
`;
export const updateTrainingCenter = gql`
  mutation trainingCenterUpdate(
    $id: ID!
    $clientId: ID!
    $name: String!
    $email: String!
    $phoneNumber: String!
    $address: String!
    $city: String!
    $country: String!
    $contactName: String!
    $zip: String
    $geoLocation: String
    $code: String
  ) {
    trainingCenterUpdate(
      id: $id
      clientId: $clientId
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      address: $address
      city: $city
      country: $country
      contactName: $contactName
      zip: $zip
      code: $code
      geoLocation: $geoLocation
    ) {
      success
      message
    }
  }
`;

export const deleteTrainingCenter = gql`
  mutation instructorDelete($id: ID) {
    instructorDelete(id: $id)
  }
`;
