import { apolloClient } from '../apollo/apollo';
import { facilities } from './graphql/queries/facilities';
import {
  createFacility,
  updateFacility,
  deleteFacility,
} from './graphql/mutations/facilities';

export default {
  async getFacilitiesByClientId(clientId) {
    return apolloClient.query({
      query: facilities,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
      },
    });
  },

  async createFacility(payload) {
    return apolloClient.mutate({
      mutation: createFacility,
      fetchPolicy: 'no-cache',
      variables: {
        ...payload,
      },
    });
  },

  async deleteFacility(id) {
    return apolloClient.mutate({
      mutation: deleteFacility,
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    });
  },

  async updateFacility(payload) {
    return apolloClient.mutate({
      mutation: updateFacility,
      fetchPolicy: 'no-cache',
      variables: {
        ...payload,
      },
    });
  },
};
