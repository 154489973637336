import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useCustomForm } from 'hooks';
import * as yup from 'yup';
import { withRouter } from 'react-router';

import { ErrorToast, DatePicker, TextArea, Text, Button } from 'components';
import { MedicationInput } from 'features';

import clientMedicationApi from '../../../api/patientOverview/clientApi/medicationApi';

import '../patMedCreators.scss';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  startDate: yup.date().required('Date is required'),
  remark: yup.string(),
});

const AddMedicationPage = ({
  edit,
  location,
  onCreate = () => {},
  patient,
}) => {

  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
  } = useCustomForm(schema, {
    name: edit ? location?.state?.data?.name : '',
    startDate: edit ? location?.state?.data?.startDate : moment().toDate(),
    remark: edit ? location?.state?.data?.remark : '',
  });

  const updatePatient = async (record) => {
    try {
      await clientMedicationApi.addMedication(patient.id, record);
      onCreate();
      // TODO fetch patient data again
    } catch (error) {
      console.log(error);
      toast.error(<ErrorToast error={error} />);
    }
  };

  return (
    <div className="pat-med-c">
      <form onSubmit={handleSubmit(updatePatient)}>
        <div className="pat-med-c__form-wrapper">
          <div className="pat-med-c__form-wrapper__row">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <MedicationInput
                  value={value}
                  onChange={(value) => onChange(value)}
                  label={
                    <Text type="label" id="patient_overview.medication" bold>
                      Medication
                    </Text>
                  }
                  invalid={errors?.name}
                />
              )}
            />
          </div>
          <div className="pat-med-c__form-wrapper__row">
            <Text type="label" id="patient_overview.start_date">
              Start Date
            </Text>
            <Controller
              name="startDate"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  className="pat-med-cs__date"
                  onChange={(date) => onChange(date)}
                  value={value}
                  size="fullWidth"
                  invalid={errors?.startDate}
                  maxDate={moment().toDate()}
                  showErrorMessage={false}
                />
              )}
            />
          </div>
          <div className="pat-med-c__form-wrapper__row">
            <Controller
              name="remark"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  label={
                    <Text type="label" id="patient_overview.remarks">
                      Remarks
                    </Text>
                  }
                  placeholder="3 times daily"
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  invalid={errors?.remark}
                  showErrorMessage={false}
                />
              )}
            />
          </div>
        </div>
        <Button color="blue" size="fullWidth" className="mt-4" type="submit">
          <Text color="inherit" id="settings_lab_grps.create">
            Create
          </Text>
        </Button>
      </form>
    </div>
  );
};

export default withRouter(AddMedicationPage);
