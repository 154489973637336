// import * as React from 'react';

// function Settings(props) {
//   return (
//     <svg width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
//       <path
//         d="M5 10h5V5H5v5zm7.5 15h5v-5h-5v5zM5 25h5v-5H5v5zm0-7.5h5v-5H5v5zm7.5 0h5v-5h-5v5zM20 5v5h5V5h-5zm-7.5 5h5V5h-5v5zm7.5 7.5h5v-5h-5v5zm0 7.5h5v-5h-5v5z"
//         fill="#1b1b1b"
//       />
//     </svg>
//   );
// }

// export default Settings;

import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={27}
      height={27}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M24.274 8.894l-.726-1.26a2.232 2.232 0 00-3.043-.822v0a2.221 2.221 0 01-3.044-.79 2.135 2.135 0 01-.299-1.068v0a2.232 2.232 0 00-2.232-2.296h-1.463a2.221 2.221 0 00-2.22 2.232v0a2.232 2.232 0 01-2.233 2.2 2.136 2.136 0 01-1.068-.3v0a2.232 2.232 0 00-3.043.823l-.78 1.281a2.232 2.232 0 00.812 3.044v0a2.232 2.232 0 010 3.865v0a2.221 2.221 0 00-.812 3.033v0l.737 1.271a2.232 2.232 0 003.044.865v0a2.21 2.21 0 013.033.812c.191.323.295.691.299 1.068v0c0 1.232.999 2.231 2.231 2.231h1.463a2.232 2.232 0 002.232-2.22v0a2.22 2.22 0 012.232-2.233c.375.01.742.113 1.068.3v0a2.232 2.232 0 003.044-.812v0l.768-1.282a2.221 2.221 0 00-.811-3.043v0a2.221 2.221 0 01-.812-3.044 2.19 2.19 0 01.812-.811v0a2.232 2.232 0 00.811-3.033v0-.01z"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={14.204}
        cy={13.87}
        r={3.076}
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
