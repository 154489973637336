import React from 'react';

const SvgTrend = (props) => (
  <svg
    width="47"
    height="14"
    viewBox="0 0 47 14"
    fill="none"
    stroke={props.color}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2.5" cy="8" r="2.5" fill="black" />
    <circle cx="12.5" cy="3" r="2.5" fill="black" />
    <circle cx="22.5" cy="11" r="2.5" fill="black" />
    <circle cx="32.5" cy="5" r="2.5" fill="black" />
    <circle cx="44.5" cy="8" r="2.5" fill="black" />
    <path d="M2.5 8L12.5 3L22.5 11L32.5 5L44.5 8" stroke="black" />
  </svg>
);

export default SvgTrend;
