import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    width="inherit"
    height="inherit"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    {...props}
  >
    <g>
      <path
        d="M18.667 17.501a1.167 1.167 0 0 1-1.167 1.167h-2.333v2.333a1.167 1.167 0 0 1-2.334 0v-2.333H10.5a1.167 1.167 0 1 1 0-2.333h2.333V14a1.167 1.167 0 1 1 2.334 0v2.334H17.5a1.167 1.167 0 0 1 1.167 1.166ZM28 9.335V21a5.84 5.84 0 0 1-5.833 5.834H5.833A5.84 5.84 0 0 1 0 21v-14a5.84 5.84 0 0 1 5.833-5.833h2.95c.543 0 1.078.127 1.564.369l3.682 1.848c.163.078.341.118.522.116h7.616A5.84 5.84 0 0 1 28 9.335ZM2.333 7v1.167h23.119a3.5 3.5 0 0 0-3.285-2.333H14.55a3.516 3.516 0 0 1-1.565-.369L9.304 3.624a1.185 1.185 0 0 0-.521-.123h-2.95a3.5 3.5 0 0 0-3.5 3.5Zm23.334 14v-10.5H2.333v10.5a3.5 3.5 0 0 0 3.5 3.5h16.334a3.5 3.5 0 0 0 3.5-3.5Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
