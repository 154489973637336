import {
  SET_ANALYTIC_DATA,
  SET_SUB_TYPES_OPTIONS,
  SET_SELECTED_SUBTYPES,
} from './charts.actions';

const initialState = {
  analyticData: [],
  subTypes: [],
  selectedSubTypes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ANALYTIC_DATA:
      return { ...state, analyticData: action.payload };
    case SET_SUB_TYPES_OPTIONS:
      return { ...state, subTypes: action.payload };
    case SET_SELECTED_SUBTYPES:
      return { ...state, selectedSubTypes: action.payload };
    default:
      return state;
  }
};
