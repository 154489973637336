import React from 'react';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

import './PDFViewer.scss';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const renderToolbar = (Toolbar) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar } = toolbarPluginInstance;

  const transform = (slot) => ({
      ...slot,
      // These slots will be empty
      Open: () => <></>,
      SwitchTheme: () => <></>,
  });

  return (
    <Toolbar>
      {renderDefaultToolbar(transform)}
    </Toolbar>
  )
};

const PDFViewer = ({ file, name, title, url }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [
        defaultTabs[0], // Bookmarks tab
    ],
    renderToolbar
});

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Viewer plugins={[defaultLayoutPluginInstance]} fileUrl={url} theme="dark" />
    </Worker>
  );
};

export default PDFViewer;
