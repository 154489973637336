import React from 'react';

const SvgTestTubes = (props) => (
  <svg
    width={props.width || '20'}
    height={props.height || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.95833 3.2487H1.45833C1.29257 3.2487 1.1336 3.31272 1.01639 3.42667C0.899181 3.54063 0.833333 3.69518 0.833333 3.85634C0.833333 4.01749 0.899181 4.17205 1.01639 4.286C1.1336 4.39996 1.29257 4.46398 1.45833 4.46398H2.08333V14.7938C2.08333 15.5996 2.41257 16.3724 2.99862 16.9422C3.58468 17.5119 4.37953 17.832 5.20833 17.832C6.03713 17.832 6.83199 17.5119 7.41804 16.9422C8.00409 16.3724 8.33333 15.5996 8.33333 14.7938V4.46398H8.95833C9.12409 4.46398 9.28306 4.39996 9.40028 4.286C9.51749 4.17205 9.58333 4.01749 9.58333 3.85634C9.58333 3.69518 9.51749 3.54063 9.40028 3.42667C9.28306 3.31272 9.12409 3.2487 8.95833 3.2487ZM5.20833 16.6168C4.71105 16.6168 4.23414 16.4247 3.88251 16.0828C3.53088 15.741 3.33333 15.2773 3.33333 14.7938V8.71745H7.08333V14.7938C7.08333 15.2773 6.88579 15.741 6.53416 16.0828C6.18253 16.4247 5.70561 16.6168 5.20833 16.6168ZM7.08333 7.50217H3.33333V4.46398H7.08333V7.50217Z"
      fill={props.color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.51042 1.27995V3.02995H7.90625V1.27995H2.51042ZM2.29167 0.332031C1.88896 0.332031 1.5625 0.65849 1.5625 1.0612V3.97786H8.85417V1.0612C8.85417 0.65849 8.52771 0.332031 8.125 0.332031H2.29167Z"
      fill={props.color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.75 1.42578C3.95135 1.42578 4.11458 1.58901 4.11458 1.79036V3.2487C4.11458 3.45005 3.95135 3.61328 3.75 3.61328C3.54865 3.61328 3.38542 3.45005 3.38542 3.2487V1.79036C3.38542 1.58901 3.54865 1.42578 3.75 1.42578Z"
      fill={props.color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.20833 1.42578C5.40969 1.42578 5.57292 1.58901 5.57292 1.79036V3.2487C5.57292 3.45005 5.40969 3.61328 5.20833 3.61328C5.00698 3.61328 4.84375 3.45005 4.84375 3.2487V1.79036C4.84375 1.58901 5.00698 1.42578 5.20833 1.42578Z"
      fill={props.color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.66667 1.42578C6.86802 1.42578 7.03125 1.58901 7.03125 1.79036V3.2487C7.03125 3.45005 6.86802 3.61328 6.66667 3.61328C6.46531 3.61328 6.30208 3.45005 6.30208 3.2487V1.79036C6.30208 1.58901 6.46531 1.42578 6.66667 1.42578Z"
      fill={props.color}
    />
    <path
      d="M5.9375 6.16536C5.9375 5.76266 6.26396 5.4362 6.66667 5.4362H8.125V14.9154H6.66667C6.26396 14.9154 5.9375 14.5889 5.9375 14.1862V6.16536Z"
      fill={props.color}
    />
    <path
      d="M18.125 3.2487H10.625C10.4592 3.2487 10.3002 3.31272 10.183 3.42667C10.0658 3.54063 9.99996 3.69518 9.99996 3.85634C9.99996 4.01749 10.0658 4.17205 10.183 4.286C10.3002 4.39996 10.4592 4.46398 10.625 4.46398H11.25V14.7938C11.25 15.5996 11.5792 16.3724 12.1653 16.9422C12.7513 17.5119 13.5462 17.832 14.375 17.832C15.2038 17.832 15.9986 17.5119 16.5847 16.9422C17.1707 16.3724 17.5 15.5996 17.5 14.7938V4.46398H18.125C18.2907 4.46398 18.4497 4.39996 18.5669 4.286C18.6841 4.17205 18.75 4.01749 18.75 3.85634C18.75 3.69518 18.6841 3.54063 18.5669 3.42667C18.4497 3.31272 18.2907 3.2487 18.125 3.2487ZM14.375 16.6168C13.8777 16.6168 13.4008 16.4247 13.0491 16.0828C12.6975 15.741 12.5 15.2773 12.5 14.7938V8.71745H16.25V14.7938C16.25 15.2773 16.0524 15.741 15.7008 16.0828C15.3492 16.4247 14.8722 16.6168 14.375 16.6168ZM16.25 7.50217H12.5V4.46398H16.25V7.50217Z"
      fill={props.color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.677 1.27995V3.02995H17.0729V1.27995H11.677ZM11.4583 0.332031C11.0556 0.332031 10.7291 0.65849 10.7291 1.0612V3.97786H18.0208V1.0612C18.0208 0.65849 17.6943 0.332031 17.2916 0.332031H11.4583Z"
      fill={props.color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9166 1.42578C13.118 1.42578 13.2812 1.58901 13.2812 1.79036V3.2487C13.2812 3.45005 13.118 3.61328 12.9166 3.61328C12.7153 3.61328 12.552 3.45005 12.552 3.2487V1.79036C12.552 1.58901 12.7153 1.42578 12.9166 1.42578Z"
      fill={props.color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.375 1.42578C14.5763 1.42578 14.7395 1.58901 14.7395 1.79036V3.2487C14.7395 3.45005 14.5763 3.61328 14.375 3.61328C14.1736 3.61328 14.0104 3.45005 14.0104 3.2487V1.79036C14.0104 1.58901 14.1736 1.42578 14.375 1.42578Z"
      fill={props.color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8333 1.42578C16.0346 1.42578 16.1979 1.58901 16.1979 1.79036V3.2487C16.1979 3.45005 16.0346 3.61328 15.8333 3.61328C15.6319 3.61328 15.4687 3.45005 15.4687 3.2487V1.79036C15.4687 1.58901 15.6319 1.42578 15.8333 1.42578Z"
      fill={props.color}
    />
    <path
      d="M15.1041 6.16536C15.1041 5.76266 15.4306 5.4362 15.8333 5.4362H17.2916V14.9154H15.8333C15.4306 14.9154 15.1041 14.5889 15.1041 14.1862V6.16536Z"
      fill={props.color}
    />
    <path
      d="M10.8333 11.1654C10.8333 10.7051 10.4602 10.332 10 10.332C9.53976 10.332 9.16667 10.7051 9.16667 11.1654V15.332C9.16667 17.173 7.67428 18.6654 5.83333 18.6654H5C3.15905 18.6654 1.66667 17.173 1.66667 15.332V11.1654C1.66667 10.7051 1.29357 10.332 0.833333 10.332C0.373096 10.332 0 10.7051 0 11.1654V18.6654C0 19.1256 0.373096 19.4987 0.833333 19.4987H19.1667C19.6269 19.4987 20 19.1256 20 18.6654V11.1654C20 10.7051 19.6269 10.332 19.1667 10.332C18.7064 10.332 18.3333 10.7051 18.3333 11.1654V15.332C18.3333 17.173 16.8409 18.6654 15 18.6654H14.1667C12.3257 18.6654 10.8333 17.173 10.8333 15.332V11.1654Z"
      fill={props.color}
    />
  </svg>
);

export default SvgTestTubes;
