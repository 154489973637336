import axiosInstance from '../axiosInstance';
import memoize from 'lodash/memoize';

const patientApi = {
  async deletePatient(patientId) {
    const patientsResponse = await axiosInstance.delete(
      `/patient/${patientId}`
    );
    return patientsResponse?.data;
  },

  async createPatient(payload) {
    const patientsResponse = await axiosInstance.post('/patient', payload);
    return patientsResponse?.data;
  },

  async bulkUpload(payload) {
    const patientsResponse = await axiosInstance.post('/patient/bulkUpload', payload);
    return patientsResponse?.data;
  },

  async updatePatient(payload) {
    const patientsResponse = await axiosInstance.put('/patient', payload);
    return patientsResponse?.data;
  },

  async getPatients(filters) {
    const params = { ...filters };
    if(!params.isDeleted) delete params.isDeleted;
    const patientsResponse = await axiosInstance.get('/patient', {
      params,
    });
    return patientsResponse?.data;
  },

  async getSharedPatients(filters) {
    const params = { ...filters };
    if(!params.isDeleted) delete params.isDeleted;
    const patientsResponse = await axiosInstance.get('/share/patient', {
      params,
    });
    return patientsResponse?.data;
  },

  getPatient: memoize(async (patientId) => {
    const patientsResponse = await axiosInstance.get(
      `/patient/${patientId.replace('Patient/', '')}`
    );
    return patientsResponse?.data;
  }, undefined, 5000), // 5000 ms = 5 seconds

  async getSharedPatient(patientId) {
    const patientsResponse = await axiosInstance.get(
      `/share/patient/${patientId.replace('Patient/', '')}`
    );
    return patientsResponse?.data;
  },

  async createPatientByIdentityUserIfNotExist() {
    const patientsResponse = await axiosInstance.post(
      `/patient/by-identity-user`
    );
    return patientsResponse?.data;
  },

  async createFhirPatientIfNotExist(patientId) {
    const patientsResponse = await axiosInstance.post(
      '/patient/by-portal-patient',
      { patientId }
    );
    return patientsResponse?.data;
  },

  getPatientBiomarkerOverview: memoize(async (patientId) => {
    const patientsResponse = await axiosInstance.get(
      '/patient/biomarker-overview',
      {
        params: { patientId },
      }
    );
    return patientsResponse?.data;
  }, undefined, 5000), // 5000 ms = 5 seconds

  async getPatientBiomakerValues(patientId) {
    const patientsResponse = await axiosInstance.get(
      '/patient/model-biomarker',
      {
        params: { patientId },
      }
    );
    return patientsResponse?.data;
  },
};

export default patientApi;
