import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';

import allergyApi from 'api/patientOverview/allergyApi';

import { Select } from 'components';

const AllergyInput = ({
  onChange = () => {},
  value = null,
  invalid,
  label = 'Allergy',
  labelTextId = '',
  optional = false,
  multiple = false,
  placeholder = 'Search for your allergy',
}) => {
  const [allergyOptions, setAllergyOptions] = useState([]);

  useEffect(() => {
    fetchAllergies({});
  }, []);

  const handleSearchChange = async (e, { searchQuery }) => {
    handleSearch(searchQuery);
  };

  const handleSearch = useCallback(
    debounce((search) => fetchAllergies({ search }), 500),
    []
  );

  const fetchAllergies = async ({ search = '' }) => {
    const allAllergies = await allergyApi.getAll({
      search,
      limit: 50,
    });

    const meds = allAllergies.map((allergy) => {
      return {
        key: allergy.name,
        value: allergy.name,
        text: allergy.name,
      }
    });

    setAllergyOptions(meds);
  }; 

  return (
    <Select
      icon={null}
      size="fullWidth"
      search
      optional={optional}
      label={label}
      labelTextId={labelTextId}
      multiple={multiple}
      placeholder={placeholder}
      onSearchChange={handleSearchChange}
      border
      value={value}
      onChange={(e, { value }) => {
        onChange(value);
      }}
      options={allergyOptions}
      invalid={invalid}
      showErrorMessage={false}
    />
  );
};

export default AllergyInput;
