import gql from 'graphql-tag';

export const getTimeslots = gql`
  query getTimeslots(
    $clientId: ID!
    $fromDate: String!
    $toDate: String!
    $isDev: Boolean
  ) {
    getTimeslots(
      clientId: $clientId
      fromDate: $fromDate
      toDate: $toDate
      isDev: $isDev
    ) {
      id
      clientId
      startTime
      endTime
      appointmentLimit
      appointments {
        id
        patientCount
        type
        name
      }
    }
  }
`;

export const getAppointmentDetails = gql`
  query getAppointmentDetails($appointmentId: ID!, $isDev: Boolean) {
    getAppointmentDetails(appointmentId: $appointmentId, isDev: $isDev) {
      id
      userId
      testTypeId
      name
      type
      staff {
        id
        name
        phone
        email
      }
      patients {
        id
        title
        fullName
        email
        gender
        phone
        nationality
        dob
        passNo
        additionalFields {
          label
          value
        }
      }
      cart {
        name
        type
        skipPayment
      }
      startTime
      endTime
      time
      paymentId
      isCompleted
      isCanceled
      unitNumber
      timeSlotId
      postCode
      address
      createdAt
      updatedAt
    }
  }
`;

export const getAppointmentTypes = gql`
  query getAppointmentTypes($clientId: ID!, $isDev: Boolean) {
    getAppointmentTypes(clientId: $clientId, isDev: $isDev) {
      name
      type
    }
  }
`;

export const getMedBookingAppointmentOverview = gql`
  query getMedBookingAppointmentOverview(
    $fromDate: String
    $toDate: String
    $clientId: ID
    $ssn: String
    $staffId: ID
    $limit: Int
    $page: Int
    $search: String
    $type: String
    $branchId: String
    $isCompleted: Boolean
    $isCanceled: Boolean
    $isDev: Boolean
  ) {
    getMedBookingAppointmentOverview(
      fromDate: $fromDate
      toDate: $toDate
      clientId: $clientId
      ssn: $ssn
      staffId: $staffId
      limit: $limit
      page: $page
      search: $search
      type: $type
      branchId: $branchId
      isCompleted: $isCompleted
      isCanceled: $isCanceled
      isDev: $isDev
    ) {
      totalPages
      appointments {
        id
        userId
        testTypeId
        owner
        startTime
        endTime
        paymentId
        payment {
          userId
          paymentId
          billingDetails {
            name
            email
            phone
            address
            postCode
            unitNumber
          }
          status
          amount
          currency
          discount
          discountDetails {
            name
            code
            discount
          }
          createdAt
        }
        patientIds
        patients {
          fullName
          email
          title
          passNo
        }
        isCompleted
        isCanceled
        address
        postCode
        unitNumber
        remark
        branchId
        type
        cart {
          name
          type
          category
          packageTests
          additionalTests
        }
        staff {
          name
        }
      }
    }
  }
`;

export const getAppointmentsPerTimeSlotId = gql`
  query getAppointmentsPerTimeSlotId($timeSlotId: ID!, $isDev: Boolean) {
    getAppointmentsPerTimeSlotId(timeSlotId: $timeSlotId, isDev: $isDev) {
      id
      _id
      clientId
      startTime
      endTime
      appointmentLimit
      appointments {
        id
        patientCount
        type
        name
      }
      patientIds
      paymentId
      owner
      address
      owner
      isCompleted
    }
  }
`;

export const getPaymentDetails = gql`
  query getPaymentDetails($paymentId: ID, $isDev: Boolean) {
    getPaymentDetails(paymentId: $paymentId, isDev: $isDev) {
      userId
      paymentId
      billingDetails {
        name
        email
        phone
        address
        postCode
        unitNumber
      }
      status
      amount
      currency
      discount
      discountDetails {
        discount
      }
    }
  }
`;

export const getTestTypes = gql`
  query getTestTypes($clientId: ID, $search: String, $isDev: Boolean) {
    getTestTypes(clientId: $clientId, search: $search, isDev: $isDev) {
      id
      name
      type
      categoryId
      category
      price
      extraFee
      extraFeeDesc
      referralPrice
      currency
      description
      duration
      packageType
      tests
      skipPayment
      hideFromMain
      packageTests {
        id
        name
        category
        description
      }
      additionalFields {
        label
        type
        required
      }
      validity
      startDate
      endDate
      expired
    }
  }
`;

export const getPackageTests = gql`
  query getPackageTests($clientId: ID, $search: String, $isDev: Boolean) {
    getPackageTests(clientId: $clientId, search: $search, isDev: $isDev) {
      id
      name
      category
      categoryId
      description
      price
      validity
      startDate
      endDate
      expired
      skipPayment
    }
  }
`;

export const getReferrals = gql`
  query getReferrals($clientId: ID, $isDev: Boolean) {
    getReferrals(clientId: $clientId, isDev: $isDev) {
      id
      clientId
      partnerId
      partnerName
      logo
      url
      appointmentIds
      hasDefaultAppointments
    }
  }
`;

export const getReferralOverview = gql`
  query getReferralOverview($clientId: ID, $isDev: Boolean) {
    getReferralOverview(clientId: $clientId, isDev: $isDev) {
      id
      clientId
      partnerId
      partnerName
      logo
      url
      cost
      appointments
    }
  }
`;

export const getReferralCsv = gql`
  query getReferralCsv($clientId: ID, $isDev: Boolean) {
    getReferralCsv(clientId: $clientId, isDev: $isDev) {
      id
      clientId
      partnerId
      partnerName
      logo
      url
      cost
      appointments
      paymentOverviewDetails {
        paymentId
        name
        appointmentDate
        paymentDate
        patientCount
        totalCost
        currency
      }
    }
  }
`;

export const getReferralOverviewDetails = gql`
  query getReferralOverviewDetails(
    $referralId: String
    $fromDate: String
    $toDate: String
    $isDev: Boolean
  ) {
    getReferralOverviewDetails(
      referralId: $referralId
      fromDate: $fromDate
      toDate: $toDate
      isDev: $isDev
    ) {
      paymentId
      name
      appointmentDate
      paymentDate
      patientCount
      totalCost
      currency
    }
  }
`;

export const getDashboardStats = gql`
  query getDashboardStats(
    $fromDate: String
    $toDate: String
    $clientId: ID
    $isDev: Boolean
  ) {
    getDashboardStats(
      fromDate: $fromDate
      toDate: $toDate
      clientId: $clientId
      isDev: $isDev
    )
  }
`;

export const getAllHomePageVersions = gql`
  query getAllHomePageVersions {
    getAllHomePageVersions {
      versions
    }
  }
`;

export const getClientDetails = gql`
  query getClientDetails($clientId: ID!, $isDev: Boolean) {
    getClientDetails(clientId: $clientId, isDev: $isDev) {
      clientId
      clientName
      alias
      domain
      currency
      logo
      country
      city
      address
      zipCode
      taxId
      email
      assistEmails
      subClients {
        _id
        name
        alias
        currency
        country
        city
        address
        zipCode
        taxId
        email
        assistEmails
        phone
        supportEmail
        supportPhone
        timezone
      }
      supportEmail
      phone
      supportPhone
      primaryColor
      hasQuestionnaire
      homePageVersion
      timezone
      cutoffTime
      skipPayment
      reminder {
        daysBefore
        time
      }
      rescheduleLimit {
        daysBeforeAppointment
        time
      }
      emailTemplates {
        emailVerification
        passwordReset
        reminder
        staffAssgined
        orderConfirmation
      }
      distributionId
      devDistributionId
      titles
    }
  }
`;

export const getAllClients = gql`
  query getAllClients($isDev: Boolean) {
    getAllClients(isDev: $isDev) {
      clientId
      clientName
      emailTemplates {
        emailVerification
        passwordReset
        reminder
        staffAssgined
        orderConfirmation
      }
      distributionId
      devDistributionId
    }
  }
`;

export const getCoupons = gql`
  query getCoupons($clientId: String, $search: String, $isDev: Boolean) {
    getCoupons(clientId: $clientId, search: $search, isDev: $isDev) {
      id
      name
      code
      discount
      appointments
      isActive
      hasMinimum
      isLimited
      validity
      endDate
      startDate
      limit
      miminumOrder
      expired
    }
  }
`;

export const verifyCouponCode = gql`
  query verifyCouponCode(
    $clientId: String
    $amount: Float
    $code: String
    $isDev: Boolean
  ) {
    verifyCouponCode(
      clientId: $clientId
      amount: $amount
      code: $code
      isDev: $isDev
    ) {
      status
      discount
    }
  }
`;

export const calcTotalPayment = gql`
  query calcTotalPayment(
    $clientId: String
    $testTypeId: String
    $additionalTestsIds: [String]
    $adultNumber: Int
    $childNumber: Int
    $couponCode: String
    $isDev: Boolean
  ) {
    calcTotalPayment(
      clientId: $clientId
      testTypeId: $testTypeId
      additionalTestsIds: $additionalTestsIds
      adultNumber: $adultNumber
      childNumber: $childNumber
      couponCode: $couponCode
      isDev: $isDev
    ) {
      totalAmount
      vatTotal
      subTotal
      additionalTestsTotal
      extrafees
      discount
    }
  }
`;

export const getAppointmentCategories = gql`
  query getAppointmentCategories($clientId: String, $isDev: Boolean) {
    getAppointmentCategories(clientId: $clientId, isDev: $isDev) {
      id
      name
    }
  }
`;

export const getPackageTypes = gql`
  query getPackageTypes($isDev: Boolean) {
    getPackageTypes(isDev: $isDev) {
      id
      name
      type
      description
    }
  }
`;

export const getTestCategories = gql`
  query getTestCategories($clientId: String, $isDev: Boolean) {
    getTestCategories(clientId: $clientId, isDev: $isDev) {
      id
      name
    }
  }
`;

export const getCharges = gql`
  query getCharges($clientId: ID!, $isDev: Boolean) {
    getCharges(clientId: $clientId, isDev: $isDev) {
      id
      clientId
      homebasedAppointment
    }
  }
`;
