import gql from 'graphql-tag';

export const getPaymentGateWays = gql`
  query getPaymentGateWays($isDev: Boolean) {
    getPaymentGateWays(isDev: $isDev) {
      id
      name
      type
    }
  }
`;

export const getAccountDetails = gql`
  query getAccountDetails($clientId: ID, $serviceId: String, $isDev: Boolean) {
    getAccountDetails(
      clientId: $clientId
      serviceId: $serviceId
      isDev: $isDev
    ) {
      id
      clientId
      serviceId
      gatewayId
      appFee
      appFeeType
      extraFee
      extraFeeType
      refundFee
      markAsDefault
      status
      paymentMethodType
    }
  }
`;