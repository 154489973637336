import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="inherit"
    height="inherit"
    fill="none"
    viewBox="0 0 25 25"
    {...props}
  >
    <g fill={props.color} clipPath="url(#a)">
      <path d="M5 14.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM1 10.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM5 6.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM1 2.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM23 14.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM19 10.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM23 6.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM19 2.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM14 24.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM10 20.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM14 16.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM10 12.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM15 3.5a2 2 0 0 1-2-2 1 1 0 0 0-2 0 2 2 0 0 1-2 2 1 1 0 0 0 0 2 2 2 0 0 1 2 2 1 1 0 0 0 2 0 2 2 0 0 1 2-2 1 1 0 1 0 0-2ZM7 19.5a2 2 0 0 1-2-2 1 1 0 1 0-2 0 2 2 0 0 1-2 2 1 1 0 0 0 0 2 2 2 0 0 1 2 2 1 1 0 1 0 2 0 2 2 0 0 1 2-2 1 1 0 0 0 0-2ZM23 19.5a2 2 0 0 1-2-2 1 1 0 0 0-2 0 2 2 0 0 1-2 2 1 1 0 0 0 0 2 2 2 0 0 1 2 2 1 1 0 0 0 2 0 2 2 0 0 1 2-2 1 1 0 0 0 0-2Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={props.color} d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
