import React from 'react';

const SvgAudience = props => {
  return (
    <svg width="1.1em" height="1em" viewBox="0 0 30 21" {...props}>
      <path
        d="M22.85 8.588c3.311 0 5.252 1.693 5.822 5.08A2 2 0 0126.7 16l-4.116-.002c.047.236.087.48.122.73A2 2 0 0120.724 19H9.976a2 2 0 01-1.982-2.272c.035-.25.075-.494.122-.73L4 16a2 2 0 01-1.972-2.332c.57-3.386 2.51-5.08 5.822-5.08 2.086 0 3.629.672 4.627 2.017.842-.27 1.8-.405 2.873-.405 1.073 0 2.03.135 2.873.405.998-1.345 2.54-2.017 4.627-2.017zM15.183 2.2a4 4 0 110 8 4 4 0 010-8zM7.45 2c1.767 0 3.2 1.475 3.2 3.294 0 1.82-1.433 3.294-3.2 3.294-1.767 0-3.2-1.475-3.2-3.294C4.25 3.474 5.683 2 7.45 2zm15 0c1.767 0 3.2 1.475 3.2 3.294 0 1.82-1.433 3.294-3.2 3.294-1.767 0-3.2-1.475-3.2-3.294 0-1.82 1.433-3.294 3.2-3.294z"
        stroke={props.color}
        strokeWidth={2.5}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgAudience.defaultProps = {
  color: '#1b1b1b',
};

export default SvgAudience;
