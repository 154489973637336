import React, { useEffect, useState } from 'react';
import { Loader, Popup } from 'semantic-ui-react';
import moment from 'moment';
import cx from 'classnames';

import { Text } from 'components';

import history from '../../history';
import idUserService from 'api/identity/user';

import './labResultBasicInfo.scss';

const PatientDetails = ({
  patient,
  result,
  encounter,
  isPatientView,
  isReactSession,
  isLoading,
}) => {
  const [uploadedBy, setUploadedBy] = useState(null);
  const display =
    result.testProfile && !!result.testProfile?.split(',')?.length
      ? result?.testProfile
      : result?.display;

  useEffect(() => {
    if (result?.uploadedBy) getUser();
  }, [result]);

  const getUser = async () => {
    try {
      const {
        data: { user },
      } = await idUserService.getUserBySafeId(result?.uploadedBy);
      setUploadedBy(user?.metadata?.name);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="pat-details">
      {isLoading ? (
        <div className="pat-details__loader-wrapper">
          <Loader
            active
            className="pat-details__loader-wrapper__loader"
            size="small"
          />
        </div>
      ) : (
        <>
          {!isPatientView && !isReactSession && (
            <div className="pat-details__wrapper">
              <div
                className="pat-details__intro"
                onClick={
                  isPatientView
                    ? () => {}
                    : () => history.push(`/patients/${patient.id}`)
                }
              >
                <Text dark size="bigger" uppercase className="mb-2">
                  {patient.fullName}
                </Text>
                <Text size="small" uppercase className="mb-2">
                  {patient.gender}
                </Text>
                <Text size="small" className="mb-2 pat-details__intro__age">
                  {moment(patient.dateOfBirth).format('DD-MM-YYYY')}{' '}
                  <Text className="ml-1" size="tinniest">
                    ({moment().diff(patient.dateOfBirth, 'years')} yrs)
                  </Text>
                </Text>
                {patient.nationality && (
                  <Text size="small" uppercase className="mb-2">
                    {patient.nationality}
                  </Text>
                )}
                {patient.phoneNumber && (
                  <Text size="small" className="mb-2">
                    Tel: {patient.phoneNumber}
                  </Text>
                )}
                {patient.email && (
                  <Text size="small" className="mb-2">
                    {patient.email}
                  </Text>
                )}
              </div>
            </div>
          )}
          <div className="pat-details__res-details-wrapper">
            <div className="pat-details__res-details-wrapper__rows">
              <div className="pat-details__detail mb-4">
                <>
                  <Text id="results_inbox.test\/profiles" type="dataLabel">
                    Tests/Profiles
                  </Text>
                  <Text type="data" uppercase>
                    {/* {result.display} */}
                    <div className="pat-details__display">
                      <div className="pat-details__display__text">
                        {display.split(',')[0]}
                      </div>
                      {display.split(',').length !== 1 ? (
                        <Popup
                          position="top right"
                          hoverable
                          trigger={
                            <div style={{ cursor: 'pointer' }}>
                              <Text
                                size="tiny"
                                bold
                                className="pat-details__displays"
                              >
                                + {`${display.split(',').length - 1}`}
                              </Text>
                            </div>
                          }
                        >
                          <Popup.Header>
                            <Text id="results_inbox.test\/profiles">
                              Tests/Profiles
                            </Text>
                          </Popup.Header>
                          {display.split(',').map((panel) => (
                            <Popup.Content key={panel}>
                              <div className="order-verification__popup__panel">
                                <Text size="tiny">- {panel}</Text>
                              </div>
                            </Popup.Content>
                          ))}
                        </Popup>
                      ) : (
                        ''
                      )}
                    </div>
                  </Text>
                </>
                {/* ) : (
                  <Text dark size="bigger" className="mb-4" uppercase>
                    {result.display}
                  </Text>
                )} */}
              </div>
              <div className="pat-details__res-wrapper">
                {encounter && (
                  <div className="pat-details__detail">
                    <Text type="dataLabel">
                      Participant{' '}
                      {encounter?.participants?.length > 1 ? 's' : ''}
                    </Text>
                    <Text type="data" uppercase>
                      {encounter?.participants
                        ?.map((p) => p.display)
                        .join(', ')}
                    </Text>
                  </div>
                )}

                {/* <div className="pat-details__detail">
                  <Text type="dataLabel">ACSN</Text>
                  <Text type="data" uppercase>
                    {result.bundleId}
                  </Text>
                </div> */}

                {!!result?.order?.id && (
                  <div className="pat-details__detail">
                    <Text type="dataLabel">Order ID xxx</Text>
                    <Text type="data" uppercase>
                      {result.order.id}
                    </Text>
                  </div>
                )}
                {!!result?.issuedDate && (
                  <div className="pat-details__detail">
                    <Text type="dataLabel">Reported</Text>
                    <Text type="data" uppercase>
                      {moment(result.issuedDate).format('DD-MM-YYYY, HH:mm')}
                    </Text>
                  </div>
                )}
                {!!result?.uploadedBy && uploadedBy && (
                  <div className="pat-details__detail">
                    <Text type="dataLabel">Reported by</Text>
                    <Text type="data" uppercase>
                      {uploadedBy}
                    </Text>
                  </div>
                )}
                <div className="pat-details__detail">
                  <Text type="dataLabel">Status</Text>
                  <div className="pat-details__status">
                    <div
                      className={cx('pat-details__status__dot', {
                        'pat-details__status__dot--red':
                          result.status !== 'final' &&
                          result.status !== 'completed',
                        'pat-details__status__dot--green':
                          result.status === 'final' ||
                          result.status === 'completed',
                      })}
                    />
                    <Text type="data" uppercase>
                      {result.status}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

{
  /* <div className="patient-details__detail">
<Text className="patient-details__detail__header" color="grey" size="small" dark>Name</Text>
<Text className="patient-details__detail__data">{patientDetails.familyName}</Text>
</div>

{patientDetails.identifier?.passportNumber && (
<div className="patient-details__detail">
  <Text className="patient-details__detail__header" color="grey" size="small" dark>Passport Number</Text>
  <Text className="patient-details__detail__data">{patientDetails.identifier?.passportNumber}</Text>
</div>
)}

<div className="patient-details__detail">
<Text className="patient-details__detail__header" color="grey" size="small" dark>Gender</Text>
<Text className="patient-details__detail__data" uppercase>{patientDetails.gender}</Text>
</div>

{patientDetails.birthDate && (
<div className="patient-details__detail">
  <Text className="patient-details__detail__header" color="grey" size="small" dark>Date of Birth</Text>
  <Text className="patient-details__detail__data">{momentSg(patientDetails.birthDate).format('DD-MM-YYYY')}</Text>
</div>
)}
{patientDetails.nationality && (
<div className="patient-details__detail">
  <Text className="patient-details__detail__header" color="grey" size="small" dark>Nationality</Text>
  <Text className="patient-details__detail__data">{patientDetails.nationality}</Text>
</div>
)}

{patientDetails.email && (
<div className="patient-details__detail">
  <Text className="patient-details__detail__header" color="grey" size="small" dark>Email</Text>
  <Text className="patient-details__detail__data">{patientDetails.email}</Text>
</div>
)}

{patientDetails.telecom?.phone && (
<div className="patient-details__detail">
  <Text className="patient-details__detail__header" color="grey" size="small" dark>Phone Number</Text>
  <Text className="patient-details__detail__data">{patientDetails.telecom?.phone}</Text>
</div>
)} */
}

export default PatientDetails;
