import React from 'react';

const SvgFilledRibbonBadge = props => (
  <svg width="1em" height="1em" viewBox="0 0 22 32" {...props}>
    <g stroke="#1b1b1b" strokeWidth={3} fill="none" fillRule="evenodd">
      <circle cx={8.5} cy={8.5} r={8.5} transform="translate(3 2)" />
      <circle
        cx={1.5}
        cy={1.5}
        r={1.5}
        transform="translate(10 9)"
        fill="#FFF"
      />
      <path d="M7.486 18L6 29l5.5-2.214L17 29l-1.187-11" />
    </g>
  </svg>
);

export default SvgFilledRibbonBadge;
