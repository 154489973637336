import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { Icon, Text } from 'components';
import TableRow from './TableRow';

import { sortOrder } from 'api/fhir/observation/observationMapper';

const TableCatRow = ({
  categoryKey,
  categoryDescriptions,
  observationsByCat,
  values,
  graphData,
  onRowClick,
  expandAll,
  setExpandAll,
}) => {
  const [showCatItems, setShowCatItems] = useState(false);

  useEffect(() => {
    if (expandAll === 'all-expanded') setShowCatItems(true);
    if (expandAll === 'all-collapsed') setShowCatItems(false);
  }, [expandAll]);

  return (
    <div className={`m-obs__cat-row-wrapper${showCatItems ? ' m-obs__cat-row-wrapper--expanded': ''}`}>
      <div
        className={cx('m-obs__row m-obs__cat-row', {
          'm-obs__row--high':
            observationsByCat[categoryKey]?.abnormalCount || 0 >
            observationsByCat[categoryKey]?.normalCount || 0,
        })}
        onClick={() => {
          setExpandAll('neutral');
          setShowCatItems(!showCatItems);
        }}
      >
        <div className='m-obs__cat-row__content'>
          <Text size="big" dark className="mb-1">
            {categoryKey !== 'undefined' ? categoryDescriptions[categoryKey]?.category : 'Other'}
          </Text>
          {!!observationsByCat[categoryKey]?.abnormalCount && (
            <div className="abnormal-counts">
              <div className="abnormal-counts__wrapper">
                <div className="abnormal-counts__icon__wrapper">
                  <img
                    width={18}
                    className="abnormal-counts__icon"
                    src={values.abnormal.icon}
                    alt="abnormal"
                  />
                </div>
                <div className="abnormal-counts__text">
                  <Text size="small">
                    {`Abnormal (${String(
                      observationsByCat[categoryKey]?.abnormalCount || 0
                    ).padStart(2, '0')})`}
                  </Text>
                </div>
              </div>
            </div>
          )}
          <div className="abnormal-counts">
            <div className="abnormal-counts__wrapper">
              <div className="abnormal-counts__icon__wrapper">
                <img
                  width={18}
                  className="abnormal-counts__icon"
                  src={values.normal.icon}
                  alt="normal"
                />
              </div>
              <div className="abnormal-counts__text">
                <Text size="small">
                  {`Normal (${String(
                    observationsByCat[categoryKey]?.normalCount || 0
                  ).padStart(2, '0')})`}
                </Text>
              </div>
            </div>
          </div>
        </div>
        <Icon fontSize="20px" color="#1C1C1C" name={showCatItems ? "angleDown" : "angleRight"} />
      </div>
      {showCatItems &&
        sortOrder.map(
          (observationKey) => (
            <>
              {observationsByCat[categoryKey][observationKey]?.range && (
                <>
                  {observationsByCat[categoryKey][observationKey]?.range.map(
                    (observation) => (
                      <TableRow
                        observation={observation}
                        values={values}
                        onRowClick={onRowClick}
                        graphData={graphData}
                      />
                    )
                  )}
                </>
              )}
              {observationsByCat[categoryKey][observationKey]?.value && (
                <>
                  {observationsByCat[categoryKey][observationKey]?.value.map(
                    (observation) => (
                      <TableRow
                        observation={observation}
                        values={values}
                        onRowClick={onRowClick}
                        graphData={graphData}
                      />
                    )
                  )}
                </>
              )}
            </>
          )
        )}
    </div>
  );
};

export default TableCatRow;
