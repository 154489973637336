import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { Text, Button, ErrorToast, SuccessToast } from 'components';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import SmartReportConsent from './components/SmartReportConsent';
import SmartReport from './components/smartReport/SmartReport';
import pdfnetApi from 'api/pdfnetApi/pdfnetApi';
import patientReactSessionApi from 'api/patientReactSession';

import './smartReport.scss';

const SmartReportWrapper = ({
  isModalToggled,
  toggleModal,
  patient,
  observations,
  categoryDescriptions,
  graphData,
  report,
  setDiagnosticReport,
  riskModelsSummary,
  vitals,
  setViewType,
  isReactSession,
  reactSessionReports,
  extGenerateReportFunc,
  isLoading,
  setRerenderTrigger,
}) => {
  const clientInfo = useSelector((state) => state.general.clientInformation);
  const userInfo = useSelector((state) => state.general.user);

  const [hasConsent, setHasConsent] = useState(false);
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [isSendEmail, setSendEmail] = useState(false);

  useEffect(() => {
    setHasConsent(false);
  }, [isModalToggled])

  const generateReport = async () => {
    if (extGenerateReportFunc) return extGenerateReportFunc({ isSendEmail });

    try {
      setGeneratingPdf(true);
      const clientId = userInfo.currentClientId;

      const data = await generateApi(report._id, clientId);

      // setRerenderTrigger(moment().toISOString());
      setDiagnosticReport({ ...report, smartReportUrl: data.pdfPath });
      toast.success(<SuccessToast message="ReACT report generated" />);
      setViewType && setViewType('smartRep');
      toggleModal(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setGeneratingPdf(false);
    }
  };

  const generateApi = async () => {
    const clientId = userInfo.currentClientId;

    if (isReactSession) {
      const { data } = await patientReactSessionApi.issueReport({ id: isReactSession, isSendEmail });
      return data?.data;
    }

    const { data } = await pdfnetApi.generateReactReport(report._id, clientId, isSendEmail);
    return data;
  };

  return (
    <div className="smart-rep-wrapper">
      <Modal
        className="smart-rep-wrapper__content"
        open={isModalToggled}
        onClose={() => toggleModal(false)}
        closeIcon
        size={hasConsent ? '' : 'small'}
      >
        <Modal.Content>
          <div
            className={`smart-rep-wrapper__header ${
              hasConsent ? 'smart-rep-wrapper__header--pdf' : ''
            }`}
          >
            <div className="smart-rep-wrapper__header__section">
              {clientInfo.logo && (
              <img
                src={clientInfo.logo}
                alt="logo"
                className={`smart-rep-wrapper__header${
                  !hasConsent ? '__consent' : ''
                }__logo`}
              />
              )}
              <div className="smart-rep-wrapper__header__text">
                <Text
                  id="react_single.react_report"
                  bold
                  size="big"
                  className="mr-2"
                >
                  ReACT Report
                </Text>
                <Text
                  id="react_report.powered_by"
                  size="tinniest"
                  color="lightGrey"
                >
                  powered by Riverr
                </Text>
              </div>
            </div>
            {hasConsent && (
              <div className="smart-rep-wrapper__header__section">
                <Button
                  color="blue"
                  onClick={generateReport}
                  isLoading={isLoading || generatingPdf}
                  isDisabled={isLoading || generatingPdf}
                  textId="react_report.generate"
                >
                  Generate
                </Button>
              </div>
            )}
          </div>
          {hasConsent ? (
            <SmartReport
              patient={patient}
              observations={observations}
              graphData={graphData}
              categoryDescriptions={categoryDescriptions}
              doctorsNotes={report.doctorNote}
              riskModelsSummary={riskModelsSummary}
              vitals={vitals}
              reactSessionReports={reactSessionReports}
            />
          ) : (
            <SmartReportConsent
              patientEmail={patient?.email}
              setHasConsent={setHasConsent}
              hasSmartReport={!!report.smartReportUrl}
              hasDoctorsNotes={!!report.doctorNote}
              setSendEmail={setSendEmail}
              isSendEmail={isSendEmail}
              isReactSession={isReactSession}
            />
          )}
        </Modal.Content>
      </Modal>
    </div>
  );
};

SmartReportWrapper.propTypes = {
  label: PropTypes.string,
  src: PropTypes.string,
};

export default SmartReportWrapper;
