export const SET_PROFILE_SUMMARY = 'PATIENT/SET_PROFILE_SUMMARY';
export const SET_RISK_SUMMARY = 'PATIENT/SET_RISK_SUMMARY';
export const SET_BIOMAKER_VALUES = 'PATIENT/SET_BIOMAKER_VALUES';

/**
 * @function setProfileSummary
 * @description Set information of the logged in client
 */
export const setProfileSummary = (summary) => ({
  type: SET_PROFILE_SUMMARY,
  payload: summary,
});

/**
 * @function setRiskSummary
 * @description Set information of the logged in client
 */
export const setRiskSummary = (summary) => ({
  type: SET_RISK_SUMMARY,
  payload: summary,
});

/**
 * @function setBiomakerValues
 * @description Set information of the logged in client
 */
export const setBiomakerValues = (value) => ({
  type: SET_BIOMAKER_VALUES,
  payload: value,
});
