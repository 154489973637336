import { createAxiosInstance } from './axiosInstance';

const axiosInstance = createAxiosInstance(
  process.env.REACT_APP_API_V1_END_POINT
);

const requestHandler = (request) => {
  if (
    localStorage.getItem('AUTH_TOKEN') &&
    JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token
  ) {
    request.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token
    }`;
  }
  // if(localStorage.getItem('USER_SAFE_ID')) {
  //   request.headers['x-safe-id'] = localStorage.getItem('USER_SAFE_ID');
  // }

  if (localStorage.getItem('LOGIN_TYPE') === 'patient') {
    request.headers['x-universal-id'] = 'PATIENT';
  }

  return request;
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);


export default axiosInstance;
