import React from 'react';

const SvgLightning = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 21" {...props}>
    <path
      d="M9 8h4.138a.5.5 0 01.435.748L6.934 20.365A.5.5 0 016 20.117V11H1.982a.5.5 0 01-.404-.794l6.518-8.962A.5.5 0 019 1.538V8z"
      stroke="#1b1b1b"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgLightning;
