import React from 'react';

const SvgDiagonalArrowUp = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 17" {...props}>
    <g
      stroke="#1b1b1b"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path d="M7.121 1.879l8.3.213a.5.5 0 01.487.487l.213 8.3M15.387 2.613L2.782 15.218" />
    </g>
  </svg>
);

export default SvgDiagonalArrowUp;
