export const SET_TIMESLOTS = 'CALENDAR/SET_TIMESLOTS';
export const SET_PEOPLE = 'CALENDAR/SET_PEOPLE';
export const SET_TYPES = 'CALENDAR/SET_TYPES';

/**
 * @function setEvents
 * @description update course details in redux state
 */
export const setTimeSlots = payload => ({
  type: SET_TIMESLOTS,
  payload,
});

/**
 * @function setCourseNames
 * @description update course details in redux state
 */
export const setPeople = payload => ({
  type: SET_PEOPLE,
  payload,
});

/**
 * @function setTypes
 * @description update course details in redux state
 */
export const setTypes = payload => ({
  type: SET_TYPES,
  payload,
});
