import React from 'react';
import PlaceholderPage from 'features/patientApp/PlaceholderCard/PlaceholderCard';
import PageHeader from '../PageHeader/PageHeader';
import emergencySvg from 'assets/rhc/emergencyContact/emergency.svg';

import './emergencyContact.scss';

const EmergencyContact = ({ backPath, onButtonClick }) => {
  return (
    <>
      <div className="emergencyContact__header">
        <PageHeader title={'Your emergency contact'} backPath={backPath} />
      </div>
      <PlaceholderPage
        icon={emergencySvg}
        header={`Nominate your emergency contact could make all the difference in a pinch!`}
        text="Choosing an emergency contact is like having a safety net – quick, easy, and crucial for your well-being. Pick someone you trust, and you're all set!"
        buttonText="Nominate Now"
        onButtonClick={onButtonClick}
        buttonIcon
      />
    </>
  );
};
export default EmergencyContact;
