import React, { useEffect, useState } from 'react';
import { Loader, Dropdown } from 'semantic-ui-react';

import {
  Text,
  Select,
  Cleave,
  Input,
  Button,
} from '../../../../../../components';
import './doseSection.scss';

const DoseSection = ({
  errors,
  doseData = {},
  setDoseData,
  doseCount = 1,
  loadingVac,
  pastVacs,
  linkId,
  setLinkId,
  physicians,
  clinics,
  dosage,
  setDose,
  onRemove,
}) => {
  const [isToggled, setToggle] = useState(doseCount === 1);

  useEffect(() => {
    if (dosage === 'partial') setToggle(true);
  }, [dosage]);

  const onRemovePress = () => {
    onRemove();
    setToggle(!isToggled);
  };

  return (
    <div className="dose-section">
      <div className="appointment-details__modal-content__column">
        <div className="dose-section__header">
          {dosage === 'partial' ? (
            <span className="dose-section__header__select">
              Dose
              <Dropdown
                className="dose-section__header__selector"
                options={[{ text: '1', value: 1 }, { text: '2', value: 2 }]}
                value={doseCount}
                onChange={(e, { value }) => setDose(value)}
              />
            </span>
          ) : (
            <span>
              {dosage === 'booster' ? 'Booster' : `Dose ${doseCount}`}
            </span>
          )}
          {isToggled &&
            doseCount !== 1 &&
            dosage !== 'partial' &&
            dosage !== 'booster' && (
              <Button
                className="dose-section__header__button"
                inverted
                color="red"
                size="small"
                onClick={() => onRemovePress()}
              >
                Remove
              </Button>
            )}
        </div>
        {isToggled ? (
          <div className="dose-section__content">
            {dosage === 'partial' && doseCount === 2 && (
              <div className="dose-section__content__first-dose">
                {loadingVac ? (
                  <div className="appointment-details__modal-content__inputs-wrapper--info__loader">
                    <Loader active size="tiny" />
                  </div>
                ) : (
                  <>
                    <Select
                      label="Connect to first dose"
                      size="fullWidth"
                      placeholder="Select First dose"
                      border
                      search
                      icon={false}
                      options={pastVacs}
                      value={linkId}
                      onChange={(e, { value }) => setLinkId(value)}
                      invalid={errors.linkId}
                    />
                    <Text color="grey" size="tiny">
                      *Select a first dose to link the second dose
                    </Text>
                  </>
                )}
              </div>
            )}
            <div className="appointment-details__modal-content__inputs-wrapper">
              <div className="appointment-details__modal-content__input">
                <Text type="label">Vaccination date</Text>
                <div className="appointment-details__modal-content__input--row">
                  <Cleave
                    size="fullWidth"
                    style={{ width: '200px' }}
                    placeholder="DD/MM/YYYY"
                    options={{
                      date: true,
                      datePattern: ['d', 'm', 'Y'],
                      delimiter: '/',
                    }}
                    onChange={e =>
                      setDoseData(doseCount, 'collectionDate', e.target.value)
                    }
                    value={doseData.collectionDate}
                    invalid={errors.collectionDate}
                  />
                  <div className="ml-2">
                    <Cleave
                      style={{ width: '100px' }}
                      size="fullWidth"
                      placeholder="hh:mm"
                      options={{
                        time: true,
                        timePattern: ['h', 'm'],
                      }}
                      onChange={e =>
                        setDoseData(doseCount, 'collectionTime', e.target.value)
                      }
                      value={doseData.collectionTime}
                      invalid={errors.collectionTime}
                      errorMessage=" "
                    />
                  </div>
                </div>
                <Text size="tiny" color="grey" className="mt-1">
                  *Time should be according to your current timezone
                </Text>
              </div>
              {/* <div className="appointment-details__modal-content__input">
              <Select
                label="Country of Vaccination"
                size="fullWidth"
                border
                search
                icon={false}
                placeholder="Select vaccination country"
                options={countries}
                value={country}
                onChange={(e, { value }) => setCountry(value)}
                invalid={errors.country}
              />
            </div> */}
              {/* <div className="appointment-details__modal-content__input">
                <Select
                  label="Vaccinator"
                  size="fullWidth"
                  border
                  search
                  icon={false}
                  placeholder="Select a Vaccinator"
                  options={physicians}
                  value={doseData.vaccinatorName}
                  onChange={(e, { value }) =>
                    setDoseData(doseCount, 'vaccinatorName', value)
                  }
                  invalid={errors.vaccinatorName}
                />
              </div> */}
              <div className="appointment-details__modal-content__input">
                <Select
                  label="Clinic/Facility of vaccination"
                  size="fullWidth"
                  placeholder="Select clinic/facility"
                  border
                  search
                  icon={false}
                  options={clinics}
                  value={doseData.location}
                  onChange={(e, { value }) =>
                    setDoseData(doseCount, 'location', value)
                  }
                  invalid={errors.location}
                />
              </div>
            </div>
            <div className="appointment-details__modal-content__inputs-wrapper">
              <div className="appointment-details__modal-content__input">
                <Select
                  label="Vaccine"
                  size="fullWidth"
                  placeholder="Select vaccine type"
                  border
                  search
                  icon={false}
                  options={[
                    { text: 'Pfizer-BioNTech', value: 'Pfizer-BioNTech' },
                    { text: 'Moderna', value: 'Moderna' },
                    {
                      text: 'Johnson & Johnson / Janssen',
                      value: 'Johnson & Johnson / Janssen',
                    },
                    { text: 'Oxford–AstraZeneca', value: 'Oxford–AstraZeneca' },
                    { text: 'Sinovac-CoronaVac', value: 'Sinovac-CoronaVac' },
                    { text: 'SinoPharm', value: 'SinoPharm' },
                  ]}
                  value={doseData.vaccineName}
                  onChange={(e, { value }) =>
                    setDoseData(doseCount, 'vaccineName', value)
                  }
                  invalid={errors.vaccineName}
                />
              </div>
              {/* <div className="appointment-details__modal-content__input">
                <Select
                  label="Injection Area"
                  size="fullWidth"
                  placeholder="Select injection area"
                  border
                  search
                  icon={false}
                  options={[
                    { text: 'Left arm', value: 'Left arm' },
                    { text: 'Right arm', value: 'Right arm' },
                  ]}
                  value={doseData.injectionArea}
                  onChange={(e, { value }) =>
                    setDoseData(doseCount, 'injectionArea', value)
                  }
                  invalid={errors.injectionArea}
                />
              </div> */}
              {/* <div className="appointment-details__modal-content__input">
                <Select
                    label="Slot Name"
                    size="fullWidth"
                    border
                    search
                    icon={false}
                    options={labs}
                    value={lab}
                    onChange={(e, { value }) => setLab(value)}
                    invalid={errors.lab}
                    />
                </div> */}
              {/* <div className="appointment-details__modal-content__input">
                <Select
                  label="Dosage Strength"
                  size="fullWidth"
                  placeholder="Select Dosage strength"
                  border
                  search
                  icon={false}
                  options={[
                    { text: '0.3mL', value: '0.3mL' },
                    { text: '0.5mL', value: '0.5mL' },
                  ]}
                  value={doseData.dosageStrength}
                  onChange={(e, { value }) =>
                    setDoseData(doseCount, 'dosageStrength', value)
                  }
                  invalid={errors.dosageStrength}
                />
              </div> */}
              <div className="appointment-details__modal-content__input">
                <Input
                  size="fullWidth"
                  label="Lot no."
                  border
                  onChange={e =>
                    setDoseData(doseCount, 'lotNumber', e.target.value)
                  }
                  value={doseData.lotNumber}
                  invalid={errors.lotNumber}
                  placeholder="Lot no."
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="dose-section__add-content">
            <Button color="blue" onClick={() => setToggle(!isToggled)}>
              Add dose
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DoseSection;
