import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { Router } from 'react-router-dom';
import { UserProvider } from 'hooks';

import store from './store';
import history from './history';

import { MainRoutes } from './routes/routes';
import { apolloClient } from './apollo/apollo';
import { ToastContainer, toast, Slide } from 'react-toastify';
import LanguageWrapper from './components/LanguageWrapper';

import 'react-toastify/dist/ReactToastify.css';
import './styles/main.scss';
import 'semantic-ui-css/semantic.min.css';
import 'react-phone-number-input/style.css';
// import 'react-image-crop/lib/ReactCrop.scss';

ReactDOM.render(
  <LanguageWrapper>
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <Router history={history}>
          <UserProvider>
            <ToastContainer
              autoClose={3000}
              hideProgressBar={true}
              limit={2}
              position={toast.POSITION.TOP_CENTER}
              theme="colored"
              transition={Slide}
            />
            <MainRoutes />
          </UserProvider>
        </Router>
      </Provider>
    </ApolloProvider>
  </LanguageWrapper>,

  document.getElementById('root')
);
