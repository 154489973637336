import { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import pusherConfig from '../../src/pusherConfig';
import { API_V1_ENDPOINT } from 'constants';

let pusher

export const usePusher = (user, token, event) => {
  const [userEvent, setUserEvent] = useState({});
  const [clientEvent, setClientEvent] = useState({});

  useEffect(() => {
    if (!user || !token) return;

    if (!pusher) {
      pusher = new Pusher(pusherConfig.key, {
        ...pusherConfig,
        channelAuthorization: {
          endpoint: `${API_V1_ENDPOINT}/pubsub/auth`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
    }

    const userChannelName = `private-USER-${user.safe_id}`;
    // const event = 'LAB-RESULT_UPLOAD_COMPLETE';
    const userChannel = pusher.subscribe(userChannelName);
    userChannel.bind(event, (data) => {
      setUserEvent(data);
    });

    const clientChannelName = `private-CLIENT-${user.currentClientId}`;
    const clientChannel = pusher.subscribe(clientChannelName);
    clientChannel.bind(event, (data) => {
      setClientEvent(data);
    });

    // Clean up the subscription on component unmount
    return () => {
      clientChannel.unbind(event);
      userChannel.unbind(event);
      pusher.unsubscribe(clientChannelName);
      pusher.unsubscribe(userChannelName);
    };
  }, [token, user]);

  return { userEvent, clientEvent };
}
