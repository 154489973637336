import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../index';
import './imageCard.scss';

// TODO: Make this like the designs, click to see next items instead of scroll
const ImageCard = (props) => {
  const getType = () => {
    if (props.type === 'PCR_TEST') return 'PCR';
    return props.type;
  };

  return (
    <div className="image-card" onClick={props.onClick}>
      <div className="image-card__image-wrapper">
        {props.src ? (
          <img
            className="image-card__image-wrapper__image"
            alt="img"
            src={props.src}
          />
        ) : (
          <div className="image-card__image-wrapper__image-placeholder" />
        )}
      </div>
      <div className="image-card__footer">
        <Text dark size="big">
          {props.label}
        </Text>
        {props.type && (
          <Text size="small" className="mt-2">
            Type: {getType()}
          </Text>
        )}
      </div>
    </div>
  );
};

ImageCard.propTypes = {
  label: PropTypes.string,
  src: PropTypes.string,
};

export default ImageCard;
