import React from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Text, RichTextEditor } from '../../../../../components';
import { convertFromRaw, EditorState } from 'draft-js';

import './fftCertificate.scss';

const FftCertificate = props => {
  const formatSignerRole = role => {
    return role.charAt(0) + role.slice(1).toLowerCase();
  };

  const renderSigners = () => {
    return props.certificate.signers.map(signer => {
      if (!signer || (!signer.name && !signer.image)) return null;
      return (
        <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
          <div className="certificate__content__data-wrapper__signature-wrapper">
            <img
              className="certificate__content__data-wrapper__signature-wrapper__signature"
              src={signer.image}
              alt="img"
            />
            <div className="certificate__content__data-wrapper__signature-wrapper__signature-footer">
              {signer.name}, {formatSignerRole(signer.role)}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={'certificate ' + props.className} ref={props.certRef}>
      <div className="certificate__content">
        {props.certificate.isRevoked && (
          <div className="certificate__content__revoked">
            <div>Document revoked</div>
          </div>
        )}
        {props.certificate.logoImage && (
          <div className="certificate__content__logo-wrapper">
            <img
              className="certificate__content__logo-wrapper__logo"
              src={props.certificate.logoImage}
              alt="logo"
            />
          </div>
        )}
        <div className="certificate__content__row">
          <div className="certificate__content__data-wrapper">
            <div className="certificate__content__data-wrapper__data">
              <Text size="huge" dark color="black">
                {props.certificate.name} Document
              </Text>
            </div>
          </div>
        </div>

        <div className="certificate__content__row">
          <div className="certificate__content__data-wrapper">
            <div className="certificate__content__data-wrapper__label">
              Document holder
            </div>
            <div className="certificate__content__data-wrapper__data">
              <Text bold size="bigger" color="black">
                {props.certificate.holderName}
              </Text>
            </div>
          </div>
        </div>

        {props.certificate.certificateInformation && (
          <div className="certificate__content__row">
            <div className="certificate__content__data-wrapper">
              <RichTextEditor
                editorState={EditorState.createWithContent(
                  convertFromRaw(
                    JSON.parse(props.certificate.certificateInformation)
                  )
                )}
                viewer={true}
                readOnly={true}
              />
            </div>
          </div>
        )}

        <div className="certificate__content__row">
          <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
            <div className="certificate__content__data-wrapper__label">
              Status
            </div>
            <div className="certificate__content__data-wrapper__important-data">
              <Text
                dark
                color={
                  moment
                    .utc(props.certificate.validUntil)
                    .endOf('day')
                    .toISOString() > moment().toISOString()
                    ? '#37bd99'
                    : '#fe3738'
                }
              >
                {moment
                  .utc(props.certificate.validUntil)
                  .endOf('day')
                  .toISOString() > moment().toISOString()
                  ? 'VALID'
                  : 'EXPIRED'}
              </Text>
            </div>
          </div>
          <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
            <div className="certificate__content__data-wrapper__label">
              Result
            </div>
            <div className="certificate__content__data-wrapper__important-data">
              <Text dark>
                {props.certificate.isFit
                  ? 'FIT FOR TRAVEL'
                  : 'NOT FIT FOR TRAVEL'}
              </Text>
            </div>
          </div>
        </div>

        <div className="certificate__content__row">
          <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
            <div className="certificate__content__data-wrapper__label">
              Physician License no.
            </div>
            <div className="certificate__content__data-wrapper__data">
              <Text bold color="black">
                {props.certificate.physicianLicense}
              </Text>
            </div>
          </div>

          <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
            <div className="certificate__content__data-wrapper__label">
              OGUK Pin
            </div>
            <div className="certificate__content__data-wrapper__data">
              <Text bold color="black">
                {props.certificate.pinNo}
              </Text>
            </div>
          </div>
        </div>

        <div className="certificate__content__row">
          <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
            <div className="certificate__content__data-wrapper__label">
              Examination date
            </div>
            <div className="certificate__content__data-wrapper__data">
              <Text bold color="black">
                {moment.utc(props.certificate.issuedAt).format('DD-MM-YYYY')}
              </Text>
            </div>
          </div>

          <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
            <div className="certificate__content__data-wrapper__label">
              Expiry date
            </div>
            <div className="certificate__content__data-wrapper__data">
              <Text bold color="black">
                {props.certificate.validUntil === '4100716800' ||
                props.certificate.validUntil === '32501952000'
                  ? 'Unlimited'
                  : moment
                      .utc(props.certificate.validUntil)
                      .format('DD-MM-YYYY')}
              </Text>
            </div>
          </div>
        </div>

        <div className="certificate__content__row">
          {renderSigners()}
          <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
            {props.showQrCode && (
              <img
                className="certificate__content__data-wrapper__qr-code--solo"
                src={props.certificate.qrCodeImage}
                alt="qrcode"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(FftCertificate);
