import React from 'react';

const SvgPin = props => (
  <svg width="1em" height="1em" viewBox="0 0 31 31" {...props}>
    <path
      d="M16 30.668C10 20.059 7 13.124 7 9.86 7 4.967 11.03 1 16 1s9 3.967 9 8.861c0 3.263-3 10.198-9 20.807zM16 15a5 5 0 100-10 5 5 0 000 10z"
      fill="#1b1b1b"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgPin;
