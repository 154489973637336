import gql from 'graphql-tag';

export const issueCertificateTemplate = gql`
  mutation certificateTemplateSet(
    $name: String
    $adminId: ID
    $clientId: ID
    $trainingProviderAddress: String
    $trainingProviderCity: String
    $trainingProviderZip: String
    $trainingProviderCountry: String
    $location2: String
    $logoImage: String
    $stampImage: String
    $certificateInformation: String
    $watermarkImage: String
    $locationImage: String
    $signers: String
    $hasSSN: Boolean
    $hasExpiration: Boolean
    $hasCourseDates: Boolean
    $certificateName: String
    $instructorAutofill: Boolean
    $additionalImage: String
    $version: String
    $type: String
    $certType: String
    $telephone: String
    $telefax: String
    $accreditationNo: String
    $email: String
    $paperSize: String
  ) {
    certificateTemplateSet(
      name: $name
      adminId: $adminId
      clientId: $clientId
      trainingProviderAddress: $trainingProviderAddress
      trainingProviderCity: $trainingProviderCity
      trainingProviderZip: $trainingProviderZip
      trainingProviderCountry: $trainingProviderCountry
      location2: $location2
      logoImage: $logoImage
      certificateInformation: $certificateInformation
      watermarkImage: $watermarkImage
      signers: $signers
      hasSSN: $hasSSN
      locationImage: $locationImage
      hasExpiration: $hasExpiration
      hasCourseDates: $hasCourseDates
      certificateName: $certificateName
      instructorAutofill: $instructorAutofill
      additionalImage: $additionalImage
      version: $version
      stampImage: $stampImage
      type: $type
      certType: $certType
      telephone: $telephone
      telefax: $telefax
      accreditationNo: $accreditationNo
      email: $email
      paperSize: $paperSize
    ) {
      success
      message
    }
  }
`;

export const updateCertificateTemplate = gql`
  mutation certificateTemplateUpdate(
    $id: String
    $name: String
    $adminId: ID
    $clientId: ID
    $trainingProviderAddress: String
    $trainingProviderCity: String
    $trainingProviderZip: String
    $trainingProviderCountry: String
    $location2: String
    $logoImage: String
    $certificateInformation: String
    $watermarkImage: String
    $locationImage: String
    $signers: String
    $hasSSN: Boolean
    $hasExpiration: Boolean
    $hasCourseDates: Boolean
    $certificateName: String
    $instructorAutofill: Boolean
    $additionalImage: String
    $stampImage: String
    $version: String
    $type: String
    $certType: String
    $telephone: String
    $additionalData: String
    $telefax: String
    $accreditationNo: String
    $email: String
    $paperSize: String
  ) {
    certificateTemplateUpdate(
      id: $id
      name: $name
      adminId: $adminId
      clientId: $clientId
      stampImage: $stampImage
      trainingProviderAddress: $trainingProviderAddress
      trainingProviderCity: $trainingProviderCity
      trainingProviderZip: $trainingProviderZip
      trainingProviderCountry: $trainingProviderCountry
      location2: $location2
      logoImage: $logoImage
      certificateInformation: $certificateInformation
      watermarkImage: $watermarkImage
      signers: $signers
      hasSSN: $hasSSN
      locationImage: $locationImage
      hasExpiration: $hasExpiration
      hasCourseDates: $hasCourseDates
      certificateName: $certificateName
      instructorAutofill: $instructorAutofill
      additionalImage: $additionalImage
      version: $version
      type: $type
      certType: $certType
      telephone: $telephone
      additionalData: $additionalData
      telefax: $telefax
      accreditationNo: $accreditationNo
      email: $email
      paperSize: $paperSize
    ) {
      success
      message
    }
  }
`;

export const deleteCertificateTemplate = gql`
  mutation templateDelete($id: ID!) {
    templateDelete(id: $id)
  }
`;

export const addCertTemplateImage = gql`
  mutation addCertTemplateImage(
    $clientId: ID!
    $imageId: String!
    $type: String!
  ) {
    addCertTemplateImage(clientId: $clientId, imageId: $imageId, type: $type)
  }
`;
