import { Icon, Modal } from 'semantic-ui-react';
import { Button, Text } from 'components';

const DownloadPdfModal = ({ pdfModalToggled, togglePdfModals, isDownloading, downloadDocs }) => {
  return (
    <Modal
    closeIcon
    size="tiny"
    open={pdfModalToggled}
    onClose={() => togglePdfModals(false)}
    >
      <Modal.Header>
        <Text id="results_inbox.download_format" dark>
          Download Format
        </Text>
      </Modal.Header>
      <Modal.Content>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '5px',
          }}
        >
          <Button
          className="mr-4"
          size="fluid"
          color="blue"
          onClick={() => downloadDocs('individual')}
          isLoading={isDownloading}
          disabled={isDownloading}
          >
            <Icon name="folder" />{' '}
            <Text id="results_inbox.as_individual_files" color="white">
              As individual files
            </Text>
          </Button>
          <Button
          size="fluid"
          color="blue"
          onClick={() => downloadDocs()}
          isLoading={isDownloading}
          disabled={isDownloading}
          >
            <Icon name="file pdf" />{' '}
            <Text id="results_inbox.as_combined_pdf" color="white">
                As one combined PDF
            </Text>
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default DownloadPdfModal