import React, { useEffect, useRef, useState } from 'react';

import { Text } from 'components';

import './labResultsTableFilter.scss';
import { Icon } from 'semantic-ui-react';
import { set } from 'react-hook-form';

const LabResultsTableFilter = ({
  hasBioOverview = true,
  setActiveList,
  results,
  activeList,
}) => {
  const [items, setItems] = useState([]);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const scrollRef = useRef(null);

  useEffect(() => {
    setItems(getSkeleton());
  }, [results]);

  const getSkeleton = () => {
    const itemsSkeleton = [
      {
        name: 'overview',
        text: (
          <Text color="inherit" bold id="react_report.biomaker_overview">
            Biomarker Overview
          </Text>
        ),
        count: false,
        render: hasBioOverview,
      },
      {
        name: 'result',
        text: (
          <Text color="inherit" bold id="patient_overview.lab_results">
            Lab Results
          </Text>
        ),
        count: true,
        countNumber: getCount('result'),
        render: true,
      },
      {
        name: 'diagnostic',
        text: (
          <Text color="inherit" bold id="react_report.diagnostic_imaging">
            Diagnostic Imaging
          </Text>
        ),
        count: true,
        countNumber: getCount('diagnostic'),
        render: true,
      },
      {
        name: 'pulmonary',
        text: (
          <Text color="inherit" bold id="react_report.pulmonary_function">
            Pulmonary Function
          </Text>
        ),
        count: true,
        countNumber: getCount('pulmonary'),
        render: true,
      },
      {
        name: 'audio',
        text: (
          <Text color="inherit" bold id="react_report.audios_n_visual">
            Audio & Visual Acuity
          </Text>
        ),
        count: true,
        countNumber: getCount('audio'),
        render: true,
      },
      {
        name: 'other',
        text: (
          <Text color="inherit" bold id="react_report.other_reports">
            Other Reports
          </Text>
        ),
        count: true,
        countNumber: getCount('other'),
        render: true,
      },
    ];
    return itemsSkeleton;
  };

  const onTabClick = (val) => {
    setActiveList(val);
    const newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?add-t-filter=${val}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
  };

  const getCount = (filter) => {
    const filtered = results.filter((result) => {
      if (filter === 'result') {
        return result?.type === filter || !result?.type;
      }
      return result?.type === filter;
    });
    return filtered?.length || 0;
  };

  const Item = ({ itemDetails }) => {
    return (
      <div
        onClick={() => onTabClick(itemDetails.name)}
        className={`lrtf__item${
          activeList === itemDetails.name ? '-active' : ''
        }`}
      >
        {itemDetails.text}
        {itemDetails.count && (
          <Text size="tinniest" className="lrtf__item__counter">
            {itemDetails.countNumber}
          </Text>
        )}
      </div>
    );
  };

  const checkScrollPosition = () => {
    const { current } = scrollRef;
    if (current) {
      const atStart = current.scrollLeft === 0;
      const atEnd =
        current.scrollWidth - current.clientWidth === current.scrollLeft;
      setShowLeftArrow(!atStart);
      setShowRightArrow(!atEnd);
    }
  };

  const checkScrollable = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftArrow(scrollWidth > clientWidth && scrollLeft > 0);
      setShowRightArrow(scrollWidth < 1245 ? true : false);
    }
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', checkScrollPosition);
      checkScrollable();
    }
    window.addEventListener('resize', checkScrollable);
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', checkScrollPosition);
      }
      window.removeEventListener('resize', checkScrollable);
    };
  }, []);

  return (
    <div className="lrtf__wrapper">
      {showLeftArrow && (
        <div
          className="lrtf__scroll-arrow lrtf__arrow-left"
          onClick={scrollLeft}
        >
          <Icon name="arrow left" />
        </div>
      )}

      <div className="lrtf" ref={scrollRef}>
        {items.map((item) => (
          <>{item.render && <Item itemDetails={item} />}</>
        ))}
      </div>
      {showRightArrow && (
        <div
          className="lrtf__scroll-arrow lrtf__arrow-right"
          onClick={scrollRight}
        >
          <Icon name="arrow right" />
        </div>
      )}
    </div>
  );
};

export default LabResultsTableFilter;
