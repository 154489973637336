import React, { useContext } from 'react';
import { Dropdown } from 'semantic-ui-react'

import { LangContext } from '../LanguageWrapper';

import indFlag from '../../assets/lang-flags/indFlag.png'
import engFlag from '../../assets/lang-flags/ukFlag.png'

import './languageSelector.scss';

const LangSelector = () => {
  const options = [
    { value: 'EN', image: { src: engFlag, avatar: true } },
    { value: 'ID', image: { src: indFlag, avatar: true} }
  ];

  const selectLanguage = (lang) => {
    langContext.selectLanguage(lang)
  }

  const langContext = useContext(LangContext);

  return (
    <div className='lang-s'>
    <Dropdown
      floating
      direction="center"
      icon={null}
      value={langContext.locale}
      options={options}
      inline
      onChange={(e, {value}) => selectLanguage(value)}
    />
    </div>
    // <Select border className="lang-s" placeholder='Select your country' options={options} onChange={(e, {value}) => langContext.selectLanguage(value)} value={langContext.locale} />

  );
};


export default LangSelector;
