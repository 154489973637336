import { apolloClient } from '../apollo/apollo';
import { getPatient, searchPatients } from './graphql/queries/patients';
import { createPatients } from './graphql/mutations/patients';

export default {
  async getPatient(patientId) {
    return apolloClient.query({
      query: getPatient,
      fetchPolicy: 'no-cache',
      variables: {
        patientId,
      },
    });
  },

  async doSearchPatients({ clientId, search, limit, offset }) {
    return apolloClient.query({
      query: searchPatients,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        search,
        limit,
        offset,
      },
    });
  },

  async doCreatePatients(payload) {
    return apolloClient.mutate({
      mutation: createPatients,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
};
