import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';

import HealthValueLine from 'features/HealthValueLine/HealthValueLine';

import NormalIcon from 'assets/range-icons/normal.png';
import HighIcon from 'assets/range-icons/high.png';
import LowIcon from 'assets/range-icons/low.png';
import DetectedIcon from 'assets/range-icons/detected.png';
import NotDetectedIcon from 'assets/range-icons/not-detected.png';
import HeartHandIcon from 'assets/new/heartHand';

import { Text } from 'components';

import heartSvg from 'assets/new/patientApp/heart.svg';
// import HealthValueRange from 'components/ValueRange/ValueRange';

import './riskFactor.scss';

const RangeValues = {
    borderline: { name: 'Borderline', color: 'orange', icon: HighIcon },
    desirable: { name: 'Desirable', color: 'green', icon: NormalIcon },
    high: { name: 'High', color: 'red', icon: HighIcon },
    microalbuminuria: { name: 'Microalbuminuria', color: 'red', icon: HighIcon },
    macroalbuminuria: { name: 'Macroalbuminuria', color: 'red2', icon: HighIcon },
    criticalhigh: { name: 'Very High', color: 'red2', icon: HighIcon },
    veryhigh: { name: 'Very High', color: 'red2', icon: HighIcon },
    verylow: { name: 'Very Low', color: 'orange', icon: LowIcon },
    lowrisk: { name: 'Low Risk', color: 'greenish2', icon: NormalIcon },
    averagerisk: { name: 'Average Risk', color: 'green', icon: NormalIcon },
    highrisk: { name: 'High Risk', color: 'red', icon: HighIcon },
    low: { name: 'Low', color: 'yellow', icon: LowIcon },
    optimal: { name: 'Optimal', color: 'greenish', icon: NormalIcon },
    suboptimal: { name: 'subOptimal', color: 'yellow', icon: LowIcon },
    normal: { name: 'Normal', color: 'green', icon: NormalIcon },
    sufficient: { name: 'Sufficient', color: 'green', icon: NormalIcon },
    ideal: { name: 'Ideal', color: 'greenish2', icon: NormalIcon },
    acceptable: { name: 'Acceptable', color: 'green', icon: DetectedIcon },
    unacceptable: { name: 'Unacceptable', color: 'red', icon: NotDetectedIcon },
    negative: { name: 'Negative', color: 'green', icon: NotDetectedIcon },
    positive: { name: 'Positive', color: 'red', icon: DetectedIcon },
    abnormal: { name: 'Abnormal', color: 'red', icon: HighIcon },
    'non-reactive': {
      name: 'Non-Reactive',
      color: 'green',
      icon: NotDetectedIcon,
    },
    reactive: { name: 'Reactive', color: 'red', icon: DetectedIcon },
    nil: { name: 'Nil', color: 'green', icon: NotDetectedIcon },
    trace: { name: 'Trace', color: 'orange', icon: DetectedIcon },
    deficient: { name: 'Trace', color: 'red', icon: DetectedIcon },
    insufficient: { name: 'Trace', color: 'orange', icon: DetectedIcon },


    mildhypertension: { name: 'Mild Hypertension', color: 'yellow', icon: HighIcon },
    moderatehypertension: { name: 'Moderate Hypertension', color: 'orange', icon: HighIcon },
    severehypertension: { name: 'Severe Hypertension', color: 'red', icon: HighIcon },

    underweight: { name: 'Under Weight', color: 'orange', icon: LowIcon },
    overweight: { name: 'Over Weight', color: 'orange', icon: HighIcon },

    prediabetes: { name: 'Prediabeted', color: 'orange', icon: HighIcon },
    diabetes: { name: 'Diabetes', color: 'red', icon: HighIcon },
  };


const RiskFactor = ({ data, score, diseaseName, isNonManageable }) => {
    const [statusDetails, setDetails] = useState({});
    const [ranges, setRanges] = useState([]);
    const [value, setValue] = useState(null);

    useEffect(() => {
        const anomStatus = data.anomaly_status?.replace('Range', '')
        ?.replace(' ', '')
        ?.toLowerCase();

        const details = RangeValues[anomStatus]
        formatValue(data.value)
        if(!details) return;
        setDetails(details)
        formatRanges(data.reference_range)
    }, [])

    const formatValue = (val) => {
        let value = isNaN(val) ? val : Math.round(val * 100) / 100;
        if(value === 1) value = 'Yes'
        setValue(value)
    }

    const formatRanges = (refRanges) => {
        const ranges = [];
        const objKeys = Object.keys(refRanges);

        objKeys.forEach((key, index) => {
            const ref = refRanges[key];
            if(!Array.isArray(ref)) return;

            const anomStatus = key?.replace('Range', '')
                ?.replace(' ', '')
                ?.toLowerCase();

            const color = RangeValues[anomStatus]?.color || 'blue';
            let subText = `${ref[0]} - ${ref[1]-1}`;
            // let subText = `>${ref[0]}`;
            if(index === objKeys.length - 1) subText = `>${ref[0]}`
            const section = { text: key, subText, range: ref, color: color };
            ranges.push(section);
        })

        setRanges(ranges);
    }
    // Yo Alfred, check this out
    return (
    <div className='risk-fact'>
        <div className='risk-fact__header'>
            <Text dark>{data.name}</Text> {!!data.anomaly_status && <Text className="risk-fact__status" inline size="tiny" dark color={statusDetails.color}>{data.anomaly_status}</Text>}
        </div>
        <div className='risk-fact__value'>
            <Text inline size="tiny" bold>Your result:</Text>
            <Text inline className="ml-2" size="tiny">{value} {data.unit !== "NA" && data.unit}</Text>
        </div>
        <div className='risk-fact__value'>
            <Text inline size="tiny"><Text inline size="tiny" className="mr-2" bold>Risk contribution:</Text><Text inline size="tiny">{Math.round(data.risk_factor * 10000) / 100}%</Text> of {score}% calculated risk</Text>
        </div>
        {!!ranges.length && (
            <div className='risk-fact__line'>
                <HealthValueLine
                    className="risk-fact__hvl"
                    value={{
                        value: data.value,
                        text: data.anomaly_status,
                    }}
                    ranges={ranges}
                    showValue={false}
                />
            </div>
        )}
        {!isNonManageable && (
            <div className='risk-fact__suggestion'>
                <Text bold className='risk-fact__suggestion__header'><HeartHandIcon color="black" className="risk-fact__icon" /> <span className='mt-1'>Suggestions to improve towards optimal value</span></Text>
                <div className='risk-fact__reason' dangerouslySetInnerHTML={{__html: data.text}}/>
            </div>
        )}
        {isNonManageable && (
            <div className='mb-3' />
        )}
    </div>
  );
};

export default RiskFactor;
