import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';

import './healthValueLine.scss';

const HealthValueLine = ({ ranges, value, isReversed, showValue = true, isRelative = false, className, color }) => {
  const [totalLineLength, setTotalLineLength] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  const [dotSectionIndex, setDotSectionIndex] = useState(0);
  const [dotRelativePosition, setDotRelativePosition] = useState(0);
  const [valueColor, setColor] = useState('green');
  const [relativeRanges, setRelativeRanges] = useState([]);

  useEffect(() => {
    calculatePosition();
    setRelativeRanges(ranges);
  }, [ranges, value, totalLineLength]);

  useEffect(() => {
    onScreenResize();
  }, [relativeRanges]);

  useEffect(() => {
    window.addEventListener('resize', onScreenResize, false);

    return () => {
      window.removeEventListener('resize', onScreenResize);
    };
  }, []);

  const onScreenResize = useCallback(
    debounce(() => setScreenWidth(window.innerWidth), 300),
    []
  );

  const measuredRef = useCallback(
    (node) => {
      if (node !== null) {
        setTotalLineLength(node.getBoundingClientRect().width);
      }
    },
    [screenWidth]
  );

  const calculatePosition = () => {
    // This will calculate the position of the dot based on the line and section the dot is in
    let valueSection; // Which section is the value in
    let sectionLength; // Length of a single section
    let sectionRange; // Value range of the section the dot is in
    let valueSectionIndex; // Which section is the value in
    let relativePositionInSection; // Which section is the value in
    let lastValue; // Last value in the range

    const actualValue = Number(value.value); // Actual value

    if (!actualValue && actualValue !== 0) return;
    if(!ranges.length) return;
    ranges.forEach((range, index) => {
      if(!range || !range.range) return
      if (actualValue >= Number(range.range[0]) && actualValue <= Number(range.range[1])) {
        valueSection = range;
        valueSectionIndex = index;
      }
    });

    // is normal
    if (!isReversed) {
      if (Number(ranges[ranges.length - 1].range[1]) < actualValue) {
        valueSectionIndex = ranges.length - 1;
        valueSection = ranges[ranges.length - 1];
      }
      if (Number(ranges[0].range[0]) > actualValue) {
        valueSectionIndex = 0;
        valueSection = ranges[0];
      }
    } else {
      // is reversed
      if (Number(ranges[ranges.length - 1].range[1]) > actualValue) {
        valueSectionIndex = ranges.length - 1;
        valueSection = ranges[ranges.length - 1];
      }
      if (Number(ranges[0].range[0]) < actualValue) {
        valueSectionIndex = 0;
        valueSection = ranges[0];
      }
    }
    if(!valueSection){
      // TODO check if this is correct
      return
    }
    lastValue = valueSection.range[1];
    if (Number(lastValue) === 99999 || Number(lastValue) === 9999) {
      lastValue = Number(valueSection.range[0]) + Number(valueSection.range[0]);
    }
    sectionRange = lastValue - Number(valueSection.range[0]);

    sectionLength = totalLineLength / ranges.length;

    if(isRelative) return relativeSectionLengths(totalLineLength, ranges, valueSectionIndex, valueSection, actualValue);
    const relativeValue = actualValue - valueSection.range[0];

    relativePositionInSection = (relativeValue / sectionRange) * sectionLength;

    relativePositionInSection = isReversed
      ? sectionLength - relativePositionInSection - 25
      : relativePositionInSection - 25;

    if (relativePositionInSection > sectionLength)
      relativePositionInSection = sectionLength - 25;
    if (relativePositionInSection < -25) relativePositionInSection = -25;
    setColor(color || valueSection.color);
    setDotSectionIndex(valueSectionIndex);
    setDotRelativePosition(relativePositionInSection);
  };

  const relativeSectionLengths = (totalLineLength, ranges, valueSectionIndex, valueSection, actualValue) => {
    const lowest = ranges[0]?.range[0];
    const highest = ranges[ranges.length - 1]?.range[1];
    const totalValueRange = highest - lowest;

    const relativeValues = ranges.map(rangeObj => {
      const range = rangeObj.range[1] - rangeObj.range[0];
      const length = range / totalValueRange;
      const sectionLength = length * totalLineLength;

      return {...rangeObj, range, length, sectionLength}
    })

    const relativePosPerc = actualValue / totalValueRange;
    const relativePos = (relativePosPerc * totalLineLength) - 25;

    setColor(color || valueSection.color);
    setDotSectionIndex(valueSectionIndex);
    setDotRelativePosition(relativePos);
    setRelativeRanges(relativeValues);
  }

  return (
    <div className={`hvl__line-wrapper ${className}`}>
      <div ref={measuredRef} className="hvl__line">
        <div className="hvl__line__sections-wrapper">
        {isRelative && (
          <div
            className="hvl__value"
            style={{
              left: dotRelativePosition + 'px',
            }}
          >
            {showValue && (
              <>
                <div
                  className={`hvl__value__square hvl__value__square--${valueColor}`}
                >
                  {value.value}{value.unit}
                </div>
                <div
                  className={`hvl__value__line hvl__value__line--${valueColor}`}
                />
              </>
            )}
            <div
              className={`hvl__value__dot hvl__value__dot--${valueColor}`}
            />
          </div>
          )}
          {relativeRanges.map((range, index) => (
            <div className="hvl__line__sec-wrapper">
              {!showValue && (
                <div className="hvl__line__section__name hvl__line__section__name--top">{range.text}</div>
              )}
              <div
              key={range.text}
              className={`hvl__line__section hvl__line__section--${range.color}`}
              style={{
                width: range.sectionLength + 'px',
                minWidth: range.sectionLength + 'px',
                maxWidth: range.sectionLength + 'px',
              }}
              >
                {!isRelative && index === dotSectionIndex && (
                  <div
                    className="hvl__value"
                    style={{
                      left: dotRelativePosition + 'px',
                    }}
                  >
                    {showValue && (
                      <>
                        <div
                          className={`hvl__value__square hvl__value__square--${valueColor}`}
                        >
                          {!isNaN(value.value ) ? value.value : "" }{value.unit}
                        </div>
                        <div
                          className={`hvl__value__line hvl__value__line--${valueColor}`}
                        />
                      </>
                    )}
                    <div
                      className={`hvl__value__dot hvl__value__dot--${valueColor}`}
                    />
                  </div>
                )}
                {showValue && (
                  <div className="hvl__line__section__name">{range.text}</div>
                )}
                <div className={`hvl__line__section__sub-text${!showValue ? " hvl__line__section__sub-text--top" : ''}`}>
                  {range.subText}
                </div>
              </div>
            </div>
          ))}
          {/* {ranges.length > 0 &&
            ranges.map((range, index) => (
              <div
                key={range.text}
                className={`hvl__line__section hvl__line__section--${range.color}`}
              >
                {index === dotSectionIndex && (
                  <div
                    className="hvl__value"
                    style={{
                      left: dotRelativePosition + 'px',
                    }}
                  >
                    {showValue && (
                      <>
                        <div
                          className={`hvl__value__square hvl__value__square--${valueColor}`}
                        >
                          {value.value}{value.unit}
                        </div>
                        <div
                          className={`hvl__value__line hvl__value__line--${valueColor}`}
                        />
                      </>
                    )}
                    <div
                      className={`hvl__value__dot hvl__value__dot--${valueColor}`}
                    />
                  </div>
                )}
                <div className="hvl__line__section__name">{range.text}</div>
                <div className="hvl__line__section__sub-text">
                  {range.subText}
                </div>
              </div>
            ))} */}
        </div>
      </div>
    </div>
  );
};

export default HealthValueLine;
