import React from 'react';
import Webcam from 'react-webcam';

import Button from '../Button';
import { Text } from 'components';

import './webcam.scss';

const WebCam = ({ onCapture, height = 400, width = 400 }) => {
  const webcamRef = React.useRef(null);

  const videoConstraints = {
    height,
    width,
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({
      width,
      height,
    });
    onCapture(imageSrc);
  }, [height, onCapture, width]);

  return (
    <div className="web-cam">
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        height={height}
        videoConstraints={videoConstraints}
      />
      <Button color="blue" onClick={capture}>
        <Text id="add_patient.capture_photo" color="white">
          Capture photo
        </Text>
      </Button>
    </div>
  );
};
export default WebCam;
