import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Text } from '../components';
import colors from '../styles/colors';

const InputComponent = (props) => {
  const clientBranding = useSelector((state) => state.general.clientBranding);

  return (
    <InputWrapper
      size={props.size}
      style={props.style}
      className={props.className}
    >
      {props.label && (
        <Text id={props?.labelTextId || ''} type="label">
          {props.label}
        </Text>
      )}
      {props.optional && (
        <Text size="tinniest" color="blue" className="input__optional-label">
          {props.optionalLabel || 'Optional'}
        </Text>
      )}
      <Input
        onClick={props.onClick}
        center={props.center}
        placeholder={props.placeholder}
        onChange={props.onChange}
        border={props.border}
        borderColor={props.borderColor}
        type={props.type}
        value={props.value}
        disabled={props.disabled}
        invalid={props.invalid}
        onKeyDown={props.onKeyDown}
        autoComplete={props.autoComplete}
        errorMessage={props.errorMessage}
        onBlur={props.onBlur}
        style={props.inputStyle}
        min={props.min}
        max={props.max}
        pattern={props.pattern}
        step={props.step}
        onKeyPress={props.onKeyPress}
        clientBranding={clientBranding}
      />
      {props?.showNote && props?.note && (
        <Text color="lightGrey" size="tinniest">
          {props?.note}
        </Text>
      )}
      {props.invalid && props.showErrorMessage && (
        <Text className="input-error-text" color="red" size="tiny">
          {props.errorMessage ?? 'This field is required'}
        </Text>
      )}
    </InputWrapper>
  );
};

InputComponent.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
};

InputComponent.defaultProps = {
  size: 'medium',
  onChange: () => {},
  showErrorMessage: true,
};

const getSize = (size) => {
  if (size === 'tiny') return { width: '100px' };
  if (size === 'small') return { width: '150px' };
  if (size === 'smedium') return { width: '235px' };
  if (size === 'medium') return { width: '300px' };
  if (size === 'large') return { width: '400px' };
  if (size === 'fullWidth') return { width: '100%' };
};

const getBorder = (border, invalid) => {
  if (border) {
    if (invalid)
      return `border: solid 1px #ff5252; border-radius: 10px; padding: 10px; height: 40px;`;
    return `border: solid 1px ${colors.GREY}; border-radius: 10px; padding: 10px; height: 40px;`;
  }

  if (invalid) return `border-bottom: solid 1px #ff5252; border-radius: 0;`;
  return `border-bottom: solid 1px #e8e8e8; border-radius: 0;`;
};

const getBorderHighlight = (border, clientColor, borderColor) => {
  if (border)
    return `outline: none; border: solid 1px ${
      clientColor || borderColor || colors.HIGHLIGHT_BLUE
    };`;

  return `outline: none; border-bottom: solid 1px ${
    clientColor || borderColor || colors.HIGHLIGHT_BLUE
  };`;
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => getSize(props.size)}
  position: relative;
  .input__optional-label {
    position: absolute;
    right: 0;
    top: 5px;
  }
`;

const Input = styled.input`
  border: none;
  height: 35px;
  outline-width: 0;
  font-size: 17px;
  color: ${colors.DARK_GREY};
  text-align: ${(props) => props.center && 'center'};
  font-weight: 200;
  background: transparent;
  ${(props) => getBorder(props.border, props.invalid)}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  ::placeholder {
    color: ${colors.DARK_GREY};
    opacity: 0.6;
    transition: all 0.3s ease;
    font-weight: 200;
    font-size: 16px;
  }

  :-ms-input-placeholder {
    color: ${colors.GREY};
  }

  ::-ms-input-placeholder {
    color: ${colors.GREY};
  }

  &:focus {
    ${(props) => getBorderHighlight(props.border, props.clientBranding?.color, props.borderColor)}
    ::placeholder {
      color: ${colors.GREY};
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: ${colors.DARK_GREY};
    }

    ::-ms-input-placeholder {
      color: ${colors.DARK_GREY};
    }
  }

  &:disabled {
    background-color: #fafafa;
    border: solid 1px #dddddd;
  }
`;

export default InputComponent;
