import { SET_COMPLETED_COUNT } from './stats.actions';

const initialState = {
  selectedSection: '/dashboard',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPLETED_COUNT:
      return { ...state, completedCourses: action.payload };

    default:
      return state;
  }
};
