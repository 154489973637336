import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Loader, Popup, Modal, Icon as SIcon } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { sortBy, debounce } from 'lodash';

import {
  Text,
  Button,
  Select,
  Cleave,
  Input,
  ErrorToast,
  SuccessToast,
  Page,
  PhoneInput,
  Icon,
  Search,
  TextArea,
  Switch,
} from 'components';
import CertView from '../../certificateView/certificates';

import countries from 'helpers/countries';
import PatientsService from 'api-delta/patients';
import CertificateTemplatesService from 'api-delta/certificateTemplates';
import CertificateService from 'api-delta/certificates';
import StaffService from 'api-delta/staff';
import FacilitiesService from 'api-delta/facilities';
import TrainingCenterService from 'api-delta/trainingCenters';
import patientApi from 'api/patient/patientApi';

import validateEmail from 'helpers/validateEmail';
import { reagentBrands } from 'utils/reagentBrands';

import { setTemplates } from '../../../certificatePages/templatesManagement/templatesManagement.actions';
import { setStaff } from '../../../staffPages/staffManagement/store/staffManagement.actions';
import GovTechError from './govTechError/GovTechError';

import { sortAlphabetically } from 'helpers/helpers';

import '../../../appointmentPages/appointmentDetails/appointmentDetails.scss';
import '../../../certificatePages/labDocuments/components/resultDetails.scss';

// TODO: Use 1 componenet for PcrAppointment.js, they have ended up becoming the same thing, except PcrAppointment accepts lab results to populate fields
// TODO: Also split this up into more component and refactor the logic, it has become a mess

const IssuePcrCert = ({
  patient,
  subType,
  cert,
  setSubType,
  setRecentDocs,
}) => {
  const [countriesList, setCountriesList] = useState([]);
  const [lab, setLab] = useState(null);
  const [labs, setLabs] = useState([]);
  const [errors, setErrors] = useState({});
  const [collectionDate, setCollectionDate] = useState('');
  const [collectionTime, setCollectionTime] = useState('');
  const [passNo, setPassNo] = useState('');
  const [nationality, setNationality] = useState(null);
  const [gender, setGender] = useState(null);
  const [birthDate, setBirthDate] = useState('');
  // const [testFile, setTestFile] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPreviewLoading, setPreviewLoading] = useState(false);
  const [selectedDoc, setDocument] = useState(null);
  const [holderName, setHolderName] = useState('');
  const [ssn, setSSN] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');
  const [findingUser, setFindingUser] = useState(false);
  const [docOptions, setDocOptions] = useState([]);
  const [physicianSelect, setPhysicianSelect] = useState(null);
  const [physicians, setPhysicians] = useState([]);
  const [result, setResult] = useState(null);
  const [acsn, setACSN] = useState('');
  const [freeTextTestKit, setFreeTextTestKit] = useState('');
  const [secondResult, setSecondResult] = useState(null);
  const [secondLab, setSecondLab] = useState(null);
  const [secondAcsn, setSecondACSN] = useState('');
  const [clinics, setClinics] = useState([]);
  const [issuerName, setIssuerName] = useState(null);
  const [ccEmail, setCcEmail] = useState('');
  const [ccEmail1, setCcEmail1] = useState('');
  const [ccEmail2, setCcEmail2] = useState('');
  const [issuedDate, setIssuedDate] = useState(null);
  const [issuedTime, setIssuedTime] = useState(null);
  const [reportDate, setReportDate] = useState(null);
  const [reportTime, setReportTime] = useState(null);
  const [antigenTypes, setAntigenTypes] = useState([]);
  const [formattedAntigenTypes, setFormattedAntigenTypes] = useState([]);
  const [antigenType, setAntigenType] = useState(null);
  const [phone, setPhone] = useState(null);
  const [transportName, setTransportName] = useState('Nasopharyngeal swab');
  const [remark, setRemark] = useState('');
  const [extraData, setAdditionalData] = useState({});
  const [previewToggled, togglePreview] = useState(false);
  const [docPreview, setDocPreview] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [hasPatients, setHasPatients] = useState(false);
  const [disableIssue, setDisableIssue] = useState(false);
  const [disableIssueText, setDisableIssueText] = useState('');
  const [lockTransport, setLockTransport] = useState(false);
  const [isFreeText, setIsFreeText] = useState(false);
  const [govTechModalToggled, toggleGovTechModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const userInfo = useSelector((state) => state.general.user);
  const templates = useSelector((state) => state.templatesManagement.templates);
  const clientInfo = useSelector((state) => state.general.clientInformation);

  const dispatch = useDispatch();

  useEffect(() => {
    const setPlatoData = () => {
      if (!patient) return;
      let nationality = null;
      countriesList.forEach((nat) => {
        if (nat.text === patient.nationality) nationality = nat.value;
      });
      setNationality(nationality);
      setGender(patient.sex);
      setBirthDate(moment(patient.dob, 'YYYY/MM/DD').format('DD/MM/YYYY'));
      setSSN(patient.nric);
      setHolderName(patient.name);
      setEmail(patient.email);
      setPhone(`+65${patient.telephone}`);
    };
    setPlatoData();
  }, [countriesList, patient]);

  useEffect(() => {
    fetchInstructors();
    formatCountries();
    fetchTemplates();
    fetchLocations();
    fetchClinics();
    findOneUser();
    setCollectionDate(moment().format('DD/MM/YYYY'));
  }, []); // eslint-disable-line

  useEffect(() => {
    formatTemplates();
  }, [templates]); // eslint-disable-line

  useEffect(() => {
    if (result === 'Positive' && subType === 'CH_PDT_PCR_TEST') {
      setDisableIssueText(
        'China PDT cannot be used to issue Positive results, please use the Singapore PDT instead.'
      );
      setDisableIssue(true);
    } else setDisableIssue(false);
  }, [subType, result]); // eslint-disable-line

  useEffect(() => {
    setCertData();
  }, [cert, physicians, labs, formattedAntigenTypes, antigenTypes]); // eslint-disable-line

  useEffect(() => {
    if (
      subType === 'SG_ANTI_RAPID_TEST' ||
      subType === 'PA_SG_ANTI_RAPID_TEST' ||
      subType === 'RMSP_SG_ANTI_RAPID_TEST' ||
      subType === 'SP_SG_ANTI_RAPID_TEST'
    ) {
      fetchAntigenTypes();
      setTransportName('Nasal swab');
      setCollectionTime(moment().subtract(20, 'minutes').format('HH:mm'));
      setIssuedDate(moment().format('DD/MM/YYYY'));
      setIssuedTime(moment().format('HH:mm'));
      setResult('Negative');
      return;
    }

    if (subType === 'SG_LAMP') {
      setTransportName('Anterior Nasal swab');
      setLockTransport(true);
      return;
    }
    setLockTransport(false);
  }, [subType]); // eslint-disable-line

  const handleSearch = useCallback(
    debounce((search) => findUser({ search }), 500),
    []
  );

  const clearFields = () => {
    setPassNo('');
    setNationality(null);
    setGender(null);
    setBirthDate('');
    // setTestFile(false);
    setHolderName('');
    setEmail('');
    setUserId('');
    setSSN('');
    setIssuerName(null);
    setCcEmail('');
    setCcEmail1('');
    setCcEmail2('');
    setPhone(null);
  };

  const getPatientCountry = (countryCode) => {
    let nat = null;
    countries.forEach((country) => {
      if (country.key === countryCode)
        nat = { countryCode, nationality: country.text };
    });
    return nat;
  };

  const setCertData = () => {
    if (!cert || (physicians.length === 0 && labs.length === 0)) return;
    setSubType(cert.subType);
    if (
      (cert.subType === 'SG_ANTI_RAPID_TEST' ||
        cert.subType === 'RMSP_SG_ANTI_RAPID_TEST' ||
        cert.subType === 'SP_SG_ANTI_RAPID_TEST' ||
        cert.subType === 'PA_SG_ANTI_RAPID_TEST') &&
      (formattedAntigenTypes.length === 0 || antigenTypes.length === 0)
    )
      return;
    setResult(cert.result);
    setEmail(cert.email);
    setNationality(getPatientCountry(cert.countryCode));
    setSSN(cert.ssn);
    setHolderName(cert.holderName);
    setBirthDate(moment(cert.dateOfBirth).format('DD/MM/YYYY'));
    setPassNo(cert.passNo);
    setGender(cert.gender);
    setCollectionDate(moment(cert.collectionDate).format('DD/MM/YYYY'));
    setCollectionTime(moment(cert.collectionDate).format('HH:mm'));
    setIssuedDate(moment(cert.issuedAt).format('DD/MM/YYYY'));
    setIssuedTime(moment(cert.issuedAt).format('HH:mm'));
    physicians.forEach((phys) => {
      if (phys.value.licenseNo === cert.physicianLicense)
        setPhysicianSelect(phys.value);
    });
    labs.forEach((lab) => {
      if (lab.value.labName === cert.labName) setLab(lab.value);
    });
    if (
      (cert.subType === 'SG_ANTI_RAPID_TEST' ||
        cert.subType === 'RMSP_SG_ANTI_RAPID_TEST' ||
        cert.subType === 'SP_SG_ANTI_RAPID_TEST' ||
        cert.subType === 'PA_SG_ANTI_RAPID_TEST') &&
      cert.additionalData
    ) {
      const parsedKit = JSON.parse(cert.additionalData);
      antigenTypes.forEach((kit) => {
        if (kit.code === parsedKit.code) setAntigenType(kit.id);
      });
    }
  };

  const fetchAntigenTypes = async () => {
    if (antigenTypes.length > 0) return;

    try {
      const {
        data: { getAntigenTypes },
      } = await CertificateService.getAntigenTypes();
      const formattedTypes = getAntigenTypes.map((type) => {
        return { text: type.name, value: type.id, key: type.id };
      });

      setAntigenTypes(getAntigenTypes);
      setFormattedAntigenTypes(sortBy(formattedTypes, ['text']));
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const formatCountries = () => {
    const formattedCons = countries.map((con, index) => {
      return {
        key: index,
        text: con.text,
        value: { nationality: con.text, countryCode: con.key },
      };
    });

    setCountriesList(formattedCons);
  };

  const fetchLocations = async () => {
    try {
      const {
        data: { facilities },
      } = await FacilitiesService.getFacilitiesByClientId(
        userInfo.currentClientId
      );
      const formattedFacilities = facilities.map((lab) => {
        return {
          key: lab.id,
          text: lab.name,
          value: {
            labPhoneNumber: lab.phoneNumber,
            labAddress: `${lab.address}, ${lab.city}, ${lab.country}`,
            labName: lab.name,
          },
        };
      });
      setLabs(formattedFacilities);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchClinics = async () => {
    if (clientInfo.role !== 'lab') return;
    try {
      const {
        data: { trainingCenters },
      } = await TrainingCenterService.getTrainingCentersByClientId(
        userInfo.currentClientId
      );

      const facilitiesOptions = trainingCenters.map((facility) => ({
        key: facility.id,
        text: facility.name,
        value: facility.name,
      }));
      setClinics(facilitiesOptions);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const formatTemplates = () => {
    if (!templates || templates.length === 0) return;
    const filteredTemplates = [];
    templates.forEach((temp) => {
      if (temp.certType !== 'PCR_TEST') return;
      filteredTemplates.push({
        text: temp.name,
        value: temp.id,
      });
    });
    setDocOptions(filteredTemplates);
  };

  const fetchTemplates = async () => {
    try {
      const {
        data: { certificateTemplatesByClientId },
      } = await CertificateTemplatesService.getCertificateTemplatessByClientId(
        userInfo.currentClientId
      );
      const sortedData = sortAlphabetically(certificateTemplatesByClientId);
      dispatch(setTemplates(sortedData));
    } catch (error) {
      toast.error(<ErrorToast message="Error fetching templates" />);
    }
  };

  const fetchCertificate = async () => {
    try {
      const {
        data: { certificateTemplate },
      } = await CertificateTemplatesService.getCertificateTemplateById(
        selectedDoc
      );
      return certificateTemplate;
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const getAntigenValue = () => {
    let result = null;
    if (freeTextTestKit)
      return JSON.stringify({
        name: freeTextTestKit,
        accuracy: '95%',
        code: freeTextTestKit.substring(0, 3) + '19',
      });
    if (antigenType)
      antigenTypes.forEach((type) => {
        if (type.id === antigenType)
          result = JSON.stringify({
            name: type.name,
            accuracy: type.accuracy,
            code: type.code,
          });
      });
    return result;
  };

  const getIdType = (subType) => {
    if (subType === 'TW_PCR_TEST') return 'ID/ARC';
    return 'NRIC/FIN';
  };

  const issueCertificate = async () => {
    setErrorMessage('');
    if (!isFormValid()) return;
    setIsSaving(true);
    try {
      const certTemplate = await fetchCertificate();

      const signers = certTemplate.signers;

      const ccArray = [ccEmail.trim(), ccEmail1.trim(), ccEmail2.trim()];

      const idType = getIdType(subType);

      await CertificateService.onIssueCert({
        clientId: userInfo.currentClientId,
        userId: userId || null,
        email: email.trim() || null,
        holderName: holderName,
        courseStartDate: null,
        courseEndDate: null,
        dateOfBirth: moment.utc(birthDate, 'DD/MM/YYYY').toISOString(),
        centerId: userInfo.centerId || null,
        passNo: passNo === '' ? null : passNo,
        type: 'medical',
        certType: 'PCR_TEST',
        version: '1',
        // attachment: filePath.location,
        collectionDate: moment(
          collectionDate + ' ' + collectionTime,
          'DD/MM/YYYY HH:mm'
        )
          .utc()
          .toISOString(),
        ...nationality,
        gender,
        ...lab,
        birthDate,
        idType,
        acsn,
        issuerName,
        transportName:
          subType === 'SEROLOGY' || subType === 'SEROLOGY_IGG'
            ? 'Blood Venous'
            : transportName,
        subType,
        physicianId: physicianSelect.id,
        physicianName: physicianSelect.name,
        physicianLicense: physicianSelect.licenseNo,
        physicianSignature: physicianSelect.signature,
        logoImage: certTemplate.logoImage,
        stampImage: certTemplate.stampImage,
        watermarkImage: certTemplate.watermarkImage,
        certificateInformation:
          subType === 'PH_PDT_SF_TEST'
            ? remark
            : certTemplate.certificateInformation,
        telephone: certTemplate.telephone,
        trainingProviderAddress: certTemplate.trainingProviderAddress,
        location2: certTemplate.location2,
        signers: signers ? JSON.stringify(signers) : signers,
        locationImage: certTemplate.locationImage,
        additionalImage: certTemplate.additionalImage,
        name: certTemplate.certificateName,
        ssn: ssn === '' ? null : ssn,
        issuedAt: moment(issuedDate + ' ' + issuedTime, 'DD/MM/YYYY HH:mm')
          .utc()
          .toISOString(),
        reportedAt: moment(reportDate + ' ' + reportTime, 'DD/MM/YYYY HH:mm')
          .utc()
          .toISOString(),
        result,
        secondResult,
        additionalData:
          subType === 'SG_ANTI_RAPID_TEST' ||
          subType === 'RMSP_SG_ANTI_RAPID_TEST' ||
          subType === 'SP_SG_ANTI_RAPID_TEST' ||
          subType === 'PA_SG_ANTI_RAPID_TEST'
            ? getAntigenValue()
            : certTemplate.additionalData,
        secondAcsn,
        secondLab: JSON.stringify({ ...secondLab }),
        ccEmails: JSON.stringify(
          ccArray.filter((a, b) => ccArray.indexOf(a) === b)
        ),
        phone,
        extraData:
          Object.keys(extraData).length > 0 ? JSON.stringify(extraData) : null,
        issuingUser: userInfo.name,
        issuingUserId: userInfo.safe_id,
      });
      console.log({ userInfo });
      let recentDocsLS = localStorage.getItem('RECENT_ISSUED_DOCS');
      if (recentDocsLS) {
        recentDocsLS = JSON.parse(recentDocsLS);
        let _subTypes = recentDocsLS.subTypes;
        if (_subTypes.length > 5) {
          _subTypes = _subTypes.slice(0, 5);
        }
        if (!_subTypes.includes(subType)) {
          _subTypes.unshift(subType);
        }
        recentDocsLS.userId = userInfo.safe_id;
        recentDocsLS.subTypes = _subTypes;
      } else {
        recentDocsLS = {
          userId: userInfo.safe_id,
          subTypes: [subType],
        };
      }
      setRecentDocs(recentDocsLS.subTypes);
      localStorage.setItem('RECENT_ISSUED_DOCS', JSON.stringify(recentDocsLS));

      clearFields();
      toast.success(<SuccessToast message="Document issued" />, {
        hideProgressBar: true,
        autoClose: 4000,
        pauseOnHover: true,
      });
    } catch (error) {
      setErrorMessage(error.message);
      if (
        error.message !==
        'GraphQL error: Date of birth and/or gender do not match existing records. Please try again with the correCt values. If the problem persists, please submit supporting documents to support@notarise.gov.sg'
      )
        toast.error(<ErrorToast error={error} />);
    }
    setIsSaving(false);
  };

  const onSearchChange = (searchValue) => {
    setSearchValue(searchValue);
    handleSearch(searchValue);
  };

  //TODO: Get rid of this at some point when the patient feature is used more
  const findOneUser = async () => {
    try {
      const {
        data: { searchPatients },
      } = await PatientsService.doSearchPatients({
        search: null,
        clientId: clientInfo.clientId,
        limit: 1,
      });
      setHasPatients(searchPatients.length > 0);
    } catch (error) {
      console.log('error', error);
    }
  };

  const findUser = async (value) => {
    try {
      setFindingUser(true);
      const patients = await patientApi.getPatients({
        search: value,
        limit: 20,
        page: 1,
      });
      const formattedResults = patients.results.map((pat) => ({
        key: pat.id,
        title: pat.fullName,
        // description: `Passport number: ${pat?.identifier?.passportNumber}, NRIC: ${pat?.identifier?.socialSecurityNumber}`,
        description: `${pat?.passNo ? `Passport number: ${pat?.passNo}` : ''} ${
          pat?.ssn ? `NRIC: ${pat?.ssn}` : ''
        }`,
        data: pat,
      }));
      setSearchResults(formattedResults);
      setFindingUser(false);
    } catch (error) {
      setFindingUser(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  // const selectPatient = (selectedPatient) => {
  //   setSearchValue('');
  //   selectedPatient?.email && setEmail(selectedPatient.email);
  //   selectedPatient?.identifier?.passportNumber &&
  //     setPassNo(selectedPatient.identifier.passportNumber);
  //   selectedPatient?.identifier?.socialSecurityNumber &&
  //     setSSN(selectedPatient.identifier.socialSecurityNumber);
  //   selectedPatient?.fullName && setHolderName(selectedPatient.fullName);
  //   selectedPatient?.phone && setPhone(selectedPatient.phone);
  //   if (selectedPatient?.dateOfBirth)
  //     setBirthDate(
  //       moment
  //         .utc(selectedPatient.dateOfBirth, 'YYYY-MM-DD')
  //         .format('DD-MM-YYYY')
  //     );
  //   selectedPatient?.nationality &&
  //     selectedPatient?.natCode &&
  //     setNationality({
  //       nationality:
  //         selectedPatient.nationality.charAt(0).toUpperCase() +
  //         selectedPatient.nationality.slice(1),
  //       countryCode: selectedPatient.natCode.toUpperCase(),
  //     });
  //   selectedPatient?.gender &&
  //     setGender(
  //       selectedPatient.gender.toLowerCase() === 'male' ? 'Male' : 'Female'
  //     );
  // };
  const selectPatient = (selectedPatient) => {
    setSearchValue('');
    selectedPatient?.email && setEmail(selectedPatient.email);
    setPassNo(selectedPatient.passNo);
    setSSN(selectedPatient.ssn);
    selectedPatient?.fullName && setHolderName(selectedPatient.fullName);
    selectedPatient?.phone && setPhone(selectedPatient.phone);
    if (selectedPatient?.dateOfBirth)
      setBirthDate(
        moment
          .utc(selectedPatient.dateOfBirth, 'YYYY-MM-DD')
          .format('DD-MM-YYYY')
      );
    selectedPatient?.nationality &&
      selectedPatient?.nationalityCode &&
      setNationality({
        nationality:
          selectedPatient.nationality.charAt(0).toUpperCase() +
          selectedPatient.nationality.slice(1),
        countryCode: selectedPatient.nationalityCode.toUpperCase(),
      });
    selectedPatient?.gender &&
      setGender(
        selectedPatient.gender.toLowerCase() === 'male' ? 'Male' : 'Female'
      );
    setPhone(selectedPatient.phoneNumber);
  };

  const isFormValid = () => {
    const newErrors = {};
    let isValid = true;
    const ccArray = [ccEmail.trim(), ccEmail1.trim(), ccEmail2.trim()];

    if (
      !lab &&
      subType !== 'SG_ANTI_RAPID_TEST' &&
      subType !== 'PA_SG_ANTI_RAPID_TEST' &&
      subType !== 'SG_LAMP' &&
      subType !== 'RMSP_SG_ANTI_RAPID_TEST' &&
      subType !== 'SP_SG_ANTI_RAPID_TEST'
    ) {
      newErrors.lab = true;
      isValid = false;
    }

    if (!selectedDoc) {
      newErrors.template = true;
      isValid = false;
    }
    if (!physicianSelect) {
      newErrors.physicianSelect = true;
      isValid = false;
    }
    if (
      (subType === 'SG_PDT_PCR_TEST' ||
        subType === 'CH_PCR_ANTIGEN_TEST' ||
        subType === 'SEROLOGY' ||
        subType === 'CH_PDT_PCR_TEST' ||
        subType === 'SEROLOGY_IGG' ||
        subType === 'CH_PCR_PRO_ANTIBODY_TEST' ||
        subType === 'CH_PCR_PRO_S_ANTIBODY_TEST' ||
        subType === 'TW_PCR_TEST') &&
      (!acsn || acsn.length < 4)
    ) {
      newErrors.acsn = true;
      isValid = false;
    }
    if (
      (subType === 'CH_PCR_ANTIGEN_TEST' ||
        subType === 'CH_PCR_PRO_S_ANTIBODY_TEST' ||
        subType === 'CH_PDT_PCR_TEST' ||
        subType === 'CH_PCR_PRO_ANTIBODY_TEST') &&
      (!physicianSelect || !physicianSelect.signature)
    ) {
      toast.error(<ErrorToast message="Physician requires a signature" />);
    }
    if (!email || !validateEmail(email)) {
      newErrors.email = true;
      isValid = false;
    }
    if (!holderName || holderName.length < 2) {
      newErrors.holderName = true;
      isValid = false;
    }
    if (!collectionDate || collectionDate.length < 6) {
      newErrors.collectionDate = true;
      isValid = false;
    }
    if (!collectionTime || collectionTime.length < 4) {
      newErrors.collectionTime = true;
      isValid = false;
    }
    if (result == null) {
      newErrors.result = true;
      isValid = false;
    }

    if (
      (subType === 'CH_PCR_ANTIGEN_TEST' ||
        subType === 'CH_PCR_PRO_S_ANTIBODY_TEST' ||
        subType === 'CH_PCR_PRO_ANTIBODY_TEST') &&
      !secondResult
    ) {
      newErrors.secondResult = true;
      isValid = false;
    }
    if (subType === 'TW_PCR_TEST' && result === 'Positive' && !extraData.ct) {
      newErrors.ct = true;
      isValid = false;
    }
    if (
      (subType === 'CH_PCR_ANTIGEN_TEST' ||
        subType === 'CH_PCR_PRO_S_ANTIBODY_TEST' ||
        subType === 'CH_PCR_PRO_ANTIBODY_TEST') &&
      !secondLab
    ) {
      newErrors.secondResult = true;
      isValid = false;
    }
    if (
      (subType === 'SG_ANTI_RAPID_TEST' ||
        subType === 'RMSP_SG_ANTI_RAPID_TEST' ||
        subType === 'SP_SG_ANTI_RAPID_TEST' ||
        subType === 'PA_SG_ANTI_RAPID_TEST') &&
      !antigenType &&
      !freeTextTestKit
    ) {
      newErrors.antigenType = true;
      isValid = false;
    }
    if (
      (subType === 'CH_PCR_ANTIGEN_TEST' ||
        subType === 'CH_PCR_PRO_S_ANTIBODY_TEST' ||
        subType === 'CH_PCR_PRO_ANTIBODY_TEST') &&
      !secondLab
    ) {
      newErrors.secondResult = true;
      isValid = false;
    }
    if (
      (subType === 'CH_PCR_ANTIGEN_TEST' ||
        subType === 'CH_PCR_PRO_S_ANTIBODY_TEST' ||
        subType === 'CH_PCR_PRO_ANTIBODY_TEST') &&
      (!secondAcsn || secondAcsn.length < 4)
    ) {
      newErrors.secondAcsn = true;
      isValid = false;
    }

    if (subType === 'CH_PDT_PCR_TEST' && !extraData.reagentBrand) {
      newErrors.reagentBrand = true;
      isValid = false;
    }

    if (subType !== 'SG_PCR_TEST' && (!passNo || passNo.length < 5)) {
      newErrors.passNo = true;
      isValid = false;
    }

    if (
      (subType === 'SG_ANTI_RAPID_TEST' ||
        subType === 'RMSP_SG_ANTI_RAPID_TEST' ||
        subType === 'SP_SG_ANTI_RAPID_TEST' ||
        subType === 'PA_SG_ANTI_RAPID_TEST') &&
      (!passNo || passNo.length < 5) &&
      (!ssn || ssn.length < 5)
    ) {
      newErrors.passNo = true;
      newErrors.ssn = true;
      isValid = false;
    }

    if (
      !birthDate ||
      birthDate.length !== 10 ||
      !moment(birthDate, 'DD/MM/YYYY').isValid() ||
      !moment(birthDate, 'DD/MM/YYYY').isAfter(moment().subtract(110, 'years'))
    ) {
      toast.error(<ErrorToast message="Invalid birth date" />);
      newErrors.birthDate = true;
      isValid = false;
    }

    if (!nationality) {
      newErrors.nationality = true;
      isValid = false;
    }
    if (!gender) {
      newErrors.gender = true;
      isValid = false;
    }

    ccArray.forEach((email, index) => {
      if (email && !validateEmail(email)) {
        isValid = false;
        newErrors['ccEmail' + index] = true;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const fetchInstructors = () => {
    if (userInfo.centerId) return fetchInstructorsByCenter();
    return fetchInstructorsByClient();
  };

  const fetchInstructorsByClient = async () => {
    const clientId = userInfo.currentClientId;

    try {
      const {
        data: { instructorsByClientId },
      } = await StaffService.getStaffByClientId(clientId);
      dispatch(setStaff(instructorsByClientId));
      formatPhysicians(instructorsByClientId);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const previewDoc = async () => {
    if (!isFormValid())
      return toast.error(<ErrorToast message="Fill all required fields" />);
    setPreviewLoading(true);
    const certTemplate = await fetchCertificate();
    setPreviewLoading(false);

    const signers = certTemplate.signers;

    const docPreviewObj = {
      clientId: userInfo.currentClientId,
      ssn: ssn === '' ? null : ssn,
      passNo,
      gender,
      result,
      dateOfBirth: moment.utc(birthDate, 'DD/MM/YYYY').toISOString(),
      acsn,
      subType,
      extraData: JSON.stringify(extraData),
      certType: 'PCR_TEST',
      templateId: selectedDoc,
      ...nationality,
      labPhoneNumber: lab ? lab.labPhoneNumber : null,
      labAddress: lab ? lab.labAddress : null,
      labName: lab ? lab.labName : null,
      email: email.trim(),
      holderName,
      transportName:
        subType === 'SEROLOGY' || subType === 'SEROLOGY_IGG'
          ? 'Blood Venous'
          : transportName,
      issuedAt: moment(issuedDate + ' ' + issuedTime, 'DD/MM/YYYY HH:mm')
        .utc()
        .toISOString(),
      physicianName: physicianSelect.name,
      physicianSignature: physicianSelect && physicianSelect.signature,
      physicianLicense: physicianSelect.licenseNo,
      issuerName: clientInfo.name,
      secondResult,
      secondAcsn,
      qrCodes: JSON.stringify({
        SG: 'public/riverr-qrcode.png',
        EU: 'public/riverr-qrcode.png',
      }),
      phone,
      reportedAt: reportDate
        ? moment(reportDate + ' ' + reportTime, 'DD/MM/YYYY HH:mm')
            .utc()
            .toISOString()
        : null,
      secondLab: JSON.stringify({ ...secondLab }),
      collectionDate: moment(
        collectionDate + ' ' + collectionTime,
        'DD/MM/YYYY HH:mm'
      )
        .utc()
        .toISOString(),
      collectionTime,
      additionalData:
        subType === 'SG_ANTI_RAPID_TEST' ||
        subType === 'PA_SG_ANTI_RAPID_TEST' ||
        subType === 'RMSP_SG_ANTI_RAPID_TEST' ||
        subType === 'SP_SG_ANTI_RAPID_TEST'
          ? getAntigenValue()
          : certTemplate.additionalData,
      logoImage: certTemplate.logoImage,
      stampImage: certTemplate.stampImage,
      watermarkImage: certTemplate.watermarkImage,
      certificateInformation:
        subType === 'PH_PDT_SF_TEST'
          ? remark
          : certTemplate.certificateInformation,
      telephone: certTemplate.telephone,
      trainingProviderAddress: certTemplate.trainingProviderAddress,
      location2: certTemplate.location2,
      signers: signers ? JSON.stringify(signers) : signers,
      locationImage: certTemplate.locationImage,
      additionalImage: certTemplate.additionalImage,
    };
    setDocPreview(docPreviewObj);
    togglePreview(true);
  };

  const fetchInstructorsByCenter = async () => {
    try {
      const {
        data: { instructorsByCenterId },
      } = await StaffService.getInstructorsByCenterId(userInfo.centerId);
      dispatch(setStaff(instructorsByCenterId));
      formatPhysicians(instructorsByCenterId);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const formatPhysicians = (instructorsByClientId) => {
    setPhysicians(
      instructorsByClientId.map((phys) => {
        return {
          key: phys.id,
          text: phys.name,
          value: {
            id: phys.id,
            name: phys.name,
            signature: phys.signature,
            licenseNo: phys.licenseNo,
            certAuthority: phys.certAuthority,
          },
        };
      })
    );
  };

  // const onEmailChange = e => {
  //   if (e.key === 'Enter') {
  //     findUser(email);
  //   }
  // };

  const onAdditionalChange = (value, key) => {
    if (key === 'reagentBrand') {
      const brandDetails = reagentBrands[value];
      const addData = { ...extraData, ...brandDetails };
      setAdditionalData(addData);
    } else {
      const addData = { ...extraData };
      addData[key] = value;
      setAdditionalData(addData);
    }
  };

  const resultRenderer = ({ title, data }) => (
    <>
      <Text size="tiny" dark>
        {title}
      </Text>
      <Text size="tiny">Passport: {data.passNo}</Text>
      <Text size="tiny">NRIC: {data.ssn}</Text>
    </>
  );

  const toggleFreeText = (value) => {
    setFreeTextTestKit('');
    setAntigenType(null);
    setIsFreeText(value);
  };

  return (
    <>
      <GovTechError
        error={errorMessage}
        isToggled={govTechModalToggled}
        toggleModal={toggleGovTechModal}
      />
      <Page style={{ paddingTop: '30px', marginTop: '15px' }}>
        <div className="appointment-details__modal-content__column">
          {disableIssue && (
            <div className="result-details__info">
              <div className="result-details__info__text">
                <Icon name="info" />
                <Text>{disableIssueText}</Text>
              </div>
            </div>
          )}
          <div className="appointment-details__modal-content__inputs-wrapper">
            <div className="appointment-details__modal-content__input">
              <Select
                label="Document template"
                labelTextId="documents.document_template"
                size="fullWidth"
                border
                search
                icon={false}
                placeholder={
                  <Text id="documents.select_document_template">
                    Select document template
                  </Text>
                }
                options={docOptions}
                value={selectedDoc}
                onChange={(e, { value }) => setDocument(value)}
                invalid={errors.template}
              />
            </div>
            <div className="appointment-details__modal-content__input">
              <Select
                label="Performer"
                size="fullWidth"
                border
                search
                icon={false}
                placeholder="Select a performer"
                options={physicians}
                value={physicianSelect}
                onChange={(e, { value }) => setPhysicianSelect(value)}
                invalid={errors.physicianSelect}
              />
            </div>
          </div>
        </div>
        <div className="appointment-details__modal-content__column">
          <Text
            id="documents.patient"
            className="appointment-details__modal-content__header"
          >
            Patient
          </Text>
          {/* {hasPatients && ( */}
          <div className="appointment-details__modal-content__inputs-wrapper">
            <div className="appointment-details__modal-content__input">
              <Popup
                content="Search patients using name, passport number or NRIC / FIN"
                key={0}
                position="top right"
                trigger={
                  <div
                    style={{
                      position: 'absolute',
                      top: '11px',
                      right: '8px',
                      zIndex: 1,
                    }}
                  >
                    <Icon name="info" fontSize="17px" />
                  </div>
                }
              />
              <Search
                size="fullWidth"
                resultRenderer={resultRenderer}
                onSearchChange={(e, { value }) => onSearchChange(value)}
                isFilter={true}
                value={searchValue}
                isLoading={findingUser}
                results={searchResults}
                onResultSelect={(e, data) => selectPatient(data.result.data)}
              />
            </div>
          </div>
          {/* )} */}
          <div className="appointment-details__modal-content__inputs-wrapper">
            <div className="appointment-details__modal-content__input appointment-details__loader-input">
              <Input
                size="fullWidth"
                label="Email"
                labelTextId="documents.email"
                border
                onChange={(e) => setEmail(e.target.value)}
                // onKeyDown={onEmailChange}
                value={email}
                invalid={errors.email}
                placeholder={'Email'}
                errorMessage="Invalid email"
              />
              {/* {findingUser && (
                <>
                  <LinearLoader />
                  <Text
                    size="tiny"
                    className="app-creation__input-wrapper__loader-text"
                  >
                    Searching for patient
                  </Text>
                </>
              )} */}
            </div>
            <div className="appointment-details__modal-content__input">
              <Popup
                content="SMS with document info is sent to phone number"
                key={0}
                position="top right"
                trigger={
                  <div
                    style={{
                      position: 'absolute',
                      top: '37px',
                      right: '8px',
                      zIndex: 1,
                    }}
                  >
                    <Icon name="info" fontSize="17px" />
                  </div>
                }
              />
              <PhoneInput
                border
                label="Phone number"
                labelTextId="documents.phone_number"
                placeholder={'Enter phone number'}
                value={phone}
                optional
                onChange={(value) => setPhone(value)}
              />
            </div>
          </div>
          <div className="appointment-details__modal-content__inputs-wrapper">
            <div className="appointment-details__modal-content__input">
              <Input
                size="fullWidth"
                label="Patient name"
                labelTextId="documents.patient_name"
                border
                onChange={(e) => setHolderName(e.target.value)}
                value={holderName}
                invalid={errors.holderName}
                placeholder={'Patient name'}
              />
            </div>
            {subType === 'TW_PCR_TEST' && (
              <div className="appointment-details__modal-content__input">
                <Input
                  size="fullWidth"
                  label="Chinese name"
                  labelTextId="documents.chinese_name"
                  border
                  onChange={(e) => onAdditionalChange(e.target.value, 'name')}
                  value={extraData.name}
                  placeholder={'Patient name in Chinese'}
                />
              </div>
            )}
            <div className="appointment-details__modal-content__input">
              <Select
                label="Nationality"
                labelTextId="documents.nationality"
                placeholder={'Nationality'}
                border
                search
                size="fullWidth"
                icon={false}
                options={countriesList}
                value={nationality}
                onChange={(e, { value }) => setNationality(value)}
                invalid={errors.nationality}
              />
            </div>

            <div className="appointment-details__modal-content__input">
              <Cleave
                size="fullWidth"
                style={{ paddingLeft: '10px', width: '300px' }}
                label="Birth date"
                labelTextId="documents.birth_date"
                placeholder="DD/MM/YYYY"
                options={{
                  date: true,
                  datePattern: ['d', 'm', 'Y'],
                  delimiter: '/',
                }}
                onChange={(e) => setBirthDate(e.target.value)}
                value={birthDate}
                invalid={errors.birthDate}
              />
            </div>

            <div className="appointment-details__modal-content__input">
              <Select
                size="fullWidth"
                label="Gender"
                labelTextId="documents.gender"
                border
                search
                icon={false}
                placeholder={
                  <Text id="documents.select_gender">Select gender</Text>
                }
                options={[
                  {
                    text: <Text id="documents.gender.male">Male</Text>,
                    value: 'Male',
                  },
                  {
                    text: <Text id="documents.gender.female">Female</Text>,
                    value: 'Female',
                  },
                  {
                    text: <Text id="documents.gender.other">Other</Text>,
                    value: 'Other',
                  },
                  {
                    text: <Text id="documents.gender.unknown">Unknown</Text>,
                    value: 'Unknown',
                  },
                ]}
                value={gender}
                onChange={(e, { value }) => setGender(value)}
                invalid={errors.gender}
              />
            </div>

            <div className="appointment-details__modal-content__input">
              <Input
                size="fullWidth"
                label="Passport No."
                labelTextId="documents.passport_no"
                border
                onChange={(e) => setPassNo(e.target.value)}
                value={passNo}
                invalid={errors.passNo}
                placeholder={'Passport No.'}
                optional={subType === 'SG_PCR_TEST'}
              />
            </div>
            {subType === 'TW_PCR_TEST' && (
              <div className="appointment-details__modal-content__input">
                <Input
                  size="fullWidth"
                  label="ID/ARC No."
                  labelTextId="documents.id/arc_no"
                  border
                  onChange={(e) => setSSN(e.target.value)}
                  value={ssn}
                  invalid={errors.ssn}
                  placeholder={
                    <Text id="documents.id/arc_no.">ID/ARC No.</Text>
                  }
                />
              </div>
            )}
            {(subType === 'SG_PDT_PCR_TEST' ||
              subType === 'SG_LAMP' ||
              subType === 'SG_PCR_TEST' ||
              subType === 'CH_PCR_ANTIGEN_TEST' ||
              subType === 'CH_PCR_PRO_S_ANTIBODY_TEST' ||
              subType === 'CH_PCR_PRO_ANTIBODY_TEST' ||
              subType === 'PA_SG_ANTI_RAPID_TEST' ||
              subType === 'RMSP_SG_ANTI_RAPID_TEST' ||
              subType === 'SP_SG_ANTI_RAPID_TEST' ||
              subType === 'CH_PDT_PCR_TEST' ||
              subType === 'SG_ANTI_RAPID_TEST') && (
              <div className="appointment-details__modal-content__input">
                <Input
                  size="fullWidth"
                  label="NRIC / FIN no."
                  labelTextId="documents.nric/fin_no"
                  optional
                  border
                  onChange={(e) => setSSN(e.target.value)}
                  value={ssn}
                  invalid={errors.ssn}
                  placeholder="NRIC / FIN no."
                />
              </div>
            )}
          </div>
        </div>
        <div className="appointment-details__modal-content__column">
          <div className="appointment-details__modal-content__header">
            Results
          </div>
          <div className="appointment-details__modal-content__inputs-wrapper">
            <div className="appointment-details__modal-content__input">
              <Text id="documents.specimen_collection_date/time" type="label">
                Specimen collection date/time
              </Text>
              <div className="appointment-details__modal-content__input--row">
                <Cleave
                  size="fullWidth"
                  style={{ width: '200px' }}
                  placeholder="DD/MM/YYYY"
                  options={{
                    date: true,
                    datePattern: ['d', 'm', 'Y'],
                    delimiter: '/',
                  }}
                  onChange={(e) => setCollectionDate(e.target.value)}
                  value={collectionDate}
                  invalid={errors.collectionDate}
                />
                <div className="ml-2">
                  <Cleave
                    style={{ width: '100px' }}
                    size="fullWidth"
                    placeholder="hh:mm"
                    options={{
                      time: true,
                      timePattern: ['h', 'm'],
                    }}
                    onChange={(e) => setCollectionTime(e.target.value)}
                    value={collectionTime}
                    invalid={errors.collectionTime}
                    errorMessage=" "
                  />
                </div>
              </div>
              <Text
                id="documents.time_should_be_your_timezone"
                size="tiny"
                color="grey"
                className="mt-1"
              >
                *Time should be according to your current timezone
              </Text>
            </div>
            {subType !== 'SEROLOGY' && subType !== 'SEROLOGY_IGG' && (
              <div className="appointment-details__modal-content__input">
                <Select
                  label="Swab Type"
                  labelTextId="documents.swab_type"
                  placeholder={<Text id="documents.swab_type">Swab Type</Text>}
                  size="fullWidth"
                  border
                  search
                  disabled={lockTransport}
                  icon={false}
                  options={[
                    {
                      text: 'Nasopharyngeal swab',
                      value: 'Nasopharyngeal swab',
                    },
                    {
                      text: 'Nasal swab',
                      value: 'Nasal swab',
                    },
                    {
                      text: 'Oropharyngeal swab',
                      value: 'Oropharyngeal swab',
                    },
                    {
                      text: 'Oropharyngeal Mid-Turbinate swab',
                      value: 'Oropharyngeal Mid-Turbinate swab',
                    },
                    {
                      text: 'Sputum specimen',
                      value: 'Sputum specimen',
                    },
                    {
                      text: 'Saliva specimen',
                      value: 'Saliva specimen',
                    },
                    {
                      text: 'Nasal Mid-Turbinate swab',
                      value: 'Nasal Mid-Turbinate swab',
                    },
                    {
                      text: 'Anterior Nasal swab',
                      value: 'Anterior Nasal swab',
                    },
                  ]}
                  value={transportName}
                  onChange={(e, { value }) => setTransportName(value)}
                  invalid={errors.transportName}
                />
              </div>
            )}
            {clientInfo?.type === 'lab' && (
              <div className="appointment-details__modal-content__input">
                <Select
                  label="Specimen collector"
                  labelTextId="documents.specimen_collector"
                  placeholder={
                    <Text id="documents.specimen_collector">
                      Specimen collector
                    </Text>
                  }
                  size="fullWidth"
                  border
                  search
                  icon={false}
                  options={clinics}
                  value={issuerName}
                  onChange={(e, { value }) => setIssuerName(value)}
                  invalid={errors.issuerName}
                />
              </div>
            )}
          </div>
          {subType !== 'CH_PCR_ANTIGEN_TEST' &&
            subType !== 'CH_PCR_PRO_S_ANTIBODY_TEST' &&
            subType !== 'CH_PCR_PRO_ANTIBODY_TEST' && (
              <div className="appointment-details__modal-content__inputs-wrapper">
                <div className="appointment-details__modal-content__input">
                  <Text id="documents.results_reported_date/time" type="label">
                    Results reported date/time
                  </Text>
                  <div className="appointment-details__modal-content__input--row">
                    <Cleave
                      style={{ width: '200px' }}
                      size="fullWidth"
                      placeholder="DD/MM/YYYY"
                      options={{
                        date: true,
                        datePattern: ['d', 'm', 'Y'],
                        delimiter: '/',
                      }}
                      onChange={(e) => setIssuedDate(e.target.value)}
                      value={issuedDate}
                    />
                    <div className="ml-2">
                      <Cleave
                        style={{ width: '100px' }}
                        size="fullWidth"
                        placeholder="hh:mm"
                        options={{
                          time: true,
                          timePattern: ['h', 'm'],
                        }}
                        onChange={(e) => setIssuedTime(e.target.value)}
                        value={issuedTime}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          {subType !== 'CH_PCR_ANTIGEN_TEST' &&
          subType !== 'CH_PCR_PRO_S_ANTIBODY_TEST' &&
          subType !== 'CH_PCR_PRO_ANTIBODY_TEST' ? (
            <div className="appointment-details__modal-content__inputs-wrapper">
              {subType !== 'SG_ANTI_RAPID_TEST' &&
                subType !== 'RMSP_SG_ANTI_RAPID_TEST' &&
                subType !== 'SG_LAMP' &&
                subType !== 'SP_SG_ANTI_RAPID_TEST' &&
                subType !== 'PA_SG_ANTI_RAPID_TEST' && (
                  <div className="appointment-details__modal-content__input">
                    <Select
                      label="Lab that conducted test"
                      labelTextId="documents.lab_that_conducted_test"
                      placeholder={
                        <Text id="documents.select_lab">Select lab</Text>
                      }
                      size="fullWidth"
                      border
                      search
                      icon={false}
                      options={labs}
                      value={lab}
                      onChange={(e, { value }) => setLab(value)}
                      invalid={errors.lab}
                    />
                  </div>
                )}

              <div className="appointment-details__modal-content__input">
                <Select
                  label="Test result"
                  labelTextId="documents.select_result"
                  placeholder={
                    <Text id="documents.select_result">Select result</Text>
                  }
                  border
                  size="fullWidth"
                  search
                  icon={false}
                  options={[
                    { text: 'Negative', value: 'Negative' },
                    { text: 'Positive', value: 'Positive' },
                  ]}
                  value={result}
                  onChange={(e, { value }) => setResult(value)}
                  invalid={errors.result}
                />
              </div>
              {subType === 'TW_PCR_TEST' && (
                <div className="appointment-details__modal-content__input">
                  <Input
                    size="fullWidth"
                    label="Sample No."
                    labelTextId="documents.sample_no"
                    border
                    onChange={(e) => setACSN(e.target.value)}
                    value={acsn}
                    invalid={errors.acsn}
                    placeholder="Sample number"
                  />
                </div>
              )}
              {subType === 'TW_PCR_TEST' && result === 'Positive' && (
                <div className="appointment-details__modal-content__input">
                  <Input
                    size="fullWidth"
                    label="Cycle Threshold Value"
                    labelTextId="documents.cycle_threshold_value"
                    border
                    onChange={(e) => onAdditionalChange(e.target.value, 'ct')}
                    invalid={errors.ct}
                    value={extraData.ct}
                    placeholder="Cycle Threshold Value"
                  />
                </div>
              )}
              {(subType === 'CH_PDT_PCR_TEST' ||
                subType === 'CH_PCR_ANTIGEN_TEST' ||
                subType === 'CH_PCR_PRO_S_ANTIBODY_TEST' ||
                subType === 'CH_PCR_PRO_ANTIBODY_TEST') && (
                <>
                  <div className="appointment-details__modal-content__input">
                    <Select
                      size="fullWidth"
                      label="Reagent Brand"
                      labelTextId="documents.reagent_brand"
                      border
                      search
                      icon={false}
                      placeholder={
                        <Text id="documents.select_a_reagent_brand">
                          Select a Reagent brand
                        </Text>
                      }
                      options={Object.keys(reagentBrands).map((key) => ({
                        value: key,
                        text: key,
                      }))}
                      value={extraData.reagentBrand}
                      onChange={(e, { value }) =>
                        onAdditionalChange(value, 'reagentBrand')
                      }
                      invalid={errors.reagentBrand}
                    />
                  </div>
                </>
              )}
              {(subType === 'PH_PCR_TEST' || subType === 'PH_PDT_SF_TEST') && (
                <div className="appointment-details__modal-content__input">
                  <Input
                    size="fullWidth"
                    label="Specimen No."
                    border
                    onChange={(e) => setACSN(e.target.value)}
                    value={acsn}
                    invalid={errors.acsn}
                    placeholder="Specimen no."
                  />
                </div>
              )}
              {subType === 'PH_PDT_SF_TEST' && (
                <div className="appointment-details__modal-content__free-text">
                  <TextArea
                    label="Remarks/recommendations"
                    labelTextId="documents.remarks/recommendations"
                    border
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                    placeholder="Remarks and recommendations"
                  />
                </div>
              )}
              {(subType === 'SG_PDT_PCR_TEST' ||
                subType === 'CH_PDT_PCR_TEST' ||
                subType === 'SG_PCR_TEST' ||
                subType === 'CH_PCR_PRO_ANTIBODY_TEST' ||
                subType === 'SEROLOGY' ||
                subType === 'SEROLOGY_IGG' ||
                subType === 'CH_PCR_PRO_S_ANTIBODY_TEST' ||
                subType === 'CH_PCR_ANTIGEN_TEST') && (
                <div className="appointment-details__modal-content__input">
                  <Input
                    size="fullWidth"
                    label="Lab ID"
                    labelTextId="documents.lab_id"
                    border
                    onChange={(e) => setACSN(e.target.value)}
                    value={acsn}
                    invalid={errors.acsn}
                    placeholder="Lab ID"
                  />
                </div>
              )}
              {(subType === 'SG_ANTI_RAPID_TEST' ||
                subType === 'RMSP_SG_ANTI_RAPID_TEST' ||
                subType === 'SP_SG_ANTI_RAPID_TEST' ||
                subType === 'PA_SG_ANTI_RAPID_TEST') && (
                <>
                  {!isFreeText ? (
                    <div className="appointment-details__modal-content__input">
                      <Select
                        label="Test kit"
                        labelTextId="documents.test_kit"
                        border
                        size="fullWidth"
                        placeholder={
                          <Text id="documents.select_a_test_kit">
                            Select a test kit
                          </Text>
                        }
                        search
                        icon={false}
                        options={formattedAntigenTypes}
                        value={antigenType}
                        onChange={(e, { value }) => setAntigenType(value)}
                        invalid={errors.antigenType}
                      />
                    </div>
                  ) : (
                    <div className="appointment-details__modal-content__input">
                      <Input
                        size="fullWidth"
                        label="Test kit"
                        labelTextId="documents.test_kit"
                        optional
                        optionalLabel="Free text"
                        border
                        invalid={errors.antigenType}
                        onChange={(e) => setFreeTextTestKit(e.target.value)}
                        value={freeTextTestKit}
                        errorMessage=" "
                        placeholder="Test kit name"
                      />
                      <Text id="documents.send_missing_kit" style={{ padding: '5px' }} size="tiny">
                        Send us the missing test kit names via the support chat
                      </Text>
                      <div style={{ padding: '5px', display: 'flex !important' }} size="tiny">
                        <Text id="documents.do" size="tiny" dark>DO:</Text>
                        <Text id="documents.abbott" size="tiny">Abbott</Text>
                      </div>
                      <div style={{ padding: '5px', display: 'flex !important' }} size="tiny">
                        <Text id="documents.dont" size="tiny" dark>DON'T: </Text>
                        <Text id="documents.abbott_antigen_rapid_test_kit" size="tiny">Abbott Antigen Rapid Test Kit</Text>
                      </div>
                    </div>
                  )}
                  <div>
                    <Text bold size="small">
                      Can't find the Test Kit?
                    </Text>
                    <Text size="tiny">
                      Toggle me to enter a test kit manually
                    </Text>
                    <Switch
                      className="appointment-details__modal-content__switch"
                      onChange={() => toggleFreeText(!isFreeText)}
                      checked={isFreeText}
                    />
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              <div>
                <div className="appointment-details__modal-content__sub-header">
                  <Text>RT-PCR results</Text>
                </div>
                <div className="appointment-details__modal-content__inputs-wrapper">
                  <div className="appointment-details__modal-content__input">
                    <Text type="label">Results reported date/time</Text>
                    <div className="appointment-details__modal-content__input--row">
                      <Cleave
                        style={{ width: '200px' }}
                        size="fullWidth"
                        placeholder="DD/MM/YYYY"
                        options={{
                          date: true,
                          datePattern: ['d', 'm', 'Y'],
                          delimiter: '/',
                        }}
                        onChange={(e) => setIssuedDate(e.target.value)}
                        value={issuedDate}
                      />
                      <div className="ml-2">
                        <Cleave
                          style={{ width: '100px' }}
                          size="fullWidth"
                          placeholder="hh:mm"
                          options={{
                            time: true,
                            timePattern: ['h', 'm'],
                          }}
                          onChange={(e) => setIssuedTime(e.target.value)}
                          value={issuedTime}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="appointment-details__modal-content__inputs-wrapper">
                  <div className="appointment-details__modal-content__input">
                    <Select
                      label="Lab that conducted test"
                      placeholder="Select lab"
                      size="fullWidth"
                      border
                      search
                      icon={false}
                      options={labs}
                      value={lab}
                      onChange={(e, { value }) => setLab(value)}
                      invalid={errors.lab}
                    />
                  </div>

                  <div className="appointment-details__modal-content__input">
                    <Select
                      label="Test result"
                      placeholder="Select result"
                      border
                      search
                      size="fullWidth"
                      icon={false}
                      options={[
                        { text: 'Negative', value: 'Negative' },
                        { text: 'Positive', value: 'Positive' },
                      ]}
                      value={result}
                      onChange={(e, { value }) => setResult(value)}
                      invalid={errors.result}
                    />
                  </div>
                  <div className="appointment-details__modal-content__input">
                    <Input
                      size="fullWidth"
                      label="Lab ID"
                      border
                      onChange={(e) => setACSN(e.target.value)}
                      value={acsn}
                      invalid={errors.acsn}
                      placeholder="Lab ID"
                    />
                  </div>
                  <div className="appointment-details__modal-content__input">
                    <Select
                      size="fullWidth"
                      label="Reagent Brand"
                      border
                      search
                      icon={false}
                      placeholder="Select a Reagent brand"
                      options={Object.keys(reagentBrands).map((key) => ({
                        value: key,
                        text: key,
                      }))}
                      value={extraData.reagentBrand}
                      onChange={(e, { value }) =>
                        onAdditionalChange(value, 'reagentBrand')
                      }
                      invalid={errors.reagentBrand}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="appointment-details__modal-content__sub-header">
                  <Text>Serology results</Text>
                </div>
                <div className="appointment-details__modal-content__inputs-wrapper">
                  <div className="appointment-details__modal-content__input">
                    <Text type="label">Results reported date/time</Text>
                    <div className="appointment-details__modal-content__input--row">
                      <Cleave
                        style={{ width: '200px' }}
                        size="fullWidth"
                        placeholder="DD/MM/YYYY"
                        options={{
                          date: true,
                          datePattern: ['d', 'm', 'Y'],
                          delimiter: '/',
                        }}
                        onChange={(e) => setReportDate(e.target.value)}
                        value={reportDate}
                      />
                      <div className="ml-2">
                        <Cleave
                          style={{ width: '100px' }}
                          size="fullWidth"
                          placeholder="hh:mm"
                          options={{
                            time: true,
                            timePattern: ['h', 'm'],
                          }}
                          onChange={(e) => setReportTime(e.target.value)}
                          value={reportTime}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="appointment-details__modal-content__inputs-wrapper">
                  <div className="appointment-details__modal-content__input">
                    <Select
                      label="Lab that conducted test"
                      placeholder="Select lab"
                      size="fullWidth"
                      border
                      search
                      icon={false}
                      options={labs}
                      value={secondLab}
                      onChange={(e, { value }) => setSecondLab(value)}
                      invalid={errors.secondLab}
                    />
                  </div>

                  <div className="appointment-details__modal-content__input">
                    <Select
                      label="Test result"
                      placeholder="Select result"
                      border
                      search
                      size="fullWidth"
                      icon={false}
                      options={[
                        { text: 'Negative', value: 'Negative' },
                        { text: 'Positive', value: 'Positive' },
                      ]}
                      value={secondResult}
                      onChange={(e, { value }) => setSecondResult(value)}
                      invalid={errors.secondResult}
                    />
                  </div>
                  <div className="appointment-details__modal-content__input">
                    <Input
                      size="fullWidth"
                      label="Lab ID"
                      border
                      onChange={(e) => setSecondACSN(e.target.value)}
                      value={secondAcsn}
                      invalid={errors.secondAcsn}
                      placeholder="Lab ID"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="appointment-details__modal-content__column">
          <div className="appointment-details__modal-content__header">
            Email
          </div>
          {email?.length > 0 && (
            <Text style={{ padding: '5px' }} size="tiny">
              Email is automatically sent to patient email ({email})
              {clientInfo.notCcAll
                ? '.'
                : ` and account
              holder email (${clientInfo.accountHolderEmail}).`}
            </Text>
          )}
          <div className="appointment-details__modal-content__inputs-wrapper">
            <div className="appointment-details__modal-content__input">
              <Input
                optional
                size="fullWidth"
                label="CC email"
                border
                onChange={(e) => setCcEmail(e.target.value)}
                value={ccEmail}
                placeholder="CC email"
                invalid={errors.ccEmail0}
                errorMessage="Invalid email"
              />
            </div>
            <div className="appointment-details__modal-content__input">
              <Input
                optional
                size="fullWidth"
                label="CC email"
                border
                onChange={(e) => setCcEmail1(e.target.value)}
                value={ccEmail1}
                placeholder="CC email"
                invalid={errors.ccEmail1}
                errorMessage="Invalid email"
              />
            </div>
            <div className="appointment-details__modal-content__input">
              <Input
                optional
                size="fullWidth"
                label="CC email"
                border
                onChange={(e) => setCcEmail2(e.target.value)}
                value={ccEmail2}
                placeholder="CC email"
                invalid={errors.ccEmail2}
                errorMessage="Invalid email"
              />
            </div>
          </div>
        </div>
        {disableIssue && (
          <div className="result-details__info">
            <div className="result-details__info__text">
              <Icon name="info" />
              <Text>{disableIssueText}</Text>
            </div>
          </div>
        )}
        <div className="appointment-details__modal-content__buttons">
          <Modal
            trigger={
              <Button
                inverted
                size="wide"
                color="green"
                style={{ width: '500px', height: '50px !important' }}
                onClick={previewDoc}
                disabled={isSaving}
                isLoading={isPreviewLoading}
              >
                <SIcon name="clone outline" /> Preview Document
              </Button>
            }
            open={previewToggled}
            onClose={() => togglePreview(false)}
          >
            <Modal.Header>Sample Document Preview</Modal.Header>
            <Modal.Content
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CertView
                className="appointment-details__peme-preview"
                certificate={docPreview}
              />
            </Modal.Content>
          </Modal>
          <Button
            size="fullWidth"
            color="blue"
            onClick={issueCertificate}
            disabled={isSaving || disableIssue}
            isLoading={isSaving}
          >
            <SIcon name="paper plane outline" /> Issue
          </Button>
        </div>
      </Page>
    </>
  );
};

export default IssuePcrCert;
