import React from 'react';
import Switch from 'rc-switch';

import 'rc-switch/assets/index.css';
import './switch.scss';

const Switcher = props => {
  return (
    <Switch
      className={props.className}
      onChange={props.onChange}
      checked={props.checked}
      disabled={props.disabled}
      uncheckedIcon={false}
      checkedIcon={false}
      height={props.height}
      onColor="#85b7d9"
    />
  );
};

Switcher.defaultProps = {
  height: 25,
};

export default Switcher;
