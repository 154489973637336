import React from 'react';

const SvgDollar = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 32" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      stroke="#1b1b1b"
      strokeWidth={3}
    >
      <path d="M15.101 16H13.9C10.64 16 8 13.538 8 10.5S10.64 5 13.899 5H21M15.101 16C18.36 16 21 18.462 21 21.5S18.36 27 15.101 27H8M15 2v28.033" />
    </g>
  </svg>
);

export default SvgDollar;
