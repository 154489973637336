// import * as React from 'react';

// function Dashboard(props) {
//   return (
//     <svg
//       width={props.width}
//       height={props.height}
//       viewBox="0 0 30 30"
//       fill="none"
//       {...props}
//     >
//       <path
//         d="M5 16.25h7.5c.688 0 1.25-.563 1.25-1.25V5c0-.688-.563-1.25-1.25-1.25H5c-.688 0-1.25.563-1.25 1.25v10c0 .688.563 1.25 1.25 1.25zm0 10h7.5c.688 0 1.25-.563 1.25-1.25v-5c0-.688-.563-1.25-1.25-1.25H5c-.688 0-1.25.563-1.25 1.25v5c0 .688.563 1.25 1.25 1.25zm12.5 0H25c.688 0 1.25-.563 1.25-1.25V15c0-.688-.563-1.25-1.25-1.25h-7.5c-.688 0-1.25.563-1.25 1.25v10c0 .688.563 1.25 1.25 1.25zM16.25 5v5c0 .688.563 1.25 1.25 1.25H25c.688 0 1.25-.563 1.25-1.25V5c0-.688-.563-1.25-1.25-1.25h-7.5c-.688 0-1.25.563-1.25 1.25z"
//         fill="#1b1b1b"
//       />
//     </svg>
//   );
// }

// Dashboard.defaultProps = {
//   width: 30,
//   height: 30,
// };

// export default Dashboard;

import * as React from 'react';

function Dashboard(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M3.5 7.583c0-3.062.033-4.083 4.083-4.083s4.084 1.02 4.084 4.083.013 4.084-4.084 4.084c-4.096 0-4.083-1.02-4.083-4.084zM16.333 7.583c0-3.062.033-4.083 4.084-4.083 4.05 0 4.083 1.02 4.083 4.083s.013 4.084-4.083 4.084c-4.097 0-4.084-1.02-4.084-4.084zM3.5 20.417c0-3.063.033-4.084 4.083-4.084s4.084 1.02 4.084 4.084c0 3.062.013 4.083-4.084 4.083-4.096 0-4.083-1.02-4.083-4.083zM16.333 20.417c0-3.063.033-4.084 4.084-4.084 4.05 0 4.083 1.02 4.083 4.084 0 3.062.013 4.083-4.083 4.083-4.097 0-4.084-1.02-4.084-4.083z"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Dashboard.defaultProps = {
  width: 27,
  height: 27,
};

export default Dashboard;
