import React from 'react';

const SvgBell = props => (
  <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
    <g
      stroke="#1b1b1b"
      strokeWidth={3}
      transform="translate(3 2)"
      fill="none"
      fillRule="evenodd"
    >
      <path d="M20 20.24S20 0 10 0 0 20.24 0 20.24" />
      <path d="M8.182 17.48A1.83 1.83 0 0010 19.32a1.83 1.83 0 001.818-1.84" />
      <ellipse cx={10} cy={20.24} rx={10} ry={2.76} />
    </g>
  </svg>
);

export default SvgBell;
