import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from '.';
import colors from '../styles/colors';

const Toggle = props => {
  return (
    <ToggleWrapper
      isLabel={!!props.label}
      className={props.className}
      style={props.style}
      size={props.size}
    >
      {!!props.label && (
        <ToggleLabel>
          <Text
            type="label"
            style={{ paddingTop: '3px !important' }}
            className={props.labelClassName}
          >
            {props.label}
          </Text>
        </ToggleLabel>
      )}
      <ToggleGroup>
        <ToggleInput
          checked={props.checked}
          type="checkbox"
          onClick={props.onClick}
          onChange={e => props.onChange(e.target.checked)}
        />
        <Checkmark checked={props.checked} />
      </ToggleGroup>
    </ToggleWrapper>
  );
};

Toggle.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
};

Toggle.defaultProps = {
  onClick: () => {},
  onChange: () => {},
  checked: false,
  label: '',
  size: 'medium',
};

const getSize = size => {
  if (size === 'tiny') return { width: '50px' };
  if (size === 'small') return { width: '150px' };
  if (size === 'medium') return { width: '300px' };
  if (size === 'large') return { width: '400px' };
  if (size === 'fullWidth') return { width: '100%' };
};

const ToggleWrapper = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  ${props => getSize(props.size)}
`;

const ToggleGroup = styled.div`
  position: relative;
  width: 60px;
  height: 34px;
`;

const ToggleLabel = styled.div`
  position: relative;
  line-height: 26px;
  flex: 0 0 calc(100% - 60px);
`;

const Checkmark = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.GREY};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  ${props =>
    props.checked ? `background-color: ${colors.HIGHLIGHT_BLUE};` : ''}

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    ${props =>
      props.checked ? 'transform: scale(1.1);transform: translateX(26px);' : ''}
  }
`;

const ToggleInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  opacity: 0;
  width: 0;
  height: 0;
`;

export default Toggle;
