import React from 'react';

const SvgImage = props => (
  <svg width="1em" height="1em" viewBox="0 0 37 27" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect
        stroke="#1b1b1b"
        strokeWidth={3}
        x={1.5}
        y={1.5}
        width={34}
        height={24}
        rx={2}
      />
      <path d="M2 25l9.35-11.258a2 2 0 012.996-.09L25 25H2z" fill="#1b1b1b" />
      <path d="M17 25l8.168-6.96a2 2 0 012.5-.075L37 25H17z" fill="#1b1b1b" />
      <circle fill="#1b1b1b" cx={22} cy={9} r={3} />
    </g>
  </svg>
);

export default SvgImage;
