import { SET_LAB_DOCUMENTS } from './labDocuments.actions';

const initialState = {
  labDocuments: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LAB_DOCUMENTS:
      return { ...state, labDocuments: action.payload };

    default:
      return state;
  }
};
