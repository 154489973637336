import React from 'react';
import { useCustomForm } from 'hooks';

import { Button, Icon, Input, Select, Text } from 'components';

import PageHeader from '../../../components/PageHeader/PageHeader';
import { completeProfile1Schema } from '../../schema';

import './completeProfile.scss';

const CompleteProfile = ({
  updateDetails,
  nationalities,
  ethnicities,
  languages,
  onNextClick,
  details,
  isSaving,
  breadCrumb,
}) => {
  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
  } = useCustomForm(completeProfile1Schema, {
    nationality: details.nationality || '',
    passNo: details.passNo || '',
    ssn: details.ssn || '',
    language: details.language || '',
    ethnicity: details.ethnicity || '',
  });

  const onValChange = (field, value, callback) => {
    updateDetails({ ...details, [field]: value });
    callback(value);
  };
  return (
    <>
      <div className="completeProfile__header">
        <PageHeader
          titleId="patient_app.suggestion_complete"
          title="Complete your profile"
          backPath="/settings"
        />
        {breadCrumb}
      </div>
      <div className="completeProfile">
        <div className="completeProfile__form">
          <form onSubmit={handleSubmit(onNextClick)}>
            <div className="completeProfile__form__grid-wrapper">
              <div className="completeProfile__form__grid">
                <Controller
                  name="ethnicity"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={
                        <Text
                          id="add_patient.race"
                          type="label"
                          color="inherit"
                        >
                          Race
                        </Text>
                      }
                      border
                      placeholder="Select a race"
                      value={value}
                      onChange={(e, { value }) =>
                        onValChange('ethnicity', value, onChange)
                      }
                      options={ethnicities}
                      search
                      errorMessage=""
                      size="fullWidth"
                      selection
                      className="u-patient-form__form__input"
                      invalid={errors?.ethnicity}
                    />
                  )}
                />
                <Controller
                  name="nationality"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={
                        <Text
                          id="add_patient.nationality"
                          color="inherit"
                          type="label"
                        >
                          Nationality
                        </Text>
                      }
                      border
                      placeholder="Select your nationality"
                      value={value}
                      onChange={(e, { value }) =>
                        onValChange('nationality', value, onChange)
                      }
                      options={nationalities}
                      search
                      size="fullWidth"
                      errorMessage=""
                      selection
                      className="u-patient-form__form__input"
                      invalid={errors?.nationality}
                    />
                  )}
                />
                <Controller
                  name="ssn"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      border
                      label={
                        <Text
                          color="inherit"
                          type="label"
                          id="add_patient.national_id"
                        >
                          National ID No.
                        </Text>
                      }
                      placeholder="Enter your national ID number"
                      size="fullWidth"
                      value={value}
                      errorMessage="This field requires 7 - 22 characters"
                      onChange={(e) =>
                        onValChange('ssn', e.target.value, onChange)
                      }
                      invalid={errors?.ssn}
                    />
                  )}
                />
                <Controller
                  name="passNo"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Input
                        border
                        className="u-patient-form__form__input patient-form__form__input"
                        label={
                          <Text
                            id="add_patient.passport_no"
                            color="inherit"
                            type="label"
                          >
                            Passport No.
                          </Text>
                        }
                        placeholder="Enter your passport number"
                        size="fullWidth"
                        errorMessage="This field requires 7 - 22 characters"
                        value={value}
                        onChange={(e) =>
                          onValChange('passNo', e.target.value, onChange)
                        }
                        invalid={errors?.passNo}
                        showNote
                        note={
                          <Text
                            id="patient_app.passport_note"
                            color="inherit"
                            type="label"
                          >
                            If you are a frequent traveller this might be useful
                          </Text>
                        }
                      />
                    </>
                  )}
                />
                <Controller
                  name="language"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={
                        <Text
                          id="patient_app.settings.my_profile.identity.primary_language"
                          color="inherit"
                          type="label"
                        >
                          Primary Language
                        </Text>
                      }
                      border
                      search
                      size="fullWidth"
                      errorMessage=""
                      placeholder="Select your preferred language"
                      invalid={errors?.language}
                      value={value}
                      onChange={(e, { value }) =>
                        onValChange('language', value, onChange)
                      }
                      options={languages}
                    />
                  )}
                />
              </div>
            </div>
            <div className="completeProfile__button global__fixed-button">
              <Button
                type="submit"
                // color="black"
                color="blue"
                className="rec-ph__button mt-4"
                size="fullWidth"
                // onClick={onNextClick}
                isLoading={isSaving}
                disabled={isSaving}
              >
                <Text
                  id="patient_app.settings.my_profile.next"
                  color="inherit"
                  type="label"
                >
                  Next
                </Text>
                <Icon name="arrowRight" className="ml-3" color="white" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default CompleteProfile;
