import { SET_ISSUED_CERTIFICATES } from './issuedCertificates.actions';

const initialState = {
  issuedCertificates: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ISSUED_CERTIFICATES:
      return { ...state, issuedCertificates: action.payload };

    default:
      return state;
  }
};
