import { patientOverviewClient } from './patientOverviewClient';
import pick from '../../utils/pick';

async function getAll({ search, offset, limit }) {
  try {
    const requestBody = pick.pickNotNull({
      search,
      offset,
      limit,
    });
    const profileResponse = await patientOverviewClient.post(
      'medicalProfile/public/conditions/listAll',
      requestBody
    );
    return profileResponse.data?.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getConditionsOfPatient() {
  try {
    const profileResponse = await patientOverviewClient.post(
      'medicalProfile/getByUser',
      { fields: ['conditionList'] }
    );
    return profileResponse.data?.data?.profile?.conditionList || [];
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function addCondition(condition) {
  const response = await patientOverviewClient.post('medicalProfile/create', {
    condition,
  });

  return response.data;
}

async function updateCondition(condition) {
  const response = await patientOverviewClient.post(
    'medicalProfile/conditions/update',
    condition
  );
  return response.data;
}

async function deleteCondition(id) {
  const response = await patientOverviewClient.post(
    'medicalProfile/conditions/delete',
    {
      id,
    }
  );
  return response.data;
}

const conditionApi = {
  getAll,
  getConditionsOfPatient,
  addCondition,
  updateCondition,
  deleteCondition,
};

export default conditionApi;
