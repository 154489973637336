import * as yup from 'yup';

export const vitalSchema = yup.object().shape(
  {
    weight: yup.string().when('weight', {
      is: (val) => Boolean(val),
      then: yup
        .string()
        .test(
          'should be a number between 30 - 250',
          (val) => !isNaN(val) && Number(val) >= 30 && Number(val) <= 250
        ),
    }),
    height: yup.string().when('height', {
      is: (val) => Boolean(val),
      then: yup
        .string()
        .test(
          'should be a number between 140 - 210',
          (val) => !isNaN(val) && Number(val) >= 140 && Number(val) <= 210
        ),
    }),
    systolicUpperNumber: yup.string().when('systolicUpperNumber', {
      is: (val) => Boolean(val),
      then: yup
        .string()
        .test(
          'should be a number between 50 - 300',
          (val) => !isNaN(val) && Number(val) >= 50 && Number(val) <= 300
        ),
    }),
    diastolicLowerNumber: yup.string().when('diastolicLowerNumber', {
      is: (val) => Boolean(val),
      then: yup
        .string()
        .test(
          'should not be less than 30',
          (val) => !isNaN(val) && Number(val) >= 30
        ),
    }),
    heartRate: yup.string(),
    pulseOximeter: yup.string(),
    perfusionIndex: yup.string(),
  },
  [
    ['weight', 'weight'],
    ['height', 'height'],
    ['systolicUpperNumber', 'systolicUpperNumber'],
    ['diastolicLowerNumber', 'diastolicLowerNumber'],
  ]
);

export const visualAcuitySchema = yup.object().shape(
  {
    snellenTestVAid: yup.string(),
    snellenTestOd: yup.string(),
    snellenTestOs: yup.string(),
    snellenTestUpdatedAt: yup.string(),
    visualRefractionTestOd: yup.number(),
    visualRefractionTestOs: yup.number(),
    visualRefractionTestRemark: yup.string(),
    visualRefractionTestUpdatedAt: yup.string(),
    colorBlindness: yup.string(),
    colorBlindnessRemark: yup.string(),
    colorBlindnessUpdatedAt: yup.string(),
    eyePressure: yup.number(),
    eyePressureUpdatedAt: yup.string(),
  }
);
