
const allowToEditPatient = ['superAdmin', 'user', 'admin']
export function isAllowedToEditPatient(role) {
  return allowToEditPatient.includes(role)
}
export function filterQuestionnaireDocs(role, docs) {
  if (['clinicianAdmin', 'superAdmin', 'user', 'admin'].includes(role) ) {
    return docs
  }

  return docs.filter(doc => doc.template !== 'Medical Encounter Form');
}

export function filterDocumentTypes(role, docs) {
  if (['clinicianAdmin', 'superAdmin', 'user', 'admin'].includes(role) ) {
    return docs
  }

  return docs.filter(doc => doc.name !== 'Medical Encounter Form');
}