import React from 'react';

const SvgLink = props => (
  <svg width="1em" height="1em" viewBox="0 0 21 14" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#1b1b1b" strokeWidth={2}>
      <rect x={1} width={15} height={9} rx={4} />
      <rect x={6} y={4} width={15} height={9} rx={4} />
    </g>
  </svg>
);

export default SvgLink;
