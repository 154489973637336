import React, { useEffect, useState } from 'react';
import { Loader, Modal, Icon } from 'semantic-ui-react';

import {
  Button,
  ErrorToast,
  Input,
  SuccessToast,
} from 'components';
import { toast } from 'react-toastify';

import CerticficateService from 'api-delta/certificates';

import './emailModal.scss';

const EmailModal = ({
  toggleEmailModal,
  emailModalToggled,
  certId,
  certEmail,
  ccEmail,
  clientId,
}) => {
  const [isSendingMail, setSendingMail] = useState(false);
  const [email, setEmail] = useState('');
  const [ccEmails, setCcEmails] = useState([ccEmail]);

  useEffect(() => {
    if (ccEmail === certEmail) setCcEmails([]);
    else if (ccEmail) setCcEmails([ccEmail]);
    setEmail(certEmail, ccEmail);
  }, [certEmail, ccEmail]);

  const sendEmail = async () => {
    try {
      setSendingMail(true);
      await CerticficateService.sendDocumentEmail(
        clientId,
        certId,
        email,
        ccEmails
      );
      setSendingMail(false);
      toggleEmailModal(false);
      toast.success(<SuccessToast message="Email sent" />);
    } catch (error) {
      setSendingMail(false);
      toast.error(<ErrorToast message="Error sending email" />);
    }
  };

  const updateCcEmail = (key, value) => {
    const updatedCC = [...ccEmails];
    updatedCC[key] = value;
    setCcEmails(updatedCC);
  };

  return (
    <Modal
      size="tiny"
      open={emailModalToggled}
      onClose={() => toggleEmailModal(false)}
      className="email-modal"
    >
      <Modal.Header>Send document email</Modal.Header>
      <Modal.Content>
        <div className="email-modal__content">
          {/* {!clientInfo.notCcAll && (
            <Text className="email-modal__content__info" size="small">
              Email is automatically sent the account holder email:{' '}
              <b>{clientInfo.accountHolderEmail}</b>
            </Text>
          )} */}
          <Input
            label="Send to"
            border
            onChange={e => setEmail(e.target.value)}
            value={email}
            placeholder="Receiver email address"
          />
          <div className="email-modal__content__row">
            <Input
              label="CC Email"
              optional
              border
              onChange={e => updateCcEmail(0, e.target.value)}
              value={ccEmails[0]}
              placeholder="CC email address"
            />
            <Input
              label="CC Email"
              optional
              border
              onChange={e => updateCcEmail(1, e.target.value)}
              value={ccEmails[1]}
              placeholder="CC email address"
            />
          </div>
        </div>
        <div className="email-modal__buttons">
          <Button
            size="medium"
            onClick={() => toggleEmailModal(false)}
            inverted
          >
            <Icon name="close" />
            Cancel
          </Button>
          <Button
            size="medium"
            onClick={() => sendEmail()}
            color="green"
            disabled={isSendingMail}
          >
            {isSendingMail ? (
              <Loader active inverted size="tiny" color="white" />
            ) : (
              <>
                <Icon name="mail outline" /> Send
              </>
            )}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default EmailModal;
