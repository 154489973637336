function extractIdentifierInformation(identifiers) {
  if (!identifiers) {
    return {
      socialSecurityNumber: null,
      driversLicense: null,
      passportNumber: null,
    };
  }
  let socialSecurityNumber;
  let driversLicense;
  let passportNumber;
  identifiers.forEach((identifier) => {
    const codes = identifier.type?.coding?.filter(Boolean);
    if (codes?.length) {
      const code = codes[0];
      switch (code.code.toLowerCase()) {
        case 'ss':
          socialSecurityNumber = identifier.value;
          break;
        case 'dl':
          driversLicense = identifier.value;
          break;
        case 'ppn':
          passportNumber = identifier.value;
      }
    }
  });

  return {
    socialSecurityNumber,
    driversLicense,
    passportNumber,
  };
}

function extractAddress(address) {
  if (!address) {
    return undefined;
  }
  return {
    line1: address.line[0] || '',
    line2: address.line[1] || '',
    city: address.city || '',
    state: address.state || '',
    postalCode: address.postalCode || '',
    country: address.country || '',
  };
}

function extractTelecom(telecom = []) {
  if (!telecom) {
    return undefined;
  }
  const phone = telecom.find((t) => t.system === 'phone')?.value;
  return {
    phone,
  };
}

function convertToDisplayedPatient(patient) {
  if (!patient) {
    return null;
  }
  const identifier = patient.identifier
    ? extractIdentifierInformation(patient.identifier)
    : null;
  const address = patient.address?.length
    ? extractAddress(patient.address[0])
    : null;
  const telecom = extractTelecom(patient.telecom);
  return {
    id: patient.id || '',
    lastName: patient.name?.find((name) => name.family)?.family || '',
    birthDate: patient.birthDate || '',
    gender: patient.gender || '',
    identifier,
    address,
    telecom,
  };
}

function convertBundle(patientSearchBundle) {
  return patientSearchBundle?.entry?.map((entry) =>
    convertToDisplayedPatient(entry.resource)
  );
}

const patientFhirMapper = {
  convertBundle,
  convertToDisplayedPatient,
};

export default patientFhirMapper;
