import * as React from 'react';
const SvgInterview = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '17px', marginRight: '10px' }}
    {...props}
  >
    <g clipPath="url(#clip0_99_523)">
      <path
        d="M24 15.9999V20.9999C24 21.7955 23.6839 22.5586 23.1213 23.1212C22.5587 23.6838 21.7956 23.9999 21 23.9999H16C14.5971 23.9985 13.2192 23.6281 12.0047 22.926C10.7901 22.224 9.78145 21.2148 9.08 19.9999C9.83387 19.9945 10.5852 19.9114 11.322 19.7519C11.8832 20.4535 12.595 21.0199 13.4048 21.4091C14.2146 21.7982 15.1016 22.0001 16 21.9999H21C21.2652 21.9999 21.5196 21.8945 21.7071 21.707C21.8946 21.5195 22 21.2651 22 20.9999V15.9999C21.9998 15.1011 21.7972 14.2139 21.4074 13.4041C21.0175 12.5943 20.4504 11.8826 19.748 11.3219C19.9088 10.5852 19.9933 9.8339 20 9.07989C21.215 9.78134 22.2241 10.79 22.9262 12.0045C23.6282 13.2191 23.9986 14.597 24 15.9999ZM17.977 9.65089C18.0705 8.36253 17.8856 7.06913 17.4348 5.85859C16.9841 4.64804 16.278 3.54871 15.3646 2.63531C14.4512 1.7219 13.3518 1.01582 12.1413 0.565046C10.9308 0.114271 9.63736 -0.0706368 8.349 0.0228889C6.06592 0.283621 3.95693 1.37006 2.41918 3.07763C0.881427 4.7852 0.0210272 6.99606 0 9.29389L0 14.3339C0 16.8659 1.507 17.9999 3 17.9999H8.7C10.9988 17.9801 13.211 17.1203 14.9198 15.5824C16.6286 14.0446 17.7159 11.9349 17.977 9.65089ZM13.95 4.05089C14.6599 4.76239 15.2088 5.61809 15.5593 6.56008C15.9099 7.50207 16.054 8.50837 15.982 9.51089C15.7686 11.2946 14.9105 12.9387 13.5693 14.1338C12.2282 15.3289 10.4964 15.9926 8.7 15.9999H3C2.072 15.9999 2 14.7249 2 14.3339V9.29389C2.00834 7.49826 2.67265 5.76759 3.86792 4.42757C5.06319 3.08754 6.70699 2.23056 8.49 2.01789C8.656 2.00589 8.822 1.99989 8.988 1.99989C9.90927 1.99903 10.8217 2.17973 11.6731 2.53165C12.5245 2.88357 13.2982 3.39982 13.95 4.05089Z"
        fill={props?.color || '#25282C'}
      />
    </g>
    <defs>
      <clipPath id="clip0_99_523">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgInterview;
