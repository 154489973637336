import gql from 'graphql-tag';

export const getEmailsByClientId = gql`
  query getEmailsByClientId(
    $clientId: ID!
    $limit: Int
    $status: String
    $dayPeriod: Boolean
    $search: String
  ) {
    getEmailsByClientId(
      clientId: $clientId
      limit: $limit
      status: $status
      dayPeriod: $dayPeriod
      search: $search
    ) {
      email
      sg_message_id
      clientId
      timestamp
      sg_template_id
      certId
      event
      patientName
    }
  }
`;

export const getEmailActivityByCertId = gql`
  query getEmailActivityByCertId($certId: String!) {
    getEmailActivityByCertId(certId: $certId) {
      email
      sg_message_id
      clientId
      timestamp
      sg_template_id
      certId
      event
    }
  }
`;
