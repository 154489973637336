import React from 'react';
import moment from 'moment';
import { Table, Popup } from 'semantic-ui-react';
import history from '../../../../history';

import { CheckBox, Text, Input, Button } from 'components';

const RhdTableRow = ({
  participant,
  onRowClick,
  onRowSelect,
  selectedRows,
  isMobile,
  updateValue,
  onBlur,
}) => {
  const onInputClick = (e) => {
    e.stopPropagation();
  };
  const getNextAction = () => {
    if (participant.vitalsStatus === 'pending')
      return { text: 'Update vitals', isText: false };
    if (!participant?.result?.id)
      return { text: 'Wait for results', isText: true };
    if (!participant?.isReviewed?.status)
      return { text: 'Review result', isText: false };
    if (
      participant?.result?.id &&
      participant?.isReviewed?.status &&
      !participant.reportId
    )
      return {
        text: 'Issue ReACT report',
        isText: false,
      };

    return { text: 'No action needed', isText: true };
  };

  const onActionClick = (e) => {
    // onInputClick(e);
    // let path;
    // if (participant.vitalsStatus === 'pending')
    //   path = `/patients/${participant.patientId}`;
    // else if (participant?.isReviewed?.status)
    //   path = `/results/fhir/zHAzw9fw2H4cGE8udj50-CAS20230323.000003.2022-06-13`;
    // // path = `/results/fhir/${participant.result.id}`;
    // history.push({
    //   pathname: path,
    //   state: { fromReactSession: true },
    // });
  };

  const calcCompletion = () => {
    let count = 0;

    if (participant?.questionnaire?.status === 'completed') count += 1;
    if (participant?.vitalsStatus === 'completed') count += 1;
    if (!!participant?.result?.id) count += 1;
    if (!!participant?.isReviewed?.status) count += 1;
    if (!!participant?.reportId) count += 1;

    return count;
  };

  return (
    <Table.Row
      key={participant.id}
      className="table-row"
      onClick={(e) => onRowClick(e, participant)}
    >
      {!isMobile && (
        <Table.Cell
          style={{ width: '30px' }}
          onClick={(e) => onRowSelect(e, participant.id, participant)}
        >
          <div>
            <CheckBox
              className="rhd__checkbox"
              checked={!!selectedRows[participant.id]}
            />
          </div>
        </Table.Cell>
      )}
      <Table.Cell>
        <Text size="small" uppercase>
          {participant.patientName}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <div onClick={(e) => onInputClick(e)}>
          <Input
            size="fullWidth"
            border
            onChange={(e) => updateValue(participant.id, e.target.value)}
            onBlur={() => onBlur(participant.id, participant)}
            value={participant.addOns}
          />
        </div>
      </Table.Cell>
      <Table.Cell>{participant?.result?.accNo}</Table.Cell>
      <Table.Cell>
        {moment(participant.updatedAt).format('DD-MM-YYYY HH:mm A')}
      </Table.Cell>
      <Table.Cell>
        {getNextAction().isText ? (
          <Text size="small">{getNextAction().text}</Text>
        ) : (
          <Button
            size="fluid"
            inverted
            color="blue"
            onClick={(e) => onActionClick(e)}
          >
            {getNextAction().text}
          </Button>
        )}
      </Table.Cell>
      {/* <Table.Cell>
        <Text size="small">
          {moment(participant.date).format('DD-MM-YYYY HH:MM A')}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text
          size="small"
          color={participant.status}
          uppercase
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div
            className={`rhd__dot rhd__dot--${participant.status.replace(
              ' ',
              '-'
            )}`}
          />
          {participant.status}
        </Text>
      </Table.Cell> */}
      <Table.Cell textAlign="center">
        <Text
          size="small"
          color={participant?.questionnaire?.status}
          uppercase
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div
            className={`rhd__dot rhd__dot--${participant?.questionnaire?.status.replace(
              ' ',
              '-'
            )}`}
          />
          {participant?.questionnaire?.status}
        </Text>
      </Table.Cell>
      <Popup
        hoverable
        position="top left"
        basic
        trigger={
          <Table.Cell textAlign="center">
            <Text
              size="small"
              color={participant.isComplete}
              uppercase
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                className={`rhd__dot rhd__dot--${
                  participant.isComplete || calcCompletion() === 5
                    ? 'completed'
                    : 'pending-vitals'
                }`}
              />
              {participant.isComplete || calcCompletion() === 5
                ? 'Completed'
                : 'In Progress'}{' '}
              ({calcCompletion()}/5)
            </Text>
          </Table.Cell>
        }
      >
        <Popup.Content>
          <div className="rhd__popup__row">
            <Text bold size="tiny" className="mb-1">
              Questionnaire
            </Text>
            <Text
              size="tiny"
              uppercase
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                className={`rhd__dot rhd__dot--${participant?.questionnaire?.status.replace(
                  ' ',
                  '-'
                )}`}
              />
              {participant?.questionnaire?.status}
            </Text>
          </div>
          <div className="rhd__popup__row">
            <Text bold size="tiny" className="mb-1 mt-3">
              Vitals
            </Text>
            <Text
              size="tiny"
              uppercase
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                className={`rhd__dot rhd__dot--${participant?.vitalsStatus?.replace(
                  ' ',
                  '-'
                )}`}
              />
              {participant?.vitalsStatus}
            </Text>
          </div>
          <div className="rhd__popup__row">
            <Text bold size="tiny" className="mb-1 mt-3">
              Result
            </Text>
            <Text
              size="tiny"
              uppercase
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                className={`rhd__dot rhd__dot--${
                  participant?.result?.id ? 'completed' : 'pending'
                }`}
              />
              {participant?.result?.id ? 'completed' : 'pending'}
            </Text>
          </div>
          <div className="rhd__popup__row">
            <Text bold size="tiny" className="mb-1 mt-3">
              Review
            </Text>
            <Text
              size="tiny"
              uppercase
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                className={`rhd__dot rhd__dot--${
                  !!participant?.isReviewed?.status ? 'completed' : 'pending'
                }`}
              />
              {!!participant?.isReviewed?.status ? 'completed' : 'pending'}
            </Text>
          </div>
          <div className="rhd__popup__row">
            <Text bold size="tiny" className="mb-1 mt-3">
              ReACT Report
            </Text>
            <Text
              size="tiny"
              uppercase
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                className={`rhd__dot rhd__dot--${
                  !!participant?.reportId ? 'completed' : 'pending'
                }`}
              />
              {!!participant?.reportId ? 'completed' : 'pending'}
            </Text>
          </div>
        </Popup.Content>
      </Popup>
    </Table.Row>
  );
};

export default RhdTableRow;
