import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Modal, Icon as SIcon, Dropdown } from 'semantic-ui-react';
import { debounce } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import axios from 'axios';
import downloadFile from 'js-file-download';
import cx from 'classnames';

import {
  Text,
  Search,
  Button,
  ErrorToast,
  CsvReader,
  Select,
} from 'components';
import FailedPatientCreations from './components/failedPatientCreations/FailedPatientCreations';

import nationalities from 'helpers/countriesAndNationalities';

import history from '../../history';
import patientApi from 'api/patient/patientApi';

import './patientsFhir.scss';
import Info from '../../assets/icons/Info';


const PatientsFhir = (props) => {
  let urlSearchParams = new URLSearchParams(window.location.search);
  const defaultSearchParams = {
    limit: Number(urlSearchParams.get(`p-limit`)) || 20,
    page: Number(urlSearchParams.get(`p-page`)) || 1,
    search: urlSearchParams.get(`p-search-term`) || '',
    patientType: urlSearchParams.get(`p-patient-type`) || 'PATIENTS',
    clients: urlSearchParams.get(`p-clients`)?.split(',') || [],
  };

  const { clinicOptions } = useSelector((state) => state.general);
  const { clientId } = useSelector((state) => state.general.clientInformation);
  const filterDropdownRef = useRef();

  const [patients, setPatients] = useState([]);
  const [patientType, setPatientType] = useState('PATIENTS');
  const [uploadPatients, setUploadPatients] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [isSearching, setSearching] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [modalToggled, toggleModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({});
  const [selectedClinics, setSelectedClinics] = useState([clientId]);
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const [isShowFilters, setShowFilters] = useState(false);
  const [failedPatientCreations, setFailedCreations] = useState([]);

  const [isExternal, setIsExternal] = useState(false);

  useEffect(() => {
    const path = history.location.pathname;
    if(path.includes('/external/')) setIsExternal(true);
  }, []);

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        !filterDropdownRef.current ||
        filterDropdownRef.current.contains(event.target)
      ) {
        return;
      }
      setShowFilters(false);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [filterDropdownRef, setShowFilters]);

  const handleSearch = useCallback(
    debounce((params) => {
      fetchPatients(params);
    }, 500),
    [patientType, patients]
  );

  useEffect(() => {
    fetchPatients(searchParams);

    return () => {
      toast.dismiss();
    };
  }, []);

  useEffect(() => {
    if (firstRender) return setFirstRender(false);
    handleSearch(searchParams);
  }, [searchParams, patientType]);

  useEffect(() => {
    const upPatients = defaultCsvFormat();
    setUploadPatients(upPatients);
  }, [csvData]);

  useEffect(() => {
    if (props.location?.state?.isTestOrderFlow) {
      testOrderFlowNotification();
    }
    if (props.location?.state?.isReactSessionFlow) {
      reactFlowNotification();
    }
  }, [
    props.location?.state?.isTestOrderFlow,
    props.location?.state?.isReactSessionFlow,
  ]);

  const testOrderFlowNotification = () => {
    toast.info(
      <Text color="white" bold>
        Select a patient to create a test order for
      </Text>,
      {
        autoClose: false,
        closeOnClick: false,
        draggable: true,
        closeButton: false,
      }
    );
  };

  const reactFlowNotification = () => {
    toast.info(
      <Text color="white" bold>
        Select a patient to create a ReACT Health session for
      </Text>,
      {
        autoClose: false,
        closeOnClick: false,
        draggable: true,
        closeButton: false,
      }
    );
  };

  const updateSearchParams = (params) => {
    let selectedClinicz = [...params.clients];
    if (params.clients.length === 0) selectedClinicz = [clientId];
    // selectedClinicz = clinicOptions.map((clinic) => clinic.value);

    const updatedSearchParams = {
      ...params,
      page: 0,
      clients: selectedClinicz,
    };
    setSearchParams(updatedSearchParams);
    let url = new URL(window.location.href);
    let searchParams = url.searchParams;

    if (JSON.stringify(searchParams) !== JSON.stringify(updatedSearchParams)) {
      updatedSearchParams.limit &&
        searchParams.set(`p-limit`, updatedSearchParams.limit);
      updatedSearchParams.page &&
        searchParams.set(`p-page`, updatedSearchParams.page);
      searchParams.set(`p-search-term`, updatedSearchParams.search || '');
      updatedSearchParams.patientType &&
        searchParams.set(
          `p-patient-type`,
          updatedSearchParams.patientType || ''
        );
      const filteredClients = updatedSearchParams?.clients?.filter(
        (client) => client !== clientId
      );
      filteredClients?.length > 0
        ? searchParams.set(`p-clients`, filteredClients)
        : searchParams.delete(`p-clients`);
      window.history.replaceState({ path: url.toString() }, '', url.toString());
    }
  };

  const onSearchChange = (search) => {
    setSearchValue(search);
    updateSearchParams({ ...searchParams, search });
  };

  const fetchPatients = async (searchParams = {}) => {
    try {
      setSearching(true);
      const patientsResponseData =
        searchParams.patientType === 'SHARED'
          ? await fetchSharedPatients(searchParams)
          : await fetchNormalPatients(searchParams);

      if (patientsResponseData) {
        const { page, totalPages, limit } = patientsResponseData;
        if (page > 1)
          setPatients([...patients, ...patientsResponseData.results]);
        else setPatients(patientsResponseData.results);
        setPaginationInfo({ page, totalPages, limit });
      }
      setSearching(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchNormalPatients = async (searchParams = {}) => {
    return patientApi.getPatients(searchParams);
  };

  const fetchSharedPatients = async (searchParams = {}) => {
    return patientApi.getSharedPatients(searchParams);
  };

  const onRowClick = (e, patient) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.metaKey || e.ctrlKey) {
      if (props.location?.state?.isTestOrderFlow)
        return window.open(
          `/test-order/patient/${patient.id || patient.fhirId}/panel`,
          '_blank'
        );

      if (props.location?.state?.isReactSessionFlow)
        return window.open(`/react-health`, '_blank');

      if (patientType === 'SHARED')
        return window.open(
          `/patients/shared/${patient.id || patient.fhirId}`,
          '_blank'
        );

      return window.open(`/patients/${patient.id || patient.fhirId}`, '_blank');
    }

    if (props.location?.state?.isTestOrderFlow)
      return history.push({
        pathname: `/test-order/patient/${patient.id || patient.fhirId}/panel`,
        state: { patient },
      });

    if (props.location?.state?.isReactSessionFlow)
      return history.push({
        pathname: isExternal ? `/external/react-health` : `/react-health`,
        state: { patient },
      });

    if (patientType === 'SHARED')
      return history.push({
        pathname: `/patients/shared/${patient.id || patient.fhirId}`,
        state: { patient },
      });

    return history.push({
      pathname: `/patients/${patient.id || patient.fhirId}`,
      state: { patient },
    });
  };

  const onClickMoreData = async () => {
    const updatedSearchParams = {
      ...searchParams,
      page: paginationInfo.page + 1,
    };
    setSearchParams(updatedSearchParams);
  };

  const onUpload = (data) => {
    setFailedCreations([]);
    setIsSaved(false);
    setCsvData(data);
  };

  const onResetCsvData = () => {
    setCsvData([]);
    setFailedCreations([]);
    setIsSaving(false);
    setIsSaved(false);
  }

  const closeUpdateCSVModal = () => {
    toggleModal(false);
    onResetCsvData()
  }

  const uploadPatientData = async () => {
    try {
      setFailedCreations([]);
      setIsSaving(true);
      setIsSaved(false);
      let failedCreations = [];
      if (!uploadPatients) return;
      const patients = uploadPatients.map((pat) => {
        return {...pat, clientId};
      });
      const result = await bulkUpload(patients);
      const dataResult = result?.data || []
       failedCreations = patients.map( (pat, index) => {
        return {
          ...pat,
          status: dataResult[index]?.status || '',
          error: dataResult[index] ?.error ||''
        }
      })
      await fetchPatients({});
      setFailedCreations(failedCreations);
      setIsSaving(false);
      if (!failedCreations.length) {
        closeUpdateCSVModal()
      }
    } catch (error) {
      setIsSaving(false);
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsSaved(true);
    }
  };

  const hasFailedUploadRecord = failedPatientCreations.some( item => !!item.error)

  const bulkUpload = (patients) => {
    return patientApi.bulkUpload(patients);
  };

  const defaultCsvFormat = () => {
    setHasError(false);
    return csvData.map((data) => {
      let lHasError = false;
      if (!data?.first_name || !data?.last_name) {
        toast.error(<ErrorToast message="Patient name is required" />, {
          toastId: 'pat-name',
        });
        lHasError = true;
      }

      if (
        data?.birth_date &&
        !moment(data.birth_date, 'DD/MM/YYYY', true).isValid()
      ) {
        if (moment(data.birth_date, 'D/M/YYYY', true).isValid())
          data.birth_date = moment(data.birth_date, 'D/M/YYYY').format(
            'DD/MM/YYYY'
          );
        else {
          toast.error(
            <ErrorToast
              message={`Invalid date of birth: ${data.birth_date}`}
            />,
            {
              toastId: 'pat-dob',
            }
          );
          lHasError = true;
        }
      }
      if (data.gender !== 'Male' && data?.gender !== 'Female') {
        toast.error(<ErrorToast message={`Invalid gender: ${data.gender}`} />, {
          toastId: 'pat-gen',
        });
        lHasError = true;
      }

      if (!data.nric) {
        toast.error(
          <ErrorToast message="SSN/NRIC is required for all patients" />,
          {
            toastId: 'pat-ric',
          }
        );
        lHasError = true;
      }

      const formattedNationality = data?.nationality
        ? data?.nationality?.trim()
        : null;

      const nat = formattedNationality
        ? getPatientNationality(data?.nationality?.toLowerCase())
        : null;

      if (!nat) {
        lHasError = true;
        toast.error(
          <ErrorToast
            message={`Unsupported nationality: ${data.nationality}`}
          />,
          {
            toastId: 'pat-nat',
          }
        );
      }

      if (lHasError) {
        setHasError(lHasError);
        return false;
      }

      const formattedData = {
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        phoneNumber: data.phone,
        dateOfBirth: moment(data.birth_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        gender: data.gender.toLowerCase(),
        passNo: data.passport_number,
        ssn: data.nric,
        nationality: nat,
        employer: data.employer,
        action: data.action,
      };

      return Object.entries(formattedData).reduce(
        (a, [k, v]) => (v ? ((a[k] = v), a) : a),
        {}
      );
    });
  };

  const getPatientNationality = (nationality) => {
    let nat;
    for (let i = 0; i < nationalities.length; i++) {
      if (
        nationalities[i] &&
        (nationalities[i]?.value === nationality ||
          nationalities[i]?.key === nationality)
      ) {
        nat = nationalities[i];
        break;
      }
    }
    if (!nat) return null;
    return { text: nat?.value, value: nat?.key };
  };

  const downloadSampleFile = async () => {
    try {
      setIsDownloading(true);
      const resp = await axios({
        url: 'https://riverr-trustroot.s3.ap-southeast-1.amazonaws.com/public/patient_upload_sample.csv',
        method: 'GET',
        responseType: 'blob',
      });
      downloadFile(resp.data, 'patient_upload_sample.csv');
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      toast.error(<ErrorToast message="Error downloading template" />);
    }
  };

  const renderTableData = () => {
    return patients.map((patient, index) => {
      return (
        <Table.Row
          key={patient.id + index}
          className="table-row"
          onClick={(e) => onRowClick(e, patient)}
        >
          <Table.Cell>
            <Text size="small" bold uppercase>
              {patient.fullName}
            </Text>
          </Table.Cell>
          {/* <Table.Cell>
            <Text size="small" bold uppercase>
              {patient.lastName}
            </Text>
          </Table.Cell> */}
          <Table.Cell>
            <Text size="small" uppercase>
              {patient.gender}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">
              {!!patient.dateOfBirth
                ? moment(patient.dateOfBirth, 'YYYY-MM-DD').format('DD-MM-YYYY')
                : ''}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{patient?.phoneNumber}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">
              {patient.identifier?.passportNumber || patient.passNo}
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{patient?.employer || ''}</Text>
          </Table.Cell>
        </Table.Row>
      );
    });
  };
  return (
    <div className="patients">
      <div className="patients__header">
        <Text
          id="patients.patients_"
          type="pageHeader"
          style={{ marginBottom: 0 }}
        >
          Patients
        </Text>

        <div className="patients__header__actions">
          <Dropdown
            className="button icon patients__dropdown"
            selectOnBlur={false}
            floating
            trigger={
              <Text id="patients.add_patients" size="tiny" color="white">
                Add Patients
              </Text>
            }
          >
            <Dropdown.Menu className="right types-overview__header-action-items">
              <Dropdown.Item
                onClick={() => history.push('/patients/create')}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <SIcon name="plus" />{' '}
                <Text id="patients.add_patient" size="small">
                  Add patient
                </Text>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => toggleModal(true)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <SIcon name="upload" />{' '}
                <Text id="patients.csv_upload" size="small">
                  CSV upload
                </Text>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* TODO: Move the modal to own component */}
          <Modal
            className="patients__modal"
            closeIcon
            size="small"
            open={modalToggled}
            onClose={closeUpdateCSVModal}
          >
            <Modal.Header>
              <Text id="patients.add_patients" dark>
                Add patients
              </Text>
            </Modal.Header>
            <Modal.Content className="patients__modal__content">
              <div className="patients__modal__content__header">
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Text id="patients.upload_csv_patient" size="small" bold>
                    Upload a CSV file with patient details
                  </Text>
                  <div style={{display: "flex", alignItems:"center", marginTop: "10px"}}>
                    <Info
                      color="blue"
                      style={{marginRight: '10px' }}
                      fontSize="1.5em"
                    />
                    <Text id="patients.date_format" size="tiny" color="lightGrey">
                      Date format: DD/MM/YYYY
                    </Text>
                  </div>
                </div>

                <Button
                  size="small"
                  color="green"
                  inverted
                  onClick={downloadSampleFile}
                  isLoading={isDownloading}
                  fontSize="tiny"
                  style={{ width: '220px', height: '33px' }}
                >
                  <>
                    <SIcon name="download" className="mr-3" />
                    <Text id="patients.download_template" color="green">
                      Download template
                    </Text>
                  </>
                </Button>
              </div>
              <div className="patients__modal__content__dropzone">
                <CsvReader
                  onUpload={onUpload}
                  onRemoveFile={onResetCsvData}
                />
              </div>
              {!!failedPatientCreations.length && (
                <FailedPatientCreations data={failedPatientCreations} />
              )}
              <Button
                size="fullWidth"
                color="blue"
                disabled={uploadPatients.length === 0 || hasError}
                onClick={ !isSaved && !hasFailedUploadRecord  || isSaved && hasFailedUploadRecord ? uploadPatientData : closeUpdateCSVModal  }
                isLoading={isSaving}
              >
                {!isSaved && !hasFailedUploadRecord  || isSaved && hasFailedUploadRecord ? <>
                  <SIcon name="upload"/>{' '}
                  <Text id="patients.upload_data" color="white">
                    Upload data
                  </Text>
                </> : <Text  color="white">
                  Continue
                </Text>
                }
              </Button>
            </Modal.Content>
          </Modal>
          <div className="results-table__custom-dropdown mr-3">
            <Button
              color="lightGrey"
              onClick={() => setShowFilters(true)}
              inverted
            >
              <Text id="patients.filters">Filters</Text>
              <SIcon
                style={{ position: 'absolute', right: '3px' }}
                name="filter"
                color="white"
              />
            </Button>
            <div
              className={cx('results-table__custom-dropdown__portal', {
                'results-table__custom-dropdown__portal--open': isShowFilters,
              })}
              ref={filterDropdownRef}
            >
              <>
                {clinicOptions?.length > 0 && (
                  <div className="mt-3 mb-5" style={{ position: 'relative' }}>
                    <Text
                      id="patients.clinics"
                      size="tinniest"
                      color="lightGrey"
                      className="results-table__search__date-tag"
                    >
                      Clinics
                    </Text>
                    <Select
                      search
                      multiple
                      border
                      icon={false}
                      placeholder={
                        <Text id="patients.all_clinics">All clinics</Text>
                      }
                      options={clinicOptions}
                      value={searchParams.clients}
                      onChange={(e, { value }) => {
                        setSelectedClinics(value);
                        updateSearchParams({ ...searchParams, clients: value });
                      }}
                    />
                  </div>
                )}
                <div className="mt-3" style={{ position: 'relative' }}>
                  <Text
                    id="patients.patient_type"
                    size="tinniest"
                    color="lightGrey"
                    className="results-table__search__date-tag"
                  >
                    Patient type
                  </Text>
                  <Select
                    border
                    className="patients__selector"
                    size="fullWidth"
                    value={searchParams.patientType}
                    onChange={(e, { value }) => {
                      setPatientType(value);
                      updateSearchParams({
                        ...searchParams,
                        patientType: value,
                      });
                    }}
                    options={[
                      {
                        text: (
                          <Text id="patients.patient_type.patients">
                            Patients
                          </Text>
                        ),
                        value: 'PATIENTS',
                      },
                      {
                        text: (
                          <Text id="patients.patient_type.shared_patients">
                            Shared patients
                          </Text>
                        ),
                        value: 'SHARED',
                      },
                    ]}
                  />
                </div>
              </>
            </div>
          </div>
          <Search
            isLoading={isSearching}
            size="large"
            value={searchParams.search}
            dropdown={false}
            onSearchChange={(e, { value }) => onSearchChange(value)}
            isFilter={true}
            placeholder="Search by name, passport, phone, employer"
          />
        </div>
      </div>
      <div className="patients__table-wrapper">
        <Table selectable unstackable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Text size="tiny" id="patients.name" dark>
                  Name
                </Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text size="tiny" id="patients.gender" dark>
                  Gender
                </Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text size="tiny" id="patients.birth_date" dark>
                  Date of birth
                </Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text size="tiny" id="patients.phone" dark>
                  Phone
                </Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text size="tiny" id="patients.passport_no" dark>
                  Passport No.
                </Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text size="tiny" dark>
                  Employer
                </Text>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{!!patients?.length && renderTableData()}</Table.Body>
        </Table>
      </div>
      {paginationInfo.page &&
        paginationInfo.page < paginationInfo.totalPages && (
          <Button
            icon
            onClick={() => onClickMoreData()}
            color="blue"
            style={{ width: '200px' }}
            isLoading={isSearching}
            disabled={isSearching}
          >
            <Text
              id="patients.load_more_patients"
              color="white"
              size="small"
              bold
            >
              Load more patients
            </Text>
          </Button>
        )}
    </div>
  );
};

export default withRouter(PatientsFhir);
