// import React from 'react';

// const SvgBarChart = props => (
//   <svg width="1em" height="1em" viewBox="0 0 29 26" {...props}>
//     <g
//       transform="translate(1)"
//       stroke={props.color}
//       strokeWidth={3}
//       fill="none"
//       fillRule="evenodd"
//     >
//       <rect x={1.5} y={7.5} width={8} height={17} rx={2} />
//       <rect x={9.5} y={1.5} width={8} height={23} rx={2} />
//       <rect x={17.5} y={11.5} width={8} height={13} rx={2} />
//     </g>
//   </svg>
// );

// SvgBarChart.defaultProps = {
//   color: '#1b1b1b',
// };

// export default SvgBarChart;

import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={27}
      height={27}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M20.158 16.204c.787 0 1.447.65 1.327 1.427-.706 4.573-4.621 7.969-9.343 7.969-5.224 0-9.459-4.234-9.459-9.457 0-4.304 3.27-8.312 6.95-9.218.79-.196 1.601.36 1.601 1.175 0 5.517.186 6.944 1.233 7.72 1.048.776 2.28.384 7.69.384z"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M25.308 11.61c.063-3.544-4.29-9.257-9.596-9.159a.792.792 0 00-.762.763c-.133 2.914.047 6.69.148 8.402.03.533.45.952.981.983 1.76.1 5.674.238 8.546-.197a.81.81 0 00.683-.792z"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
