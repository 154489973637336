import gql from 'graphql-tag';

export const facilities = gql`
  query facilities($clientId: ID!) {
    facilities(clientId: $clientId) {
      id
      name
      address
      city
      zip
      country
      email
      phoneNumber
      geoLocation
      isDefault
    }
  }
`;
