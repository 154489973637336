import React from 'react';

const SvgFilter = props => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M5.94 12.744l6.507 5.847a5 5 0 011.657 3.719V30a1 1 0 001 1h1.792a1 1 0 001-1v-7.845a5 5 0 011.469-3.54l5.922-5.907A1 1 0 0024.581 11H6.61a1 1 0 00-.669 1.744z"
        stroke="#1b1b1b"
        strokeWidth={3}
      />
      <circle stroke="#1b1b1b" strokeWidth={3} cx={12.5} cy={5.5} r={1.5} />
      <circle fill="#1b1b1b" cx={19.5} cy={2.5} r={1.5} />
    </g>
  </svg>
);

export default SvgFilter;
