import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router';
import cx from 'classnames';
import { Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import moment from 'moment';

import { Text, ErrorToast, SuccessToast } from 'components';

import PatientSignup from '../patientSignup/PatientSignup';
import EmergencyContactForm from '../emergencyContactForm/EmergencyContactForm';
import EmergencyContactOverview from '../emergencyContactOverview/EmergencyContactOverview';

import nationalities from 'utils/nationalities';
import ethnicities from 'utils/ethnicities';
import riverrLogo from 'assets/images/riverr-logo-long.png';

import history from '../../../history';

import './patientSignupWrapper.scss';
import medicalProfileApi from 'api/patientOverview/medicalProfileApi';

const sections = {
  '/p/create-account': { header: 'Account Registration', fill: 1 },
  '/p/create-account/emergency': {
    header: 'Emergency Contact',
    fill: 2,
    backRoute: '/p/create-account',
  },
  '/p/create-account/summary': {
    header: 'Emergency Card',
    fill: 3,
    backRoute: '/p/create-account/emergency',
  },
};

const PatientSignupWrapper = () => {
  const [details, updateDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setLocal();
  }, []);

  useEffect(() => {
    localStorage.setItem('RIV-REG-F', JSON.stringify(details));
  }, [details]);

  const path = history.location.pathname;

  const setLocal = async () => {
    const localDetails = await localStorage.getItem('RIV-REG-F');
    if (localDetails) updateDetails(JSON.parse(localDetails));
    // little hack, remove when possible
    setTimeout(() => setIsLoading(false), 100);
  };

  const createUser = async () => {
    // shareDetails = if they chose to accept the emergency contact sharing
    try {
      const data = details;
      data.nationality = nationalities.find(
        (nat) => nat.value === data.nationality
      );
      data.smoke = data.smoker;
      data.country = nationalities.find((nat) => nat.value === data.country);
      data.ethnicity = ethnicities.find((eth) => eth.value === data.ethnicity);
      data.dateOfBirth = moment(data.dateOfBirth, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
      );
      await medicalProfileApi.signupPatient(details);
      toast.success(<SuccessToast message="Account created!" />);
      history.push(`/p/success/${data.email}`);
    } catch (error) {
      console.log(error);
      toast.error(<ErrorToast error={error} />);
    }
  };

  return (
    <div className="p-signup-w">
      <div className="p-signup-w__content">
        {/* <img className="p-signup-w__logo" src={riverrLogo} alt="logo" /> */}
        <div className="p-signup-w__header">
          {sections[path]?.backRoute && (
            <Icon
              size="large"
              name="arrow left"
              style={{ cursor: 'pointer' }}
              className="mr-3"
              onClick={() => history.push(sections[path]?.backRoute)}
            />
          )}
          <Text size="bigger" dark>
            {sections[path]?.header}
          </Text>
        </div>
        <div className="psw-bread-c">
          <div className="psw-bread-c__count">
            <Text bold size="tiny">
              Step {sections[path]?.fill} of {Object.keys(sections).length}
            </Text>
          </div>
          <div className="psw-bread-c__sections">
            <div
              className={cx('psw-bread-c__section', {
                'psw-bread-c__section--active': sections[path]?.fill > 0,
              })}
            ></div>
            <div
              className={cx('psw-bread-c__section', {
                'psw-bread-c__section--active': sections[path]?.fill > 1,
              })}
            ></div>
            <div
              className={cx('psw-bread-c__section', {
                'psw-bread-c__section--active': sections[path]?.fill > 2,
              })}
            ></div>
          </div>
        </div>
        {!isLoading && (
          <div>
            <Switch>
              <Route
                exact
                path="/p/create-account"
                render={() => (
                  <PatientSignup
                    details={details}
                    updateDetails={updateDetails}
                  />
                )}
              />
              <Route
                path="/p/create-account/emergency"
                render={() => (
                  <EmergencyContactForm
                    details={details}
                    updateDetails={updateDetails}
                  />
                )}
              />
              <Route
                path="/p/create-account/summary"
                render={() => (
                  <EmergencyContactOverview
                    createUser={createUser}
                    details={details}
                    updateDetails={updateDetails}
                  />
                )}
              />
            </Switch>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientSignupWrapper;
