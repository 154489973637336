import { apolloClient } from '../apollo/apollo';
import { getTinyUrl } from './graphql/queries/tinyUrl';

export default {
  async getTinyUrl(tinyUrl) {
    return apolloClient.query({
      query: getTinyUrl,
      variables: {
        tinyUrl,
      },
    });
  },
};
