import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Input, Select, Text, Button } from 'components';
import { useCustomForm } from 'hooks';
import ethnicities from 'utils/ethnicities';

import { schema } from '../schema';

import '../reactHealthQuestions.scss';

const ReactHealthQuestions = ({ riskInfo, onSubmit, isSaving, location }) => {
  const userInfo = useSelector((state) => state.general.user);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [details, setDetails] = useState({
    lipidMeds: null,
    hypertensiveMeds: null,
    historyOfCorticosteroidUse: null,
    historyOfStatinUse: null,
    historyOf2ndGenerationAntipsychoticUse: null,

    familyHeartAttack: false,
    diabetesFamilyHistory: false,
    historyTreatedHypertension: false,
    historyCardiovascularDisease: false,
    historyGestationalDiabetes: false,
    historyPolycysticOvarySyndrome: false,
    historyLearningDifficulties: false,
    historySchizophreniaBipolar: false,
    diabetic: false,
    parentalHyperTension: 'none'
  });

  useEffect(() => {
    if(!isInitialRender) localStorage.setItem('RIV-SCR-S', JSON.stringify(details));
    setIsInitialRender(false);
  }, [details]);

  const getInitialDetails = async () => {
    const localDetails = await localStorage.getItem('RIV-SCR-S');
    const initialForm = getInitialFormData();

    if (localDetails) {
      const res = JSON.parse(localDetails);
      setDetails({ ...initialForm, ...res });
      return { ...initialForm, ...res };
    } else {
      setDetails(initialForm)
      return initialForm;
    }
  };

  const getInitialFormData = () => {
    const searchParams = new URLSearchParams(location.search);

    return {
      gender: details?.gender || userInfo?.metadata?.gender || searchParams.get('gender'),
      // height: details?.height || riskInfo?.bmi?.height?.value || searchParams.get('height'),
      // weight: details?.weight || riskInfo?.bmi?.weight?.value || searchParams.get('weight'),
      ethnicity: details?.ethnicity || userInfo?.metadata?.ethnicity?.value || searchParams.get('ethn'),
      smoker: details?.smoker || riskInfo.smoke,
      diabetic: Boolean(details?.diabetic) || riskInfo.isDiabetic,
      alcoholPerWeek: details?.alcoholPerWeek || riskInfo.alcoholicDrinkPerWeek,
      lipidMeds:
        details?.lipidMeds !== undefined
          ? details?.lipidMeds
          : riskInfo.isLipidLoweringMedication,
      hypertensiveMeds:
        details?.hypertensiveMeds !== undefined
          ? details?.hypertensiveMeds
          : riskInfo.isAntiHypertensiveMedication,
      parentalHyperTension:
        details?.parentalHyperTension !== undefined
          ? details?.parentalHyperTension
          : riskInfo.parentalHyperTension,
      familyHeartAttack:
        details?.familyHeartAttack !== undefined
          ? details?.familyHeartAttack
          : riskInfo.isFamilyHistoryOfHeartAttack,
      diabetesFamilyHistory:
        details?.diabetesFamilyHistory !== undefined
          ? details?.diabetesFamilyHistory
          : riskInfo.isFamilyHistoryOfDiabetes,
      historyTreatedHypertension:
        details?.historyTreatedHypertension !== undefined
          ? details?.historyTreatedHypertension
          : riskInfo.isHistoryOfTreatedHypertension,
      historyCardiovascularDisease:
        details?.historyCardiovascularDisease !== undefined
          ? details?.historyCardiovascularDisease
          : riskInfo.isHistoryOfCardiovascularDisease,
      historyGestationalDiabetes:
        details?.historyGestationalDiabetes !== undefined
          ? details?.historyGestationalDiabetes
          : riskInfo.isHistoryOfGestationalDiabetes,
      historyPolycysticOvarySyndrome:
        details?.historyPolycysticOvarySyndrome !== undefined
          ? details?.historyPolycysticOvarySyndrome
          : riskInfo.isHistoryOfPolycysticOvarySyndrome,
      historyLearningDifficulties:
        details?.historyLearningDifficulties !== undefined
          ? details?.historyLearningDifficulties
          : riskInfo.isHistoryOfLearningDifficulties,
      historySchizophreniaBipolar:
        details?.historySchizophreniaBipolar !== undefined
          ? details?.historySchizophreniaBipolar
          : riskInfo.isHistoryOfSchizophreniaOrBipolarAffectiveDisorder,
      historyOf2ndGenerationAntipsychoticUse:
        details?.historyOf2ndGenerationAntipsychoticUse !== undefined
          ? details?.historyOf2ndGenerationAntipsychoticUse
          : riskInfo.isHistoryOf2ndGenerationAntipsychoticUse,
      historyOfCorticosteroidUse:
        details?.historyOfCorticosteroidUse !== undefined
          ? details?.historyOfCorticosteroidUse
          : riskInfo.isHistoryOfCorticosteroidUse,
      historyOfStatinUse:
        details?.historyOfStatinUse !== undefined
          ? details?.historyOfStatinUse
          : riskInfo.isHistoryOfStatinUse,
    };
  };

  const {
    Controller,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useCustomForm(schema, details);

  useEffect(async () => {
    const initialData = await getInitialDetails();
    reset(initialData);
    setDetails(initialData);
  }, []);

  const onValChange = (field, value, callback) => {
    setDetails({ ...details, [field]: value });
    callback(value);
  };

  return (
      <div className="r-health-questions__form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="r-health-questions__form__grid-wrapper">
            <Text dark size="bigish">
              Personal Details
            </Text>
            <div className="r-health-questions__form__grid">
              <Controller
                name="gender"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    border
                    label="Gender"
                    placeholder="Female"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('gender', value, onChange)
                    }
                    options={[
                      { text: 'Female', value: 'female' },
                      { text: 'Male', value: 'male' },
                      { text: 'Other', value: 'other' },
                      { text: 'Unknown', value: 'unknown' },
                    ]}
                    search
                    size="fullWidth"
                    errorMessage=""
                    selection
                    className="u-patient-form__form__input"
                    invalid={errors?.gender}
                  />
                )}
              />
              <Controller
                name="ethnicity"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Race"
                    border
                    placeholder="Asian"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('ethnicity', value, onChange)
                    }
                    options={ethnicities}
                    search
                    errorMessage=""
                    size="fullWidth"
                    selection
                    className="u-patient-form__form__input"
                    invalid={errors?.ethnicity}
                  />
                )}
              />
              {/* <Controller
                name="height"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="patient-form__form__input"
                    label="Height (cm)"
                    placeholder="182"
                    errorMessage=""
                    size="fullWidth"
                    value={value}
                    invalid={errors?.height}
                    onChange={(e) =>
                      onValChange('height', e.target.value, onChange)
                    }
                  />
                )}
              />
              <Controller
                name="weight"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="patient-form__form__input"
                    label="Weight (kg)"
                    placeholder="84"
                    errorMessage=""
                    size="fullWidth"
                    value={value}
                    invalid={errors?.weight}
                    onChange={(e) =>
                      onValChange('weight', e.target.value, onChange)
                    }
                  />
                )}
              /> */}
            </div>
          </div>
          <div className="r-health-questions__form__grid-wrapper">
            <Text dark size="bigish">
              Habits
            </Text>
            <div className="r-health-questions__form__grid">
              <Controller
                name="smoker"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Smoker"
                    border
                    size="fullWidth"
                    placeholder="Non-smoker"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('smoker', value, onChange)
                    }
                    options={[
                      { text: 'Non-smoker', value: 'Non' },
                      { text: 'Ex-smoker', value: 'Ex' },
                      {
                        text: 'Less than 10 cigarettes per day',
                        value: 'Light',
                      },
                      { text: '10 - 19 cigarettes per day', value: 'Moderate' },
                      { text: '> 20 cigarettes per day', value: 'Heavy' },
                    ]}
                    invalid={errors?.smoker}
                    showErrorMessage={false}
                  />
                )}
              />
              <Controller
                name="alcoholPerWeek"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    border
                    label="Alcoholic drinks per week (avg)"
                    placeholder="Non-drinker"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('alcoholPerWeek', value, onChange)
                    }
                    options={[
                      { text: 'Non-drinker', value: 0 },
                      { text: '1 - 5 alcoholic drinks per week', value: 5 },
                      { text: '6 - 15 alcoholic drinks per week', value: 10 },
                      { text: '> 15 alcoholic drinks per week', value: 15 },
                    ]}
                    size="fullWidth"
                    showErrorMessage={false}
                    invalid={errors?.alcoholPerWeek}
                  />
                )}
              />
            </div>
          </div>
          <div className="r-health-questions__form__grid-wrapper r-health-questions__form__grid-wrapper--optional">
            <div className="r-health-questions__form__grid-wrapper__header">
              <Text dark size="bigish">
                Medication
              </Text>
              <Text
                id="add_patient.optional"
                className="ml-3"
                size="tinniest"
                color="blue"
              >
                Optional
              </Text>
            </div>
            <div className="r-health-questions__form__grid">
              <Controller
                name="lipidMeds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="History of cholesterol regulating medications (any types) use"
                    border
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('lipidMeds', value, onChange)
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    search
                    size="fullWidth"
                    selection
                    className="patient-form__form__input"
                    invalid={errors?.lipidMeds}
                    showErrorMessage={false}
                  />
                )}
              />
              <Controller
                name="hypertensiveMeds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="History of blood pressure regulating medications use"
                    border
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('hypertensiveMeds', value, onChange)
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    search
                    size="fullWidth"
                    selection
                    className="patient-form__form__input"
                    invalid={errors?.hypertensiveMeds}
                    showErrorMessage={false}
                  />
                )}
              />
              <Controller
                name="historyOfCorticosteroidUse"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="History of anti-inflammatory medication use"
                    border
                    search
                    size="fullWidth"
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('historyOfCorticosteroidUse', value, onChange)
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    invalid={errors?.historyOfCorticosteroidUse}
                    showErrorMessage={false}
                  />
                )}
              />
              <Controller
                name="historyOfStatinUse"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="History of cholesterol regulating medications (statins) use"
                    border
                    search
                    size="fullWidth"
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('historyOfStatinUse', value, onChange)
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    invalid={errors?.historyOfStatinUse}
                    showErrorMessage={false}
                  />
                )}
              />
              <Controller
                name="historyOf2ndGenerationAntipsychoticUse"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="History of use of 2nd generation antipsychotics"
                    border
                    search
                    size="fullWidth"
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange(
                        'historyOf2ndGenerationAntipsychoticUse',
                        value,
                        onChange
                      )
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    invalid={errors?.historyOf2ndGenerationAntipsychoticUse}
                    showErrorMessage={false}
                  />
                )}
              />
            </div>
          </div>
          <div className="r-health-questions__form__grid-wrapper">
            <Text dark size="bigish">
              Medical History
            </Text>
            <div className="r-health-questions__form__grid">
              <Controller
                name="diabetic"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Are you diagnosed with Diabetes?"
                    border
                    search
                    size="fullWidth"
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('diabetic', value, onChange)
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    invalid={errors?.diabetic}
                    showErrorMessage={false}
                  />
                )}
              />
              <Controller
                name="historyCardiovascularDisease"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="History of Cardiovascular Disease"
                    border
                    search
                    size="fullWidth"
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange(
                        'historyCardiovascularDisease',
                        value,
                        onChange
                      )
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    invalid={errors?.historyCardiovascularDisease}
                    showErrorMessage={false}
                  />
                )}
              />
              <Controller
                name="historyTreatedHypertension"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="History of treated Hypertension"
                    border
                    search
                    size="fullWidth"
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('historyTreatedHypertension', value, onChange)
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    invalid={errors?.historyTreatedHypertension}
                    showErrorMessage={false}
                  />
                )}
              />

              <Controller
                name="historyGestationalDiabetes"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="History of diabetes during pregnancy"
                    border
                    search
                    size="fullWidth"
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('historyGestationalDiabetes', value, onChange)
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    invalid={errors?.historyCardiovascularDisease}
                    showErrorMessage={false}
                  />
                )}
              />
              <Controller
                name="historyPolycysticOvarySyndrome"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="History of Polycystic Ovary Syndrome"
                    border
                    search
                    size="fullWidth"
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange(
                        'historyPolycysticOvarySyndrome',
                        value,
                        onChange
                      )
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    invalid={errors?.historyPolycysticOvarySyndrome}
                    showErrorMessage={false}
                  />
                )}
              />
              <Controller
                name="historySchizophreniaBipolar"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="History of Schizophrenia or Bipolar Affective Disorder"
                    border
                    search
                    size="fullWidth"
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange(
                        'historySchizophreniaBipolar',
                        value,
                        onChange
                      )
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    invalid={errors?.historySchizophreniaBipolar}
                    showErrorMessage={false}
                  />
                )}
              />
              <Controller
                name="historyLearningDifficulties"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="History of learning difficulties"
                    border
                    search
                    size="fullWidth"
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange(
                        'historyLearningDifficulties',
                        value,
                        onChange
                      )
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    invalid={errors?.historyLearningDifficulties}
                    showErrorMessage={false}
                  />
                )}
              />
            </div>
          </div>
          <div className="r-health-questions__form__grid-wrapper">
            <Text dark size="bigish">
              Family history
            </Text>
            <div className="r-health-questions__form__grid">
              <Controller
                name="parentalHyperTension"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    border
                    label="Parental Hypertension"
                    placeholder="None / Single / Both"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('parentalHyperTension', value, onChange)
                    }
                    options={[
                      { text: 'None', value: 'none' },
                      { text: 'Single', value: 'single' },
                      { text: 'Both', value: 'both' },
                    ]}
                    search
                    size="fullWidth"
                    selection
                    className="patient-form__form__input"
                    invalid={errors?.parentalHyperTension}
                    showErrorMessage={false}
                  />
                )}
              />
              <Controller
                name="familyHeartAttack"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Family history of Heart Attack"
                    border
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('familyHeartAttack', value, onChange)
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    search
                    size="fullWidth"
                    selection
                    className="patient-form__form__input"
                    invalid={errors?.familyHeartAttack}
                    showErrorMessage={false}
                  />
                )}
              />
              <Controller
                name="diabetesFamilyHistory"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Family history of Diabetes"
                    border
                    search
                    size="fullWidth"
                    placeholder="Yes / No"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('diabetesFamilyHistory', value, onChange)
                    }
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                    invalid={errors?.diabetesFamilyHistory}
                    showErrorMessage={false}
                  />
                )}
              />
            </div>
          </div>
          <Button
            type="submit"
            className="mt-5 patient-form__button"
            size="fullWidth"
            color="blue"
            isLoading={isSaving}
          >
            Submit
          </Button>
        </form>
    </div>
  );
};

export default withRouter(ReactHealthQuestions);
