import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Icon } from 'semantic-ui-react';

import { ErrorToast, Input, Select, Text, Cleave, Button } from 'components';

import { useCustomForm } from 'hooks';
import { schema } from './schema';

import history from '../../../history';

import './patientSignupV1.scss';
import moment from 'moment';

const PatientSignupV1 = ({
  details,
  updateDetails,
  createUser,
  createUserLoading,
  location,
}) => {
  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
  } = useCustomForm(schema, {
    // title: details?.title || '',
    gender: details?.gender || '',
    firstName: details?.firstName || '',
    lastName: details.lastName || '',
    dateOfBirth: details?.dateOfBirth
      ? moment(details?.dateOfBirth).format('DD-MM-YYYY')
      : '',
    email: details?.email || '',
    ssn: details?.ssn || '',
  });

  const [disableEmail, setDisableEmail] = useState(false);

  useEffect(() => {
    console.log(errors, 'error');
    if (Object.keys(errors).length > 0)
      toast.error(<ErrorToast message="Please fill out all required fields" />);
  }, [errors]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    console.log('params', params.get('email'), params.get('type'));

    if (params.get('email')) {
      updateDetails({ ...details, email: params.get('email') });
    }
    if (params.get('external')) {
      setDisableEmail(true);
    }
  }, []);

  const onRegister = async () => {
    await createUser();
  };

  const onValChange = (field, value, callback) => {
    updateDetails({ ...details, [field]: value });
    callback(value);
  };

  return (
    <div className="u-patient-form-v1">
      <div className="u-patient-form-v1__header">
        <Icon
          size="large"
          name="arrow left"
          style={{ cursor: 'pointer' }}
          className="mr-3"
          onClick={() => history.push('/')}
        />
        <Text size="bigger" dark>
          Riverr for Patients
        </Text>
      </div>
      <div className="u-patient-form-v1__welcome">
        <Text size="big" bold>
          Welcome to Riverr!
        </Text>
        <Text className="mt-1">
          Get started on your patient-centric medical experience.
        </Text>
      </div>
      <div className="u-patient-form-v1__form">
        <form onSubmit={handleSubmit(onRegister)}>
          <div className="u-patient-form-v1__form__grid-wrapper">
            <div className="u-patient-form-v1__form__grid">
              {/* <Controller
                name="title"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    className="u-patient-form-v1__form__input"
                    label="Title"
                    errorMessage=""
                    border
                    search
                    size="fullWidth"
                    placeholder="Mr."
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('title', value, onChange)
                    }
                    options={[
                      { text: 'Mr.', value: 'Mr.' },
                      { text: 'Mrs.', value: 'Mrs.' },
                      { text: 'Ms.', value: 'Ms' },
                    ]}
                    invalid={errors?.title}
                    showErrorMessage={false}
                  />
                )}
              /> */}
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="u-patient-form-v1__form__input"
                    label="First Name"
                    errorMessage=""
                    placeholder="Jane"
                    size="fullWidth"
                    value={value}
                    invalid={errors?.firstName}
                    onChange={(e) =>
                      onValChange('firstName', e.target.value, onChange)
                    }
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="u-patient-form-v1__form__input"
                    label="Last Name"
                    errorMessage=""
                    placeholder="Doe"
                    size="fullWidth"
                    value={value}
                    invalid={errors?.lastName}
                    onChange={(e) =>
                      onValChange('lastName', e.target.value, onChange)
                    }
                  />
                )}
              />
              <Controller
                name="gender"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    border
                    label="Gender"
                    placeholder="Female"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('gender', value, onChange)
                    }
                    options={[
                      { text: 'Female', value: 'female' },
                      { text: 'Male', value: 'male' },
                      { text: 'Other', value: 'other' },
                      { text: 'Unknown', value: 'unknown' },
                    ]}
                    search
                    size="fullWidth"
                    errorMessage=""
                    selection
                    className="u-patient-form__form__input"
                    invalid={errors?.gender}
                  />
                )}
              />
              <Controller
                name="dateOfBirth"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Cleave
                    label="Birth Date"
                    placeholder="DD-MM-YYYY"
                    options={{
                      date: true,
                      datePattern: ['d', 'm', 'Y'],
                      delimiter: '-',
                    }}
                    onChange={(e) =>
                      onValChange('dateOfBirth', e.target.value, onChange)
                    }
                    errorMessage=""
                    value={value}
                    size="fullWidth"
                    invalid={errors?.dateOfBirth}
                  />
                )}
              />
              <Controller
                name="ssn"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    label="National ID No."
                    optional
                    border
                    placeholder="S123456789"
                    value={value}
                    onChange={(e) =>
                      onValChange('ssn', e.target.value, onChange)
                    }
                    size="fullWidth"
                    errorMessage="This field requires 7 - 22 characters"
                    className="u-patient-form-v1__form__input"
                    invalid={errors?.ssn}
                  />
                )}
              />
            </div>
          </div>
          <div className="u-patient-form-v1__form__grid-wrapper">
            <div className="u-patient-form-v1__form__grid">
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="u-patient-form-v1__form__input"
                    label="Email Address"
                    placeholder="john-doe@riverr.ai"
                    size="fullWidth"
                    disabled={disableEmail}
                    onChange={(e) =>
                      onValChange('email', e.target.value, onChange)
                    }
                    errorMessage="This field requires 7 - 22 characters"
                    value={value}
                    invalid={errors?.email}
                  />
                )}
              />
            </div>
          </div>
          <Button
            type="submit"
            className="mt-5 patient-form__button"
            size="fullWidth"
            color="blue"
            isLoading={createUserLoading}
          >
            Register
          </Button>
        </form>
      </div>
    </div>
  );
};

export default withRouter(PatientSignupV1);
