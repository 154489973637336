import React, { useState } from 'react';
import { Select, Input, Button, ErrorToast, TextArea } from 'components';
import { Icon as SIcon, Table } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import './requestForm.scss';

const options = [
  { value: { type: "Vacutainers", name: "6ml Plain Tube (red top)"}, text: "(Vacutainers) 6ml Plain Tube (red top)"},
  { value: { type: "Vacutainers", name: "8ml GEL SSTI Tube (gold top)"}, text: "(Vacutainers) 8ml GEL SSTI Tube (gold top)"},
  { value: { type: "Vacutainers", name: "3ml EDTA Tube (lavender top)"}, text: "(Vacutainers) 3ml EDTA Tube (lavender top)"},
  { value: { type: "Vacutainers", name: "2ml Fluoride Tube (grey top)"}, text: "(Vacutainers) 2ml Fluoride Tube (grey top)"},
  { value: { type: "Vacutainers", name: "2ml Sodium Citrate Tube (blue top)"}, text: "(Vacutainers) 2ml Sodium Citrate Tube (blue top)"},
  { value: { type: "Vacutainers", name: "21G x 1.5 Vacutainer Needles (green cap)"}, text: "(Vacutainers) 21G x 1.5 Vacutainer Needles (green cap)"},
  { value: { type: "Vacutainers", name: "22G x 1.5 Vacutainer Needles (black cap)"}, text: "(Vacutainers) 22G x 1.5 Vacutainer Needles (black cap)"},
  { value: { type: "Vacutainers", name: "23G needles"}, text: "(Vacutainers) 23G needles"},
  { value: { type: "Vacutainers", name: "Vacutainer Holders"}, text: "(Vacutainers) Vacutainer Holders"},
  { value: { type: "Syringes", name: "5ml Syringes with Needles"}, text: "(Syringes) 5ml Syringes with Needles" },
  { value: { type: "Syringes", name: "10ml Syringes with Needles"}, text: "(Syringes) 10ml Syringes with Needles" },
  { value: { type: "Miscellaneous", name: "60ml Urine Container"}, text: "(Misc) 60ml Urine Container" },
  { value: { type: "Miscellaneous", name: "30ml Stool Container"}, text: "(Misc) 30ml Stool Container" },
  { value: { type: "Miscellaneous", name: "5L 24-Hr Urine Containers"}, text: "(Misc) 5L 24-Hr Urine Containers" },
  { value: { type: "Miscellaneous", name: "Eurofins Specimen Ziplock Bag"}, text: "(Misc) Eurofins Specimen Ziplock Bag" },
  { value: { type: "Miscellaneous", name: "Thinprep Test Kit"}, text: "(Misc) Thinprep Test Kit" },
  { value: { type: "Miscellaneous", name: "Pap Smear Test Kit"}, text: "(Misc) Pap Smear Test Kit" },
  { value: { type: "Miscellaneous", name: "HPV Cervi-Collect Kit"}, text: "(Misc) HPV Cervi-Collect Kit" },
  { value: { type: "Miscellaneous", name: "STI Multi-Collect Kit"}, text: "(Misc) STI Multi-Collect Kit" },
  { value: { type: "Miscellaneous", name: "Urea Breath Test Kit"}, text: "(Misc) Urea Breath Test Kit" },
  { value: { type: "Miscellaneous", name: "Quantiferon - TB GOLD PLUS"}, text: "(Misc) Quantiferon - TB GOLD PLUS" },
  { value: { type: "Miscellaneous", name: "Royal Blue Tube"}, text: "(Misc) Royal Blue Tube" },
  { value: { type: "Miscellaneous", name: "FORMS"}, text: "(Misc) FORMS" },
  { value: { type: "Miscellaneous", name: "Request Forms"}, text: "(Misc) Request Forms" },
  { value: { type: "Miscellaneous", name: "Request Forms - Urgent"}, text: "(Misc) Request Forms - Urgent" },
  { value: { type: "Miscellaneous", name: "Request Forms - Others (Pls indicate)"}, text: "(Misc) Request Forms - Others (Pls indicate)" },
];

const RequestRow = ({ consumable, quantity, onConsumableSelect, onQuantityChange, onRemoveRow, index, length }) => {
  return (
    <Table.Row className='cr-req-f__row'>
      <Table.Cell>
        <Select
          size="normal"
          search
          placeholder="Search for a consumable"
          border
          value={consumable}
          onChange={(e, {value}) => {
            onConsumableSelect(index, value);
          }}
          options={options}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          type="number"
          size="small"
          border
          onChange={(e) => onQuantityChange(index, e.target.value)}
          value={quantity}
        />
      </Table.Cell>
      <Table.Cell width={1}>
        {length > 1 && (
          <SIcon
            onClick={() => onRemoveRow(index)}
            style={{ margin: '0' }}
            name="trash alternate outline"
            color="black"
            className='cr-req-f__row__icon'
          />
        )}
      </Table.Cell>
    </ Table.Row>
  );
}

const RequestFormTable = ({ orderConsumables, setOrderConsumables, isSaving }) => {
  const addRow = () => {
    const toEdit = [...orderConsumables];
    toEdit.push({ name: '', quantity: '' })
    setOrderConsumables(toEdit);
  }
  
  const onConsumableSelect = (index, value) => {
    const toEdit = [...orderConsumables];
    const item = toEdit[index];
    toEdit[index] = {...item, ...value }
    setOrderConsumables(toEdit);
  }

  const onQuantityChange = (index, value) => {
    const toEdit = [...orderConsumables];
    const item = toEdit[index];
    toEdit[index] = {...item, quantity: value }
    setOrderConsumables(toEdit);
  }

  const onRemoveRow = (index) => {
    if(orderConsumables.length === 1) return;
    const toEdit = [...orderConsumables];
    toEdit.splice(index, 1);
    setOrderConsumables(toEdit);
  }

  return (
    <div className='cr-req-f__table-w'>
      <Table basic="very" className='cr-req-f__table'>
        <Table.Header>
          <Table.Row>
              <Table.HeaderCell>Item Name</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {orderConsumables.map(({consumable, quantity}, index) => (
            <RequestRow length={orderConsumables.length} index={index} consumable={consumable} quantity={quantity} onConsumableSelect={onConsumableSelect} onQuantityChange={onQuantityChange} onRemoveRow={onRemoveRow} />
          ))}
        </Table.Body>
      </Table>
      <Button size="fullWidth" onClick={addRow} inverted className='cr-req-f__a-button' disable={isSaving} >
        <SIcon className='cr-req-f__button__icon' name="add circle" inverted color="black" /> Add consumable
      </Button>
    </div>
  );
};

const RequestForm = ({ createConsumable }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderConsumables, setOrderConsumables] = useState([{ type: '', name: '', quantity: '' }]);

  const placeOrder = async () => {
    try {
      let error = false;
      orderConsumables.map(item => {
        if(!item.name || !item.quantity) error = true;
      })
      if(error) return toast.error(<ErrorToast message="An order item is not complete" />)
      setIsSaving(true)
      await createConsumable(orderConsumables);
    } catch(error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <div className='cr-req-f'>
      <RequestFormTable orderConsumables={orderConsumables} setOrderConsumables={setOrderConsumables} isSaving={isSaving} />
      <TextArea label="Remarks" wClassName='cr-req-f__remarks' placeholder="Write a remark"/>
      <Button color="blue" size="fullWidth" onClick={placeOrder} className='cr-req-f__button' disable={isSaving || isLoading} isLoading={isSaving}>
        Submit order
      </Button>
    </div>
  );
};

export default RequestForm;
