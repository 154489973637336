import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CircleLoader from 'react-spinners/CircleLoader';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorToast, Text } from 'components';

import reactSessionService from 'api/reactSession';
import { setUser, setTempState } from 'redux/generalState.actions';

import ReactHealthOverview from './ReactHealthOverview';
import ReactReportPromotion from 'features/PromotionPlacholders/ReactHealthPromotion/ReactHealthPromotion';
import PatientReactHealthOverview from './components/PatientReactHealthOverview/PatientReactHealthOverview';

const ReactHealthContainer = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.general.user);
  const patient = useSelector(state => state.general.tempState);

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    authenticatePlato();
  }, [])

  const authenticatePlato = async () => {
    try {
      // Prevent already logged in users to stop the fetching of the plato token
      const isExternalCheck = localStorage.getItem('IS_PLATO_EXTERNAL') === 'true';

      if(isExternalCheck && currentUser.currentClientId) {
        setIsAuthenticated(true);
        setIsLoading(false);
        localStorage.setItem('IS_PLATO_EXTERNAL', false);
        return;
      };
      const urlSearchParams = new URLSearchParams(window.location.search);
      const platoToken = urlSearchParams.get('token')
      const { data } = await reactSessionService.platoReactSession(platoToken);
      localStorage.setItem('AUTH_TOKEN', JSON.stringify({ token: data?.shortLivedToken?.access_token?.token, expires:  data?.shortLivedToken?.access_token?.expiresAt }));
      localStorage.setItem('IS_PLATO_EXTERNAL', true);
      dispatch(setTempState(data.patient));
      const clientId = data.clientId
      dispatch(setUser({ currentClientId: clientId }));
      localStorage.setItem('DELTA_CLIENT_ID', clientId);
    } catch(error) {
      toast.error(<ErrorToast error={error} />);
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading ?
        <div className='rhce__loader-w'>
          <CircleLoader color="#455af7" />
          <Text className="mt-5" bold>Loading your Riverr integration...</Text>
        </div>
      : 
        <>
          {(isAuthenticated && !!patient) ? 
            <PatientReactHealthOverview fetchingPatient={isLoading} patientDetails={patient} />
            // <ReactHealthOverview /> 
          :
            <div className='rhce__promotion'>
              <div className='rhce__promotion__text'>
                <Text size="bigish" dark>You don't have an existing integration to Riverr, reach out to us!</Text>
                <Text size="big" bold className="mt-3">Contact: <a>sales@riverr.ai</a></Text>
              </div>
              <ReactReportPromotion />
            </div>
          }
        </>
      }
    </>
  );
};

export default ReactHealthContainer;
