import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { Button, Text } from 'components';

import './govTechError.scss';

const GovTechError = ({ toggleModal, isToggled, error }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [subErrorMessage, setSubErrorMessage] = useState('');

  useEffect(() => {
    checkError(error);
  }, [error]);

  const checkError = (error) => {
    if (
      error ===
      'GraphQL error: Date of birth and/or gender do not match existing records. Please try again with the correCt values. If the problem persists, please submit supporting documents to support@notarise.gov.sg'
    ) {
      toggleModal(true);
      setErrorMessage(
        'The Date of birth and/or gender do no match GovTech records. Please try again with the correCt values. If the problem persists, please submit supporting documents to support@notarise.gov.sg'
      );
      setSubErrorMessage(
        'If required: Bypass the error by removing the patients incorrect NRIC/FIN number'
      );
    }
  };

  return (
    <Modal
      size="small"
      className="gov-tech-error"
      open={isToggled}
      onClose={() => toggleModal(false)}
      closeIcon
    >
      <Modal.Header>Error Notarising Document</Modal.Header>
      <Modal.Content
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Text center size="small" className="mt-2 ml-3 mr-3">
          {errorMessage}
        </Text>
        <Text bold size="small" className="mb-5 mt-5 ml-3 mr-3">
          {subErrorMessage}
        </Text>
        <Button
          color="blue"
          size="wide"
          onClick={() => toggleModal(false)}
          style={{ height: '40px' }}
        >
          Close
        </Button>
      </Modal.Content>
    </Modal>
  );
};

export default GovTechError;
