import gql from 'graphql-tag';

export const createTimeslots = gql`
  mutation createTimeslots(
    $clientId: ID!
    $fromDate: String!
    $toDate: String!
    $startTime: String!
    $endTime: String!
    $appointmentLimit: Int!
    $includeWeekend: Boolean
    $override: Boolean!
    $duration: Int!
    $isDev: Boolean
    $daysOfWeek: [String]
  ) {
    createTimeslots(
      clientId: $clientId
      fromDate: $fromDate
      toDate: $toDate
      startTime: $startTime
      endTime: $endTime
      appointmentLimit: $appointmentLimit
      includeWeekend: $includeWeekend
      override: $override
      duration: $duration
      isDev: $isDev
      daysOfWeek: $daysOfWeek
    )
  }
`;

export const deleteTimeslots = gql`
  mutation deleteTimeslots(
    $clientId: ID!
    $fromDate: String!
    $toDate: String!
    $startTime: String!
    $endTime: String!
    $onlyWeekend: Boolean
    $includeWeekend: Boolean
    $override: Boolean
    $isDev: Boolean
    $daysOfWeek: [String]
  ) {
    deleteTimeslots(
      clientId: $clientId
      fromDate: $fromDate
      toDate: $toDate
      startTime: $startTime
      endTime: $endTime
      onlyWeekend: $onlyWeekend
      includeWeekend: $includeWeekend
      override: $override
      isDev: $isDev
      daysOfWeek: $daysOfWeek
    )
  }
`;

export const cancelAppointment = gql`
  mutation cancelAppointment($appointmentId: ID!, $isDev: Boolean) {
    cancelAppointment(appointmentId: $appointmentId, isDev: $isDev)
  }
`;

export const createAppointmentTypes = gql`
  mutation createAppointmentTypes(
    $clientId: ID!
    $name: String!
    $type: String!
    $price: String!
    $category: String!
    $currecy: String!
    $isDev: Boolean
  ) {
    createAppointmentTypes(
      clientId: $clientId
      name: $name
      type: $type
      price: $price
      category: $category
      currency: $currency
      isDev: $isDev
    )
  }
`;

export const assignStaff = gql`
  mutation assignStaff($staffId: ID, $appointmentId: ID, $isDev: Boolean) {
    assignStaff(staffId: $staffId, appointmentId: $appointmentId, isDev: $isDev)
  }
`;

export const editAppointment = gql`
  mutation editAppointment(
    $appointmentId: ID
    $staffId: ID
    $timeSlotId: ID
    $isCompleted: Boolean
    $isDev: Boolean
  ) {
    editAppointment(
      appointmentId: $appointmentId
      staffId: $staffId
      timeSlotId: $timeSlotId
      isCompleted: $isCompleted
      isDev: $isDev
    )
  }
`;

export const createTestType = gql`
  mutation createTestType(
    $name: String
    $categoryId: String
    $clientId: String
    $price: Float
    $extraFee: Float
    $extraFeeDesc: String
    $referralPrice: Float
    $type: String
    $currency: String
    $duration: String
    $description: String
    $packageType: String
    $tests: [String]
    $skipPayment: Boolean
    $hideFromMain: Boolean
    $additionalFields: [AdditionalFieldsInput]
    $validity: Boolean
    $startDate: String
    $endDate: String
    $isDev: Boolean
  ) {
    createTestType(
      name: $name
      categoryId: $categoryId
      clientId: $clientId
      price: $price
      extraFee: $extraFee
      extraFeeDesc: $extraFeeDesc
      referralPrice: $referralPrice
      type: $type
      currency: $currency
      duration: $duration
      description: $description
      packageType: $packageType
      tests: $tests
      skipPayment: $skipPayment
      hideFromMain: $hideFromMain
      additionalFields: $additionalFields
      validity: $validity
      startDate: $startDate
      endDate: $endDate
      isDev: $isDev
    )
  }
`;

export const createBulkTestType=gql`
  mutation createBulkTestType(
    $testType:[TestTypePayload]
    $isDev: Boolean
  ){
    createBulkTestType(
      testType:$testType
      isDev: $isDev
    )
  }
`;

export const editTestType = gql`
  mutation editTestType(
    $id: ID
    $name: String!
    $categoryId: String!
    $price: Float
    $extraFee: Float
    $extraFeeDesc: String
    $referralPrice: Float
    $type: String
    $currency: String
    $duration: String
    $description: String
    $packageType: String
    $tests: [String]
    $skipPayment: Boolean
    $hideFromMain: Boolean
    $additionalFields: [AdditionalFieldsInput]
    $validity: Boolean
    $startDate: String
    $endDate: String
    $isDev: Boolean
  ) {
    editTestType(
      id: $id
      name: $name
      categoryId: $categoryId
      price: $price
      extraFee: $extraFee
      extraFeeDesc: $extraFeeDesc
      referralPrice: $referralPrice
      type: $type
      currency: $currency
      duration: $duration
      description: $description
      packageType: $packageType
      tests: $tests
      skipPayment: $skipPayment
      hideFromMain: $hideFromMain
      additionalFields: $additionalFields
      validity: $validity
      startDate: $startDate
      endDate: $endDate
      isDev: $isDev
    )
  }
`;

export const deleteTestType = gql`
  mutation deleteTestType($id: ID, $isDev: Boolean) {
    deleteTestType(id: $id, isDev: $isDev)
  }
`;

export const createPackageTest = gql`
  mutation createPackageTest(
    $name: String
    $categoryId: String
    $clientId: String
    $description: String
    $price: Float
    $validity: Boolean
    $startDate: String
    $endDate: String
    $skipPayment: Boolean,
    $isDev: Boolean
  ) {
    createPackageTest(
      name: $name
      categoryId: $categoryId
      clientId: $clientId
      description: $description
      price: $price
      validity: $validity
      startDate: $startDate
      endDate: $endDate
      skipPayment: $skipPayment
      isDev: $isDev
    )
  }
`;

export const createBulkPackageTest=gql`
  mutation createBulkPackageTest(
    $tests:[PackageTestsPayload]
    $isDev: Boolean
  ){
    createBulkPackageTest(
      tests:$tests
      isDev: $isDev
    )
  }
`;

export const editPackageTest = gql`
  mutation editPackageTest(
    $id: ID
    $name: String!
    $categoryId: String!
    $description: String
    $price: Float
    $validity: Boolean
    $startDate: String
    $endDate: String
    $skipPayment: Boolean,
    $isDev: Boolean
  ) {
    editPackageTest(
      id: $id
      name: $name
      categoryId: $categoryId
      description: $description
      price: $price
      validity: $validity
      startDate: $startDate
      endDate: $endDate
      skipPayment: $skipPayment
      isDev: $isDev
    )
  }
`;

export const deletePackageTest = gql`
  mutation deletePackageTest($id: ID, $isDev: Boolean) {
    deletePackageTest(id: $id, isDev: $isDev)
  }
`;

export const createReferral = gql`
  mutation createReferral(
    $clientId: String
    $partnerName: String
    $logo: String
    $appointmentIds: [String]
    $hasDefaultAppointments: Boolean
    $isDev: Boolean
  ) {
    createReferral(
      clientId: $clientId
      partnerName: $partnerName
      logo: $logo
      appointmentIds: $appointmentIds
      hasDefaultAppointments: $hasDefaultAppointments
      isDev: $isDev
    )
  }
`;

export const editReferral = gql`
  mutation editReferral(
    $id: ID
    $clientId: String
    $partnerName: String
    $logo: String
    $appointmentIds: [String]
    $hasDefaultAppointments: Boolean
    $isDev: Boolean
  ) {
    editReferral(
      id: $id
      clientId: $clientId
      partnerName: $partnerName
      logo: $logo
      appointmentIds: $appointmentIds
      hasDefaultAppointments: $hasDefaultAppointments
      isDev: $isDev
    )
  }
`;

export const deleteReferral = gql`
  mutation deleteReferral($id: ID, $isDev: Boolean) {
    deleteReferral(id: $id, isDev: $isDev)
  }
`;

export const createGoogleCalToken = gql`
  mutation createGoogleCalToken(
    $adminId: String
    $clientId: String
    $code: String
    $redirectUri: String
  ) {
    createGoogleCalToken(
      adminId: $adminId
      clientId: $clientId
      code: $code
      redirectUri: $redirectUri
    )
  }
`;
export const removeGoogleAccount = gql`
  mutation removeGoogleAccount($adminId: String, $redirectUri: String) {
    removeGoogleAccount(adminId: $adminId, redirectUri: $redirectUri)
  }
`;

export const createOutlookCalToken = gql`
  mutation createOutlookCalToken(
    $adminId: String
    $clientId: String
    $code: String
    $redirectUri: String
  ) {
    createOutlookCalToken(
      adminId: $adminId
      clientId: $clientId
      code: $code
      redirectUri: $redirectUri
    )
  }
`;
export const removeOutlookAccount = gql`
  mutation removeOutlookAccount($adminId: String, $redirectUri: String) {
    removeOutlookAccount(adminId: $adminId, redirectUri: $redirectUri)
  }
`;

export const createClientDetails = gql`
  mutation createClientDetails(
    $clientId: String
    $clientName: String
    $domain: String
    $currency: String
    $logo: String
    $country: String
    $city: String
    $address: String
    $zipCode: String
    $taxId: String
    $email: String
    $assistEmails: [String]
    $phone: String
    $supportEmail: String
    $supportPhone: String
    $primaryColor: String
    $homePageVersion: String
    $timezone: String
    $isDev: Boolean
  ) {
    createClientDetails(
      clientId: $clientId
      clientName: $clientName
      domain: $domain
      currency: $currency
      logo: $logo
      country: $country
      city: $city
      address: $address
      zipCode: $zipCode
      taxId: $taxId
      email: $email
      assistEmails: $assistEmails
      phone: $phone
      supportEmail: $supportEmail
      supportPhone: $supportPhone
      primaryColor: $primaryColor
      homePageVersion: $homePageVersion
      timezone: $timezone
      isDev: $isDev
    )
  }
`;

export const editClientDetails = gql`
  mutation editClientDetails(
    $id: ID
    $clientId: String
    $clientName: String
    $alias: String
    $domain: String
    $currency: String
    $logo: String
    $country: String
    $city: String
    $address: String
    $zipCode: String
    $taxId: String
    $email: String
    $assistEmails: [String]
    $phone: String
    $supportEmail: String
    $supportPhone: String
    $primaryColor: String
    $hasQuestionnaire: Boolean
    $homePageVersion: String
    $timezone: String
    $cutoffTime: Int
    $skipPayment: Boolean
    $titles: [String]
    $isDev: Boolean
  ) {
    editClientDetails(
      id: $id
      clientId: $clientId
      clientName: $clientName
      alias: $alias
      domain: $domain
      currency: $currency
      hasQuestionnaire: $hasQuestionnaire
      logo: $logo
      country: $country
      city: $city
      address: $address
      zipCode: $zipCode
      taxId: $taxId
      email: $email
      assistEmails: $assistEmails
      phone: $phone
      supportEmail: $supportEmail
      supportPhone: $supportPhone
      primaryColor: $primaryColor
      homePageVersion: $homePageVersion
      timezone: $timezone
      cutoffTime: $cutoffTime
      skipPayment: $skipPayment
      titles: $titles
      isDev: $isDev
    )
  }
`;
export const editSubClients = gql`
  mutation editSubClients($subClients: [SubClientsInput], $isDev: Boolean) {
    editSubClients(subClients: $subClients, isDev: $isDev)
  }
`;

export const saveEmailTemplateIds = gql`
  mutation saveEmailTemplateIds(
    $clientId: String
    $emailVerification: String
    $passwordReset: String
    $reminder: String
    $staffAssgined: String
    $orderConfirmation: String
    $isDev: Boolean
  ) {
    saveEmailTemplateIds(
      clientId: $clientId
      emailVerification: $emailVerification
      passwordReset: $passwordReset
      reminder: $reminder
      staffAssgined: $staffAssgined
      orderConfirmation: $orderConfirmation
      isDev: $isDev
    )
  }
`;

export const setReminderTime = gql`
  mutation setReminderTime(
    $clientId: String
    $daysBefore: Int
    $time: String
    $isDev: Boolean
  ) {
    setReminderTime(
      clientId: $clientId
      daysBefore: $daysBefore
      time: $time
      isDev: $isDev
    )
  }
`;

export const setRescheduleLimit = gql`
  mutation setRescheduleLimit(
    $clientId: String
    $daysBeforeAppointment: Int
    $time: String
    $isDev: Boolean
  ) {
    setRescheduleLimit(
      clientId: $clientId
      daysBeforeAppointment: $daysBeforeAppointment
      time: $time
      isDev: $isDev
    )
  }
`;

export const syncGoogleCalendar = gql`
  mutation syncGoogleCalendar(
    $adminId: String
    $clientId: String
    $redirectUri: String
  ) {
    syncGoogleCalendar(
      adminId: $adminId
      clientId: $clientId
      redirectUri: $redirectUri
    )
  }
`;

export const syncOutlookCalendar = gql`
  mutation syncGoogleCalendar(
    $adminId: String
    $clientId: String
    $redirectUri: String
  ) {
    syncOutlookCalendar(
      adminId: $adminId
      clientId: $clientId
      redirectUri: $redirectUri
    )
  }
`;

export const createAppointmentRecord = gql`
  mutation createAppointmentRecord(
    $userId: String
    $clientId: String
    $patientDetails: [PatientDetails]
    $payment: PaymentPayload
  ) {
    createAppointmentRecord(
      userId: $userId
      clientId: $clientId
      patientDetails: $patientDetails
      payment: $payment
    )
  }
`;

export const createCoupon = gql`
  mutation createCoupon(
    $clientId: String
    $name: String
    $code: String
    $discount: Float
    $isActive: Boolean
    $hasMinimum: Boolean
    $isLimited: Boolean
    $validity: Boolean
    $endDate: String
    $startDate: String
    $limit: Int
    $miminumOrder: String
    $isDev: Boolean
  ) {
    createCoupon(
      clientId: $clientId
      name: $name
      code: $code
      discount: $discount
      isActive: $isActive
      hasMinimum: $hasMinimum
      isLimited: $isLimited
      validity: $validity
      endDate: $endDate
      startDate: $startDate
      limit: $limit
      miminumOrder: $miminumOrder
      isDev: $isDev
    )
  }
`;

export const editCoupon = gql`
  mutation editCoupon(
    $id: ID
    $name: String
    $code: String
    $discount: Float
    $isActive: Boolean
    $hasMinimum: Boolean
    $isLimited: Boolean
    $validity: Boolean
    $endDate: String
    $startDate: String
    $limit: Int
    $miminumOrder: String
    $isDev: Boolean
  ) {
    editCoupon(
      id: $id
      name: $name
      code: $code
      discount: $discount
      isActive: $isActive
      hasMinimum: $hasMinimum
      isLimited: $isLimited
      validity: $validity
      endDate: $endDate
      startDate: $startDate
      limit: $limit
      miminumOrder: $miminumOrder
      isDev: $isDev
    )
  }
`;

export const deleteCoupon = gql`
  mutation deleteCoupon($id: ID, $isDev: Boolean) {
    deleteCoupon(id: $id, isDev: $isDev)
  }
`;

export const resendPatientFinalizeEmail = gql`
  mutation resendPatientFinalizeEmail(
    $appointmentId: ID
    $email: String
    $isDev: Boolean
  ) {
    resendPatientFinalizeEmail(
      appointmentId: $appointmentId
      email: $email
      isDev: $isDev
    )
  }
`;

export const createAppointmentCategory = gql`
  mutation createAppointmentCategory(
    $category: String
    $clientId: String
    $isDev: Boolean
  ) {
    createAppointmentCategory(
      category: $category
      clientId: $clientId
      isDev: $isDev
    )
  }
`;

export const editAppointmentCategory = gql`
  mutation editAppointmentCategory(
    $id: String
    $newCategory: String
    $isDev: Boolean
  ) {
    editAppointmentCategory(id: $id, newCategory: $newCategory, isDev: $isDev)
  }
`;

export const createTestCategory = gql`
  mutation createTestCategory(
    $category: String
    $clientId: String
    $isDev: Boolean
  ) {
    createTestCategory(category: $category, clientId: $clientId, isDev: $isDev)
  }
`;

export const editTestCategory = gql`
  mutation editTestCategory(
    $id: String
    $newCategory: String
    $isDev: Boolean
  ) {
    editTestCategory(id: $id, newCategory: $newCategory, isDev: $isDev)
  }
`;

export const saveCharges = gql`
  mutation saveCharges($clientId: String, $amount: String, $isDev: Boolean) {
    saveCharges(clientId: $clientId, amount: $amount, isDev: $isDev)
  }
`;
