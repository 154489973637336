import React from 'react';
import moment from 'moment';
import { Table, Button as SButton } from 'semantic-ui-react';

import { CheckBox, Text } from 'components';

const TableRow = ({
  session,
  onRowClick,
  onRowSelect,
  selectedRows,
  isMobile,
  onAssignClick,
  sessionType,
}) => {
  return (
    <Table.Row
      key={session.id}
      className="table-row"
      onClick={(e) => onRowClick(e, session)}
    >
      {!isMobile && (
        <Table.Cell
          style={{ width: '30px' }}
          onClick={(e) => onRowSelect(e, session.id, session)}
        >
          <div>
            <CheckBox
              className="rho__checkbox"
              checked={!!selectedRows[session.id]}
            />
          </div>
        </Table.Cell>
      )}
      <Table.Cell>
        <Text size="small" uppercase>
          {sessionType === 'single' ? session.patientName : session.externalId}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text size="small" uppercase>
          {session.name}
        </Text>
      </Table.Cell>
      {sessionType === 'group' && (
        <Table.Cell>
          <Text size="small" uppercase>
            {session.company}
          </Text>
        </Table.Cell>
      )}
      <Table.Cell>
        <Text size="small" uppercase>
          {session?.client?.name}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text size="small">
          {moment(session.date).format('DD-MM-YYYY HH:mm A')}
        </Text>
      </Table.Cell>
      <Table.Cell>
        {session?.staff?.name ? (
          <SButton
            className="rho__assign-butt"
            size="small"
            onClick={(e) => onAssignClick(e, session.id)}
            animated
          >
            <SButton.Content visible>{session?.staff?.name}</SButton.Content>
            <SButton.Content hidden>Change Staff</SButton.Content>
          </SButton>
        ) : (
          <SButton
            className="rho__assign-butt"
            size="small"
            onClick={(e) => onAssignClick(e, session.id)}
            animated
          >
            <SButton.Content visible>Not Assigned</SButton.Content>
            <SButton.Content hidden>Assign Staff</SButton.Content>
          </SButton>
        )}
      </Table.Cell>
      <Table.Cell>
        <Text
          size="small"
          color={session.status}
          uppercase
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className={`rho__dot rho__dot--${session.status}`} />
          {session.status}
        </Text>
      </Table.Cell>
    </Table.Row>
  );
};

export default TableRow;
