import React from 'react';

const SvgPlusCircle = (props) => (
  <svg
    width={props?.width || '21'}
    height={props?.height || '20'}
    viewBox="0 0 21 20"
    fill={props?.color || '1E1E1E'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 0C8.52219 0 6.58879 0.58649 4.9443 1.6853C3.29981 2.78412 2.01809 4.3459 1.26121 6.17316C0.504333 8.00043 0.3063 10.0111 0.692152 11.9509C1.078 13.8907 2.03041 15.6725 3.42894 17.0711C4.82746 18.4696 6.60929 19.422 8.5491 19.8078C10.4889 20.1937 12.4996 19.9957 14.3268 19.2388C16.1541 18.4819 17.7159 17.2002 18.8147 15.5557C19.9135 13.9112 20.5 11.9778 20.5 10C20.4971 7.34871 19.4426 4.80684 17.5679 2.9321C15.6932 1.05736 13.1513 0.00286757 10.5 0V0ZM10.5 18.3333C8.85183 18.3333 7.24066 17.8446 5.87025 16.9289C4.49984 16.0132 3.43174 14.7117 2.80101 13.189C2.17028 11.6663 2.00525 9.99075 2.32679 8.37425C2.64834 6.75774 3.44201 5.27288 4.60745 4.10744C5.77288 2.94201 7.25774 2.14833 8.87425 1.82679C10.4908 1.50525 12.1663 1.67027 13.689 2.301C15.2117 2.93173 16.5132 3.99984 17.4289 5.37025C18.3446 6.74066 18.8333 8.35182 18.8333 10C18.8309 12.2094 17.9522 14.3276 16.3899 15.8899C14.8276 17.4522 12.7094 18.3309 10.5 18.3333V18.3333ZM14.6667 10C14.6667 10.221 14.5789 10.433 14.4226 10.5893C14.2663 10.7455 14.0543 10.8333 13.8333 10.8333H11.3333V13.3333C11.3333 13.5543 11.2455 13.7663 11.0893 13.9226C10.933 14.0789 10.721 14.1667 10.5 14.1667C10.279 14.1667 10.067 14.0789 9.91075 13.9226C9.75447 13.7663 9.66667 13.5543 9.66667 13.3333V10.8333H7.16667C6.94566 10.8333 6.73369 10.7455 6.57741 10.5893C6.42113 10.433 6.33334 10.221 6.33334 10C6.33334 9.77898 6.42113 9.56702 6.57741 9.41074C6.73369 9.25446 6.94566 9.16667 7.16667 9.16667H9.66667V6.66667C9.66667 6.44565 9.75447 6.23369 9.91075 6.07741C10.067 5.92113 10.279 5.83333 10.5 5.83333C10.721 5.83333 10.933 5.92113 11.0893 6.07741C11.2455 6.23369 11.3333 6.44565 11.3333 6.66667V9.16667H13.8333C14.0543 9.16667 14.2663 9.25446 14.4226 9.41074C14.5789 9.56702 14.6667 9.77898 14.6667 10Z"
      fill={props?.color || '1E1E1E'}
    />
  </svg>
);

export default SvgPlusCircle;
