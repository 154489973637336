import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button,
  Select,
  Cleave,
  Input,
  ErrorToast,
  Text,
  LinearLoader,
  SuccessToast,
} from 'components';
import countries from 'helpers/countries';
import AppointmentService from 'api-delta/appointments';
import CertificateService from 'api-delta/certificates';
import ProfileService from 'api-delta/profiles';
import TrainingCenterService from 'api-delta/trainingCenters';
import StaffService from 'api-delta/staff';

import { setStaff } from '../../../staffPages/staffManagement/store/staffManagement.actions';

import '../appointmentDetails.scss';

// TODO: move this to features folder
const FftAppointment = ({
  appointment,
  setCourseCompleted,
  isDocCreator,
  subType,
  setRecentDocs,
}) => {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.general.user);
  const clientInfo = useSelector((state) => state.general.clientInformation);
  const instructors = useSelector(
    (state) => state.instructorManagement.instructors
  );

  const [countriesList, setCountriesList] = useState([]);
  const [clinic, setClinic] = useState(null);
  const [errors, setErrors] = useState({});
  const [nationality, setNationality] = useState(null);
  const [gender, setGender] = useState(null);
  const [birthDate, setBirthDate] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [findingUser, setFindingUser] = useState(false);
  const [pinNo, setPinNo] = useState('');
  const [email, setEmail] = useState('');
  const [isFit, setIsFit] = useState('true');
  const [physicianLicense, setPhysicianLicense] = useState('');
  const [userId, setUserId] = useState(null);
  const [occupation, setOccupation] = useState('');
  const [rigName, setRigName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [physician, setPhysician] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [facilitiesOptions, setFacilitiesOptions] = useState([]);
  const [physicians, setPhysicians] = useState([]);

  useEffect(() => {
    fetchClinics();
    formatCountries();
    fetchInstructors();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (appointment && appointment.patientEmail)
      findUser(appointment.patientEmail);
  }, [appointment]); // eslint-disable-line

  const fetchInstructors = () => {
    if (instructors.length > 0) return formatPhysicians(instructors);
    if (userInfo.centerId) return fetchInstructorsByCenter();
    return fetchInstructorsByClient();
  };

  const fetchInstructorsByClient = async () => {
    try {
      const {
        data: { instructorsByClientId },
      } = await StaffService.getStaffByClientId(clientInfo.clientId);
      dispatch(setStaff(instructorsByClientId));
      formatPhysicians(instructorsByClientId);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchInstructorsByCenter = async () => {
    try {
      const {
        data: { instructorsByCenterId },
      } = await StaffService.getInstructorsByCenterId(userInfo.centerId);
      dispatch(setStaff(instructorsByCenterId));
      formatPhysicians(instructorsByCenterId);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const formatPhysicians = (instructorsByClientId) => {
    setPhysicians(
      instructorsByClientId.map((phys) => {
        const obj = {
          text: phys.name,
          value: {
            id: phys.id,
            name: phys.name,
            signature: phys.signature,
            licenseNo: phys.licenseNo,
            certAuthority: phys.certAuthority,
          },
        };
        if (appointment && phys.id === appointment.physicianId) {
          setPhysicianLicense(phys.licenseNo);
          setPhysician(obj.value);
        }
        return obj;
      })
    );
  };

  const clearState = () => {
    setPinNo('');
    setEmail('');
    setIsFit('true');
    setPhysicianLicense('');
    setUserId(null);
    setOccupation('');
    setRigName('');
    setFirstName('');
    setLastName('');
    setPhysician(null);
    setNationality(null);
    setGender(null);
    setBirthDate('');
  };

  const selectPhysician = (phys) => {
    setPhysician(phys);
    setPhysicianLicense(phys.licenseNo || '');
  };

  const setInitialClinic = (trainingCenters) => {
    if (!appointment || !appointment.clinicId) return;
    trainingCenters.forEach((center) => {
      if (center.id === appointment.clinicId) setClinic(center.id);
    });
  };

  const fetchClinics = async () => {
    try {
      const {
        data: { trainingCenters },
      } = await TrainingCenterService.getTrainingCentersByClientId(
        userInfo.currentClientId
      );

      const facilitiesOptions = trainingCenters.map((facility) => ({
        text: facility.name,
        value: facility.id,
      }));

      setFacilities(trainingCenters);
      setFacilitiesOptions(facilitiesOptions);
      setInitialClinic(trainingCenters);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const formatCountries = () => {
    const formattedCons = countries.map((con, index) => {
      return {
        key: index,
        text: con.text,
        value: { nationality: con.text, countryCode: con.key },
      };
    });

    setCountriesList(formattedCons);
  };

  const findUser = async (emailValue = email) => {
    try {
      setFindingUser(true);
      const {
        data: { userByEmail },
      } = await ProfileService.searchUser(emailValue);
      setFindingUser(false);
      setEmail(emailValue);
      if (userByEmail.id !== '-1') {
        setUserId(userByEmail.id);
        userByEmail.firstName &&
          setFirstName(userByEmail.passportFirstName || userByEmail.firstName);
        userByEmail.lastName &&
          setLastName(userByEmail.passportLastName || userByEmail.lastName);
        userByEmail.birthday &&
          setBirthDate(moment(userByEmail.birthday).format('DD-MM-YYYY'));
        // userByEmail.fromCountry &&
        //   setNationality({
        //     nationality: userByEmail.fromCountry,
        //     countryCode: userByEmail.countryCode,
        //   });
        userByEmail.isFemale !== undefined &&
          setGender(userByEmail.isFemale ? 'female' : 'male');
      }
    } catch (error) {
      console.log('error', error);
      setFindingUser(false);
    }
  };

  const isFormValid = () => {
    const newErrors = {};
    let isValid = true;

    if (!clinic) {
      newErrors.clinic = true;
      isValid = false;
    }

    if (!birthDate || birthDate.length < 5) {
      newErrors.birthDate = true;
      isValid = false;
    }
    if (!pinNo || pinNo.length < 2) {
      newErrors.pinNo = true;
      isValid = false;
    }
    if (isDocCreator && (!email || email.length < 5)) {
      newErrors.email = true;
      isValid = false;
    }
    if (!physicianLicense || physicianLicense.length < 2) {
      newErrors.physicianLicense = true;
      isValid = false;
    }
    if (!occupation || occupation.length < 2) {
      newErrors.occupation = true;
      isValid = false;
    }
    if (!rigName || rigName.length < 2) {
      newErrors.rigName = true;
      isValid = false;
    }
    if (isDocCreator && !physician) {
      newErrors.physician = true;
      isValid = false;
    }

    if (!nationality) {
      newErrors.nationality = true;
      isValid = false;
    }
    if (!gender) {
      newErrors.gender = true;
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const completeAppointment = async () => {
    try {
      setIsSaving(true);
      const clinicDetails = facilities.filter(
        (facility) => clinic === facility.id
      );

      const clinicPayload = {
        issuerAddress: `${clinicDetails[0].address}, ${clinicDetails[0].city}, ${clinicDetails[0].country}`,
        issuerPhoneNumber: clinicDetails[0].phoneNumber,
        clinicId: clinicDetails[0].id,
      };
      await AppointmentService.completeFftAppointment({
        appointmentId: appointment.id,
        gender,
        birthDate,
        ...nationality,
        ...clinicPayload,
        position: occupation,
        pinNo,
        physicianLicense,
        transportName: rigName,
        isFit: isFit === 'true',
        centerId: userInfo.centerId || null,
        physicianId: physician.id,
      });
      setCourseCompleted(true);
    } catch (error) {
      setIsSaving(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  const issueDocument = async () => {
    try {
      setIsSaving(true);
      const clinicDetails = facilities.filter(
        (facility) => clinic === facility.id
      );

      let certPayload = {
        issuerAddress: `${clinicDetails[0].address}, ${clinicDetails[0].city}, ${clinicDetails[0].country}`,
        issuerPhoneNumber: clinicDetails[0].phoneNumber,
        clinicId: clinicDetails[0].id,
        pinNo,
        physicianLicense,
        transportName: rigName,
        isFit: isFit,
        name: 'Fitness to Travel',
        subType: 'FFT',
        validUntil: moment().add('3', 'days').unix(),
        isVerified: true,
        courseId: null,
        logoImage: clientInfo.logo,
        watermarkImage: null,
        certificateInformation: `{"blocks":[{"key":"ca2pr","text":"This is to certify that the above named individual was assessed to determine their fitness to travel.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"efncn","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3i0fj","text":"Comments: The above individual has been assessed and has been declared Fit to Travel following presenting a negative Covid-19 test result.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
        clientId: clientInfo.clientId,
        courseName: null,
        version: '1',
        type: 'medical',
        certType: 'FFT',
        saveToBlock: true,
        centerId: userInfo.centerId,
        physicianName: physician.name,
        physicianId: physician.id,
        signers: JSON.stringify([
          {
            role: 'Physician',
            image: physician.signature,
            name: physician.name,
          },
        ]),
        email: email,
        userId,
        holderName: firstName + ' ' + lastName,
        issuingUser: userInfo.name,
        issuingUserId: userInfo.safe_id,
      };
      await CertificateService.onIssueCert(certPayload);

      let recentDocsLS = localStorage.getItem('RECENT_ISSUED_DOCS');
      if (recentDocsLS) {
        recentDocsLS = JSON.parse(recentDocsLS);
        let _subTypes = recentDocsLS.subTypes;
        if (_subTypes.length > 5) {
          _subTypes = _subTypes.slice(0, 5);
        }
        if (!_subTypes.includes(subType)) {
          _subTypes.unshift(subType);
        }
        recentDocsLS.userId = clientInfo.safe_id;
        recentDocsLS.subTypes = _subTypes;
      } else {
        recentDocsLS = {
          userId: userInfo.safe_id,
          subTypes: [subType],
        };
      }
      setRecentDocs(recentDocsLS.subTypes);
      localStorage.setItem('RECENT_ISSUED_DOCS', JSON.stringify(recentDocsLS));

      clearState();
      toast.success(<SuccessToast message="Document issued" />, {
        hideProgressBar: true,
        autoClose: 4000,
        pauseOnHover: true,
      });
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  const onSubmit = () => {
    if (!isFormValid())
      return toast.error(<ErrorToast message="Fill all required fields" />);
    if (isDocCreator) issueDocument();
    else completeAppointment();
  };

  return (
    <>
      <div className="appointment-details__modal-content__column">
        <div className="appointment-details__modal-content__header">
          Physician
        </div>
        <div className="appointment-details__modal-content__inputs-wrapper">
          <div className="appointment-details__modal-content__input">
            <Select
              label="Select physician"
              border
              search
              icon={false}
              options={physicians}
              value={physician}
              onChange={(e, { value }) => selectPhysician(value)}
              invalid={errors.physician}
              size="fullWidth"
              placeholder="Physician"
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Select
              label="Clinic that conducted appointment"
              border
              search
              icon={false}
              options={facilitiesOptions}
              value={clinic}
              onChange={(e, { value }) => setClinic(value)}
              invalid={errors.clinic}
              size="fullWidth"
              placeholder="Clinic"
            />
          </div>
        </div>
        <div className="appointment-details__modal-content__inputs-wrapper">
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="License no."
              border
              onChange={(e) => setPhysicianLicense(e.target.value)}
              value={physicianLicense}
              invalid={errors.physicianLicense}
              placeholder="License no."
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Oil & Gas UK PIN no."
              border
              onChange={(e) => setPinNo(e.target.value)}
              value={pinNo}
              invalid={errors.pinNo}
              placeholder="Oil & Gas UK PIN no."
            />
          </div>
        </div>
      </div>
      <div className="appointment-details__modal-content__column">
        <div className="appointment-details__modal-content__header">
          Patient
        </div>
        <div className="appointment-details__modal-content__inputs-wrapper">
          <div className="appointment-details__modal-content__input">
            <div className="appointment-details__loader-input">
              <Input
                size="fullWidth"
                label="Email"
                border
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                invalid={errors.email}
                placeholder="Email"
                onBlur={() => findUser(email)}
              />
              {findingUser && (
                <>
                  <LinearLoader />
                  <Text
                    size="tiny"
                    className="app-creation__input-wrapper__loader-text"
                  >
                    Searching for patient
                  </Text>
                </>
              )}
            </div>
          </div>
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="First name"
              border
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              invalid={errors.firstName}
              placeholder="First name"
            />
          </div>

          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Last name"
              border
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              invalid={errors.lastName}
              placeholder="Last name"
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Select
              size="fullWidth"
              label="Nationality"
              placeholder="Nationality"
              border
              search
              icon={false}
              options={countriesList}
              value={nationality}
              onChange={(e, { value }) => setNationality(value)}
              invalid={errors.nationality}
            />
          </div>

          <div className="appointment-details__modal-content__input">
            <Cleave
              size="fullWidth"
              style={{ paddingLeft: '10px', width: '300px' }}
              label="Birth date"
              placeholder="DD/MM/YYYY"
              options={{
                date: true,
                datePattern: ['d', 'm', 'Y'],
                delimiter: '/',
              }}
              onChange={(e) => setBirthDate(e.target.value)}
              value={birthDate}
              invalid={errors.birthDate}
            />
          </div>

          <div className="appointment-details__modal-content__input">
            <Select
              size="fullWidth"
              label="Gender"
              border
              search
              icon={false}
              placeholder="Gender"
              options={[
                {
                  text: 'Male',
                  value: 'Male',
                },
                {
                  text: 'Female',
                  value: 'Female',
                },
                {
                  text: 'Other',
                  value: 'Other',
                },
                {
                  text: 'Unknown',
                  value: 'Unknown',
                },
              ]}
              value={gender}
              onChange={(e, { value }) => setGender(value)}
              invalid={errors.gender}
            />
          </div>

          <div className="appointment-details__modal-content__input">
            <Select
              size="fullWidth"
              label="Fitness to Travel"
              border
              search
              icon={false}
              placeholder="Is Fitness to Travel"
              options={[
                {
                  text: 'True',
                  value: 'true',
                },
                {
                  text: 'False',
                  value: 'false',
                },
              ]}
              value={isFit}
              onChange={(e, { value }) => setIsFit(value)}
            />
          </div>
          {/* 
          <div className="appointment-details__modal-content__input">
            <Input
            size="fullWidth"
              label="Passport no."
              border
              onChange={e => setPassNo(e.target.value)}
              value={passNo}
              invalid={errors.passNo}
              placeholder="Passport no."
            />
          </div> */}
        </div>
      </div>
      <div className="appointment-details__modal-content__column">
        <div className="appointment-details__modal-content__header">
          Travel details
        </div>
        <div className="appointment-details__modal-content__inputs-wrapper">
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Occupation"
              border
              onChange={(e) => setOccupation(e.target.value)}
              value={occupation}
              invalid={errors.occupation}
              placeholder="Occupation"
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Rig name"
              border
              onChange={(e) => setRigName(e.target.value)}
              value={rigName}
              invalid={errors.rigName}
              placeholder="Rig name"
            />
          </div>
        </div>
      </div>
      <div className="appointment-details__modal-content__buttons">
        <Button
          size="wide"
          color="blue"
          onClick={onSubmit}
          disabled={isSaving}
          style={{ width: 400, marginTop: '15px' }}
        >
          {isSaving ? (
            <Loader active inverted size="tiny" color="white" />
          ) : isDocCreator ? (
            'Issue certificate'
          ) : (
            'Complete'
          )}
        </Button>
      </div>
    </>
  );
};

export default FftAppointment;
