import gql from 'graphql-tag';

export const getTinyUrl = gql`
  query getTinyUrl($tinyUrl: String) {
    getTinyUrl(tinyUrl: $tinyUrl) {
      full
      tiny
    }
  }
`;
