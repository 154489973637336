import React from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import cx from 'classnames';
import { convertFromRaw, EditorState } from 'draft-js';

import { Text, RichTextEditor } from '../../../../components';

import './certificate.scss';

const Cerficate = props => {
  const formatSignerRole = role => {
    return role.charAt(0) + role.slice(1).toLowerCase();
  };

  const renderSigners = () => {
    if (!props.certificate?.signers) return [];
    return props.certificate.signers.map(signer => {
      if (!signer || (!signer.name && !signer.image)) return null;
      return (
        <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
          <div className="certificate__content__data-wrapper__signature-wrapper">
            <img
              className="certificate__content__data-wrapper__signature-wrapper__signature"
              src={signer.image}
              alt="img"
            />
            <div className="certificate__content__data-wrapper__signature-wrapper__signature-footer">
              {signer.name}, {formatSignerRole(signer.role)}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className={'certificate cert-wrapper ' + props.className}
      ref={props.certRef}
    >
      <div className="certificate__content">
        {props.certificate.isRevoked && (
          <div className="certificate__content__revoked">
            <div>Document revoked</div>
          </div>
        )}
        {props.certificate.logoImage && (
          <div className="certificate__content__logo-wrapper">
            <img
              className="certificate__content__logo-wrapper__logo"
              src={props.certificate.logoImage}
              alt="logo"
            />
          </div>
        )}
        <div className="certificate__content__row">
          <div className="certificate__content__data-wrapper">
            <div className="certificate__content__data-wrapper__data">
              <Text bold size="bigger" color="black">
                {props.certificate.name}
              </Text>
            </div>
          </div>
        </div>

        <div className="certificate__content__row">
          <div
            className={cx('certificate__content__data-wrapper', {
              'certificate__content__data-wrapper--half': !props.certificate
                .ssn,
            })}
          >
            <div className="certificate__content__data-wrapper__label">
              Document holder
            </div>
            <div className="certificate__content__data-wrapper__data">
              <Text bold color="black">
                {props.certificate.holderName}
              </Text>
            </div>
          </div>
          {!props.certificate.ssn && (
            <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
              <div className="certificate__content__data-wrapper__label">
                Date of Birth
              </div>
              <div className="certificate__content__data-wrapper__data">
                <Text bold color="black">
                  {moment
                    .utc(props.certificate.dateOfBirth)
                    .format('DD-MM-YYYY')}
                </Text>
              </div>
            </div>
          )}
        </div>
        {props.certificate.ssn && (
          <div className="certificate__content__row">
            <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
              <div className="certificate__content__data-wrapper__label">
                Date of Birth
              </div>
              <div className="certificate__content__data-wrapper__data">
                <Text bold color="black">
                  {moment
                    .utc(props.certificate.dateOfBirth)
                    .format('DD-MM-YYYY')}
                </Text>
              </div>
            </div>

            <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
              <div className="certificate__content__data-wrapper__label">
                Social Security No.
              </div>
              <div className="certificate__content__data-wrapper__data">
                <Text bold color="black">
                  {props.certificate.ssn}
                </Text>
              </div>
            </div>
          </div>
        )}

        {props.certificate.certificateInformation && (
          <div className="certificate__content__row">
            <div className="certificate__content__data-wrapper">
              {props.certificate.version === '1' ? (
                <RichTextEditor
                  editorState={EditorState.createWithContent(
                    convertFromRaw(
                      JSON.parse(props.certificate.certificateInformation)
                    )
                  )}
                  viewer={true}
                  readOnly={true}
                />
              ) : (
                <div className="certificate__content__data-wrapper__label">
                  <pre
                    style={{
                      whiteSpace: 'pre-line',
                      fontFamily: 'inherit',
                      margin: 0,
                    }}
                  >
                    {props.certificate.certificateInformation}
                  </pre>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="certificate__content__row">
          <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
            <div className="certificate__content__data-wrapper__label">
              Issue date
            </div>
            <div className="certificate__content__data-wrapper__data">
              <Text bold color="black">
                {moment.utc(props.certificate.issuedAt).format('DD-MM-YYYY')}
              </Text>
            </div>
          </div>

          <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
            <div className="certificate__content__data-wrapper__label">
              Expiry date
            </div>
            <div className="certificate__content__data-wrapper__data">
              <Text bold color="black">
                {props.certificate.validUntil === '4100716800' ||
                props.certificate.validUntil === '32501952000'
                  ? 'Unlimited'
                  : moment
                      .utc(props.certificate.validUntil)
                      .format('DD-MM-YYYY')}
              </Text>
            </div>
          </div>
        </div>

        <div className="certificate__content__row">
          <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
            <div className="certificate__content__data-wrapper__label">
              Document no.
            </div>
            <div className="certificate__content__data-wrapper__data">
              <Text bold color="black">
                {props.certificate.certificateNumber}
              </Text>
            </div>
          </div>
          {props.certificate.courseId && (
            <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
              <div className="certificate__content__data-wrapper__label">
                Course id
              </div>
              <div className="certificate__content__data-wrapper__data">
                <Text bold color="black">
                  {props.certificate.courseId}
                </Text>
              </div>
            </div>
          )}
        </div>
        {props.certificate.courseStart && props.certificate.courseEnd && (
          <div className="certificate__content__row">
            <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
              <div className="certificate__content__data-wrapper__label">
                Course start
              </div>
              <div className="certificate__content__data-wrapper__data">
                <Text bold color="black">
                  {moment
                    .utc(props.certificate.courseStart)
                    .format('DD-MM-YYYY')}
                </Text>
              </div>
            </div>
            <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
              <div className="certificate__content__data-wrapper__label">
                Course end
              </div>
              <div className="certificate__content__data-wrapper__data">
                <Text bold color="black">
                  {moment.utc(props.certificate.courseEnd).format('DD-MM-YYYY')}
                </Text>
              </div>
            </div>
          </div>
        )}

        <div className="certificate__content__location-row">
          {props.certificate.trainingProviderAddress &&
            props.certificate.trainingProviderCity &&
            props.certificate.trainingProviderCountry && (
              <div className="certificate__content__data-wrapper certificate__content__data-wrapper--location">
                {!props.certificate.locationImage && (
                  <div className="certificate__content__data-wrapper__label">
                    Location
                  </div>
                )}
                <div className="certificate__content__data-wrapper__data">
                  <Text bold color="black" size="small">
                    {props.certificate.trainingProviderAddress},{' '}
                    {props.certificate.trainingProviderCity},{' '}
                    {props.certificate.trainingProviderCountry}
                  </Text>
                </div>
              </div>
            )}
          {props.certificate.trainingProviderAddress &&
            !props.certificate.trainingProviderCity &&
            !props.certificate.trainingProviderCountry && (
              <div className="certificate__content__data-wrapper certificate__content__data-wrapper--location">
                <div className="certificate__content__data-wrapper__label">
                  Location
                </div>
                {props.certificate.locationImage ? (
                  <div className="certificate__content__data-wrapper__data certificate__content__data-wrapper__data--small">
                    <img
                      className="certificate__content__data-wrapper__location-image"
                      src={props.certificate.locationImage}
                      alt="locationImage"
                    />
                    <Text bold color="black" size="small">
                      {props.certificate.trainingProviderAddress}
                    </Text>
                  </div>
                ) : (
                  <div className="certificate__content__data-wrapper__data">
                    <Text bold color="black" size="small">
                      {props.certificate.trainingProviderAddress}
                    </Text>
                  </div>
                )}
              </div>
            )}
          {props.certificate.location2 && (
            <div className="certificate__content__data-wrapper certificate__content__data-wrapper--location">
              <div className="certificate__content__data-wrapper__label">
                Additional endorsement
              </div>
              {props.certificate.additionalImage && (
                <img
                  className="certificate__content__data-wrapper__location-image"
                  src={props.certificate.additionalImage}
                  alt="Additional"
                />
              )}
              <div className="certificate__content__data-wrapper__data">
                <Text bold color="black" size="small">
                  {props.certificate.location2}
                </Text>
              </div>
            </div>
          )}
        </div>

        <div className="certificate__content__row">{renderSigners()}</div>
        {props.showQrCode && (
          <div className="certificate__content__row certificate__content__row--mb0">
            <img
              className="certificate__content__data-wrapper__qr-code"
              src={props.certificate.qrCodeImage}
              alt="qrcode"
            />
          </div>
        )}
        {/* <Text size="tiny" className="certificate__footer">
          Powered by Trustroot
        </Text> */}
      </div>
      {props.certificate.watermarkImage && (
        <img
          className="certificate__watermark"
          src={props.certificate.watermarkImage}
          alt="watermark"
        />
      )}
    </div>
  );
};

export default withRouter(Cerficate);
