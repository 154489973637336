import axios from './axiosInstance';

const methods = {
  async deleteConsumable(consumableId) {
    return axios.delete(`/consumables/${consumableId}`);
  },

  async createConsumable(payload) {
    return axios.post('/consumables', payload);
  },

  async updateConsumable(consumableId, payload) {
    return axios.put(`/consumables/${consumableId}`, payload);
  },

  async getConsumables(filters) {
    return axios.get('/consumables', { params: { ...filters, limit: 10 } });
  },

  async getConsumable(consumableId) {
    return axios.get(`/consumables/${consumableId}`);
  },
};

export default methods;
