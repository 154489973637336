import gql from 'graphql-tag';

export const getCertificateTemplatesByClientId = gql`
  query certificateTemplatesByClientId($clientId: ID!) {
    certificateTemplatesByClientId(clientId: $clientId) {
      id
      name
      adminId
      imageId
      clientId
      trainingProviderAddress
      trainingProviderCity
      trainingProviderZip
      trainingProviderCountry
      location2
      logoImage
      certificateInformation
      watermarkImage
      hasSSN
      hasExpiration
      hasCourseDates
      certificateName
      locationImage
      instructorAutofill
      additionalImage
      stampImage
      version
      type
      certType
      telephone
      additionalData
      telefax
      accreditationNo
      email
      signers {
        name
        role
        image
      }
      paperSize
    }
  }
`;

export const getCertificateTemplateById = gql`
  query certificateTemplate($id: ID!) {
    certificateTemplate(id: $id) {
      id
      name
      adminId
      imageId
      clientId
      trainingProviderAddress
      trainingProviderCity
      trainingProviderZip
      trainingProviderCountry
      location2
      logoImage
      certificateInformation
      watermarkImage
      hasSSN
      hasExpiration
      hasCourseDates
      certificateName
      locationImage
      instructorAutofill
      additionalImage
      stampImage
      version
      type
      certType
      telephone
      additionalData
      telefax
      accreditationNo
      email
      signers {
        name
        role
        image
      }
      paperSize
    }
  }
`;

export const getCertTemplateImages = gql`
  query getCertTemplateImages($clientId: ID!) {
    getCertTemplateImages(clientId: $clientId) {
      imageId
      type
    }
  }
`;
