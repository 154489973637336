import { apolloClient } from '../apollo/apollo';
import {
  getUserByEmail,
  searchUser,
  getUserByID,
  getUsersByClientId,
} from './graphql/queries/users';

export default {
  async getUsersByClientId(clientId) {
    return apolloClient.query({
      query: getUsersByClientId,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
      },
    });
  },

  async getUserByID(id) {
    return apolloClient.query({
      query: getUserByID,
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    });
  },

  async getUserByEmail(email) {
    return apolloClient.query({
      query: getUserByEmail,
      fetchPolicy: 'no-cache',
      variables: {
        email,
      },
    });
  },

  async searchUser(email) {
    return apolloClient.query({
      query: searchUser,
      fetchPolicy: 'no-cache',
      variables: {
        email,
      },
    });
  },
};
