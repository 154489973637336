import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    width="inherit"
    height="inherit"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    {...props}
  >
    <g>
      <path
        d="m19.535 3.622-1.656-1.658A4.967 4.967 0 0 0 14.343.5H8a5.006 5.006 0 0 0-5 5v14a5.006 5.006 0 0 0 5 5h8a5.006 5.006 0 0 0 5-5V7.157a4.968 4.968 0 0 0-1.465-3.535Zm-1.414 1.414c.142.141.269.297.379.464H16V3a3.1 3.1 0 0 1 .465.38l1.656 1.656ZM19 19.5a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3v-14a3 3 0 0 1 3-3h6v3a2 2 0 0 0 2 2h3v12Zm-3-10a1 1 0 1 1 0 2H8a1 1 0 0 1 0-2h8Zm1 5a1 1 0 0 1-1 1H8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1Zm-.192 3.413a1 1 0 0 1-.217 1.394 6.465 6.465 0 0 1-3.456 1.193 3.252 3.252 0 0 1-2-.7c-.328-.225-.453-.3-.7-.3a3.95 3.95 0 0 0-1.832.794 1 1 0 0 1-1.214-1.588 5.86 5.86 0 0 1 3.05-1.206c.666.01 1.31.24 1.832.655.238.214.544.336.864.345a4.587 4.587 0 0 0 2.277-.809 1 1 0 0 1 1.396.222Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill={props.color}
          transform="translate(0 .5)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
