import React, { useState, useEffect, useRef } from 'react';
import isEmailFn from './isEmail';

import './multiEmailInput.scss';

const MultiEmailInput = (props) => {
  const emailInputRef = useRef();

  const [focused, setFocused] = useState(false);
  const [emails, setEmails] = useState(props.emails || []);
  const [propsEmails, setPropsEmails] = useState(props.emails || []);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (props.emails !== props.propsEmails) {
      setFocused(false);
      setFocused(false);
      setEmails(props.emails || []);
      setPropsEmails(props.emails || []);
      setInputValue('');
    }
  }, [props.emails, propsEmails]);

  // useEffect(() => {
  //   props.onChange(emails);
  // }, [emails])

  const findEmailAddress = (value, isEnter) => {
    let validEmails = [];
    let inputValue = '';
    const re = /[ ,;]/g;
    const isEmail = isEmailFn;

    const addEmails = (email) => {
      for (let i = 0, l = emails.length; i < l; i++) {
        if (emails[i] === email) {
          return false;
        }
      }
      validEmails.push(email);
      return true;
    };

    if (value !== '') {
      if (re.test(value)) {
        let splitData = value.split(re).filter((n) => {
          return n !== '' && n !== undefined && n !== null;
        });

        const setArr = new Set(splitData);
        let arr = [...setArr];

        do {
          if (isEmail('' + arr[0])) {
            addEmails('' + arr.shift());
          } else {
            if (arr.length === 1) {
              /// 마지막 아이템이면 inputValue로 남겨두기
              inputValue = '' + arr.shift();
            } else {
              arr.shift();
            }
          }
        } while (arr.length);
      } else {
        if (isEnter) {
          if (isEmail(value)) {
            addEmails(value);
          } else {
            inputValue = value;
          }
        } else {
          inputValue = value;
        }
      }
    }

    setEmails([...emails, ...validEmails]);
    setInputValue(inputValue);

    if (validEmails.length && props.onChange) {
      props.onChange([...emails, ...validEmails]);
    }
  };

  const onChangeInputValue = (value) => findEmailAddress(value);

  const removeEmail = (index, isDisabled) => {
    if (isDisabled) {
      return;
    }
    const results = [...emails.slice(0, index), ...emails.slice(index + 1)];
    setEmails(results);
    props.onChange(results);
  };

  const handleOnKeydown = (e) => {
    switch (e.which) {
      case 13:
      case 9:
        e.preventDefault();
        break;
      case 8:
        if (!e.currentTarget.value) {
          removeEmail(emails.length - 1, false);
        }
        break;
      default:
    }
  };

  const handleOnKeyup = (e) => {
    switch (e.which) {
      case 13:
      case 9:
        findEmailAddress(e.currentTarget.value, true);
        break;
      default:
    }
  };

  const handleOnChange = (e) => onChangeInputValue(e.currentTarget.value);

  const handleOnBlur = (e) => {
    setFocused(false);
    findEmailAddress(e.currentTarget.value, true);
  };

  const handleOnFocus = () => {
    setFocused(true);
  };

  return (
    <div
      className={`${props.className} ${
        props.noClass ? '' : 'react-multi-email'
      } ${focused ? 'focused' : ''} ${
        inputValue === '' && emails.length === 0 ? 'empty' : ''
      }`}
      style={props.style}
      onClick={() => {
        if (emailInputRef.current) {
          emailInputRef.current.focus();
        }
      }}
    >
      {props.placeholder ? (
        <span data-placeholder>{props.placeholder}</span>
      ) : null}
      {emails.map((email, index) => props.getLabel(email, index, removeEmail))}
      <input
        ref={emailInputRef}
        type="text"
        value={inputValue}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onKeyDown={handleOnKeydown}
        onKeyUp={handleOnKeyup}
      />
    </div>
  );
};

export default MultiEmailInput;
