import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Button as IButton } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import AssignStaffModal from '../../webAppointments/overview/components/assignStaffModal/AssignStaffModal';
import PatientReactSession from './components/PatientReactSessions';
import DeleteModal from './components/DeleteModal/DeleteModal';

import reactSessionApi from 'api/reactSession';

import {
  Text,
  Button,
  ErrorToast,
  SuccessToast,
  Icon as LIcon,
} from 'components';

import './reactHealthDetails.scss';

const ReactHealthDetails = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [session, setSession] = useState({});
  const [isStaffModalToggled, toggleStaffModal] = useState(false);
  const [isSavingStaff, setSavingStaff] = useState(false);
  const [isToggleDeleteModal, setToggleDeleteModal] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      await fetchSessionDetails();
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSessionDetails = async () => {
    const session = await reactSessionApi.fetchReactSessionById(
      props.match.params.id
    );
    setSession(session.data);
  };

  const onAssignStaffResponsible = async (staffId, staffData) => {
    try {
      setSavingStaff(true);
      await reactSessionApi.assignStaff(props.match.params.id, {
        name: staffData.fullName,
        id: staffData.id,
      });
      await fetchSessionDetails();
      setSavingStaff(false);
      toast.success(<SuccessToast error="Staff assigned" />);
      toggleStaffModal(false);
    } catch (error) {
      setSavingStaff(false);
      console.log(error);
      toast.error(<ErrorToast message="Failed to assign staff" />);
    }
  };

  const onCancelSession = async () => {
    try {
      setIsCancelling(true)
      await reactSessionApi.updateReactSession(props.match.params.id, { status: 'cancelled' })
      await fetchSessionDetails();
      setToggleDeleteModal(false);
    } catch(error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsCancelling(false)
    }
  }

  return (
    <div className="rhd">
      {isToggleDeleteModal && (
        <DeleteModal
          hide={setToggleDeleteModal}
          show={isToggleDeleteModal}
          confirmDelete={onCancelSession}
          isDeleting={isCancelling}
        />
      )}
      {isStaffModalToggled && (
        <AssignStaffModal
          show={isStaffModalToggled}
          hide={(e) => toggleStaffModal(e)}
          onAssign={onAssignStaffResponsible}
          isSaving={isSavingStaff}
          header="Assign Staff"
          //   staffId={selectedStaff}
        />
      )}
      <div className="rhd__header">
        <div className="rhd__header--left">
          <Button
            floated="left"
            circular
            icon
            size="medium"
            onClick={() => props.history.goBack()}
            className="appointment-details__back"
            color="blue"
          >
            <LIcon name="arrowLeft" color="white" fontSize="1.3em" />
          </Button>
          <div className="rhd__header__text">
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <Text type="pageHeader" className="rhd__header__text__h">
                {/* ReACT Session */}
                {session.name}
              </Text>
              {session?.externalId && (
                <Text size="big" className="ml-2">
                  #{session.externalId}
                </Text>
              )}
            </div>
            {session.status && (
              <Text
                color="white"
                bold
                size="tiny"
                uppercase
                className={`rhd__header__text__status rhd__header__text__status--${session.status}`}
              >
                {session.status}
              </Text>
            )}
          </div>
        </div>
        {session.status !== 'cancelled' && (
          <Button
            inverted
            color="red"
            textId="react_single.cancel_session"
            onClick={() => setToggleDeleteModal(true)}
          >
            Cancel Session
          </Button>
        )}
      </div>
      <div className="rhd__details-wrapper">
        <div className="rhd__details-wrapper__row">
          {session.name && session.externalId && (
            <div>
              <Text id="react_main.package_name_and_id" type="dataLabel">
                Package Name & ID
              </Text>
              <Text type="data" style={{ display: 'flex' }}>
                {session.name}
                <Text size="tiny" style={{ marginLeft: '5px' }}>
                  #{session.externalId}
                </Text>
              </Text>
            </div>
          )}
          {session.name && !session.externalId && (
            <div>
              <Text id="react_grp.package_name" type="dataLabel">
                Package Name
              </Text>
              <Text type="data">{session.name}</Text>
            </div>
          )}
          {/* {session.status && (
            <div>
              <Text type="dataLabel">Status</Text>
              <Text
                color="white"
                bold
                size="tiny"
                uppercase
                className={`rhd__header__text__status rhd__header__text__status--${session.status}`}
              >
                {session.status}
              </Text>
            </div>
          )} */}
          {session.date && (
            <div>
              <Text id="react_grp.app_date_time" type="dataLabel">
                Appt Date & Time
              </Text>
              <Text type="data">
                {moment(session.date).format('DD-MM-YYYY HH:MM A')}
              </Text>
            </div>
          )}
          {session.company && (
            <div>
              <Text id="react_main.company" type="dataLabel">
                Company
              </Text>
              <Text type="data">{session.company}</Text>
            </div>
          )}
          {session?.location && (
            <div>
              <Text id="react_main.location" type="dataLabel">
                Location
              </Text>
              <Text type="data">{session.location}</Text>
            </div>
          )}
          {session?.staff?.name && (
            <div>
              <Text id="react_main.assign_staff" type="dataLabel">
                Assigned Staff
              </Text>
              {/* <Text type="data">{session.staff.name}</Text> */}
              <IButton
                className="rhd__assign-button"
                size="small"
                onClick={(e) => toggleStaffModal(true)}
                animated
              >
                <IButton.Content visible>
                  {session?.staff?.name || 'Not assigned'}
                </IButton.Content>
                <IButton.Content hidden>
                  <Text id="react_main.assign_staff">Assign Staff</Text>
                </IButton.Content>
              </IButton>
            </div>
          )}
        </div>
      </div>
      <PatientReactSession session={session} />
    </div>
  );
};

export default withRouter(ReactHealthDetails);
