import React from 'react';

import { Text } from 'components';

import '../WebshopPromotion/webshopPromotion.scss';

const PromotionCard = ({
  parent,
  className,
  title,
  desc,
  primaryImage,
  secondaryImage,
}) => {
  return (
    <div className={`${parent}-promo__body__card ${className}`}>
      <div className={`${parent}-promo__body__card__content ${className}`}>
        <Text dark size="big" className="mb-1">
          {title}
        </Text>
        <Text size="medium">{desc}</Text>
      </div>
      <div
        className={`${parent}-promo__body__card__images-container ${className}`}
      >
        <img
          className={`${parent}-promo__body__card__image primary ${className}`}
          src={primaryImage}
        />
        <img
          className={`${parent}-promo__body__card__image secondary ${className}`}
          src={secondaryImage}
        />
      </div>
    </div>
  );
};

export default PromotionCard;
