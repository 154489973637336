import React from 'react';

const SvgAngleLeft = props => (
  <svg width="1em" height="1em" viewBox="0 0 19 29" {...props}>
    <path
      d="M13.5 24.5l-8.398-9.331a1 1 0 010-1.338L13.5 4.5"
      stroke="#1b1b1b"
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
);

export default SvgAngleLeft;
