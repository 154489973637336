import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router';
import { toast } from 'react-toastify';
import moment from 'moment';

import { ErrorToast, SuccessToast } from 'components';

import PatientSignupV1 from '../patientSignupV1/PatientSignupV1';
import ReactHealthQuestions from '../ReactHealthQuestions/ReactHealthQuestions';

import medicalProfileApi from 'api/patientOverview/medicalProfileApi';

import history from '../../../history';

import './patientSignupV1Wrapper.scss';

const PatientSignupV1Wrapper = () => {
  const [details, updateDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [createUserLoading, setCreateUserLoading] = useState(false);

  useEffect(() => {
    setLocal();
  }, []);

  useEffect(() => {
    localStorage.setItem('RIV-REG-F', JSON.stringify(details));
  }, [details]);

  const setLocal = async () => {
    const localDetails = await localStorage.getItem('RIV-REG-F');
    if (localDetails) updateDetails(JSON.parse(localDetails));
    // little hack, remove when possible
    setTimeout(() => setIsLoading(false), 100);
  };

  const createUser = async () => {
    // shareDetails = if they chose to accept the emergency contact sharing
    setCreateUserLoading(true);
    try {
      const data = details;
      data.dateOfBirth = moment(data.dateOfBirth, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
      );
      await medicalProfileApi.signupPatient(details);
      toast.success(<SuccessToast message="Account created!" />);
      history.push(`/p/success/${data.email}`);
    } catch (error) {
      console.log(error);
      toast.error(<ErrorToast error={error} />);
    } finally {
      setCreateUserLoading(false);
    }
  };

  return (
    <div className="p-signup-w">
      <div className="p-signup-w__content">
        {!isLoading && (
          <div>
            <Switch>
              {/* <Route
                path="/p/create-account-v1/react-health"
                exact
                render={() => (
                  <ReactHealthQuestions
                    details={details}
                    updateDetails={updateDetails}
                  />
                )}
              /> */}
              <Route
                path="/p/create-account-v1"
                render={() => (
                  <PatientSignupV1
                    createUserLoading={createUserLoading}
                    createUser={createUser}
                    details={details}
                    updateDetails={updateDetails}
                  />
                )}
              />
            </Switch>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientSignupV1Wrapper;
