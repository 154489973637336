import { fhirClient } from '../fhirClient';
import axiosInTstance from '../../axiosInstance';

const defaultSearchParams = {
  page: 1,
  limit: 20,
};

async function getResults(searchParams = defaultSearchParams) {
  const {
    page = 0,
    limit = 20,
    client,
    searchTerm,
    testStatus,
    patientId,
  } = { ...defaultSearchParams, ...searchParams };

  const query = { page, limit };

  if (client) {
    query.client = client;
  }

  if (patientId) {
    query.patientId = patientId;
  }

  if (testStatus) {
    query.testStatus = testStatus;
  }

  if (searchTerm && searchTerm.trim().length) {
    query.searchTerm = searchTerm;
  }

  try {
    const results = await fhirClient.get('/results', {
      params: query,
    });
    return results;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const postFhirBundleResult=async(data)=>{
  try {
    const results = await axiosInTstance.post('/result/fhir', data);
    return results.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const fhirResultApi = {
  getResults,
  postFhirBundleResult,
};

export default fhirResultApi;
