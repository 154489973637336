import { apolloClient } from '../apollo/apollo';
import {
  getLabResults,
  getLabResult,
  getLabResultPublic,
  getLabResultsCount,
  getLabResultsCountInMonth,
  getIssuedLabResultsRange,
} from './graphql/queries/labResults';
import {
  setLabResults,
  updateLabResult,
} from './graphql/mutations/labResults';

export default {
  async getLabResults({
    clientId,
    clinicId,
    filter,
    cursor,
    search,
    result,
    fromDate,
    toDate,
    type,
  }) {
    return apolloClient.query({
      query: getLabResults,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        clinicId,
        filter,
        cursor,
        result,
        search,
        fromDate,
        toDate,
        type,
      },
    });
  },

  async getLabResult(id, filter) {
    return apolloClient.query({
      query: getLabResult,
      fetchPolicy: 'no-cache',
      variables: { id, filter },
    });
  },

  async getLabResultPublic(id, filter) {
    return apolloClient.query({
      query: getLabResultPublic,
      fetchPolicy: 'no-cache',
      variables: { id, filter },
    });
  },

  async getIssuedLabResultsRange(clientId, startDate, endDate) {
    return apolloClient.query({
      query: getIssuedLabResultsRange,
      fetchPolicy: 'no-cache',
      variables: { clientId, startDate, endDate },
    });
  },

  async getLabResultsCount({ clientId, clinicId, filter }) {
    return apolloClient.mutate({
      mutation: getLabResultsCount,
      variables: { clientId, clinicId, filter },
    });
  },

  async setLabResults(clientId, clinicId, results) {
    return apolloClient.mutate({
      mutation: setLabResults,
      variables: { clientId, clinicId, results },
    });
  },

  async updateLabResult(id, result) {
    return apolloClient.mutate({
      mutation: updateLabResult,
      variables: { id, result },
    });
  },

  async getLabResultsCountInMonth(
    clientId,
    startMonth,
    endMonth,
    subType,
    isClientGroup
  ) {
    return apolloClient.query({
      query: getLabResultsCountInMonth,
      fetchPolicy: 'no-cache',
      variables: { clientId, startMonth, endMonth, subType, isClientGroup },
    });
  },
};
