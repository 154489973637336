// import * as React from 'react';

// function ShieldPlus(props) {
//   return (
//     <svg width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
//       <path
//         d="M21.25 13.75c.425 0 .837.05 1.25.112V9.475c0-1-.587-1.9-1.5-2.288l-6.875-3a2.527 2.527 0 00-2 0l-6.875 3c-.912.4-1.5 1.3-1.5 2.288v4.5c0 5.675 4 10.987 9.375 12.275.688-.163 1.35-.4 2-.688a7.473 7.473 0 01-1.375-4.312c0-4.138 3.363-7.5 7.5-7.5z"
//         fill="#1b1b1b"
//       />
//       <path
//         d="M20 16.834A4.168 4.168 0 0015.833 21c0 2.3 1.867 4.167 4.167 4.167S24.167 23.3 24.167 21 22.3 16.834 20 16.834zm-1.13 5.954l-1.495-1.496a.415.415 0 11.587-.588l1.205 1.2 2.866-2.866a.415.415 0 11.588.587l-3.163 3.163a.415.415 0 01-.587 0z"
//         fill="#1b1b1b"
//       />
//     </svg>
//   );
// }

// export default ShieldPlus;

import * as React from 'react';

function ShieldPlus(props) {
  return (
    <svg
      width={27}
      height={27}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.336 18.927H9.912M18.336 14.043H9.912M13.127 9.17H9.912"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M18.56 3.208l-8.97.005c-3.22.02-5.214 2.138-5.214 5.37V19.31c0 3.248 2.009 5.375 5.257 5.375l8.97-.003c3.22-.02 5.215-2.14 5.215-5.372V8.583c0-3.248-2.01-5.375-5.258-5.375z"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ShieldPlus;
