import * as yup from 'yup';
import moment from 'moment';

export const schema = yup.object().shape({
  firstName: yup.string().required('required').max(40),
  lastName: yup.string().required('required').max(40),
  gender: yup.string().required('required'),
  dateOfBirth: yup
    .string()
    .test('age should be greater than 18', (val) => {
      const age =
        moment().toDate().getFullYear() -
        moment(val, 'DD-MM-YYYY').toDate().getFullYear();
      return age >= 18;
    })
    .required('required'),
  nationality: yup.string().required('required'),
  idType: yup.string().required('required'),
  idNo: yup.string().min(7).max(22).required('required'),
  // ssn: yup.string().min(7).max(22).required('required'),
  // passNo: yup.string().notRequired().min(7).max(22).nullable().transform((value) => !!value ? value : null),
  address: yup.string().nullable(),
  zip: yup.string().nullable(),
  city: yup.string().nullable(),
  country: yup.string().nullable(),
  phoneNumber: yup.string().nullable(),
  smoker: yup.string().nullable(),
  email: yup.string().email().required('required'),
  ethnicity: yup.string().nullable(),
  employer: yup.string().nullable(),
});
