import React from 'react';
import PropTypes from 'prop-types';

import { CheckBox } from '../../../../../components/';

import './dualCheckbox.scss';

const DualCheckBox = props => {
  return (
    <div className="dual-check">
      <div className="dual-check__label">{props.label}</div>
      <div className="dual-check__checkboxes">
        <CheckBox
          label={props.label1 || 'Yes'}
          onClick={() => props.onSelect(true)}
          checked={props.value}
          style={{ marginBottom: '5px' }}
          labelClassName="dual-check__checkbox-label"
        />
        <CheckBox
          label={props.label2 || 'No'}
          onClick={() => props.onSelect(false)}
          checked={!props.value && props.value !== null}
          labelClassName="dual-check__checkbox-label"
        />
      </div>
    </div>
  );
};

DualCheckBox.propTypes = {
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
};

DualCheckBox.defaultProps = {
  onClick: () => {},
  checked: true,
  label: '',
};

export default DualCheckBox;
