import { SET_SELECTED_SECTION } from './certificateManagement.actions';

const initialState = {
  selectedSection: '/certificate',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_SECTION:
      return { ...state, selectedSection: action.payload };

    default:
      return state;
  }
};
