import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Icon, Dropdown } from 'semantic-ui-react';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
import moment from 'moment';

import AssignStaffModal from '../../webAppointments/overview/components/assignStaffModal/AssignStaffModal';
import TableRow from './TableRow';
import PatientModal from './components/PatientModal/PatientModal';
import BulkCreateModal from './components/BulkCreateModal/BulkCreateModal';

import {
  Text,
  Search,
  Button,
  ErrorToast,
  DatePicker,
  Select,
  CheckBox,
  SuccessToast,
} from 'components';

import crossSvg from 'assets/new/blackThickCross.svg';

import sessionApi from 'api/reactSession';
// import patientReactSessionApi from 'api/patientReactSession';

import history from '../../../history';

import './reactHealthOverview.scss';

const allTestStatus = [
  {
    text: 'All ',
    key: 'all',
    value: 'all',
  },
  {
    text: 'Planned ',
    key: 'planned',
    value: 'planned',
  },
  {
    text: 'In Progress',
    key: 'in-progress',
    value: 'in-progress',
  },
  {
    text: 'Completed',
    key: 'completed',
    value: 'completed',
  },
  {
    text: 'Cancelled',
    key: 'cancelled',
    value: 'cancelled',
  },
];

const ReactHealthOverview = (props) => {

  let urlSearchParams = new URLSearchParams(window.location.search);
    const defaultSearchParams = {
      limit: Number(urlSearchParams.get(`r-limit`)) || 20,
      page: Number(urlSearchParams.get(`r-page`)) || 1,
      searchTerm: urlSearchParams.get(`r-search-term`) || '',
      fromDate:
        (urlSearchParams.get(`r-from-date`) &&
          new Date(urlSearchParams.get(`r-from-date`))) ||
        '',
      toDate:
        (urlSearchParams.get(`r-to-date`) &&
          new Date(urlSearchParams.get(`r-to-date`))) ||
        '',
      status: urlSearchParams.get('r-status') || 'all',
      sessionType: urlSearchParams.get('r-session-type') || 'single',
      clients: urlSearchParams.get(`r-clients`)?.split(',') || [],
    };

  const { clinicOptions } = useSelector((state) => state.general);
  const { clientId } = useSelector((state) => state.general.clientInformation);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const filterDropdownRef = useRef();

  const [sessions, setSessions] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isStaffModalToggled, toggleStaffModal] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({});
  const [selectedClinics, setSelectedClinics] = useState([clientId]);
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const [isShowFilters, setShowFilters] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [isSavingStaff, setSavingStaff] = useState(false);
  const [isPatientModalToggled, togglePatientModal] = useState(false);
  const [isBulkModalToggled, toggleBulkModal] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [sessionType, setSessionType] = useState(defaultSearchParams.sessionType || 'single');
  const [isExternal, setIsExternal] = useState(false);

  const handleSearch = useCallback(
    debounce((params) => {
      fetchSessions(params);
    }, 500),
    [sessionType, paginationInfo]
  );

  useEffect(() => {
    const path = history.location.pathname;
    if(path.includes('/external/')) setIsExternal(true);

    const rhToggle = localStorage.getItem('RH-TG', sessionType);

    if (rhToggle) {
      setSessionType(rhToggle);
    }
  }, []);

  useEffect(() => {
    handleSearch(searchParams);
  }, [searchParams]);

  useEffect(() => {
    if (props.location?.state?.patient) {
      togglePatientModal(true);
    }
  }, [props.location?.state?.patient]);

  useEffect(() => {
    setSessions([]);
    localStorage.setItem('RH-TG', sessionType);
  }, [sessionType]);

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        !filterDropdownRef.current ||
        filterDropdownRef.current.contains(event.target)
      ) {
        return;
      }
      setShowFilters(false);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [filterDropdownRef, setShowFilters]);

  const updateSearchParams = (params) => {
    let selectedClinicz = [...params.clients];
    if (params.clients.length === 0) selectedClinicz = [clientId];
    // selectedClinicz = clinicOptions.map((clinic) => clinic.value);
    const updatedSearchParams = {
      ...params,
      page: 0,
      clients: selectedClinicz,
    };
    setSearchParams(updatedSearchParams);
    let url = new URL(window.location.href);
    let searchParams = url.searchParams;

    if (JSON.stringify(searchParams) !== JSON.stringify(updatedSearchParams)) {
      updatedSearchParams.limit &&
        searchParams.set(`r-limit`, updatedSearchParams.limit);
      updatedSearchParams.page &&
        searchParams.set(`r-page`, updatedSearchParams.page);
      searchParams.set(`r-search-term`, updatedSearchParams.searchTerm || '');
      if (params.fromDate) {
        searchParams.set(`r-from-date`, moment(params.fromDate).format('LL'));
      } else {
        searchParams.delete(`r-from-date`);
      }
      if (params.toDate) {
        searchParams.set(`r-to-date`, moment(params.toDate).format('LL'));
      } else {
        searchParams.delete(`r-to-date`);
      }
      updatedSearchParams.status &&
        searchParams.set(`r-status`, updatedSearchParams.status);
      const filteredClients = updatedSearchParams?.clients?.filter(
        (client) => client !== clientId
      );
      filteredClients?.length > 0
        ? searchParams.set(`r-clients`, filteredClients)
        : searchParams.delete(`r-clients`);
      updatedSearchParams.sessionType &&
        searchParams.set(`r-session-type`, updatedSearchParams.sessionType);
      window.history.replaceState({ path: url.toString() }, '', url.toString());
    }
  };

  const onSearchChange = (search) => {
    updateSearchParams({ ...searchParams, searchTerm: search });
  };

  const fetchSessions = async (searchParams = {}) => {
    try {
      setSearching(true);
      setPaginationInfo({});
      const payload = {
        ...searchParams,
        fromDate: searchParams.fromDate
          ? moment(searchParams.fromDate).startOf('day').toISOString()
          : null,
        toDate: searchParams.toDate
          ? moment(searchParams.toDate).endOf('day').toISOString()
          : null,
        type: sessionType,
      };

      if (payload.status === 'all') delete payload.status;
      if (!!payload.clients?.length) {
        let clientsFilter = payload.clients.filter(function (el) {
          return el != null;
        })
        payload.clients = clientsFilter;
      }
      const sessionsResponseData = await sessionApi.fetchReactSessions(payload);

      if (sessionsResponseData?.data) {
        const { page, totalPages, limit, totalResults } = sessionsResponseData.data;
        if (paginationInfo.page < page)
          setSessions([...sessions, ...sessionsResponseData.data.results]);
        else setSessions(sessionsResponseData.data.results);
        setPaginationInfo({ page, totalPages, limit, totalResults });
      }
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setSearching(false);
    }
  };

  const onRowClick = (e, session) => {
    const baseUrl = isExternal ? '/external/react-health' : '/react-health';
    if (e.metaKey || e.ctrlKey)
      return window.open(`${baseUrl}/${session.id}`, '_blank');

    return history.push({
      pathname: `${baseUrl}/${session.id}`,
      state: { session },
    });
  };

  const onClickMoreData = async () => {
    setSearching(true);
    const updatedSearchParams = {
      ...searchParams,
      fromDate: searchParams.fromDate
        ? moment(searchParams.fromDate).startOf('day').toISOString()
        : null,
      toDate: searchParams.toDate
        ? moment(searchParams.toDate).endOf('day').toISOString()
        : null,
      page: paginationInfo.page + 1,
    };
    setSearchParams(updatedSearchParams);
    setSearching(false);
  };

  const selectAllRows = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let rows;

    if (Object.keys(selectedRows)?.length === sessions?.length) {
      setSelectedRows({});
      rows = {};
    } else {
      rows = {};
      sessions.forEach((element) => {
        rows[element.id] = element;
      });
      setSelectedRows(rows);
    }
  };

  const onRowSelect = (e, id, patient) => {
    e.stopPropagation();
    e.preventDefault();

    if (selectedRows[id]) delete selectedRows[id];
    else
      selectedRows[id] = {
        patient,
      };

    setSelectedRows({ ...selectedRows });
  };

  const onCreateTestOrderClick = () => {
    history.push({
      pathname: isExternal ? '/external/patients' : '/patients',
      state: { isReactSessionFlow: true },
    });
  };

  const onAssignStaffResponsible = async (stadffId, staffData) => {
    try {
      setSavingStaff(true);
      await sessionApi.assignStaff(selectedSession, {
        name: staffData.fullName,
        id: staffData.id,
      });
      await handleSearch(searchParams);
      setSavingStaff(false);
      toast.success(<SuccessToast message="Staff assigned" />);
      toggleStaffModal(false);
    } catch (error) {
      setSavingStaff(false);
      console.log(error);
      toast.error(<ErrorToast message="Failed to assign staff" />);
    }
  };

  const onAssignClick = (e, id) => {
    e.stopPropagation();
    toggleStaffModal(true);
    setSelectedSession(id);
  };

  const createReactSession = async (data) => {
    try {
      await sessionApi.createReactSession(data);
      await handleSearch(searchParams);
      togglePatientModal(false);
    } catch (error) {
      toast.error(<ErrorToast message="Error creating session" />);
    }
  };

  // const markAsReviewed = async () => {
  //   try {
  //     const sessionIds = Object.keys(selectedRows);
  //     if (sessionIds.length === 0) return;
  //     await sessionApi.updateBulkSessionsAsReviewed(sessionIds);
  //     toast.success(<ErrorToast message="Marked as reviewed" />);
  //   } catch (error) {
  //     toast.error(<ErrorToast error={error} />);
  //   }
  // };

  // const bulkIssueReports = async () => {
  //   try {
  //     const sessionIds = Object.keys(selectedRows);
  //     if (sessionIds.length === 0) return;

  //     await Promise.all(
  //       sessionIds.map((id) => patientReactSessionApi.issueReport(id))
  //     );
  //     toast.success(<SuccessToast message="ReACT Reports issued" />);
  //   } catch (error) {
  //     toast.error(<ErrorToast error={error} />);
  //   }
  // };

  // const bulkDownloadReports = async () => {
  //   try {
  //     const sessionIds = Object.keys(selectedRows);
  //     if (sessionIds.length === 0) return;
  //   } catch (error) {
  //     toast.error(<ErrorToast message="Error downloading reports" />);
  //   }
  // };

  return (
    <div className="rho">
      {isPatientModalToggled && (
        <PatientModal
          show={isPatientModalToggled}
          hide={(e) => togglePatientModal(e)}
          patient={props.location?.state?.patient}
          onCreate={createReactSession}
        />
      )}
      {isBulkModalToggled && (
        <BulkCreateModal
          show={isBulkModalToggled}
          hide={(e) => toggleBulkModal(e)}
          fetchSessions={() => handleSearch(searchParams)}
        />
      )}
      {isStaffModalToggled && (
        <AssignStaffModal
          show={isStaffModalToggled}
          hide={(e) => toggleStaffModal(e)}
          onAssign={onAssignStaffResponsible}
          isSaving={isSavingStaff}
          header={
            <Text id="react_main.assign_staff" bold>
              Assign Staff
            </Text>
          }
          //   staffId={selectedStaff}
        />
      )}
      <div className="rho__search">
        {!isMobile && (
          <div className="rho__search__section">
            {/* <Button
            color="blue"
            disabled={Object.keys(selectedRows).length === 0}
            onClick={() => toggleModal(true)}
          >
            Send lab report
          </Button> */}
            <Dropdown
              floating
              direction="right"
              icon={null}
              trigger={
                <div className="rho__dropdown">
                  <Button color="blue">
                    <Text id="react_main.actions" color="inherit">
                      Actions
                    </Text>
                    <Icon
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: '5px',
                      }}
                      className="rho__dropdown__icon"
                      name="dropdown"
                    />
                  </Button>
                </div>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => onCreateTestOrderClick()}
                >
                  <img
                    className="rho__dropdown-icon"
                    src={crossSvg}
                    alt="file-check"
                  />
                  <Text id="react_main.new_single_react" size="small" bold>
                    New single ReACT session
                  </Text>
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => toggleBulkModal(true)}
                >
                  <img
                    className="rho__dropdown-icon"
                    src={crossSvg}
                    alt="file-check"
                  />
                  <Text id="react_main.new_group_react" size="small" bold>
                    New group ReACT session
                  </Text>
                </Dropdown.Item>
                {/* {sessionType === 'single' && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => markAsReviewed()}
                    >
                      <img
                        className="rho__dropdown-icon"
                        src={fileCheckSvg}
                        alt="file-check"
                      />
                      <Text size="small" bold>
                        Mark as Reviewed
                      </Text>
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => bulkIssueReports()}
                    >
                      <img
                        className="rho__dropdown-icon"
                        src={airplaneSvg}
                        alt="file-check"
                      />
                      <Text size="small" bold>
                        Issue Report
                      </Text>
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => bulkDownloadReports()}
                    >
                      <img
                        className="rho__dropdown-icon"
                        src={downloadSvg}
                        alt="file-check"
                      />
                      <Text size="small" bold>
                        Download Report
                      </Text>
                    </Dropdown.Item>
                  </>
                )} */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        <div className="rho__search__section">
          <div className="rho__session-type">
            <Text
              size="tinniest"
              color="lightGrey"
              className="rho__search__date-tag"
              id="react_main.select_session_type"
            >
              Select session type
            </Text>
            <Button
              textId="react_main.single_"
              inverted={searchParams.sessionType !== 'single'}
              disabled={isSearching}
              onClick={() => {
                setSessionType('single');
                updateSearchParams({ ...searchParams, sessionType: 'single' });
              }}
              color={searchParams.sessionType === 'single' ? 'blue' : 'grey'}
            >
              Single
            </Button>
            <Button
              textId="react_main.group"
              inverted={searchParams.sessionType !== 'group'}
              disabled={isSearching}
              onClick={() => {
                setSessionType('group');
                updateSearchParams({ ...searchParams, sessionType: 'group' });
              }}
              color={searchParams.sessionType === 'group' ? 'blue' : 'grey'}
            >
              Group
            </Button>
          </div>
          <div className="rho__custom-dropdown">
            <Button
              color="lightGrey"
              onClick={() => setShowFilters(true)}
              inverted
              textId="react_main.filters"
            >
              Filters
              <Icon
                className="rho__dropdown__icon"
                name="filter"
                color="white"
              />
            </Button>
            <div
              className={cx('rho__custom-dropdown__portal', {
                'rho__custom-dropdown__portal--open': isShowFilters,
              })}
              ref={filterDropdownRef}
            >
              <>
                <div style={{ display: 'flex', marginTop: '15px' }}>
                  <div className="mr-3" style={{ position: 'relative' }}>
                    <Text
                      size="tinniest"
                      color="lightGrey"
                      className="rho__search__date-tag"
                      id="react_main.from_"
                    >
                      From
                    </Text>
                    <DatePicker
                      onChange={(date) =>
                        updateSearchParams({
                          ...searchParams,
                          fromDate: date,
                        })
                      }
                      value={searchParams.fromDate}
                      maxDate={searchParams.toDate}
                    />
                  </div>

                  <div style={{ position: 'relative' }}>
                    <Text
                      size="tinniest"
                      color="lightGrey"
                      className="rho__search__date-tag"
                      id="react_main.to"
                    >
                      To
                    </Text>
                    <DatePicker
                      className="lab-documents__date"
                      onChange={(date) =>
                        updateSearchParams({
                          ...searchParams,
                          toDate: date,
                        })
                      }
                      value={searchParams.toDate}
                      minDate={searchParams.fromDate}
                      clearIcon={true}
                    />
                  </div>
                </div>
                {clinicOptions?.length > 0 && (
                  <div style={{ position: 'relative', marginTop: '30px' }}>
                    <Text
                      size="tinniest"
                      color="lightGrey"
                      className="rho__search__date-tag"
                      id="react_main.locations"
                    >
                      Locations
                    </Text>
                    <Select
                      search
                      multiple
                      border
                      size="fullWidth"
                      icon={false}
                      placeholder="All locations"
                      options={clinicOptions}
                      value={searchParams.clients}
                      onChange={(e, { value }) => {
                        setSelectedClinics(value);
                        updateSearchParams({ ...searchParams, clients: value });
                      }}
                    />
                  </div>
                )}
              </>
            </div>
          </div>
          <Search
            isLoading={isSearching}
            size={isMobile ? 'fullWidth' : 'large'}
            value={searchParams.searchTerm}
            dropdown={false}
            onSearchChange={(e, { value }) => onSearchChange(value)}
            isFilter={true}
          />
        </div>
      </div>
      <div className="rho__table-wrapper">
        <Table selectable unstackable striped>
          <Table.Header>
            <Table.Row>
              {!isMobile && (
                <Table.HeaderCell className="rho__th">
                  <div onClick={(e) => selectAllRows(e)}>
                    <CheckBox
                      className="rho__checkbox"
                      checked={
                        Object.keys(selectedRows)?.length === sessions?.length
                      }
                    />
                  </div>
                </Table.HeaderCell>
              )}
              <Table.HeaderCell>
                {sessionType === 'single' ? (
                  <Text id="login.patient" dark size="tiny">
                    Patient
                  </Text>
                ) : (
                  <Text id="react_main.project_id" dark size="tiny">
                    Project ID
                  </Text>
                )}
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text dark size="tiny" id="react_single.package_name">
                  Package Name
                </Text>
              </Table.HeaderCell>
              {sessionType === 'group' && (
                <Table.HeaderCell>
                  <Text id="react_main.company" dark size="tiny">
                    Company
                  </Text>
                </Table.HeaderCell>
              )}
              <Table.HeaderCell>
                <Text id="react_main.location" dark size="tiny">
                  Location
                </Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text id="react_main.app_date_time" dark size="tiny">
                  Appt Date & Time
                </Text>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text id="react_main.staff" dark size="tiny">
                  Staff
                </Text>
              </Table.HeaderCell>
              <>
                {!isMobile ? (
                  <Table.HeaderCell style={{ padding: '6px 10px' }}>
                    <Select
                      border
                      size="fullWidth"
                      placeholder="Filter by status"
                      value={searchParams.status}
                      onChange={(e, { value }) =>
                        updateSearchParams({ ...searchParams, status: value })
                      }
                      search
                      selection
                      options={allTestStatus}
                      selectClassName="sample-collection__select-text"
                    />
                  </Table.HeaderCell>
                ) : (
                  <Table.HeaderCell className="results-table__th">
                    <Text id="patient_overview.status" bold>
                      Status
                    </Text>
                  </Table.HeaderCell>
                )}
              </>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!!sessions?.length &&
              sessions.map((session) => (
                <TableRow
                  session={session}
                  onRowClick={onRowClick}
                  onRowSelect={onRowSelect}
                  selectedRows={selectedRows}
                  isMobile={isMobile}
                  onAssignClick={onAssignClick}
                  sessionType={sessionType}
                />
              ))}
          </Table.Body>
        </Table>
      </div>
      <div>
        {paginationInfo.page &&
          paginationInfo.page < paginationInfo.totalPages && (
          <Button
            icon
            onClick={() => onClickMoreData()}
            color="blue"
            style={{ width: '200px' }}
            isLoading={isLoadingMore}
            disabled={isLoadingMore}
          >
            <Text id="react_main.load_more" color="white" size="small" bold>
              Load more sessions
            </Text>
          </Button>
        )}
        {!!paginationInfo?.totalResults && <Text size="tiny" bold color="grey" className="mt-3">Total results: {paginationInfo.totalResults}</Text>}
      </div>
    </div>
  );
};

export default withRouter(ReactHealthOverview);
