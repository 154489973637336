import React from 'react';

const SvgBlueStar = props => (
  <svg width="1em" height="1em" viewBox="0 0 20 19" {...props}>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="BlueStar_svg__c">
        <stop stopColor="#7BC9F5" offset="0%" />
        <stop stopColor="#4598E7" offset="100%" />
      </linearGradient>
      <filter
        x="-273.7%"
        y="-233%"
        width="647.5%"
        height="634.4%"
        filterUnits="objectBoundingBox"
        id="BlueStar_svg__a"
      >
        <feMorphology
          radius={5}
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        />
        <feOffset dy={10} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={10}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0.270588235 0 0 0 0 0.596078431 0 0 0 0 0.905882353 0 0 0 0.03 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        id="BlueStar_svg__b"
        d="M10.5 15.489l-6.172 2.506.476-6.644-4.29-5.096 6.466-1.6L10.5-1l3.52 5.655 6.466 1.6-4.29 5.096.476 6.644z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use
        fill="currentColor"
        filter="url(#BlueStar_svg__a)"
        xlinkHref="#BlueStar_svg__b"
      />
      <use fill="url(#BlueStar_svg__c)" xlinkHref="#BlueStar_svg__b" />
    </g>
  </svg>
);

export default SvgBlueStar;
