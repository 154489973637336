import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from '../components';
import colors from '../styles/colors';

const Radio = memo(props => {
  return (
    <RadioWrapper
      isLabel={!!props.label}
      style={props.style}
      className={props.className}
    >
      {!!props.label && (
        <RadioLabel>
          <Text bold style={{ fontSize: '16px' }}>
            {props.label}
          </Text>
        </RadioLabel>
      )}
      <RadioInput type="radio" onClick={() => props.onClick()} />
      <Checkmark checked={props.checked} />
    </RadioWrapper>
  );
});

Radio.propTypes = {
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
};

Radio.defaultProps = {
  onClick: () => {},
  checked: true,
  label: '',
};

const RadioWrapper = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: ${props => (props.isLabel ? '35px' : '0')};
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 25px;
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const RadioLabel = styled.div`
  position: relative;
  line-height: 26px;
  margin-top: 4px;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: solid 3px #bbbbbb;

  &:hover {
    background-color: #eee;
  }

  &:after {
    content: '';
    position: absolute;
    display: ${props => (props.checked ? 'block' : 'none')};
    top: 3px;
    left: 3px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${colors.HIGHLIGHT_BLUE};
  }
`;

export default Radio;
