import React from 'react';

const SvgPlus = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g
      stroke={props.color}
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path d="M24.5 13.22v21.635M35.5 24h-22" />
    </g>
  </svg>
);

SvgPlus.defaultProps = {
  color: '#1b1b1b',
};

export default SvgPlus;
