import React from 'react';

const SvgCamera = props => (
  <svg width="1em" height="1em" viewBox="0 0 34 29" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#1b1b1b" strokeWidth={3}>
      <circle cx={17} cy={15} r={6} />
      <path
        d="M2 10.858V22a3 3 0 003 3h24a3 3 0 003-3V10.858a3 3 0 00-3-3h-2.873a3 3 0 01-2.404-1.204l-1.83-2.45A3 3 0 0019.49 3h-5.076a3 3 0 00-2.404 1.204l-1.83 2.45a3 3 0 01-2.403 1.204H5a3 3 0 00-3 3z"
        strokeLinejoin="round"
      />
      <path d="M4 8V5a1 1 0 011-1h2a1 1 0 011 1v3" strokeLinejoin="round" />
    </g>
  </svg>
);

export default SvgCamera;
