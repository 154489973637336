import React from 'react';

import { Text } from 'components';
import PromotionCard from '../components/PromotionCard';

import './reactHealthPromotion.scss';

const ReactHealthPromotion = (props) => {
  const section1Cards = [
    {
      parent: 'react-health',
      className: 'detect',
      title: 'Detect Abnormalities Early',
      desc: 'Comprehensive health report features intelligent prioritisation of abnormal biomarkers, helping you identify potential health risks through a familiar, simplified and easy-to-understand view.',
      primaryImage:
        'https://uploads-ssl.webflow.com/632bcb1c7ef10280608720d9/646cc951e45bf35c297fb6b0_Group%2034320.svg',
    },
    {
      parent: 'react-health',
      className: 'longitudinal',
      title: 'Longitudinal Tracking*',
      desc: 'Track both abnormalities and improvements over time through an intuitive trend view for easy interpretation and monitoring of health progress.',
      primaryImage:
        'https://uploads-ssl.webflow.com/632bcb1c7ef10280608720d9/646cc94eac0f99cf652b23b1_Group%2034333.svg',
    },
  ];
  const section2Cards = [
    {
      parent: 'react-health',
      className: 'accurate',
      title: 'Accurate Clinical Insights',
      desc: 'Contextual insights to individual biomarker and with actionable steps to improve patient’s overall health.',
      imagePosition: 'end',
      primaryImage:
        'https://uploads-ssl.webflow.com/632bcb1c7ef10280608720d9/646cc94ddc79002f6a16add0_Group%2034334.svg',
      secondaryImage:
        'https://uploads-ssl.webflow.com/632bcb1c7ef10280608720d9/6462bab6ec812e3329e6b2ac_LDL%201.png',
    },
    {
      parent: 'react-health',
      className: 'retrace',
      title: 'Retrace When You Need Them',
      desc: 'Easily retrace conversations and instructions with patients by accessing logged notes within the same report, facilitating effective and efficient patient care.',
      imagePosition: 'end',
      primaryImage:
        'https://uploads-ssl.webflow.com/632bcb1c7ef10280608720d9/646cc94dac0f99cf652b236e_Group%2034209.svg',
      secondaryImage:
        'https://uploads-ssl.webflow.com/632bcb1c7ef10280608720d9/646cc94de0537ab53d1fb8e6_Mask%20group%20(6).svg',
    },
  ];

  const footerItems = [
    'Prioritise Biomarker Abnormalities',
    'Clinical Tested Risk Assessment Models',
    'Contextual Health Insights',
  ];
  return (
    <div className="react-health-promo">
      <div className="react-health-promo__head">
        <Text dark size="huge" className="mt-4">
          ReACT
        </Text>
        <Text dark size="big" className="mt-4">
          Gain deeper contextual insights into your overall health
        </Text>
        <Text size="medium" className="mt-3">
          ReACT Health prioritises and identifies biomarker abnormalities and
          improvements through its reports, offering valuable insights and
          actionable steps to enhance overall health. This approach improves
          health risk monitoring and prediction, empowering individuals with the
          information they need to take control of their health and make
          informed decisions about their care.
        </Text>
      </div>
      <div className="react-health-promo__body">
        {section1Cards.map((card) => (
          <PromotionCard {...card} />
        ))}
      </div>
      <div className="react-health-promo__body">
        {section2Cards.map((card) => (
          <PromotionCard {...card} />
        ))}
      </div>
      <div className="react-health-promo__foot">
        {footerItems.map((item) => (
          <div className="react-health-promo__foot__item">
            <img
              src="https://uploads-ssl.webflow.com/632bcb1c7ef10280608720d9/6462b54171184d2966a66991_check-mark%20(1)%206.svg"
              loading="lazy"
              alt=""
              className="mr-3"
            />
            <Text size="medium" center>
              {item}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReactHealthPromotion;
