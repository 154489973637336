import styled from 'styled-components';

export const StyledLink = styled.div`
  // max-width: 300px;
  height: 100%;
  cursor: pointer;
`;

export const CardContainer = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 28px;
  width: 100%;
`;

export const CardWrapper = styled.div`
  display: flex;
  // flex-basis: 100%;
  flex-direction: column;
  // justify-content: space-between;
  border-radius: 8px;

  &:hover {
    box-shadow: 4px 4px 15px 1px rgb(226, 226, 226, 0.4) !important;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 24px;
  line-height: normal;
  border-radius: 8px 8px 0 0;
  background: ${(props) => `${props.bgColor}` || `rgb(245, 255, 248)`};
`;

export const CardNotice = styled.div`
  flex-grow: 1;
  color: black !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
`;

export const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardTitleText = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CardHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: black;
  padding: 5px 0 8px 0;
  max-width: 618px;

  &&:hover {
    text-decoration: underline;
  }
`;

export const CardNoticeToggle = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 32px;
  ${(props) => (props.flipped ? 'transform: matrix(1, 0, 0, -1, 0, 0);' : '')}
`;

export const CardTestType = styled.div`
  color: ${(props) => props.color || 'black'};
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

export const CardLogo = styled.div``;

export const PackageTestWrapper = styled.div`
  color: black !important;
  position: relative;
  width: 85%;
  height: 100;
  transition-duration: 200ms;
  margin: 0;
`;
export const PackageTestToggleDivider = styled.div`
  width: 100%;
  height: 15px;
  margin-top: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${(props) => props.borderColor || '#000000'};
`;
export const PackageTestToggle = styled.div`
  color: white !important;
  background: ${(props) => props.background || '#000000'};
  padding: 5px 30px;
  border-radius: 2rem;
  width: fit-content;
  margin: auto;
  font-size: medium;
  font-weight: 600;

  &&:hover {
    cursor: pointer;
  }
`;
export const PackageTestHeader = styled.div`
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 15px;
`;
export const PackageTestItem = styled.div`
  margin-bottom: 10px;
`;
export const PackageTestItemCatName = styled.div`
  margin-bottom: 6px;
  font-weight: 600;
`;
export const PackageTestItemDetials = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;
export const PackageTestItemDetialsDot = styled.div`
  width: 8px;
  height: 4px;
  background: ${(props) => props.color || '#1fa97f'};
  border-radius: 4px;
`;
export const PackageTestItemDetialsName = styled.span`
  margin-left: 10px;
`;

export const ExtraContainer = styled.div`
  background-color: white;
  border: 1px solid #dedede;
  border-top: none;
  border-radius: 0 0 8px 8px;
  padding: 16px;
`;
export const ExtraContent = styled.div`
  background-color: white;
  display: flex;
  gap: 16px;
  border-radius: 0 0 8px 8px;
`;
export const Extras = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 8px 8px;
`;

export const ExtraTotal = styled.div`
  color: black;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ExtraPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PriceDescription = styled.span`
  padding-top: 4px;
  color: #585858;
  font-size: 10px;
`;

export const ExtraCost = styled.span`
  width: 100%;
  color: black;
  font-weight: 400;
  font-size: 11px;
`;

export const ExtraCurrency = styled.span`
  color: #585858;
  font-size: medium;
  font-weight: bold;
  padding: 0 5px 0 5px;
`;
export const ExtraPrice = styled.span`
  color: black;
  font-weight: 400;
  font-size: 16px;
`;
export const ExtraPriceTag = styled.span`
  display: flex;
  gap: 5px;
`;

export const CardType = styled.span`
  font-size: 15px;
  color: #1fa97f;
  font-size: medium;
  font-weight: 900;
`;

export const CardLogoImage = styled.img`
  height: 40px;
`;
