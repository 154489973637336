import React from 'react';

const SvgPrinter = props => (
  <svg width="1em" height="1em" viewBox="0 0 42 37" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M14.44 25.5v-4.049h13.048V25.5H36a.5.5 0 00.5-.5V13A3.5 3.5 0 0033 9.5H9A3.5 3.5 0 005.5 13v12a.5.5 0 00.5.5h8.44z"
        stroke="#1b1b1b"
        strokeWidth={3}
      />
      <rect
        stroke="#1b1b1b"
        strokeWidth={3}
        x={14.5}
        y={21.5}
        width={13}
        height={14}
        rx={2}
      />
      <rect
        stroke="#1b1b1b"
        strokeWidth={3}
        x={14.5}
        y={2.5}
        width={13}
        height={7}
        rx={2}
      />
      <path
        d="M17.5 25.5h4.789M17.5 28.5h6.106M17.974 32h2.947"
        stroke="#1b1b1b"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <circle fill="#1b1b1b" cx={30} cy={16} r={1} />
    </g>
  </svg>
);

export default SvgPrinter;
