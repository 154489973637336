import React from 'react';

const SvgEdit = props => (
  <svg width="1em" height="1em" viewBox="0 0 31 32" {...props}>
    <g fill="none" fillRule="evenodd" stroke={props.color} strokeWidth={3}>
      <path d="M6.623 19.392l16.25-16.827a1 1 0 011.414-.025l2.877 2.779a1 1 0 01.025 1.414l-16.25 16.826a1 1 0 01-.668.304l-2.926.152A1 1 0 016.293 23l.05-2.93a1 1 0 01.28-.678z" />
      <path d="M3.896 30h23.27" strokeLinecap="round" />
    </g>
  </svg>
);

SvgEdit.defaultProps = {
  color: '#1b1b1b',
};

export default SvgEdit;
