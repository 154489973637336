import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { useStaff } from 'hooks';
import {
  Text,
  DatePicker,
  Select,
  Cleave,
  Button,
  Input,
  ErrorToast,
  Switch
} from 'components';
import ReactHealthIcon from 'assets/new/reactHealth';

import DropdownOption from '../../../../webAppointments/overview/components/dropdownOption/DropdownOption';

import './patientModal.scss';

const PatientModal = ({ patient, show, hide, onCreate }) => {
  const [errors, setErrors] = useState('');
  const [name, setName] = useState('');
  const [selectedStaff, setStaff] = useState(null);
  const [staffOptions, setStaffOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [date, onDateChange] = useState(moment().toDate());
  const [time, setTime] = useState(moment().format('HH:mm'));
  const [sendEmail, setSendEmail] = useState(false);

  const staff = useStaff({ clientId: localStorage.getItem('DELTA_CLIENT_ID') });

  useEffect(() => {
    if (!Array.isArray(staff)) {
      setIsLoading(false);
      return setStaffOptions([]);
    }
    setIsLoading(false);
    const result = staff.map(({ id, name, email, type }) => {
      const val = {
        value: id,
        text: name,
        type,
        content: <DropdownOption text={name} role={email} />,
      };
      //   if (staffId === id) setStaff(staffId);
      return val;
    });
    setStaffOptions(result);
  }, [staff]);

  const getStaff = () => {
    const staffData = staffOptions.find(
      (option) => option.value === selectedStaff
    );
    const staff = {
      id: staffData?.value,
      name: staffData?.text,
    };
    return staff;
  };

  const onCreateClick = async () => {
    try {
      if (!name) return setErrors({ name: true });
      setIsLoading(true);
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const finalDate = moment(
        formattedDate + ' ' + time,
        'YYYY-MM-DD HH:mm'
      ).toISOString();
      await onCreate({
        name,
        staff: getStaff(),
        date: finalDate,
        time,
        type: 'single',
        patientName: patient.fullName,
        patientId: patient.id,
        sendQuestionnaire: sendEmail
      });
      window.history.replaceState({}, '');
      setIsLoading(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    window.history.replaceState({}, '');
    hide(false);
  };

  return (
    <Modal
      closeIcon
      open={show}
      onClose={() => onClose()}
      size="mini"
      className="rh-pm"
    >
      {/* <Modal.Header>{header}</Modal.Header> */}
      <Modal.Content className="rh-pm__content">
        <div className="rh-pm__icon">
          <ReactHealthIcon height="55px" width="55px" color="#5076FF" />
        </div>
        <Text id="react_patient.screening" center bold className="mb-4">
          ReACT Health Screening Session
        </Text>
        {/* <Text
          id="react_patient.questionnaire_sent"
          center
          size="tiny"
          className="mb-3"
        >
          A ReACT Health questionnaire will be sent to the patient.
        </Text> */}
        <Text id="Detail pasien" bold size="small">
          Patient details
        </Text>
        <div className="rh-pm__pat-wrapper">
          <div className="rh-pm__pat-wrapper__row mb-2">
            <Text
              id="patients.name"
              color="lightGrey"
              size="tinniest"
              className="mr-2"
            >
              Name
            </Text>
            <Text size="tiny" bold>
              {patient?.fullName}
            </Text>
          </div>
          {patient?.passNo && (
            <div className="rh-pm__pat-wrapper__row mb-2">
              <Text
                id="patient_overview.passport_number"
                color="lightGrey"
                size="tinniest"
                className="mr-2"
              >
                Passport No.
              </Text>
              <Text size="tiny" bold>
                {patient?.passNo}
              </Text>
            </div>
          )}
          {patient?.ssn && (
            <div className="rh-pm__pat-wrapper__row mb-2">
              <Text
                id="patient_overview.ssn"
                color="lightGrey"
                size="tinniest"
                className="mr-2"
              >
                SSN
              </Text>
              <Text size="tiny" bold>
                {patient?.ssn}
              </Text>
            </div>
          )}
          <div className="rh-pm__pat-wrapper__row">
            <Text
              id="patient_overview.email"
              color="lightGrey"
              size="tinniest"
              className="mr-2"
            >
              Email
            </Text>
            <Text size="tiny" bold>
              {patient?.email}
            </Text>
          </div>
        </div>
        <div className="rh-pm__form">
          <div>
            <Text id="react_main.react_package_name" type="label">
              ReACT Screening Package Name
            </Text>
            <Input
              className="mb-3"
              border
              placeholder="Executive HS Package"
              size="fullWidth"
              value={name}
              onChange={(e) => setName(e.target.value)}
              invalid={errors.name}
              showErrorMessage={false}
            />
          </div>
          <div>
            <Text id="react_main.screening_date" type="label">
              Screening Date
            </Text>
            <DatePicker
              className="mb-3"
              onChange={(date) => onDateChange(date)}
              value={date}
              size="fullWidth"
              //   invalid={errors?.startDate}
              //   maxDate={moment().toDate()}
              showErrorMessage={false}
            />
          </div>
          <Cleave
            labelTextId="react_main.screening_time"
            wrapperClassName="mb-3"
            size="fullWidth"
            placeholder="hh:mm"
            label="Screening Time"
            options={{
              time: true,
              timePattern: ['h', 'm'],
            }}
            onChange={(e) => setTime(e.target.value)}
            value={time}
            //   invalid={errors.collectionTime}
            errorMessage=" "
            // disabled={isLabResult && !!appointment.collectionDate}
          />
          <div className='mb-3'>
            <Select
              labelTextId="react_main.doctor-in-charge"
              label="Doctor-in-charge"
              size="fullWidth"
              border
              search
              optional
              icon={false}
              placeholder="Select staff"
              options={staffOptions}
              value={selectedStaff}
              onChange={(e, { value }) => setStaff(value)}
              //   invalid={errors.template}
            />
          </div>
          <div className='mb-3'>
            <Text type="label">
              Send questionnaire to patient
            </Text>
            <Switch
              onChange={() => setSendEmail(!sendEmail)}
              checked={sendEmail}
            />
          </div>
        </div>
        <Button
          size="fullWidth"
          color="blue"
          className="mt-4"
          onClick={onCreateClick}
          disabled={isLoading}
          isLoading={isLoading}
          textId="settings_lab_grps.create"
        >
          Create
        </Button>
      </Modal.Content>
    </Modal>
  );
};

export default PatientModal;
