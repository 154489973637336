import React, { useEffect } from 'react';
import { useCustomForm } from 'hooks';
import { Text, Button, Select, TextArea, Input } from 'components';
import { Icon as SIcon } from 'semantic-ui-react';
import moment from 'moment';

import YesOrNo from 'components/YesOrNO/YesOrNo';
import { visualAcuitySchema } from 'pages/patientDetails/schema';

import './visualAcuity.scss';

const eyeTestOptions = [
  {
    key: 'Line 1 - 20/200',
    text: 'Line 1 - 20/200',
    value: 'Line 1 - 20/200',
  },
  {
    key: 'Line 2 - 20/100',
    text: 'Line 2 - 20/100',
    value: 'Line 2 - 20/100',
  },
  {
    key: 'Line 3 - 20/70',
    text: 'Line 3 - 20/70',
    value: 'Line 3 - 20/70',
  },
  {
    key: 'Line 4 - 20/50',
    text: 'Line 4 - 20/50',
    value: 'Line 4 - 20/50',
  },
  {
    key: 'Line 5 - 20/40',
    text: 'Line 5 - 20/40',
    value: 'Line 5 - 20/40',
  },
  {
    key: 'Line 6 - 20/30',
    text: 'Line 6 - 20/30',
    value: 'Line 6 - 20/30',
  },
  {
    key: 'Line 7 - 20/25',
    text: 'Line 7 - 20/25',
    value: 'Line 7 - 20/25',
  },
  {
    key: 'PinhLine 8 - 20/20ole',
    text: 'Line 8 - 20/20',
    value: 'Line 8 - 20/20',
  },
];

const VisualAcuity = ({
  data = {},
  editedData = {},
  editMode,
  onDetailsChange,
  categorizeVASnellenTest,
}) => {
  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useCustomForm(visualAcuitySchema, {
    snellenTestVAid: '',
    snellenTestOd: '',
    snellenTestOs: '',
    snellenTestUpdatedAt: '',
    visualRefractionTestOd: null,
    visualRefractionTestOs: null,
    visualRefractionTestRemark: '',
    visualRefractionTestUpdatedAt: '',
    colorBlindness: '',
    colorBlindnessRemark: '',
    colorBlindnessUpdatedAt: '',
    eyePressure: null,
    eyePressureUpdatedAt: '',
  });

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      reset(data);
    }
  }, [data]);

  return (
    <div className="va">
      <div className="va__test-item">
        <div className="va__snellen-test">
          <div className="va__snellen-test__header">
            <Text dark size="medium">
              Snellen Test
            </Text>
            <Text color="lightGrey" size="small">
              {editedData?.snellenTestUpdatedAt
                ? moment(editedData?.snellenTestUpdatedAt).format(
                    'DD MMM YYYY, HH:mm A'
                  )
                : 'Date and time not recorded yet'}
            </Text>
          </div>
          <div className="va__snellen-test__body">
            <div className="va__snellen-test__body__field">
              <Text
                size="small"
                className="va__snellen-test__body__field__label"
              >
                Visual aid{!editMode ? ':' : ''}
              </Text>
              {editMode ? (
                <Controller
                  name="snellenTestVAid"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="form-row__field"
                      size="fullWidth"
                      border
                      value={value}
                      onChange={(e, { value }) => {
                        onDetailsChange('snellenTestVAid', value, onChange);
                      }}
                      options={[
                        {
                          key: 'unaided',
                          text: 'Unaided',
                          value: 'Unaided',
                        },
                        {
                          key: 'with-glasses',
                          text: 'With glasses',
                          value: 'With glasses',
                        },
                        {
                          key: 'Pinhole',
                          text: 'Pinhole',
                          value: 'Pinhole',
                        },
                      ]}
                      invalid={errors?.snellenTestVAid}
                    />
                  )}
                />
              ) : (
                <Text
                  size="small"
                  bold
                  className="va__snellen-test__body__field__value"
                >
                  {data?.snellenTestVAid || '----'}
                </Text>
              )}
            </div>
            <div className="va__snellen-test__body__field">
              <Text
                size="small"
                className="va__snellen-test__body__field__label"
              >
                OD (right)
              </Text>
              {editMode ? (
                <Controller
                  name="snellenTestOd"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="form-row__field"
                      size="fullWidth"
                      border
                      value={value}
                      onChange={(e, { value }) => {
                        onDetailsChange('snellenTestOd', value, onChange);
                      }}
                      options={eyeTestOptions}
                      invalid={errors?.snellenTestOd}
                    />
                  )}
                />
              ) : (
                <>
                  <Text
                    size="small"
                    className={`va__snellen-test__body__field__bordered-value ${categorizeVASnellenTest(
                      data?.snellenTestOd
                    ).text.toLowerCase()}`}
                  >
                    {data?.snellenTestOd || '----'}
                  </Text>
                  <Text
                    size="tiny"
                    className="va__snellen-test__body__field__bordered-value-status ml-1"
                  >
                    {categorizeVASnellenTest(data?.snellenTestOd).text}
                  </Text>
                </>
              )}
            </div>
            <div className="va__snellen-test__body__field">
              <Text
                size="small"
                className="va__snellen-test__body__field__label"
              >
                OS (left)
              </Text>
              {editMode ? (
                <Controller
                  name="snellenTestOs"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="form-row__field"
                      size="fullWidth"
                      border
                      value={value}
                      onChange={(e, { value }) => {
                        onDetailsChange('snellenTestOs', value, onChange);
                      }}
                      options={eyeTestOptions}
                      invalid={errors?.snellenTestOs}
                    />
                  )}
                />
              ) : (
                <>
                  <Text
                    size="small"
                    className={`va__snellen-test__body__field__bordered-value ${categorizeVASnellenTest(
                      data?.snellenTestOs
                    ).text.toLowerCase()}`}
                  >
                    {data?.snellenTestOs || '----'}
                  </Text>
                  <Text
                    size="tiny"
                    className="va__snellen-test__body__field__bordered-value-status ml-1"
                  >
                    {categorizeVASnellenTest(data?.snellenTestOs).text}
                  </Text>
                </>
              )}
            </div>
          </div>
          {/* {editMode && (
            <Button inverted size="fullWidth">
              <Text dark>Save</Text>
            </Button>
          )} */}
        </div>
      </div>
      <div className="va__test-item">
        <div className="va__refraction-test">
          <div className="va__refraction-test__header">
            <Text dark size="medium">
              Visual Refraction Test
            </Text>
            <Text color="lightGrey" size="small">
              {editedData?.visualRefractionTestUpdatedAt
                ? moment(editedData?.visualRefractionTestUpdatedAt).format(
                    'DD MMM YYYY, HH:mm A'
                  )
                : 'Date and time not recorded yet'}
            </Text>
          </div>
          <div className="va__refraction-test__body">
            <div className="va__refraction-test__body__row">
              <div className="va__refraction-test__body__field">
                <Text size="small">OD (right)</Text>
                {editMode ? (
                  <Controller
                    name="visualRefractionTestOd"
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        className="form-row__field"
                        size="fullWidth"
                        border
                        inputStyle={{ width: '100%', textAlign: 'center' }}
                        value={value}
                        onChange={(e) => {
                          onDetailsChange(
                            'visualRefractionTestOd',
                            e.target.value,
                            onChange
                          );
                        }}
                        invalid={errors?.visualRefractionTestOd}
                      />
                    )}
                  />
                ) : (
                  <Text
                    size="small"
                    className="va__refraction-test__body__field__bordered-value"
                  >
                    {data?.visualRefractionTestOd || '--'}
                  </Text>
                )}
              </div>
              <div className="va__refraction-test__body__field">
                <Text size="small">OS (left)</Text>
                {editMode ? (
                  <Controller
                    name="visualRefractionTestOs"
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        className="form-row__field"
                        size="fullWidth"
                        inputStyle={{ width: '100%', textAlign: 'center' }}
                        border
                        value={value}
                        onChange={(e) => {
                          onDetailsChange(
                            'visualRefractionTestOs',
                            e.target.value,
                            onChange
                          );
                        }}
                        invalid={errors?.visualRefractionTestOs}
                      />
                    )}
                  />
                ) : (
                  <Text
                    size="small"
                    className="va__refraction-test__body__field__bordered-value"
                  >
                    {data?.visualRefractionTestOs || '--'}
                  </Text>
                )}
              </div>
            </div>
            <div className="va__refraction-test__body__field">
              {editMode ? (
                <Controller
                  name="visualRefractionTestRemark"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <TextArea
                      placeholder="Doctor's remark"
                      onChange={(e) =>
                        onDetailsChange(
                          'visualRefractionTestRemark',
                          e.target.value,
                          onChange
                        )
                      }
                      showErrorMessage={false}
                      value={value}
                      invalid={errors?.visualRefractionTestRemark}
                      // disabled={isLabResult && !!issuedTime}
                    />
                  )}
                />
              ) : (
                <Text
                  size="small"
                  className="va__refraction-test__body__field__bordered-text-area-value"
                >
                  {data?.visualRefractionTestRemark || '----'}
                </Text>
              )}
            </div>
          </div>
          {/* {editMode && (
            <Button inverted size="fullWidth">
              <Text dark>Save</Text>
            </Button>
          )} */}
        </div>
      </div>
      <div className="va__test-item">
        <div className="va__color-blindness">
          <div className="va__color-blindness__header">
            <Text dark size="medium">
              Color Blindness
            </Text>
            <Text color="lightGrey" size="small">
              {editedData?.colorBlindnessUpdatedAt
                ? moment(editedData?.colorBlindnessUpdatedAt).format(
                    'DD MMM YYYY, HH:mm A'
                  )
                : 'Date and time not recorded yet'}
            </Text>
          </div>
          <div className="va__color-blindness__body">
            <div className="va__color-blindness__body__field">
              {editMode ? (
                <Controller
                  name="colorBlindness"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <YesOrNo
                      onChange={(value) =>
                        onDetailsChange('colorBlindness', value, onChange)
                      }
                      value={value || 'No'}
                    />
                  )}
                />
              ) : (
                <Text
                  size="small"
                  className={`va__color-blindness__body__field__bordered-value ${String(
                    data?.colorBlindness
                  )?.toLowerCase()}`}
                >
                  {data?.colorBlindness === 'Yes' ? (
                    <SIcon name="check circle outline" className="mb-2" />
                  ) : (
                    <SIcon name="times circle outline" className="mb-2" />
                  )}
                  {data?.colorBlindness || 'No'}
                </Text>
              )}
            </div>
            <div className="va__color-blindness__body__field">
              {editMode ? (
                <Controller
                  name="colorBlindnessRemark"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <TextArea
                      placeholder="Doctor's remark"
                      onChange={(e) =>
                        onDetailsChange(
                          'colorBlindnessRemark',
                          e.target.value,
                          onChange
                        )
                      }
                      showErrorMessage={false}
                      value={value}
                      invalid={errors?.colorBlindnessRemark}
                      // disabled={isLabResult && !!issuedTime}
                    />
                  )}
                />
              ) : (
                <Text
                  size="small"
                  className="va__color-blindness__body__field__bordered-text-area-value"
                >
                  {data?.colorBlindnessRemark || '----'}
                </Text>
              )}
            </div>
          </div>
          {/* {editMode && (
            <Button inverted size="fullWidth">
              <Text dark>Save</Text>
            </Button>
          )} */}
        </div>
      </div>
      <div className="va__test-item">
        <div className="va__eye-pressure">
          <div className="va__eye-pressure__header">
            <Text dark size="medium">
              Eye Pressure (mmHg)
            </Text>
            <Text size="small">
              Tonometry{' '}
              <sup>
                <SIcon name="info circle" />
              </sup>
            </Text>
            <Text color="lightGrey" size="small">
              {editedData?.eyePressureUpdatedAt
                ? moment(editedData?.eyePressureUpdatedAt).format(
                    'DD MMM YYYY, HH:mm A'
                  )
                : 'Date and time not recorded yet'}
            </Text>
          </div>
          <div className="va__eye-pressure__body">
            <div
              className={`va__eye-pressure__body__field ${
                !editMode &&
                Number(data?.eyePressure) > 10 &&
                Number(data?.eyePressure) < 21
                  ? 'healthy'
                  : 'unhealthy'
              }`}
            >
              <div className="va__eye-pressure__body__field__label">
                <Text
                  size="tiny"
                  dark={Number(data?.eyePressure) <= 10}
                  color={Number(data?.eyePressure) <= 10 ? '' : 'lightGrey'}
                >
                  Low
                </Text>
                <Text
                  size="tiny"
                  dark={Number(data?.eyePressure) > 20}
                  color={Number(data?.eyePressure) > 20 ? '' : 'lightGrey'}
                >
                  Elevated
                </Text>
                <Text
                  size="tiny"
                  dark={
                    Number(data?.eyePressure) > 10 &&
                    Number(data?.eyePressure) < 21
                  }
                  color={
                    Number(data?.eyePressure) > 10 &&
                    Number(data?.eyePressure) < 21
                      ? ''
                      : 'lightGrey'
                  }
                >
                  Normal
                </Text>
              </div>
              {editMode ? (
                <Controller
                  name="eyePressure"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      className="form-row__field"
                      style={{ width: '50px' }}
                      inputStyle={{
                        fontSize: '20px',
                        fontWeight: 600,
                        textAlign: 'center',
                      }}
                      value={value}
                      onChange={(e) => {
                        onDetailsChange(
                          'eyePressure',
                          e.target.value,
                          onChange
                        );
                      }}
                      invalid={errors?.eyePressure}
                    />
                  )}
                />
              ) : (
                <Text size="bigish" style={{ width: '50px' }} dark center>
                  {data?.eyePressure || '--'}
                </Text>
              )}
              <Text
                size="small"
                className="va__eye-pressure__body__field__label"
              >
                mmHg
              </Text>
            </div>
          </div>
          <Text size="tiny" color="lightGrey">
            Normal IOP: 10 to 21 mm Hg are considered normal for most
            individuals
          </Text>
          {/* {editMode && (
            <Button inverted size="fullWidth">
              <Text dark>Save</Text>
            </Button>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default VisualAcuity;
