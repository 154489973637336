
import { Text } from 'components';

const AdditionalReports = () => {
  return (
    <>
      <Text id="react_report.upload_pdfs" className="mb-4" size="small" bold>
        Upload PDFs, add doctors notes and give your patients a better
        understanding of their results. Uploaded reports will be added to the
        final ReACT report.
      </Text>
      <div className="add-reps__text-row">
        <Text
          id="patient_overview.lab_results"
          size="small"
          bold
          className="mr-2"
        >
          Lab Results:
        </Text>
        <Text id="react_report.diagnostic_lab_test" size="small">
          Diagnostics laboratory test results such cytology, pathology and
          urinalysis.
        </Text>
      </div>
      <div className="add-reps__text-row">
        <Text
          id="react_report.audio_n_visual"
          size="small"
          bold
          className="mr-2"
        >
          Audio and Visual Acuity:
        </Text>
        <Text id="react_report.audio_n_visual_acuity" size="small">
          Audio and visual acuity assessments.
        </Text>
      </div>
      <div className="add-reps__text-row">
        <Text
          id="react_report.diagnostic_imaging"
          size="small"
          bold
          className="mr-2"
        >
          Diagnostic Imaging:
        </Text>
        <Text id="react_report.computed_tomography" size="small">
          Computed Tomography Scan (CT-scan), magnetic resonance imaging (MRI),
          ultrasound and X-ray(s).
        </Text>
      </div>
      <div className="add-reps__text-row">
        <Text
          id="react_report.pulmonary_function"
          size="small"
          bold
          className="mr-2"
        >
          Pulmonary Function:
        </Text>
        <Text id="react_report.cardiopulmonary_exercise" size="small">
          Cardiopulmonary exercise test (CPET), spirometry and more.
        </Text>
      </div>
    </>
  );
}

export default AdditionalReports;
