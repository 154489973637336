import { useCallback, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ErrorToast } from 'components';

import StaffService from 'api-delta/staff';
import { setStaff } from 'pages/staffPages/staffManagement/store/staffManagement.actions';

export const useStaff = ({ clientId }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.general.user);

  // planned courses from Redux
  const staffFromRedux = useSelector((state) => state.staffManagement.staff);

  const fetchStaff = useCallback(async () => {
    let staff;
    if (userInfo.centerId) staff = await fetchStaffByCenter();
    staff = await fetchStaffByClient();
    return staff;
  }, [clientId, dispatch]);

  const fetchStaffByClient = async (centers) => {
    try {
      const {
        data: { instructorsByClientId },
      } = await StaffService.getStaffByClientId(clientId);
      dispatch(setStaff(instructorsByClientId));
      return instructorsByClientId;
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchStaffByCenter = async () => {
    try {
      const {
        data: { instructorsByCenterId },
      } = await StaffService.getStaffByCenterId(userInfo.centerId);
      dispatch(setStaff(instructorsByCenterId));
      return instructorsByCenterId;
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  // if we already fetch planned courses before then just return it
  const staff = useMemo(() => {
    if (staffFromRedux.length) return staffFromRedux;
    return fetchStaff();
  }, [staffFromRedux, fetchStaff]);

  return staff;
};
