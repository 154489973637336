import React from 'react';

const SvgCross = props => (
  <svg width="1em" height="1em" viewBox="0 0 26 25" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      stroke={props.color}
      strokeWidth={3}
    >
      <path d="M3.595 3.553L21.421 21.38M21.538 3.7L3.713 21.526" />
    </g>
  </svg>
);

SvgCross.defaultProps = {
  color: '#1b1b1b',
};

export default SvgCross;
