import gql from 'graphql-tag';

export const issueCertificate = gql`
  mutation certificateIssue(
    $userId: ID
    $clientId: ID
    $apiClientId: ID
    $name: String!
    $email: String!
    $dateOfBirth: String
    $ssn: String
    $issuerName: String
    $idType: String
    $holderName: String!
    $validUntil: Int
    $courseStart: Int
    $courseEnd: Int
    $watermarkImage: String
    $courseId: ID
    $logoImage: String
    $certificateInformation: String
    $telephone: String
    $signers: String
    $trainingProviderAddress: String
    $trainingProviderCity: String
    $trainingProviderZip: String
    $trainingProviderCountry: String
    $location2: String
    $locationImage: String
    $centerId: ID
    $additionalImage: String
    $version: String
    $type: String
    $paperSize: String
    $certType: String
    $passNo: String
    $issuedAt: String
    $reportedAt: String
    $acsn: String
    $attachment: String
    $attachment2: String
    $labPhoneNumber: String
    $labAddress: String
    $stampImage: String
    $labName: String
    $collectionDate: String
    $nationality: String
    $countryCode: String
    $gender: String
    $birthDate: String
    $physicianId: String
    $physicianName: String
    $physicianSignature: String
    $issuerAddress: String
    $issuerPhoneNumber: String
    $clinicId: String
    $pinNo: String
    $physicianLicense: String
    $transportName: String
    $isFit: String
    $result: String
    $subType: String
    $secondAcsn: String
    $secondResult: String
    $secondLab: String
    $ccEmails: String
    $additionalData: String
    $phone: String
    $extraData: String
    $issuingUser: String
    $issuingUserId: String
  ) {
    certificateIssue(
      clientId: $clientId
      userId: $userId
      apiClientId: $apiClientId
      name: $name
      issuerName: $issuerName
      idType: $idType
      telephone: $telephone
      email: $email
      holderName: $holderName
      issuedAt: $issuedAt
      reportedAt: $reportedAt
      dateOfBirth: $dateOfBirth
      ssn: $ssn
      courseStart: $courseStart
      courseEnd: $courseEnd
      watermarkImage: $watermarkImage
      validUntil: $validUntil
      courseId: $courseId
      acsn: $acsn
      logoImage: $logoImage
      certificateInformation: $certificateInformation
      signers: $signers
      trainingProviderAddress: $trainingProviderAddress
      trainingProviderCity: $trainingProviderCity
      trainingProviderZip: $trainingProviderZip
      trainingProviderCountry: $trainingProviderCountry
      location2: $location2
      locationImage: $locationImage
      centerId: $centerId
      additionalImage: $additionalImage
      version: $version
      type: $type
      paperSize: $paperSize
      certType: $certType
      passNo: $passNo
      attachment: $attachment
      attachment2: $attachment2
      labPhoneNumber: $labPhoneNumber
      labAddress: $labAddress
      stampImage: $stampImage
      labName: $labName
      collectionDate: $collectionDate
      nationality: $nationality
      countryCode: $countryCode
      gender: $gender
      birthDate: $birthDate
      physicianId: $physicianId
      physicianName: $physicianName
      issuerAddress: $issuerAddress
      issuerPhoneNumber: $issuerPhoneNumber
      clinicId: $clinicId
      pinNo: $pinNo
      physicianLicense: $physicianLicense
      physicianSignature: $physicianSignature
      transportName: $transportName
      isFit: $isFit
      result: $result
      subType: $subType
      secondAcsn: $secondAcsn
      secondResult: $secondResult
      secondLab: $secondLab
      ccEmails: $ccEmails
      additionalData: $additionalData
      phone: $phone
      extraData: $extraData
      issuingUser: $issuingUser
      issuingUserId: $issuingUserId
    )
  }
`;

export const revokeCertificate = gql`
  mutation certificateRevoke($certificateId: ID) {
    certificateRevoke(certificateId: $certificateId)
  }
`;

export const bulkIssueCertificate = gql`
  mutation bulkIssueCertificate(
    $certificates: String
    $clientId: ID
    $templateId: ID
  ) {
    bulkIssueCertificate(
      certificates: $certificates
      clientId: $clientId
      templateId: $templateId
    )
  }
`;

export const generatePdf = gql`
  mutation generatePdf(
    $certId: ID!
    $userId: ID!
    $clientId: ID
    $format: String
  ) {
    generatePdf(
      certId: $certId
      userId: $userId
      clientId: $clientId
      format: $format
    ) {
      success
      message
    }
  }
`;

export const notariseDoc = gql`
  mutation notariseDoc($id: ID!) {
    notariseDoc(id: $id)
  }
`;

export const issueVaccineCert = gql`
  mutation bulkIssueVaccine($certificates: [BulkIssueVaccinePayload]) {
    bulkIssueVaccine(certificates: $certificates)
  }
`;

export const issuePemeCertificate = gql`
  mutation issuePemeCertificate(
    $clientId: ID!
    $centerId: ID
    $templateId: ID!
    $birthDate: String
    $patientEmail: String
    $type: String
    $passNo: String
    $firstName: String
    $middleName: String
    $lastName: String
    $nationality: String
    $countryCode: String
    $gender: String
    $religion: String
    $seabookNo: String
    $position: String
    $company: String
    $civilStatus: String
    $declaration: Boolean
    $checkedDocs: Boolean
    $hearingStandards: Boolean
    $visionMeetsStandards: Boolean
    $visualAids: String
    $fitForLookout: Boolean
    $fitnessLimitation: Boolean
    $hearingSatisfactory: Boolean
    $visualMeetsStandards: Boolean
    $aggravatedHealth: Boolean
    $result: String
    $limitation: String
    $date: String
    $issuedAt: String
    $signers: String
    $expirationDate: String
    $medicalDirector: String
    $lastColorTest: String
    $additionalImage: String
    $issuingAuthority: String
    $medicalDirectorSignature: String
    $physicianSignature: String
    $physicianName: String
    $physicianLicense: String
    $authority: String
    $city: String
    $country: String
    $address: String
    $zip: String
    $template: String
    $drugFilePath: String
    $drugScreeningFilePath: String
    $roentgenologicalFilePath: String
    $hivScreeningPath: String
    $labResultsPath: String
    $auditoryResultsPath: String
    $patientCity: String
    $patientCountry: String
    $patientAddress: String
    $patientZip: String
    $birthPlace: String
  ) {
    issuePemeCertificate(
      clientId: $clientId
      centerId: $centerId
      templateId: $templateId
      patientEmail: $patientEmail
      type: $type
      passNo: $passNo
      firstName: $firstName
      signers: $signers
      lastName: $lastName
      nationality: $nationality
      countryCode: $countryCode
      gender: $gender
      birthDate: $birthDate
      issuedAt: $issuedAt
      religion: $religion
      seabookNo: $seabookNo
      position: $position
      company: $company
      middleName: $middleName
      civilStatus: $civilStatus
      declaration: $declaration
      checkedDocs: $checkedDocs
      hearingStandards: $hearingStandards
      visionMeetsStandards: $visionMeetsStandards
      visualAids: $visualAids
      fitForLookout: $fitForLookout
      fitnessLimitation: $fitnessLimitation
      hearingSatisfactory: $hearingSatisfactory
      visualMeetsStandards: $visualMeetsStandards
      aggravatedHealth: $aggravatedHealth
      limitation: $limitation
      date: $date
      expirationDate: $expirationDate
      additionalImage: $additionalImage
      medicalDirector: $medicalDirector
      lastColorTest: $lastColorTest
      issuingAuthority: $issuingAuthority
      medicalDirectorSignature: $medicalDirectorSignature
      physicianSignature: $physicianSignature
      physicianName: $physicianName
      physicianLicense: $physicianLicense
      authority: $authority
      city: $city
      result: $result
      country: $country
      address: $address
      zip: $zip
      template: $template
      drugFilePath: $drugFilePath
      drugScreeningFilePath: $drugScreeningFilePath
      roentgenologicalFilePath: $roentgenologicalFilePath
      hivScreeningPath: $hivScreeningPath
      labResultsPath: $labResultsPath
      auditoryResultsPath: $auditoryResultsPath
      patientCity: $patientCity
      patientCountry: $patientCountry
      patientAddress: $patientAddress
      patientZip: $patientZip
      birthPlace: $birthPlace
    )
  }
`;

export const bulkIssueLabResults = gql`
  mutation bulkIssueLabResults(
    $physicianId: ID!
    $templateId: ID!
    $labResults: [String]
  ) {
    bulkIssueLabResults(
      physicianId: $physicianId
      templateId: $templateId
      labResults: $labResults
    )
  }
`;
