import React from 'react';
import moment from 'moment';
import { convertFromRaw, EditorState } from 'draft-js';

import { Text, RichTextEditor } from '../../../../../components';

import './pcrCert.scss';

const PcrCertificate = props => {
  return (
    <>
      <div className="certificate" ref={props.certRef}>
        <div className="certificate__content">
          {/* {props.certificate.isRevoked && (
            <div className="certificate__content__revoked">
              <div>Document revoked</div>
            </div>
          )} */}
          {props.certificate.logoImage && (
            <div className="certificate__content__logo-wrapper">
              <img
                className="certificate__content__logo-wrapper__logo"
                src={props.certificate.logoImage}
                alt="logo"
              />
            </div>
          )}

          <div className="certificate__content__header-wrapper">
            {/* <Text bold color="black">
              This is to certify that
            </Text> */}
            {/* <Text color="black">Has passed SARS-CoV-2 PCR</Text> */}
            <Text
              className="certificate__content__name"
              dark
              size="big"
              color="black"
            >
              {props.certificate.name}
            </Text>
          </div>

          <div className="pcr-cert__section">
            <Text size="small" className="pcr-cert__section__header">
              Test
            </Text>
            <div className="pcr-cert__row">
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Result
                </Text>
                <Text dark color="black">
                  {props.certificate.result || 'Negative'}
                </Text>
              </div>
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Collection date / time
                </Text>
                <Text bold color="black">
                  {moment
                    .utc(props.certificate.collectionDate)
                    .format('DD-MM-YYYY')}
                  {'   '}
                  {moment(props.certificate.collectionDate).format('HH:mm')}
                </Text>
              </div>
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Report date
                </Text>
                <Text bold color="black">
                  {moment.utc(props.certificate.issuedAt).format('DD-MM-YYYY')}
                </Text>
              </div>
            </div>
            <div className="pcr-cert__row pcr-cert__row--between">
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  ACSN
                </Text>
                <Text bold color="black">
                  {props.certificate.acsn}
                </Text>
              </div>
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Specimen
                </Text>
                <Text bold color="black">
                  Nasopharyngeal swab
                </Text>
              </div>
              <div className="pcr-cert__data" />
            </div>
          </div>

          <div className="pcr-cert__section">
            <Text size="small" className="pcr-cert__section__header">
              Patient
            </Text>
            <div className="pcr-cert__row">
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Document holder
                </Text>
                <Text bold color="black">
                  {props.certificate.holderName}
                </Text>
              </div>
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Nationality
                </Text>
                <Text bold color="black">
                  {props.certificate.nationality}
                </Text>
              </div>
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Gender
                </Text>
                <Text bold color="black">
                  {props.certificate.gender}
                </Text>
              </div>
            </div>
            <div className="pcr-cert__row">
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Date of birth
                </Text>
                <Text bold color="black">
                  {moment(props.certificate.dateOfBirth).format('DD-MM-YYYY')}
                </Text>
              </div>
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Passport No.
                </Text>
                <Text bold color="black">
                  {props.certificate.passNo}
                </Text>
              </div>
              {props.certificate.ssn && (
                <div className="pcr-cert__data">
                  <Text size="tiny" color="grey" className="mb-2">
                    NRIC No.
                  </Text>
                  <Text bold color="black">
                    {props.certificate.ssn}
                  </Text>
                </div>
              )}
              {/* <div className="certificate__content__data-wrapper certificate__content__data-wrapper--half">
                  <div className="cpcr-cert__data">Document no.</div>
                  <div className="certificate__content__data-wrapper__data">
                    <Text bold color="black">
                      {props.certificate.certificateNumber}
                    </Text>
                  </div>
                </div> */}
            </div>
          </div>

          {props.certificate.certificateInformation && (
            <div className="certificate__content__row mb-0">
              <div className="certificate__content__data-wrapper">
                <RichTextEditor
                  editorState={EditorState.createWithContent(
                    convertFromRaw(
                      JSON.parse(props.certificate.certificateInformation)
                    )
                  )}
                  viewer={true}
                  readOnly={true}
                />
              </div>
            </div>
          )}

          <div className="pcr-cert__section">
            <Text size="small" className="pcr-cert__section__header">
              Performer
            </Text>
            <div className="pcr-cert__row">
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Performer name
                </Text>
                <Text bold color="black">
                  {props.certificate.physicianName}
                </Text>
              </div>
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Performer MCR No.
                </Text>
                <Text size="small" bold color="black">
                  {props.certificate.physicianLicense}
                </Text>
              </div>
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Performer license issuer
                </Text>
                <Text size="small" bold color="black">
                  MOH
                </Text>
              </div>
            </div>
          </div>

          <div className="pcr-cert__section">
            <Text size="small" className="pcr-cert__section__header">
              Issuer
            </Text>
            <div className="pcr-cert__row">
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Issuer name
                </Text>
                <Text bold color="black">
                  {props.certificate.issuerName}
                </Text>
              </div>
              <div className="pcr-cert__data pcr-cert__data--long">
                <Text size="tiny" color="grey" className="mb-2">
                  Issuer address
                </Text>
                <Text size="small" bold color="black">
                  {props.certificate.issuerAddress}
                </Text>
              </div>
            </div>
          </div>

          <div className="pcr-cert__section">
            <Text size="small" className="pcr-cert__section__header">
              Lab
            </Text>
            <div className="pcr-cert__row">
              <div className="pcr-cert__data">
                <Text size="tiny" color="grey" className="mb-2">
                  Lab name
                </Text>
                <Text bold color="black">
                  {props.certificate.labName}
                </Text>
              </div>
              <div className="pcr-cert__data pcr-cert__data--long">
                <Text size="tiny" color="grey" className="mb-2">
                  Lab address
                </Text>
                <Text size="small" bold color="black">
                  {props.certificate.labAddress}
                </Text>
              </div>
            </div>
          </div>

          <div className="pcr-cert__data">
            <Text size="tiny" color="grey" className="mb-2">
              Document No.
            </Text>
            <Text bold color="black">
              {props.certificate.certificateNumber}
            </Text>
          </div>

          {props.showQrCode && (
            <div className="certificate__content__row certificate__content__row mb0 mt-0">
              <img
                className="certificate__content__data-wrapper__qr-code"
                src={props.certificate.qrCodeImage}
                alt="qrcode"
              />
            </div>
          )}
        </div>
        {props.certificate.watermarkImage && (
          <img
            className="certificate__watermark"
            src={props.certificate.watermarkImage}
            alt="watermark"
          />
        )}
      </div>
    </>
  );
};

export default PcrCertificate;
