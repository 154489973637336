import React from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';

import { Text } from '../../../../../components';

import './phPemeCert.scss';

const PemeCertificate = props => {
  return (
    <>
      <div
        className={'ph-certificate cert-wrapper ' + props.className}
        ref={props.certRef}
      >
        <div className="ph-certificate__content">
          {props.certificate.isRevoked && (
            <div className="ph-certificate__content__revoked">
              <div>Document revoked</div>
            </div>
          )}
          <div className="ph-certificate__content__header-wrapper">
            <img
              className="ph-certificate__content__logo"
              src={props.certificate.logoImage}
              alt="logo"
            />
            <div className="ph-certificate__content__header-wrapper__header">
              <div className="ph-certificate__content__header-wrapper__header__text">
                {props.certificate.issuerName}
              </div>
              <div className="small-text">
                DOH accreditation #: {props.certificate.officialNo}
              </div>
              <div className="small-text">
                {props.certificate.address}, {props.certificate.zip},{' '}
                {props.certificate.city}, {props.certificate.country}
              </div>
              <div className="small-text">
                Telephone #: {props.certificate.telephone} Telefax:{' '}
                {props.certificate.telefax}
              </div>
              <div className="small-text">
                Email Address: {props.certificate.certEmail}
              </div>
            </div>
            <div>
              {props.showQrCode && (
                <img
                  className="ph-certificate__content__qr-code"
                  src={props.certificate.qrCodeImage}
                  alt="qrcode"
                />
              )}
            </div>
          </div>
          <div className="ph-certificate__content__section ph-certificate__content__section--header">
            <div className="ph-certificate__content__header">
              <div className="ph-certificate__content__header__text">
                MEDICAL CERTIFICATE FOR SERVICE AT SEA
              </div>
              <div className="ph-certificate__content__info-text">
                Approved and authorized by the Department of Health (DOH) and
                Marine Industry Authority (MARINA) of the Republic of the
                Philippines Issued in compliance with STCW Convention, 1978, as
                amended Section A-1/9 Paragraph 7 and the Maritime Labout
                Convention, 2006
              </div>
            </div>
          </div>

          <div className="ph-certificate__content__section">
            {/* <div className="ph-certificate__content__section__header">
              Document holder details
            </div> */}
            <div className="ph-certificate__content__row">
              <div className="ph-certificate__content__data-wrapper">
                <div className="ph-certificate__content__data-wrapper__label">
                  First name
                </div>
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="text-focus">
                    {props.certificate.firstName}
                  </div>
                </div>
              </div>
              <div className="ph-certificate__content__data-wrapper">
                <div className="ph-certificate__content__data-wrapper__label">
                  Middle name
                </div>
                <div
                  className="ph-certificate__content__data-wrapper__data"
                  style={{ height: '17px' }}
                >
                  <div className="text-focus">
                    {props.certificate.middleName}
                  </div>
                </div>
              </div>
              <div className="ph-certificate__content__data-wrapper">
                <div className="ph-certificate__content__data-wrapper__label">
                  Last name
                </div>
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="text-focus">{props.certificate.lastName}</div>
                </div>
              </div>
            </div>
            <div className="ph-certificate__content__row">
              <div
                className="ph-certificate__content__data-wrapper"
                style={{ flex: '0.4 !important' }}
              >
                <div className="ph-certificate__content__data-wrapper__label">
                  Age
                </div>
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="text-focus">
                    {moment.utc().diff(props.certificate.dateOfBirth, 'years')}
                  </div>
                </div>
              </div>
              <div
                className="ph-certificate__content__data-wrapper"
                style={{ flex: '0.55 !important' }}
              >
                <div className="ph-certificate__content__data-wrapper__label">
                  Date of Birth
                </div>
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="text-focus">
                    {moment
                      .utc(props.certificate.dateOfBirth)
                      .format('DD-MMM-YYYY')}
                  </div>
                </div>
              </div>
              <div className="ph-certificate__content__data-wrapper">
                <div className="ph-certificate__content__data-wrapper__label">
                  Place of Birth
                </div>
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="text-focus">
                    {props.certificate.birthPlace}
                  </div>
                </div>
              </div>
              <div className="ph-certificate__content__data-wrapper">
                <div className="ph-certificate__content__data-wrapper__label">
                  Nationality
                </div>
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="text-focus">
                    {props.certificate.nationality}
                  </div>
                </div>
              </div>
            </div>
            <div className="ph-certificate__content__row">
              <div className="ph-certificate__content__data-wrapper">
                <div className="ph-certificate__content__data-wrapper__label">
                  Gender
                </div>
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="text-focus">{props.certificate.gender}</div>
                </div>
              </div>
              <div className="ph-certificate__content__data-wrapper">
                <div className="ph-certificate__content__data-wrapper__label">
                  Civil Status
                </div>
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="text-focus">
                    {props.certificate.civilStatus}
                  </div>
                </div>
              </div>
              <div className="ph-certificate__content__data-wrapper">
                <div className="ph-certificate__content__data-wrapper__label">
                  Religion
                </div>
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="text-focus">
                    {props.certificate.religion || 'None'}
                  </div>
                </div>
              </div>
            </div>
            <div className="ph-certificate__content__row">
              <div className="ph-certificate__content__data-wrapper">
                <div className="ph-certificate__content__data-wrapper__label">
                  Address
                </div>
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="text-focus">
                    {props.certificate.patientAddress},{' '}
                    {props.certificate.patientZip},{' '}
                    {props.certificate.patientCity},{' '}
                    {props.certificate.patientCountry}
                  </div>
                </div>
              </div>
            </div>
            <div className="ph-certificate__content__row">
              <div className="ph-certificate__content__data-wrapper">
                <div className="ph-certificate__content__data-wrapper__label">
                  Passport No.
                </div>
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="text-focus">{props.certificate.passNo}</div>
                </div>
              </div>
              <div className="ph-certificate__content__data-wrapper">
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="ph-certificate__content__data-wrapper__label">
                    Seabook No.
                  </div>
                  <div className="text-focus">
                    {props.certificate.seabookNo}
                  </div>
                </div>
              </div>
            </div>
            <div className="ph-certificate__content__row">
              <div className="ph-certificate__content__data-wrapper">
                <div className="ph-certificate__content__data-wrapper__label">
                  Position applied for
                </div>
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="text-focus">{props.certificate.position}</div>
                </div>
              </div>
              <div className="ph-certificate__content__data-wrapper ph-certificate__content__data-wrapper--half">
                <div className="ph-certificate__content__data-wrapper__label">
                  Company
                </div>
                <div className="ph-certificate__content__data-wrapper__data">
                  <div className="text-focus">{props.certificate.company}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="ph-certificate__content__data-wrapper__info-wrapper">
            <div
              className="ph-certificate__content__data-wrapper__info"
              style={{ fontWeight: 'bold' }}
            >
              DECLARATION OF THE AUTHORIZED PHYSICIAN
            </div>
          </div>
          <div className="ph-certificate__content__data-wrapper__info-wrapper">
            <div className="ph-certificate__content__data-wrapper__info">
              CONFIRMATION THAT IDENTIFICATION DOCUMENTS WERE CHECKED AT THE
              POINT OF EXAMINATION
            </div>
            <div className="ph-certificate__content__data-wrapper__info-value">
              {props.certificate.checkedDocs ? 'Yes' : 'No'}
            </div>
          </div>

          <div className="ph-certificate__content__data-wrapper__info-wrapper">
            <div className="ph-certificate__content__data-wrapper__info">
              HEARING MEETS THE STANDARDS IN STCW CODE, SECTION A-I/9?
            </div>
            <div className="ph-certificate__content__data-wrapper__info-value">
              {props.certificate.hearingStandards ? 'Yes' : 'No'}
            </div>
          </div>

          <div className="ph-certificate__content__data-wrapper__info-wrapper">
            <div className="ph-certificate__content__data-wrapper__info">
              UNAIDED HEARING SATISFACTORY?
            </div>
            <div className="ph-certificate__content__data-wrapper__info-value">
              {props.certificate.hearingSatisfactory ? 'Yes' : 'No'}
            </div>
          </div>

          <div className="ph-certificate__content__data-wrapper__info-wrapper">
            <div className="ph-certificate__content__data-wrapper__info">
              VISUAL ACUITY MEETS STANDARDS IN STCW CODE, TABLE A-I/9?
            </div>
            <div className="ph-certificate__content__data-wrapper__info-value">
              {props.certificate.visualMeetsStandards ? 'Yes' : 'No'}
            </div>
          </div>

          <div className="ph-certificate__content__data-wrapper__info-wrapper">
            <div className="ph-certificate__content__data-wrapper__info">
              COLOR VISION MEETS STANDARDS IN STCW CODE, TABLE A-I/9?
            </div>
            <div className="ph-certificate__content__data-wrapper__info-value">
              <div className="mr-4 text-micro">
                Date of last test: {props.certificate.lastColorTest}
              </div>
              {props.certificate.visionMeetsStandards ? 'Yes' : 'No'}
            </div>
          </div>

          <div className="ph-certificate__content__data-wrapper__info-wrapper">
            <div className="ph-certificate__content__data-wrapper__info">
              VISUAL AIDS (tick if worn)
            </div>
            <div className="ph-certificate__content__data-wrapper__info-value">
              {props.certificate.visualAids === 'glasses' && 'Spectacles'}
              {props.certificate.visualAids === 'contactLenses' &&
                'Contact lenses'}
              {!props.certificate.visualAids && 'None'}
            </div>
          </div>

          <div className="ph-certificate__content__data-wrapper__info-wrapper">
            <div className="ph-certificate__content__data-wrapper__info">
              FIT FOR LOOKOUT DUTIES?
            </div>
            <div className="ph-certificate__content__data-wrapper__info-value">
              {props.certificate.fitForLookout ? 'Yes' : 'No'}
            </div>
          </div>

          <div className="ph-certificate__content__data-wrapper__info-wrapper">
            <div className="ph-certificate__content__data-wrapper__info">
              NO LIMITATIONS OR RESTRICTIONS ON FITNESS?
            </div>
            <div className="ph-certificate__content__data-wrapper__info-value">
              {props.certificate.fitnessLimitation ? 'Yes' : 'No'}
            </div>
          </div>

          <div className="ph-certificate__content__data-wrapper__info-wrapper">
            <div className="ph-certificate__content__data-wrapper__info">
              IS APPLICANT SUFFERING FROM ANY MEDICAL CONDITIONS LIKELY TO BE
              AGGRAVATED BY SERVICE AT SEA OR TO RENDER THE SEAFARER UNFIT FOR
              SUCH SERVICE OR TO ENDANGER THE HEALTH OF OTHER PERSONS ON BOARD?
            </div>
            <div className="ph-certificate__content__data-wrapper__info-value">
              {props.certificate.aggravatedHealth ? 'Yes' : 'No'}
            </div>
          </div>
          <div className="ph-certificate__content__row">
            <div
              className="ph-certificate__content__data-wrapper"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img
                className="ph-certificate__content__logo"
                style={{ width: '190px', height: '190px', margin: 'auto' }}
                src={props.certificate.additionalImage}
                alt="patient"
              />
              <div className="text-tiny">
                {props.certificate.lastName}, {props.certificate.firstName}{' '}
                {props.certificate.middleName}{' '}
              </div>
            </div>
            <div
              className="ph-certificate__content__data-wrapper"
              style={{ flexBasis: '200px' }}
            >
              <div className="ph-certificate__content__column-data">
                <div className="text-tinniest">
                  This is to certify that a medical and physical examination was
                  given to:
                </div>
                <div className="text-tinniest bold mt-2">
                  {props.certificate.firstName} {props.certificate.middleName}{' '}
                  {props.certificate.lastName}
                </div>
                <span className="ph-certificate__content__sig-line" />
                <div className="text-tinniest">(Name of Seafarer)</div>
                <div className="text-tinniest bold mt-2" color="black">
                  Result:
                </div>
                <div className="text-tiny bold">
                  {props.certificate.result || 'Fit for duty'}
                </div>
                <div className="ph-certificate__content__data-wrapper__data__signature-wrapper mt-2">
                  <img
                    alt="signature"
                    src={props.certificate.physicianSignature}
                    className="ph-certificate__content__data-wrapper__data__signature"
                  />
                  <div className="text-focus">
                    {props.certificate.physicianName}
                  </div>
                </div>

                <span className="ph-certificate__content__sig-line" />
                <div className="text-tinniest">
                  Name and signature of examining/authorized Physician
                </div>
                <div style={{ display: 'flex' }}>
                  <div className="text-tinniest mt-1">
                    Date of Examination:{' '}
                  </div>
                  <div className="text-tinniest bold underline mt-1 ml-1">
                    {moment
                      .utc(props.certificate.examinationDate)
                      .format('DD MMMM YYYY')}
                  </div>
                </div>
                <div className="text-tinniest bold">Approved by:</div>
                <div className="ph-certificate__content__data-wrapper__data__signature-wrapper">
                  <img
                    alt="signature"
                    src={props.certificate.medicalDirectorSignature}
                    className="ph-certificate__content__data-wrapper__data__signature"
                  />
                  <div className="text-focus">
                    {props.certificate.medicalDirector}
                  </div>
                </div>
                <span className="ph-certificate__content__sig-line mr-4" />
                <div className="text-tinniest">Medical Director</div>
              </div>
            </div>
          </div>
          <div className="ph-certificate__content__row">
            <div
              className="ph-certificate__content__data-wrapper"
              style={{ alignItems: 'center' }}
            >
              <img
                className="ph-certificate__content__logo"
                style={{ width: '80px', height: '80px' }}
                src={props.certificate.logoImage}
                alt="stamp"
              />
            </div>
            <div
              className="ph-certificate__content__data-wrapper"
              style={{ flexBasis: '200px', padding: '10px' }}
            >
              <div style={{ display: 'flex' }}>
                <div className="text-tinniest">Name of issuing authority: </div>
                <div className="text-tinniest bold uppercase underline ml-2">
                  {props.certificate.issuingAuthority}
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className="text-tinniest">Address: </div>
                <div className="text-tinniest underline bold ml-2">
                  {props.certificate.address}, {props.certificate.zip},{' '}
                  {props.certificate.city}, {props.certificate.country}
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <div className="text-tinniest">
                  Physician's certifying authority:{' '}
                </div>
                <div className="text-tinniest uppercase underlinee boldml-2">
                  {props.certificate.authority}
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className="text-tinniest">
                  Physician's license number:{' '}
                </div>
                <div className="text-tinniest upperecase underline bold ml-2">
                  {props.certificate.physicianLicense}
                </div>
              </div>
            </div>
          </div>

          <div
            className="ph-certificate__content__row"
            style={{ padding: '0 5px' }}
          >
            <div className="ph-certificate__content__column-data">
              <div className="text-tinniest">
                I have read and understood and was informed of the contents of
                the certificate and the right to a review in accordance with the
                paragraph 6 of section A-I/9 of the STCW code
              </div>
              <div style={{ display: 'flex', marginTop: '5px' }}>
                <div>
                  <div className="text-tinniest bold" bold size="tinniest">
                    Seafarer's Name and Signature:
                  </div>
                  <div
                    style={{
                      borderBottom: 'solid 1px black',
                      width: '550px',
                      display: 'flex',
                    }}
                  >
                    <div style={{ minWidth: '200px' }}>
                      <div className="text-tinniest">
                        {props.certificate.firstName}{' '}
                        {props.certificate.middleName}{' '}
                        {props.certificate.lastName}
                      </div>
                    </div>
                    {props.certificate.signers &&
                      props.certificate.signers.length > 0 && (
                        <div style={{ position: 'relative' }}>
                          <img
                            alt="signature"
                            src={props.certificate.signers[0].image}
                            className="ph-certificate__content__data-wrapper__data__signature"
                            style={{
                              position: 'absolute',
                              left: '10px',
                              bottom: '0',
                            }}
                          />
                        </div>
                      )}
                  </div>
                  <div className="text-micro">
                    (This signature should be affixed in the presence of the
                    examining physician)
                  </div>
                </div>
                <div className="ml-4">
                  <div className="text-tinniest">Date:</div>
                  <div
                    style={{ borderBottom: 'solid 1px black', width: '150px' }}
                  >
                    <div className="text-tinniest">
                      {moment
                        .utc(props.certificate.issuedAt)
                        .format('DD MMMM YYYY')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="ph-certificate__content__row"
            style={{ borderBottom: 'solid 1px black' }}
          >
            <div
              className="ph-certificate__content__data-wrapper"
              style={{ flexDirection: 'row', justifyContent: 'initial' }}
            >
              <div className="ph-certificate__content__data-wrapper__label  mr-2">
                Date of issuance:
              </div>
              <div className="ph-certificate__content__data-wrapper__data">
                <div className="text-focus">
                  {moment
                    .utc(props.certificate.issuedAt)
                    .format('DD MMMM YYYY')}
                </div>
              </div>
            </div>

            <div
              className="ph-certificate__content__data-wrapper"
              style={{ flexDirection: 'row', justifyContent: 'initial' }}
            >
              <div className="ph-certificate__content__data-wrapper__label mr-2">
                Date of expiration:
              </div>
              <div className="ph-certificate__content__data-wrapper__data">
                <div className="text-focus">
                  {moment
                    .utc(props.certificate.validUntil)
                    .format('DD MMMM YYYY')}
                </div>
              </div>
            </div>
          </div>
          <div className="ph-certificate__footer">
            <div className="text-tiny">DOH-PEME-SB</div>
            <div className="text-tiny">Revision-01</div>
            <div className="text-tiny">10/17/2013</div>
          </div>
        </div>
      </div>
      {!props.publicView && (
        <>
          {props.certificate.drugFilePath && (
            <div className="ph-certificate__pdf">
              <Text className="ph-certificate__pdf__text" size="big">
                Drug test certificate
              </Text>
              <iframe
                title={props.certificate.drugFilePath}
                src={props.certificate.drugFilePath}
              />
            </div>
          )}
          {props.certificate.drugScreeningFilePath && (
            <div className="ph-certificate__pdf">
              <Text className="ph-certificate__pdf__text" size="big">
                Drug screening results
              </Text>
              <iframe
                title={props.certificate.drugScreeningFilePath}
                src={props.certificate.drugScreeningFilePath}
              />
            </div>
          )}
          {props.certificate.roentgenologicalFilePath && (
            <div className="ph-certificate__pdf">
              <Text className="ph-certificate__pdf__text" size="big">
                Roentgenolofical Lab Results
              </Text>
              <iframe
                title={props.certificate.roentgenologicalFilePath}
                src={props.certificate.roentgenologicalFilePath}
              />
            </div>
          )}
          {props.certificate.hivScreeningPath && (
            <div className="ph-certificate__pdf">
              <Text className="ph-certificate__pdf__text" size="big">
                HIV screening results
              </Text>
              <iframe
                title={props.certificate.hivScreeningPath}
                src={props.certificate.hivScreeningPath}
              />
            </div>
          )}
          {props.certificate.labResultsPath && (
            <div className="ph-certificate__pdf">
              <Text className="ph-certificate__pdf__text" size="big">
                Lab results
              </Text>
              <iframe
                title={props.certificate.labResultsPath}
                src={props.certificate.labResultsPath}
              />
            </div>
          )}
          {props.certificate.auditoryResultsPath && (
            <div className="ph-certificate__pdf">
              <Text className="ph-certificate__pdf__text" size="big">
                Auditory Lab Results
              </Text>
              <iframe
                title={props.certificate.auditoryResultsPath}
                src={props.certificate.auditoryResultsPath}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(PemeCertificate);
