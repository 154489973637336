import React from 'react';
import moment from 'moment';

import { Text } from 'components';
import HealthValueLine from 'features/HealthValueLine/HealthValueLine';
import HealthValueLineWrapper from 'features/HealthValueLine/HealthValueLinesWrapper';

import history from '../../../../history';

import '../../bmiPage/bmiPage.scss';

const systolicRange = [
  { text: 'Normal', subText: '<120', range: [70, 120], color: 'green' },
  {
    text: 'Elevated',
    subText: '120 - 129',
    range: [120, 129],
    color: 'yellow',
  },
  {
    text: 'High',
    subText: '130 - 139',
    range: [130, 139],
    color: 'orange',
  },
  { text: 'Very High', subText: '140-179', range: [140, 179], color: 'red' },
  { text: 'Hypertensive', subText: '≥180', range: [180, 220], color: 'red2 ' },
];
const diastolicRange = [
  { text: 'Normal', subText: '<80', range: [40, 79], color: 'green' },
  {
    text: 'High',
    subText: '80 - 89',
    range: [80, 89],
    color: 'orange',
  },
  {
    text: 'Very High',
    subText: '90 - 119',
    range: [90, 119],
    color: 'red',
  },
  { text: 'Hypertensive', subText: '≥120', range: [120, 130], color: 'red2' },
];

const heartRateRange = [
  { text: 'High Risk', subText: '<40', range: [29, 39], color: 'red2' },
  { text: 'Normal', subText: '40-100', range: [40, 100], color: 'green' },
  {
    text: 'Borderline',
    subText: '101-109',
    range: [101, 109],
    color: 'yellow',
  },
  {
    text: 'Moderate',
    subText: '110-129',
    range: [110, 129],
    color: 'orange',
  },
  {
    text: 'High Risk',
    subText: '≥130',
    range: [130, 200],
    color: 'red2',
  },
];

const bpColors = {
  Normal: { color: 'green' },
  Elevated: { color: 'yellow' },
  'High Blood Pressure': { color: 'red' },
  'Normal-High Blood Pressure': { color: 'red' },
  'High Blood Pressure (for both hypertension 1 and 2)': { color: 'red' },
  'Hypertension Crisis': { color: 'red2' },
};

const OxyPage = ({ index, row, isClient }) => {
  return (
    <div className="bmi-page__body__list__item">
      <HealthValueLineWrapper
        onClick={
          isClient
            ? () => {}
            : () =>
                history.push({
                  pathname: `/p/bp/edit/${row.id}`,
                  state: { data: row },
                })
        }
        key={index + row.id}
        title="Blood Pressure -"
        createdAt={moment(row.date).format('DD MMM YYYY, HH:mm')}
        value={{
          value: row.systolic,
          text: row.status,
        }}
        color={bpColors[row.status]?.color || 'green'}
      >
        <div style={{ display: 'flex' }}>
          <div className="bmi-page__body__line-graph-text">
            <Text size="micro">Systolic</Text>
            <Text bold>{row.systolic} mm Hg</Text>
            <Text size="nano" color="lightGrey">
              Upper Number
            </Text>
          </div>
          <HealthValueLine
            key={index + row.id}
            value={{
              value: row.systolic,
              text: row.status,
            }}
            ranges={systolicRange}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div className="bmi-page__body__line-graph-text">
            <Text size="micro">Diastolic</Text>
            <Text bold>{row.diastolic} mm Hg</Text>
            <Text size="nano" color="lightGrey">
              Lower Number
            </Text>
          </div>
          <HealthValueLine
            key={index + row.id}
            value={{
              value: row.diastolic,
              text: row.status,
            }}
            ranges={diastolicRange}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div className="bmi-page__body__line-graph-text">
            <Text size="micro">Heart Rate</Text>
            <Text bold>{row.pulse} bpm</Text>
            <Text size="nano" color="lightGrey">
              Beats Per Min
            </Text>
          </div>
          <HealthValueLine
            key={index + row.id}
            value={{
              value: row.pulse,
              text: row.status,
            }}
            ranges={heartRateRange}
          />
        </div>
      </HealthValueLineWrapper>
    </div>
  );
};

export default OxyPage;
