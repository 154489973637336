import React from 'react';

const SvgGears = props => (
  <svg width="1em" height="1em" viewBox="0 0 38 32" {...props}>
    <g
      transform="translate(2 4)"
      stroke="#1b1b1b"
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
    >
      <path d="M11.258 24.915a1.918 1.918 0 01-2.707-.726 3.293 3.293 0 00-3.017-1.742 1.918 1.918 0 01-1.981-1.981 3.293 3.293 0 00-1.742-3.017 1.918 1.918 0 01-.726-2.707 3.293 3.293 0 000-3.484 1.918 1.918 0 01.726-2.707 3.293 3.293 0 001.742-3.017 1.918 1.918 0 011.981-1.981A3.293 3.293 0 008.551 1.81a1.918 1.918 0 012.707-.726 3.293 3.293 0 003.484 0 1.918 1.918 0 012.707.726 3.293 3.293 0 003.017 1.742 1.918 1.918 0 011.981 1.981 3.293 3.293 0 001.742 3.017 1.918 1.918 0 01.726 2.707 3.293 3.293 0 000 3.484 1.918 1.918 0 01-.726 2.707 3.293 3.293 0 00-1.742 3.017 1.918 1.918 0 01-1.981 1.981 3.293 3.293 0 00-3.017 1.742 1.918 1.918 0 01-2.707.726 3.293 3.293 0 00-3.484 0z" />
      <circle cx={13} cy={13} r={7} />
      <circle cx={30} cy={4} r={3} />
    </g>
  </svg>
);

export default SvgGears;
