import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Text } from 'components';

const PemeSuccessPage = () => {
  return (
    <div className="success-page__wrapper">
      <div className="success-page__main">
        <Icon color="green" name="check circle" size="massive" />
        <Text size="big" bold className="mt-4">
          Thank you for completing the questionnaire.
        </Text>
        <Text size="big" bold className="mt-2 mb-3">
          Your responses will be reviewed by a clinician at VIKAND. Please be
          aware that you may be contacted shortly for a remote interview. This
          contact will be made via the email address you provided in the form.
        </Text>
      </div>
    </div>
  );
};

export default PemeSuccessPage;
