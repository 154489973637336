import React from 'react';

import './BreadCrumbTrail.scss';

const BreadCrumbTrail = ({ steps, activeIndex }) => {
  return (
    <div className="breadCrumbTrail__wrapper">
      {Array(steps)
        .fill()
        .map((val, index) => (
          <div
            key={index}
            className={`breadCrumbTrail__step breadCrumbTrail__${
              activeIndex - 1 >= index ? 'active' : 'inactive'
            }`}
          ></div>
        ))}
    </div>
  );
};
export default BreadCrumbTrail;
