import * as React from 'react';

const SvgDeclined = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '17px', marginRight: '10px' }}
    {...props}
  >
    <g clipPath="url(#clip0_99_514)">
      <path
        d="M18.5811 2.14073L12.3161 0.0517291C12.111 -0.016592 11.8893 -0.016592 11.6841 0.0517291L5.41812 2.14073C4.42222 2.47178 3.55594 3.10823 2.94236 3.95967C2.32878 4.81111 1.99909 5.83424 2.00012 6.88373V12.0007C2.00012 19.5637 11.2001 23.7407 11.5941 23.9147C11.7219 23.9715 11.8603 24.0009 12.0001 24.0009C12.14 24.0009 12.2783 23.9715 12.4061 23.9147C12.8001 23.7407 22.0001 19.5637 22.0001 12.0007V6.88373C22.0011 5.83412 21.6713 4.81089 21.0575 3.95944C20.4437 3.10799 19.5772 2.47161 18.5811 2.14073ZM20.0001 12.0007C20.0001 17.4557 13.6811 21.0337 12.0001 21.8897C10.3171 21.0367 4.00012 17.4697 4.00012 12.0007V6.88373C4.00011 6.25413 4.19818 5.64048 4.56628 5.1297C4.93438 4.61892 5.45386 4.23689 6.05112 4.03773L12.0001 2.05473L17.9481 4.03773C18.5456 4.23672 19.0653 4.61868 19.4336 5.12947C19.8018 5.64027 20.0001 6.25401 20.0001 6.88373V12.0007Z"
        fill={props?.color || '#25282C'}
      />
      <path
        d="M15.7417 13.4945L13.2472 11L15.7417 8.50554C15.9071 8.34015 16 8.11583 16 7.88192C16 7.64802 15.9071 7.4237 15.7417 7.25831C15.5763 7.09292 15.352 7 15.1181 7C14.8842 7 14.6599 7.09292 14.4945 7.25831L12 9.75277L9.50554 7.25831C9.34015 7.09292 9.11583 7 8.88193 7C8.64802 7 8.4237 7.09292 8.25831 7.25831C8.09292 7.4237 8 7.64802 8 7.88192C8 8.11583 8.09292 8.34015 8.25831 8.50554L10.7528 11L8.25831 13.4945C8.09292 13.6599 8 13.8842 8 14.1181C8 14.352 8.09292 14.5763 8.25831 14.7417C8.4237 14.9071 8.64802 15 8.88192 15C9.11583 15 9.34015 14.9071 9.50554 14.7417L12 12.2472L14.4945 14.7417C14.6599 14.9071 14.8842 15 15.1181 15C15.352 15 15.5763 14.9071 15.7417 14.7417C15.9071 14.5763 16 14.352 16 14.1181C16 13.8842 15.9071 13.6599 15.7417 13.4945Z"
        fill={props?.color || '#25282C'}
      />
    </g>
    <defs>
      <clipPath id="clip0_99_514">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDeclined;
