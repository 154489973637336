import React from 'react';

const SvgRecent = props => (
  <svg width="1em" height="1em" viewBox="0 0 36 32" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#1b1b1b" strokeWidth={3}>
      <g strokeLinecap="round">
        <path d="M4 12C6.14 6.147 11.475 2 17.717 2 24.266 2 30.09 5.69 32 12" />
        <path d="M.335 9.036L3.4 15.892l5.915-5.47" />
      </g>
      <g transform="translate(9 11)">
        <circle cx={9} cy={9} r={9} />
        <path strokeLinecap="round" d="M9 5v4.652L11 12" />
      </g>
    </g>
  </svg>
);

export default SvgRecent;
