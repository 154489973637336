import moment from 'moment';
const utcOffset = 8;

const dateTimeFormat = 'DD-MM-YYYY, HH:mm';

function momentSg(date){
  return moment(date).utcOffset(utcOffset);
}

function now(){
  return moment().utcOffset(utcOffset).toDate();
}

export {
  momentSg,
  now,
  dateTimeFormat
}

