import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={7} cy={7} r={7} fill="#E1E2E8" />
      <path
        stroke="#616C8D"
        strokeWidth={2.471}
        strokeLinecap="round"
        d="M7 2.882v4.942"
      />
      <circle cx={7} cy={11.118} r={1.235} fill="#616C8D" />
    </svg>
  );
}

export default SvgComponent;
