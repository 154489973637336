import React from 'react';

const SvgRiverr = (props) => (
  <svg
    width={props?.width || '12'}
    height={props?.height || '12'}
    viewBox="0 0 12 12"
    fill={props?.color || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.86902 4.16771C6.38418 4.65255 5.5981 4.65255 5.11326 4.16771L3.14497 2.19942C2.7571 1.81154 2.7571 1.18268 3.14497 0.794809C3.53284 0.406938 4.1617 0.406939 4.54957 0.79481L6.73041 2.97565C7.10004 3.34528 7.58056 3.30832 7.76538 3.27135L6.87826 4.15847L6.87823 4.15834L6.87535 4.16131L6.87239 4.16432L6.86902 4.16771Z"
      fill="white"
    />
    <path
      d="M6.86902 7.81457C6.38418 7.32973 5.5981 7.32973 5.11326 7.81457C5.11016 7.81768 5.10709 7.8208 5.10405 7.82394L5.10402 7.82381L4.2169 8.71093C4.40172 8.67397 4.88224 8.63701 5.25188 9.00664L7.43271 11.1875C7.82058 11.5753 8.44945 11.5753 8.83732 11.1875C9.22519 10.7996 9.22519 10.1707 8.83732 9.78287L6.86902 7.81457Z"
      fill="white"
    />
    <path
      d="M5.03009 9.2284C4.66263 8.86094 4.06687 8.86094 3.69941 9.2284L3.108 9.81981C2.74054 10.1873 2.74054 10.783 3.108 11.1505C3.47546 11.5179 4.07122 11.5179 4.43868 11.1505L5.69543 9.89374L5.03009 9.2284Z"
      fill="white"
    />
    <path
      d="M6.95219 2.75389C7.31965 3.12134 7.91542 3.12134 8.28287 2.75389L8.87429 2.16247C9.24174 1.79502 9.24174 1.19925 8.87429 0.831794C8.50683 0.464337 7.91106 0.464337 7.54361 0.831794L6.28685 2.08855L6.95219 2.75389Z"
      fill="white"
    />
    <path
      d="M4.16771 5.11329C4.65255 5.59813 4.65255 6.38421 4.16771 6.86905L2.19942 8.83734C1.81154 9.22521 1.18268 9.22521 0.79481 8.83734C0.406938 8.44947 0.406939 7.82061 0.79481 7.43274L2.97565 5.2519C3.34528 4.88227 3.30832 4.40175 3.27135 4.21693L4.15847 5.10405L4.15834 5.10408C4.16149 5.10712 4.16461 5.11019 4.16771 5.11329Z"
      fill="white"
    />
    <path
      d="M2.75389 5.03009C3.12134 4.66263 3.12134 4.06687 2.75389 3.69941L2.16247 3.108C1.79502 2.74054 1.19925 2.74054 0.831794 3.108C0.464337 3.47546 0.464337 4.07122 0.831794 4.43868L2.08855 5.69543L2.75389 5.03009Z"
      fill="white"
    />
    <path
      d="M7.81457 6.869C7.32973 6.38416 7.32973 5.59808 7.81457 5.11324L9.78287 3.14494C10.1707 2.75707 10.7996 2.75707 11.1875 3.14494C11.5753 3.53281 11.5753 4.16168 11.1875 4.54955L9.00664 6.73038C8.63701 7.10002 8.67397 7.58054 8.71093 7.76536L7.82381 6.87824L7.82394 6.8782C7.8208 6.87517 7.81768 6.8721 7.81457 6.869Z"
      fill="white"
    />
    <path
      d="M9.2284 6.95219C8.86094 7.31965 8.86094 7.91542 9.2284 8.28287L9.81981 8.87429C10.1873 9.24174 10.783 9.24174 11.1505 8.87429C11.5179 8.50683 11.5179 7.91106 11.1505 7.54361L9.89374 6.28685L9.2284 6.95219Z"
      fill="white"
    />
  </svg>
);

export default SvgRiverr;
