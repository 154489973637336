import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { Icon, Text } from 'components';

import './accordion.scss';

const Accordion = ({
  id,
  children,
  headerText,
  headerTextId,
  status,
  openOne,
  setOpenOne,
}) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (openOne === id) setShowContent(true);
  }, [openOne]);

  const onShowContent = () => {
    setShowContent(!showContent);
  };
  return (
    <div id={id} className="accordion-wrapper">
      <div className="accordion-wrapper__head" onClick={onShowContent}>
        <Text size="medium" center dark id={headerTextId}>
          {headerText}
        </Text>
        <div className="accordion-wrapper__head__right">
          {status && <Text size="small" className="accordion-wrapper__head__status">
            <div
              className={`accordion-wrapper__head__status__dot ${String(status)
                ?.replace(' ', '-')
                .toLowerCase()}`}
            />
            {status}
          </Text>}
          <div className="accordion-wrapper__head__caret">
            {showContent ? (
              <Icon name="angleDown" style={{ marginTop: '7px' }} />
            ) : (
              <Icon name="angleRight" style={{ marginTop: '7px' }} />
            )}
          </div>
        </div>
      </div>
      {showContent && (
        <div className="accordion-wrapper__content">{children}</div>
      )}
    </div>
  );
};

export default Accordion;
