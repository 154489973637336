import { SET_TIMESLOTS, SET_TYPES, SET_PEOPLE } from './calendar.actions';

const initialState = {
  timeSlots: [],
  people: [],
  types: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TIMESLOTS:
      return {
        ...state,
        timeSlots: action.payload,
      };
    case SET_TYPES:
      return {
        ...state,
        types: action.payload,
      };
    case SET_PEOPLE:
      return {
        ...state,
        people: action.payload,
      };

    default:
      return state;
  }
};
