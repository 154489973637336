import React from 'react';
import moment from 'moment';

import './singPDT.scss';

const SingPDT = props => {
  return (
    <div className="cert-wrapper" ref={props.certRef}>
      <div className="sing-pdt">
        {props.certificate.logoImage && (
          <div className="sing-pdt__logo-wrapper">
            <img
              className="sing-pdt__logo-wrapper__logo"
              src={props.certificate.logoImage}
              alt="logo"
            />
          </div>
        )}
        <h1 className="sing-pdt__header">FIT TO TRAVEL</h1>
        <h1 className="sing-pdt__header-bottom">CERTIFICATE</h1>

        <section>
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Name of Person:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.holderName}
            </div>
          </div>
          {props.certificate.passNo && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">Passport Number:</div>
              <div className="sing-pdt__row__data">
                {props.certificate.passNo}
              </div>
            </div>
          )}
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Nationality:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.nationality}
            </div>
          </div>
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Date of Birth:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.issuedAt < '2021-02-18T09:03:00.000Z'
                ? moment(props.certificate.dateOfBirth).format('DD MMMM YYYY')
                : moment
                    .utc(props.certificate.dateOfBirth)
                    .format('DD MMMM YYYY')}
            </div>
          </div>
        </section>

        <section className="sing-pdt__info-text">
          <p>
            This is to certify that seafarer{' '}
            {props.certificate.gender === 'Male' ? 'Mr.' : 'Ms.'}{' '}
            {props.certificate.holderName} at has completed the home quarantine
            monitoring program, has negative RT-PCR and has no medical concerns
            noted at the time of assessment.
          </p>
          <p>
            {props.certificate.gender === 'Male' ? 'He' : 'She'} is fit to fly,
            or board an aircraft, to country of destination with no overt
            indications to advise otherwise.
          </p>
          <p style={{ fontWeight: 'bold' }}>Other remarks/recommendations</p>
          <p>{props.certificate.certificateInformation}</p>
        </section>

        <section className="sing-pdt__bottom-names">
          <p>
            <span style={{ fontWeight: 'bold' }}>Name of Doctor:</span>{' '}
            {props.certificate.physicianName}
            <br />
            <div className="sing-pdt__signature-wrapper">
              {props.certificate.physicianSignature && (
                <img
                  className="sing-pdt__signature-wrapper__sig"
                  src={props.certificate.physicianSignature}
                  alt="signature"
                />
              )}
            </div>
            <br />
            <span style={{ fontWeight: 'bold' }}>PRC No.:</span>{' '}
            {props.certificate.physicianLicense}
          </p>
        </section>

        {props.showQrCode && (
          <div className="sing-pdt__qr-code sing-pdt__qr-code--top">
            <img
              className="sing-pdt__qr-code__img--small"
              src={props.certificate.qrCodeImage}
              alt="qrcode"
            />
          </div>
        )}
        {props.certificate.stampImage && (
          <img
            className="sing-pdt__stamp"
            src={props.certificate.stampImage}
            alt="stamp"
          />
        )}
        {props.certificate.watermarkImage && (
          <img
            className="sing-pdt__watermark"
            src={props.certificate.watermarkImage}
            alt="watermark"
          />
        )}
        <div className="sing-pdt__address-wrapper">
          <p className="sing-pdt__address">{props.certificate.issuerAddress}</p>
          {props.certificate.telephone && (
            <p className="sing-pdt__address">
              Tel no: {props.certificate.telephone}
            </p>
          )}
          {props.certificate.additionalData && (
            <p className="sing-pdt__address">
              {props.certificate.additionalData}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingPDT;
