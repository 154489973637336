import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Table, Modal } from 'semantic-ui-react';

import { Input, SuccessToast, ErrorToast, Button, Text } from 'components';
import ResultService from 'api/result';

import './sendResultsModal.scss';
import { useSelector } from 'react-redux';

const SendResultsModal = ({
  results,
  fetchResults,
  toggleModal,
  isModalToggled,
}) => {
  const [isSending, setIsSending] = useState(false);
  const [selectedResults, setSelectedResults] = useState([]);

  const clientInfo = useSelector((state) => state.general.clientInformation);

  useEffect(() => {
    const rez = Object.keys(results).map((key) => ({
      ...results[key],
      id: key,
    }));
    setSelectedResults(rez);
  }, [results]);

  const sendResults = async () => {
    let error = false;
    try {
      setIsSending(true);
      const formattedResults = selectedResults.map((res) => {
        if (!res.patient.email || res.patient.email === '') error = true;
        return { id: res.id, email: res.patient.email };
      });
      if (error) {
        setIsSending(false);
        return toast.error(<ErrorToast message="All emails required" />);
      }
      await ResultService.sendResults(formattedResults, clientInfo.clientId);
      fetchResults({});
      toast.success(<SuccessToast message="Emails sent" />);
      setIsSending(false);
      toggleModal(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsSending(false);
    }
  };

  const onResultChange = (resId, value) => {
    const res = selectedResults.map((res) => {
      if (res.id === resId) {
        res.patient.email = value;
      }
      return res;
    });
    setSelectedResults(res);
  };

  const renderTableData = () => {
    return selectedResults.map((result) => {

      return (
        <Table.Row key={result.id}>
          <Table.Cell>
            <Text size="small">{result.patient.fullName}</Text>
          </Table.Cell>
          <Table.Cell>
            <Text size="small">{result.display}</Text>
          </Table.Cell>
          <Table.Cell>
            {result.hasPdf ? (
              <Input
                size="fullWidth"
                border
                value={result.patient.email}
                onChange={(e) => onResultChange(result.id, e.target.value)}
              />
            ) : (
              <Text size="small" color="red">
                No lab report
              </Text>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <Modal
      className="modal-full send-results"
      open={isModalToggled}
      onClose={() => toggleModal(false)}
      closeIcon
      size="large"
    >
      <Modal.Header>Send lab reports</Modal.Header>
      <Modal.Content>
        <div className="send-results__content">
          <Text className="mb-3 send-results__content__header" bold>
            An email with the lab report attached as a PDF will be sent to the
            provided email address
          </Text>
          <Table className="send-results__table" striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Patient</Table.HeaderCell>
                <Table.HeaderCell>Result</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{renderTableData()}</Table.Body>
          </Table>
          <Button
            className="send-results__button"
            size="fullWidth"
            onClick={sendResults}
            isLoading={isSending}
            color="blue"
          >
            Send
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default SendResultsModal;
