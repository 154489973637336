import React from 'react';
import { Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router';

import { Text, Button } from 'components';

import './successPage.scss';

const SuccessPage = (props) => {
  const onContinue = () => {
    props.history.push({
      pathname: '/',
      search: `?email=${encodeURIComponent(props.match.params.email)}&type=p`,
    });
  };

  return (
    <div className="p-suc-pg">
      {/* <div className="u-patient-form__header">
        <Text size="bigger" dark>
          Account registration
        </Text>
      </div> */}
      <div className="p-suc-pg__content">
        <Icon name="check circle" color="green" size="huge" />
        <Text dark size="bigger" className="mt-4">
          Your Profile has been successfully setup
        </Text>
        <div>
          <Text className="mt-4 p-suc-pg__content__text">
            An email has been sent to{' '}
            <span style={{ fontWeight: 'bold' }}>
              {props.match.params.email}
            </span>{' '}
            to verify your account.
          </Text>
        </div>

        <Button
          className="p-suc-pg__content__button"
          size="fullWidth"
          color="blue"
          onClick={onContinue}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default withRouter(SuccessPage);
