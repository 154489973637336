import React, { useState } from 'react';
import { Text, DatePicker, Search } from 'components';
import { Table, Pagination  } from 'semantic-ui-react';
import moment from 'moment';

import './requestsOverview.scss';

const RequestsOverview = ({ searchParams, updateSearchParams, isSearching, consumableOrders = [], paginationInfo, setPaginationInfo }) => {
  const handlePaginationChange = (e, { activePage }) => {
    updateSearchParams({ ...searchParams, page: activePage })
  };

  return (
    <div className='cr-req-o'>
      <div style={{ display: 'flex', marginTop: '15px' }}>
        <div className="mr-3" style={{ position: 'relative' }}>
          <Text
            id="results_inbox.from_"
            size="tinniest"
            color="lightGrey"
            className="cr-req-o__date-tag"
          >
            From
          </Text>
          <DatePicker
            onChange={(date) =>
              updateSearchParams({ ...searchParams, fromDate: date || '' })
            }
            value={searchParams.fromDate}
            maxDate={searchParams.toDate}
            disa
          />
        </div>

        <div style={{ position: 'relative', marginRight: '30px' }}>
          <Text
            id="results_inbox.to"
            size="tinniest"
            color="lightGrey"
            className="cr-req-o__date-tag"
          >
            To
          </Text>
          <DatePicker
            className="lab-documents__date"
            onChange={(date) =>
              updateSearchParams({ ...searchParams, toDate: date || '' })
            }
            value={searchParams.toDate}
            minDate={searchParams.fromDate}
            clearIcon={true}
          />
        </div>
        <Search
          size="fullWidth"
          dropdown={false}
          onSearchChange={(e, { value }) => updateSearchParams({...searchParams , search: value})}
          isFilter={true}
          value={searchParams.search}
          isLoading={isSearching}
        />
      </div>
      <Table className='cr-req-o__table'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Reqeustor</Table.HeaderCell>
            <Table.HeaderCell>Order Date</Table.HeaderCell>
            <Table.HeaderCell>Order</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {consumableOrders.map((consumable) => (
            <RequestRow consumable={consumable} />
          ))}
        </Table.Body>
      </Table>
      {!isSearching && paginationInfo?.totalPages > 1 && paginationInfo?.page && (
        <Pagination
          size="small"
          activePage={paginationInfo.page}
          onPageChange={handlePaginationChange}
          totalPages={paginationInfo?.totalPages}
          firstItem={null}
          lastItem={null}
        />
      )}
    </div>
  );
};

const groupByType = (orderItems) => {
  return orderItems.reduce((acc, currentItem) => {
      const { type, ...rest } = currentItem;
      if (!acc[type]) {
          acc[type] = [];
      }
      acc[type].push(rest);
      return acc;
  }, {});
}
const RequestRow = ({ consumable }) => {
  const fOrder = groupByType(consumable.order);
  return (
    <Table.Row className='cr-req-o__row'>
      <Table.Cell>
        <Text bold>
          {consumable.orderer.clinicName}
        </Text>
        <Text>
          {consumable.orderer.name}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text>
          {moment(consumable.createdAt).format('DD-MM-YYYY HH:mm')}
        </Text>
      </Table.Cell>
      <Table.Cell>
        {Object.keys(fOrder).map(key => (
          <div className='mt-2'>
            <Text size="tiny" bold className="mr-2">{key}</Text>
            {fOrder[key].map(item => (
              <Text size="tiny">{item.quantity} - {item.name}</Text>
            ))}
          </div>
        ))}
      </Table.Cell>
    </ Table.Row>
  );
}

export default RequestsOverview;
