import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const useCustomForm = (schema, defaultValues = {}) => {
  const { ...other } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  return {
    Controller,
    ...other,
  };
};
export { FormProvider, useFormContext, useFieldArray };
