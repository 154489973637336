export default [
    {
      value: 'afghanistan',
      key: 'af',
    },
    {
      value: 'åland islands',
      key: 'ax',
    },
    {
      value: 'albania',
      key: 'al',
    },
    {
      value: 'algeria',
      key: 'dz',
    },
    {
      value: 'american samoa',
      key: 'as',
    },
    {
      value: 'andorra',
      key: 'ad',
    },
    {
      value: 'angola',
      key: 'ao',
    },
    {
      value: 'anguilla',
      key: 'ai',
    },
    {
      value: 'antarctica',
      key: 'aq',
    },
    {
      value: 'antigua and barbuda',
      key: 'ag',
    },
    {
      value: 'argentina',
      key: 'ar',
    },
    {
      value: 'armenia',
      key: 'am',
    },
    {
      value: 'aruba',
      key: 'aw',
    },
    {
      value: 'australia',
      key: 'au',
    },
    {
      value: 'austria',
      key: 'at',
    },
    {
      value: 'azerbaijan',
      key: 'az',
    },
    {
      value: 'bahamas',
      key: 'bs',
    },
    {
      value: 'bahrain',
      key: 'bh',
    },
    {
      value: 'bangladesh',
      key: 'bd',
    },
    {
      value: 'barbados',
      key: 'bb',
    },
    {
      value: 'belarus',
      key: 'by',
    },
    {
      value: 'belgium',
      key: 'be',
    },
    {
      value: 'belize',
      key: 'bz',
    },
    {
      value: 'benin',
      key: 'bj',
    },
    {
      value: 'bermuda',
      key: 'bm',
    },
    {
      value: 'bhutan',
      key: 'bt',
    },
    {
      value: 'bolivia',
      key: 'bo',
    },
    {
      value: 'bosnia and herzegovina',
      key: 'ba',
    },
    {
      value: 'botswana',
      key: 'bw',
    },
    {
      value: 'bouvet island',
      key: 'bv',
    },
    {
      value: 'brazil',
      key: 'br',
    },
    {
      value: 'british indian ocean territory',
      key: 'io',
    },
    {
      value: 'brunei darussalam',
      key: 'bn',
    },
    {
      value: 'bulgaria',
      key: 'bg',
    },
    {
      value: 'burkina faso',
      key: 'bf',
    },
    {
      value: 'burundi',
      key: 'bi',
    },
    {
      value: 'cambodia',
      key: 'kh',
    },
    {
      value: 'cameroon',
      key: 'cm',
    },
    {
      value: 'canada',
      key: 'ca',
    },
    {
      value: 'cape verde',
      key: 'cv',
    },
    {
      value: 'cayman islands',
      key: 'ky',
    },
    {
      value: 'central african republic',
      key: 'cf',
    },
    {
      value: 'chad',
      key: 'td',
    },
    {
      value: 'chile',
      key: 'cl',
    },
    {
      value: 'china',
      key: 'cn',
    },
    {
      value: 'christmas island',
      key: 'cx',
    },
    {
      value: 'cocos (keeling) islands',
      key: 'cc',
    },
    {
      value: 'colombia',
      key: 'co',
    },
    {
      value: 'comoros',
      key: 'km',
    },
    {
      value: 'congo',
      key: 'cg',
    },
    {
      value: 'congo, the democratic republic of the',
      key: 'cd',
    },
    {
      value: 'cook islands',
      key: 'ck',
    },
    {
      value: 'costa rica',
      key: 'cr',
    },
    {
      value: "cote d'ivoire",
      key: 'ci',
    },
    {
      value: 'croatia',
      key: 'hr',
    },
    {
      value: 'cuba',
      key: 'cu',
    },
    {
      value: 'cyprus',
      key: 'cy',
    },
    {
      value: 'czech republic',
      key: 'cz',
    },
    {
      value: 'denmark',
      key: 'dk',
    },
    {
      value: 'djibouti',
      key: 'dj',
    },
    {
      value: 'dominica',
      key: 'dm',
    },
    {
      value: 'dominican republic',
      key: 'do',
    },
    {
      value: 'ecuador',
      key: 'ec',
    },
    {
      value: 'egypt',
      key: 'eg',
    },
    {
      value: 'el salvador',
      key: 'sv',
    },
    {
      value: 'equatorial guinea',
      key: 'gq',
    },
    {
      value: 'eritrea',
      key: 'er',
    },
    {
      value: 'estonia',
      key: 'ee',
    },
    {
      value: 'ethiopia',
      key: 'et',
    },
    {
      value: 'falkland islands (malvinas)',
      key: 'fk',
    },
    {
      value: 'faroe islands',
      key: 'fo',
    },
    {
      value: 'fiji',
      key: 'fj',
    },
    {
      value: 'finland',
      key: 'fi',
    },
    {
      value: 'france',
      key: 'fr',
    },
    {
      value: 'french guiana',
      key: 'gf',
    },
    {
      value: 'french polynesia',
      key: 'pf',
    },
    {
      value: 'french southern territories',
      key: 'tf',
    },
    {
      value: 'gabon',
      key: 'ga',
    },
    {
      value: 'gambia',
      key: 'gm',
    },
    {
      value: 'georgia',
      key: 'ge',
    },
    {
      value: 'germany',
      key: 'de',
    },
    {
      value: 'ghana',
      key: 'gh',
    },
    {
      value: 'gibraltar',
      key: 'gi',
    },
    {
      value: 'greece',
      key: 'gr',
    },
    {
      value: 'greenland',
      key: 'gl',
    },
    {
      value: 'grenada',
      key: 'gd',
    },
    {
      value: 'guadeloupe',
      key: 'gp',
    },
    {
      value: 'guam',
      key: 'gu',
    },
    {
      value: 'guatemala',
      key: 'gt',
    },
    {
      value: 'guernsey',
      key: 'gg',
    },
    {
      value: 'guinea',
      key: 'gn',
    },
    {
      value: 'guinea-bissau',
      key: 'gw',
    },
    {
      value: 'guyana',
      key: 'gy',
    },
    {
      value: 'haiti',
      key: 'ht',
    },
    {
      value: 'heard island and mcdonald islands',
      key: 'hm',
    },
    {
      value: 'holy see (vatican city state)',
      key: 'va',
    },
    {
      value: 'honduras',
      key: 'hn',
    },
    {
      value: 'hong kong',
      key: 'hk',
    },
    {
      value: 'hungary',
      key: 'hu',
    },
    {
      value: 'iceland',
      key: 'is',
    },
    {
      value: 'india',
      key: 'in',
    },
    {
      value: 'indonesia',
      key: 'id',
    },
    {
      value: 'iran, islamic republic of',
      key: 'ir',
    },
    {
      value: 'iraq',
      key: 'iq',
    },
    {
      value: 'ireland',
      key: 'ie',
    },
    {
      value: 'isle of man',
      key: 'im',
    },
    {
      value: 'israel',
      key: 'il',
    },
    {
      value: 'italy',
      key: 'it',
    },
    {
      value: 'jamaica',
      key: 'jm',
    },
    {
      value: 'japan',
      key: 'jp',
    },
    {
      value: 'jersey',
      key: 'je',
    },
    {
      value: 'jordan',
      key: 'jo',
    },
    {
      value: 'kazakhstan',
      key: 'kz',
    },
    {
      value: 'kenya',
      key: 'ke',
    },
    {
      value: 'kiribati',
      key: 'ki',
    },
    {
      value: "korea, democratic people's republic of",
      key: 'kp',
    },
    {
      value: 'korea, republic of',
      key: 'kr',
    },
    {
      value: 'kosovo',
      key: 'xk',
    },
    {
      value: 'kuwait',
      key: 'kw',
    },
    {
      value: 'kyrgyzstan',
      key: 'kg',
    },
    {
      value: "lao people's democratic republic",
      key: 'la',
    },
    {
      value: 'latvia',
      key: 'lv',
    },
    {
      value: 'lebanon',
      key: 'lb',
    },
    {
      value: 'lesotho',
      key: 'ls',
    },
    {
      value: 'liberia',
      key: 'lr',
    },
    {
      value: 'libyan arab jamahiriya',
      key: 'ly',
    },
    {
      value: 'liechtenstein',
      key: 'li',
    },
    {
      value: 'lithuania',
      key: 'lt',
    },
    {
      value: 'luxembourg',
      key: 'lu',
    },
    {
      value: 'macao',
      key: 'mo',
    },
    {
      value: 'macedonia, the former yugoslav republic of',
      key: 'mk',
    },
    {
      value: 'madagascar',
      key: 'mg',
    },
    {
      value: 'malawi',
      key: 'mw',
    },
    {
      value: 'malaysia',
      key: 'my',
    },
    {
      value: 'maldives',
      key: 'mv',
    },
    {
      value: 'mali',
      key: 'ml',
    },
    {
      value: 'malta',
      key: 'mt',
    },
    {
      value: 'marshall islands',
      key: 'mh',
    },
    {
      value: 'martinique',
      key: 'mq',
    },
    {
      value: 'mauritania',
      key: 'mr',
    },
    {
      value: 'mauritius',
      key: 'mu',
    },
    {
      value: 'mayotte',
      key: 'yt',
    },
    {
      value: 'mexico',
      key: 'mx',
    },
    {
      value: 'micronesia, federated states of',
      key: 'fm',
    },
    {
      value: 'moldova, republic of',
      key: 'md',
    },
    {
      value: 'monaco',
      key: 'mc',
    },
    {
      value: 'mongolia',
      key: 'mn',
    },
    {
      value: 'montserrat',
      key: 'ms',
    },
    {
      value: 'morocco',
      key: 'ma',
    },
    {
      value: 'mozambique',
      key: 'mz',
    },
    {
      value: 'myanmar',
      key: 'mm',
    },
    {
      value: 'namibia',
      key: 'na',
    },
    {
      value: 'nauru',
      key: 'nr',
    },
    {
      value: 'nepal',
      key: 'np',
    },
    {
      value: 'netherlands',
      key: 'nl',
    },
    {
      value: 'netherlands antilles',
      key: 'an',
    },
    {
      value: 'new caledonia',
      key: 'nc',
    },
    {
      value: 'new zealand',
      key: 'nz',
    },
    {
      value: 'nicaragua',
      key: 'ni',
    },
    {
      value: 'niger',
      key: 'ne',
    },
    {
      value: 'nigeria',
      key: 'ng',
    },
    {
      value: 'niue',
      key: 'nu',
    },
    {
      value: 'norfolk island',
      key: 'nf',
    },
    {
      value: 'northern mariana islands',
      key: 'mp',
    },
    {
      value: 'norway',
      key: 'no',
    },
    {
      value: 'oman',
      key: 'om',
    },
    {
      value: 'pakistan',
      key: 'pk',
    },
    {
      value: 'palau',
      key: 'pw',
    },
    {
      value: 'palestinian territory, occupied',
      key: 'ps',
    },
    {
      value: 'panama',
      key: 'pa',
    },
    {
      value: 'papua new guinea',
      key: 'pg',
    },
    {
      value: 'paraguay',
      key: 'py',
    },
    {
      value: 'peru',
      key: 'pe',
    },
    {
      value: 'philippines',
      key: 'ph',
    },
    {
      value: 'pitcairn',
      key: 'pn',
    },
    {
      value: 'poland',
      key: 'pl',
    },
    {
      value: 'portugal',
      key: 'pt',
    },
    {
      value: 'puerto rico',
      key: 'pr',
    },
    {
      value: 'qatar',
      key: 'qa',
    },
    {
      value: 'reunion',
      key: 're',
    },
    {
      value: 'romania',
      key: 'ro',
    },
    {
      value: 'russian federation',
      key: 'ru',
    },
    {
      value: 'rwanda',
      key: 'rw',
    },
    {
      value: 'saint helena',
      key: 'sh',
    },
    {
      value: 'saint kitts and nevis',
      key: 'kn',
    },
    {
      value: 'saint lucia',
      key: 'lc',
    },
    {
      value: 'saint pierre and miquelon',
      key: 'pm',
    },
    {
      value: 'saint vincent and the grenadines',
      key: 'vc',
    },
    {
      value: 'samoa',
      key: 'ws',
    },
    {
      value: 'san marino',
      key: 'sm',
    },
    {
      value: 'sao tome and principe',
      key: 'st',
    },
    {
      value: 'saudi arabia',
      key: 'sa',
    },
    {
      value: 'senegal',
      key: 'sn',
    },
    {
      value: 'serbia and montenegro',
      key: 'cs',
    },
    {
      value: 'seychelles',
      key: 'sc',
    },
    {
      value: 'sierra leone',
      key: 'sl',
    },
    {
      value: 'singapore',
      key: 'sg',
    },
    {
      value: 'slovakia',
      key: 'sk',
    },
    {
      value: 'slovenia',
      key: 'si',
    },
    {
      value: 'solomon islands',
      key: 'sb',
    },
    {
      value: 'somalia',
      key: 'so',
    },
    {
      value: 'south africa',
      key: 'za',
    },
    {
      value: 'south georgia and the south sandwich islands',
      key: 'gs',
    },
    {
      value: 'spain',
      key: 'es',
    },
    {
      value: 'sri lanka',
      key: 'lk',
    },
    {
      value: 'sudan',
      key: 'sd',
    },
    {
      value: 'suritext',
      key: 'sr',
    },
    {
      value: 'svalbard and jan mayen',
      key: 'sj',
    },
    {
      value: 'swaziland',
      key: 'sz',
    },
    {
      value: 'sweden',
      key: 'se',
    },
    {
      value: 'switzerland',
      key: 'ch',
    },
    {
      value: 'syrian arab republic',
      key: 'sy',
    },
    {
      value: 'republic of china',
      key: 'tw',
    },
    {
      value: 'tajikistan',
      key: 'tj',
    },
    {
      value: 'tanzania, united republic of',
      key: 'tz',
    },
    {
      value: 'thailand',
      key: 'th',
    },
    {
      value: 'timor-leste',
      key: 'tl',
    },
    {
      value: 'togo',
      key: 'tg',
    },
    {
      value: 'tokelau',
      key: 'tk',
    },
    {
      value: 'tonga',
      key: 'to',
    },
    {
      value: 'trinidad and tobago',
      key: 'tt',
    },
    {
      value: 'tunisia',
      key: 'tn',
    },
    {
      value: 'turkey',
      key: 'tr',
    },
    {
      value: 'turkmenistan',
      key: 'tm',
    },
    {
      value: 'turks and caicos islands',
      key: 'tc',
    },
    {
      value: 'tuvalu',
      key: 'tv',
    },
    {
      value: 'uganda',
      key: 'ug',
    },
    {
      value: 'ukraine',
      key: 'ua',
    },
    {
      value: 'united arab emirates',
      key: 'ae',
    },
    {
      value: 'united kingdom',
      key: 'gb',
    },
    {
      value: 'united states',
      key: 'us',
    },
    {
      value: 'united states minor outlying islands',
      key: 'um',
    },
    {
      value: 'uruguay',
      key: 'uy',
    },
    {
      value: 'uzbekistan',
      key: 'uz',
    },
    {
      value: 'vanuatu',
      key: 'vu',
    },
    {
      value: 'venezuela',
      key: 've',
    },
    {
      value: 'vietnam',
      key: 'vn',
    },
    {
      value: 'virgin islands, british',
      key: 'vg',
    },
    {
      value: 'virgin islands, u.s.',
      key: 'vi',
    },
    {
      value: 'wallis and futuna',
      key: 'wf',
    },
    {
      value: 'western sahara',
      key: 'eh',
    },
    {
      value: 'yemen',
      key: 'ye',
    },
    {
      value: 'zambia',
      key: 'zm',
    },
    {
      value: 'zimbabwe',
      key: 'zw',
    },
      {
        value: 'afghan',
        key: 'af',
      },
      {
        value: 'albanian',
        key: 'al',
      },
      {
        value: 'algerian',
        key: 'dz',
      },
      {
        value: 'american',
        key: 'us',
      },
      {
        value: 'andorran',
        key: 'ad',
      },
      {
        value: 'angolan',
        key: 'ao',
      },
      {
        value: 'antiguan',
        key: 'ag',
      },
      {
        value: 'argentinian',
        key: 'ar',
      },
      {
        value: 'armenian',
        key: 'am',
      },
      {
        value: 'australian',
        key: 'au',
      },
      {
        value: 'austrian',
        key: 'at',
      },
      {
        value: 'azerbaijani',
        key: 'az',
      },
      {
        value: 'bahamian',
        key: 'bs',
      },
      {
        value: 'bahraini',
        key: 'bh',
      },
      {
        value: 'bangladeshi',
        key: 'bd',
      },
      {
        value: 'barbados',
        key: 'bb',
      },
      {
        value: 'belarussian',
        key: 'bl',
      },
      {
        value: 'belgian',
        key: 'be',
      },
      {
        value: 'belizean',
        key: 'bz',
      },
      {
        value: 'beninese',
        key: 'bj',
      },
      {
        value: 'bhutanese',
        key: 'bt',
      },
      {
        value: 'bolivian',
        key: 'bo',
      },
      {
        value: 'bosnian',
        key: 'ba',
      },
      {
        value: 'botswana',
        key: 'bw',
      },
      {
        value: 'brazilian',
        key: 'br',
      },
      {
        value: 'british',
        key: 'gb',
      },
      {
        value: 'british national overseas',
        key: 'gg',
      },
      {
        value: 'british overseas citizen',
        key: 'ge',
      },
      {
        value: 'british overseas territories citizen',
        key: 'gc',
      },
      {
        value: 'british protected person',
        key: 'gj',
      },
      {
        value: 'british subject',
        key: 'uk',
      },
      {
        value: 'bruneian',
        key: 'bn',
      },
      {
        value: 'bulgarian',
        key: 'bg',
      },
      {
        value: 'burkinabe',
        key: 'bf',
      },
      {
        value: 'burundian',
        key: 'bi',
      },
      {
        value: 'cambodian',
        key: 'kh',
      },
      {
        value: 'cameroonian',
        key: 'cm',
      },
      {
        value: 'canadian',
        key: 'ca',
      },
      {
        value: 'cape verdean',
        key: 'cv',
      },
      {
        value: 'central african republic',
        key: 'cf',
      },
      {
        value: 'chadian',
        key: 'td',
      },
      {
        value: 'chilean',
        key: 'cl',
      },
      {
        value: 'chinese',
        key: 'cn',
      },
      {
        value: 'colombian',
        key: 'co',
      },
      {
        value: 'comoran',
        key: 'km',
      },
      {
        value: 'congolese',
        key: 'cg',
      },
      {
        value: 'costa rican',
        key: 'cr',
      },
      {
        value: 'croatian',
        key: 'hr',
      },
      {
        value: 'cuban',
        key: 'cu',
      },
      {
        value: 'cypriot',
        key: 'cy',
      },
      {
        value: 'czech',
        key: 'cz',
      },
      {
        value: 'd.p.r. korean',
        key: 'kp',
      },
      {
        value: 'danish',
        key: 'dk',
      },
      {
        value: 'democratic republic of the congo',
        key: 'cd',
      },
      {
        value: 'djiboutian',
        key: 'dj',
      },
      {
        value: 'dominican',
        key: 'dm',
      },
      {
        value: 'dominican (republic)',
        key: 'do',
      },
      {
        value: 'east timorese',
        key: 'tp',
      },
      {
        value: 'ecuadorian',
        key: 'ec',
      },
      {
        value: 'egyptian',
        key: 'eg',
      },
      {
        value: 'equatorial guinea',
        key: 'gq',
      },
      {
        value: 'eritrean',
        key: 'er',
      },
      {
        value: 'estonian',
        key: 'en',
      },
      {
        value: 'ethiopian',
        key: 'et',
      },
      {
        value: 'fijian',
        key: 'fj',
      },
      {
        value: 'filipino',
        key: 'ph',
      },
      {
        value: 'finnish',
        key: 'fi',
      },
      {
        value: 'french',
        key: 'fr',
      },
      {
        value: 'gabon',
        key: 'ga',
      },
      {
        value: 'gambian',
        key: 'gm',
      },
      {
        value: 'georgian',
        key: 'go',
      },
      {
        value: 'german',
        key: 'de',
      },
      {
        value: 'ghanaian',
        key: 'gh',
      },
      {
        value: 'greek',
        key: 'gr',
      },
      {
        value: 'grenadian',
        key: 'gd',
      },
      {
        value: 'guatemalan',
        key: 'gt',
      },
      {
        value: 'guinean',
        key: 'gn',
      },
      {
        value: 'guinean (bissau)',
        key: 'gw',
      },
      {
        value: 'guyanese',
        key: 'gy',
      },
      {
        value: 'haitian',
        key: 'ht',
      },
      {
        value: 'honduran',
        key: 'hn',
      },
      {
        value: 'hong kong',
        key: 'hk',
      },
      {
        value: 'hungarian',
        key: 'hu',
      },
      {
        value: 'icelander',
        key: 'is',
      },
      {
        value: 'indian',
        key: 'in',
      },
      {
        value: 'indonesian',
        key: 'id',
      },
      {
        value: 'iranian',
        key: 'ir',
      },
      {
        value: 'iraqi',
        key: 'iq',
      },
      {
        value: 'irish',
        key: 'ie',
      },
      {
        value: 'israeli',
        key: 'il',
      },
      {
        value: 'italian',
        key: 'it',
      },
      {
        value: 'ivory coast',
        key: 'ci',
      },
      {
        value: 'jamaican',
        key: 'jm',
      },
      {
        value: 'japanese',
        key: 'jp',
      },
      {
        value: 'jordanian',
        key: 'jo',
      },
      {
        value: 'kazakhstani',
        key: 'kz',
      },
      {
        value: 'kenyan',
        key: 'ke',
      },
      {
        value: 'kiribati',
        key: 'ki',
      },
      {
        value: 'kittian & nevisian',
        key: 'sw',
      },
      {
        value: 'korean, south',
        key: 'kr',
      },
      {
        value: 'kosovar',
        key: 'kv',
      },
      {
        value: 'kuwaiti',
        key: 'kw',
      },
      {
        value: 'kyrgyzstan',
        key: 'ky',
      },
      {
        value: 'laotian',
        key: 'la',
      },
      {
        value: 'latvian',
        key: 'lv',
      },
      {
        value: 'lebanese',
        key: 'lb',
      },
      {
        value: 'lesotho',
        key: 'ls',
      },
      {
        value: 'liberian',
        key: 'lr',
      },
      {
        value: 'libyan',
        key: 'ly',
      },
      {
        value: 'liechtensteiner',
        key: 'li',
      },
      {
        value: 'lithuanian',
        key: 'lh',
      },
      {
        value: 'luxembourger',
        key: 'lu',
      },
      {
        value: 'macao',
        key: 'mo',
      },
      {
        value: 'macedonian',
        key: 'mb',
      },
      {
        value: 'madagasy',
        key: 'mg',
      },
      {
        value: 'malawian',
        key: 'mw',
      },
      {
        value: 'malaysian',
        key: 'my',
      },
      {
        value: 'maldivian',
        key: 'mv',
      },
      {
        value: 'malian',
        key: 'ml',
      },
      {
        value: 'maltese',
        key: 'mt',
      },
      {
        value: 'marshallese',
        key: 'mh',
      },
      {
        value: 'mauritanean',
        key: 'mr',
      },
      {
        value: 'mauritian',
        key: 'mu',
      },
      {
        value: 'mexican',
        key: 'mx',
      },
      {
        value: 'micronesian',
        key: 'mf',
      },
      {
        value: 'moldavian',
        key: 'md',
      },
      {
        value: 'monacan',
        key: 'mc',
      },
      {
        value: 'mongolian',
        key: 'mn',
      },
      {
        value: 'montenegrin',
        key: 'mj',
      },
      {
        value: 'moroccan',
        key: 'ma',
      },
      {
        value: 'mozambican',
        key: 'mz',
      },
      {
        value: 'myanmar',
        key: 'mm',
      },
      {
        value: 'namibian',
        key: 'na',
      },
      {
        value: 'nauruan',
        key: 'nr',
      },
      {
        value: 'nepalese',
        key: 'np',
      },
      {
        value: 'netherlands',
        key: 'nt',
      },
      {
        value: 'new zealander',
        key: 'nz',
      },
      {
        value: 'nicaraguan',
        key: 'ni',
      },
      {
        value: 'niger',
        key: 'ne',
      },
      {
        value: 'nigerian',
        key: 'ng',
      },
      {
        value: 'ni-vanuatu',
        key: 'vu',
      },
      {
        value: 'norwegian',
        key: 'no',
      },
      {
        value: 'omani',
        key: 'om',
      },
      {
        value: 'pakistani',
        key: 'pk',
      },
      {
        value: 'palauan',
        key: 'pw',
      },
      {
        value: 'palestinian',
        key: 'pn',
      },
      {
        value: 'panamanian',
        key: 'pa',
      },
      {
        value: 'papua new guinean',
        key: 'pg',
      },
      {
        value: 'paraguayan',
        key: 'py',
      },
      {
        value: 'peruvian',
        key: 'pe',
      },
      {
        value: 'polish',
        key: 'pl',
      },
      {
        value: 'portuguese',
        key: 'pt',
      },
      {
        value: 'qatari',
        key: 'qa',
      },
      {
        value: 'romanian',
        key: 'ro',
      },
      {
        value: 'russian',
        key: 'ru',
      },
      {
        value: 'rwandan',
        key: 'rw',
      },
      {
        value: 'salvadoran',
        key: 'sv',
      },
      {
        value: 'sammarinese',
        key: 'sm',
      },
      {
        value: 'samoan',
        key: 'ws',
      },
      {
        value: 'sao tomean',
        key: 'st',
      },
      {
        value: 'saudi arabian',
        key: 'sa',
      },
      {
        value: 'senegalese',
        key: 'sn',
      },
      {
        value: 'serbian',
        key: 'rs',
      },
      {
        value: 'seychellois',
        key: 'sc',
      },
      {
        value: 'sierra leone',
        key: 'sl',
      },
      {
        value: 'singapore citizen',
        key: 'sg',
      },
      {
        value: 'slovak',
        key: 'sk',
      },
      {
        value: 'slovenian',
        key: 'si',
      },
      {
        value: 'solomon islander',
        key: 'sb',
      },
      {
        value: 'somali',
        key: 'so',
      },
      {
        value: 'south african',
        key: 'za',
      },
      {
        value: 'spanish',
        key: 'es',
      },
      {
        value: 'sri lankan',
        key: 'lk',
      },
      {
        value: 'st. lucia',
        key: 'lc',
      },
      {
        value: 'st. vincentian',
        key: 'vc',
      },
      {
        value: 'sudanese',
        key: 'sd',
      },
      {
        value: 'surinamer',
        key: 'sr',
      },
      {
        value: 'swazi',
        key: 'sz',
      },
      {
        value: 'swedish',
        key: 'se',
      },
      {
        value: 'swiss',
        key: 'ch',
      },
      {
        value: 'syrian',
        key: 'sy',
      },
      {
        value: 'taiwanese',
        key: 'tw',
      },
      {
        value: 'tajikistani',
        key: 'ti',
      },
      {
        value: 'tanzanian',
        key: 'tz',
      },
      {
        value: 'thai',
        key: 'th',
      },
      {
        value: 'togolese',
        key: 'tg',
      },
      {
        value: 'tongan',
        key: 'to',
      },
      {
        value: 'trinidadian & tobagonian',
        key: 'tt',
      },
      {
        value: 'tunisian',
        key: 'tn',
      },
      {
        value: 'turk',
        key: 'tr',
      },
      {
        value: 'turkmen',
        key: 'tm',
      },
      {
        value: 'tuvalu',
        key: 'tv',
      },
      {
        value: 'ugandan',
        key: 'ug',
      },
      {
        value: 'ukrainian',
        key: 'ua',
      },
      {
        value: 'united arab emirates',
        key: 'ae',
      },
      {
        value: 'unknown',
        key: 'un',
      },
      {
        value: 'uruguayan',
        key: 'uy',
      },
      {
        value: 'uzbekistan',
        key: 'uz',
      },
      {
        value: 'vatican city state (holy see)',
        key: 'va',
      },
      {
        value: 'venezuelan',
        key: 've',
      },
      {
        value: 'vietnamese',
        key: 'vn',
      },
      {
        value: 'yemeni',
        key: 'ym',
      },
      {
        value: 'zambian',
        key: 'zm',
      },
      {
        value: 'zimbabwean',
        key: 'zw',
      },
      {
        value: 'singaporean',
        key: 'sg',
      },
      {
        key: 'xxa',
        value: 'stateless',
      },
      {
        value: 'myanmars',
        key: 'mm',
      },
      {
        value: 'south korea',
        key: 'kr',
      },
      {
        value: 'indonesia',
        key: 'id',
      },
      {
        value: 'bangladesh',
        key: 'bd',
      },
      {
        value: 'malaysia',
        key: 'my',
      },
      {
        value: 'hong konger',
        key: 'hk',
      },
      {
        value: 'china',
        key: 'cn',
      },
      {
        value: 'korean',
        key: 'kr',
      },
      {
        value: 'india',
        key: 'in',
      },
      {
        value: 'dane',
        key: 'dk',
      },
      {
        value: 'taiwan',
        key: 'tw',
      },
      {
        value: 'philippines',
        key: 'ph',
      },
      {
        value: 'pakistan',
        key: 'pk',
      },
      {
        value: 'japan',
        key: 'jp',
      },
      {
        value: 'england',
        key: 'br',
      },
      {
        value: 'south korean',
        key: 'kr',
      },
      {
        value: 'sri lanka',
        key: 'lk',
      },
      {
        value: 'france',
        key: 'fr',
      },
      {
        value: 'new zealand',
        key: 'nz',
      },
      {
        value: 'ireland',
        key: 'ie',
      },
      {
        value: 'turkish',
        key: 'tr',
      },
      {
        value: 'cambodia',
        key: 'kh',
      },
      {
        value: 'united states',
        key: 'us',
      },
      {
        value: 'turkey',
        key: 'tr',
      },
      {
        value: 'macauian',
        key: 'mo',
      },
      {
        value: 'american, united states',
        key: 'us',
      },
      {
        value: 'korean, south korea',
        key: 'kr',
      },
      {
        value: 'brazil',
        key: 'br',
      },
      {
        value: 'singapore',
        key: 'sg',
      },
      {
        value: 'united states of america',
        key: 'us',
      },
      {
        value: 'usa',
        key: 'us',
      },
      {
        value: 'us',
        key: 'us',
      },
      {
        value: 'myanmar',
        key: 'mm',
      },
    ];
  