import React from 'react';
import { useCustomForm } from 'hooks';

import { Input, Select, Button, PhoneInput, Text } from 'components';

import { completeAddressSchema } from '../../schema';
import PageHeader from '../../../components/PageHeader/PageHeader';

import './completeAddress.scss';

const CompleteAddress = ({
  updateDetails,
  nationalities,
  onConfirmClick,
  details,
  isSaving,
  breadCrumb,
}) => {
  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
  } = useCustomForm(completeAddressSchema, {
    address: details.address || '',
    zip: details.zip || '',
    city: details.city || '',
    country: details.country || '',
    phoneNumber: details.phoneNumber || '',
  });

  const onValChange = (field, value, callback) => {
    updateDetails({ ...details, [field]: value });
    callback(value);
  };

  return (
    <>
      <div className="completeAddress__header">
        <PageHeader
          titleId="patient_app.complete_address"
          title="Complete your address"
          backPath="/settings"
        />
        {breadCrumb}
      </div>
      <div className="completeAddress">
        <div className="completeAddress__form">
          <form onSubmit={handleSubmit(onConfirmClick)}>
            <div className="completeAddress__form__grid-wrapper">
              <div className="completeAddress__form__grid">
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      className="completeAddress__form__input"
                      border
                      size="fullWidth"
                      label={
                        <Text
                          id="patient_app.settings.my_profile.contact.contact_no"
                          color="inherit"
                          type="label"
                        >
                          Contact No.
                        </Text>
                      }
                      placeholder="Enter your phone number"
                      value={value}
                      // optional
                      onChange={(val) =>
                        onValChange('phoneNumber', val || '', onChange)
                      }
                      invalid={errors?.phoneNumber}
                      errorMessage={errors?.phoneNumber?.message}
                    />
                  )}
                />
                <Controller
                  name="country"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={
                        <Text
                          id="patient_app.settings.my_profile.address.country"
                          color="inherit"
                          type="label"
                        >
                          Country
                        </Text>
                      }
                      border
                      placeholder="Country of residence"
                      value={value}
                      onChange={(e, { value }) =>
                        onValChange('country', value, onChange)
                      }
                      options={nationalities}
                      search
                      errorMessage=""
                      size="fullWidth"
                      selection
                      className="completeAddress__form__input"
                      invalid={errors?.country}
                    />
                  )}
                />
                <Controller
                  name="city"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      border
                      className="completeAddress__form__input"
                      label={
                        <Text
                          id="add_patient.city"
                          color="inherit"
                          type="label"
                        >
                          City / State
                        </Text>
                      }
                      errorMessage=""
                      placeholder="City or state of residence"
                      size="fullWidth"
                      value={value}
                      invalid={errors?.city}
                      onChange={(e) =>
                        onValChange('city', e.target.value, onChange)
                      }
                    />
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      border
                      className="completeAddress__form__input"
                      label={
                        <Text
                          id="add_patient.address"
                          color="inherit"
                          type="label"
                        >
                          Address
                        </Text>
                      }
                      errorMessage=""
                      placeholder="Address of residence"
                      size="fullWidth"
                      value={value}
                      invalid={errors?.address}
                      onChange={(e) =>
                        onValChange('address', e.target.value, onChange)
                      }
                    />
                  )}
                />
                <Controller
                  name="zip"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      border
                      className="completeAddress__form__input"
                      label={
                        <Text
                          id="med_booking_register_details.postal_code"
                          color="inherit"
                          type="label"
                        >
                          Zip / Postal Code
                        </Text>
                      }
                      placeholder="Zip or postal code of your residing address"
                      errorMessage=""
                      size="fullWidth"
                      value={value}
                      invalid={errors?.zip}
                      onChange={(e) =>
                        onValChange('zip', e.target.value, onChange)
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className="completeAddress__button global__fixed-button">
              <Button
                type="submit"
                // color="black"
                color="blue"
                className="rec-ph__button mt-4"
                size="fullWidth"
                // onClick={onNextClick}
                isLoading={isSaving}
                disabled={isSaving}
              >
                <Text id="patient_app.medication_confirm" color="inherit">
                  Confirm
                </Text>
              </Button>
              {/* <Button
                type="submit"
                color="lightGrey"
                className="rec-ph__button completeProfile__button__back mt-4"
                size="fullWidth"
                inverted
                onClick={onBackClick}
              >
                {'Back'}
              </Button> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default CompleteAddress;
