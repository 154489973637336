import { filterDocumentTypes } from '../utils/role';

export const SET_CLIENT_INFORMATION = 'GENERAL_STATE/SET_CLIENT_INFORMATION';
export const SET_USER = 'GENERAL_STATE/SET_USER';
export const SET_CLIENT_BRANDING = 'GENERAL_STATE/SET_CLIENT_BRANDING';
export const SET_USER_CLIENTS = 'GENERAL_STATE/SET_USER_CLIENTS';
export const SET_CLIENT_CLINICS = 'GENERAL_STATE/SET_CLIENT_CLINICS';
export const SET_DOCUMENT_TYPES = 'GENERAL_STATE/SET_DOCUMENT_TYPES';
export const SET_DEV_MODE = 'GENERAL_STATE/SET_DEV_MODE';
export const SET_SHOW_SIDE_BAR = 'SET_SHOW_SIDE_BAR';
export const SET_CUSTOM_URL_CLIENT = 'SET_CUSTOM_URL_CLIENT';
export const SET_CLINIC_OPTIONS = 'GENERAL_STATE/SET_CLINIC_OPTIONS';
export const SET_LAB_CLINICS = 'GENERAL_STATE/SET_LAB_CLINICS';
export const SET_REFRESH_TOKEN_COUNT = 'GENERAL_STATE/SET_REFRESH_TOKEN_COUNT';
export const SET_TEMP_STATE = 'GENERAL_STATE/SET_TEMP_STATE';
const hack = [
  {
    name: 'Antigen Rapid Swab Test',
    type: 'medical',
    category: 'COVID',
    subType: 'SG_ANTI_RAPID_TEST',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'Antigen Rapid Test - Professionally Administered',
    type: 'medical',
    category: 'COVID',
    subType: 'PA_SG_ANTI_RAPID_TEST',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'Antigen Rapid Test - Remotely Supervised',
    type: 'medical',
    category: 'COVID',
    subType: 'RMSP_SG_ANTI_RAPID_TEST',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'Antigen Rapid Test - Supervised',
    type: 'medical',
    category: 'COVID',
    subType: 'SP_SG_ANTI_RAPID_TEST',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'COVID-19 Vaccine',
    type: 'medical',
    category: 'COVID',
    subType: 'COVID_VAC',
    subCategory: 'VACCINE',
    location: null,
    __typename: 'DocumentType',
  },
  {
    name: 'Filipino RT-PCR',
    type: 'medical',
    category: 'COVID',
    subType: 'PH_PCR_TEST',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'PH',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'Fit For Travel',
    type: 'medical',
    category: 'MARITIME',
    subType: 'FFT',
    subCategory: 'FFT',
    location: {
      country: 'UK',
      region: 'EU',
      continent: 'EU',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'Fit To Travel COVID (Singapore)',
    type: 'medical',
    category: 'COVID',
    subType: 'FFT_COV_SG',
    subCategory: 'TRAVEL',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'LAMP - N gene [Presence] by NAA Non-probe detection',
    type: 'medical',
    category: 'COVID',
    subType: 'SG_LAMP',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'Medical certificate for service at sea',
    type: 'medical',
    category: 'MARITIME',
    subType: 'PH_PEME',
    subCategory: 'PEME',
    location: {
      country: 'PH',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'Pre-Departure PCR Test - PDT (China)',
    type: 'medical',
    category: 'COVID',
    subType: 'CH_PDT_PCR_TEST',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'Pre-Departure PCR Test - PDT (Singapore)',
    type: 'medical',
    category: 'COVID',
    subType: 'SG_PDT_PCR_TEST',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'RT-PCR (Singapore)',
    type: 'medical',
    category: 'COVID',
    subType: 'SG_PCR_TEST',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'RT-PCR (Taiwan)',
    type: 'medical',
    category: 'COVID',
    subType: 'TW_PCR_TEST',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'TW',
      region: 'EA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'RT-PCR + IgM Antibody (Chinese)',
    type: 'medical',
    category: 'COVID',
    subType: 'CH_PCR_ANTIGEN_TEST',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'RT-PCR Total Antibodies N Protein IgG + IgM (Chinese)',
    type: 'medical',
    category: 'COVID',
    subType: 'CH_PCR_PRO_ANTIBODY_TEST',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'RT-PCR Total Antibodies S Protein IgG + IgM (Chinese)',
    type: 'medical',
    category: 'COVID',
    subType: 'CH_PCR_PRO_S_ANTIBODY_TEST',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'Serology',
    type: 'medical',
    category: 'COVID',
    subType: 'SEROLOGY_IGG',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'Serology IgM',
    type: 'medical',
    category: 'COVID',
    subType: 'SEROLOGY',
    subCategory: 'TEST_RESULT',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'Crew Wellness Questionnaire',
    type: 'QUESTIONNAIRE',
    category: 'MARITIME',
    subType: 'CRW_QUESTIONNAIRE',
    subCategory: 'QUESTIONNAIRE',
    id: 'vikand-crew-wellness-questionnaire',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'Medical Encounter Form',
    type: 'QUESTIONNAIRE',
    category: 'MARITIME',
    subType: 'MD_ECT_QUESTIONNAIRE',
    subCategory: 'QUESTIONNAIRE',
    id: 'medical-encounter-form',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
  {
    name: 'Mental Health Encounter Form',
    type: 'QUESTIONNAIRE',
    category: 'MARITIME',
    subType: 'MTH_ECT_QUESTIONNAIRE',
    subCategory: 'QUESTIONNAIRE',
    id: 'mental-health-encounter-form',
    location: {
      country: 'SG',
      region: 'SEA',
      continent: 'Asia',
      __typename: 'DocLocation',
    },
    __typename: 'DocumentType',
  },
];

/**
 * @function setClientInformation
 * @description Set information of the logged in client
 */
export const setClientInformation = (clientInfo) => ({
  type: SET_CLIENT_INFORMATION,
  payload: clientInfo,
});

/**
 * @function setClientTheme
 * @description Set information of the logged in client
 */
export const setClientBranding = (clientBranding) => ({
  type: SET_CLIENT_BRANDING,
  payload: clientBranding,
});

/**
 * @function setUser
 * @description Set state of logged in user
 */
export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

/**
 * @function setUserClients
 * @description Set state of logged in user
 */
export const setUserClients = (clients) => ({
  type: SET_USER_CLIENTS,
  payload: clients,
});

/**
 * @function setClientClinics
 * @description Set information of all lab clinics
 */

export const setClientClinics = (clinics) => ({
  type: SET_CLIENT_CLINICS,
  payload: clinics,
});

/**
 * @function setDevMode
 * @description Set state of logged in user
 */
export const setDevMode = (isDevMode) => ({
  type: SET_DEV_MODE,
  payload: isDevMode,
});

export const setDocumentTypes = (documentTypes, role) => {
  const filteredDocuments = filterDocumentTypes(role, hack)

  return {
    type: SET_DOCUMENT_TYPES,
    payload: filteredDocuments,
  };
};

/**
 * @function setSideBarState
 * @description Set state of side bar
 */
export const setSideBarState = (state) => ({
  type: SET_SHOW_SIDE_BAR,
  payload: state,
});

/**
 * @function setCustomUrlClientId
 * @description Set state of side bar
 */
export const setCustomUrlClientId = (state) => ({
  type: SET_CUSTOM_URL_CLIENT,
  payload: state,
});

/**
 * @function setCustomUrlClientId
 * @description Set state of side bar
 */
export const setClinicOptions = (state) => ({
  type: SET_CLINIC_OPTIONS,
  payload: state,
});

/**
 * @function setCustomUrlClientId
 * @description Set state of side bar
 */
export const setLabClients = (state) => ({
  type: SET_LAB_CLINICS,
  payload: state,
});

/**
 * @function setCustomUrlClientId
 * @description Set state of side bar
 */
export const setRefreshTokenCount = (state) => ({
  type: SET_REFRESH_TOKEN_COUNT,
  payload: state,
});

/**
 * @function setCustomUrlClientId
 * @description Set state of side bar
 */
export const setTempState = (state) => ({
  type: SET_TEMP_STATE,
  payload: state,
});

