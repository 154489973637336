import React from 'react';

const SvgRefresh = props => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      stroke="#1b1b1b"
      strokeWidth={3}
    >
      <path d="M5 20.724C6.775 25.568 11.2 29 16.379 29c5.433 0 10.037-3.778 11.621-9" />
      <path d="M2.04 23.885l2.933-5.832 5.757 2.971" />
      <g>
        <path d="M27 11.276C25.225 6.432 20.8 3 15.621 3 10.188 3 5.584 6.778 4 12" />
        <path d="M29.96 8.115l-2.933 5.832-5.757-2.971" />
      </g>
    </g>
  </svg>
);

export default SvgRefresh;
