import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import cx from 'classnames';

import { Icon, Text } from 'components';
import TableRow from '../tableRow/TableRow';

import { sortOrder } from 'api/fhir/observation/observationMapper';

import './tableCatRow.scss';

const TableCatRow = ({
  categoryKey,
  categoryDescriptions,
  observationsByCat,
  values,
  graphData,
  onRowClick,
  expandAll,
  setExpandAll,
}) => {
  const [showCatItems, setShowCatItems] = useState(false);

  useEffect(() => {
    if (expandAll === 'all-expanded') setShowCatItems(true);
    if (expandAll === 'all-collapsed') setShowCatItems(false);
  }, [expandAll]);

  return (
    <>
      <Table.Row
        className={cx('obs-table__row obs-table__cat-row', {
          'obs-table__row--high':
            observationsByCat[categoryKey]?.abnormalCount ||
            0 > observationsByCat[categoryKey]?.normalCount ||
            0,
        })}
        onClick={() => {
          setExpandAll('neutral');
          setShowCatItems(!showCatItems);
        }}
      >
        <Table.Cell width={2}>
          {/* <Text size="small" dark uppercase>
            {categoryDescriptions?.[categoryKey]?.categoryId}
          </Text> */}
        </Table.Cell>
        <Table.Cell className={`obs-table__col`} style={{ width: '250px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Text size="big" dark>
              {categoryKey !== 'undefined'
                ? categoryDescriptions[categoryKey]?.category
                : 'Other'}
              {/* <Text size="small">
                {categoryDescriptions?.[categoryKey]?.description || ''}
              </Text> */}
            </Text>
          </div>
        </Table.Cell>
        <Table.Cell className="obs-table__col">
          {/* <Table.Cell> */}
          {!!observationsByCat[categoryKey]?.abnormalCount && (
            <div className="category-tag">
              <div className="category-tag__wrapper">
                <div className="category-tag__icon__wrapper">
                  <img
                    width={18}
                    className="category-tag__icon"
                    src={values.abnormal.icon}
                    alt="abnormal"
                  />
                </div>
                <div className="category-tag__text">
                  <Text size="small">
                    {`Abnormal (${String(
                      observationsByCat[categoryKey]?.abnormalCount || 0
                    ).padStart(2, '0')})`}
                  </Text>
                </div>
              </div>
            </div>
          )}
          <div className="category-tag">
            <div className="category-tag__wrapper">
              <div className="category-tag__icon__wrapper">
                <img
                  width={18}
                  className="category-tag__icon"
                  src={values.normal.icon}
                  alt="normal"
                />
              </div>
              <div className="category-tag__text">
                <Text size="small">
                  {`Normal (${String(
                    observationsByCat[categoryKey]?.normalCount || 0
                  ).padStart(2, '0')})`}
                </Text>
              </div>
            </div>
          </div>
        </Table.Cell>
        <Table.Cell className={`obs-table__col`} style={{ width: '100px' }}>
          <div className="dropdown-toggle">
            {showCatItems ? <Icon name="angleUp" /> : <Icon name="angleDown" />}
          </div>
        </Table.Cell>
      </Table.Row>
      {showCatItems &&
        sortOrder.map((sortKey) => (
          <>
            {observationsByCat[categoryKey][sortKey]?.range && (
              <>
                {observationsByCat[categoryKey][sortKey]?.range.map(
                  (observation) => (
                    <TableRow
                      observation={observation}
                      values={values}
                      onRowClick={onRowClick}
                      graphData={graphData}
                    />
                  )
                )}
              </>
            )}
            {observationsByCat[categoryKey][sortKey]?.value && (
              <>
                {observationsByCat[categoryKey][sortKey]?.value.map(
                  (observation) => (
                    <TableRow
                      observation={observation}
                      values={values}
                      onRowClick={onRowClick}
                      graphData={graphData}
                    />
                  )
                )}
              </>
            )}


          </>
        ))}
    </>
  );
};

export default TableCatRow;
