import { Modal } from 'semantic-ui-react';
import React, { useState } from 'react';
import { Icon as SIcon } from 'semantic-ui-react';
import { Text, Icon, Button } from 'components';

import './additionalReports__TableModal.scss';

const AdditionalReportsTableModal = ({
  report,
  showModal,
  setShowModal,
  deleteSessionReport,
  deleteLoading,
}) => {
  return (
    <Modal
      className="artm"
      open={showModal}
      onClose={() => setShowModal(false)}
      closeIcon
      size="tiny"
    >
      <Modal.Header className="artm__header">
        <div className="artm__icon mr-2">
          <Icon name="alert" />
        </div>
        <Text size="large" className="artm__header-text" dark>
          Delete Report Related to ReACT Session
        </Text>
      </Modal.Header>
      <Modal.Content>
        <div className="artm__file">
          <Text size="small" bold color="#5b53f3">
            <SIcon name="file pdf" color="red" size="" />{' '}
            {report?.pdf?.at(0)?.name || 'N.A'}
          </Text>
        </div>
        <div className="artm__text-wrapper">
          <Text size="medium" className="artm__text">
            The file you are trying to delete will also be deleted in the
            patient profile. are you sure you want to permanently delete the
            selected report?
          </Text>
        </div>
        <div className="artm__button-wrapper">
          <Button
            size="fullWidth"
            isLoading={deleteLoading}
            color="red"
            className="artm__button"
            onClick={() => deleteSessionReport(report.bundleId, report.id)}
          >
            <div className="artm__icon mr-2">
              <Icon name="close" color="white" />
            </div>
            Delete
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AdditionalReportsTableModal;
