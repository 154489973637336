import React from 'react';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';

import ProfilePlaceholder from 'assets/new/profileImagePlaceholder';

import { Icon, Text } from 'components';

import '../patientOverview.scss';

const PatientOverview = ({ patient, fetchingPatient }) => {
  return (
    <div className="order-patient-overview__details-wrapper">
      {!patient || fetchingPatient ? (
        <div className="order-patient-overview__details-wrapper__loader-wrapper">
          <Loader
            active
            className="order-patient-overview__details-wrapper__loader-wrapper__loader"
            size="small"
          />
        </div>
      ) : (
        <>
          <div className="order-patient-overview__details-wrapper__row">
            {patient.profileUrl ? (
              <div className="order-patient-overview__patient-photo">
                <img src={patient.profileUrl} alt="patient" />
              </div>
            ) : (
              <ProfilePlaceholder className="order-patient-overview__patient-photo" />
            )}
            {/* <div className="order-patient-overview__detail">
              <Text
                className="order-patient-overview__detail__header"
                color="grey"
                size="small"
                dark
              >
                Riverr ID No.
              </Text>
              <div className="order-patient-overview__detail__data r-id">
                <div className="order-patient-overview__detail__data r-id-icon">
                  <Icon name="riverr" />
                </div>
                <div className="order-patient-overview__detail__data r-id-text">
                  {patient.friendlyId}
                </div>
              </div>
            </div> */}
            <div className="order-patient-overview__detail">
              <Text
                className="order-patient-overview__detail__header"
                color="grey"
                size="small"
                dark
                id="med_booking_be_order_patient_fullname"
              >
                Full Name
              </Text>
              <Text className="order-patient-overview__detail__data">
                {patient.fullName}
              </Text>
            </div>

            {patient.ssn && (
              <div className="order-patient-overview__detail">
                <Text
                  className="order-patient-overview__detail__header"
                  color="grey"
                  size="small"
                  dark
                  id="react_single_overview.nric_fin"
                >
                  NRIC/FIN
                </Text>
                <Text className="order-patient-overview__detail__data">
                  {patient.ssn}
                </Text>
              </div>
            )}

            {patient.passNo && (
              <div className="order-patient-overview__detail">
                <Text
                  className="order-patient-overview__detail__header"
                  color="grey"
                  size="small"
                  dark
                  id="add_patient.passport_no"
                >
                  Passport No.
                </Text>
                <Text className="order-patient-overview__detail__data">
                  {patient.passNo}
                </Text>
              </div>
            )}

            <div className="order-patient-overview__detail">
              <Text
                className="order-patient-overview__detail__header"
                color="grey"
                size="small"
                dark
                id="add_patient.gender"
              >
                Gender
              </Text>
              <Text className="order-patient-overview__detail__data" uppercase>
                {patient.gender}
              </Text>
            </div>

            {patient.dateOfBirth && (
              <div className="order-patient-overview__detail">
                <Text
                  className="order-patient-overview__detail__header"
                  color="grey"
                  size="small"
                  dark
                  id="react_single_overview.birth_date"
                >
                  Date of Birth
                </Text>
                <Text className="order-patient-overview__detail__data">
                  {moment(patient.dateOfBirth, 'YYYY-MM-DD').format(
                    'DD-MM-YYYY'
                  )}
                </Text>
              </div>
            )}
            {patient.nationality && (
              <div className="order-patient-overview__detail">
                <Text
                  className="order-patient-overview__detail__header"
                  color="grey"
                  size="small"
                  dark
                  id="add_patient.nationality"
                >
                  Nationality
                </Text>
                <Text className="order-patient-overview__detail__data">
                  {patient.nationality}
                </Text>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PatientOverview;
