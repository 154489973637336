import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Loader, Popup } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Text,
  Button,
  Select,
  Cleave,
  Input,
  ErrorToast,
  SuccessToast,
  Page,
  PhoneInput,
  LinearLoader,
  Icon,
} from 'components';
import countries from 'helpers/countries';
import ProfileService from 'api-delta/profiles';
import CertificateService from 'api-delta/certificates';
import StaffService from 'api-delta/staff';
import TrainingCenterService from 'api-delta/trainingCenters';

import DoseSection from './components/DoseSection';

import { setStaff } from '../../../../staffPages/staffManagement/store/staffManagement.actions';

const CovidVac = ({ patient, subType, setRecentDocs }) => {
  const [countriesList, setCountriesList] = useState([]);
  const [errors, setErrors] = useState({ 1: {}, 2: {} });
  const [passNo, setPassNo] = useState('');
  const [nationality, setNationality] = useState(null);
  const [gender, setGender] = useState(null);
  const [birthDate, setBirthDate] = useState('');
  // const [testFile, setTestFile] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [holderName, setHolderName] = useState('');
  const [ssn, setSSN] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');
  const [idType, setIdType] = useState('PPN');
  const [findingUser, setFindingUser] = useState(false);
  const [physicians, setPhysicians] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [ccEmail, setCcEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [ccEmail1, setCcEmail1] = useState('');
  const [ccEmail2, setCcEmail2] = useState('');
  const [linkId, setLinkId] = useState('');
  const [pastVacs, setPastVacs] = useState([]);
  const [count, setCount] = useState('');
  const [loadingVac, setLoadingVac] = useState(false);
  const [doseData, setDoseData] = useState({});
  const [dosage, setDosage] = useState('complete');
  const [dose, setDose] = useState(1);

  const userInfo = useSelector((state) => state.general.user);
  const clientInfo = useSelector((state) => state.general.clientInformation);

  const dispatch = useDispatch();

  useEffect(() => {
    const setPlatoData = () => {
      if (!patient) return;
      let nationality = null;
      countriesList.forEach((nat) => {
        if (nat.text === patient.nationality) nationality = nat.value;
      });
      setNationality(nationality);
      setGender(patient.sex);
      setBirthDate(moment(patient.dob, 'YYYY/MM/DD').format('DD/MM/YYYY'));
      setSSN(patient.nric);
      setHolderName(patient.name);
      setEmail(patient.email);
      setPhone(`+65${patient.telephone}`);
    };
    setPlatoData();
  }, [countriesList, patient]);

  useEffect(() => {
    fetchInstructors();
    formatCountries();
    fetchClinics();
  }, []); // eslint-disable-line

  useEffect(() => {
    const getVacs = async () => {
      try {
        setLoadingVac(true);
        const {
          data: { getVaccineCerts },
        } = await CertificateService.getVaccineCerts(userInfo.currentClientId);

        const pastVacs = getVaccineCerts.map((vac) => {
          return {
            text: `${vac.holderName} - ${moment(vac.collectionDate).format(
              'DD/MM/YYYY'
            )} - ${JSON.parse(vac.extraData)[1].vaccineName}`,
            value: vac.id,
          };
        });

        setPastVacs(pastVacs);
      } catch (error) {
        console.log('error', error);
      }
      setLoadingVac(false);
    };
    getVacs();
  }, [count]);

  const clearFields = () => {
    setPassNo('');
    setNationality(null);
    setGender(null);
    setBirthDate('');
    // setTestFile(false);
    setHolderName('');
    setEmail('');
    setUserId('');
    setSSN('');
    setCcEmail('');
    setCcEmail1('');
    setCcEmail2('');
    setCount('');
    setPhone(null);
  };

  const fetchClinics = async () => {
    try {
      const {
        data: { trainingCenters },
      } = await TrainingCenterService.getTrainingCentersByClientId(
        userInfo.currentClientId
      );

      const facilitiesOptions = trainingCenters.map((facility) => ({
        text: facility.name,
        value: {
          locationPhoneNumber: facility.phoneNumber,
          locationAddress: `${facility.address}, ${facility.city}, ${facility.country}`,
          locationName: facility.name,
          locationCode: facility.code,
        },
      }));
      setClinics(facilitiesOptions);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const formatCountries = () => {
    const formattedCons = countries.map((con, index) => {
      return {
        key: index,
        text: con.text,
        value: { nationality: con.text, countryCode: con.key },
      };
    });

    setCountriesList(formattedCons);
  };

  const onDoseDataChange = (doseCount, label, value) => {
    const data = JSON.parse(JSON.stringify(doseData));

    if (!data[doseCount]) data[doseCount] = {};
    data[doseCount][label] = value;
    setDoseData(data);
  };

  const getStatus = () => {
    if (dosage === 'partial') return 'INCOMPLETE';
    if (dosage === 'booster') return 'BOOSTER';
    if (dosage === 'nonReq') return 'NONREQ';
    return 'COMPLETE';
  };

  const issueCertificate = async () => {
    if (!isFormValid()) return;
    setIsSaving(true);
    const data = JSON.parse(JSON.stringify(doseData));

    if (data[1]) {
      data[1].collectionDate = moment(
        data[1].collectionDate + ' ' + data[1].collectionTime,
        'DD/MM/YYYY HH:mm'
      )
        .utc()
        .toISOString();
    }

    if (data[2]) {
      data[2].collectionDate = moment(
        data[2].collectionDate + ' ' + data[2].collectionTime,
        'DD/MM/YYYY HH:mm'
      )
        .utc()
        .toISOString();
    }

    try {
      const {
        data: { bulkIssueVaccine },
      } = await CertificateService.issueVaccineCert({
        certificates: [
          {
            extraData: JSON.stringify(data),
            clientId: userInfo.currentClientId,
            dateOfBirth: moment.utc(birthDate, 'DD/MM/YYYY').toISOString(),
            subType: 'COVID_VAC',
            userId,
            email,
            gender,
            ...nationality,
            type: 'medical',
            passNo,
            holderName,
            linkId: dosage === 'partial' && dose === 2 ? linkId : null,
            ssn,
            idType,
            status: getStatus(),
            ccEmails: JSON.stringify([
              ccEmail.trim(),
              ccEmail1.trim(),
              ccEmail2.trim(),
            ]),
          },
        ],
      });
      console.log('issueResp', bulkIssueVaccine);

      let recentDocsLS = localStorage.getItem('RECENT_ISSUED_DOCS');
      if (recentDocsLS) {
        recentDocsLS = JSON.parse(recentDocsLS);
        let _subTypes = recentDocsLS.subTypes;
        if (_subTypes.length > 5) {
          _subTypes = _subTypes.slice(0, 5);
        }
        if (!_subTypes.includes(subType)) {
          _subTypes.unshift(subType);
        }
        recentDocsLS.userId = userInfo.safe_id;
        recentDocsLS.subTypes = _subTypes;
      } else {
        recentDocsLS = {
          userId: userInfo.safe_id,
          subTypes: [subType],
        };
      }
      setRecentDocs(recentDocsLS.subTypes);
      localStorage.setItem('RECENT_ISSUED_DOCS', JSON.stringify(recentDocsLS));

      setIsSaving(false);
      if (bulkIssueVaccine && JSON.parse(bulkIssueVaccine).length > 0)
        return toast.error(
          <ErrorToast message={JSON.parse(bulkIssueVaccine)[0].error} />
        );

      clearFields();
      toast.success(<SuccessToast message="Document issued" />, {
        hideProgressBar: true,
        autoClose: 4000,
        pauseOnHover: true,
      });
    } catch (error) {
      setIsSaving(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  const findUser = async () => {
    try {
      setFindingUser(true);
      const {
        data: { userByEmail },
      } = await ProfileService.searchUser(email);

      if (userByEmail.id !== '-1') {
        setUserId(userByEmail.id);
        userByEmail.passNo && setPassNo(userByEmail.passNo);
        userByEmail.firstName &&
          setHolderName(
            `${userByEmail.passportFirstName || userByEmail.firstName} ${
              userByEmail.passportLastName || userByEmail.lastName
            }`
          );
        userByEmail.phoneNumber && setPhone(userByEmail.phoneNumber);
        setBirthDate(moment.utc(userByEmail.birthday).format('DD-MM-YYYY'));
        // userByEmail.fromCountry &&
        //   setNationality({
        //     nationality: userByEmail.fromCountry,
        //     countryCode: userByEmail.countryCode,
        //   });
        userByEmail.isFemale !== undefined &&
          setGender(userByEmail.isFemale ? 'Female' : 'Male');
      }
      setFindingUser(false);
    } catch (error) {
      setFindingUser(false);
    }
  };

  const isFormValid = () => {
    const newErrors = { 1: {}, 2: {} };
    let isValid = true;

    if (doseData[1]) {
      if (
        !doseData[1] ||
        !doseData[1].collectionDate ||
        doseData[1].collectionDate.length < 6
      ) {
        newErrors[1].collectionDate = true;
        isValid = false;
      }

      if (
        !doseData[1] ||
        !doseData[1].collectionTime ||
        doseData[1].collectionTime.length < 4
      ) {
        newErrors[1].collectionTime = true;
        isValid = false;
      }

      // if (!doseData[1] || !doseData[1].vaccinatorName) {
      //   newErrors[1].vaccinatorName = true;
      //   isValid = false;
      // }

      if (!doseData[1] || !doseData[1].location) {
        newErrors[1].location = true;
        isValid = false;
      }

      if (!doseData[1] || !doseData[1].vaccineName) {
        newErrors[1].vaccineName = true;
        isValid = false;
      }

      if (!doseData[1] || !doseData[1].lotNumber) {
        newErrors[1].lotNumber = true;
        isValid = false;
      }
    }

    if (doseData[2]) {
      if (
        !doseData[2] ||
        !doseData[2].collectionDate ||
        doseData[2].collectionDate.length < 6
      ) {
        newErrors[2].collectionDate = true;
        isValid = false;
      }

      if (
        !doseData[2] ||
        !doseData[2].collectionTime ||
        doseData[2].collectionTime.length < 4
      ) {
        newErrors[2].collectionTime = true;
        isValid = false;
      }

      // if (!doseData[2] || !doseData[2].vaccinatorName) {
      //   newErrors[2].vaccinatorName = true;
      //   isValid = false;
      // }

      if (!doseData[2] || !doseData[2].location) {
        newErrors[2].location = true;
        isValid = false;
      }

      if (!doseData[2] || !doseData[2].vaccineName) {
        newErrors[2].vaccineName = true;
        isValid = false;
      }

      if (!doseData[2] || !doseData[2].lotNumber) {
        newErrors[2].lotNumber = true;
        isValid = false;
      }
    }

    if (!email || email.length < 4) {
      newErrors.email = true;
      isValid = false;
    }
    if (!holderName || holderName.length < 2) {
      newErrors.holderName = true;
      isValid = false;
    }

    if (!passNo || passNo.length < 5) {
      newErrors.passNo = true;
      isValid = false;
    }

    if (!birthDate || birthDate.length < 5) {
      newErrors.birthDate = true;
      isValid = false;
    }

    if (!nationality) {
      newErrors.nationality = true;
      isValid = false;
    }
    if (!gender) {
      newErrors.gender = true;
      isValid = false;
    }

    if (!isValid)
      toast.error(<ErrorToast message="Fill all required fields" />);
    setErrors(newErrors);
    return isValid;
  };

  const fetchInstructors = () => {
    if (userInfo.centerId) return fetchInstructorsByCenter();
    return fetchInstructorsByClient();
  };

  const fetchInstructorsByClient = async () => {
    const clientId = userInfo.currentClientId;
    try {
      const {
        data: { instructorsByClientId },
      } = await StaffService.getStaffByClientId(clientId);
      dispatch(setStaff(instructorsByClientId));
      formatPhysicians(instructorsByClientId);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchInstructorsByCenter = async () => {
    try {
      const {
        data: { instructorsByCenterId },
      } = await StaffService.getInstructorsByCenterId(userInfo.centerId);
      dispatch(setStaff(instructorsByCenterId));
      formatPhysicians(instructorsByCenterId);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const formatPhysicians = (instructorsByClientId) => {
    setPhysicians(
      instructorsByClientId.map((phys) => {
        return {
          text: phys.name,
          value: phys.name,
        };
      })
    );
  };

  const onEmailChange = (e) => {
    if (e.key === 'Enter') {
      findUser(email);
    }
  };

  const onDoseRemove = () => {
    const obj = { ...doseData };
    delete obj[2];
    setDoseData(obj);
  };

  const onDosageChange = (dosage) => {
    const obj = { ...doseData };
    delete obj[2];
    setDoseData(obj);
    setDosage(dosage);
  };

  const onDoseCountChange = (doseCount) => {
    const data = JSON.parse(JSON.stringify(doseData));
    if (data[dose])
      Object.defineProperty(
        data,
        doseCount,
        Object.getOwnPropertyDescriptor(data, dose)
      );
    delete data[dose];
    setDose(doseCount);
    setDoseData(data);
  };

  return (
    <>
      <Page style={{ paddingTop: '15px', marginTop: '15px' }}>
        <div className="appointment-details__modal-content__column">
          <div className="appointment-details__modal-content__input">
            <Select
              label="Dosage"
              placeholder="Complete / Partial"
              border
              size="fullWidth"
              icon={false}
              options={[
                {
                  text: 'Complete',
                  value: 'complete',
                  content: (
                    <span>
                      <Text size="tiny">Complete</Text>
                      <Text size="tiny" color="lightGrey">
                        Issue with all doses provided at once
                      </Text>
                    </span>
                  ),
                },
                {
                  text: 'Partial',
                  value: 'partial',
                  content: (
                    <span>
                      <Text size="tiny">Partial</Text>
                      <Text size="tiny" color="lightGrey">
                        Issue with not all doses at once
                      </Text>
                    </span>
                  ),
                },
                {
                  text: 'Booster',
                  value: 'booster',
                  content: (
                    <span>
                      <Text size="tiny">Booster</Text>
                      <Text size="tiny" color="lightGrey">
                        Issue a booster dose
                      </Text>
                    </span>
                  ),
                },
                {
                  text: 'Non-requirement',
                  value: 'nonReq',
                  content: (
                    <span>
                      <Text size="tiny">Non-requirement</Text>
                      <Text size="tiny" color="lightGrey">
                        Issue as a non-requirement for another dose
                      </Text>
                    </span>
                  ),
                },
              ]}
              value={dosage}
              onChange={(e, { value }) => onDosageChange(value)}
            />
          </div>
        </div>
        <div className="appointment-details__modal-content__column">
          <div className="appointment-details__modal-content__header">
            Patient
          </div>
          <div className="appointment-details__modal-content__inputs-wrapper">
            <div className="appointment-details__modal-content__input appointment-details__loader-input">
              <Input
                size="fullWidth"
                label="Email"
                optional
                optionalLabel="Press enter to search"
                border
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={onEmailChange}
                value={email}
                invalid={errors.email}
                placeholder="Email"
              />
              {findingUser && (
                <>
                  <LinearLoader />
                  <Text
                    size="tiny"
                    className="app-creation__input-wrapper__loader-text"
                  >
                    Searching for patient
                  </Text>
                </>
              )}
            </div>
            <div className="appointment-details__modal-content__input">
              <Popup
                content="SMS with document info is sent to phone number"
                key={0}
                position="top right"
                trigger={
                  <div
                    style={{
                      position: 'absolute',
                      top: '37px',
                      right: '6px',
                      zIndex: 1,
                    }}
                  >
                    <Icon name="info" fontSize="17px" />
                  </div>
                }
              />
              <PhoneInput
                border
                label="Phone number"
                placeholder="Enter phone number"
                value={phone}
                optional
                onChange={(value) => setPhone(value)}
              />
            </div>
          </div>
          <div className="appointment-details__modal-content__inputs-wrapper">
            <div className="appointment-details__modal-content__input">
              <Input
                size="fullWidth"
                label="Patient name"
                border
                onChange={(e) => setHolderName(e.target.value)}
                value={holderName}
                invalid={errors.holderName}
                placeholder="Patient name"
              />
            </div>
            <div className="appointment-details__modal-content__input">
              <Select
                label="Nationality"
                placeholder="Nationality"
                border
                search
                size="fullWidth"
                icon={false}
                options={countriesList}
                value={nationality}
                onChange={(e, { value }) => setNationality(value)}
                invalid={errors.nationality}
              />
            </div>
            <div className="appointment-details__modal-content__input">
              <Cleave
                size="fullWidth"
                style={{ paddingLeft: '10px', width: '300px' }}
                label="Birth date"
                placeholder="DD/MM/YYYY"
                options={{
                  date: true,
                  datePattern: ['d', 'm', 'Y'],
                  delimiter: '/',
                }}
                onChange={(e) => setBirthDate(e.target.value)}
                value={birthDate}
                invalid={errors.birthDate}
              />
            </div>
            <div className="appointment-details__modal-content__input">
              <Select
                size="fullWidth"
                label="Gender"
                border
                search
                icon={false}
                placeholder="Select gender"
                options={[
                  {
                    text: 'Male',
                    value: 'Male',
                  },
                  {
                    text: 'Female',
                    value: 'Female',
                  },
                ]}
                value={gender}
                onChange={(e, { value }) => setGender(value)}
                invalid={errors.gender}
              />
            </div>
            <div className="appointment-details__modal-content__input">
              <Select
                size="fullWidth"
                label="ID Type"
                border
                search
                icon={false}
                placeholder="Select ID Type"
                options={[
                  {
                    text: 'Passport number',
                    value: 'PPN',
                  },
                  {
                    text: 'Medicare/CMS Performing Provider Identification Number',
                    value: 'PPIN',
                  },
                  {
                    text: 'Person number',
                    value: 'PN',
                  },
                  {
                    text: 'Patient external identifier',
                    value: 'PT',
                  },
                ]}
                value={idType}
                onChange={(e, { value }) => setIdType(value)}
                invalid={errors.gender}
              />
            </div>
            <div className="appointment-details__modal-content__input">
              <Input
                size="fullWidth"
                label="ID no."
                border
                onChange={(e) => setPassNo(e.target.value)}
                value={passNo}
                invalid={errors.passNo}
                placeholder="ID no."
              />
            </div>
            <div className="appointment-details__modal-content__input">
              <Input
                size="fullWidth"
                label="NRIC / FIN no."
                optional
                border
                onChange={(e) => setSSN(e.target.value)}
                value={ssn}
                invalid={errors.ssn}
                placeholder="NRIC / FIN no."
              />
            </div>
          </div>
        </div>
        {(dosage === 'complete' ||
          dosage === 'booster' ||
          dosage === 'nonReq') && (
          <DoseSection
            errors={errors[1]}
            doseData={doseData[1]}
            setDoseData={onDoseDataChange}
            dosage={dosage}
            clinics={clinics}
            physicians={physicians}
          />
        )}
        {dosage !== 'booster' && dosage !== 'nonReq' && (
          <DoseSection
            errors={errors[2]}
            doseData={doseData[2]}
            setDoseData={onDoseDataChange}
            doseCount={dosage === 'partial' ? dose : 2}
            loadingVac={loadingVac}
            pastVacs={pastVacs}
            linkId={linkId}
            setLinkId={setLinkId}
            clinics={clinics}
            physicians={physicians}
            dosage={dosage}
            setDose={onDoseCountChange}
            onRemove={onDoseRemove}
          />
        )}
        <div className="appointment-details__modal-content__column">
          <div className="appointment-details__modal-content__header">
            Email
          </div>
          {email?.length > 0 && (
            <Text style={{ padding: '5px' }} size="tiny">
              Email is automatically sent to patient email ({email})
              {clientInfo.notCcAll
                ? '.'
                : ` and account
              holder email (${clientInfo.accountHolderEmail}).`}
            </Text>
          )}
          <div className="appointment-details__modal-content__inputs-wrapper">
            <div className="appointment-details__modal-content__input">
              <Input
                optional
                size="fullWidth"
                label="CC email"
                border
                onChange={(e) => setCcEmail(e.target.value)}
                value={ccEmail}
                placeholder="CC email"
              />
            </div>
            <div className="appointment-details__modal-content__input">
              <Input
                optional
                size="fullWidth"
                label="CC email"
                border
                onChange={(e) => setCcEmail1(e.target.value)}
                value={ccEmail1}
                placeholder="CC email"
              />
            </div>
            <div className="appointment-details__modal-content__input">
              <Input
                optional
                size="fullWidth"
                label="CC email"
                border
                onChange={(e) => setCcEmail2(e.target.value)}
                value={ccEmail2}
                placeholder="CC email"
              />
            </div>
          </div>
        </div>
        <div className="appointment-details__modal-content__buttons">
          <Button
            size="wide"
            color="blue"
            onClick={issueCertificate}
            disabled={isSaving}
            style={{ width: 400 }}
          >
            {isSaving ? (
              <Loader active inverted size="tiny" color="white" />
            ) : (
              'Issue'
            )}
          </Button>
        </div>
      </Page>
    </>
  );
};

export default CovidVac;
