import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    width="inherit"
    height="inherit"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    {...props}
  >
    <g>
      <path
        d="M22.167 5.833h-3.5V4.666a3.5 3.5 0 0 0-3.5-3.5h-2.334a3.5 3.5 0 0 0-3.5 3.5v1.167h-3.5A5.84 5.84 0 0 0 0 11.666V21a5.84 5.84 0 0 0 5.833 5.833h16.334A5.84 5.84 0 0 0 28 21v-9.334a5.84 5.84 0 0 0-5.833-5.833Zm-10.5-1.167A1.167 1.167 0 0 1 12.833 3.5h2.334a1.167 1.167 0 0 1 1.166 1.167v1.166h-4.666V4.666Zm14 16.334a3.5 3.5 0 0 1-3.5 3.5H5.833a3.5 3.5 0 0 1-3.5-3.5v-9.334a3.5 3.5 0 0 1 3.5-3.5h16.334a3.5 3.5 0 0 1 3.5 3.5V21Zm-7-4.667A1.167 1.167 0 0 1 17.5 17.5h-2.333v2.333a1.167 1.167 0 0 1-2.334 0V17.5H10.5a1.167 1.167 0 1 1 0-2.334h2.333v-2.333a1.167 1.167 0 1 1 2.334 0v2.333H17.5a1.167 1.167 0 0 1 1.167 1.167Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={props.color} d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
