import React from 'react';

const SvgBarChart = props => (
  <svg width="1em" height="1em" viewBox="0 0 29 26" {...props}>
    <g
      transform="translate(1)"
      stroke={props.color}
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
    >
      <rect x={1.5} y={7.5} width={8} height={17} rx={2} />
      <rect x={9.5} y={1.5} width={8} height={23} rx={2} />
      <rect x={17.5} y={11.5} width={8} height={13} rx={2} />
    </g>
  </svg>
);

SvgBarChart.defaultProps = {
  color: '#1b1b1b',
};

export default SvgBarChart;
