import React, { useState } from 'react';
import { Button, ButtonGroup, Icon } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';

import './ImageViewer.scss';

const ImageViewer = ({ imgUrl }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [scale, setScale] = useState(1);

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    setScale(scale - 0.1);
  };

  return (
    <div className="imageViewer">
      <ButtonGroup className="imageViewer__tools">
        <Button onClick={() => handleZoomIn()} icon>
          <Icon name="zoom-in" />
        </Button>
        <Button onClick={() => handleZoomOut()} icon>
          <Icon name="zoom-out" />
        </Button>
        <Button onClick={() => setScale(1)} icon>
          <Icon name="refresh" />
        </Button>
      </ButtonGroup>
      <div className="imageViewer__wrapper">
        <div className="imageViewer__main">
          <img
            src={imgUrl}
            alt="report"
            style={{
              transform: `scale(${scale})`,
            }}
            className="imageViewer__img"
          />
        </div>
      </div>
    </div>
  );
};
export default ImageViewer;
