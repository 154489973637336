import gql from 'graphql-tag';

export const searchPatients = gql`
  query searchPatients(
    $clientId: ID!
    $search: String
    $limit: Int
    $offset: Int
  ) {
    searchPatients(
      clientId: $clientId
      search: $search
      limit: $limit
      offset: $offset
    ) {
      id
      name
      email
      phoneNumber
      nationality
      natCode
      birthDate
      gender
      passNo
      ssn
    }
  }
`;

export const getPatient = gql`
  query getPatient($clientId: ID!) {
    getPatient(clientId: $clientId) {
      id
      type
      description
      name
      eventName
      startDate
      endDate
      connections
    }
  }
`;
