import React, { useState, useEffect } from 'react';
import { Accordion, Icon, Table, Label } from 'semantic-ui-react';
import moment from 'moment';

import emailService from 'api-delta/email';

import './EmailActivityAccordion.scss';

const EmailActivityAccordion = ({ certId }) => {
  const [active, setActive] = useState(false);
  const [emailData, setEmailData] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    setActive(!active);
  };

  const fetchEvents = async () => {
    try {
      const {
        data: { getEmailActivityByCertId },
      } = await emailService.getEmailActivityByCertId({ certId });
      setEmailData(getEmailActivityByCertId);
    } catch (error) {
      console.log(error);
    }
  };

  const renderStatusColor = status => {
    if (status === 'delivered' || status === 'processed') return 'green';
    if (status === 'bounce' || status === 'dropped') return 'red';
    return 'green';
  };

  const getStatusText = status => {
    if (status === 'bounce' || status === 'dropped') return 'Unable to deliver';
    return 'Delivered';
  };

  const renderTableData = () => {
    return emailData.map((data, i) => {
      return (
        <Table.Row className="table-row" key={i}>
          <Table.Cell>
            <Icon name="envelope" />
          </Table.Cell>
          <Table.Cell>{data.email}</Table.Cell>
          <Table.Cell>
            {moment(data.timestamp).format('DD-MM-YYYY HH:mm')}
          </Table.Cell>
          <Table.Cell>
            {
              <Label
                color={renderStatusColor(data.event)}
                style={{ fontWeight: 500, fontSize: '13px', lineHeight: 0.8 }}
              >
                {getStatusText(data.event)}
              </Label>
            }
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div className="email-accordion">
      {emailData.length > 0 && (
        <div className="email-accordion__events-wrapper">
          <Accordion fluid styled>
            <Accordion.Title active={active} index={0} onClick={handleClick}>
              <Table.Row>
                <Icon name="dropdown" />
                {!active ? (
                  <>
                    <Table.Cell>{emailData[0].email}</Table.Cell>
                    <Table.Cell className="email-accordion__events-wrapper__status">
                      {
                        <Label
                          color={renderStatusColor(emailData[0].event)}
                          style={{
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: 0.8,
                          }}
                        >
                          {getStatusText(emailData[0].event)}
                        </Label>
                      }
                    </Table.Cell>
                  </>
                ) : (
                  'Email Events'
                )}
              </Table.Row>
              <a className="email-accordion__events-wrapper__more">See more</a>
            </Accordion.Title>

            <Accordion.Content active={active}>
              <Table singleLine fixed unstackable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}></Table.HeaderCell>

                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Event Time</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Status</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{renderTableData()}</Table.Body>
              </Table>
            </Accordion.Content>
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default EmailActivityAccordion;
