const colors = {
  FADED_WHITE: '#FBFBFB',
  GREY: '#dddddd',
  LIGHT_GREY: '#f5f5f5',
  DARK_GREY: '#2b2b2b',
  BLACK: 'black',
  WHITE: '#FFFFFF',
  BLUE: '#5076ff',
  PURPLE: '#5076ff',
  DARK_BLUE: '#39396c',
  YELLOW: '#ffc727',
  GREEN: '#19b970',
  HIGHLIGHT_BLUE: '#3357d9',
  HIGHLIGHT_GREEN: '#53f393',
  RED: '#f35353',
  ORANGE: '#FF9B71',
};

export default colors;