import React from 'react';

const SvgSearch = props => (
  <svg width="1em" height="1em" viewBox="0 0 27 25" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#1b1b1b" strokeWidth={3}>
      <circle cx={10.5} cy={10.5} r={8.5} />
      <path d="M17.5 16.5l7 6" strokeLinecap="round" />
    </g>
  </svg>
);

export default SvgSearch;
