import { patientOverviewClient } from './patientOverviewClient';
import pick from '../../utils/pick';

async function getAll({ search, offset, limit }) {
  try {
    const requestBody = pick.pickNotNull({
      search,
      offset,
      limit,
    });
    const profileResponse = await patientOverviewClient.post(
      '/medicalProfile/public/familyHistory/listAll',
      requestBody
    );
    return profileResponse.data?.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getFamilyHistoryOfPatient() {
  try {
    const profileResponse = await patientOverviewClient.post(
      'medicalProfile/getByUser',
      { fields: ['familyHistoryList'] }
    );
    return profileResponse.data?.data?.profile?.familyHistoryList || [];
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function addFamilyHistory(familyHistory) {
  const response = await patientOverviewClient.post('medicalProfile/create', {
    familyHistory,
  });

  return response.data;
}

async function updateFamilyHistory(familyHistory) {
  const response = await patientOverviewClient.post(
    'medicalProfile/familyHistory/update',
    familyHistory
  );
  return response.data;
}

async function deleteFamilyHistory(id) {
  const response = await patientOverviewClient.post(
    'medicalProfile/familyHistory/delete',
    {
      id,
    }
  );
  return response.data;
}

const familyHistoryApi = {
  getAll,
  getFamilyHistoryOfPatient,
  addFamilyHistory,
  updateFamilyHistory,
  deleteFamilyHistory,
};

export default familyHistoryApi;
