import React, { useCallback } from 'react';
import { Table } from 'semantic-ui-react';

import NormalIcon from 'assets/range-icons/normal.png';
import HighIcon from 'assets/range-icons/high.png';
import LowIcon from 'assets/range-icons/low.png';
import DetectedIcon from 'assets/range-icons/detected.png';
import NotDetectedIcon from 'assets/range-icons/not-detected.png';
import { Text } from 'components';

import TableCatRow from './tableCatRow/TableCatRow';

import './observationsTable.scss';

const values = {
  borderline: {
    name: 'Borderline',
    color: 'orange',
    colorCode: '#ffaf3e',
    icon: HighIcon,
  },
  desirable: {
    name: 'Desirable',
    color: 'green',
    colorCode: '#00c48c',
    icon: NormalIcon,
  },
  high: { name: 'High', color: 'red', colorCode: '#ff6666', icon: HighIcon },
  microalbuminuria: {
    name: 'Microalbuminuria',
    color: 'red',
    colorCode: '#ff6666',
    icon: HighIcon,
  },
  macroalbuminuria: {
    name: 'Macroalbuminuria',
    color: 'red2',
    colorCode: '#ff3a3a',
    icon: HighIcon,
  },
  criticalhigh: {
    name: 'Very High',
    color: 'red2',
    colorCode: '#ff3a3a',
    icon: HighIcon,
  },
  veryhigh: {
    name: 'Very High',
    color: 'red2',
    colorCode: '#ff3a3a',
    icon: HighIcon,
  },
  verylow: {
    name: 'Very Low',
    color: 'yellow',
    colorCode: '#ffd443',
    icon: LowIcon,
  },
  lowrisk: {
    name: 'Low Risk',
    color: 'greenish2',
    colorCode: '#12b001',
    icon: NormalIcon,
  },
  averagerisk: {
    name: 'Average Risk',
    color: 'green',
    colorCode: '#00c48c',
    icon: NormalIcon,
  },
  highrisk: {
    name: 'High Risk',
    color: 'red',
    colorCode: '#ff6666',
    icon: HighIcon,
  },
  low: { name: 'Low', color: 'yellow', colorCode: '#ffd443', icon: LowIcon },
  optimal: {
    name: 'Optimal',
    color: 'greenish',
    colorCode: '#12b001',
    icon: NormalIcon,
  },
  suboptimal: {
    name: 'subOptimal',
    color: 'yellow',
    colorCode: '#ffd443',
    icon: LowIcon,
  },
  normal: {
    name: 'Normal',
    color: 'green',
    colorCode: '#00c48c',
    icon: NormalIcon,
  },
  sufficient: {
    name: 'Sufficient',
    color: 'green',
    colorCode: '#00c48c',
    icon: NormalIcon,
  },
  ideal: {
    name: 'Ideal',
    color: 'greenish2',
    colorCode: '#12b001',
    icon: NormalIcon,
  },
  grayzoneta: {
    name: 'Grayzoneta',
    color: 'greenish2',
    colorCode: '#12b001',
    icon: NormalIcon,
  },
  acceptable: {
    name: 'Acceptable',
    color: 'green',
    colorCode: '#00c48c',
    icon: DetectedIcon,
  },
  unacceptable: {
    name: 'Unacceptable',
    color: 'red',
    colorCode: '#ff6666',
    icon: NotDetectedIcon,
  },
  negative: {
    name: 'Negative',
    color: 'green',
    colorCode: '#00c48c',
    icon: NotDetectedIcon,
  },
  positive: {
    name: 'Positive',
    color: 'red',
    colorCode: '#ff6666',
    icon: DetectedIcon,
  },
  abnormal: {
    name: 'Abnormal',
    color: 'red',
    colorCode: '#ff6666',
    icon: HighIcon,
  },
  'non-reactive': {
    name: 'Non-Reactive',
    color: 'green',
    colorCode: '#00c48c',
    icon: NotDetectedIcon,
  },
  reactive: {
    name: 'Reactive',
    color: 'red',
    colorCode: '#ff6666',
    icon: DetectedIcon,
  },
  nil: {
    name: 'Nil',
    color: 'green',
    colorCode: '#00c48c',
    icon: NotDetectedIcon,
  },
  trace: {
    name: 'Trace',
    color: 'orange',
    colorCode: '#ffaf3e',
    icon: DetectedIcon,
  },
  deficient: {
    name: 'Trace',
    color: 'red',
    colorCode: '#ff6666',
    icon: DetectedIcon,
  },
  insufficient: {
    name: 'Trace',
    color: 'orange',
    colorCode: '#ffaf3e',
    icon: DetectedIcon,
  },
};

const ObservationTables = ({
  observationsByCat = [],
  categoryDescriptions,
  graphData,
  expandAll,
  setExpandAll,
}) => {
  const onRowClick = (observation) => {
    // TODO
    console.log(observation);
  };

  const renderTableData = useCallback(() => {
    if (
      !observationsByCat ||
      !Object.keys(observationsByCat) ||
      !Object.keys(observationsByCat)?.length
    )
      return;
    const cats = Object.keys(observationsByCat);
    const othersCat = cats.splice(cats.indexOf('undefined'), 1)[0];
    const normalCats = cats.filter(
      (cat) => !observationsByCat[cat]?.abnormalCount
    );
    const abnormalCats = cats.filter(
      (cat) => observationsByCat[cat]?.abnormalCount
    );

    normalCats?.sort((key1, key2) =>
      String(categoryDescriptions[key1]?.category).localeCompare(
        String(categoryDescriptions[key2]?.category)
      )
    );
    abnormalCats?.sort((key1, key2) =>
      String(categoryDescriptions[key1]?.category).localeCompare(
        String(categoryDescriptions[key2]?.category)
      )
    );

    const rows = [...abnormalCats, ...normalCats, othersCat]?.map((key) => (
      <TableCatRow
        key={key}
        categoryKey={key}
        categoryDescriptions={categoryDescriptions}
        observationsByCat={observationsByCat}
        values={values}
        graphData={graphData}
        onRowClick={onRowClick}
        expandAll={expandAll}
        setExpandAll={setExpandAll}
      />
    ));
    return rows;
  }, [observationsByCat, expandAll, graphData, categoryDescriptions]);


  return (
    <div className="obs-table__table-wrapper">
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              className="obs-table__th"
              style={{ minWidth: '100px' }}
            >
              <Text id="react_report.test_code" bold>
                Test Code
              </Text>
            </Table.HeaderCell>
            <Table.HeaderCell className="obs-table__th">
              <Text id="react_report.display" bold>
                Display
              </Text>
            </Table.HeaderCell>
            <Table.HeaderCell className="obs-table__th">
              <Text id="react_single_overview.result" bold>
                Result
              </Text>
            </Table.HeaderCell>
            <Table.HeaderCell
              className="obs-table__th"
              style={{ minWidth: '170px' }}
            >
              <Text id="react_report.Issued" bold>
                Issued
              </Text>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{renderTableData()}</Table.Body>
      </Table>
    </div>
  );
};

export default ObservationTables;
