import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Text, ErrorToast } from 'components';
import PageHeader from '../components/PageHeader/PageHeader';
import ethnicities from 'utils/ethnicities';

import ReactHealthQuestionsForm from './components/ReactHealthQuestionsForm';

import medicalProfileApi from 'api/patientOverview/medicalProfileApi';
import patientReactSessionApi from 'api/patientReactSession';

import history from '../../../history';

import './reactHealthQuestions.scss';

const ReactHealthQuestions = () => {
  const userInfo = useSelector((state) => state.general.user);
  const [isSaving, setIsSaving] = useState(false);
  const [riskInfo, setRiskAssessmentInfo] = useState({});

  useEffect(() => {
    getRiskAssessmentInformation();
  }, []);

  const getRiskAssessmentInformation = async () => {
    const { data } = await medicalProfileApi.getRiskAssessmentInformation();
    setRiskAssessmentInfo(data.profile);
  };

  const updateRiskAssessment = async (details) => {
    try {
      setIsSaving(true);
      const formattedData = {
        gender: details.gender,
        height: details.height,
        weight: details.weight,
        ethnicity: ethnicities.find((eth) => eth.value === details.ethnicity),
        basicHealthInformation: {
          gender: details.gender,
          height: details.height,
          weight: details.weight,
          ethnicity: details.ethnicity,
          smoke: details?.smoker,
          isDiabetic: Boolean(details?.diabetic),
          alcoholicDrinkPerWeek: details?.alcoholicDrinkPerWeek,
          isLipidLoweringMedication: Boolean(details?.lipidMeds),
          isAntiHypertensiveMedication: Boolean(details?.hypertensiveMeds),
          parentalHyperTension: details?.parentalHyperTension,
          isFamilyHistoryOfHeartAttack: Boolean(details?.familyHeartAttack),
          isFamilyHistoryOfDiabetes: Boolean(details?.diabetesFamilyHistory),
          isHistoryOfTreatedHypertension: Boolean(
            details?.historyTreatedHypertension
          ),
          isHistoryOfCardiovascularDisease: Boolean(
            details?.historyCardiovascularDisease
          ),
          isHistoryOfGestationalDiabetes: Boolean(
            details?.historyGestationalDiabetes
          ),
          isHistoryOfPolycysticOvarySyndrome: Boolean(
            details?.historyPolycysticOvarySyndrome
          ),
          isHistoryOfLearningDifficulties: Boolean(
            details?.historyLearningDifficulties
          ),
          isHistoryOfSchizophreniaOrBipolarAffectiveDisorder: Boolean(
            details?.historySchizophreniaBipolar
          ),
          isHistoryOf2ndGenerationAntipsychoticUse: Boolean(
            details?.historyOf2ndGenerationAntipsychoticUse
          ),
          isHistoryOfCorticosteroidUse: Boolean(
            details?.historyOfCorticosteroidUse
          ),
          isHistoryOfStatinUse: Boolean(details?.historyOfStatinUse),
        },
      };

      await medicalProfileApi.updateAssessmentInformation(formattedData);
      const sessions = await fetchParticipantSessionsByPatId(userInfo.email);

      await Promise.all(
        sessions.map((sess) =>
          patientReactSessionApi.completeQuestionnaire(sess.id, formattedData)
        )
      );
      history.push({ pathname: '/p', state: { isCompleteRQuest: true } });
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsSaving(false);
    }
  };

  const fetchParticipantSessionsByPatId = async () => {
    if (!userInfo.id) return;
    const patientReactSessions =
      await patientReactSessionApi.getPatientReactSessionsByEmail(
        userInfo.email
      );
    return patientReactSessions?.data;
  };

  return (
    <div className="r-health-questions">
      <div className="r-health-questions__header">
        <PageHeader title="ReACT Questionnaire" backPath="/p" />
      </div>
      <div className="r-health-questions__info">
        <Text bold>
          Completing the questionnaire as accurately as possible will help with
          the accuracy of your health risk assessment.
        </Text>
      </div>
      <ReactHealthQuestionsForm riskInfo={riskInfo} isSaving={isSaving} onSubmit={updateRiskAssessment} />
    </div>
  );
};

export default withRouter(ReactHealthQuestions);
