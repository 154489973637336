import { patientOverviewClient } from './patientOverviewClient';
import pick from '../../utils/pick';

async function getAll({ search, offset, limit }) {
  try {
    const requestBody = pick.pickNotNull({
      search,
      offset,
      limit,
    });
    const profileResponse = await patientOverviewClient.post(
      'medicalProfile/public/medications/listAll',
      requestBody
    );
    return profileResponse.data?.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getMedicationsOfPatient() {
  try {
    const profileResponse = await patientOverviewClient.post(
      'medicalProfile/getByUser',
      { fields: ['medicationList'] }
    );
    return profileResponse.data?.data?.profile?.medicationList || [];
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function addMedication(medication) {
  const response = await patientOverviewClient.post('medicalProfile/create', {
    medication,
  });

  return response.data;
}

async function updateMedication(medication) {
  const response = await patientOverviewClient.post(
    'medicalProfile/medications/update',
    medication
  );
  return response.data;
}

async function deleteMedication(id) {
  const response = await patientOverviewClient.post(
    'medicalProfile/medications/delete',
    {
      id,
    }
  );
  return response.data;
}

const medicationApi = {
  getAll,
  getMedicationsOfPatient,
  addMedication,
  updateMedication,
  deleteMedication,
};

export default medicationApi;
