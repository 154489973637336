import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button as Sbutton, Icon as SIcon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

import ObservationTables from './components/observationsTable/ObservationsTable';
import ObservationsTableMobile from './components/observationsTable-mobile/ObservationsTable';
import LabReportPdfPreview from './components/labReportPdfPreview/labReportPdfPreview';
import SmartReportPreview from './components/smartReportPreview/SmartReportPreview';
import BiomarkerExtractionPlaceholder from './components/biomarkerExtractionPlaceholder/BiomarkerExtractionPlaceholder';

import { Text, Button } from 'components';

import NormalIcon from 'assets/range-icons/normal.png';
import HighIcon from 'assets/range-icons/high.png';
import LowIcon from 'assets/range-icons/low.png';
import DetectedIcon from 'assets/range-icons/detected.png';
import NotDetectedIcon from 'assets/range-icons/not-detected.png';

import './biomarkerOverview.scss';
import { usePusher } from '../../hooks/usePusher';
import resultApi from '../../api/result/resultApi';
import ObservationTableHl7 from './components/observationsTable/ObservationsTableHl7';

const BiomarkerOverview = ({
                             match,
                             diagnosticReport,
                             viewType,
                             pdf,
                             filteredObservationsByCat,
                             categoryDescriptions,
                             graphData,
                             onStatusChange,
                             setViewType,
                             patientDetails,
                             typeSelector = true,
                             hideTitle = false
                           }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const userInfo = useSelector((state) => state.general.user);
  const [shouldHideBioMarkerOverview, setShouldHideBioMarkerOverview] = useState(diagnosticReport?.anomalyProcessState === 'pending');
  const [expandAllObsTableRows, setExpandAllObsTableRows] = useState('all-collapsed');
  const [showFixedButtons, setShowFixedButtons] = useState(false);
  const [shouldRenderHl7Table, setShouldRenderHl7Table] = useState(false);

  const { clientEvent } = usePusher(
    userInfo,
    JSON.parse(localStorage.getItem('REFRESH_TOKEN'))?.token,
    'LAB-RESULT_UPLOAD_COMPLETE'
  );

  useEffect(() => {
    if (!diagnosticReport) return;
    const { event, metadata } = clientEvent;
    const id = metadata?.id;
    if (id === diagnosticReport._id && event === 'LAB-RESULT_UPLOAD_COMPLETE') {
      setShouldHideBioMarkerOverview(false);
      window.location.reload();
    }
  }, [clientEvent, diagnosticReport]);

  useEffect(() => {
    if (diagnosticReport?.anomalyProcessState === 'pending') {
      let attempt = 0;
      const intervalId = setInterval(async () => {
        attempt++;
        const anomalyProcessState = await resultApi.getAnomalyProcessState(diagnosticReport._id);
        if (anomalyProcessState === 'success') {
          setShouldHideBioMarkerOverview(false);
          window.location.reload();
          clearInterval(intervalId);
        } else if (attempt === 20) {
          clearInterval(intervalId);
        }
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [diagnosticReport]);

  useEffect(() => {
    const shouldRender = diagnosticReport?.order?.id && userInfo.currentClientId === 'sIdzwYsvwehphIHNG2ce';
    setShouldRenderHl7Table(shouldRender);
  }, [diagnosticReport, userInfo]);

  useEffect(() => {
    const pageWrapper = document.getElementById('page-wrapper__content');
    if (!pageWrapper) return;
    pageWrapper.addEventListener('scroll', scrollPastExapndButtonsDetector);
    return () => {
      pageWrapper.removeEventListener('scroll', scrollPastExapndButtonsDetector);
    };
  }, []);

  const scrollPastExapndButtonsDetector = useCallback(() => {
    const elementTarget = document.getElementById("buttons-collapse");
    const scrollElement = document.getElementById('page-wrapper__content');
    if (!elementTarget || !scrollElement) return;
    setShowFixedButtons(scrollElement.scrollTop > (elementTarget?.offsetTop + elementTarget?.offsetHeight + 50));
  }, []);

  const renderLegend = () => {
    if (shouldRenderHl7Table) return null;

    return (
      <div className={`biomarker-overview__legend${hideTitle ? ' biomarker-overview__legend--hide-title' : ''}`}>
        <div className="biomarker-overview__legend__sec biomarker-overview__legend__sec--green">
          <img className="biomarker-overview__legend__icon" src={NormalIcon} alt="icon" />
          Normal
        </div>
        <div className="biomarker-overview__legend__sec biomarker-overview__legend__sec--yellow">
          <img className="biomarker-overview__legend__icon" src={LowIcon} alt="icon" />
          Low
        </div>
        <div className="biomarker-overview__legend__sec biomarker-overview__legend__sec--red">
          <img className="biomarker-overview__legend__icon" src={HighIcon} alt="icon" />
          High
        </div>
        <Text color="lightGrey" className="mr-3">|</Text>
        <div className="biomarker-overview__legend__sec biomarker-overview__legend__sec--green">
          <img className="biomarker-overview__legend__icon" src={NotDetectedIcon} alt="icon" />
          Negative
        </div>
        <div className="biomarker-overview__legend__sec biomarker-overview__legend__sec--red">
          <img className="biomarker-overview__legend__icon" src={DetectedIcon} alt="icon" />
          Positive
        </div>
      </div>
    );
  };

  const renderTypeSelector = () => (
    <div className="biomarker-overview__report-wrapper__header">
      <Sbutton.Group className="biomarker-overview__report-wrapper__header__buttons">
        <Sbutton onClick={() => setViewType('table')} active={viewType === 'table'}>
          <Text size="tiny" color="inherit" id="react_report.table_view">Table View</Text>
        </Sbutton>
        <Sbutton onClick={() => setViewType('pdf')} active={viewType === 'pdf'}>
          <Text size="tiny" color="inherit" id="react_report.pdf_report">PDF Report</Text>
        </Sbutton>
        {!!diagnosticReport?.smartReportUrl && (
          <Sbutton
            onClick={() => setViewType('smartRep')}
            active={viewType === 'smartRep'}
            style={{ width: '150px !important', 'max-width': '130px !important' }}
          >
            <Text size="tiny" color="inherit">ReACT Report</Text>
          </Sbutton>
        )}
      </Sbutton.Group>
    </div>
  );

  const renderCollapseButtons = () => {
    if (shouldRenderHl7Table) return null;

    return (
      <div className="biomarker-overview__report-wrapper__header__buttons-collapse" id="buttons-collapse">
        <Button
          color="#333"
          inverted
          onClick={() => setExpandAllObsTableRows('all-expanded')}
          fontSize="tiny"
          size={isMobile && 'fullWidth'}
        >
          <SIcon name="arrow down" />
          <Text id="react_report.expand_all" size="tiny">Expand All</Text>
        </Button>
        <Button
          color="#333"
          inverted
          onClick={() => setExpandAllObsTableRows('all-collapsed')}
          fontSize="tiny"
          size={isMobile && 'fullWidth'}
        >
          <SIcon name="arrow up" />
          <Text id="react_report.collapse_all" size="tiny">Collapse All</Text>
        </Button>
      </div>
    );
  };

  const renderContent = () => {
    switch (viewType) {
      case 'table':
        if (shouldRenderHl7Table) {
          return <ObservationTableHl7 orderId={diagnosticReport.order.id} />;
        } else {
          return isMobile ? (
            <ObservationsTableMobile
              observationsByCat={filteredObservationsByCat}
              categoryDescriptions={categoryDescriptions}
              graphData={graphData}
              onStatusChange={onStatusChange}
              expandAll={expandAllObsTableRows}
              setExpandAll={setExpandAllObsTableRows}
            />
          ) : (
            <ObservationTables
              observationsByCat={filteredObservationsByCat}
              categoryDescriptions={categoryDescriptions}
              graphData={graphData}
              onStatusChange={onStatusChange}
              expandAll={expandAllObsTableRows}
              setExpandAll={setExpandAllObsTableRows}
            />
          );
        }
      case 'pdf':
        return (
          <LabReportPdfPreview
            pdf={pdf}
            patient={patientDetails}
            reportId={diagnosticReport.fhirId || diagnosticReport._id || match.params.id}
          />
        );
      case 'smartRep':
        return (
          <SmartReportPreview
            reportId={diagnosticReport._id}
            patient={patientDetails}
            result={diagnosticReport}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="biomarker-overview">
      {(viewType === 'table' && showFixedButtons && !shouldRenderHl7Table) && (
        <div className="biomarker-overview__fixed-collapse">
          {renderCollapseButtons()}
        </div>
      )}
      {!hideTitle && (
        <Text id="react_report.biomaker_overview" dark size="big" className="diagnostic-report__category-title">
          Biomarker Overview
        </Text>
      )}
      <div className="biomarker-overview__header">
        <div className="biomarker-overview__header__left">
          {renderLegend()}
          {typeSelector && renderTypeSelector()}
        </div>
        {viewType === 'table' && renderCollapseButtons()}
      </div>
      <div className="biomarker-overview__report-wrapper">
        {renderContent()}
      </div>
    </div>
  );
};

export default BiomarkerOverview;
