import jwt from 'jwt-decode'; // import dependency

import AuthService from 'api/identity/authentication';
import history from '../history';

export const clearUserStorage = () => {
  // clearInterval(window.refreshToken);
  sessionStorage.clear();
  localStorage.clear();
};

export const createUserStorage = async (user) => {
  localStorage.setItem('USER_ID', user.safe_id);
  localStorage.setItem('USER_SAFE_ID', user.safe_id);
  localStorage.setItem('USER_INFO', JSON.stringify(user));
};

export const setTokens = (tokens) => {
  localStorage.setItem('AUTH_TOKEN', JSON.stringify(tokens.accessToken));
  localStorage.setItem('REFRESH_TOKEN', JSON.stringify(tokens.refreshToken));
};

export const setClientStorage = (client) => {
  localStorage.setItem('ISSUER', client.name);
  localStorage.setItem('ROOT_ID', client._id);
  localStorage.setItem('DELTA_CLIENT_ID', client.clientId);
  localStorage.setItem('CLIENT_LOGO', client.logo);
};

export const signOutUser = async (redirect) => {
  const rootId = localStorage.getItem('ROOT_ID');
  try {
    window.Intercom('shutdown');
    // const rLocalToken = localStorage.getItem('REFRESH_TOKEN');
    // if(rLocalToken) {
    //   console.log("rLocalToken", rLocalToken)
    //   const rToken = JSON.parse(rLocalToken).token;
    //   await refreshUserToken(rToken);
    //   clearUserStorage(rootId);
    //   return
    // }
    clearUserStorage();
    if (redirect) history.push('/');
  } catch (error) {
    clearUserStorage();
    history.push('/');
  }
};

export const refreshUserToken = async (params) => {
  const rtoken = localStorage.getItem('REFRESH_TOKEN');
  if (!rtoken) return signOutUser(true);
  try {
    const parsedToken = JSON.parse(localStorage.getItem('REFRESH_TOKEN'));
    const path = history.location.pathname;
    const { data } = await AuthService.refreshToken(parsedToken?.token);
    if (!data) throw Error('No Data');
    createUserStorage(data);

    const parsedLocalToken = jwt(data.access_token);
    const parsedRefreshToken = jwt(data.id_token);

    const localToken = {
      token: data.access_token,
      expires: parsedLocalToken.StandardClaims.exp,
      // expires: moment.utc().add(5, 'seconds'),
    };
    const refreshToken = {
      token: data.id_token,
      expires: parsedRefreshToken.StandardClaims.exp,
      // expires: moment.utc().add(10, 'seconds'),
    };
    const tokens = { accessToken: localToken, refreshToken: refreshToken };
    setTokens(tokens);
    if (path === '/') await history.push('/results');
    return tokens;
  } catch (error) {
    console.log('er refreshUserToken error', error);
    signOutUser(true);
    history.push({ pathname: '/', search: params });
  }
};

export const checkIfTokenExist = () => !!localStorage.getItem('AUTH_TOKEN');
