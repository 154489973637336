import {
  SET_COMPLETED_COUNT,
} from './dashboard.actions';

const initialState = {
  completedCourses: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPLETED_COUNT:
      return { ...state, completedCourses: action.payload };

    default:
      return state;
  }
};
