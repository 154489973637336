import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// icons
import LeftArrow from 'assets/icons/ArrowLeft';
import Cross from 'assets/icons/Cross';

import PemeCreator from '../../../appointmentPages/appointmentDetails/components/PemeAppointment';

import history from '../../../../history';

import TrainingCenterService from 'api-delta/trainingCenters';
import CeritificateService from 'api-delta/certificates';

//components
import { Page, Button, Select, ErrorToast } from 'components';

import './certificateCreation.scss';

// TODO: Split this monster component up
const PemeCreation = props => {
  const clientId = useSelector(state => state.general.clientInformation.clientId);
  const docTemplates = useSelector(state => state.general.templates);

  const [facilitiesOptions, setFacilitiesOptions] = useState([]);

  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const {
          data: { trainingCenters },
        } = await TrainingCenterService.getTrainingCentersByClientId(clientId);

        const facilitiesOptions = trainingCenters.map(facility => ({
          text: facility.name,
          value: {
            ...facility,
          },
        }));
        setFacilitiesOptions(facilitiesOptions);
      } catch (error) {
        toast.error(<ErrorToast error={error} />);
      }
    };
    fetchFacilities();
  }, [clientId]);

  return (
    <>
      {props.isTemplateCreator && (
        <>
          <Button
            floated="left"
            circular
            icon
            size="medium"
            onClick={() => history.goBack()}
            style={{ position: 'absolute', top: '15px', left: '50px' }}
            color="blue"
          >
            <LeftArrow color="white" fontSize="1.3em" />
          </Button>
          <Button
            floated="right"
            circular
            icon
            size="medium"
            color="blue"
            onClick={() => history.push('/documents/templates')}
            style={{ position: 'absolute', top: '15px', right: '50px' }}
          >
            <Cross color="white" fontSize="1.3em" />
          </Button>
        </>
      )}
      <div className="certificate-creation__content">
        <Page style={{ paddingTop: '15px' }}>
          <PemeCreator
            isDocCreator={true}
            facilitiesOptions={facilitiesOptions}
            _subType={props.subType}
            setRecentDocs={props.setRecentDocs}
            onIssue={payload =>
              CeritificateService.issuePemeCertificate(payload)
            }
          />
        </Page>
      </div>
    </>
  );
};

export default withRouter(PemeCreation);
