import _ from 'lodash';
import {
  SET_TEST_TYPE,
  UPDATE_CART,
  SET_PATIENT_DETAILS,
  UPDATE_ADULT_COUNT,
  UPDATE_CHILD_COUNT,
  SET_DETAILS,
  SET_SELECTED_DATE,
  SET_SELECTED_TIME,
  SET_TIME_SLOT_ID,
  UPDATE_PATIENT_DETAILS,
  UPDATE_ADDRESS_DETAILS,
  UPDATE_TOTAL_PAYMENT,
} from './webAppointments.actions';

const patientObject = {
  fullName: '',
  email: '',
  phone: '',
  passNo: '',
  title: '',
  gender: '',
  dob: '',
  nationality: '',
  isValid: false,
};

const addressObject = {
  address: '',
  unitNumber: '',
  postCode: '',
  remark: '',
  isValid: false,
};

const testTypesObject = [
  {
    text: 'No appointments',
    value: {
      name: '',
      price: '',
      type: '',
      currency: '',
      description: '',
      packageType: '',
      packageTests: [],
    },
    key: 0,
  },
];

const totalsObject = {
  totalAmount: 0.0,
  vatTotal: 0.0,
  subTotal: 0.0,
  additionalTestsTotal: 0.0,
};

const initialState = {
  patientDetails: [patientObject],
  addressDetails: addressObject,
  adultNumber: 1,
  childNumber: 0,
  timeSlotId: '',
  timeSlotDate: '',
  timeSlotTime: '',
  cart: {},
  testTypes: [testTypesObject],
  details: {},
  totalPayment: totalsObject,
};

const webAppointmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEST_TYPE:
      return { ...state, testTypes: action.payload };
    case UPDATE_CART:
      return { ...state, cart: action.payload };
    case UPDATE_ADULT_COUNT:
      const newPatDetails = [...state.patientDetails];
      if (state.adultNumber < action.payload) {
        newPatDetails.push(patientObject);
      } else {
        newPatDetails.splice(-1);
      }
      return {
        ...state,
        adultNumber: action.payload,
        patientDetails: newPatDetails,
      };
    case UPDATE_CHILD_COUNT:
      const newPatsDetails = [...state.patientDetails];
      if (state.childNumber < action.payload) {
        newPatsDetails.push(patientObject);
      } else {
        newPatsDetails.splice(-1);
      }
      return {
        ...state,
        childNumber: action.payload,
        patientDetails: newPatsDetails,
      };
    case SET_DETAILS:
      return { ...state, details: action.payload };
    case SET_SELECTED_DATE:
      return { ...state, timeSlotDate: action.payload };
    case SET_SELECTED_TIME:
      return { ...state, timeSlotTime: action.payload };
    case SET_TIME_SLOT_ID:
      return { ...state, timeSlotId: action.payload };
    case SET_PATIENT_DETAILS:
      return { ...state, patientDetails: action.payload };
    case UPDATE_PATIENT_DETAILS:
      let pDetails = [...state.patientDetails];
      pDetails[action.payload.index] = action.payload.data;
      return { ...state, patientDetails: pDetails };
    case UPDATE_ADDRESS_DETAILS:
      return { ...state, addressDetails: action.payload };
    case UPDATE_TOTAL_PAYMENT:
      return { ...state, totalPayment: action.payload };
    default:
      return state;
  }
};
export default webAppointmentsReducer;
