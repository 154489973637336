import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../index';

const SuccessToast = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text size="small" color="white" dark>
        {props.message}
      </Text>
    </div>
  );
};

SuccessToast.propTypes = {
  message: PropTypes.string,
};

SuccessToast.defaultProps = {
  message: '',
};

export default SuccessToast;
