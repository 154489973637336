import React from 'react';
import {
  ErrorToast,
  Input,
  Select,
  Text,
  Button,
  PhoneInput,
  Icon,
} from 'components';
import PageHeader from '../PageHeader/PageHeader';

import { useCustomForm } from 'hooks';
import { schema } from '../../emergencyContactForm/schema';

import './editEmergencyContact.scss';

const EditEmergencyContact = ({
  details,
  updateDetails,
  languages,
  onNextClick,
  isSaving,
}) => {
  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
  } = useCustomForm(schema, {
    relationship: details.relationship || '',
    fullName: details.fullName || '',
    title: details.title || '',
    emphoneNumber: details.emphoneNumber || '',
    emlanguage: details.emlanguage || '',
  });

  const onValChange = (field, value, callback) => {
    updateDetails({ ...details, [field]: value });
    callback(value);
  };

  return (
    <>
      <div className="emergencyContact__header">
        <PageHeader
          backPath="/settings/emer"
          titleId="patient_app.complete_emergency"
          title="Complete your emergency contact"
        />
      </div>
      <div className="emergencyContact">
        <div className="emergencyContact__form">
          <form onSubmit={handleSubmit(onNextClick)}>
            <div className="emergencyContact__form__grid-wrapper">
              <div className="emergencyContact__form__grid">
                <Controller
                  name="relationship"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={
                        <Text
                          id="patient_app.settings.emergency_contact.relationship"
                          color="inherit"
                          type="label"
                        >
                          Relationship
                        </Text>
                      }
                      border
                      search
                      size="fullWidth"
                      errorMessage=""
                      placeholder="Partner"
                      invalid={errors?.relationship}
                      value={value}
                      onChange={(e, { value }) =>
                        onValChange('relationship', value, onChange)
                      }
                      options={[
                        { text: 'Partner', value: 'Partner' },
                        { text: 'Spouse', value: 'Spouse' },
                        { text: 'Sibling', value: 'Sibling' },
                        { text: 'Friend', value: 'Friend' },
                        { text: 'Colleague', value: 'Colleague' },
                        { text: 'Other', value: 'Other' },
                      ]}
                    />
                  )}
                />
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={
                        <Text
                          id="patient_app.settings.emergency_contact.title"
                          color="inherit"
                          type="label"
                        >
                          Title
                        </Text>
                      }
                      border
                      errorMessage=""
                      invalid={errors?.title}
                      search
                      size="fullWidth"
                      placeholder="Mr."
                      onChange={(e, { value }) =>
                        onValChange('title', value, onChange)
                      }
                      value={value}
                      options={[
                        { text: 'Mr.', value: 'Mr.' },
                        { text: 'Ms.', value: 'Ms.' },
                        { text: 'Mrs.', value: 'Mrs.' },
                      ]}
                    />
                  )}
                />
                <Controller
                  name="fullName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      border
                      className="emergencyContact__form__input"
                      label={
                        <Text
                          id="patient_app.settings.emergency_contact.full_name"
                          color="inherit"
                          type="label"
                        >
                          Full Name
                        </Text>
                      }
                      errorMessage=""
                      placeholder="John Doe"
                      onChange={(e) =>
                        onValChange('fullName', e.target.value, onChange)
                      }
                      size="fullWidth"
                      value={value}
                      invalid={errors?.fullName}
                    />
                  )}
                />
                <Controller
                  name="emphoneNumber"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      className="patient-form__form__input"
                      border
                      size="fullWidth"
                      label={
                        <Text
                          id="patient_app.settings.emergency_contact.contact_no"
                          color="inherit"
                          type="label"
                        >
                          Contact No.
                        </Text>
                      }
                      onChange={(value) =>
                        onValChange('emphoneNumber', value || '', onChange)
                      }
                      placeholder="+123456789"
                      value={value}
                      errorMessage={errors?.emphoneNumber?.message}
                      invalid={errors?.emphoneNumber}
                    />
                  )}
                />
                <Controller
                  name="emlanguage"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={
                        <Text
                          id="patient_app.settings.emergency_contact.primary_language"
                          color="inherit"
                          type="label"
                        >
                          Primary Language
                        </Text>
                      }
                      border
                      search
                      size="fullWidth"
                      errorMessage=""
                      placeholder="English"
                      invalid={errors?.language}
                      value={value}
                      onChange={(e, { value }) =>
                        onValChange('emlanguage', value, onChange)
                      }
                      options={languages}
                    />
                  )}
                />
              </div>
            </div>
            <div style={{ padding: '0px 20px' }}>
              <Text
                size="tiny"
                color="#808080"
                className="mt-2"
                id="patient_app.settings.emergency_contact.message"
              >
                Your emergency contact will receive a message saying that he/she
                has been appointed as your point of contact when you require
                emergency medical attention.
              </Text>
            </div>
            <div className="completeProfile__button">
              <Button
                type="submit"
                // color="black"
                color="blue"
                className="rec-ph__button mt-4"
                size="fullWidth"
                isLoading={isSaving}
                disabled={isSaving}
              >
                <Text
                  color="inherit"
                  id="patient_app.emergency_confirm_contact"
                >
                  Confirm Contact
                </Text>
                <Icon name="arrowRight" className="ml-3" color="white" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default EditEmergencyContact;
