import React from 'react';

const SvgCardDropdown = props => (
  <svg
    width="26"
    height="12"
    viewBox="0 0 26 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0002 11.672C12.3431 11.6732 11.6923 11.5449 11.0849 11.2944C10.4775 11.0438 9.92538 10.6761 9.46019 10.212L1.29019 2.04204C1.10188 1.85374 0.996094 1.59834 0.996094 1.33204C0.996094 1.06574 1.10188 0.810345 1.29019 0.622041C1.47849 0.433738 1.73388 0.32795 2.00019 0.32795C2.26649 0.32795 2.52188 0.433738 2.71019 0.622041L10.8802 8.79204C11.4427 9.35384 12.2052 9.6694 13.0002 9.6694C13.7952 9.6694 14.5577 9.35384 15.1202 8.79204L23.2902 0.622041C23.4785 0.433738 23.7339 0.32795 24.0002 0.32795C24.2665 0.32795 24.5219 0.433738 24.7102 0.622041C24.8985 0.810345 25.0043 1.06574 25.0043 1.33204C25.0043 1.59834 24.8985 1.85374 24.7102 2.04204L16.5402 10.212C16.075 10.6761 15.5229 11.0438 14.9155 11.2944C14.3081 11.5449 13.6572 11.6732 13.0002 11.672Z"
      fill="black"
    />
  </svg>
);

export default SvgCardDropdown;
