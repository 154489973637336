import { apolloClient } from '../apollo/apollo';
import {
  trainingCenters,
  getCenterCount,
} from './graphql/queries/trainingCenters';
import {
  createTrainingCenter,
  updateTrainingCenter,
  deleteTrainingCenter,
} from './graphql/mutations/trainingCenters';

export default {
  async getTrainingCentersByClientId(clientId) {
    return apolloClient.query({
      query: trainingCenters,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
      },
    });
  },

  async createTrainingCenter(payload) {
    return apolloClient.mutate({
      mutation: createTrainingCenter,
      fetchPolicy: 'no-cache',
      variables: {
        ...payload,
      },
    });
  },

  async getCenterCount(clientId) {
    return apolloClient.mutate({
      mutation: getCenterCount,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
      },
    });
  },

  async deleteClientTag(id) {
    return apolloClient.mutate({
      mutation: deleteTrainingCenter,
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    });
  },

  async updateTrainingCenter(payload) {
    return apolloClient.mutate({
      mutation: updateTrainingCenter,
      fetchPolicy: 'no-cache',
      variables: {
        ...payload,
      },
    });
  },
};
