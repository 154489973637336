import React from 'react';
import moment from 'moment-timezone';

import { Icon } from '../../../../../components';

import './covidVac.scss';

const CovidVac = ({ certificate, certRef }) => {
  const extraData = JSON.parse(certificate.extraData);

  return (
    <div ref={certRef}>
      <div className="covid-vac">
        {certificate.logoImage && (
          <div className="sing-pdt__logo-wrapper">
            <img
              className="sing-pdt__logo-wrapper__logo"
              src={certificate.logoImage}
              alt="logo"
            />
          </div>
        )}
        <div className="covid-vac__header-row">
          <div>
            <div className="covid-vac__header">COVID-19 Vaccination Record</div>
            <div className="covid-vac__sub-header">Vaccinated</div>
          </div>
        </div>
        <div className="covid-vac__user-wrapper">
          <div className="covid-vac__user-wrapper__data-wrapper">
            <div className="covid-vac__row">
              <div className="covid-vac__data-wrapper">
                <div className="covid-vac__data-wrapper__label">Name</div>
                <div className="covid-vac__data-wrapper__data">
                  {certificate.holderName}
                </div>
              </div>
            </div>
            <div className="covid-vac__row">
              <div className="covid-vac__data-wrapper">
                <div className="covid-vac__data-wrapper__label">
                  Passport no.
                </div>
                <div className="covid-vac__data-wrapper__data">
                  {certificate.passNo}
                </div>
              </div>
              <div className="covid-vac__data-wrapper">
                <div className="covid-vac__data-wrapper__label">
                  Nationality
                </div>
                <div className="covid-vac__data-wrapper__data">
                  {certificate.nationality}
                </div>
              </div>
              <div className="covid-vac__data-wrapper">
                <div className="covid-vac__data-wrapper__label">
                  Date of birth
                </div>
                <div className="covid-vac__data-wrapper__data">
                  {moment(certificate.dateOfBirth).format('DD-MM-YYYY')}
                </div>
              </div>
            </div>
          </div>
          <div className="covid-vac__qr-code">
            <img
              className="covid-vac__qr-code__img"
              src={certificate.qrCodeImage}
              alt="qrcode"
            />
          </div>
        </div>
        <div className="covid-vac__vac-content">
          <div className="covid-vac__vac-content__section">
            {certificate.status === 'NONREQ' && (
              <div className="covid-vac__vac-content__additional-info">
                As you have recovered from a prior COVID-19 infection, a second
                appointment / dose is not required.
              </div>
            )}
            {/* <div className="covid-vac__s-data-wrapper">
              <div className="covid-vac__s-data-wrapper__label">
                Inoculated since
              </div>
              <div className="covid-vac__s-data-wrapper__data">
                {extraData[1]
                  ? moment(extraData[1].collectionDate).format('DD-MM-YYYY')
                  : moment(certificate.collectionDate).format('DD-MM-YYYY')}
              </div>
            </div> */}
            <div className="covid-vac__vac-content__sub-section mr-3">
              <div style={{ padding: '14px 0', paddingRight: '5px' }}>
                <div className="covid-vac__s-data-wrapper">
                  <div className="covid-vac__s-data-wrapper__label">
                    Vaccine Brand
                  </div>
                  <div className="covid-vac__s-data-wrapper__data">
                    {extraData[1].vaccineName}
                  </div>
                </div>
                <div className="covid-vac__s-data-wrapper">
                  <div className="covid-vac__s-data-wrapper__label">Batch</div>
                  <div className="covid-vac__s-data-wrapper__data">
                    {extraData[1].lotNumber}
                  </div>
                </div>
                <div className="covid-vac__s-data-wrapper">
                  <div className="covid-vac__s-data-wrapper__label">
                    Date of Vacciantion
                  </div>
                  <div className="covid-vac__s-data-wrapper__data">
                    {moment(extraData[1].collectionDate).format('DD-MM-YYYY')}
                  </div>
                </div>
                <div className="covid-vac__s-data-wrapper">
                  <div className="covid-vac__s-data-wrapper__label">
                    Place of Vaccination
                  </div>
                  <div className="covid-vac__s-data-wrapper__data">
                    {extraData[1].location.locationName}
                  </div>
                </div>
                <div className="covid-vac__s-data-wrapper">
                  <div className="covid-vac__s-data-wrapper__label">
                    Vaccine SDD ID
                  </div>
                  <div className="covid-vac__s-data-wrapper__data">
                    {extraData[1].vaccineCode}
                  </div>
                </div>
              </div>
              {certificate.status === 'BOOSTER' ? (
                <div className="covid-vac__dose">
                  <div className="covid-vac__dose__content">
                    <div className="covid-vac__dose__title--booster">
                      Booster
                    </div>
                  </div>
                </div>
              ) : (
                <div className="covid-vac__dose">
                  <div className="covid-vac__dose__content">
                    <div className="covid-vac__dose__title">Dose</div>
                    <div className="covid-vac__dose__number">1</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {extraData[2] && (
            <div
              className="covid-vac__vac-content__section"
              style={{ marginTop: '61px' }}
            >
              <div className="covid-vac__vac-content__sub-section">
                <div style={{ padding: '15px 0' }}>
                  <div className="covid-vac__s-data-wrapper">
                    <div className="covid-vac__s-data-wrapper__label">
                      Vaccine manufacturer
                    </div>
                    <div className="covid-vac__s-data-wrapper__data">
                      {extraData[2].vaccineName}
                    </div>
                  </div>
                  <div className="covid-vac__s-data-wrapper">
                    <div className="covid-vac__s-data-wrapper__label">Date</div>
                    <div className="covid-vac__s-data-wrapper__data">
                      {moment(extraData[2].collectionDate).format('DD-MM-YYYY')}
                    </div>
                  </div>
                  <div className="covid-vac__s-data-wrapper">
                    <div className="covid-vac__s-data-wrapper__label">
                      Vaccine SDD ID
                    </div>
                    <div className="covid-vac__s-data-wrapper__data">
                      {extraData[2].vaccineCode}
                    </div>
                  </div>
                  <div className="covid-vac__s-data-wrapper">
                    <div className="covid-vac__s-data-wrapper__label">
                      Batch Number
                    </div>
                    <div className="covid-vac__s-data-wrapper__data">
                      {extraData[2].lotNumber}
                    </div>
                  </div>
                </div>
                <div className="covid-vac__dose">
                  <div className="covid-vac__dose__content">
                    <div className="covid-vac__dose__title">Dose</div>
                    <div className="covid-vac__dose__number">2</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="covid-vac__info-text">
          <div className="covid-vac__info-text__header">
            What is a COVID-19 Vaccination HealthCert?
          </div>
          <div className="covid-vac__info-text__info">
            <div className="covid-vac__info-text__icon">
              <Icon name="infoTick" />
            </div>
            Holds important vaccination or lab report data
          </div>
          <div className="covid-vac__info-text__info">
            <div className="covid-vac__info-text__icon">
              <Icon name="infoTick" />
            </div>
            Can be scanned to verify that the information has not been tampered
            with
          </div>
          <div className="covid-vac__info-text__info">
            <div className="covid-vac__info-text__icon">
              <Icon name="infoExclamation" />
            </div>
            <div>
              <b>Don't forget</b> - scanning this QR Code will share all of the
              information on your Vaccine Card, so make sure to only share with
              those you trust.
            </div>
          </div>
          <div className="covid-vac__info-text__footer-text">
            This vaccination record is powered by the OpenAttestation framework
            to endorse and verify documents independently. It is developed by
            GovTech Singapore.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CovidVac;
