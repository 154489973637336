import React from 'react';
import { Text } from 'components';
import { Icon } from 'semantic-ui-react';

import history from '../../../../history';

import './pageHeader.scss';

const PageHeader = ({
  backPath,
  isLoading = false,
  subTitle = '',
  title = '',
  subTitleId = '',
  titleId = '',
  className = '',
}) => {
  return (
    <div className={`med-recs-page-header ${className}`}>
      {backPath && (
        <Icon
          className="mr-2"
          size="large"
          name="arrow left"
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(backPath)}
        />
      )}
      {!isLoading && (
        <>
          <Text id={titleId} dark className="med-recs-page-header__header">
            {title}
          </Text>
          {subTitle && (
            <Text id={subTitleId} dark size="big" className="ml-2">
              {subTitle}
            </Text>
          )}
        </>
      )}
    </div>
  );
};

export default PageHeader;
