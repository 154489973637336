import React from 'react';
import PlaceholderPage from 'features/patientApp/PlaceholderCard/PlaceholderCard';
import PageHeader from '../../../components/PageHeader/PageHeader';
import startCompleteProfileSvg from 'assets/rhc/completeProfile/start-profile-change.svg';

const CompleteProfileTriggered = ({ backPath, name, onButtonClick }) => {
  return (
    <>
      <PageHeader title={'Your Profile'} backPath={backPath} />
      <PlaceholderPage
        icon={startCompleteProfileSvg}
        header={`Hi ${name}! We're here to help you reach your health goals. Please help us by completing your profile so we can give you the best possible support.`}
        text="We'd like to learn more about your race and the region and place you are living in."
        buttonText="Start Now"
        onButtonClick={onButtonClick}
      />
    </>
  );
};
export default CompleteProfileTriggered;
