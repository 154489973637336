import React from 'react';

const SvgList = props => (
  <svg width="1em" height="1em" viewBox="0 0 23 25" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect
        stroke="#1b1b1b"
        strokeWidth={2}
        x={3}
        y={1}
        width={18}
        height={23}
        rx={2}
      />
      <g transform="translate(6 5)">
        <path
          d="M4.292 1h6.416"
          stroke="#1b1b1b"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <circle fill="#1b1b1b" cx={1} cy={1} r={1} />
      </g>
      <g transform="translate(6 13)">
        <path
          d="M4.3 1h5.4"
          stroke="#1b1b1b"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <circle fill="#1b1b1b" cx={1} cy={1} r={1} />
      </g>
      <g transform="translate(6 9)">
        <path
          d="M4.375 1H11"
          stroke="#1b1b1b"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <circle fill="#1b1b1b" cx={1} cy={1} r={1} />
      </g>
      <g transform="translate(6 17)">
        <path
          d="M4.375 1H11"
          stroke="#1b1b1b"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <circle fill="#1b1b1b" cx={1} cy={1} r={1} />
      </g>
    </g>
  </svg>
);

export default SvgList;
