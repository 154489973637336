export default [
  {
    code: 'aa',
    text: 'Afar',
    value: 'Afar',
    native: 'Afar',
  },
  {
    code: 'ab',
    text: 'Abkhazian',
    value: 'Abkhazian',
    native: 'Аҧсуа',
  },
  {
    code: 'af',
    text: 'Afrikaans',
    value: 'Afrikaans',
    native: 'Afrikaans',
  },
  {
    code: 'ak',
    text: 'Akan',
    value: 'Akan',
    native: 'Akana',
  },
  {
    code: 'am',
    text: 'Amharic',
    value: 'Amharic',
    native: 'አማርኛ',
  },
  {
    code: 'an',
    text: 'Aragonese',
    value: 'Aragonese',
    native: 'Aragonés',
  },
  {
    code: 'ar',
    text: 'Arabic',
    value: 'Arabic',
    native: 'العربية',
    rtl: 1,
  },
  {
    code: 'as',
    text: 'Assamese',
    value: 'Assamese',
    native: 'অসমীয়া',
  },
  {
    code: 'av',
    text: 'Avar',
    value: 'Avar',
    native: 'Авар',
  },
  {
    code: 'ay',
    text: 'Aymara',
    value: 'Aymara',
    native: 'Aymar',
  },
  {
    code: 'az',
    text: 'Azerbaijani',
    value: 'Azerbaijani',
    native: 'Azərbaycanca / آذربايجان',
  },
  {
    code: 'ba',
    text: 'Bashkir',
    value: 'Bashkir',
    native: 'Башҡорт',
  },
  {
    code: 'be',
    text: 'Belarusian',
    value: 'Belarusian',
    native: 'Беларуская',
  },
  {
    code: 'bg',
    text: 'Bulgarian',
    value: 'Bulgarian',
    native: 'Български',
  },
  {
    code: 'bh',
    text: 'Bihari',
    value: 'Bihari',
    native: 'भोजपुरी',
  },
  {
    code: 'bi',
    text: 'Bislama',
    value: 'Bislama',
    native: 'Bislama',
  },
  {
    code: 'bm',
    text: 'Bambara',
    value: 'Bambara',
    native: 'Bamanankan',
  },
  {
    code: 'bn',
    text: 'Bengali',
    value: 'Bengali',
    native: 'বাংলা',
  },
  {
    code: 'bo',
    text: 'Tibetan',
    value: 'Tibetan',
    native: 'བོད་ཡིག / Bod skad',
  },
  {
    code: 'br',
    text: 'Breton',
    value: 'Breton',
    native: 'Brezhoneg',
  },
  {
    code: 'bs',
    text: 'Bosnian',
    value: 'Bosnian',
    native: 'Bosanski',
  },
  {
    code: 'ca',
    text: 'Catalan',
    value: 'Catalan',
    native: 'Català',
  },
  {
    code: 'ce',
    text: 'Chechen',
    value: 'Chechen',
    native: 'Нохчийн',
  },
  {
    code: 'ch',
    text: 'Chamorro',
    value: 'Chamorro',
    native: 'Chamoru',
  },
  {
    code: 'co',
    text: 'Corsican',
    value: 'Corsican',
    native: 'Corsu',
  },
  {
    code: 'cr',
    text: 'Cree',
    value: 'Cree',
    native: 'Nehiyaw',
  },
  {
    code: 'cs',
    text: 'Czech',
    value: 'Czech',
    native: 'Česky',
  },
  {
    code: 'cu',
    text: 'Old Church Slavonic / Old Bulgarian',
    value: 'Old Church Slavonic / Old Bulgarian',
    native: 'словѣньскъ / slověnĭskŭ',
  },
  {
    code: 'cv',
    text: 'Chuvash',
    value: 'Chuvash',
    native: 'Чăваш',
  },
  {
    code: 'cy',
    text: 'Welsh',
    value: 'Welsh',
    native: 'Cymraeg',
  },
  {
    code: 'da',
    text: 'Danish',
    value: 'Danish',
    native: 'Dansk',
  },
  {
    code: 'de',
    text: 'German',
    value: 'German',
    native: 'Deutsch',
  },
  {
    code: 'dv',
    text: 'Divehi',
    value: 'Divehi',
    native: 'ދިވެހިބަސް',
    rtl: 1,
  },
  {
    code: 'dz',
    text: 'Dzongkha',
    value: 'Dzongkha',
    native: 'ཇོང་ཁ',
  },
  {
    code: 'ee',
    text: 'Ewe',
    value: 'Ewe',
    native: 'Ɛʋɛ',
  },
  {
    code: 'el',
    text: 'Greek',
    value: 'Greek',
    native: 'Ελληνικά',
  },
  {
    code: 'en',
    text: 'English',
    value: 'English',
    native: 'English',
  },
  {
    code: 'eo',
    text: 'Esperanto',
    value: 'Esperanto',
    native: 'Esperanto',
  },
  {
    code: 'es',
    text: 'Spanish',
    value: 'Spanish',
    native: 'Español',
  },
  {
    code: 'et',
    text: 'Estonian',
    value: 'Estonian',
    native: 'Eesti',
  },
  {
    code: 'eu',
    text: 'Basque',
    value: 'Basque',
    native: 'Euskara',
  },
  {
    code: 'fa',
    text: 'Persian',
    value: 'Persian',
    native: 'فارسی',
    rtl: 1,
  },
  {
    code: 'ff',
    text: 'Peul',
    value: 'Peul',
    native: 'Fulfulde',
  },
  {
    code: 'fi',
    text: 'Finnish',
    value: 'Finnish',
    native: 'Suomi',
  },
  {
    code: 'fj',
    text: 'Fijian',
    value: 'Fijian',
    native: 'Na Vosa Vakaviti',
  },
  {
    code: 'fo',
    text: 'Faroese',
    value: 'Faroese',
    native: 'Føroyskt',
  },
  {
    code: 'fr',
    text: 'French',
    value: 'French',
    native: 'Français',
  },
  {
    code: 'fy',
    text: 'West Frisian',
    value: 'West Frisian',
    native: 'Frysk',
  },
  {
    code: 'ga',
    text: 'Irish',
    value: 'Irish',
    native: 'Gaeilge',
  },
  {
    code: 'gd',
    text: 'Scottish Gaelic',
    value: 'Scottish Gaelic',
    native: 'Gàidhlig',
  },
  {
    code: 'gl',
    text: 'Galician',
    value: 'Galician',
    native: 'Galego',
  },
  {
    code: 'gn',
    text: 'Guarani',
    value: 'Guarani',
    native: "Avañe'ẽ",
  },
  {
    code: 'gu',
    text: 'Gujarati',
    value: 'Gujarati',
    native: 'ગુજરાતી',
  },
  {
    code: 'gv',
    text: 'Manx',
    value: 'Manx',
    native: 'Gaelg',
  },
  {
    code: 'ha',
    text: 'Hausa',
    value: 'Hausa',
    native: 'هَوُسَ',
    rtl: 1,
  },
  {
    code: 'he',
    text: 'Hebrew',
    value: 'Hebrew',
    native: 'עברית',
    rtl: 1,
  },
  {
    code: 'hi',
    text: 'Hindi',
    value: 'Hindi',
    native: 'हिन्दी',
  },
  {
    code: 'ho',
    text: 'Hiri Motu',
    value: 'Hiri Motu',
    native: 'Hiri Motu',
  },
  {
    code: 'hr',
    text: 'Croatian',
    value: 'Croatian',
    native: 'Hrvatski',
  },
  {
    code: 'ht',
    text: 'Haitian',
    value: 'Haitian',
    native: 'Krèyol ayisyen',
  },
  {
    code: 'hu',
    text: 'Hungarian',
    value: 'Hungarian',
    native: 'Magyar',
  },
  {
    code: 'hy',
    text: 'Armenian',
    value: 'Armenian',
    native: 'Հայերեն',
  },
  {
    code: 'hz',
    text: 'Herero',
    value: 'Herero',
    native: 'Otsiherero',
  },
  {
    code: 'ia',
    text: 'Interlingua',
    value: 'Interlingua',
    native: 'Interlingua',
  },
  {
    code: 'id',
    text: 'Indonesian',
    value: 'Indonesian',
    native: 'Bahasa Indonesia',
  },
  {
    code: 'ie',
    text: 'Interlingue',
    value: 'Interlingue',
    native: 'Interlingue',
  },
  {
    code: 'ig',
    text: 'Igbo',
    value: 'Igbo',
    native: 'Igbo',
  },
  {
    code: 'ii',
    text: 'Sichuan Yi',
    value: 'Sichuan Yi',
    native: 'ꆇꉙ / 四川彝语',
  },
  {
    code: 'ik',
    text: 'Inupiak',
    value: 'Inupiak',
    native: 'Iñupiak',
  },
  {
    code: 'io',
    text: 'Ido',
    value: 'Ido',
    native: 'Ido',
  },
  {
    code: 'is',
    text: 'Icelandic',
    value: 'Icelandic',
    native: 'Íslenska',
  },
  {
    code: 'it',
    text: 'Italian',
    value: 'Italian',
    native: 'Italiano',
  },
  {
    code: 'iu',
    text: 'Inuktitut',
    value: 'Inuktitut',
    native: 'ᐃᓄᒃᑎᑐᑦ',
  },
  {
    code: 'ja',
    text: 'Japanese',
    value: 'Japanese',
    native: '日本語',
  },
  {
    code: 'jv',
    text: 'Javanese',
    value: 'Javanese',
    native: 'Basa Jawa',
  },
  {
    code: 'ka',
    text: 'Georgian',
    value: 'Georgian',
    native: 'ქართული',
  },
  {
    code: 'kg',
    text: 'Kongo',
    value: 'Kongo',
    native: 'KiKongo',
  },
  {
    code: 'ki',
    text: 'Kikuyu',
    value: 'Kikuyu',
    native: 'Gĩkũyũ',
  },
  {
    code: 'kj',
    text: 'Kuanyama',
    value: 'Kuanyama',
    native: 'Kuanyama',
  },
  {
    code: 'kk',
    text: 'Kazakh',
    value: 'Kazakh',
    native: 'Қазақша',
  },
  {
    code: 'kl',
    text: 'Greenlandic',
    value: 'Greenlandic',
    native: 'Kalaallisut',
  },
  {
    code: 'km',
    text: 'Cambodian',
    value: 'Cambodian',
    native: 'ភាសាខ្មែរ',
  },
  {
    code: 'kn',
    text: 'Kannada',
    value: 'Kannada',
    native: 'ಕನ್ನಡ',
  },
  {
    code: 'ko',
    text: 'Korean',
    value: 'Korean',
    native: '한국어',
  },
  {
    code: 'kr',
    text: 'Kanuri',
    value: 'Kanuri',
    native: 'Kanuri',
  },
  {
    code: 'ks',
    text: 'Kashmiri',
    value: 'Kashmiri',
    native: 'कश्मीरी / كشميري',
    rtl: 1,
  },
  {
    code: 'ku',
    text: 'Kurdish',
    value: 'Kurdish',
    native: 'Kurdî / كوردی',
    rtl: 1,
  },
  {
    code: 'kv',
    text: 'Komi',
    value: 'Komi',
    native: 'Коми',
  },
  {
    code: 'kw',
    text: 'Cornish',
    value: 'Cornish',
    native: 'Kernewek',
  },
  {
    code: 'ky',
    text: 'Kirghiz',
    value: 'Kirghiz',
    native: 'Kırgızca / Кыргызча',
  },
  {
    code: 'la',
    text: 'Latin',
    value: 'Latin',
    native: 'Latina',
  },
  {
    code: 'lb',
    text: 'Luxembourgish',
    value: 'Luxembourgish',
    native: 'Lëtzebuergesch',
  },
  {
    code: 'lg',
    text: 'Ganda',
    value: 'Ganda',
    native: 'Luganda',
  },
  {
    code: 'li',
    text: 'Limburgian',
    value: 'Limburgian',
    native: 'Limburgs',
  },
  {
    code: 'ln',
    text: 'Lingala',
    value: 'Lingala',
    native: 'Lingála',
  },
  {
    code: 'lo',
    text: 'Laotian',
    value: 'Laotian',
    native: 'ລາວ / Pha xa lao',
  },
  {
    code: 'lt',
    text: 'Lithuanian',
    value: 'Lithuanian',
    native: 'Lietuvių',
  },
  {
    code: 'lu',
    text: 'Luba-Katanga',
    value: 'Luba-Katanga',
    native: 'Tshiluba',
  },
  {
    code: 'lv',
    text: 'Latvian',
    value: 'Latvian',
    native: 'Latviešu',
  },
  {
    code: 'mg',
    text: 'Malagasy',
    value: 'Malagasy',
    native: 'Malagasy',
  },
  {
    code: 'mh',
    text: 'Marshallese',
    value: 'Marshallese',
    native: 'Kajin Majel / Ebon',
  },
  {
    code: 'mi',
    text: 'Maori',
    value: 'Maori',
    native: 'Māori',
  },
  {
    code: 'mk',
    text: 'Macedonian',
    value: 'Macedonian',
    native: 'Македонски',
  },
  {
    code: 'ml',
    text: 'Malayalam',
    value: 'Malayalam',
    native: 'മലയാളം',
  },
  {
    code: 'mn',
    text: 'Mongolian',
    value: 'Mongolian',
    native: 'Монгол',
  },
  {
    code: 'mo',
    text: 'Moldovan',
    value: 'Moldovan',
    native: 'Moldovenească',
  },
  {
    code: 'mr',
    text: 'Marathi',
    value: 'Marathi',
    native: 'मराठी',
  },
  {
    code: 'ms',
    text: 'Malay',
    value: 'Malay',
    native: 'Bahasa Melayu',
  },
  {
    code: 'mt',
    text: 'Maltese',
    value: 'Maltese',
    native: 'bil-Malti',
  },
  {
    code: 'my',
    text: 'Burmese',
    value: 'Burmese',
    native: 'မြန်မာစာ',
  },
  {
    code: 'na',
    text: 'Nauruan',
    value: 'Nauruan',
    native: 'Dorerin Naoero',
  },
  {
    code: 'nb',
    text: 'Norwegian Bokmål',
    value: 'Norwegian Bokmål',
    native: 'Norsk bokmål',
  },
  {
    code: 'nd',
    text: 'North Ndebele',
    value: 'North Ndebele',
    native: 'Sindebele',
  },
  {
    code: 'ne',
    text: 'Nepali',
    value: 'Nepali',
    native: 'नेपाली',
  },
  {
    code: 'ng',
    text: 'Ndonga',
    value: 'Ndonga',
    native: 'Oshiwambo',
  },
  {
    code: 'nl',
    text: 'Dutch',
    value: 'Dutch',
    native: 'Nederlands',
  },
  {
    code: 'nn',
    text: 'Norwegian Nynorsk',
    value: 'Norwegian Nynorsk',
    native: 'Norsk nynorsk',
  },
  {
    code: 'no',
    text: 'Norwegian',
    value: 'Norwegian',
    native: 'Norsk',
  },
  {
    code: 'nr',
    text: 'South Ndebele',
    value: 'South Ndebele',
    native: 'isiNdebele',
  },
  {
    code: 'nv',
    text: 'Navajo',
    value: 'Navajo',
    native: 'Diné bizaad',
  },
  {
    code: 'ny',
    text: 'Chichewa',
    value: 'Chichewa',
    native: 'Chi-Chewa',
  },
  {
    code: 'oc',
    text: 'Occitan',
    value: 'Occitan',
    native: 'Occitan',
  },
  {
    code: 'oj',
    text: 'Ojibwa',
    value: 'Ojibwa',
    native: 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin',
  },
  {
    code: 'om',
    text: 'Oromo',
    value: 'Oromo',
    native: 'Oromoo',
  },
  {
    code: 'or',
    text: 'Oriya',
    value: 'Oriya',
    native: 'ଓଡ଼ିଆ',
  },
  {
    code: 'os',
    text: 'Ossetian / Ossetic',
    value: 'Ossetian / Ossetic',
    native: 'Иронау',
  },
  {
    code: 'pa',
    text: 'Panjabi / Punjabi',
    value: 'Panjabi / Punjabi',
    native: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي',
  },
  {
    code: 'pi',
    text: 'Pali',
    value: 'Pali',
    native: 'Pāli / पाऴि',
  },
  {
    code: 'pl',
    text: 'Polish',
    value: 'Polish',
    native: 'Polski',
  },
  {
    code: 'ps',
    text: 'Pashto',
    value: 'Pashto',
    native: 'پښتو',
    rtl: 1,
  },
  {
    code: 'pt',
    text: 'Portuguese',
    value: 'Portuguese',
    native: 'Português',
  },
  {
    code: 'qu',
    text: 'Quechua',
    value: 'Quechua',
    native: 'Runa Simi',
  },
  {
    code: 'rm',
    text: 'Raeto Romance',
    value: 'Raeto Romance',
    native: 'Rumantsch',
  },
  {
    code: 'rn',
    text: 'Kirundi',
    value: 'Kirundi',
    native: 'Kirundi',
  },
  {
    code: 'ro',
    text: 'Romanian',
    value: 'Romanian',
    native: 'Română',
  },
  {
    code: 'ru',
    text: 'Russian',
    value: 'Russian',
    native: 'Русский',
  },
  {
    code: 'rw',
    text: 'Rwandi',
    value: 'Rwandi',
    native: 'Kinyarwandi',
  },
  {
    code: 'sa',
    text: 'Sanskrit',
    value: 'Sanskrit',
    native: 'संस्कृतम्',
  },
  {
    code: 'sc',
    text: 'Sardinian',
    value: 'Sardinian',
    native: 'Sardu',
  },
  {
    code: 'sd',
    text: 'Sindhi',
    value: 'Sindhi',
    native: 'सिनधि',
  },
  {
    code: 'se',
    text: 'Northern Sami',
    value: 'Northern Sami',
    native: 'Sámegiella',
  },
  {
    code: 'sg',
    text: 'Sango',
    value: 'Sango',
    native: 'Sängö',
  },
  {
    code: 'sh',
    text: 'Serbo-Croatian',
    value: 'Serbo-Croatian',
    native: 'Srpskohrvatski / Српскохрватски',
  },
  {
    code: 'si',
    text: 'Sinhalese',
    value: 'Sinhalese',
    native: 'සිංහල',
  },
  {
    code: 'sk',
    text: 'Slovak',
    value: 'Slovak',
    native: 'Slovenčina',
  },
  {
    code: 'sl',
    text: 'Slovenian',
    value: 'Slovenian',
    native: 'Slovenščina',
  },
  {
    code: 'sm',
    text: 'Samoan',
    value: 'Samoan',
    native: 'Gagana Samoa',
  },
  {
    code: 'sn',
    text: 'Shona',
    value: 'Shona',
    native: 'chiShona',
  },
  {
    code: 'so',
    text: 'Somalia',
    value: 'Somalia',
    native: 'Soomaaliga',
  },
  {
    code: 'sq',
    text: 'Albanian',
    value: 'Albanian',
    native: 'Shqip',
  },
  {
    code: 'sr',
    text: 'Serbian',
    value: 'Serbian',
    native: 'Српски',
  },
  {
    code: 'ss',
    text: 'Swati',
    value: 'Swati',
    native: 'SiSwati',
  },
  {
    code: 'st',
    text: 'Southern Sotho',
    value: 'Southern Sotho',
    native: 'Sesotho',
  },
  {
    code: 'su',
    text: 'Sundanese',
    value: 'Sundanese',
    native: 'Basa Sunda',
  },
  {
    code: 'sv',
    text: 'Swedish',
    value: 'Swedish',
    native: 'Svenska',
  },
  {
    code: 'sw',
    text: 'Swahili',
    value: 'Swahili',
    native: 'Kiswahili',
  },
  {
    code: 'ta',
    text: 'Tamil',
    value: 'Tamil',
    native: 'தமிழ்',
  },
  {
    code: 'te',
    text: 'Telugu',
    value: 'Telugu',
    native: 'తెలుగు',
  },
  {
    code: 'tg',
    text: 'Tajik',
    value: 'Tajik',
    native: 'Тоҷикӣ',
  },
  {
    code: 'th',
    text: 'Thai',
    value: 'Thai',
    native: 'ไทย / Phasa Thai',
  },
  {
    code: 'ti',
    text: 'Tigrinya',
    value: 'Tigrinya',
    native: 'ትግርኛ',
  },
  {
    code: 'tk',
    text: 'Turkmen',
    value: 'Turkmen',
    native: 'Туркмен / تركمن',
  },
  {
    code: 'tl',
    text: 'Tagalog / Filipino',
    value: 'Tagalog / Filipino',
    native: 'Tagalog',
  },
  {
    code: 'tn',
    text: 'Tswana',
    value: 'Tswana',
    native: 'Setswana',
  },
  {
    code: 'to',
    text: 'Tonga',
    value: 'Tonga',
    native: 'Lea Faka-Tonga',
  },
  {
    code: 'tr',
    text: 'Turkish',
    value: 'Turkish',
    native: 'Türkçe',
  },
  {
    code: 'ts',
    text: 'Tsonga',
    value: 'Tsonga',
    native: 'Xitsonga',
  },
  {
    code: 'tt',
    text: 'Tatar',
    value: 'Tatar',
    native: 'Tatarça',
  },
  {
    code: 'tw',
    text: 'Twi',
    value: 'Twi',
    native: 'Twi',
  },
  {
    code: 'ty',
    text: 'Tahitian',
    value: 'Tahitian',
    native: 'Reo Mā`ohi',
  },
  {
    code: 'ug',
    text: 'Uyghur',
    value: 'Uyghur',
    native: 'Uyƣurqə / ئۇيغۇرچە',
  },
  {
    code: 'uk',
    text: 'Ukrainian',
    value: 'Ukrainian',
    native: 'Українська',
  },
  {
    code: 'ur',
    text: 'Urdu',
    value: 'Urdu',
    native: 'اردو',
    rtl: 1,
  },
  {
    code: 'uz',
    text: 'Uzbek',
    value: 'Uzbek',
    native: 'Ўзбек',
  },
  {
    code: 've',
    text: 'Venda',
    value: 'Venda',
    native: 'Tshivenḓa',
  },
  {
    code: 'vi',
    text: 'Viettextse',
    value: 'Viettextse',
    native: 'Tiếng Việt',
  },
  {
    code: 'vo',
    text: 'Volapük',
    value: 'Volapük',
    native: 'Volapük',
  },
  {
    code: 'wa',
    text: 'Walloon',
    value: 'Walloon',
    native: 'Walon',
  },
  {
    code: 'wo',
    text: 'Wolof',
    value: 'Wolof',
    native: 'Wollof',
  },
  {
    code: 'xh',
    text: 'Xhosa',
    value: 'Xhosa',
    native: 'isiXhosa',
  },
  {
    code: 'yi',
    text: 'Yiddish',
    value: 'Yiddish',
    native: 'ייִדיש',
    rtl: 1,
  },
  {
    code: 'yo',
    text: 'Yoruba',
    value: 'Yoruba',
    native: 'Yorùbá',
  },
  {
    code: 'za',
    text: 'Zhuang',
    value: 'Zhuang',
    native: 'Cuengh / Tôô / 壮语',
  },
  {
    code: 'zh',
    text: 'Chinese',
    value: 'Chinese',
    native: '中文',
  },
  {
    code: 'zu',
    text: 'Zulu',
    value: 'Zulu',
    native: 'isiZulu',
  },
];
