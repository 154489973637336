import React from 'react';

const SvgTick = props => (
  <svg width="1em" height="1em" viewBox="0 0 23 20" {...props}>
    <path
      d="M4 10.25l4.046 6.45a1 1 0 001.675.028L19 3"
      stroke={props.color}
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
);

SvgTick.defaultProps = {
  color: '#1b1b1b',
};

export default SvgTick;
