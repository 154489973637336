import React, { useRef } from 'react';
import { GoogleMap, useLoadScript, Data, Marker } from '@react-google-maps/api';
import { Loader } from 'semantic-ui-react';

import './map.scss';

const Map = (props) => {
  const markerRef = useRef();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCHwigbhM2m02cGdDaIQI1EdV2cHiY1Prw',
  });

  const options = {
    clickable: false,
    draggable: props.draggable,
    editable: false,
    fillColor: '#F05',
    fillOpacity: 1,
    strokeColor: '#00FF55',
    strokeOpacity: 1,
    strokeWeight: 2,
    title: 'Title',
    visible: true,
    zIndex: 2,
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };

  const renderPlaceholder = () => {
    if (loadError) {
      return <div>Map cannot be loaded right now, sorry.</div>;
    }

    return <Loader />;
  };

  const onMarkerDragEnd = () => {
    if (!markerRef.current) return;
    const latitude = markerRef.current.state.marker.position.lat();
    const longitude = markerRef.current.state.marker.position.lng();
    props.onMarkerMove({ lat: latitude, lng: longitude });
  };

  return (
    <div className={'map ' + props.wrapperClassName}>
      {isLoaded && !loadError ? (
        <GoogleMap
          id={props.className}
          zoom={17}
          center={props.location}
          streetView={undefined}
          options={options}
        >
          <Data options={options} />
          <Marker
            position={props.location}
            draggable={props.markerDraggable}
            ref={markerRef}
            onDragEnd={onMarkerDragEnd}
          />
        </GoogleMap>
      ) : (
        renderPlaceholder()
      )}
    </div>
  );
};

Map.defaultProps = {
  location: {
    lat: 38.805470223177466,
    lng: -118.76220703125,
  },
  draggable: true,
  markerDraggable: true,
};

export default Map;
