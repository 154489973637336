import React from 'react';

const SvgLock = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 32" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#1b1b1b">
      <rect strokeWidth={3} x={3.5} y={11.5} width={22} height={19} rx={2} />
      <path d="M21 11c0-4.97-1.444-9-6.5-9S8 6.03 8 11" strokeWidth={3} />
      <circle strokeWidth={2} fill="#1b1b1b" cx={14.5} cy={19.5} r={1.5} />
    </g>
  </svg>
);

export default SvgLock;
