export const SET_STAFF = 'STAFF_MANAGEMENT/SET_STAFF';
export const REMOVE_STAFF = 'STAFF_MANAGEMENT/REMOVE_STAFF';

// ****************
// STATE SETTERS
// ****************

/**
 * @function setStaff
 * @description Set selected header section for certificate management
 */
export const setStaff = staff => ({
  type: SET_STAFF,
  payload: staff,
});

/**
 * @function removeStaff
 * @description Set selected header section for certificate management
 */
export const removeStaff = STAFFId => ({
  type: REMOVE_STAFF,
  payload: STAFFId,
});
