import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useCustomForm } from 'hooks';
import * as yup from 'yup';
import { withRouter } from 'react-router';

import {
  ErrorToast,
  TextArea,
  Text,
  Button,
} from 'components';

import { FamilyHistoryInput } from 'features';

import familyHistoryApi from '../../../api/patientOverview/clientApi/familyHistoryApi';

import '../patMedCreators.scss';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  remark: yup.string(),
});

const AddFamilyHistoryPage = ({ edit, location, onCreate = () => {}, patient }) => {
  const [isSaving, setIsSaving] = useState(false);

  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
  } = useCustomForm(schema, {
    name: edit ? location?.state?.data?.name : '',
    remark: edit ? location?.state?.data?.remark : '',
  });

  const updatePatient = async (record) => {
    try {
      await familyHistoryApi.addFamilyHistory(patient.id, record)
      onCreate();
    } catch (error) {
      console.log(error);
      toast.error(<ErrorToast error={error} />);
    }
  };

  return (
    <div className="pat-med-c">
      <form onSubmit={handleSubmit(updatePatient)}>
        <div className="pat-med-c__form-wrapper">
          <div className="pat-med-c__form-wrapper__row">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FamilyHistoryInput
                  value={value}
                  onChange={(value) => onChange(value)}
                  label={
                    <Text
                      color="inherit"
                      type="label"
                      id="patient_app.health_wallet.medical_records.forms.family_history"
                    >
                      Family history
                    </Text>
                  }
                  invalid={errors?.name}
                />
              )}
            />
          </div>
          <div className="pat-med-c__form-wrapper__row">
            <Controller
              name="remark"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  label={
                    <Text type="label" id="patient_overview.remarks">
                      Remarks
                    </Text>
                  }
                  optional
                  showErrorMessage={false}
                  placeholder="Add a remark"
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  invalid={errors?.remark}
                  // disabled={isLabResult && !!issuedTime}
                />
              )}
            />
          </div>
        </div>
        <Button color="blue" size="fullWidth" className="mt-4" type="submit">
          <Text color="inherit" id="settings_lab_grps.create">
            Create
          </Text>
        </Button>
      </form>
    </div>
  );
};

export default withRouter(AddFamilyHistoryPage);
