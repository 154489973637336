import gql from 'graphql-tag';

export const trainingCenters = gql`
  query trainingCenters($clientId: ID!) {
    trainingCenters(clientId: $clientId) {
      id
      name
      address
      city
      country
      email
      contactName
      phoneNumber
      isDefault
      zip
      geoLocation
      code
    }
  }
`;

export const getCenterCount = gql`
  query getCenterCount($clientId: ID!) {
    getCenterCount(clientId: $clientId)
  }
`;
