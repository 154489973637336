import React from 'react';
import moment from 'moment';

import './fftCovCertificateSg.scss';

const FftCovCertificateSg = props => {
  return (
    <div ref={props.certRef}>
      <div className="fft-cov">
        {props.certificate.logoImage && (
          <div className="fft-cov__logo-wrapper">
            <img
              className="fft-cov__logo-wrapper__logo"
              src={props.certificate.logoImage}
              alt="logo"
            />
          </div>
        )}
        <div className="mt-5">
          <div className="fft-cov__small-bold mb-4">
            Date:{' '}
            {moment
              .utc(props.certificate.issuedAt)
              .clone()
              .tz('Asia/Singapore')
              .format('DD/MM/YYYY')}
          </div>
          <div className="fft-cov__small-bold">To: THE OFFICER-IN-CHARGE</div>
          <div className="fft-cov__small-bold">Port Health Office, MPA</div>
          <div className="fft-cov__small-bold">
            Immigration & Quarantine Office
          </div>
          <div className="fft-cov__small-bold">Changi Airport, Singapore</div>
        </div>
        <div className="mt-4">
          <div className="fft-cov__small-bold">
            Patient Name: {props.certificate.holderName}
          </div>
          <div className="fft-cov__small-bold">
            Date of Birth:{' '}
            {moment(props.certificate.dateOfBirth, 'DD-MM-YYYY').format(
              'D MMMM YYYY'
            )}
          </div>
          <div className="fft-cov__small-bold">
            Nationality: {props.certificate.nationality}
          </div>
          <div className="fft-cov__small-bold">
            Passport Number: {props.certificate.passNo}
          </div>
          <div className="fft-cov__small-bold">
            Sex: {props.certificate.gender}
          </div>
        </div>
        <div className="mt-5">
          <div className="fft-cov__info-text">
            This is to certify that the above-named patient had been examined
            via telemedicine today, in line with guidelines set out by MOH
            Circular 119/2020.
          </div>
        </div>
        <div className="mt-4">
          <div className="fft-cov__info-text">
            Patient did not have any respiratory symptoms in the last 72hours.
            Patient does not exhibit any fever (Temp > 37.5deg) or
            symptoms/signs suggestive of any acute respiratory infection.
            Patient pulse oxymetry was normal (SpO2 > 95% on room air).
          </div>
        </div>
        <div className="mt-4">
          <div className="fft-cov__info-text">
            In the past 14 days, patient:
          </div>
          <div className="fft-cov__info-text">
            1) Did not have any contact with any confirmed case of Covid-19
            infection.
          </div>
          <div className="fft-cov__info-text">
            2) Did not visit healthcare institution/hospitals or high risk
            exposure environment in any countries. The patient does not fullfill
            the Singapore MOH case definition of a covid19 suspect.
          </div>
        </div>
        <div className="mt-4">
          <div className="fft-cov__info-text">
            The patient is fit for repatriation by air or sea. No medical escort
            is needed. This certificate is valid for no more than 72hours.
          </div>
        </div>
        {props.certificate?.signers && (
          <div className="fft-cov__signer mt-6">
            {props.certificate?.signers[0]?.image && (
              <img
                className="fft-cov__signer__img mb-3"
                src={props.certificate?.signers[0]?.image}
                alt="img"
              />
            )}
            <div className="fft-cov__small-bold">
              {props.certificate?.signers[0]?.name}
            </div>
            <div className="fft-cov__small-bold">
              {props.certificate.physicianLicense}
            </div>
            <div className="fft-cov__small-bold">
              {moment
                .utc(props.certificate.issuedAt)
                .clone()
                .tz('Asia/Singapore')
                .format('DD/MM/YYYY')}
            </div>
          </div>
        )}
        <div className="mt-4">
          <div className="fft-cov__info-text">
            *Medical Document issued in accordance with the provisions of
            STCW Convention regulation I/9 and of section A-I/9 of the STCW
            Code.
          </div>
        </div>
        {props.showQrCode && (
          <img
            className="fft-cov__qr-code"
            src={props.certificate.qrCodeImage}
            alt="qrcode"
          />
        )}
      </div>
    </div>
  );
};

export default FftCovCertificateSg;
