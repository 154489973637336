import { API_V1_ENDPOINT } from '../../../constants';
import fetchWrapper from 'utils/fetchWrapper';

export const SET_SELECTED_SECTION =
  'CERTIFICATE_MANAGEMENT/SET_SELECTED_SECTION';

// ****************
// API CALLS
// ****************

/**
 * @function fetchIndustries
 * @description API call to fetch course industries
 *
 */
export const fetchIndustries = () =>
  fetchWrapper({ endpoint: API_V1_ENDPOINT });

// ****************
// STATE SETTERS
// ****************

/**
 * @function setSelectedSection
 * @description Set selected header section for certificate management
 */
export const setSelectedSection = (selectedSection) => ({
  type: SET_SELECTED_SECTION,
  payload: selectedSection,
});
