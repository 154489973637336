import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    width="inherit"
    height="inherit"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    {...props}
  >
    <g>
      <path
        d="M14.73 8.2a1.001 1.001 0 0 1 1.43-1.4L18 8.681V1.5a1 1 0 0 1 2 0v7.181L21.84 6.8a1 1 0 0 1 1.43 1.4l-2.515 2.57a2.478 2.478 0 0 1-3.502.008L14.73 8.2ZM23 11.5a1 1 0 0 0-1 1v7a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V9.571l6.465 6.465a5.025 5.025 0 0 0 7.06.01l1.882-1.829a1 1 0 1 0-1.394-1.434l-1.892 1.839a3.074 3.074 0 0 1-4.242 0L2.361 7.1A2.99 2.99 0 0 1 5 5.5h7a1 1 0 1 0 0-2H5a5.006 5.006 0 0 0-5 5v11a5.006 5.006 0 0 0 5 5h14a5.006 5.006 0 0 0 5-5v-7a1 1 0 0 0-1-1Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill={props.color}
          transform="translate(0 .5)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
