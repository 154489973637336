import { apolloClient } from '../apollo/apollo';
import {
  getCertificateTemplateById,
  getCertificateTemplatesByClientId,
  getCertTemplateImages,
} from './graphql/queries/certificateTemplates';
import {
  issueCertificateTemplate,
  updateCertificateTemplate,
  deleteCertificateTemplate,
  addCertTemplateImage,
} from './graphql/mutations/certificateTemplates';

export default {
  async getCertificateTemplatessByClientId(clientId) {
    return apolloClient.query({
      query: getCertificateTemplatesByClientId,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
      },
    });
  },

  async getCertificateTemplateById(id) {
    return apolloClient.query({
      query: getCertificateTemplateById,
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    });
  },

  async createCertificateTemplate(template) {
    return apolloClient.mutate({
      mutation: issueCertificateTemplate,
      variables: template,
    });
  },

  async updateCertificateTemplate(template) {
    return apolloClient.mutate({
      mutation: updateCertificateTemplate,
      variables: template,
    });
  },

  async deleteCertificateTemplate(templateId) {
    return apolloClient.mutate({
      mutation: deleteCertificateTemplate,
      variables: {
        id: templateId,
      },
    });
  },

  async addCertTemplateImage(clientId, imageId, type) {
    return apolloClient.query({
      query: addCertTemplateImage,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        imageId,
        type,
      },
    });
  },

  async getCertTemplateImages(clientId) {
    return apolloClient.query({
      query: getCertTemplateImages,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
      },
    });
  },
};
