import React from 'react';
import moment from 'moment';

import PatientReactSessions from 'pages/reactHealthPages/ReactHealthDetails/components/PatientReactSessions';
import tinyBlueSvg from 'assets/new/tinyBlueRiverrLogo.svg';

import { Text } from 'components'

import 'pages/patientDetails/patientDetails.scss';

const PatientReactHealthOverview = ({ fetchingPatient, patientDetails }) => {
  return (
    <div className='prho'>

      <div className="patient-details__section">
        <div className="patient-details__details-wrapper">
          {fetchingPatient ? (
            <div className="patient-details__details-wrapper__loader-wrapper">
              <Loader
                active
                className="patient-details__details-wrapper__loader-wrapper__loader"
                size="small"
              />
            </div>
          ) : (
            <>
              <div className="patient-details__patient-photo-wrapper">
                <div className="patient-details__patient-photo-wrapper__pic-name">
                  <div className="patient-details__patient-photo">
                    {/* {(patientPhoto || medProfile?.profileUrl) && (
                      <img
                        src={patientPhoto || medProfile?.profileUrl}
                        alt="patient"
                      />
                    )} */}
                  </div>
                  <div className="patient-details__detail">
                    <Text size="big" dark className="ml-1 mb-1" uppercase>
                      {patientDetails.fullName}
                    </Text>
                    {patientDetails.id && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={tinyBlueSvg} alt="logo" />
                        <Text color="blue" size="tinniest" className="mr-2">
                          Riverr ID
                        </Text>
                        <Text color="blue" size="tiny" dark capitalize>
                          {patientDetails.id.substring(0, 3)}-
                          {patientDetails.id.substring(3, 6)}
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="patient-details__details-wrapper__row patient-details__details-wrapper__row--line">
                {patientDetails.dateOfBirth && (
                  <div>
                    <Text type="dataLabel" id="patient_overview.birth_date">
                      Date of Birth
                    </Text>
                    <Text type="data">
                      {moment(patientDetails.dateOfBirth, 'YYYY-MM-DD').format(
                        'DD-MM-YYYY'
                      )}{' '}
                      ({moment().diff(patientDetails.dateOfBirth, 'years')}{' '}
                      years old)
                    </Text>
                  </div>
                )}

                {patientDetails.gender && (
                  <div>
                    <Text type="dataLabel" id="patient_overview.gender">
                      Gender
                    </Text>
                    <Text type="data" uppercase>
                      {patientDetails.gender}
                    </Text>
                  </div>
                )}
                {patientDetails.nationality && (
                  <div>
                    <Text type="dataLabel" id="patient_overview.nationality_">
                      Nationality
                    </Text>
                    <Text type="data" uppercase>
                      {patientDetails.nationality}
                    </Text>
                  </div>
                )}

                {(patientDetails.ssn ||
                  patientDetails?.identified?.socialSecurityNumber) && (
                  <div>
                    <Text type="dataLabel">NRIC/FIN</Text>
                    <Text type="data">
                      {patientDetails.ssn ||
                        patientDetails?.identified?.socialSecurityNumber}
                    </Text>
                  </div>
                )}

                {(patientDetails.passNo ||
                  patientDetails?.identified?.passportNumber) && (
                  <div>
                    <Text
                      type="dataLabel"
                      id="patient_overview.passport_number"
                    >
                      Passport Number
                    </Text>
                    <Text type="data">
                      {patientDetails.passNo ||
                        patientDetails?.identified?.passportNumber}
                    </Text>
                  </div>
                )}
                {patientDetails.email && (
                  <div>
                    <Text type="dataLabel" id="patient_overview.email">
                      Email
                    </Text>
                    <Text type="data">{patientDetails.email}</Text>
                  </div>
                )}

                {patientDetails.phoneNumber && (
                  <div>
                    <Text type="dataLabel" id="patient_overview.phone_number">
                      Phone Number
                    </Text>
                    <Text type="data">{patientDetails.phoneNumber}</Text>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="patient-details__section">
        <Text bold size="big" id="patient_overview.react_sessions">
          ReACT Sessions
        </Text>
        <div className="details-table">
          <PatientReactSessions patientId={patientDetails.id} patientDetails={patientDetails} hasCreate={true} />
        </div>
      </div>
    </div>
  );
};

export default PatientReactHealthOverview;
