import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Dropdown, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { Search } from '../components';
import colors from '../styles/colors';

const SearchDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const onOpen = () => {
    setIsOpen(true);
    props.onOpen();
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onSelect = (value) => {
    props.onSelect(value);
  };

  const filterData = (e, { value }) => {
    const filteredResults = props.options.filter((o) =>
      Object.keys(o).some((k) => {
        if (k === 'value') {
          return o[k].toLowerCase().includes(value);
        }
      })
    );

    setOptions(filteredResults);
  };

  return (
    <DropdownButton
      text={props.value}
      icon={isOpen ? 'angle up' : 'angle down'}
      onOpen={onOpen}
      onClose={onClose}
      floating
      labeled
      className="icon"
    >
      <Dropdown.Menu>
        <SearchWrapper>
          <Search
            onSearchChange={filterData}
            size="small"
            open={false}
            loading={props.loading}
            dropdown={props.dropdown}
          />
        </SearchWrapper>
        <DropdownContent>
          {props.isLoading && <Loader active inline="centered" />}
          {options.length === 0 && !props.isLoading && (
            <div style={{ textAlign: 'center' }}>No results</div>
          )}
          {options.map((option, index) => (
            <DropdownItem
              key={index}
              {...option}
              onClick={() => onSelect(option.value)}
            />
          ))}
        </DropdownContent>
      </Dropdown.Menu>
    </DropdownButton>
  );
};

SearchDropdown.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  onOpen: PropTypes.func,
  onSelect: PropTypes.func,
};

SearchDropdown.defaultProps = {
  value: 'value',
  options: [],
  onOpen: () => {},
  dropdown: true,
  loading: false,
};

const DropdownButton = styled(Dropdown)`
  box-shadow: 0px 1px 4px 0px ${colors.GREY};
  background: white;
  padding: 9px 7px;
  border-radius: 4px;
  user-select: none;

  &:hover {
    background: #f2f8fc;
  }

  .icon {
    margin-left: 7px;
  }

  .text {
    color: #1b1b1b;
    font-size: 18px;
  }

  .menu {
    width: 400px;
  }
`;

const DropdownContent = styled.div`
  margin: 30px;
  margin-left: 40px;
  max-height: 250px;
  overflow: auto;
`;

const DropdownItem = styled(Dropdown.Item)`
  height: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    .text {
      color: #4598e7;
    }
  }
`;

const SearchWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
`;

export default SearchDropdown;
