import shortUuid from 'short-uuid';
import {
  SET_TEST_ORDER,
  CLEAR_TEST_ORDER,
  SET_TEST_ORDER_PANEL,
  ADD_TEST_ORDER_PANEL,
  REMOVE_TEST_ORDER_PANEL,
  SET_TEST_ORDER_PROGRESS,
  SET_TEST_ORDER_STEP,
  SET_TEST_PANEL,
  SET_TEST_PROFILE_OPTIONS,
  RESET_TEST_ORDER,
} from './testOrderPages.actions';

const panelObject = {
  id: Math.round(+new Date() / 1000),
  value: null,
  history: [],
};

const initialState = {
  isUrgent: false,
  isPatientFasting: false,
  isVerified: false,
  lab: {},
  panels: [],
  testPanels: [],
  progress: 1,
  testProfileOptions: [],
};

const testOrderReducer = (state = initialState, action) => {
  const { testProfileOptions, ..._state } = state;
  const currentClient = localStorage.getItem('DELTA_CLIENT_ID');
  switch (action.type) {
    case RESET_TEST_ORDER:
      localStorage.setItem(
        `TEST_ORDER/${currentClient}`,
        JSON.stringify(initialState)
      );
      return initialState;
    case SET_TEST_ORDER:
      localStorage.setItem(
        `TEST_ORDER/${currentClient}`,
        JSON.stringify({
          ..._state,
          ...action.payload,
        })
      );
      return { ...state, ...action.payload };
    case SET_TEST_PROFILE_OPTIONS:
      return { ...state, testProfileOptions: action.payload };
    case SET_TEST_ORDER_PANEL:
      const panels = [...state.panels];
      const panelIndex = panels.findIndex(
        (panel) => panel.id === action.payload.id
      );
      panels[panelIndex] = action.payload.testOrderPanel;
      localStorage.setItem(
        `TEST_ORDER/${currentClient}`,
        JSON.stringify({
          ..._state,
          panels,
        })
      );
      return { ...state, panels };
    case SET_TEST_PANEL:
      const testPanels = [...state.testPanels];
      testPanels[action.payload.index] = action.payload.testPanel;
      localStorage.setItem(
        `TEST_ORDER/${currentClient}`,
        JSON.stringify({
          ..._state,
          testPanels,
        })
      );
      return { ...state, testPanels };
    case ADD_TEST_ORDER_PANEL:
      const panelsAdd = [...state.panels];
      panelsAdd.push({
        value: null,
        history: [],
        ...action.payload.testOrderPanel,
        id: Math.round(+new Date() / 1000),
      });
      localStorage.setItem(
        `TEST_ORDER/${currentClient}`,
        JSON.stringify({
          ..._state,
          panels: panelsAdd,
        })
      );
      return { ...state, panels: panelsAdd };
    case REMOVE_TEST_ORDER_PANEL:
      const panelsRemove = [...state.panels];
      const _panelIndex = panelsRemove.findIndex(
        (panel) => panel.id === action.payload.id
      );
      panelsRemove.splice(_panelIndex, 1);
      localStorage.setItem(
        `TEST_ORDER/${currentClient}`,
        JSON.stringify({
          ..._state,
          panels: panelsRemove,
        })
      );
      return { ...state, panels: panelsRemove };
    case CLEAR_TEST_ORDER:
      localStorage.setItem(
        `TEST_ORDER/${currentClient}`,
        JSON.stringify(initialState)
      );
      return initialState;
    case SET_TEST_ORDER_STEP:
      localStorage.setItem(
        `TEST_ORDER/${currentClient}`,
        JSON.stringify({ ..._state, ...action.payload })
      );
      return { ...state, ...action.payload };
    case SET_TEST_ORDER_PROGRESS:
      localStorage.setItem(
        `TEST_ORDER/${currentClient}`,
        JSON.stringify({ ..._state, ...action.payload })
      );
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default testOrderReducer;
