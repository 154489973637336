import React from 'react';
import moment from 'moment-timezone';

import './singPDT.scss';

const SingPDT = (props) => {
  const qrCodes = props.certificate.qrCodes
    ? JSON.parse(props.certificate.qrCodes)
    : null;
  console.log('props.certificate.extraData', props.certificate.extraData);
  const extraData = props.certificate.extraData
    ? JSON.parse(props.certificate.extraData)
    : null;

  const negativeSymbol = extraData?.negativeSymbol || '≥';

  return (
    <div ref={props.certRef}>
      <div className="sing-pdt">
        {props.certificate.logoImage && (
          <div className="sing-pdt__logo-wrapper">
            <img
              className="sing-pdt__logo-wrapper__logo"
              src={props.certificate.logoImage}
              alt="logo"
            />
          </div>
        )}
        <h1 className="sing-pdt__header">
          {props.certificate.name ||
            'Memo on Reverse Transcription Polymerase Chain Reaction (RT-PCR) test'}{' '}
          {props.certificate.result} Result
        </h1>

        <section>
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Name of Person:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.holderName}
            </div>
          </div>
          {props.certificate.ssn && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">NRIC/FIN Number:</div>
              <div className="sing-pdt__row__data">{props.certificate.ssn}</div>
            </div>
          )}
          {props.certificate.passNo && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">
                Passport Number/Travel Document Number:
              </div>
              <div className="sing-pdt__row__data">
                {props.certificate.passNo}
              </div>
            </div>
          )}
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Nationality/Citizenship:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.nationality}
            </div>
          </div>
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Date of Birth:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.issuedAt < '2021-02-18T09:03:00.000Z'
                ? moment(props.certificate.dateOfBirth).format('DD mmm YYYY')
                : moment
                    .utc(props.certificate.dateOfBirth)
                    .format('DD MMMM YYYY')}
            </div>
          </div>
        </section>

        <section className="sing-pdt__info-text" style={{ marginTop: '20px' }}>
          <p style={{ marginBottom: '0px' }}>
            To whom it may concern, the above-mentioned has undergone RT-PCR
            testing for COVID-19 using a{' '}
            {props.certificate.transportName || 'Nasopharyngeal swab'} the
            result is{' '}
            <span className="sing-pdt__info-text__result">
              {props.certificate.result}
            </span>{' '}
            and the test information is as follows:
          </p>
          <div className="sing-pdt__row" style={{ marginTop: '20px' }}>
            <div className="sing-pdt__row__label">Collection time:</div>
            <div className="sing-pdt__row__data">
              {moment
                .utc(props.certificate.collectionDate)
                .clone()
                .tz('Asia/Singapore')
                .format('DD MMMM YYYY HH:mm')}{' '}
              (GMT +08:00)
            </div>
          </div>
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">
              Name of licensed/approved PCR Provider:
            </div>
            <div className="sing-pdt__row__data">
              {props.certificate.issuerName}
            </div>
          </div>
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Laboratory name:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.labName}
            </div>
          </div>

          {extraData && extraData.reagentBrand && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">Reagent Brand:</div>
              <div className="sing-pdt__row__data">
                {extraData.reagentBrand}
              </div>
            </div>
          )}

          {extraData && extraData.orf1ab && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">ORF1ab, Ct value:</div>
              <div className="sing-pdt__row__data">
                Ct{' '}
                {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
                {Number(extraData.orf1ab).toFixed(
                  props.certificate.result === 'Positive' ? 2 : 0
                )}
              </div>
            </div>
          )}
          {extraData && extraData.nGene && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">N Gene, Ct value:</div>
              <div className="sing-pdt__row__data">
                Ct{' '}
                {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
                {Number(extraData.nGene).toFixed(
                  props.certificate.result === 'Positive' ? 2 : 0
                )}
              </div>
            </div>
          )}

          {extraData && extraData.n2Gene && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">N2 Gene, Ct value:</div>
              <div className="sing-pdt__row__data">
                Ct{' '}
                {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
                {Number(extraData.n2Gene).toFixed(
                  props.certificate.result === 'Positive' ? 2 : 0
                )}
              </div>
            </div>
          )}

          {extraData && extraData.sGene && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">S Gene, Ct value:</div>
              <div className="sing-pdt__row__data">
                Ct{' '}
                {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
                {Number(extraData.sGene).toFixed(
                  props.certificate.result === 'Positive' ? 2 : 0
                )}
              </div>
            </div>
          )}

          {extraData && extraData.eGene && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">E Gene, Ct value:</div>
              <div className="sing-pdt__row__data">
                Ct{' '}
                {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
                {Number(extraData.eGene).toFixed(
                  props.certificate.result === 'Positive' ? 2 : 0
                )}
              </div>
            </div>
          )}

          {extraData && extraData.rdrp && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">RdRp, Ct value:</div>
              <div className="sing-pdt__row__data">
                Ct{' '}
                {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
                {Number(extraData.rdrp).toFixed(
                  props.certificate.result === 'Positive' ? 2 : 0
                )}
              </div>
            </div>
          )}

          {extraData && extraData.RdRpSORF1ab && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">
                RdRp/S/ORF1ab, Ct value:
              </div>
              <div className="sing-pdt__row__data">
                Ct{' '}
                {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
                {Number(extraData.RdRpSORF1ab).toFixed(
                  props.certificate.result === 'Positive' ? 2 : 0
                )}
              </div>
            </div>
          )}

          {extraData && extraData.n1n2Gene && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">N1, N2 Gene, Ct value:</div>
              <div className="sing-pdt__row__data">
                Ct{' '}
                {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
                {Number(extraData.n1n2Gene).toFixed(
                  props.certificate.result === 'Positive' ? 2 : 0
                )}
              </div>
            </div>
          )}

          <div className="mt-3 mb-0">
            This memo is used for applying for the health code.
          </div>
          <div className="tw-pdt__ch-s mb-3 mt-0">
            此检测证明用于申请健康码。
          </div>
        </section>
        <div className="sing-pdt__signature-wrapper">
          {props.certificate.physicianSignature ? (
            <img
              className="sing-pdt__signature-wrapper__sig"
              src={props.certificate.physicianSignature}
              alt="signature"
            />
          ) : (
            <div />
          )}
          {props.certificate.stampImage && (
            <img
              className="sing-pdt__stamp"
              src={props.certificate.stampImage}
              alt="stamp"
            />
          )}
        </div>

        <section className="sing-pdt__bottom-names">
          <p>
            <span style={{ fontWeight: 'bold' }}>Name of Doctor:</span>{' '}
            {props.certificate.physicianName}
            <br />
            <span style={{ fontWeight: 'bold' }}>MCR No.:</span>{' '}
            {props.certificate.physicianLicense}
          </p>
        </section>

        {props.certificate.qrCodeImage &&
          (!qrCodes ||
            Object.keys(qrCodes).length === 1 ||
            (qrCodes && Object.keys(qrCodes).length > 1 && !qrCodes.EU)) && (
            <div className="sing-pdt__qr-code">
              <img
                className="sing-pdt__qr-code__img"
                src={props.certificate.qrCodeImage}
                alt="qrcode"
              />
            </div>
          )}
        {qrCodes && Object.keys(qrCodes).length > 1 && qrCodes.EU && (
          <div className="sing-pdt__qr-codez">
            <div className="sing-pdt__qr-code-wrapper">
              <div className="sing-pdt__qr-code-wrapper__qr">
                <img
                  className="sing-pdt__qr-code-wrapper__qr__img sing-pdt__qr-code-wrapper__qr__img--small"
                  src={
                    'https://riverr-trustroot.s3-ap-southeast-1.amazonaws.com/' +
                    qrCodes.EU
                  }
                  alt="qrcode"
                />
                <div className="sing-pdt__qr-code-wrapper__text">
                  Offline QR code (EU DCC-compatible)
                </div>
                <div className="sing-pdt__qr-code-wrapper__sub-text">
                  Requires a DCC QR scanner. Supported by the European Union
                  (EU) this may be used for public health measures beyond travel
                  within the EU.
                </div>
              </div>
              <div className="sing-pdt__qr-code-wrapper__qr">
                <img
                  className="sing-pdt__qr-code-wrapper__qr__img sing-pdt__qr-code-wrapper__qr__img--small"
                  src={
                    'https://riverr-trustroot.s3-ap-southeast-1.amazonaws.com/' +
                    qrCodes.SG
                  }
                  alt="qrcode"
                />
                <div className="sing-pdt__qr-code-wrapper__text">
                  Singapore HealthCert
                </div>
              </div>
            </div>
          </div>
        )}
        {props.certificate.watermarkImage && (
          <img
            className="sing-pdt__watermark"
            src={props.certificate.watermarkImage}
            alt="watermark"
          />
        )}
      </div>
    </div>
  );
};

export default SingPDT;
