import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter } from 'react-router';
import { Loader, Modal, Dropdown, Button as Sbutton } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import CircleLoader from 'react-spinners/CircleLoader';
import downloadFile from 'js-file-download';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import Certificate from './certificates/Certificate';
import { Button, ErrorToast, Text, Switch, SuccessToast } from 'components';
import { isExportedPdf, isExportingPdf } from 'utils/downloadPdf';

import history from 'history.js';
import CertificatesService from 'api-delta/certificates';
import LabResultService from 'api-delta/labResults';

import LeftArrow from 'assets/icons/ArrowLeft';
import ErrorIllustration from 'assets/illustrations/empty.png';
import PcrCertificate from './certificates/pcrCertificate/PcrCertificate';
import SingPDT from './certificates/pcrCertificate/certs/SingPDT';
import LampCert from './certificates/pcrCertificate/certs/LampCert';
import SingPCR from './certificates/pcrCertificate/certs/SingPCR';
import ChPCRAnti from './certificates/pcrCertificate/certs/ChinesePCRAntiGen';
import ChPCRAntiProtein from './certificates/pcrCertificate/certs/ChinesePCRAntProtein';
import SingRapid from './certificates/pcrCertificate/certs/SingRapidTest';
import PhPCR from './certificates/pcrCertificate/certs/FilipinoPCR';
import TwPCR from './certificates/pcrCertificate/certs/TaiwanPCR';
import PhFTT from './certificates/pcrCertificate/certs/FilipinoFitToTravel';
import PemeCertificate from './certificates/pemeCertificate/PhPemeCert';
import FftCertificate from './certificates/fftCertificate/FftCertificate';
import ChPDT from './certificates/pcrCertificate/certs/ChPDT';
import FftCovCertificateSg from './certificates/fftCovCertificateSg/FftCovCertificateSg';
import CovidVac from './certificates/covidVac/CovidVac';
import SerCert from './certificates/pcrCertificate/certs/SerologyCert';
import EmailModal from './components/EmailModal/EmailModal';

import './certificateView.scss';
import EmailActivityAccordion from './components/EmailActivityAccordion/EmailActivityAccordion';
import { useInterval } from 'hooks';
import { A4_SIZE_FORMAT } from 'constant/certificate';
import {
  DownloadPdfStatus,
  INTERVAL_DURATION,
} from 'constant/downloadPdfStatus';

const CertificateView = (props) => {
  const certRef = useRef();

  const [isGenerating, setIsGenerating] = useState(false);
  const [isFetchingCert, setIsFetchingCert] = useState(true);
  const [isNotarising, setIsNotarising] = useState(false);
  const [image, setImage] = useState(null);
  const [certificate, setCertificate] = useState({});
  const [isError, setIsError] = useState(false);
  const [fetchingImage, setFetchingImage] = useState(false);
  const [isModalToggled, setIsModalToggled] = useState(false);
  const [isPrintModalToggled, setIsPrintModalToggled] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false);
  const [qrPrint, setQRPrint] = useState(true);
  const [openCert, setOpenCert] = useState(null);
  const [loadingOpenCert, setLoadingOpenCert] = useState(true);
  const [notarisationStatus, setNotarisationStatus] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [labResult, setLabResult] = useState({});
  const [emailModalToggled, toggleEmailModal] = useState(false);

  const clientInformation = useSelector(
    (state) => state.general.clientInformation
  );

  useEffect(() => {
    fetchCertificate();
  }, []); // eslint-disable-line

  const isFetchableInterval = useCallback((cert) => {
    return isExportingPdf(cert);
  }, []);

  useInterval(
    async () => {
      try {
        const certificateId =
          props.match.params.id || props.match.params.certificateId;
        const {
          data: { certificate: newCert },
        } = await CertificatesService.getCertificate(certificateId);

        setCertificate(newCert);
        if (!isFetchableInterval(newCert)) {
          setIsGenerating(false);
          switch (newCert.exportPdfStatus) {
            case DownloadPdfStatus.SUCCESS:
              if (newCert.pdfPath) {
                downloadPdf(newCert.pdfPath);
              }
              break;
            case DownloadPdfStatus.FAILED:
            case DownloadPdfStatus.IDLE:
            case DownloadPdfStatus.RETRYABLE:
              toast.error(
                <ErrorToast message="The PDF generate failed, please try again" />
              );
              break;
            default:
              toast.error(
                <ErrorToast message="The PDF is still generating, please try again a little later" />
              );
              break;
          }
        }
      } catch (error) {
        toast.error(<ErrorToast message="Error fetching document" />);
        setIsGenerating(false);
      }
    },
    isFetchableInterval(certificate) ? INTERVAL_DURATION : null
  );

  const fetchCertificate = async () => {
    setIsError(false);
    setIsFetchingCert(true);
    const certificateId =
      props.match.params.id || props.match.params.certificateId;
    try {
      const {
        data: { certificate },
      } = await CertificatesService.getCertificate(certificateId);
      setCertificate(certificate);
      fetchCertPic(certificate);
      getFailedReq(certificate);
      fetchOpenCert(certificate.id);
      setIsFetchingCert(false);

      if (certificate.isRevoked && !props.publicView)
        fetchLabResult(certificate);

      const isValid =
        certificate.isVerified &&
        moment().toISOString() <
          moment(certificate.validUntil).endOf('day').toISOString() &&
        !certificate.isRevoked;

      setIsValid(isValid);

      return certificate;
    } catch (error) {
      toast.error(<ErrorToast message="Error fetching document" />);
      setIsError(true);
    }

    setIsFetchingCert(false);
  };

  const fetchLabResult = async (cert) => {
    try {
      const {
        data: { getLabResult },
      } = await LabResultService.getLabResultPublic(cert.id, 'docId');
      setLabResult(getLabResult);
    } catch (error) {}
  };

  const fetchCertPic = async (cert) => {
    if (cert.issuedByDLM === true) return;
    try {
      setFetchingImage(true);
      if (cert.imageId) {
        let path = `documents/${cert.imageId}`;
        if (!cert.imageId.includes('pdf') && !cert.imageId.includes('jpg'))
          path = path + '.jpg';
        const {
          data: { getMIDImage },
        } = await CertificatesService.getMIDImage(path);
        setImage(getMIDImage.image);
      }
    } catch (error) {
      toast.error(<ErrorToast message="Error fetching document file" />);
    }
    setFetchingImage(false);
  };

  const closePrintModal = () => {
    setQRPrint(true);
    setIsPrintModalToggled(false);
  };

  const fetchOpenCert = async (certificateId) => {
    try {
      setLoadingOpenCert(true);
      const {
        data: { openCert },
      } = await CertificatesService.getOpenCert(certificateId);
      setOpenCert(openCert.cert);
      setLoadingOpenCert(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleDropdownSelect = (e, { value }) => {
    if (value === 'download') downloadOpenCert();
    if (value === 'sendEmail') toggleEmailModal(true);
    if (value === 'modalPrint') setIsPrintModalToggled(true);
    if (value === 'print_doc') setIsPrintModalToggled(true);
  };

  const downloadOpenCert = () => {
    downloadFile(
      openCert,
      `${certificate.holderName} - ${certificate.name}.oa`
    );
  };

  const printCert = () => {
    const prtHtml = certRef.current.innerHTML;

    // Get all stylesheets HTML
    let stylesHtml = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const node of [
      ...document.querySelectorAll('link[rel="stylesheet"], style'),
    ]) {
      stylesHtml += node.outerHTML;
    }
    // Open the print window
    let WinPrint;
    // eslint-disable-next-line prefer-const
    WinPrint = window.open(
      '',
      '',
      'left=0,top=0,width=1024,height=800,toolbar=0,status=0'
    );
    WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}
            </head>
            <body>
              ${prtHtml}
            </body>
          </html>`);
    WinPrint.document.close();
    setTimeout(() => {
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    }, 500);
  };

  const getStatusText = () => {
    if (isValid) return { text: 'Valid', valid: true };
    if (certificate.isRevoked) return { text: 'Revoked', valid: false };
    if (
      moment().toISOString() >
      moment(certificate.validUntil).endOf('day').toISOString()
    )
      return { text: 'Expired', valid: false };
    return { text: 'Invalid', valid: false };
  };

  const renderCert = () => {
    const { publicView } = props;

    if (isFetchingCert)
      return (
        <div className="certificate-view__loader-wrapper">
          <CircleLoader color="#455af7" />
        </div>
      );

    if (certificate.certType === 'PCR_TEST') {
      if (!certificate.subType || certificate.subType === 'SG_PDT_PCR_TEST')
        return (
          <SingPDT
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );

      if (certificate.subType === 'SG_LAMP')
        return (
          <LampCert
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );

      if (certificate.subType === 'SG_PCR_TEST')
        return (
          <SingPCR
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );

      if (certificate.subType === 'CH_PDT_PCR_TEST')
        return (
          <ChPDT
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );

      if (
        certificate.subType === 'SEROLOGY' ||
        certificate.subType === 'SEROLOGY_IGG'
      )
        return (
          <SerCert
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );

      if (certificate.subType === 'CH_PCR_ANTIGEN_TEST')
        return (
          <ChPCRAnti
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      if (certificate.subType === 'CH_PCR_PRO_ANTIBODY_TEST')
        return (
          <ChPCRAntiProtein
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      if (certificate.subType === 'CH_PCR_PRO_S_ANTIBODY_TEST')
        return (
          <ChPCRAntiProtein
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      if (certificate.subType === 'MY_PCR_TEST')
        return (
          <SingPDT
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      if (
        certificate.subType === 'SG_ANTI_RAPID_TEST' ||
        certificate.subType === 'PA_SG_ANTI_RAPID_TEST' ||
        certificate.subType === 'RMSP_SG_ANTI_RAPID_TEST' ||
        certificate.subType === 'SP_SG_ANTI_RAPID_TEST'
      )
        return (
          <SingRapid
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      if (certificate.subType === 'PH_PCR_TEST')
        return (
          <PhPCR
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      if (certificate.subType === 'TW_PCR_TEST')
        return (
          <TwPCR
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        );
      if (certificate.subType === 'PH_PDT_SF_TEST')
        return (
          <PhFTT
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
            client={clientInformation}
          />
        );
      else
        return (
          <div className="certificate-view__cert">
            <PcrCertificate
              showQrCode={qrPrint}
              certificate={certificate}
              certRef={certRef}
            />
          </div>
        );
    }

    if (certificate.subType === 'FFT_COV_SG') {
      return (
        <FftCovCertificateSg
          showQrCode={qrPrint}
          certificate={certificate}
          certRef={certRef}
        />
      );
    }

    if (
      certificate.subType === 'COVID_VAC' ||
      certificate.subType === 'SG_COVID_VAC'
    )
      return (
        <CovidVac
          showQrCode={qrPrint}
          certificate={certificate}
          certRef={certRef}
        />
      );

    if (certificate.certType === 'PEME') {
      return (
        <div className="certificate-view__cert">
          <PemeCertificate
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
            publicView={publicView}
          />
        </div>
      );
    }

    if (certificate.subType === 'FFT') {
      return (
        <div className="certificate-view__cert">
          <FftCertificate
            showQrCode={qrPrint}
            certificate={certificate}
            certRef={certRef}
          />
        </div>
      );
    }

    return (
      <div className="certificate-view__cert">
        <Certificate
          showQrCode={qrPrint}
          certificate={certificate}
          certRef={certRef}
        />
      </div>
    );
  };

  const revokeDocument = async () => {
    setIsRevoking(true);
    const certificateId =
      props.match.params.id || props.match.params.certificateId;
    try {
      await CertificatesService.onRemoveCertificate(certificateId);
      await fetchCertificate();
      setIsModalToggled(false);
    } catch (error) {
      toast.error(<ErrorToast message="Error revoking document" />);
    }

    setIsRevoking(false);
  };

  const getFileBase64 = async () => {
    try {
      if (
        !isExportedPdf(certificate)
        // && moment(certificate.createdAt).diff(Date.now(), 'minutes') < -1
      )
        return generatePdf();
      if (!isExportedPdf(certificate)) {
        toast.error(
          <SuccessToast message="The PDF is still generating, please try again a little later" />
        );
        return props.publicView ? props.fetchCertificate() : fetchCertificate();
      }

      let pdfPath = certificate.pdfPath;

      if (certificate.secCert) {
        const secPdfPath = JSON.parse(certificate.secCert).pdfPath;
        if (!secPdfPath) {
          toast.error(
            <SuccessToast message="The PDF is still generating, please try again a little later" />
          );
          return props.publicView
            ? props.fetchCertificate()
            : fetchCertificate();
        }
        pdfPath = secPdfPath;
      }

      const {
        data: { getSignedFile },
      } = await CertificatesService.getSignedFile(pdfPath);

      axios
        .get(getSignedFile.file, {
          responseType: 'blob',
        })
        .then((res) => {
          const isIphone = /iPhone/i.test(navigator.userAgent);
          if (isIphone) {
            let url = window.URL.createObjectURL(res.data);
            window.location.assign(url, '_blank');
          } else {
            downloadFile(
              res.data,
              `${certificate.holderName} - ${certificate.name}.pdf`
            );
          }
        });
    } catch (error) {
      return null;
    }
  };

  const generatePdf = async () => {
    try {
      setIsGenerating(true);
      toast.success(
        <SuccessToast message="Generating PDF, this could take up to 20 seconds" />,
        {
          hideProgressBar: true,
          autoClose: 8000,
          pauseOnHover: true,
        }
      );

      await CertificatesService.generatePdf(
        certificate.userId,
        certificate.id,
        certificate.clientId,
        certificate.paperSize || A4_SIZE_FORMAT
      );
      const cert = await fetchCertificate();
      // should check status before interval timer called
      if (!isFetchableInterval(cert)) {
        setIsGenerating(false);
      }

      return cert;
    } catch (error) {
      toast.error(
        <ErrorToast message="Error generating PDF, please select 'Print document' from the dropdown istead" />,
        {
          autoClose: 8000,
          pauseOnHover: true,
        }
      );
    }
  };

  const downloadPdf = async (pdfPath) => {
    try {
      const {
        data: { getSignedFile },
      } = await CertificatesService.getSignedFile(pdfPath);

      axios
        .get(getSignedFile.file, {
          responseType: 'blob',
        })
        .then((res) => {
          downloadFile(
            res.data,
            `${certificate.holderName} - ${certificate.name}.pdf`
          );
        });
    } catch (error) {
      toast.error(
        <ErrorToast message="Error downloading PDF, please try again later" />
      );
    }
  };

  const handleDownloadPdf = async () => {
    if (isExportedPdf(certificate))
      return await downloadPdf(certificate.pdfPath);
    const cert = await getFileBase64();
    if (isExportedPdf(cert) && cert.pdfPath) {
      return await downloadPdf(cert.pdfPath);
    }
  };

  const getOptions = () => {
    const options = [
      {
        key: 'modalPrint',
        icon: 'print',
        value: 'modalPrint',
        text: 'Print/Save Document',
      },
    ];
    if (!props.publicView)
      options.unshift({
        key: 'sendEmail',
        icon: 'mail',
        value: 'sendEmail',
        text: 'Email Document',
      });
    if (certificate.storedInBlock && certificate.transactionHash)
      options.push({
        key: 'download',
        icon: 'download',
        value: 'download',
        text: 'Download OA file',
      });
    return options;
  };

  const notariseDoc = async () => {
    try {
      setIsNotarising(true);
      await CertificatesService.notariseDoc(
        props.match.params.id || props.match.params.certificateId
      );
      await new Promise((resolve) => {
        setTimeout(resolve, 3000);
      });
      setNotarisationStatus(false);
      await fetchCertificate();
      setIsNotarising(false);
    } catch (error) {
      toast.error(<ErrorToast message="Error notarising document" />);
      setIsNotarising(false);
    }
  };

  const getFailedReq = async (cert) => {
    if (!cert) return;
    // Is it worthy of Notarisation?
    const isNotaWorthy =
      cert.subType === 'SG_PDT_PCR_TEST' ||
      cert.subType === 'SG_LAMP' ||
      cert.subType === 'CH_PDT_PCR_TEST' ||
      cert.subType === 'SG_PCR_TEST' ||
      cert.subType === 'CH_PCR_ANTIGEN_TEST' ||
      cert.subType === 'SEROLOGY' ||
      cert.subType === 'SEROLOGY_IGG' ||
      cert.subType === 'CH_PCR_PRO_S_ANTIBODY_TEST' ||
      cert.subType === 'CH_PCR_PRO_ANTIBODY_TEST';

    if (!isNotaWorthy && cert.storedInBlock !== 'false')
      return setNotarisationStatus(false);
    try {
      const {
        data: { getFailedReq },
      } = await CertificatesService.getFailedReq(
        props.match.params.id || props.match.params.certificateId
      );
      if (getFailedReq) {
        const parsedReq = JSON.parse(getFailedReq);
        setNotarisationStatus(parsedReq.status);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const reIssueLabResult = () => {
    history.push(`/documents/lab/issue/${labResult.id}`);
  };

  const reIssueDocument = () => {
    history.push(
      `/documents/issue/${
        props.match.params.id || props.match.params.certificateId
      }`
    );
  };

  return (
    <div className="certificate-view">
      <EmailModal
        clientId={certificate.clientId}
        emailModalToggled={emailModalToggled}
        toggleEmailModal={toggleEmailModal}
        certEmail={certificate.email}
        certId={props.match.params.id || props.match.params.certificateId}
        ccEmail={
          !props.publicView ? clientInformation.accountHolderEmail : null
        }
      />
      <Modal
        size="tiny"
        open={isModalToggled}
        onClose={() => setIsModalToggled(false)}
        closeIcon
      >
        <Modal.Header>Are you sure?</Modal.Header>
        <Modal.Content className="certificate-view__modal-content">
          <Button
            inverted
            size="medium"
            onClick={() => setIsModalToggled(false)}
            className="certificate-view__modal-content__button"
          >
            Cancel
          </Button>
          <Button
            size="medium"
            onClick={() => revokeDocument()}
            color="red"
            disabled={isRevoking}
          >
            {isRevoking ? (
              <Loader active inverted size="tiny" color="white" />
            ) : (
              'Revoke'
            )}
          </Button>
        </Modal.Content>
      </Modal>
      <Modal
        size="tiny"
        open={isPrintModalToggled}
        onClose={() => closePrintModal(false)}
      >
        <Modal.Header>Print settings</Modal.Header>
        <Modal.Content className="certificate-view__print-modal">
          <div className="certificate-view__print-modal__info">
            Enable colours and backgrounds in the print settings. You will
            probably need to change your print settings:
            <ul>
              <li>'Margins' to 'None'</li>
            </ul>
          </div>
          <div className="certificate-view__print-modal__content">
            <div className="certificate-view__print-modal__header">
              Settings
            </div>
            <div className="certificate-view__print-modal__switch">
              <Switch
                onChange={() => setQRPrint(!qrPrint)}
                checked={qrPrint}
                // height={props.height}
              />

              <div className="certificate-view__print-modal__label">
                QR code in print
              </div>
            </div>
          </div>
          <div className="certificate-view__print-modal__buttons">
            <Button
              inverted
              size="medium"
              onClick={() => closePrintModal(false)}
              className="certificate-view__modal-content__button"
            >
              Cancel
            </Button>
            <Button
              size="medium"
              onClick={() => printCert()}
              color="blue"
              disabled={isRevoking}
            >
              Print
            </Button>
          </div>
        </Modal.Content>
      </Modal>
      <div
        className={`certificate-view__buttons-wrapper ${
          props.publicView && 'mt-3'
        }`}
      >
        <div>
          {!props.publicView && (
            <Button
              floated="left"
              circular
              icon
              size="medium"
              onClick={() => history.goBack()}
              color="blue"
            >
              <LeftArrow color="white" fontSize="1.3em" />
            </Button>
          )}
        </div>
        <div className="certificate-view__buttons-wrapper__right">
          {!props.publicView &&
            !isFetchingCert &&
            !certificate.isRevoked &&
            !certificate.isExternal &&
            (certificate.issuedBy === clientInformation.issuerName ||
              clientInformation?.type === 'lab') && (
              <Button
                floated="right"
                icon
                size="fluid"
                onClick={() => setIsModalToggled(true)}
                color="red"
                className="certificate-view__print-button mr-3"
                disabled={isFetchingCert}
              >
                <Text color="white" size="small" type="pill">
                  Revoke document
                </Text>
              </Button>
            )}
          {certificate &&
            !certificate.imageId &&
            !isFetchingCert &&
            !certificate.isExternal && (
              <Sbutton.Group color="teal">
                {/* {renderDownloadComponent} */}
                <Button
                  floated="right"
                  icon
                  size="fluid"
                  onClick={() => handleDownloadPdf()}
                  color="blue"
                  className="certificate-view__print-button mr-2"
                  disabled={isGenerating || isExportingPdf(certificate)}
                >
                  <Text color="white" size="small">
                    {isGenerating || isExportingPdf(certificate)
                      ? 'Generating PDF'
                      : 'Download PDF'}
                  </Text>
                </Button>
                <Dropdown
                  className="button icon certificate-view__print-button__dropdown"
                  onChange={handleDropdownSelect}
                  selectOnBlur={false}
                  floating
                  value={null}
                  options={getOptions()}
                  trigger={<></>}
                />
              </Sbutton.Group>
            )}
        </div>
      </div>
      {!props.publicView && (
        <EmailActivityAccordion certId={props.match.params.id} />
      )}

      <div>
        {notarisationStatus === 'FAILED' && (
          <div className="certificate-view__nota-status">
            <Text size="small" bold>
              Document failed notarisation, click the button to manually
              notarise
            </Text>
            <Button
              style={{ marginTop: '14px' }}
              onClick={() => notariseDoc()}
              disabled={isNotarising}
              color="green"
              isLoading={isNotarising}
            >
              Notarise
            </Button>
          </div>
        )}
        {certificate.isRevoked && labResult.id && (
          <div className="certificate-view__nota-status certificate-view__nota-status--green">
            <Text size="small" dark>
              Re-issue Lab Result
            </Text>
            <Text
              size="small"
              bold
              style={{
                maxWidth: '650px',
                marginTop: '15px',
                marginBottom: '15px',
              }}
            >
              You revoked this document that was based on a Lab Result, if you
              want to re-issue click the button below.
            </Text>
            <Button
              style={{ marginTop: '14px' }}
              onClick={() => reIssueLabResult()}
              color="green"
            >
              Issue lab result
            </Button>
          </div>
        )}
        {certificate.isRevoked &&
          !props.publicView &&
          !labResult.id &&
          (certificate.subType === 'SG_ANTI_RAPID_TEST' ||
            certificate.subType === 'RMSP_SG_ANTI_RAPID_TEST' ||
            certificate.subType === 'SP_SG_ANTI_RAPID_TEST' ||
            certificate.subType === 'PA_SG_ANTI_RAPID_TEST') && (
            <div className="certificate-view__nota-status certificate-view__nota-status--green">
              <Text size="small" dark>
                Re-issue Document
              </Text>
              <Text
                size="small"
                bold
                style={{
                  maxWidth: '650px',
                  marginTop: '15px',
                  marginBottom: '15px',
                }}
              >
                You revoked this document if you want to re-issue click the
                button below.
              </Text>
              <Button
                style={{ marginTop: '14px' }}
                onClick={() => reIssueDocument()}
                color="green"
              >
                Re-issue
              </Button>
            </div>
          )}
        {notarisationStatus === 'RETRY' && (
          <div className="certificate-view__nota-status">
            <Text size="small" bold>
              Document is notarising, click to refresh
            </Text>
            <Button
              style={{ marginTop: '14px' }}
              onClick={() => fetchCertificate()}
              disabled={isNotarising}
              color="green"
              isLoading={isNotarising}
            >
              Refresh
            </Button>
          </div>
        )}
        {!isFetchingCert && props.publicView && (
          <div className="certificate-view__nota-status certificate-view__nota-status--green">
            <Text size="small" bold>
              If any details are incorrect please contact your clinic{' '}
              <b>{certificate.issuerName}</b> directly
            </Text>
          </div>
        )}
        {isError ? (
          <div className="certificate-view__error-wrapper">
            <img
              className="certificate-view__error-wrapper__error"
              src={ErrorIllustration}
              alt=""
            />
            <Text size="huge">Error fetching document</Text>
          </div>
        ) : (
          <div>
            {certificate &&
              !isFetchingCert &&
              !certificate.isExternal &&
              ((!props.publicView && certificate.isRevoked) ||
                props.publicView) && (
                // <div
                //   className={`certificate-view__status ${!isValid &&
                //     'certificate-view__status--red'}`}
                // >
                //   <Icon
                //     className="certificate-view__status__icon"
                //     name="info"
                //     color="white"
                //     style={{ marginLeft: '15px', marginRight: '15px' }}
                //     fontSize="1.5em"
                //   />
                //   <Text
                //     bold
                //     className="certificate-view__status__Text"
                //     color="white"
                //   >
                //     {getStatusText()}
                //   </Text>
                // </div>
                <div className="certificate-view__status-info">
                  <div className="certificate-view__status-info__section">
                    <Text
                      dark
                      size="small"
                      className="certificate-view__status-info__section__header"
                    >
                      Status
                    </Text>
                    <Text
                      bold
                      size="small"
                      className="certificate-view__status-info__section__text"
                    >
                      <div
                        className={`certificate-view__status-info__dot ${
                          !isValid && 'certificate-view__status-info__dot--red'
                        }`}
                      />
                      {getStatusText().text}
                    </Text>
                  </div>
                  <div className="certificate-view__status-info__section">
                    <Text
                      dark
                      size="small"
                      className="certificate-view__status-info__section__header"
                    >
                      Issuer Identified
                    </Text>
                    <Text
                      bold
                      size="small"
                      className="certificate-view__status-info__section__text"
                    >
                      <div className="certificate-view__status-info__dot" />
                      {certificate.issuerName}
                    </Text>
                  </div>
                  <div className="certificate-view__status-info__section">
                    <Text
                      dark
                      size="small"
                      className="certificate-view__status-info__section__header"
                    >
                      {certificate.transactionHash
                        ? 'Is Notarised'
                        : 'Is Issued'}
                    </Text>
                    <Text
                      bold
                      size="small"
                      className="certificate-view__status-info__section__text"
                    >
                      <div className="certificate-view__status-info__dot" />
                      True
                    </Text>
                  </div>
                </div>
              )}
            <div className="certificate-view__wrapper">{renderCert()}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(CertificateView);
