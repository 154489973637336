import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { CircleLoader } from 'react-spinners';

import PaymentService from 'api-delta/paymentService';

import './createAccountSuccess.scss';

const CreateAccountSuccess = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState({});
  let { id } = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      setLoading(true);
      await PaymentService.onAccountSuccess({
        id,
        isDev: localStorage.getItem('IS_DEV_MODE') === 'true',
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
    if (error) {
      setResponse({
        icon: 'exclamation',
        color: 'red',
        message: 'Something went wrong, please try again....',
      });
      // return <>Something went wrong, please try again....</>;
    } else {
      setResponse({
        icon: 'checkmark',
        color: 'green',
        message: 'Account created',
      });
    }
  }, [error, id]);

  return (
    <div className="account-success__wrapper">
      {loading ? (
        <div className="appointment-overview__loader-wrapper">
          <CircleLoader color="#455af7" />
        </div>
      ) : (
        <div className="account-success__content">
          <Icon
            name={response.icon}
            circular
            inverted
            bordered
            size="big"
            color={response.color}
          />

          <h2>{response.message}</h2>
        </div>
      )}
    </div>
  );
};

export default CreateAccountSuccess;