import React from 'react';

const SvgBurgerMenu = props => (
  <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
    <g
      stroke="#1b1b1b"
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path d="M3.217 4.5H22.61M3.217 13H22.61M3.217 21.5H22.61" />
    </g>
  </svg>
);

export default SvgBurgerMenu;
