import { apolloClient } from '../apollo/apollo';
import {
  getStaffByClientId,
  getStaffByCenterId,
  getStaff,
  getStaffCount,
} from './graphql/queries/staff';
import {
  createStaff,
  deleteStaff,
  updateStaff,
} from './graphql/mutations/staff';

export default {
  async getStaffByClientId(clientId) {
    return apolloClient.query({
      query: getStaffByClientId,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
      },
    });
  },

  async getStaffByCenterId(centerId) {
    return apolloClient.query({
      query: getStaffByCenterId,
      fetchPolicy: 'no-cache',
      variables: {
        centerId,
      },
    });
  },

  async getStaff(id) {
    return apolloClient.query({
      query: getStaff,
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    });
  },

  async createStaff(Staff) {
    return apolloClient.mutate({
      mutation: createStaff,
      variables: {
        ...Staff,
      },
    });
  },

  async updateStaff(Staff) {
    return apolloClient.mutate({
      mutation: updateStaff,
      variables: {
        ...Staff,
      },
    });
  },

  async deleteStaff(StaffId) {
    return apolloClient.mutate({
      mutation: deleteStaff,
      variables: {
        id: StaffId,
      },
    });
  },

  async getStaffCount(clientId, centerId) {
    return apolloClient.query({
      query: getStaffCount,
      variables: {
        clientId,
        centerId,
      },
    });
  },
};
