import React from 'react';

import Alert from '../assets/icons/Alert';
import AngleDown from '../assets/icons/AngleDown';
import AngleLeft from '../assets/icons/AngleLeft';
import AngleRight from '../assets/icons/AngleRight';
import AngleUp from '../assets/icons/AngleUp';
import ArrowCircle from '../assets/icons/ArrowCircle';
import ArrowLeft from '../assets/icons/ArrowLeft';
import ArrowRight from '../assets/icons/ArrowRight';
import Audience from '../assets/icons/Audience';
import BarChart from '../assets/icons/BarChart';
import Bell from '../assets/icons/Bell';
import Bin from '../assets/icons/Bin';
import BlueStar from '../assets/icons/BlueStar';
import BurgerMenu from '../assets/icons/BurgerMenu';
import Calendar from '../assets/icons/Calendar';
import Camera from '../assets/icons/Camera';
import Certificate from '../assets/icons/Certificate';
import CaretRight from '../assets/icons/CaretRight';
import Chat from '../assets/icons/Chat';
import CheckShield from '../assets/icons/CheckShield';
import Clock from '../assets/icons/Clock';
import Connect from '../assets/icons/Connect';
import CopyIcon from '../assets/icons/CopyIcon';
import Cross from '../assets/icons/Cross';
import DiagonalArrowDown from '../assets/icons/DiagonalArrowDown';
import DiagonalArrowUp from '../assets/icons/DiagonalArrowUp';
import Dollar from '../assets/icons/Dollar';
import Edit from '../assets/icons/Edit';
import Eye from '../assets/icons/Eye';
import FilledRibbonBadge from '../assets/icons/FilledRibbonBadge';
import Filter from '../assets/icons/Filter';
import Gears from '../assets/icons/Gears';
import GradHat from '../assets/icons/GradHat';
import Home from '../assets/icons/Home';
import Image from '../assets/icons/Image';
import Info from '../assets/icons/Info';
import Language from '../assets/icons/Language';
import Lightning from '../assets/icons/Lightning';
import Link from '../assets/icons/Link';
import List from '../assets/icons/List';
import LocationPin from '../assets/icons/LocationPin';
import Lock from '../assets/icons/Lock';
import Message from '../assets/icons/Message';
import More from '../assets/icons/More';
import Pin from '../assets/icons/Pin';
import Plus from '../assets/icons/Plus';
import Printer from '../assets/icons/Printer';
import Profile from '../assets/icons/Profile';
import ProfilePicPlaceholder from '../assets/icons/ProfilePicPlaceholder';
import Recent from '../assets/icons/Recent';
import Refresh from '../assets/icons/Refresh';
import RibbonBadge from '../assets/icons/RibbonBadge';
import Search from '../assets/icons/Search';
import Smiley from '../assets/icons/Smiley';
import SpacedBarChart from '../assets/icons/SpacedBarChart';
import StarOutline from '../assets/icons/StarOutline';
import TestTubes from '../assets/icons/TestTubes';
import Tick from '../assets/icons/Tick';
import Trend from '../assets/icons/Trend';
import Dashboard from '../assets/icons/Dashboard';
import ShieldPlus from '../assets/icons/ShieldPlus';
import Courses from '../assets/icons/Courses';
import Settings from '../assets/icons/Settings';
import Templates from '../assets/icons/Templates';
import InfoExclamation from '../assets/icons/InfoExclamation';
import InfoTick from '../assets/icons/InfoTick';
import Patient from '../assets/icons/Patient';
import Orders from '../assets/icons/Orders';
import Samples from '../assets/icons/Samples';
import User from '../assets/new/staff';
import Close from '../assets/icons/Close';
import SvgTimeOutline from 'assets/icons/timeOutline';
import Riverr from 'assets/icons/riverr';
import PlusCircle from 'assets/icons/PlusCircle';
import AngleCaretRight from 'assets/icons/AngleCaretRight';
import Approved from 'assets/icons/Approved';
import Declined from 'assets/icons/Declined';
import Interview from 'assets/icons/Interview';

const Icon = (props) => {
  switch (props.name) {
    case 'alert':
      return <Alert {...props} />;
    case 'patient':
      return <Patient {...props} />;
    case 'orders':
      return <Orders {...props} />;
    case 'samples':
      return <Samples {...props} />;
    case 'courses':
      return <Courses {...props} />;
    case 'templates':
      return <Templates {...props} />;
    case 'settings':
      return <Settings {...props} />;
    case 'infoExclamation':
      return <InfoExclamation {...props} />;
    case 'infoTick':
      return <InfoTick {...props} />;
    case 'shieldPlus':
      return <ShieldPlus {...props} />;
    case 'dashboard':
      return <Dashboard {...props} />;
    case 'angleDown':
      return <AngleDown {...props} />;
    case 'angleLeft':
      return <AngleLeft {...props} />;
    case 'angleRight':
      return <AngleRight {...props} />;
    case 'caretRight':
      return <CaretRight {...props} />;
    case 'angleUp':
      return <AngleUp {...props} />;
    case 'arrowCircle':
      return <ArrowCircle {...props} />;
    case 'arrowLeft':
      return <ArrowLeft {...props} />;
    case 'arrowRight':
      return <ArrowRight {...props} />;
    case 'audience':
      return <Audience {...props} />;
    case 'barChart':
      return <BarChart {...props} />;
    case 'bell':
      return <Bell {...props} />;
    case 'bin':
      return <Bin {...props} />;
    case 'blueStar':
      return <BlueStar {...props} />;
    case 'burgerMenu':
      return <BurgerMenu {...props} />;
    case 'calendar':
      return <Calendar {...props} />;
    case 'close':
      return <Close {...props} />;
    case 'camera':
      return <Camera {...props} />;
    case 'angleCaretRight':
      return <AngleCaretRight {...props} />;
    case 'certificate':
      return <Certificate {...props} />;
    case 'chat':
      return <Chat {...props} />;
    case 'checkShield':
      return <CheckShield {...props} />;
    case 'clock':
      return <Clock {...props} />;
    case 'connect':
      return <Connect {...props} />;
    case 'copy':
      return <CopyIcon {...props} />;
    case 'cross':
      return <Cross {...props} />;
    case 'diagonalArrowDown':
      return <DiagonalArrowDown {...props} />;
    case 'diagonalArrowUp':
      return <DiagonalArrowUp {...props} />;
    case 'dollar':
      return <Dollar {...props} />;
    case 'edit':
      return <Edit {...props} />;
    case 'eye':
      return <Eye {...props} />;
    case 'filledRibbonBadge':
      return <FilledRibbonBadge {...props} />;
    case 'filter':
      return <Filter {...props} />;
    case 'gears':
      return <Gears {...props} />;
    case 'gradHat':
      return <GradHat {...props} />;
    case 'home':
      return <Home {...props} />;
    case 'image':
      return <Image {...props} />;
    case 'info':
      return <Info {...props} />;
    case 'language':
      return <Language {...props} />;
    case 'lightning':
      return <Lightning {...props} />;
    case 'link':
      return <Link {...props} />;
    case 'list':
      return <List {...props} />;
    case 'locationPin':
      return <LocationPin {...props} />;
    case 'lock':
      return <Lock {...props} />;
    case 'message':
      return <Message {...props} />;
    case 'more':
      return <More {...props} />;
    case 'pin':
      return <Pin {...props} />;
    case 'plus':
      return <Plus {...props} />;
    case 'plusCircle':
      return <PlusCircle {...props} />;
    case 'printer':
      return <Printer {...props} />;
    case 'profile':
      return <Profile {...props} />;
    case 'profilePicPlaceholder':
      return <ProfilePicPlaceholder {...props} />;
    case 'recent':
      return <Recent {...props} />;
    case 'refresh':
      return <Refresh {...props} />;
    case 'ribbonBadge':
      return <RibbonBadge {...props} />;
    case 'riverr':
      return <Riverr {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'smiley':
      return <Smiley {...props} />;
    case 'spacedBarChart':
      return <SpacedBarChart {...props} />;
    case 'starOutline':
      return <StarOutline {...props} />;
    case 'timeOutline':
      return <SvgTimeOutline {...props} />;
    case 'testTubes':
      return <TestTubes {...props} />;
    case 'tick':
      return <Tick {...props} />;
    case 'trend':
      return <Trend {...props} />;
    case 'user':
      return <User {...props} />;
    case 'approved':
      return <Approved {...props} />;
    case 'declined':
      return <Declined {...props} />;
    case 'interview':
      return <Interview {...props} />;
    default:
      return <div />;
  }
};

export default Icon;
