import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import styles from './ImagePreview.scss';
import PropTypes from 'prop-types';

const Preview = (props) => {
  const handleClick = useCallback(
    (event) => {
      if (!event.target || !props.closeOnClickOutside) return;

      const checkId = event.target.id === 'preview-image';
      const checkClass = event.target.classList.contains(
        'image-preview__slide'
      );

      if (checkId || checkClass) {
        event.stopPropagation();
        props.onClose?.();
      }
    },
    [props]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Escape') props.onClose?.();
    },
    [props]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div
      id="preview-image"
      className={`${styles.wrapper} image-preview__modal`}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
    >
      <span
        className={`image-preview__close`}
        onClick={() => props.onClose?.()}
      >
        {props.closeComponent || '×'}
      </span>

      <div className={`image-preview__modal-content`} onClick={handleClick}>
        <div className="image-preview__slide">
          <img className={styles.image} src={props.image} alt="" />
        </div>
      </div>
    </div>
  );
};

Preview.propTypes = {
  thumbnail: PropTypes.string,
  image: PropTypes.string,
  closeOnClickOutside: PropTypes.bool,
  onClose: PropTypes.func,
  closeComponent: PropTypes.node,
};

const ImagePreview = (props) => {
  const [isOpenPreview, setIsOpenPreview] = useState(false);

  return (
    <div className="image-preview">
      <img
        src={props.thumbnail}
        onClick={() => setIsOpenPreview(true)}
        width="90%"
        style={{ margin: 'auto', cursor: 'pointer' }}
        alt=""
      />
      {isOpenPreview && (
        <Preview
          thumbnail={props.thumbnail}
          image={props.image}
          onClose={() => setIsOpenPreview(false)}
          closeOnClickOutside={true}
        />
      )}
    </div>
  );
};

export default ImagePreview;
