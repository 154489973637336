import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Icon } from 'semantic-ui-react';

import {
  Button,
  Select,
  Cleave,
  Input,
  ErrorToast,
  TextArea,
  FileUpload,
  Text,
  LinearLoader,
  SuccessToast,
  CheckBox,
  Webcam,
} from 'components';
import DualCheckBox from './DualCheckbox/DualCheckbox';
import PemeCert from '../../../certificatePages/certificateView/certificates/pemeCertificate/PhPemeCert';
import { setTemplates } from '../../../certificatePages/templatesManagement/templatesManagement.actions';
import { setStaff } from '../../../staffPages/staffManagement/store/staffManagement.actions';

import appointmentService from 'api-delta/appointments';
import ProfileService from 'api-delta/profiles';
import StaffService from 'api-delta/staff';
import CertificateTemplatesService from 'api-delta/certificateTemplates';

import countries from 'helpers/countries';
import { s3UploadMixin } from 'helpers/s3Upload';
import { uploadModes } from '../../../../constants';
import { sortAlphabetically } from 'helpers/helpers';

import '../appointmentDetails.scss';

const PemeAppointment = ({
  appointment,
  setCourseCompleted,
  physician,
  isDocCreator,
  facilitiesOptions,
  onIssue,
  _subType,
  setRecentDocs,
}) => {
  const dispatch = useDispatch();

  const clientInfo = useSelector((state) => state.general.clientInformation);

  const [isSaving, setIsSaving] = useState(false);
  const [savingDocs, setIsSavingDocs] = useState(false);

  const [countriesList, setCountriesList] = useState([]);
  const [errors, setErrors] = useState({});
  const [passNo, setPassNo] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nationality, setNationality] = useState(null);
  const [gender, setGender] = useState(null);
  const [birthDate, setBirthDate] = useState('');
  const [religion, setReligion] = useState('');
  const [seabookNo, setSeabook] = useState('');
  const [position, setPosition] = useState('');
  const [company, setCompany] = useState('');
  const [civilStatus, setCivilStatus] = useState(null);
  const [checkedDocs, setCheckedDocs] = useState(null);
  const [hearingStandards, setHearingStandards] = useState(null);
  const [visionMeetsStandards, setVisionMeetsStandards] = useState(null);
  const [visualAids, setVisualAids] = useState(false);
  const [fitForLookout, setFitForLookout] = useState(null);
  const [fitnessLimitation, setFitnessLimitation] = useState(null);
  const [hearingSatisfactory, setHearingSatisfactory] = useState(null);
  const [visualMeetsStandards, setVisualMeetsStandards] = useState(null);
  const [aggravatedHealth, setAggravatedHealth] = useState(null);
  const [isFit, setIsFit] = useState(null);
  const [limitation, setLimitation] = useState('');
  // const [docOptions, setDocOptions] = useState([]);
  const [date, setDate] = useState(moment().format('DD/MM/YYYY'));
  const [dateOfIssance, setDateOfIssuance] = useState(
    moment().format('DD/MM/YYYY')
  );
  const [expirationDate, setExpirationDate] = useState(
    moment().add(1, 'years').format('DD/MM/YYYY')
  );
  const [physicianName, setPhysicianName] = useState('');
  const [physicianLicense, setPhysicianLicense] = useState('');
  const [authority, setAuthority] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [address, setAddress] = useState('');
  const [zip, setZip] = useState('');
  const [medicalDirector, setMedicalDirector] = useState(null);
  const [issuingAuthority, setIssuingAuthority] = useState(null);

  const [patientCity, setPatientCity] = useState('');
  const [patientCountry, setPatientCountry] = useState('');
  const [patientAddress, setPatientAddress] = useState('');
  const [patientZip, setPatientZip] = useState('');
  const [birthPlace, setBirthPlace] = useState('');
  const [lastColorTest, setLastColorTest] = useState('');

  const [drugFile, setDrugFile] = useState(false);
  const [drugScreeningFile, setDrugScreeningFile] = useState(false);
  const [roentgenologicalFile, setRoentgenologicalFile] = useState(false);
  const [hivScreening, setHivScreening] = useState(false);
  const [labResults, setLabResults] = useState(false);
  const [auditoryResults, setAuditoryResults] = useState(false);

  const [physicians, setPhysicians] = useState(false);
  const [physicianSelect, setPhysicianSelect] = useState(null);
  const [email, setEmail] = useState('');
  const [findingUser, setFindingUser] = useState(false);
  const [selectedDoc, setDocument] = useState(false);
  const [subType, setSubType] = useState('PH_MARI_PEME');
  const [docOptions, setDocOptions] = useState([]);
  const [isModalToggled, toggleModal] = useState(false);
  const [isSigModalToggled, toggleSigModal] = useState(false);
  const [patientPhoto, setPatientPhoto] = useState(null);
  const [patientSig, setPatientSig] = useState(null);
  const [lenses, setLenses] = useState(false);

  const [facility, setFacility] = useState(null);
  const [previewToggled, togglePreview] = useState(false);
  const [previewData, setPreviewData] = useState(null);

  const templates = useSelector((state) => state.templatesManagement.templates);
  useEffect(() => {
    formatCountries();
    fetchTemplates();
    fetchInstructors();
  }, []); // eslint-disable-line
  useEffect(() => {
    if (isDocCreator) return;
    findUser();
    setAppointmentData();
  }, [appointment]); // eslint-disable-line
  useEffect(() => {
    setPhysicianData();
  }, [physician]); // eslint-disable-line
  // useEffect(() => {
  //   formatTemplates();
  //       }, [templates]); // eslint-disable-line

  useEffect(() => {
    if (!facilitiesOptions || facilitiesOptions.length !== 1) return;
    setAddress(facilitiesOptions[0].address);
    setCity(facilitiesOptions[0].city);
    setZip(facilitiesOptions[0].zip);
    setCountry(facilitiesOptions[0].country);
    setFacility(facilitiesOptions[0]);
  }, [facilitiesOptions]);

  useEffect(() => {
    formatTemplates();
  }, [templates]); // eslint-disable-line

  const userInfo = useSelector((state) => state.general.user);
  const clientInformation = useSelector(
    (state) => state.general.clientInformation
  );

  const setAppointmentData = () => {
    if (!appointment) return;
    setAddress(appointment.street);
    setCity(appointment.city);
    setZip(appointment.zip);
    setCountry(appointment.country);
  };

  const setPhysicianData = () => {
    if (!physician) return;
    setPhysicianName(physician.name);
    setPhysicianLicense(physician.licenseNo);
    setAuthority(physician.certAuthority);
  };

  const fetchTemplates = async () => {
    if (templates.length !== 0) return;
    try {
      const {
        data: { certificateTemplatesByClientId },
      } = await CertificateTemplatesService.getCertificateTemplatessByClientId(
        userInfo.currentClientId
      );
      const sortedData = sortAlphabetically(certificateTemplatesByClientId);
      dispatch(setTemplates(sortedData));
    } catch (error) {
      toast.error(<ErrorToast message="Error fetching templates" />);
    }
  };

  const fetchInstructors = () => {
    if (userInfo.centerId) return fetchInstructorsByCenter();
    return fetchInstructorsByClient();
  };

  const fetchInstructorsByClient = async () => {
    const clientId = userInfo.currentClientId;

    try {
      const {
        data: { instructorsByClientId },
      } = await StaffService.getStaffByClientId(clientId);
      dispatch(setStaff(instructorsByClientId));
      formatPhysicians(instructorsByClientId);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const formatTemplates = () => {
    if (!templates || templates.length === 0) return;
    const formInsts = [];
    templates.forEach((temp) => {
      if (temp.certType === 'PEME')
        formInsts.push({
          text: temp.name,
          value: temp.id,
        });
    });
    setDocOptions(formInsts);
  };

  const fetchInstructorsByCenter = async () => {
    try {
      const {
        data: { instructorsByCenterId },
      } = await StaffService.getInstructorsByCenterId(userInfo.centerId);
      dispatch(setStaff(instructorsByCenterId));
      formatPhysicians(instructorsByCenterId);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const formatPhysicians = (instructorsByClientId) => {
    setPhysicians(
      instructorsByClientId.map((phys) => {
        return {
          text: phys.name,
          value: {
            name: phys.name,
            signature: phys.signature,
            licenseNo: phys.licenseNo,
            certAuthority: phys.certAuthority,
          },
        };
      })
    );
  };

  const formatCountries = () => {
    const formattedCons = countries.map((con, index) => {
      return {
        key: index,
        text: con.text,
        value: { nationality: con.text, countryCode: con.key },
      };
    });

    setCountriesList(formattedCons);
  };

  const findUser = useCallback(async () => {
    try {
      if (!email && !appointment) return;
      setFindingUser(true);
      const {
        data: { userByEmail },
      } = await ProfileService.searchUser(email || appointment.patientEmail);
      setFindingUser(false);

      if (userByEmail.id !== '-1') {
        userByEmail.address && setPatientAddress(userByEmail.address);
        userByEmail.addressCity && setPatientCity(userByEmail.addressCity);
        userByEmail.addressCountry &&
          setPatientCountry(userByEmail.addressCountry);
        userByEmail.passNo && setPassNo(userByEmail.passNo);
        userByEmail.firstName &&
          setFirstName(userByEmail.passportFirstName || userByEmail.firstName);
        userByEmail.lastName &&
          setLastName(userByEmail.passportLastName || userByEmail.lastName);
        userByEmail.religion && setReligion(userByEmail.religion);
        userByEmail.currentEmployer && setCompany(userByEmail.currentEmployer);
        userByEmail.seamanBookNo && setSeabook(userByEmail.seamanBookNo);
        userByEmail.rank && setPosition(userByEmail.rank);
        userByEmail.isMarried !== undefined &&
          setCivilStatus(userByEmail.isMarried ? 'married' : 'single');
        userByEmail.birthday &&
          setBirthDate(moment(userByEmail.birthday).format('DD/MM/YYYY'));
        // userByEmail.fromCountry &&
        //   setNationality({
        //     nationality: userByEmail.fromCountry,
        //     countryCode: userByEmail.countryCode,
        //   });
        userByEmail.isFemale !== undefined &&
          setGender(userByEmail.isFemale ? 'Female' : 'Male');
      }
    } catch (error) {
      setFindingUser(false);
      console.log('error', error);
    }
  }, [appointment, email]);

  const isFormValid = () => {
    const newErrors = {};
    let toasted = false;
    let isValid = true;

    // if (!testFile) {
    //   newErrors.file = true;
    //   isValid = false;
    // }

    if (!date || date.length < 5) {
      newErrors.date = true;
      isValid = false;
    }
    if (!physicianName || physicianName.length < 2) {
      newErrors.physicianName = true;
      isValid = false;
    }
    if (!physicianLicense || physicianLicense.length < 5) {
      newErrors.physicianLicense = true;
      isValid = false;
    }
    if (!authority || authority.length < 2) {
      newErrors.authority = true;
      isValid = false;
    }
    if (!city || city.length < 3) {
      newErrors.city = true;
      isValid = false;
    }
    if (!country || country.length < 3) {
      newErrors.country = true;
      isValid = false;
    }
    if (!address || address.length < 3) {
      newErrors.address = true;
      isValid = false;
    }
    if (!zip || zip.length < 3) {
      newErrors.zip = true;
      isValid = false;
    }
    if (!expirationDate || expirationDate.length < 5) {
      newErrors.expirationDate = true;
      isValid = false;
    }
    if (!issuingAuthority || issuingAuthority.length < 3) {
      newErrors.issuingAuthority = true;
      isValid = false;
    }
    if (!passNo || passNo.length < 5) {
      newErrors.passNo = true;
      isValid = false;
    }
    if (!firstName || firstName.length < 3) {
      newErrors.firstName = true;
      isValid = false;
    }
    if (!birthPlace || birthPlace.length < 3) {
      newErrors.birthPlace = true;
      isValid = false;
    }
    if (!lastColorTest || lastColorTest.length < 5) {
      newErrors.lastColorTest = true;
      isValid = false;
    }
    if (!lastName || lastName.length < 3) {
      newErrors.lastName = true;
      isValid = false;
    }
    if (!birthDate || birthDate.length < 5) {
      newErrors.birthDate = true;
      isValid = false;
    }
    // if (!drugFile) {
    //   newErrors.drugFile = true;
    //   isValid = false;
    // }
    // if (!drugScreeningFile) {
    //   newErrors.drugScreeningFile = true;
    //   isValid = false;
    // }
    // if (!roentgenologicalFile) {
    //   newErrors.roentgenologicalFile = true;
    //   isValid = false;
    // }
    // if (!hivScreening) {
    //   newErrors.hivScreening = true;
    //   isValid = false;
    // }
    // if (!labResults) {
    //   newErrors.labResults = true;
    //   isValid = false;
    // }
    // if (!auditoryResults) {
    //   newErrors.auditoryResults = true;
    //   isValid = false;
    // }
    if (!medicalDirector) {
      newErrors.medicalDirector = true;
      isValid = false;
    }
    if (!nationality) {
      newErrors.nationality = true;
      isValid = false;
    }
    if (!gender) {
      newErrors.gender = true;
      isValid = false;
    }
    if (!seabookNo || seabookNo.length < 5) {
      newErrors.seabookNo = true;
      isValid = false;
    }
    if (!position || position.length < 3) {
      newErrors.position = true;
      isValid = false;
    }
    if (!company || company.length < 2) {
      newErrors.company = true;
      isValid = false;
    }
    if (!civilStatus || civilStatus.length < 2) {
      newErrors.civilStatus = true;
      isValid = false;
    }
    if (!patientCity || patientCity.length < 2) {
      newErrors.patientCity = true;
      isValid = false;
    }
    if (!patientCountry || patientCountry.length < 2) {
      newErrors.patientCountry = true;
      isValid = false;
    }
    if (!patientAddress || patientAddress.length < 2) {
      newErrors.patientAddress = true;
      isValid = false;
    }
    if (!patientZip || patientZip.length < 2) {
      newErrors.patientZip = true;
      isValid = false;
    }
    if (!patientPhoto) {
      newErrors.patientPhoto = true;
      isValid = false;
    }
    if (!selectedDoc) {
      newErrors.template = true;
      isValid = false;
    }
    if (!isFit) {
      newErrors.isFit = true;
      isValid = false;
    }
    if (
      (isDocCreator && physicianSelect && !physicianSelect.signature) ||
      (!isDocCreator && !physician.signature)
    ) {
      toast.error(<ErrorToast message="Physician requires signature" />);
      toasted = true;
      isValid = false;
    }
    if (!medicalDirector.signature) {
      toast.error(<ErrorToast message="Medical director requires signature" />);
      toasted = true;
      isValid = false;
    }
    if (!patientZip || patientZip.length < 2) {
      newErrors.patientZip = true;
      isValid = false;
    }
    setErrors(newErrors);
    if (!toasted && !isValid)
      toast.error(<ErrorToast message="Fill all required fields" />);
    return isValid;
  };

  const fileSelectHandler = useCallback((e, setFile) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    if (!file) return;

    setFile(file);
    reader.readAsDataURL(file);
  }, []);

  const fileToBase64 = (e, setFile) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    if (!file) return;
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const saveFiles = async () => {
    try {
      const files = {};
      const promises = [];

      if (drugFile) promises.push(saveFile('drugCert', drugFile, files));
      if (drugScreeningFile)
        promises.push(saveFile('drugScreening', drugScreeningFile, files));
      if (roentgenologicalFile)
        promises.push(
          saveFile('roentgenologicalFile', roentgenologicalFile, files)
        );
      if (hivScreening)
        promises.push(saveFile('hivScreening', hivScreening, files));
      if (labResults) promises.push(saveFile('labResults', labResults, files));
      if (auditoryResults)
        promises.push(saveFile('auditoryResults', auditoryResults, files));

      await Promise.all(promises);
      setIsSavingDocs(false);
      return files;
    } catch (error) {
      return toast.error(<ErrorToast message="Error saving files" />);
    }
  };

  const saveFile = async (name, file, fileObject) => {
    const filePath = await s3UploadMixin.uploadFileToS3(
      uploadModes.certAttachments,
      file
    );

    fileObject[name] = filePath;
  };

  const savePhoto = async (photo) => {
    const file = await fetch(photo)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], 'photo', { type: 'image/png' }));

    const filePath = await s3UploadMixin.uploadFileToS3(
      uploadModes.certAttachments,
      file
    );

    return filePath.location;
  };

  const completeAppointment = async () => {
    try {
      if (!isFormValid()) return;
      setIsSavingDocs(true);
      setIsSaving(true);

      const files = await saveFiles();
      const photo = await savePhoto(patientPhoto);
      const signature = patientSig ? await savePhoto(patientSig) : null;

      let visualAidsValue = null;
      if (visualAids && lenses) visualAidsValue = 'contactLenses, glasses';
      if (visualAids && !lenses) visualAidsValue = 'glasses';
      if (!visualAids && lenses) visualAidsValue = 'contactLenses';

      await appointmentService.completePemeAppointment({
        templateId: selectedDoc,
        appointmentId: isDocCreator ? 'N/A' : appointment.id,
        type: 'PEME',
        subType: 'PH_PEME',
        passNo,
        firstName,
        middleName,
        lastName,
        ...nationality,
        gender,
        birthDate: birthDate,
        religion,
        seabookNo,
        position,
        company,
        civilStatus,
        declaration: true,
        checkedDocs,
        hearingStandards,
        visionMeetsStandards,
        visualAids: visualAidsValue,
        fitForLookout,
        fitnessLimitation,
        hearingSatisfactory,
        visualMeetsStandards,
        aggravatedHealth,
        limitation,
        issuedAt: moment.utc(dateOfIssance, 'DD/MM/YYYY').toISOString(),
        result: isFit,
        subType,
        date: moment.utc(date, 'DD/MM/YYYY').toISOString(),
        expirationDate: moment.utc(expirationDate, 'DD/MM/YYYY').toISOString(),
        physicianName,
        physicianLicense,
        additionalImage: photo,
        medicalDirector: medicalDirector.name,
        medicalDirectorSignature: medicalDirector.signature,
        physicianSignature: isDocCreator
          ? physicianSelect.signature
          : physician.signature,
        authority,
        city,
        country,
        issuingAuthority,
        address,
        signers: signature
          ? JSON.stringify([{ name: '', role: 'patient', image: signature }])
          : null,
        zip,
        patientCity,
        patientCountry,
        patientAddress,
        patientZip,
        birthPlace,
        lastColorTest,
        template: null,
        drugFilePath: files['drugCert'] ? files['drugCert'].location : null,
        drugScreeningFilePath: files['drugScreening']
          ? files['drugScreening'].location
          : null,
        roentgenologicalFilePath: files['roentgenologicalFile']
          ? files['roentgenologicalFile'].location
          : null,
        hivScreeningPath: files['hivScreening']
          ? files['hivScreening'].location
          : null,
        labResultsPath: files['labResults']
          ? files['labResults'].location
          : null,
        auditoryResultsPath: files['auditoryResults']
          ? files['auditoryResults'].location
          : null,
      });
      setCourseCompleted(true);
    } catch (error) {
      setIsSaving(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  const clearData = () => {
    setPassNo('');
    setFirstName('');
    setMiddleName('');
    setLastName('');
    setNationality(null);
    setGender(null);
    setBirthDate('');
    setReligion('');
    setSeabook('');
    setPosition('');
    setCompany('');
    setCivilStatus(null);
    setDrugFile(false);
    setDrugScreeningFile(false);
    setRoentgenologicalFile(false);
    setHivScreening(false);
    setLabResults(false);
    setAuditoryResults(false);
    setEmail('');
    setCheckedDocs(null);
    setHearingStandards(null);
    setVisionMeetsStandards(null);
    setVisualAids(false);
    setLenses(false);
    setFitForLookout(null);
    setFitnessLimitation(null);
    setHearingSatisfactory(null);
    setVisualMeetsStandards(null);
    setAggravatedHealth(null);
    setIsFit(null);
    setLimitation('');
  };

  const issueCert = async () => {
    try {
      if (!isFormValid()) return;
      setIsSavingDocs(true);
      setIsSaving(true);

      const files = await saveFiles();
      const photo = await savePhoto(patientPhoto);
      const signature = patientSig ? await savePhoto(patientSig) : null;

      let visualAidsValue = null;
      if (visualAids && lenses) visualAidsValue = 'contactLenses, glasses';
      if (visualAids && !lenses) visualAidsValue = 'glasses';
      if (!visualAids && lenses) visualAidsValue = 'contactLenses';
      await onIssue({
        clientId: userInfo.currentClientId,
        templateId: selectedDoc,
        centerId: clientInformation.centerId || null,
        patientEmail: email,
        type: 'PEME',
        subType: 'PH_PEME',
        passNo,
        firstName,
        middleName,
        lastName,
        ...nationality,
        gender,
        birthDate: birthDate,
        religion,
        seabookNo,
        position,
        company,
        civilStatus,
        declaration: true,
        checkedDocs,
        lastColorTest,
        hearingStandards,
        visionMeetsStandards,
        additionalImage: photo,
        visualAids: visualAidsValue,
        fitForLookout,
        fitnessLimitation,
        hearingSatisfactory,
        visualMeetsStandards,
        aggravatedHealth,
        limitation,
        birthPlace,
        result: isFit,
        issuingAuthority,
        issuedAt: moment.utc(dateOfIssance, 'DD/MM/YYYY').toISOString(),
        date: moment.utc(date, 'DD/MM/YYYY').toISOString(),
        expirationDate: moment.utc(expirationDate, 'DD/MM/YYYY').toISOString(),
        physicianName,
        physicianLicense,
        medicalDirector: medicalDirector.name,
        medicalDirectorSignature: medicalDirector.signature,
        physicianSignature: isDocCreator
          ? physicianSelect.signature
          : physician.signature,
        authority,
        city,
        country,
        address,
        zip,
        patientCity,
        patientCountry,
        patientAddress,
        signers: signature
          ? JSON.stringify([{ name: '', role: 'patient', image: signature }])
          : null,
        patientZip,
        template: null,
        drugFilePath: files['drugCert'] ? files['drugCert'].location : null,
        drugScreeningFilePath: files['drugScreening']
          ? files['drugScreening'].location
          : null,
        roentgenologicalFilePath: files['roentgenologicalFile']
          ? files['roentgenologicalFile'].location
          : null,
        hivScreeningPath: files['hivScreening']
          ? files['hivScreening'].location
          : null,
        labResultsPath: files['labResults']
          ? files['labResults'].location
          : null,
        auditoryResultsPath: files['auditoryResults']
          ? files['auditoryResults'].location
          : null,
      });

      let recentDocsLS = localStorage.getItem('RECENT_ISSUED_DOCS');
      if (recentDocsLS) {
        recentDocsLS = JSON.parse(recentDocsLS);
        let _subTypes = recentDocsLS.subTypes;
        if (_subTypes.length > 5) {
          _subTypes = _subTypes.slice(0, 5);
        }
        if (!_subTypes.includes(_subType)) {
          _subTypes.unshift(_subType);
        }
        recentDocsLS.userId = userInfo.safe_id;
        recentDocsLS.subTypes = _subTypes;
      } else {
        recentDocsLS = {
          userId: userInfo.safe_id,
          subTypes: [_subType],
        };
      }
      setRecentDocs(recentDocsLS.subTypes);
      localStorage.setItem('RECENT_ISSUED_DOCS', JSON.stringify(recentDocsLS));

      toast.success(<SuccessToast message="Document issued" />, {
        hideProgressBar: true,
        autoClose: 4000,
        pauseOnHover: true,
      });
      clearData();
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  const selectPhysician = (phys) => {
    setPhysicianName(phys.name);
    setPhysicianLicense(phys.licenseNo || '');
    setPhysicianSelect(phys);
    setAuthority(phys.certAuthority || '');
  };

  const selectFacility = (facility) => {
    setAddress(facility.address);
    setCity(facility.city);
    setZip(facility.zip);
    setCountry(facility.country);
    setFacility(facility);
  };

  const onWebcamCapture = (image) => {
    setPatientPhoto(image);
    toggleModal(false);
  };

  const onSigWebcamCapture = (image) => {
    setPatientSig(image);
    toggleSigModal(false);
  };

  const previewDoc = () => {
    if (!isFormValid())
      return toast.error(<ErrorToast message="Fill all required fields" />);

    let visualAidsValue = null;
    if (visualAids && lenses) visualAidsValue = 'contactLenses, glasses';
    if (visualAids && !lenses) visualAidsValue = 'glasses';
    if (!visualAids && lenses) visualAidsValue = 'contactLenses';

    const doc = {
      issuerName: clientInformation.name,
      logoImage: clientInformation.logo,
      clientId: userInfo.currentClientId,
      templateId: selectedDoc,
      centerId: clientInformation.centerId || null,
      patientEmail: email,
      type: 'PEME',
      subType: 'PH_PEME',
      passNo,
      firstName,
      middleName,
      lastName,
      ...nationality,
      gender,
      dateOfBirth: moment.utc(birthDate, 'DD/MM/YYYY').toISOString(),
      religion,
      seabookNo,
      position,
      company,
      civilStatus,
      declaration: true,
      examinationDate: moment.utc(lastColorTest, 'DD/MM/YYYY').toISOString(),
      checkedDocs,
      hearingStandards,
      visionMeetsStandards,
      additionalImage: patientPhoto,
      visualAids: visualAidsValue,
      fitForLookout,
      fitnessLimitation,
      hearingSatisfactory,
      visualMeetsStandards,
      aggravatedHealth,
      limitation,
      birthPlace,
      result: isFit,
      issuingAuthority,
      issuedAt: moment.utc(dateOfIssance, 'DD/MM/YYYY').toISOString(),
      date: moment.utc(date, 'DD/MM/YYYY').toISOString(),
      validUntil: moment.utc(expirationDate, 'DD/MM/YYYY').toISOString(),
      physicianName,
      physicianLicense,
      medicalDirector: medicalDirector.name,
      medicalDirectorSignature: medicalDirector.signature,
      physicianSignature: isDocCreator
        ? physicianSelect.signature
        : physician.signature,
      authority,
      city,
      country,
      address,
      zip,
      patientCity,
      patientCountry,
      patientAddress,
      signers: patientSig
        ? [{ name: '', role: 'patient', image: patientSig }]
        : null,
      patientZip,
    };
    setPreviewData(doc);
    togglePreview(true);
  };

  return (
    <div className={isDocCreator ? 'appointment-details__small' : ''}>
      <div className="appointment-details__modal-content__column">
        <div className="appointment-details__modal-content__header">
          Appointment
        </div>
        <div className="appointment-details__modal-content__inputs-wrapper">
          <div className="appointment-details__modal-content__input">
            <Select
              size="fullWidth"
              label="Document template"
              border
              search
              icon={false}
              placeholder="Select document template"
              options={docOptions}
              value={selectedDoc}
              onChange={(e, { value }) => setDocument(value)}
              invalid={errors.template}
            />
          </div>
        </div>
      </div>
      <div className="appointment-details__modal-content__column">
        <div className="appointment-details__modal-content__header">
          Physician
        </div>
        {isDocCreator && (
          <div className="appointment-details__modal-content__inputs-wrapper">
            <div className="appointment-details__modal-content__input">
              <Select
                label="Physician"
                border
                search
                icon={false}
                placeholder="Select a physician"
                options={physicians}
                value={physicianSelect}
                onChange={(e, { value }) => selectPhysician(value)}
                invalid={errors.physicianSelect}
              />
            </div>
          </div>
        )}
        <div className="appointment-details__modal-content__inputs-wrapper">
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Physician name"
              border
              onChange={(e) => setPhysicianName(e.target.value)}
              value={physicianName}
              invalid={errors.firstName}
              placeholder="Physician name"
            />
          </div>

          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Physician license No."
              border
              onChange={(e) => setPhysicianLicense(e.target.value)}
              value={physicianLicense}
              invalid={errors.physicianLicense}
              placeholder="Physician license No."
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Certifying authority"
              border
              onChange={(e) => setAuthority(e.target.value)}
              value={authority}
              invalid={errors.authority}
              placeholder="Certifying authority"
            />
          </div>
        </div>
        <div className="appointment-details__modal-content__column">
          <div className="appointment-details__modal-content__header">
            Authority
          </div>
          <div className="appointment-details__modal-content__inputs-wrapper">
            <div className="appointment-details__modal-content__input">
              <Select
                label="Medical director"
                border
                search
                icon={false}
                size="fullWidth"
                placeholder="Select medical director"
                options={physicians}
                value={medicalDirector}
                onChange={(e, { value }) => setMedicalDirector(value)}
                invalid={errors.medicalDirector}
              />
            </div>
          </div>
          <div className="appointment-details__modal-content__inputs-wrapper">
            <div className="appointment-details__modal-content__input">
              <Input
                size="fullWidth"
                label="Name of issuing authority"
                border
                onChange={(e) => setIssuingAuthority(e.target.value)}
                value={issuingAuthority}
                invalid={errors.issuingAuthority}
                placeholder="Name of issuing authority"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="appointment-details__modal-content__column">
        <div className="appointment-details__modal-content__header">
          Appointment
        </div>
        {isDocCreator && (
          <>
            <div className="appointment-details__modal-content__inputs-wrapper">
              <div className="appointment-details__modal-content__input">
                <Select
                  label="Select address"
                  border
                  search
                  icon={false}
                  options={facilitiesOptions}
                  value={facility}
                  onChange={(e, { value }) => selectFacility(value)}
                  invalid={errors.facility}
                />
              </div>
            </div>
            <div className="appointment-details__modal-content__inputs-wrapper">
              <div className="appointment-details__modal-content__input">
                <Input
                  size="fullWidth"
                  label="Street"
                  border
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  invalid={errors.address}
                  placeholder="Street"
                />
              </div>
              <div className="appointment-details__modal-content__input">
                <Input
                  size="fullWidth"
                  label="Zip"
                  border
                  onChange={(e) => setZip(e.target.value)}
                  value={zip}
                  invalid={errors.zip}
                  placeholder="Zip"
                />
              </div>
              <div className="appointment-details__modal-content__input">
                <Input
                  size="fullWidth"
                  label="City"
                  border
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  invalid={errors.city}
                  placeholder="City"
                />
              </div>
              <div className="appointment-details__modal-content__input">
                <Input
                  size="fullWidth"
                  label="Country"
                  border
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                  invalid={errors.country}
                  placeholder="Country"
                />
              </div>
            </div>
          </>
        )}
        <div className="appointment-details__modal-content__inputs-wrapper">
          <div className="appointment-details__modal-content__input">
            <Cleave
              style={{ paddingLeft: '10px', width: '300px' }}
              label="Examination date"
              size="fullWidth"
              placeholder="DD/MM/YYYY"
              options={{
                date: true,
                datePattern: ['d', 'm', 'Y'],
                delimiter: '/',
              }}
              onChange={(e) => setDate(e.target.value)}
              value={date}
              invalid={errors.date}
            />
          </div>
        </div>
        <div className="appointment-details__modal-content__inputs-wrapper">
          <div className="appointment-details__modal-content__input">
            <Cleave
              style={{ paddingLeft: '10px', width: '300px' }}
              label="Document issuance date"
              size="fullWidth"
              placeholder="DD/MM/YYYY"
              options={{
                date: true,
                datePattern: ['d', 'm', 'Y'],
                delimiter: '/',
              }}
              onChange={(e) => setDateOfIssuance(e.target.value)}
              value={dateOfIssance}
              invalid={errors.dateOfIssance}
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Cleave
              style={{ paddingLeft: '10px', width: '300px' }}
              label="Document expiration date"
              size="fullWidth"
              placeholder="DD/MM/YYYY"
              options={{
                date: true,
                datePattern: ['d', 'm', 'Y'],
                delimiter: '/',
              }}
              onChange={(e) => setExpirationDate(e.target.value)}
              value={expirationDate}
              invalid={errors.expirationDate}
            />
          </div>
          {!isDocCreator && (
            <>
              <div className="appointment-details__modal-content__input">
                <Input
                  size="fullWidth"
                  label="Street"
                  border
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  invalid={errors.address}
                  placeholder="Street"
                />
              </div>
              <div className="appointment-details__modal-content__input">
                <Input
                  size="fullWidth"
                  label="Zip"
                  border
                  onChange={(e) => setZip(e.target.value)}
                  value={zip}
                  invalid={errors.zip}
                  placeholder="Zip"
                />
              </div>
              <div className="appointment-details__modal-content__input">
                <Input
                  size="fullWidth"
                  label="City"
                  border
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  invalid={errors.city}
                  placeholder="City"
                />
              </div>
              <div className="appointment-details__modal-content__input">
                <Input
                  size="fullWidth"
                  label="Country"
                  border
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                  invalid={errors.country}
                  placeholder="Country"
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="appointment-details__modal-content__column">
        <div className="appointment-details__modal-content__header">
          Patient
        </div>
        <div className="appointment-details__modal-content__inputs-wrapper">
          <div className="appointment-details__modal-content__input">
            <div className="appointment-details__loader-input">
              <Input
                size="fullWidth"
                label="Email"
                border
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                invalid={errors.email}
                placeholder="Email"
                onBlur={findUser}
              />
              {findingUser && (
                <>
                  <LinearLoader />
                  <Text
                    size="tiny"
                    className="app-creation__input-wrapper__loader-text"
                  >
                    Searching for patient
                  </Text>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="appointment-details__modal-content__inputs-wrapper">
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="First name"
              border
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              invalid={errors.firstName}
              placeholder="First name"
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Middle name"
              border
              onChange={(e) => setMiddleName(e.target.value)}
              value={middleName}
              invalid={errors.middleName}
              placeholder="Middle name"
            />
          </div>

          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Last name"
              border
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              invalid={errors.lastName}
              placeholder="Last name"
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Select
              label="Nationality"
              border
              search
              icon={false}
              options={countriesList}
              value={nationality}
              onChange={(e, { value }) => setNationality(value)}
              invalid={errors.nationality}
            />
          </div>

          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Place of birth"
              border
              onChange={(e) => setBirthPlace(e.target.value)}
              value={birthPlace}
              invalid={errors.birthPlace}
              placeholder="Birth place"
            />
          </div>

          <div className="appointment-details__modal-content__input">
            <Cleave
              style={{ paddingLeft: '10px', width: '300px' }}
              label="Birth date"
              size="fullWidth"
              placeholder="DD/MM/YYYY"
              options={{
                date: true,
                datePattern: ['d', 'm', 'Y'],
                delimiter: '/',
              }}
              onChange={(e) => setBirthDate(e.target.value)}
              value={birthDate}
              invalid={errors.birthDate}
            />
          </div>

          <div className="appointment-details__modal-content__input">
            <Select
              label="Gender"
              border
              search
              icon={false}
              placeholder="Select gender"
              options={[
                {
                  text: 'Male',
                  value: 'Male',
                },
                {
                  text: 'Female',
                  value: 'Female',
                },
                {
                  text: 'Other',
                  value: 'Other',
                },
                {
                  text: 'Unknown',
                  value: 'Unknown',
                },
              ]}
              value={gender}
              onChange={(e, { value }) => setGender(value)}
              invalid={errors.gender}
            />
          </div>

          <div className="appointment-details__modal-content__input">
            <Select
              label="Civil status"
              border
              search
              size="fullWidth"
              icon={false}
              placeholder="Select civil status"
              options={[
                {
                  text: 'Married',
                  value: 'married',
                },
                {
                  text: 'Single',
                  value: 'single',
                },
              ]}
              value={civilStatus}
              onChange={(e, { value }) => setCivilStatus(value)}
              invalid={errors.civilStatus}
            />
          </div>

          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Religion"
              border
              onChange={(e) => setReligion(e.target.value)}
              value={religion}
              invalid={errors.religion}
              placeholder="Religion"
            />
          </div>

          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Street"
              border
              onChange={(e) => setPatientAddress(e.target.value)}
              value={patientAddress}
              invalid={errors.patientAddress}
              placeholder="Street"
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Zip"
              border
              onChange={(e) => setPatientZip(e.target.value)}
              value={patientZip}
              invalid={errors.patientZip}
              placeholder="Zip"
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="City"
              border
              onChange={(e) => setPatientCity(e.target.value)}
              value={patientCity}
              invalid={errors.patientCity}
              placeholder="City"
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Country"
              border
              onChange={(e) => setPatientCountry(e.target.value)}
              value={patientCountry}
              invalid={errors.patientCountry}
              placeholder="Country"
            />
          </div>

          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Passport no."
              border
              onChange={(e) => setPassNo(e.target.value)}
              value={passNo}
              invalid={errors.passNo}
              placeholder="Passport no."
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Seamans book no."
              border
              onChange={(e) => setSeabook(e.target.value)}
              value={seabookNo}
              invalid={errors.seabookNo}
              placeholder="Seamans book no."
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Position applied for"
              border
              onChange={(e) => setPosition(e.target.value)}
              value={position}
              invalid={errors.position}
              placeholder="Position applied for"
            />
          </div>
          <div className="appointment-details__modal-content__input">
            <Input
              size="fullWidth"
              label="Company"
              border
              onChange={(e) => setCompany(e.target.value)}
              value={company}
              invalid={errors.company}
              placeholder="Company"
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className="appointment-details__patient-photo-wrapper">
            <Text type="label">Patient photo</Text>
            <div
              className={
                errors.patientPhoto
                  ? 'appointment-details__patient-photo appointment-details__patient-photo--error'
                  : 'appointment-details__patient-photo'
              }
            >
              {patientPhoto && <img src={patientPhoto} alt="patient" />}
            </div>
            <Modal
              closeIcon
              size="small"
              trigger={
                <Button
                  size="fullWidth"
                  onClick={() => toggleModal(true)}
                  color="blue"
                  className="mb-2"
                >
                  Take photo via Webcam
                </Button>
              }
              open={isModalToggled}
              onClose={() => toggleModal(false)}
            >
              <Modal.Header>Photo mode</Modal.Header>
              <Modal.Content
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Webcam onCapture={onWebcamCapture} />
              </Modal.Content>
            </Modal>
            <FileUpload
              onSelect={(e) => fileToBase64(e, setPatientPhoto)}
              accept="image/*"
              icon={false}
              label="Upload photo"
            />
          </div>
          <div className="appointment-details__patient-photo-wrapper">
            <Text type="label">Patient signature</Text>
            <div
              className={
                errors.patientSig
                  ? 'appointment-details__patient-signature appointment-details__patient-signature--error'
                  : 'appointment-details__patient-signature'
              }
            >
              {patientSig && <img src={patientSig} alt="patient" />}
            </div>
            <Modal
              size="small"
              trigger={
                <Button
                  size="fullWidth"
                  onClick={() => toggleSigModal(true)}
                  color="blue"
                  className="mb-2"
                >
                  Take photo via Webcam
                </Button>
              }
              open={isSigModalToggled}
              onClose={() => toggleSigModal(false)}
            >
              <Modal.Header>Photo mode</Modal.Header>
              <Modal.Content
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Webcam
                  height={200}
                  width={500}
                  onCapture={onSigWebcamCapture}
                />
              </Modal.Content>
            </Modal>
            <FileUpload
              onSelect={(e) => fileToBase64(e, setPatientSig)}
              accept="image/*"
              icon={false}
              label="Upload signature"
            />
          </div>
        </div>
      </div>
      <div className="appointment-details__modal-content__column">
        <div className="appointment-details__modal-content__header">
          Examination
        </div>
        <div className="appointment-details__modal-content__inputs-wrapper">
          <div className="appointment-details__modal-content__checkbox">
            <DualCheckBox
              label="CONFIRMATION THAT IDENTIFICATION DOCUMENTS WERE CHECKED AT THE POINT OF EXAMINATION"
              value={checkedDocs}
              onSelect={setCheckedDocs}
            />
          </div>
          <div className="appointment-details__modal-content__checkbox">
            <DualCheckBox
              label="HEARING MEETS THE STANDARDS IN STCW CODE, SECTION A-I/9?"
              value={hearingStandards}
              onSelect={setHearingStandards}
            />
          </div>
          <div className="appointment-details__modal-content__checkbox">
            <DualCheckBox
              label="UNAIDED HEARING SATISFACTORY?"
              value={hearingSatisfactory}
              onSelect={setHearingSatisfactory}
            />
          </div>
          <div className="appointment-details__modal-content__checkbox">
            <DualCheckBox
              label="VISUAL ACUITY MEETS STANDARDS IN STCW CODE, TABLE A-I/9?"
              value={visualMeetsStandards}
              onSelect={setVisualMeetsStandards}
            />
          </div>
          <div className="appointment-details__modal-content__checkbox">
            <DualCheckBox
              label="COLOR VISION MEETS STANDARDS IN STCW CODE, TABLE A-I/9?"
              value={visionMeetsStandards}
              onSelect={setVisionMeetsStandards}
            />
            <div style={{ marginTop: '10px' }}>
              <Cleave
                style={{ width: '220px' }}
                label="Date of last color vision test"
                size="small"
                placeholder="DD/MM/YYYY"
                options={{
                  date: true,
                  datePattern: ['d', 'm', 'Y'],
                  delimiter: '/',
                }}
                onChange={(e) => setLastColorTest(e.target.value)}
                value={lastColorTest}
                invalid={errors.lastColorTest}
              />
            </div>
          </div>
          <div className="appointment-details__modal-content__checkbox">
            <div className="dual-check">
              <div className="dual-check__label">
                VISUAL AIDS (tick if worn)
              </div>
              <div className="dual-check__checkboxes">
                <CheckBox
                  label="Spectacles"
                  onClick={() => setVisualAids(!visualAids)}
                  checked={visualAids}
                  style={{ marginBottom: '5px' }}
                  labelClassName="dual-check__checkbox-label"
                />
                <CheckBox
                  label="Contact Lenses"
                  onClick={() => setLenses(!lenses)}
                  checked={lenses}
                  labelClassName="dual-check__checkbox-label"
                />
              </div>
            </div>
          </div>
          <div className="appointment-details__modal-content__checkbox">
            <DualCheckBox
              label="FIT FOR LOOKOUT DUTIES?"
              value={fitForLookout}
              onSelect={setFitForLookout}
            />
          </div>
          <div className="appointment-details__modal-content__checkbox">
            <DualCheckBox
              label="NO LIMITATIONS OR RESTRICTIONS ON FITNESS?"
              value={fitnessLimitation}
              onSelect={setFitnessLimitation}
            />
          </div>
          {!fitnessLimitation && fitnessLimitation !== null && (
            <TextArea
              placeholder="Specify limitations or restrictions"
              style={{ minHeight: 100, paddingRight: '15px' }}
              onChange={(e) => setLimitation(e.target.value)}
              value={limitation}
              invalid={errors.limitation}
            />
          )}
          <div className="appointment-details__modal-content__checkbox">
            <DualCheckBox
              label="IS APPLICANT SUFFERING FROM ANY MEDICAL CONDITIONS LIKELY TO BE AGGRAVATED BY SERVICE AT SEA OR TO RENDER THE SEAFARER UNFIT FOR SUCH SERVICE OR TO ENDANGER THE HEALTH OF OTHER PERSONS ON BOARD?"
              value={aggravatedHealth}
              onSelect={setAggravatedHealth}
            />
          </div>
          <div className="appointment-details__modal-content__checkbox">
            <Select
              label="IS FIT FOR DUTY?"
              border
              search
              icon={false}
              placeholder="Select an option"
              options={[
                { text: 'Fit for duty', value: 'Fit for duty' },
                { text: 'Fit with restriction', value: 'Fit with restriction' },
                { text: 'Unfit for sea duty', value: 'Unfit for sea duty' },
              ]}
              value={isFit}
              onChange={(e, { value }) => setIsFit(value)}
              invalid={errors.isFit}
            />
          </div>
        </div>
        <div className="appointment-details__modal-content__column">
          <div className="appointment-details__modal-content__header">
            Lab Results (upload as PDF)
          </div>
          <div className="appointment-details__modal-content__file-row">
            <FileUpload
              invalid={errors.drugFile}
              onSelect={(e) => fileSelectHandler(e, setDrugFile)}
              accept="application/pdf"
              className="appointment-details__modal-content__file-upload"
              icon={false}
              label="Drug test certificate"
            />
            {drugFile && (
              <Text className="appointment-details__modal-content__file-name">
                {drugFile.name}
              </Text>
            )}
          </div>
          <div className="appointment-details__modal-content__file-row">
            <FileUpload
              invalid={errors.drugScreeningFile}
              onSelect={(e) => fileSelectHandler(e, setDrugScreeningFile)}
              accept="application/pdf"
              className="appointment-details__modal-content__file-upload"
              icon={false}
              label="Drug Lab Results"
            />
            {drugScreeningFile && (
              <Text className="appointment-details__modal-content__file-name">
                {drugScreeningFile.name}
              </Text>
            )}
          </div>
          <div className="appointment-details__modal-content__file-row">
            <FileUpload
              invalid={errors.roentgenologicalFile}
              onSelect={(e) => fileSelectHandler(e, setRoentgenologicalFile)}
              accept="application/pdf"
              className="appointment-details__modal-content__file-upload"
              icon={false}
              label="Roentgenological results"
            />
            {roentgenologicalFile && (
              <Text className="appointment-details__modal-content__file-name">
                {roentgenologicalFile.name}
              </Text>
            )}
          </div>
          <div className="appointment-details__modal-content__file-row">
            <FileUpload
              invalid={errors.hivScreening}
              onSelect={(e) => fileSelectHandler(e, setHivScreening)}
              accept="application/pdf"
              className="appointment-details__modal-content__file-upload"
              icon={false}
              label="HIV screening results"
            />
            {hivScreening && (
              <Text className="appointment-details__modal-content__file-name">
                {hivScreening.name}
              </Text>
            )}
          </div>
          <div className="appointment-details__modal-content__file-row">
            <FileUpload
              invalid={errors.labResults}
              onSelect={(e) => fileSelectHandler(e, setLabResults)}
              accept="application/pdf"
              className="appointment-details__modal-content__file-upload"
              icon={false}
              label="Lab results"
            />
            {labResults && (
              <Text className="appointment-details__modal-content__file-name">
                {labResults.name}
              </Text>
            )}
          </div>
          <div className="appointment-details__modal-content__file-row">
            <FileUpload
              invalid={errors.auditoryResults}
              onSelect={(e) => fileSelectHandler(e, setAuditoryResults)}
              accept="application/pdf"
              className="appointment-details__modal-content__file-upload"
              icon={false}
              label="Auditory results"
            />
            {auditoryResults && (
              <Text className="appointment-details__modal-content__file-name">
                {auditoryResults.name}
              </Text>
            )}
          </div>
        </div>
      </div>

      <div className="appointment-details__modal-content__buttons">
        <Modal
          size="small"
          trigger={
            <Button
              inverted
              size="wide"
              color="green"
              style={{ width: '500px', height: '50px !important' }}
              onClick={previewDoc}
              disabled={isSaving}
            >
              <Icon name="clone outline" /> Preview Document
            </Button>
          }
          open={previewToggled}
          onClose={() => togglePreview(false)}
        >
          <Modal.Content style={{ display: 'flex', justifyContent: 'center' }}>
            <PemeCert
              className="appointment-details__peme-preview"
              certificate={previewData}
            />
          </Modal.Content>
        </Modal>

        <Button
          size="wide"
          color="blue"
          onClick={isDocCreator ? issueCert : completeAppointment}
          disabled={isSaving}
        >
          {isSaving ? (
            <div className="appointment-details__loader-wrapper">
              <Loader active inverted size="tiny" color="white" />
              <div className="appointment-details__loader-wrapper__text">
                {savingDocs
                  ? 'Uploading test documents...'
                  : 'Creating certificate...'}
              </div>
            </div>
          ) : (
            <>
              <Icon name="paper plane outline" />{' '}
              {isDocCreator ? 'Issue' : 'Complete'}
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default PemeAppointment;
