import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
// import fhirValidator from 'js-fhir-validator/r4/js/diagnosticreport'
import {
  ErrorToast,
  Input,
  Select,
  Text,
  Cleave,
  Button,
  PhoneInput,
} from 'components';
import { MedicationInput, AllergyInput, ConditionInput } from 'features';
import { Checkmark } from './components/checkmark/Checkmark';

import PatientService from 'api/patient';
import IdClientService from 'api/client/client';

import nationalities from 'utils/nationalities';
import ethnicities from 'utils/ethnicities';
import { useCustomForm } from 'hooks';
import { schema } from './schema';

import './patientForm.scss';

const PublicPatientForm = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [client, setClient] = useState({});
  const [isRegistered, setIsRegistered] = useState(false);

  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useCustomForm(schema, {
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    nationality: '',
    passNo: '',
    ssn: '',
    address: '',
    zip: '',
    city: '',
    country: '',
    phoneNumber: '',
    email: '',
    ethnicity: '',
    weight: '',
    height: '',
    bloodType: '',
    drugAllergies: [],
    preExisting: [],
    familyHistory: [],
    medication: [],
  });

  console.log('errors', errors);

  useEffect(() => {
    fetchClient();
  }, []); // eslint-disable-line

  //  https://terminology.hl7.org/3.1.0/ValueSet-v3-Race.html

  const fetchClient = async () => {
    const { id } = props.match.params;

    const { data } = await IdClientService.getPublicClientById(id);
    setClient(data.data);
  };

  const createPatient = async (data) => {
    try {
      const { id } = props.match.params;
      setIsSaving(true);

      data.nationality = nationalities.find(
        (nat) => nat.value === data.nationality
      );
      data.country = nationalities.find((nat) => nat.value === data.country);
      data.ethnicity = ethnicities.find((eth) => eth.value === data.ethnicity);
      data.dateOfBirth = moment(data.dateOfBirth, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
      );
      data.clientName = client.name;

      const formattedData = Object.fromEntries(
        Object.entries(data).filter(([_, v]) => v !== null && v !== '')
      );

      await PatientService.createPatient(formattedData, id);
      setIsRegistered(true);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="public-patient-form">
      <div className="public-patient-form__content">
        <form onSubmit={handleSubmit(createPatient)}>
          <div className="patient-form__form">
            <div className="public-patient-form__branding">
              <img
                className="public-patient-form__branding__logo"
                src={client.logo}
                alt="logo"
              />
              <Text className="ml-4" size="huge" dark>
                {client.name}
              </Text>
            </div>
            {isRegistered ? (
              <div className="public-patient-form__registered">
                <Checkmark />
                <Text size="big" dark className="mt-4 mb-2">
                  You have successfully registed
                </Text>
                <Text className="mb-6">
                  Thank you for your time and see you soon!
                </Text>
                <Button
                  color="green"
                  size="fluid"
                  onClick={() => {
                    setIsRegistered(false);
                    reset();
                  }}
                >
                  Register new patient
                </Button>
              </div>
            ) : (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text
                    id="med_booking_register_details.title"
                    type="pageHeader"
                  >
                    Register your details
                  </Text>
                </div>
                <Text size="small" color="grey">
                  Please register your details based on your official
                  information
                </Text>
                <div className="patient-form__form__grid-wrapper">
                  <div className="patient-form__form__grid">
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          border
                          className="patient-form__form__input"
                          label="First Name"
                          placeholder="Jane"
                          size="fullWidth"
                          value={value}
                          invalid={errors?.firstName}
                          errorMessage=""
                          onChange={onChange}
                        />
                      )}
                    />
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          border
                          className="patient-form__form__input"
                          label="Last Name"
                          placeholder="Doe"
                          size="fullWidth"
                          value={value}
                          invalid={errors?.lastName}
                          errorMessage=""
                          onChange={onChange}
                        />
                      )}
                    />
                    <Controller
                      name="gender"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          border
                          label="Gender"
                          placeholder="Female"
                          value={value}
                          onChange={(e, { value }) => onChange(value)}
                          options={[
                            { text: 'Female', value: 'female' },
                            { text: 'Male', value: 'male' },
                            { text: 'Other', value: 'other' },
                            { text: 'Unknown', value: 'unknown' },
                          ]}
                          search
                          size="fullWidth"
                          selection
                          className="patient-form__form__input"
                          invalid={errors?.gender}
                          errorMessage=""
                        />
                      )}
                    />
                    <Controller
                      name="dateOfBirth"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Cleave
                          label="Birth Date"
                          placeholder="DD-MM-YYYY"
                          options={{
                            date: true,
                            datePattern: ['d', 'm', 'Y'],
                            delimiter: '-',
                          }}
                          onChange={onChange}
                          value={value}
                          size="fullWidth"
                          invalid={errors?.dateOfBirth}
                          errorMessage=""
                        />
                      )}
                    />
                    <Controller
                      name="nationality"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label="Nationality"
                          border
                          placeholder="Singapore"
                          value={value}
                          onChange={(e, { value }) => onChange(value)}
                          options={nationalities}
                          search
                          size="fullWidth"
                          selection
                          className="patient-form__form__input"
                          invalid={errors?.nationality}
                          errorMessage=""
                        />
                      )}
                    />
                    <Controller
                      name="ethnicity"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label="Race"
                          border
                          placeholder="Asian"
                          value={value}
                          onChange={(e, { value }) => onChange(value)}
                          // Arab, Caucasian, Chinese, Malay, Indian, Other
                          options={ethnicities}
                          search
                          size="fullWidth"
                          selection
                          className="patient-form__form__input"
                          invalid={errors?.ethnicity}
                          errorMessage=""
                        />
                      )}
                    />
                    <Controller
                      name="ssn"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          border
                          label="NRIC / FIN / SSN / ID / IC No."
                          placeholder="S123456789"
                          size="fullWidth"
                          value={value}
                          onChange={onChange}
                          invalid={errors?.ssn}
                          errorMessage="This field requires 7 - 22 characters"
                        />
                      )}
                    />
                    <Controller
                      name="passNo"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          border
                          className="patient-form__form__input patient-form__form__input"
                          label="Passport No."
                          optional
                          placeholder="P123456789"
                          size="fullWidth"
                          value={value}
                          onChange={onChange}
                          invalid={errors?.passNo}
                          errorMessage="This field requires 7 - 22 characters"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="patient-form__form__grid-wrapper">
                  <Text bold size="big">
                    Contact Details
                  </Text>
                  <div className="patient-form__form__grid">
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          border
                          className="patient-form__form__input"
                          label="Email Address"
                          placeholder="john-doe@riverr.ai"
                          size="fullWidth"
                          value={value}
                          onChange={onChange}
                          invalid={errors?.email}
                          errorMessage=""
                        />
                      )}
                    />
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                          className="patient-form__form__input"
                          border
                          size="fullWidth"
                          label="Phone number"
                          placeholder="+123456789"
                          value={value}
                          optional
                          onChange={onChange}
                          invalid={errors?.phoneNumber}
                          errorMessage=""
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="patient-form__form__grid-wrapper">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text bold size="big">
                      Address
                    </Text>
                    <Text className="ml-3" size="tinniest" color="blue">
                      Optional
                    </Text>
                  </div>
                  <div className="patient-form__form__grid">
                    <Controller
                      name="address"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          border
                          className="patient-form__form__input"
                          label="Address"
                          placeholder="12 John Doe Street"
                          size="fullWidth"
                          value={value}
                          onChange={onChange}
                          invalid={errors?.address}
                          errorMessage=""
                        />
                      )}
                    />
                    <Controller
                      name="zip"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          border
                          className="patient-form__form__input"
                          label="Zip"
                          placeholder="12345"
                          size="fullWidth"
                          value={value}
                          onChange={onChange}
                          invalid={errors?.zip}
                          errorMessage=""
                        />
                      )}
                    />
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          border
                          className="patient-form__form__input"
                          label="City"
                          placeholder="Singapore"
                          size="fullWidth"
                          value={value}
                          onChange={onChange}
                          invalid={errors?.city}
                          errorMessage=""
                        />
                      )}
                    />
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label="Country"
                          border
                          placeholder="Singapore"
                          value={value}
                          onChange={(e, { value }) => onChange(value)}
                          options={nationalities}
                          search
                          size="fullWidth"
                          selection
                          className="patient-form__form__input"
                          invalid={errors?.country}
                          errorMessage=""
                        />
                      )}
                    />
                  </div>
                  <div className="patient-form__form__grid-wrapper">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Text bold size="big">
                        Personal Health Details
                      </Text>
                      <Text className="ml-3" size="tinniest" color="blue">
                        Optional
                      </Text>
                    </div>
                    <div className="patient-form__form__grid">
                      <Controller
                        name="height"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Input
                            border
                            className="patient-form__form__input"
                            label="Height (cm)"
                            placeholder="182"
                            size="fullWidth"
                            value={value}
                            onChange={onChange}
                            invalid={errors?.height}
                          />
                        )}
                      />
                      <Controller
                        name="weight"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Input
                            border
                            className="patient-form__form__input"
                            label="Weight (kg)"
                            placeholder="84"
                            size="fullWidth"
                            value={value}
                            onChange={onChange}
                            invalid={errors?.weight}
                          />
                        )}
                      />
                      <Controller
                        name="smoker"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            label="Smoker"
                            border
                            search
                            size="fullWidth"
                            placeholder="No"
                            invalid={errors?.smoker}
                            value={value}
                            onChange={(e, { value }) => onChange(value)}
                            options={[
                              { text: 'Non-smoker', value: 'Non' },
                              { text: 'Ex-smoker', value: 'Ex' },
                              {
                                text: 'Less than 10 cigarettes per day',
                                value: 'Light',
                              },
                              {
                                text: '10 - 19 cigarettes per day',
                                value: 'Moderate',
                              },
                              {
                                text: '20 or more cigarettes per day',
                                value: 'Heavy',
                              },
                            ]}
                          />
                        )}
                      />
                      <Controller
                        name="bloodType"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            label="Blood Type"
                            border
                            search
                            size="fullWidth"
                            placeholder="O+"
                            value={value}
                            onChange={(e, { value }) => onChange(value)}
                            options={[
                              { text: 'O+', value: 'O+' },
                              { text: 'O-', value: 'O-' },
                              { text: 'A+', value: 'A+' },
                              { text: 'A-', value: 'A-' },
                              { text: 'B+', value: 'B+' },
                              { text: 'B-', value: 'B-' },
                              { text: 'AB+', value: 'AB+' },
                              { text: 'AB-', value: 'AB-' },
                            ]}
                          />
                        )}
                      />
                      <Controller
                        name="familyHistory"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ConditionInput
                            value={value}
                            onChange={(value) => onChange(value)}
                            label="Family history"
                            multiple
                          />
                        )}
                      />
                      <Controller
                        name="preExisting"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ConditionInput
                            value={value}
                            onChange={(value) => onChange(value)}
                            label="Pre-existing Medical Condition(s)"
                            multiple
                          />
                        )}
                      />
                      <Controller
                        name="medication"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <MedicationInput
                            value={value}
                            onChange={(value) => onChange(value)}
                            label="Medication"
                            multiple
                          />
                        )}
                      />
                      <Controller
                        name="drugAllergies"
                        control={control}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <AllergyInput
                            value={value}
                            onChange={(value) => onChange(value)}
                            label="Allergy"
                            multiple
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <Button
                  type="submit"
                  className="mt-5 patient-form__button"
                  size="large"
                  color="blue"
                  isLoading={isSaving}
                >
                  Register
                </Button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(PublicPatientForm);
