import React from 'react';

const SvgProfilePicPlaceholder = props => (
  <svg width="1em" height="1em" viewBox="0 0 88 88" {...props}>
    <defs>
      <path
        d="M44 88c24.3 0 44-19.7 44-44 0-16.202-8.757-30.358-21.796-37.995A43.797 43.797 0 0044 0C19.7 0 0 19.7 0 44s19.7 44 44 44z"
        id="ProfilePicPlaceholder_svg__a"
      />
      <path id="ProfilePicPlaceholder_svg__c" d="M0 .139h72.855V73H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="ProfilePicPlaceholder_svg__b" fill="#fff">
        <use xlinkHref="#ProfilePicPlaceholder_svg__a" />
      </mask>
      <use fill="#FAFAFA" xlinkHref="#ProfilePicPlaceholder_svg__a" />
      <g mask="url(#ProfilePicPlaceholder_svg__b)">
        <g transform="translate(8 15)">
          <path
            d="M30.093 0c-.86 1.34-1.277 2.827-1.47 4.39-.022.18.021.394-.284.382-.8-.031-1.546-.64-1.728-1.427-.019-.08-.043-.16-.084-.31-.197.244-.37.418-.496.621-.47.76-.966 1.506-1.377 2.296-2.006 3.851-3.009 8.018-3.767 12.252-.33 1.843-.445 3.709-.425 5.583.001.137-.101.376-.197.4-1.106.272-1.62 1.067-1.879 2.083-.306 1.202-.22 2.419-.092 3.635.21 2.009 1.008 3.607 2.996 4.417.156.064.298.301.341.483.314 1.315.563 2.647.905 3.955.735 2.812 1.66 5.546 3.359 7.963.38.54.581 1.213.82 1.843.157.41.19.803-.279 1.128-.255.177-.392.539-.554.833-.144.26-.245.542-.369.813-.658 1.433-1.746 2.335-3.28 2.734-.928.242-1.851.518-2.753.843a1042.823 1042.823 0 00-8.84 3.247c-2.144.795-4.296 1.571-6.414 2.429-1.228.497-2.409 1.124-3.37 2.077-.394.392-.674.842-.69 1.424-.05 1.99-.095 3.98-.164 5.97-.016.448.08.854.363 1.17.32.359.675.713 1.08.962.947.578 2.017.786 3.113.793 2.763.018 5.527.008 8.29.008h37.554c6.001 0 12.002.002 18.004-.005.953-.001 1.893-.13 2.781-.497 1.285-.531 1.88-1.565 1.807-2.937a135.674 135.674 0 01-.179-5.238c-.01-.707-.251-1.268-.759-1.722-.332-.297-.668-.612-1.055-.82-1.119-.6-2.23-1.243-3.408-1.703-2.57-1.004-5.178-1.912-7.774-2.846-3.118-1.122-6.172-2.423-9.387-3.27-1.352-.358-2.289-1.243-2.87-2.508-.102-.22-.17-.464-.302-.664-.308-.47-.655-.914-.967-1.382-.084-.127-.16-.31-.137-.45.156-.952.6-1.785 1.103-2.596.455-.735.93-1.467 1.291-2.25 1.282-2.781 2.047-5.73 2.626-8.723.13-.677.401-1.067 1.015-1.338 1.269-.56 1.999-1.583 2.29-2.92.123-.566.228-1.142.276-1.72.099-1.19.147-2.384-.27-3.536-.303-.838-.825-1.463-1.734-1.664-.28-.062-.28-.23-.276-.446.034-2.05-.1-4.088-.45-6.11-.549-3.179-1.527-6.2-3.384-8.88-2.348-3.39-5.568-5.285-9.688-5.655-2.296-.206-4.514-.75-6.59-1.765C31.596.977 30.901.455 30.174 0"
            fill="#1b1b1b"
          />
          <mask id="ProfilePicPlaceholder_svg__d" fill="#fff">
            <use xlinkHref="#ProfilePicPlaceholder_svg__c" />
          </mask>
          <path
            d="M30.115.139c.726.454 1.42.976 2.185 1.35 2.073 1.012 4.287 1.556 6.578 1.761 4.112.369 7.325 2.26 9.668 5.644 1.853 2.676 2.83 5.69 3.379 8.864a32.59 32.59 0 01.448 6.098c-.003.215-.004.383.276.445.906.2 1.428.824 1.73 1.66.417 1.15.368 2.342.27 3.53a13.27 13.27 0 01-.276 1.718c-.29 1.334-1.019 2.355-2.285 2.913-.613.271-.883.66-1.014 1.335-.577 2.988-1.34 5.93-2.62 8.707-.36.781-.834 1.512-1.289 2.246-.502.81-.945 1.64-1.101 2.59-.023.14.053.323.138.45.311.467.657.91.965 1.379.131.2.198.443.3.663.581 1.262 1.515 2.146 2.865 2.502 3.208.847 6.257 2.146 9.368 3.265 2.592.933 5.194 1.839 7.76 2.84 1.175.46 2.283 1.1 3.4 1.7.387.208.722.522 1.053.819.507.453.747 1.013.758 1.719.026 1.743.085 3.486.178 5.227.074 1.37-.52 2.402-1.803 2.932-.886.367-1.825.495-2.775.496-5.99.007-11.98.005-17.97.005H12.824c-2.758 0-5.516.01-8.275-.008-1.093-.007-2.161-.215-3.105-.792-.405-.248-.76-.602-1.079-.96-.282-.315-.378-.72-.362-1.167.069-1.986.114-3.973.164-5.96.015-.58.294-1.029.689-1.42.959-.952 2.137-1.577 3.363-2.074 2.114-.856 4.261-1.63 6.4-2.424 2.938-1.09 5.876-2.177 8.823-3.24.9-.325 1.822-.6 2.749-.842 1.53-.398 2.616-1.299 3.273-2.73.124-.27.225-.551.367-.81.162-.294.299-.655.554-.832.468-.324.434-.715.278-1.126-.238-.628-.44-1.3-.819-1.839-1.695-2.413-2.618-5.14-3.352-7.948-.341-1.305-.59-2.635-.903-3.948-.043-.18-.184-.418-.34-.481-1.984-.808-2.781-2.404-2.99-4.409-.127-1.214-.213-2.428.092-3.628.258-1.014.771-1.807 1.875-2.08.095-.023.197-.261.196-.399-.02-1.87.095-3.732.424-5.572.757-4.226 1.758-8.384 3.76-12.228.41-.789.905-1.534 1.374-2.292.126-.203.299-.376.496-.62.04.15.064.23.083.31.182.784.927 1.392 1.724 1.424.306.012.262-.202.285-.382.191-1.56.608-3.044 1.467-4.381h.08z"
            mask="url(#ProfilePicPlaceholder_svg__d)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgProfilePicPlaceholder;
