import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useCustomForm } from 'hooks';
import * as yup from 'yup';
import { withRouter } from 'react-router';

import { ErrorToast, TextArea, Button, Text } from 'components';
import { AllergyInput } from 'features';

import medicationApi from 'api/patientOverview/medicationApi';

import '../patMedCreators.scss';
import clientAllergyApi from '../../../api/patientOverview/clientApi/allergyApi';

const schema = yup.object().shape({
  allergy: yup.string().required('Allergy is required'),
  reaction: yup.string().required('Date is required'),
});

const AddAllergy = ({ edit, location, onCreate = () => {}, patient }) => {
  const [isSaving, setIsSaving] = useState(false);

  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
  } = useCustomForm(schema, {
    allergy: edit ? location?.state?.data?.name : '',
    reaction: edit ? location?.state?.data?.reaction : '',
  });

  const updatePatient = async (record) => {
    try {
      const allergy = {
        name: record.allergy,
        reaction: record.reaction,
      };
      await clientAllergyApi.addAllergy(patient.id, allergy);
      onCreate();
    } catch (error) {
      console.log(error);
      toast.error(<ErrorToast error={error} />);
    }
  };

  return (
    <div className="pat-med-c">
      <form onSubmit={handleSubmit(updatePatient)}>
        <div className="pat-med-c__form-wrapper">
          <div className="pat-med-c__form-wrapper__row">
            <Controller
              name="allergy"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <AllergyInput
                  value={value}
                  onChange={(value) => onChange(value)}
                  label={
                    <Text
                      type="label"
                      color="inherit"
                      id="patient_overview.allergy"
                    >
                      Allergy
                    </Text>
                  }
                  invalid={errors?.allergy}
                />
              )}
            />
          </div>
          <div className="pat-med-c__form-wrapper__row">
            <Controller
              name="reaction"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  label={
                    <Text
                      color="inherit"
                      type="label"
                      id="patient_overview.reaction_"
                    >
                      Reaction
                    </Text>
                  }
                  placeholder="Rashes, Airway tightens, etc."
                  onChange={(e) => onChange(e.target.value)}
                  showErrorMessage={false}
                  value={value}
                  invalid={errors?.reaction}
                  // disabled={isLabResult && !!issuedTime}
                />
              )}
            />
          </div>
        </div>
        <Button color="blue" size="fullWidth" className="mt-4" type="submit">
          <Text color="inherit" id="settings_lab_grps.create">
            Create
          </Text>
        </Button>
      </form>
    </div>
  );
};

export default withRouter(AddAllergy);
