import React, { useEffect, useState, useCallback } from 'react';
import { Loader } from 'semantic-ui-react';
import { debounce } from 'lodash';

import NormalIcon from '../../assets/range-icons/normal.png';
import HighIcon from '../../assets/range-icons/high.png';
import LowIcon from '../../assets/range-icons/low.png';
import DetectedIcon from '../../assets/range-icons/detected.png';
import NotDetectedIcon from '../../assets/range-icons/not-detected.png';

import './healthValueRange.scss';

const values = {
  borderline: { name: 'Borderline', color: 'orange', icon: HighIcon },
  desirable: { name: 'Desirable', color: 'green', icon: NormalIcon },
  high: { name: 'High', color: 'red', icon: HighIcon },
  microalbuminuria: { name: 'Microalbuminuria', color: 'red', icon: HighIcon },
  macroalbuminuria: { name: 'Macroalbuminuria', color: 'red2', icon: HighIcon },
  criticalhigh: { name: 'Very High', color: 'red2', icon: HighIcon },
  veryhigh: { name: 'Very High', color: 'red2', icon: HighIcon },
  verylow: { name: 'Very Low', color: 'orange', icon: LowIcon },
  lowrisk: { name: 'Low Risk', color: 'greenish2', icon: NormalIcon },
  averagerisk: { name: 'Average Risk', color: 'green', icon: NormalIcon },
  highrisk: { name: 'High Risk', color: 'red', icon: HighIcon },
  low: { name: 'Low', color: 'yellow', icon: LowIcon },
  optimal: { name: 'Optimal', color: 'greenish', icon: NormalIcon },
  suboptimal: { name: 'subOptimal', color: 'yellow', icon: LowIcon },
  normal: { name: 'Normal', color: 'green', icon: NormalIcon },
  sufficient: { name: 'Sufficient', color: 'green', icon: NormalIcon },
  ideal: { name: 'Ideal', color: 'greenish2', icon: NormalIcon },
  acceptable: { name: 'Acceptable', color: 'green', icon: DetectedIcon },
  unacceptable: { name: 'Unacceptable', color: 'red', icon: NotDetectedIcon },
  negative: { name: 'Negative', color: 'green', icon: NotDetectedIcon },
  positive: { name: 'Positive', color: 'red', icon: DetectedIcon },
  abnormal: { name: 'Abnormal', color: 'red', icon: HighIcon },
  'non-reactive': {
    name: 'Non-Reactive',
    color: 'green',
    icon: NotDetectedIcon,
  },
  reactive: { name: 'Reactive', color: 'red', icon: DetectedIcon },
  nil: { name: 'Nil', color: 'green', icon: NotDetectedIcon },
  trace: { name: 'Trace', color: 'orange', icon: DetectedIcon },
  deficient: { name: 'Trace', color: 'red', icon: DetectedIcon },
  insufficient: { name: 'Trace', color: 'orange', icon: DetectedIcon },
};

const HealthValueRange = ({ observation, isLoading, isLight }) => {
  // const ranges = [{color: 'green', range: [0,50]}, {color: 'yellow', range: [50,100]}, {color: 'red', range: [100,150]}]
  const [color, setColor] = useState('');
  const [leftOffsetValue, setLeftOffsetValue] = useState(0);
  const [totalLineLength, setTotalLineLength] = useState(0);
  const [ranges, setRanges] = useState([]);
  const [screenWidth, setScreenWidth] = useState(0);
  const [icon, setIcon] = useState(null);
  const [isShowLineRange, setShowLineRange] = useState(false);
  const [dotSectionIndex, setDotSectionIndex] = useState(0);

  useEffect(() => {
    anomalySetup(observation.anomalyResult, observation.anomalyResult.status);
    showLineRange(observation);
  }, [totalLineLength]);

  useEffect(() => {
    window.addEventListener('resize', onScreenResize, false);

    return () => {
      window.removeEventListener('resize', onScreenResize);
    };
  }, []);

  const onScreenResize = useCallback(
    debounce(() => setScreenWidth(window.innerWidth), 300),
    []
  );

  const measuredRef = useCallback(
    (node) => {
      if (node !== null) {
        setTotalLineLength(node.getBoundingClientRect().width);
      }
    },
    [screenWidth]
  );

  const anomalySetup = (anomaly, status) => {
    if (!anomaly) return;
    const range = [];

    const anomalyRange = anomaly.extractedRanges;

    if (status) {
      // Get the General anomaly status: low, normal, high
      const formattedStatus = status
        ?.replace('Range', '')
        ?.replace(' ', '')
        ?.toLowerCase();
      setColor(values[formattedStatus]?.color);
      setIcon(values[formattedStatus]?.icon);
    }

    anomalyRange.forEach((anom) => {
      // Get the Precise anomaly status: low, normal, high
      const formattedAnomStatus = anom.status
        ?.replace('Range', '')
        ?.replace(' ', '')
        ?.toLowerCase();

      range.push({
        color: values[formattedAnomStatus]?.color || 'blue',
        range: anom.values,
        name: anom.status,
      });
    });

    if (range.length === 0) return;
    calculatePosition(range);
    setRanges(range);
  };

  const calculatePosition = (rangez) => {
    // This will calculate the position of the dot based on the line and section the dot is in
    let valueSection; // Which section is the value in
    let sectionLength; // Length of a single section
    let sectionRange; // Value range of the section the dot is in
    let valueSectionIndex; // Which section is the value in
    let relativePositionInSection; // Which section is the value in
    let lastValue;

    const actualValue = Number(observation.value);

    // Yo alfred check this out
    if (!actualValue && actualValue !== 0) return;
    rangez.forEach((range, index) => {
      if (
        actualValue >= Number(range.range[0]) &&
        actualValue <= Number(range.range[1])
      ) {
        valueSection = range;
        valueSectionIndex = index;
      }
    });

    if (rangez[rangez.length - 1].range[1] < actualValue) {
      valueSectionIndex = rangez.length - 1;
      valueSection = rangez[rangez.length - 1];
    }
    if (rangez[0].range[0] > actualValue) {
      valueSectionIndex = 0;
      valueSection = rangez[0];
    }
    lastValue = valueSection.range[1];
    if (Number(lastValue) === 99999) {
      lastValue = Number(valueSection.range[0]) + Number(valueSection.range[0]);
    }
    sectionRange = lastValue - valueSection.range[0];
    sectionLength = totalLineLength / rangez.length;
    const relativeValue = actualValue - valueSection.range[0];
    relativePositionInSection = (relativeValue / sectionRange) * sectionLength;
    relativePositionInSection = relativePositionInSection - 25;
    if (relativePositionInSection > sectionLength)
      relativePositionInSection = sectionLength - 25;
    if (relativePositionInSection < -25) relativePositionInSection = -25;
    // if (relativePositionInSection === 0) relativePositionInSection = -25;
    setColor(valueSection.color);
    setDotSectionIndex(valueSectionIndex);
    setLeftOffsetValue(relativePositionInSection);
  };

  const showLineRange = (obs) => {
    let showRange = false;
    if (obs?.anomalyResult?.extractedRanges?.length > 0) showRange = true;

    setShowLineRange(showRange);
  };


  return (
    <div
      key={observation.id}
      className={`hvr ${isLight ? 'hvr--light' : ''} hvr--${
        observation?.anomalyResult?.status || observation.status
      }`}
    >
      {isLoading ? (
        <div className="hvr__loader">
          <Loader active size="small" />
        </div>
      ) : (
        <>
          <div
            className={
              isShowLineRange
                ? 'hvr__text-wrapper'
                : 'hvr__text-wrapper hvr__text-wrapper--no-line'
            }
          >
            <div className="hvr__text-wrapper__name-wrapper">
              <div className="hvr__text-wrapper__name">{observation.display}</div>
              {/* {observation?.anomalyResult?.status && <div className={`hvr__text-wrapper__status hvr__text-wrapper__status--${color}`}>{observation.anomalyResult.status}</div>} */}
            </div>
          </div>
          <div
            className={
              isShowLineRange
                ? 'hvr__line-wrapper'
                : 'hvr__line-wrapper hvr__line-wrapper--no-line'
            }
          >
            <div className="hvr__text-wrapper__unit-wrapper">
              {/* {icon && (
                <img
                  className="hvr__text-wrapper__icon"
                  src={icon}
                  alt="icon"
                />
              )} */}
              <div
                className={`hvr__text-wrapper__value hvr__text-wrapper__value--${color}`}
              >
                {/* yo alfred check this out */}
                {observation.value &&
                  String(observation.value)?.replace('&amp;', '&')
                    ?.replace('&Amp;', '&')
                    ?.replace('&lt;', '<')
                    ?.replace('&Lt;', '<')
                    ?.replace('&gt;', '>')
                    ?.replace('&Gt;', '>')
                    ?.replace('&amp;', '&')
                    ?.replace('&Amp;', '&')
                    ?.replace('&lt;', '<')
                    ?.replace('&Lt;', '<')
                    ?.replace('&gt;', '>')
                    ?.replace('&Gt;', '>')}
              </div>
              <div className="hvr__text-wrapper__unit">{observation.unit}</div>
            </div>
            {isShowLineRange && (
              <div ref={measuredRef} className="hvr__line">
                <div className="hvr__line__section-wrapper">
                  {ranges.length > 0 &&
                    ranges.map((range, index) => (
                      <div
                        key={range.range[0]}
                        className={`hvr__line__section hvr__line__section--${range.color}`}
                      >
                        {index === dotSectionIndex && (
                          <div
                            className="hvr__value"
                            style={{ left: leftOffsetValue }}
                          >
                            <div
                              className={`hvr__value__square hvr__value__square--${color}`}
                            >
                              {/* yo alfred check this out */}
                              {String(observation.value)
                                ?.replace('&amp;', '&')
                                ?.replace('&Amp;', '&')
                                ?.replace('&lt;', '<')
                                ?.replace('&Lt;', '<')
                                ?.replace('&gt;', '>')
                                ?.replace('&Gt;', '>')
                              }
                            </div>
                            <div
                              className={`hvr__value__line hvr__value__line--${color}`}
                            />
                            <div
                              className={`hvr__value__dot hvr__value__dot--${color}`}
                            />
                          </div>
                        )}
                        {index + 1 !== ranges.length && (
                          <>
                            <div className="hvr__line__section__text">
                              {range.range[1]}
                            </div>
                          </>
                        )}
                        <div className="hvr__line__section__name">
                          {range.name}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default HealthValueRange;
