function pickNotNull(obj) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

const pick = {
  pickNotNull,
};

export default pick;
