import React from 'react';

const SvgClock = props => (
  <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#1b1b1b" strokeWidth={3}>
      <circle cx={13} cy={13} r={11} />
      <path strokeLinecap="round" d="M12 6v7.627L15 18" />
    </g>
  </svg>
);

export default SvgClock;
