function generateBundleGetResources(resourceUrl = []) {
  if (!resourceUrl.length) {
    return null;
  }
  const entry = resourceUrl.map((url) => {
    return {
      request: {
        method: 'GET',
        url,
      },
    };
  });

  return {
    resourceType: 'Bundle',
    type: 'batch',
    entry,
  };
}

const bundleResourceRequest = {
  generateBundleGetResources,
};

export default bundleResourceRequest;
