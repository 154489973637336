import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { PageHeader, PageContent } from '../components';

const Page = props => {
  return (
    <PageContainer className={props.className}>
      {!!props.header && <PageHeader>{props.header}</PageHeader>}
      <PageContent>
        <PageContentWapper style={props.style}>
          {props.children}
        </PageContentWapper>
      </PageContent>
    </PageContainer>
  );
};

Page.propTypes = {
  style: PropTypes.object,
  header: PropTypes.string,
  children: PropTypes.node,
};

Page.defaultProps = {
  clickHandler: () => {},
  checked: true,
  label: '',
};

const PageContainer = styled.div`
  position: relative;
  max-width: 1400px;
  min-width: 800px;
  width: 100%;
  margin: auto;
  margin-bottom: 25px;
  margin-top: 0;
`;

const PageContentWapper = styled.div`
  background: white;
  box-shadow: 0px 0px 3px 0px #d8d8d88a !important;
  width: 100%;
  border-radius: 10px;
  min-height: 100px;
  padding: 40px 40px;
  @media only screen and (max-width: 855px) {
    padding: 20px 25px;
  }
`;

export default Page;
