import React from 'react';
import { withRouter } from 'react-router-dom';

import FftCreator from '../../../appointmentPages/appointmentDetails/components/FftAppointment';

//components
import { Page } from '../../../../components';

import './certificateCreation.scss';

// TODO: Split this monster component up
const FtfCertificate = (props) => {
  return (
    <div className="certificate-creation__content">
      <Page style={{ paddingTop: '15px' }}>
        <FftCreator
          subType={props.subType}
          setRecentDocs={props.setRecentDocs}
          isDocCreator={true}
        />
      </Page>
    </div>
  );
};

export default withRouter(FtfCertificate);
