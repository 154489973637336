import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Sidebar, Menu } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import { signOutUser } from 'auth/authentication';

import { Icon, SideBarMenu, Text } from '../../components';
import history from '../../history';

import ResultsIcon from '../../assets/new/medicalBriefcase';
import PatientsIcon from '../../assets/new/patients';
import AppointmentsIcom from '../../assets/new/calendar';
import SettingsIcon from '../../assets/new/settings';
import MedicalFolder from '../../assets/new/medicalFolder';

import signoutSvg from '../../assets/new/signout.svg';
import editSvg from '../../assets/new/edit.svg';

import '../SideBar/sideBar.scss';

const SideBar = (props) => {
  const sidebarRef = useRef();
  // const sidebarToggleButtonRef = useRef();
  const subMenuRef = useRef();

  const [isSidebarToggled, setIsSidebarToggled] = useState(false);
  const [selectedTab, setSelectedTab] = useState('/results');
  const [subMenuItems, setSubMenuItems] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const {
    user: userInfo,
    clientInformation,
    showSideBar,
  } = useSelector((state) => state.general);

  // const toggleSidebar = e => {
  //   setIsSidebarToggled(!isSidebarToggled);
  // };

  useEffect(() => {
    const getSelectedTab = () => {
      const splitPath = history.location.pathname.split('/');
      if (splitPath[1] === 'results') return '/results';
      if (splitPath[1] === 'insights') return '/insights';
      if (splitPath[1] === 'calendar') return '/calendar';
      if (splitPath[1] === 'certificates') return '/certificates';
      if (splitPath[1] === 'course') return '/course/planned';
    };
    setSelectedTab(getSelectedTab());

    const handleClick = (e) => {
      if (
        sidebarRef.current.contains(e.target) ||
        // sidebarToggleButtonRef.current.contains(e.target) ||
        (subMenuRef.current && subMenuRef.current.contains(e.target))
      ) {
        return;
      }
      setIsSidebarToggled(false);
    };
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []); // eslint-disable-line

  const navigationItems = [
    {
      id: 'patient_app.health_wallet',
      title: 'Health Wallet',
      icon: ResultsIcon,
      urlKey: '/p',
    },
    {
      id: 'patient_app.sharing_records',
      title: 'Sharing Records',
      icon: MedicalFolder,
      urlKey: '/share',
    },
    // {
    //   title: 'Appointments',
    //   icon: AppointmentsIcom,
    //   urlKey: '/p/appointments',
    // TODO: implement disabled style
    //   disabled: true,
    // },
    // {
    //   title: 'Payment',
    //   icon: PatientsIcon,
    //   urlKey: '/payment',
    // TODO: implement disabled style
    //   disabled: true,
    // },
    {
      id: 'patient_app.settings',
      title: 'Settings',
      icon: SettingsIcon,
      urlKey: '/settings',
    },
  ];

  const containerClassNames = classNames('side-bar__container', {
    'side-bar__container--open': showSideBar,
    'side-bar__container--closed': !showSideBar,
  });

  const signUserOut = () => {
    console.log('er signUserOut');
    signOutUser(true);
  };

  const selectMenuItem = (item) => {
    item.subMenu && setSubMenuItems(item.subMenu);
    !item.subMenu && setSubMenuItems([]);

    if (typeof item.redirect !== 'undefined') return;
    props.history.push(item.redirectUrl || item.urlKey);
    setSelectedTab(item.urlKey);
    if (isMobile) props.setSideBarState(false);
  };

  const splitPath = history.location.pathname.split('/');

  // TODO: move this to useEffect
  const renderItems = () => {
    let items = [];

    if (
      userInfo?.email?.includes('@riverr.ai') ||
      clientInformation?.clientId === 'zHAzw9fw2H4cGE8udj50'
    ) {
      const item = {
        title: 'Accounts',
        icon: 'settings',
        urlKey: '/create-account',
        clientRole: { full: true, lab: true },
        type: 'medical',
      };

      navigationItems.push(item);
    }

    navigationItems.forEach((item, index) => {
      if (
        (!item.role || item.role[userInfo.role]) &&
        (!item.clientRole || item.clientRole[clientInformation.role]) &&
        (!item.type || item.type === clientInformation.type)
      )
        items.push(
          <div
            className={
              `/${splitPath[1]}` === item.urlKey
                ? 'side-bar__item side-bar__item--selected'
                : 'side-bar__item'
            }
            key={item.urlKey}
            onClick={() => selectMenuItem(item)}
          >
            <div className="side-bar__item__icon-wrapper">
              <div
                style={{
                  width: 22,
                  minWidth: 22,
                  maxWidth: 22,
                  height: 22,
                  minWeight: 22,
                  maxWeight: 22,
                  margin: 'auto',
                  marginBottom: '5px',
                }}
              >
                <item.icon
                  color={
                    `/${splitPath[1]}` === item.urlKey ? '#fff' : '#6f6f6f'
                  }
                />
              </div>
              <Text
                id={item?.id}
                className="side-bar__item__title"
                bold
                color={`/${splitPath[1]}` === item.urlKey ? '#fff' : '#6f6f6f'}
              >
                {item.title}
              </Text>
            </div>
          </div>
        );
    });

    return items;
  };

  return (
    <Sidebar.Pushable as="section" className="s-side-bar">
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        vertical
        visible={showSideBar}
      >
        <div className="side-bar">
          <div className={containerClassNames} ref={sidebarRef}>
            <div className="side-bar__content">{renderItems()}</div>
            <div className="side-bar__bottom-content">
              <div
                className="side-bar__item"
                onClick={() => props.setIsModalToggled(true)}
              >
                <div className="side-bar__item__icon-wrapper">
                  {/* <item.icon color='#6f6f6f'/> */}
                  <img src={editSvg} />
                  <Text
                    id="forget_pw.reset_password"
                    className="side-bar__item__title"
                    bold
                    color="#6f6f6f"
                  >
                    Reset password
                  </Text>
                </div>
              </div>
              <div className="side-bar__item" onClick={() => signUserOut()}>
                <div className="side-bar__item__icon-wrapper">
                  {/* <item.icon color='#6f6f6f'/> */}
                  <img src={signoutSvg} />
                  <Text className="side-bar__item__title" bold color="#6f6f6f">
                    Log out
                  </Text>
                </div>
              </div>
            </div>
          </div>
          {subMenuItems.length > 0 && (
            <div className="side-bar__sub-menu">
              <SideBarMenu
                clientType={clientInformation.type}
                role={userInfo.role}
                subMenuRef={subMenuRef}
                sidebarRef={sidebarRef}
                history={props.history}
                items={subMenuItems}
                closeMenu={() => setSubMenuItems([])}
                location={history.location}
                setIsSidebarToggled={setIsSidebarToggled}
              />
            </div>
          )}
        </div>
      </Sidebar>
    </Sidebar.Pushable>
  );
};

export default withRouter(SideBar);
