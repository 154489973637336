import React, { useState, createContext } from 'react';
import { IntlProvider } from 'react-intl';
import English from '../lang/en.json';
import Bahasa from '../lang/id.json';

const LangContext = createContext('en');
const local = 'EN';

let lang;
if (local === 'EN') {
  lang = English;
} else {
  if (local === 'ID') {
    lang = Bahasa;
  } else {
    lang = English;
  }
}

const Wrapper = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);

  function selectLanguage(newLocale) {
    setLocale(newLocale);
    if (newLocale === 'EN') {
      setMessages(English);
    } else {
      if (newLocale === 'ID') {
        setMessages(Bahasa);
      } else {
        setLocale('EN');
        setMessages(English);
      }
    }
  }
  return (
    <LangContext.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </LangContext.Provider>
  );
};

export { LangContext };
export default Wrapper;
