import gql from 'graphql-tag';

export const signInUser = gql`
  mutation adminLogin($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      success
      message
      admin {
        id
        email
        name
        lastLogin
        role
        centerId
        instructorId
      }
    }
  }
`;

export const platoLogin = gql`
  mutation platoLogin($token: String, $databaseName: String) {
    platoLogin(token: $token, databaseName: $databaseName)
  }
`;

export const adminForgotPassword = gql`
  mutation adminForgotPassword($email: String!) {
    adminForgotPassword(email: $email) {
      success
      message
    }
  }
`;

export const adminResetPassword = gql`
  mutation adminResetPassword(
    $email: String!
    $token: String!
    $password: String!
  ) {
    adminResetPassword(email: $email, token: $token, password: $password) {
      success
      message
    }
  }
`;

export const adminActivate = gql`
  mutation adminActivate($email: String!, $token: String!, $password: String!) {
    adminActivate(email: $email, token: $token, password: $password) {
      success
      message
    }
  }
`;

export const signOutUser = gql`
  mutation adminLogout($id: ID!) {
    adminLogout(id: $id)
  }
`;

export const userChangePassword = gql`
  mutation adminChangePassword(
    $email: String!
    $oldPassword: String!
    $newPassword: String!
  ) {
    adminChangePassword(
      email: $email
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      success
      message
    }
  }
`;

export const inviteUser = gql`
  mutation adminAdd(
    $name: String!
    $email: String!
    $clientId: ID
    $role: String!
    $centerId: ID
  ) {
    adminAdd(
      name: $name
      email: $email
      clientId: $clientId
      role: $role
      centerId: $centerId
    ) {
      success
      message
    }
  }
`;

export const deleteUser = gql`
  mutation adminDelete($id: ID!) {
    adminDelete(id: $id) {
      success
      message
    }
  }
`;

export const appUserActivate = gql`
  mutation userApprove($id: ID!) {
    userApprove(id: $id) {
      success
      message
    }
  }
`;

export const appUserDelete = gql`
  mutation userDelete($id: ID!) {
    userDelete(id: $id) {
      success
      message
    }
  }
`;

export const appUserReject = gql`
  mutation userReject($id: ID!) {
    userReject(id: $id) {
      success
      message
    }
  }
`;
