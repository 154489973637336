import React, { useState } from 'react';
import { Icon as SIcon } from 'semantic-ui-react';

import { Text } from 'components';

import './yesOrNo.scss';

const YesOrNo = ({ onChange, value }) => {
  return (
    <div className="yes-or-no">
      <Text
        className={`yes-or-no__yesno ${value === 'Yes' ? 'active' : ''}`}
        onClick={() => {
          if (value !== 'Yes') onChange('Yes');
        }}
      >
        <SIcon name="check circle outline" className="mb-2" />
        Yes
      </Text>
      <Text
        className={`yes-or-no__yesno ${value === 'No' ? 'active' : ''}`}
        onClick={() => {
          if (value !== 'No') onChange('No');
        }}
      >
        <SIcon name="times circle outline" className="mb-2" />
        No
      </Text>
    </div>
  );
};
export default YesOrNo;
