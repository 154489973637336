import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ErrorToast,
} from 'components';

import { useCustomForm } from 'hooks';
import { schema } from './schema';

import languages from 'helpers/languages';
import EmergencyContact from '../components/emergencyContact/emergencyContact';
import EditEmergencyContact from '../components/editEmergencyContact/editEmergencyContact';
import SuccessModal from '../components/successModal/successModal';
import completeEmergencySvg from 'assets/rhc/emergencyContact/emergency-success.svg';
import ViewEmergencyContact from '../components/viewEmergencyContact/viewEmergencyContact';

import './emergencyContactForm.scss';

const EmergencyContactForm = ({
  details,
  updateDetails,
  saveChanges,
  backPath,
  toggle,
  setToggle,
  isSaving,
}) => {
  const [emerStep, setEmerStep] = useState(0);

  const {
    formState: { errors },
  } = useCustomForm(schema, {
    relationship: details.relationship || '',
    fullName: details.fullName || '',
    title: details.title || '',
    emphoneNumber: details.emphoneNumber || '',
    emlanguage: details.emlanguage || '',
  });

  useEffect(() => {
    if (details?.emlanguage && details?.relationship) setEmerStep(3);
    if (Object.keys(errors).length > 0)
      toast.error(<ErrorToast message="Please fill out all required fields" />);
  }, [errors]);

  const onNextClick = async () => {
    setEmerStep(3);
    setToggle(false);
  };

  return (
    <>
      {toggle && (
        <SuccessModal
          isToggled={toggle}
          setToggled={setToggle}
          headerId="patient_app.emergency_hooray"
          header="Hooray!"
          headerSubText="Your Emergency ID is now ready."
          subHeaderId="patient_app.emergency_id_ready"
          icon={completeEmergencySvg}
          textId="patient_app.emergency_congrats"
          text="Congratulations on setting up your Emergency Medical ID! You've taken a vital step towards ensuring your safety and well-being."
          buttonText={'View now'}
          buttonTextId="patient_app.emergency_view_now"
          onButtonClick={() => onNextClick(3)}
        />
      )}
      {emerStep === 0 && (
        <EmergencyContact
          backPath="/settings"
          onButtonClick={() => setEmerStep(1)}
        />
      )}
      {emerStep === 1 && (
        <>
          <EditEmergencyContact
            details={details}
            updateDetails={updateDetails}
            languages={languages}
            onNextClick={saveChanges}
            isSaving={isSaving}
          />
        </>
      )}
      {emerStep === 3 && (
        <ViewEmergencyContact
          details={details}
          onClickEdit={() => setEmerStep(1)}
        />
      )}
    </>
  );
};

export default withRouter(EmergencyContactForm);
