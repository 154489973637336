import React, { useEffect, useState } from 'react';
import { Text } from 'components';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

import HealthValueLine from 'features/HealthValueLine/HealthValueLine';

import PageHeader from 'pages/patientPages/components/PageHeader/PageHeader';

import './riskAssessment.scss';

const ranges1 = [
  {
    text: 'Low',
    range: [0, 10],
    subText: '<10%',
    color: 'green',
  },
  {
    text: 'Intermediate',
    range: [11, 20],
    subText: '<19%',
    color: 'orange',
  },
  { text: 'High', range: [21, 100], subText: '>20%', color: 'red' },
];

const RiskAssessmentHistory = ({
  modelName,
  data: iData,
  dataHistory = [],
  icon,
  label,
  isPreview,
  clientView,
}) => {
  const [data, setData] = useState({});
  const [ranges, setRanges] = useState(ranges1);

  useEffect(() => {
    if (!iData) return;
    const formattedData = { ...iData };
    formattedData.score = Math.round(iData.score * 1000) / 10;
    setData(formattedData);
    formatRanges(formattedData.reference_range);
  }, [iData]);

  const formatRanges = (ranges) => {
    if (!ranges) return {};
    const rangez = Object.keys(ranges).map((key, index) => {
      const color = getColor(key.toLowerCase()).color;
      const values = [
        Math.round(ranges[key][0] * 10000) / 100,
        Math.round(ranges[key][1] * 10000) / 100,
      ];

      return {
        text: key,
        range: values,
        subText:
          index === Object.keys(ranges).length - 1
            ? values[0] + ' - ' + values[1] + '%'
            : '<' + values[1] + '%',
        color,
      };
    });

    setRanges(rangez);
  };

  const getLabel = () => {
    switch (modelName) {
      case 'cardiovascular':
        return 'Cardiovascular Disease';
      case 'hypertension':
        return 'Hypertension';
      case 'coronaryHeartDisease':
        return 'Coronary Heart Disease';
      case 'liver':
        return 'Non-Alcoholic Fatty Liver';
      case 'diabetes':
        return 'Diabetes';
      default:
        return label || '';
    }
  };

  const getColor = (status) => {
    if (status === 'low' || status === 'normal')
      return { color: 'green', colorCode: '#00c48c' };
    if (status === 'borderline')
      return { color: 'yellow', colorCode: '#FFD443' };
    if (status === 'intermediate')
      return { color: 'orange', colorCode: '#ffaf3e' };
    return { color: 'red', colorCode: '#ff6666' };
  };

  return (
    <div>
      <PageHeader
        titleId={''}
        title={'Your Assessment History'}
        className="mt-5"
      />
      {dataHistory.map((historyItem) => (
        <div
          className={`risk-model ${clientView ? 'risk-model--small' : ''} mb-3`}
        >
          <div className="risk-model__header">
            {/* <img className="risk-model__header__icon" alt="ic" src={icon} /> */}
            <div className="risk-model__header__text">
              {/* <Text dark size="big" className="mb-1">
                {getLabel()}
              </Text> */}
              {/* <Text size="tiny" bold>
                {data.period} risk forecast based on {data.age_group} age group
              </Text> */}
              <Text size="big" dark>
                {moment(historyItem?.updatedAt).format('LL')}
              </Text>
            </div>
          </div>
          <div
            className="risk-model__graph-wrapper"
            style={{ marginTop: '12px' }}
          >
            <div
              className={`risk-model__square-value risk-model__square-value--${
                (isPreview ? 'border-' : '') +
                getColor(historyItem?.status?.toLowerCase()).color
              }`}
            >
              <Text
                color={
                  isPreview
                    ? getColor(historyItem?.status?.toLowerCase()).colorCode
                    : 'white'
                }
                size="tiny"
                style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
              >
                Your risk is
              </Text>
              <div className="risk-model__square-value__pct">
                <Text
                  color={
                    isPreview
                      ? getColor(historyItem?.status?.toLowerCase()).colorCode
                      : 'white'
                  }
                  dark
                  size="huge"
                >
                  {Math.round(historyItem.score * 1000) / 10}
                </Text>
                <Text
                  color={
                    isPreview
                      ? getColor(historyItem?.status?.toLowerCase()).colorCode
                      : 'white'
                  }
                  bold
                >
                  %
                </Text>
              </div>
              <Text
                color={
                  isPreview
                    ? getColor(historyItem?.status?.toLowerCase()).colorCode
                    : 'white'
                }
                size="big"
                dark
              >
                {historyItem?.status || 'N/A'}
              </Text>
            </div>
            <div className="risk-model__graph">
              <HealthValueLine
                value={{
                  value: Math.round(historyItem.score * 1000) / 10,
                  text: 'normal',
                  unit: '%',
                }}
                ranges={ranges}
                color={getColor(historyItem?.status?.toLowerCase()).color}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RiskAssessmentHistory;
