import React from 'react';
import styled from 'styled-components';

const Card = props => {
  return (
    <Container style={props.style} margin={props.margin}>
      {props.children}
    </Container>
  );
};

const Container = styled.div`
  box-shadow: 0 0 7px 0 rgba(201, 201, 201, 0.5411764705882353);
  position: relative;
  min-width: 100px;
  border-radius: 10px;
  background: white;
  display: flex;
`;

export default Card;
