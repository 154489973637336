import { patientOverviewClient } from './patientOverviewClient';
import pick from '../../utils/pick';

async function getAll({ search, offset, limit }) {
  try {
    const requestBody = pick.pickNotNull({
      search,
      offset,
      limit,
    });
    const profileResponse = await patientOverviewClient.post(
      'medicalProfile/public/allergies/listAll',
      requestBody
    );
    return profileResponse.data?.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getAllergiesOfPatient() {
  try {
    const profileResponse = await patientOverviewClient.post(
      'medicalProfile/getByUser',
      {
        fields: ['allergyList'],
      }
    );
    return profileResponse.data?.data?.profile?.allergyList || [];
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function addAllergy(allergy) {
  const response = await patientOverviewClient.post('medicalProfile/create', {
    allergy,
  });

  return response.data;
}

async function deleteAllergy(id) {
  const response = await patientOverviewClient.post(
    'medicalProfile/allergies/delete',
    {
      id,
    }
  );

  return response.data;
}

async function updateAllergy(allergy) {
  const response = await patientOverviewClient.post(
    'medicalProfile/allergies/update',
    allergy
  );

  return response.data;
}

const allergyApi = {
  getAll,
  getAllergiesOfPatient,
  addAllergy,
  deleteAllergy,
  updateAllergy,
};

export default allergyApi;
