// import React from 'react';

// const SvgProfile = props => (
//   <svg width="1em" height="1em" viewBox="0 0 24 27" {...props}>
//     <g
//       transform="translate(2 2)"
//       stroke="#1b1b1b"
//       strokeWidth={3}
//       fill="none"
//       fillRule="evenodd"
//     >
//       <path d="M10 10c6.092 0 9.4 3.618 9.926 10.855A2 2 0 0117.93 23H2.069a2 2 0 01-1.995-2.145C.6 13.618 3.908 10 10 10z" />
//       <circle cx={10} cy={5} r={5} />
//     </g>
//   </svg>
// );

// export default SvgProfile;

import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={27}
      height={27}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M13.982 17.904c-4.512 0-8.365.682-8.365 3.414s3.829 3.44 8.365 3.44c4.512 0 8.365-.684 8.365-3.415s-3.828-3.44-8.365-3.44z"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M13.982 14.007A5.362 5.362 0 108.62 8.645a5.344 5.344 0 005.325 5.362h.037z"
        stroke="#130F26"
        strokeWidth={1.429}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
