import React, { useEffect, useState } from 'react';
import { Modal, Icon, Table } from 'semantic-ui-react';
import downloadFile from 'js-file-download';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';

import {
  Text,
  Button,
  CsvReader,
  ErrorToast,
  Input,
  Cleave,
  DatePicker,
  Select,
  Switch
} from 'components';

import { useStaff } from 'hooks';
import nationalities from 'helpers/countriesAndNationalities';
import reactSessionApi from 'api/reactSession';

import DropdownOption from '../../../../webAppointments/overview/components/dropdownOption/DropdownOption';

import './bulkCreateModal.scss';

const BulkCreateModal = ({ show, hide, fetchSessions }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [data, setData] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [projectId, setProjectId] = useState('');
  const [packageName, setPackageName] = useState('');
  const [date, onDateChange] = useState(moment().toDate());
  const [time, setTime] = useState(moment().format('HH:mm'));
  const [selectedStaff, setStaff] = useState(null);
  const [staffOptions, setStaffOptions] = useState([]);
  const [errors, setErrors] = useState('');
  const [sendEmail, setSendEmail] = useState(false);

  useEffect(() => {
    formatCsvData();
  }, [csvData]);

  const downloadSampleFile = async () => {
    try {
      setIsDownloading(true);
      const resp = await axios({
        url: 'https://riverr-trustroot.s3.ap-southeast-1.amazonaws.com/public/react_session_template.csv',
        method: 'GET',
        responseType: 'blob',
      });
      downloadFile(resp.data, 'patient_react_sample.csv');
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      toast.error(<ErrorToast message="Error downloading template" />);
    }
  };

  const staff = useStaff({ clientId: localStorage.getItem('DELTA_CLIENT_ID') });

  useEffect(() => {
    if (!Array.isArray(staff)) return setStaffOptions([]);
    const result = staff.map(({ id, name, email, type }) => {
      const val = {
        value: id,
        text: name,
        type,
        content: <DropdownOption text={name} role={email} />,
      };
      //   if (staffId === id) setStaff(staffId);
      return val;
    });
    setStaffOptions(result);
  }, [staff]);

  const getStaff = () => {
    const staffData = staffOptions.find(
      (option) => option?.value === selectedStaff
    );
    const staff = {
      id: staffData?.value,
      name: staffData?.text,
    };
    return staff;
  };

  const onUpload = (data) => {
    setCsvData(data);
  };

  const formatCsvData = () => {
    const formattedData = [];
    let hasError = false;
    // let projId;
    // let packageName;

    csvData.forEach((data) => {
      if (!data.first_name || !data.last_name) {
        toast.error(<ErrorToast message="Patient name is required" />, {
          toastId: 'pat-name',
        });
        hasError = true;
        return;
      }

      if (
        data.birth_date &&
        !moment(data.birth_date, 'DD/MM/YYYY', true).isValid()
      ) {
        if (moment(data.birth_date, 'D/M/YYYY', true).isValid())
          data.birth_date = moment(data.birth_date, 'D/M/YYYY').format(
            'DD/MM/YYYY'
          );
        else {
          toast.error(
            <ErrorToast
              message={`Invalid date of birth: ${data.birth_date}`}
            />,
            {
              toastId: 'pat-dob',
            }
          );
          hasError = true;
          return;
        }
      }
      if (data.gender !== 'Male' && data.gender !== 'Female') {
        toast.error(<ErrorToast message={`Invalid gender: ${data.gender}`} />, {
          toastId: 'pat-gender',
        });
        hasError = true;
        return;
      }
      if (!data.national_id) {
        toast.error(
          <ErrorToast message="National ID is required for all patients" />,
          {
            toastId: 'pat-id',
          }
        );
        hasError = true;
        return;
      }

      const formattedNationality = data.nationality
        ? data.nationality.trim()
        : null;

      const nat = formattedNationality
        ? getPatientNationality(data.nationality.toLowerCase())
        : null;

      if (!nat)
        return toast.error(
          <ErrorToast
            message={`Unsupported nationality: ${data.nationality}`}
          />,
          {
            toastId: 'pat-nat',
          }
        );

      formattedData.push({
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email.trim(),
        phoneNumber: data.phone.trim(),
        dateOfBirth: moment(data.birth_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        gender: data.gender.toLowerCase(),
        passNo: data.passport_number.trim(),
        ssn: data.national_id.trim(),
        nationality: nat,
        addOns:
          data?.add_ons?.length > 0
            ? data.add_ons.split(',').map((item) => item.trim())
            : [],
      });
    });
    if (hasError) return;

    // Remove duplicate passNo and ssn values
    // let passNoArr = formattedData.map((item) => item.passNo);
    // let ssnArr = formattedData.map((item) => item.ssn);
    // const arr = [...formattedData];
    // const finalArr = formattedData.filter(
    //   (item, index) => {
    //     // console.log("222 ssnArr.indexOf(item.ssn) === index", ssnArr.indexOf(item.ssn) === index, ssnArr.indexOf(item.ssn), index)
    //     console.log("222 ssnArr.includes(item.ssn, index + 1)", ssnArr.indexOf(item.ssn) === index)
    //     console.log("222 passNoArr.includes(item.passNo, index + 1)", passNoArr.indexOf(item.passNo) === index)
    //     // console.log("222 !!item.passNo", !!item.passNo)
    //     if((!!item.passNo && passNoArr.indexOf(item.passNo) === index) || (!!item.ssn && ssnArr.indexOf(item.ssn) === index)) return false;
    //     return true;
    //   }
    // );
    // passNoArr.indexOf(item.passNo) === index && ssnArr.indexOf(item.ssn) === index


    let seenSsn = {};
    let seenPassNo = {};

    const finalArr = formattedData.filter(function(item) {
        let ssnDuplicate = !!item.ssn && seenSsn.hasOwnProperty(item.ssn);
        let passNoDuplicate = !!item.passNo && seenPassNo.hasOwnProperty(item.passNo);
        
        seenSsn[item.ssn] = true;
        seenPassNo[item.passNo] = true;

        return !ssnDuplicate && !passNoDuplicate;
    });
    setData(finalArr);
  };

  const getPatientNationality = (nationality) => {
    let nat;
    for (let i = 0; i < nationalities.length; i++) {
      if (
        nationalities[i]?.value === nationality ||
        nationalities[i]?.key === nationality
      ) {
        nat = nationalities[i];
        break;
      }
    }
    return { text: nat.value, value: nat.key };
  };

  const checkForm = () => {
    let error = false;
    let errors = {};

    if (!companyName) {
      errors.companyName = true;
      error = true;
    }
    if (!projectId) {
      errors.projectId = true;
      error = true;
    }
    if (!packageName) {
      errors.packageName = true;
      error = true;
    }
    setErrors(errors);
    return error;
  };

  const bulkCreateReactSession = async () => {
    try {
      const isFormErrors = checkForm();
      if (isFormErrors) return;
      setIsSaving(true);

      const formattedDate = moment(date).format('YYYY-MM-DD');
      const finalDate = moment(
        formattedDate + ' ' + time,
        'YYYY-MM-DD HH:mm'
      ).toISOString();

      const payload = {
        company: companyName,
        patients: data,
        externalId: projectId,
        name: packageName,
        staff: getStaff(),
        date: finalDate,
        time,
        type: data.length === 1 ? 'single' : 'group',
        sendQuestionnaire: sendEmail,
        patientName:
          data.length === 1 ? data[0].firstName + ' ' + data[0].lastName : '',
      };

      await reactSessionApi.bulkCreateReactSession(payload);
      await fetchSessions();
      setCsvData([]);
      setIsSaving(false);
      hide(false);
    } catch (error) {
      setIsSaving(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  const renderTableData = () => {
    return data.map((entry, i) => {
      return (
        <Table.Row className="table-row" key={i}>
          <Table.Cell>
            {entry.firstName} {entry.lastName}
          </Table.Cell>
          {/* <Table.Cell>{entry.company}</Table.Cell>
          <Table.Cell>{entry.package}</Table.Cell> */}
          <Table.Cell>{entry.email}</Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <Modal
      className="rh-bcm"
      closeIcon
      size="small"
      open={show}
      onClose={() => hide(false)}
    >
      <Modal.Header>
        <Text id="react_main.bulk_react_creation" dark>
          Bulk Create ReACT Health Session
        </Text>
      </Modal.Header>
      <Modal.Content className="rh-bcm__content">
        <div className="rh-bcm__c-data-wrapper">
          <div style={{ display: 'flex', marginBottom: '15px' }}>
            <Input
              labelTextId="react_main.company"
              placeholder="Riverr"
              label="Company"
              border
              size="fullWidth"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              // showErrorMessage={false}
              className="mr-3"
              invalid={errors?.companyName}
            />
            <Input
              labelTextId="react_main.project_id"
              placeholder="RiverrMay23"
              label="Project ID"
              border
              size="fullWidth"
              value={projectId}
              onChange={(e) => setProjectId(e.target.value)}
              // showErrorMessage={false}
              invalid={errors?.projectId}
            />
          </div>
          <Input
            labelTextId="react_main.react_package_name"
            className="mb-3"
            placeholder="Executive HS Package"
            label="ReACT Screening Package Name"
            border
            size="fullWidth"
            value={packageName}
            onChange={(e) => setPackageName(e.target.value)}
            // showErrorMessage={false}
            invalid={errors?.packageName}
          />
          <div
            style={{
              display: 'flex',
              marginBottom: '15px',
              width: '100%',
            }}
          >
            <div style={{ width: '100%', marginRight: '10px' }}>
              <Text id="react_main.screening_date" type="label">
                Screening Date
              </Text>
              <DatePicker
                label="Screening Date"
                onChange={(date) => onDateChange(date)}
                value={date}
                size="fullWidth"
                //   invalid={errors?.startDate}
                //   maxDate={moment().toDate()}
                showErrorMessage={false}
              />
            </div>
            <Cleave
              labelTextId="react_main.screening_time"
              wrapperStyle={{ width: '100%' }}
              size="fullWidth"
              placeholder="hh:mm"
              label="Screening Time"
              options={{
                time: true,
                timePattern: ['h', 'm'],
              }}
              onChange={(e) => setTime(e.target.value)}
              value={time}
              //   invalid={errors.collectionTime}
              errorMessage=" "
              // disabled={isLabResult && !!appointment.collectionDate}
            />
          </div>
          <div className='mb-3'>
            <Select
              labelTextId="react_main.doctor-in-charge"
              label="Doctor-in-charge"
              size="fullWidth"
              border
              search
              optional
              icon={false}
              placeholder="Select staff"
              options={staffOptions}
              value={selectedStaff}
              onChange={(e, { value }) => setStaff(value)}
              //   invalid={errors.template}
            />
          </div>
          <div className='mb-3'>
            <Text type="label">
              Send questionnaires to patients
            </Text>
            <Switch
              onChange={() => setSendEmail(!sendEmail)}
              checked={sendEmail}
            />
          </div>
        </div>
        {data.length === 0 && (
          <>
            <div className="rh-bcm__content__header">
              <Text id="patients.upload_csv_patient" size="small" bold>
                Upload a CSV file with patient details
              </Text>
              <Button
                size="small"
                color="green"
                inverted
                onClick={downloadSampleFile}
                isLoading={isDownloading}
                fontSize="tiny"
                style={{ width: '220px', height: '33px' }}
                textId="patients.download_template"
              >
                <>
                  <Icon name="download" className="mr-3" />
                  Download template
                </>
              </Button>
            </div>
            <div className="rh-bcm__content__dropzone">
              <CsvReader
                onUpload={onUpload}
                onRemoveFile={() => setCsvData([])}
              />
            </div>
          </>
        )}
        {data.length > 0 && (
          <div className="rh-bcm__data-wrapper">
            <Button
              className="rh-bcm__data-wrapper__clear"
              size="fluid"
              color="grey"
              inverted
              onClick={() => setData([])}
            >
              <Icon name="redo" />
            </Button>
            <div style={{ display: 'flex', marginRight: '50px' }}>
              {/* <Icon name="warning sign" size="large" /> */}
              <Text size="small">
                <span style={{ fontWeight: 'bold' }}>
                  {data.length}
                  {/* Patients */}
                </span>{' '}
                {/* will be added to the ReACT Health Screening session. A ReACT
                Health questionnaire will be sent to the following patient(s) to
                complete. */}
              </Text>
              <Text id="react_main.react_creation_message" size="small">
                {/* <span style={{ fontWeight: 'bold' }}>
                  {data.length} Patients
                </span>{' '} */}
                Patients will be added to the ReACT Health Screening session. A
                ReACT Health questionnaire will be sent to the following
                patient(s) to complete.
              </Text>
            </div>
            <Table singleLine fixed unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Text id="react_main.patient" bold>
                      Patient
                    </Text>
                  </Table.HeaderCell>
                  {/* <Table.HeaderCell>Company</Table.HeaderCell>
                  <Table.HeaderCell>Package</Table.HeaderCell> */}
                  <Table.HeaderCell>
                    <Text id="react_main.email" bold>
                      Email
                    </Text>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{renderTableData()}</Table.Body>
            </Table>
          </div>
        )}
        <Button
          size="fullWidth"
          color="blue"
          disabled={csvData.length === 0}
          onClick={bulkCreateReactSession}
          isLoading={isSaving}
          textId="react_main.upload_data"
        >
          <Icon name="upload" /> Upload data
        </Button>
      </Modal.Content>
    </Modal>
  );
};

export default BulkCreateModal;
