// import * as React from 'react';

// function Courses(props) {
//   return (
//     <svg width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
//       <path
//         d="M15 14.438a16.493 16.493 0 00-8.35-4.188c-1.513-.262-2.9.938-2.9 2.475v7.8c0 2.1.9 3.2 2.138 3.363a16.36 16.36 0 018.287 3.587c.438.362 1.15.4 1.587.05 2.338-1.912 5.2-3.225 8.35-3.625 1.176-.162 2.138-1.325 2.138-2.525v-8.65c0-1.537-1.387-2.737-2.9-2.475A16.493 16.493 0 0015 14.438zM15 10a3.745 3.745 0 003.75-3.75A3.745 3.745 0 0015 2.5a3.745 3.745 0 00-3.75 3.75A3.745 3.745 0 0015 10z"
//         fill="#1b1b1b"
//       />
//     </svg>
//   );
// }

// export default Courses;

import * as React from 'react';

function Courses(props) {
  return (
    <svg
      width={27}
      height={27}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.158 4.959v2.823M16.158 20.72v2.361M16.158 16.712v-5.624"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M21.82 23.333c2.125 0 3.847-1.7 3.847-3.8V16.51c-1.407 0-2.54-1.119-2.54-2.508s1.133-2.509 2.54-2.509l-.002-3.026c0-2.099-1.723-3.8-3.847-3.8H6.182c-2.124 0-3.847 1.701-3.847 3.8l-.002 3.125c1.407 0 2.54 1.021 2.54 2.41 0 1.39-1.133 2.508-2.54 2.508v3.025c0 2.1 1.722 3.8 3.848 3.8h15.638z"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Courses;
