import React from 'react';
import { withRouter } from 'react-router-dom';

import CertificateView from '../certificateView/CertificateView';

import '../publicCertificateView/publicCertificateView.scss';

const PublicQrCertView = props => {
  return (
    <div className="public-certificate-view">
      <CertificateView
        publicView
        className="public-certificate-view__certificate"
      />
    </div>
  );
};

export default withRouter(PublicQrCertView);
