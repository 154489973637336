import React from 'react';

const SvgBin = props => (
  <svg width="1em" height="1em" viewBox="0 0 31 32" {...props}>
    <g fill="none" fillRule="evenodd" stroke={props.color || '1b1b1b'} strokeWidth={3}>
      <rect x={6.5} y={9.5} width={18} height={21} rx={2} />
      <path d="M6.559 5.5H24.44" strokeLinecap="round" />
      <path d="M9 6V4a2 2 0 012-2h9a2 2 0 012 2v2" />
      <g strokeLinecap="round">
        <path d="M12 17l7 7M19 17l-7 7" />
      </g>
    </g>
  </svg>
);

export default SvgBin;
