export const SET_COMPLETED_COUNT = 'STATS/SET_COMPLETED_COUNT';

/**
 * @function setCompletedCount
 * @description Set information of the logged in client
 */
export const setCompletedCount = count => ({
  type: SET_COMPLETED_COUNT,
  payload: count,
});
