import React from 'react';
import moment from 'moment-timezone';

import './singPDT.scss';

const SingPDT = props => {
  const extraData = props.certificate.extraData
    ? JSON.parse(props.certificate.extraData)
    : null;

  const negativeSymbol = extraData?.negativeSymbol || '≥';

  return (
    <div className="cert-wrapper" ref={props.certRef}>
      <div className="sing-pdt">
        {props.certificate.logoImage && (
          <div className="sing-pdt__logo-wrapper">
            <img
              className="sing-pdt__logo-wrapper__logo"
              src={props.certificate.logoImage}
              alt="logo"
            />
          </div>
        )}
        <h1
          className="sing-pdt__header"
          style={{ fontSize: '20px', marginBottom: '0px' }}
        >
          MEMO ON COVID-19
        </h1>
        <h1 className="sing-pdt__header-bottom" style={{ fontSize: '20px' }}>
          RT-PCR and SEROLOGY (IgM ANTIBODY) TEST RESULT
        </h1>

        <section>
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Name of Person:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.holderName}
            </div>
          </div>
          {props.certificate.ssn && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">NRIC/FIN Number:</div>
              <div className="sing-pdt__row__data">{props.certificate.ssn}</div>
            </div>
          )}
          {props.certificate.passNo && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">Passport Number:</div>
              <div className="sing-pdt__row__data">
                {props.certificate.passNo}
              </div>
            </div>
          )}
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Nationality:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.nationality}
            </div>
          </div>
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Date of Birth:</div>
            <div className="sing-pdt__row__data">
              {moment(props.certificate.dateOfBirth).format('DD/MM/YYYY')}
            </div>
          </div>
        </section>

        <section className="sing-pdt__info-text" style={{ marginTop: '20px' }}>
          <p>To whom it may concern,</p>
          <p style={{ marginBottom: '0px' }}>
            1. The above-mentioned has undergone RT-PCR testing using a{' '}
            {props.certificate.transportName || 'Nasopharyngeal swab'} on{' '}
            {moment
              .utc(props.certificate.collectionDate)
              .clone()
              .tz('Asia/Singapore')
              .format('DD/MM/YYYY HH:mm')}{' '}
            SGT by {props.certificate.issuerName} and has tested{' '}
            <span className="sing-pdt__info-text__result">
              {props.certificate.result}.
            </span>{' '}
            This test result was reported by {props.certificate.labName} on{' '}
            {moment
              .utc(props.certificate.issuedAt)
              .clone()
              .tz('Asia/Singapore')
              .format('DD/MM/YYYY')}
            .
          </p>
          {extraData && extraData.reagentBrand && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">Reagent Brand:</div>
              <div className="sing-pdt__row__data">{extraData.reagentBrand}</div>
            </div>
          )}

          {extraData && extraData.orf1ab && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">ORF1ab, Ct value:</div>
            <div className="sing-pdt__row__data">Ct {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
              {Number(extraData.orf1ab).toFixed(
                props.certificate.result === 'Positive' ? 2 : 0
              )}</div>
            </div>
          )}
          {extraData && extraData.nGene && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">N Gene, Ct value:</div>
            <div className="sing-pdt__row__data">Ct {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
                {Number(extraData.nGene).toFixed(
                  props.certificate.result === 'Positive' ? 2 : 0
                )}</div>
            </div>
          )}
           
           {extraData && extraData.n2Gene && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">N2 Gene, Ct value:</div>
            <div className="sing-pdt__row__data">Ct {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
              {Number(extraData.n2Gene).toFixed(
                props.certificate.result === 'Positive' ? 2 : 0
              )}</div>
            </div>
          )}
           
           {extraData && extraData.sGene && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">S Gene, Ct value:</div>
            <div className="sing-pdt__row__data">Ct {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
              {Number(extraData.sGene).toFixed(
                props.certificate.result === 'Positive' ? 2 : 0
              )}</div>
            </div>
          )}
           
           {extraData && extraData.eGene && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">E Gene, Ct value:</div>
            <div className="sing-pdt__row__data">Ct {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
              {Number(extraData.eGene).toFixed(
                props.certificate.result === 'Positive' ? 2 : 0
              )}</div>
            </div>
          )}

          {extraData && extraData.rdrp && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">RdRp, Ct value:</div>
            <div className="sing-pdt__row__data">Ct {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
              {Number(extraData.rdrp).toFixed(
                props.certificate.result === 'Positive' ? 2 : 0
              )}</div>
            </div>
          )}
          
          {extraData && extraData.RdRpSORF1ab && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">RdRp/S/ORF1ab, Ct value:</div>
            <div className="sing-pdt__row__data">Ct {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
              {Number(extraData.RdRpSORF1ab).toFixed(
                props.certificate.result === 'Positive' ? 2 : 0
              )}</div>
            </div>
          )}

             
          {extraData && extraData.n1n2Gene && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">N1, N2 Gene, Ct value:</div>
            <div className="sing-pdt__row__data">Ct {props.certificate.result === 'Positive' ? '<' : negativeSymbol}{' '}
              {Number(extraData.n1n2Gene).toFixed(
                props.certificate.result === 'Positive' ? 2 : 0
              )}</div>
            </div>
          )}
          <p style={{ marginTop: '20px'}}>
            2. The above-mentioned has undergone Serology (IgM antibody) testing for COVID-19 using{' '}
            <span className="sing-pdt__info-text__result">
              venous blood method
            </span>{' '}
            on{' '}
            {moment
              .utc(props.certificate.collectionDate)
              .clone()
              .tz('Asia/Singapore')
              .format('DD/MM/YYYY HH:mm')}{' '}
            SGT by {props.certificate.issuerName} and has tested{' '}
            <span className="sing-pdt__info-text__result">
              {props.certificate.secondResult
                ? props.certificate.secondResult.toLowerCase()
                : props.certificate.result}
              .
            </span>{' '}
            This test result was reported by{' '}
            {props.certificate.secondLab
              ? JSON.parse(props.certificate.secondLab).labName
              : props.certificate.labName}{' '}
            on{' '}
            {props.certificate.reportedAt
              ? moment
                  .utc(props.certificate.reportedAt)
                  .clone()
                  .tz('Asia/Singapore')
                  .format('DD/MM/YYYY')
              : moment
                  .utc(props.certificate.issuedAt)
                  .clone()
                  .tz('Asia/Singapore')
                  .format('DD/MM/YYYY')}
            .
          </p>
          <p>
            Travellers should note that they are subject to the country or
            region's requirements prior to travel.
          </p>

          {/* {props.certificate.result !== 'positive' && (
            <p>
              {props.certificate.gender === 'Male' ? 'He' : 'She'} is fit for
              travel, based solely on the above Lab Results.
            </p>
          )} */}
          <p>Thank you.</p>
          <div
            style={{ fontSize: '14px', lineHeight: '19px', marginTop: '15px' }}
          >
            This test certificate is used to apply for Health Declaration Certificate
          </div>
          <div
            className="tw-pdt__ch-s"
            style={{ fontSize: '14px', lineHeight: '19px' }}
          >
            此检测证明用于申请健康码;
          </div>
        </section>

        <div className="sing-pdt__signature-wrapper" style={{ marginTop: '30px'}}>
          {props.certificate.physicianSignature ? (
            <img
              className="sing-pdt__signature-wrapper__sig"
              src={props.certificate.physicianSignature}
              alt="signature"
            />
          ) : (
            <div />
          )}
          {props.certificate.stampImage && (
            <img
              className="sing-pdt__stamp"
              src={props.certificate.stampImage}
              alt="stamp"
            />
          )}
        </div>
        <section className="sing-pdt__bottom-names sing-pdt__bottom-names--mt-3">
          <p>
            <span style={{ fontWeight: 'bold' }}>Name of Doctor:</span>{' '}
            {props.certificate.physicianName}
            <br />
            <span style={{ fontWeight: 'bold' }}>MCR No.:</span>{' '}
            {props.certificate.physicianLicense}
          </p>
        </section>

        {props.showQrCode && (
          <div className="sing-pdt__qr-code">
            <img
              className="sing-pdt__qr-code__img--small"
              src={props.certificate.qrCodeImage}
              alt="qrcode"
            />
          </div>
        )}
        {props.certificate.watermarkImage && (
          <img
            className="sing-pdt__watermark"
            src={props.certificate.watermarkImage}
            alt="watermark"
          />
        )}
      </div>
    </div>
  );
};

export default SingPDT;
