import React from 'react';

const SvgArrowRight = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 33 26" {...props}>
    <g
      stroke={props.color}
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path d="M17.27 1.686l10.607 10.607a1 1 0 010 1.414L17.27 24.314M25.374 13H2.104" />
    </g>
  </svg>
);

export default SvgArrowRight;
