import React, { useEffect, useState } from 'react';
import { Icon as SIcon } from 'semantic-ui-react';

import { Text } from 'components';

import './physicalExaminationFilter.scss';

const PhysicalExaminationFilter = ({
  setActiveList,
  activeList,
  completedSections = [],
}) => {
  const items = [
    {
      name: 'vitals',
      text: (
        <div style={{ display: 'flex', gap: '5px' }}>
          <Text color="inherit" bold>
            Vitals
          </Text>
          {completedSections?.includes('vitals') && (
            <SIcon name="check square" className="pt-1" />
          )}
        </div>
      ),
    },
    {
      name: 'visual-acuity',
      text: (
        <div style={{ display: 'flex', gap: '5px' }}>
          <Text color="inherit" bold>
            Visual Acuity
          </Text>
          {completedSections?.includes('visual-acuity') && (
            <SIcon name="check square" className="pt-1" />
          )}
        </div>
      ),
    },
  ];

  const onTabClick = (val) => {
    setActiveList(val);
  };

  const Item = ({ itemDetails }) => {
    return (
      <div
        onClick={() => onTabClick(itemDetails.name)}
        className={`vf__item${
          activeList === itemDetails.name ? '-active' : ''
        }`}
      >
        {itemDetails.text}
      </div>
    );
  };

  return (
    <div className="vf">
      {items.map((item) => (
        <>{<Item itemDetails={item} />}</>
      ))}
    </div>
  );
};

export default PhysicalExaminationFilter;
