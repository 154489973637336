import * as yup from 'yup';

export const schema = yup.object().shape({
  gender: yup.string().required('required'),
  ethnicity: yup.string().required('required'),
  // height: yup.string().required('required'),
  // weight: yup.string().required('required'),
  smoker: yup.string().required('required'),
  diabetic: yup.boolean().required('required'),
  alcoholPerWeek: yup.number().required('required'),

  lipidMeds: yup.boolean().nullable(),
  hypertensiveMeds: yup.boolean().nullable(),
  historyOfStatinUse: yup.boolean().nullable(),
  historyOfCorticosteroidUse: yup.boolean().nullable(),
  historyOf2ndGenerationAntipsychoticUse: yup.boolean().nullable(),

  parentalHyperTension: yup.string().required('required'),
  familyHeartAttack: yup.boolean().required('required'),
  diabetesFamilyHistory: yup.boolean().required('required'),
  historyTreatedHypertension: yup.boolean().required('required'),
  historyCardiovascularDisease: yup.boolean().required('required'),
  historyGestationalDiabetes: yup.boolean().required('required'),
  historyPolycysticOvarySyndrome: yup.boolean().required('required'),
  historyLearningDifficulties: yup.boolean().required('required'),
  historySchizophreniaBipolar: yup.boolean().required('required'),
});
