import React from 'react';

const SvgGradHat = props => (
  <svg width="1.1em" height="1em" viewBox="0 0 31 23" {...props}>
    <g stroke={props.color} strokeWidth={2.5} fill="none" fillRule="evenodd">
      <path d="M8 9v10.058c4.235 2.663 12 2.515 16 0V9" />
      <path d="M15.973 1.392a.75.75 0 00-.459-.01L2.167 5.327a.75.75 0 00-.045 1.424l13.254 4.837a.75.75 0 00.49.009l13.096-4.262a.75.75 0 00.015-1.421L15.973 1.392z" />
      <path d="M28.5 8.444v7.334" strokeLinecap="round" />
    </g>
  </svg>
);

SvgGradHat.defaultProps = {
  color: '#1b1b1b',
};

export default SvgGradHat;
