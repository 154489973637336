// https://ned.dev.demo.api.riverr.ai/v1/chat-gpt/health-rec
import axios from './axiosInstance';

const methods = {
  async getHealthRecommendations(resultId) {
    return axios.post('chat-gpt/health-rec', { resultId });
  },
  async getTestRecommendations(resultId) {
    return axios.post('chat-gpt/test-rec', { resultId });
  },
  async getResultSummary(resultId) {
    return axios.post('chat-gpt/res-summary', { resultId });
  },
};

export default methods;
