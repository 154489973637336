import React from 'react';

const SvgSmiley = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#1b1b1b" strokeWidth={2} cx={9} cy={9} r={8} />
      <path
        d="M12.907 9.86a4.001 4.001 0 01-7.811.015"
        stroke="#1b1b1b"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <circle fill="#1b1b1b" cx={7} cy={6} r={1} />
      <circle fill="#1b1b1b" cx={11} cy={6} r={1} />
    </g>
  </svg>
);

export default SvgSmiley;
