import gql from 'graphql-tag';

export const createPaymentAccount = gql`
  mutation createPaymentAccount(
    $clientId: String
    $serviceId: String
    $gatewayId: String
    $appFee: Float
    $appFeeType: String
    $extraFee: Float
    $extraFeeType: String
    $refundFee: Boolean
    $markAsDefault: Boolean
    $paymentMethodType: [String]
    $status: String
    $isDev: Boolean
  ) {
    createPaymentAccount(
      clientId: $clientId
      serviceId: $serviceId
      gatewayId: $gatewayId
      appFee: $appFee
      appFeeType: $appFeeType
      extraFee: $extraFee
      extraFeeType: $extraFeeType
      refundFee: $refundFee
      markAsDefault: $markAsDefault
      paymentMethodType: $paymentMethodType
      status: $status
      isDev: $isDev
    )
  }
`;

export const editPaymentAccount = gql`
  mutation editPaymentAccount(
    $id: ID
    $clientId: String
    $serviceId: String
    $gatewayId: String
    $appFee: Float
    $appFeeType: String
    $extraFee: Float
    $extraFeeType: String
    $refundFee: Boolean
    $markAsDefault: Boolean
    $paymentMethodType: [String]
    $isDev: Boolean
  ) {
    editPaymentAccount(
      id: $id
      clientId: $clientId
      serviceId: $serviceId
      gatewayId: $gatewayId
      appFee: $appFee
      appFeeType: $appFeeType
      extraFee: $extraFee
      extraFeeType: $extraFeeType
      refundFee: $refundFee
      markAsDefault: $markAsDefault
      paymentMethodType: $paymentMethodType
      isDev: $isDev
    )
  }
`;

export const onAccountSuccess = gql`
  mutation onAccountSuccess($id: String, $isDev: Boolean) {
    onAccountSuccess(id: $id, isDev: $isDev)
  }
`;