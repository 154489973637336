// TODO: Move this to DB. IDK how long this will be needed as it is a new Chinese requirement, so won't spend the initial time to move it to the DB
export const reagentBrands = {
  'Abbott Alinity': {
    positiveSymbol: '<',
    negativeSymbol: '≥',
    reagentBrand: 'Abbott Alinity',
    rdrp: 42,
    nGene: 42,
  },
  'Acu-Corona': {
    positiveSymbol: '<',
    negativeSymbol: '≥',
    reagentBrand: 'Acu-Corona',
    nGene: 40,
    orf1ab: 40,
  },
  Cepheid: {
    positiveSymbol: '<',
    negativeSymbol: '≥',
    reagentBrand: 'Cepheid',
    nGene: 45,
    eGene: 45,
  },
  'Cobas Liat': {
    positiveSymbol: '<',
    negativeSymbol: '≥',
    reagentBrand: 'Cobas Liat',
    orf1ab: 40,
    nGene: 40,
  },
  'Cobas 6800': {
    positiveSymbol: '<',
    negativeSymbol: '≥',
    reagentBrand: 'Cobas 6800',
    orf1ab: 40,
    eGene: 40,
  },
  GeneXpert: {
    positiveSymbol: '<',
    negativeSymbol: '≥',
    reagentBrand: 'GeneXpert',
    n2Gene: 40,
    eGene: 40,
  },
  PerkinElmer: {
    positiveSymbol: '<',
    negativeSymbol: '≥',
    reagentBrand: 'PerkinElmer',
    nGene: 42,
    orf1ab: 42,
  },
  TaqPath: {
    positiveSymbol: '<',
    negativeSymbol: '>',
    reagentBrand: 'TaqPath',
    nGene: 37,
    orf1ab: 37,
    sGene: 37,
  },
  'Resolute 2.0': {
    positiveSymbol: '<',
    negativeSymbol: '≥',
    reagentBrand: 'Resolute 2.0',
    nGene: 40,
    eGene: 40,
  },
  Seegene: {
    positiveSymbol: '<',
    negativeSymbol: '≥',
    reagentBrand: 'Seegene',
    nGene: 40,
    eGene: 40,
    RdRpSORF1ab: 40,
  },
  Veredus: {
    positiveSymbol: '<',
    negativeSymbol: '≥',
    reagentBrand: 'Veredus',
    nGene: 40,
  },
  ProTect: {
    positiveSymbol: '<',
    negativeSymbol: '≥',
    reagentBrand: 'ProTect™',
    n1n2Gene: 37,
  },
  'Fortitude 2.1': {
    positiveSymbol: '<',
    negativeSymbol: '≥',
    reagentBrand: 'Fortitude 2.1',
    orf1ab: 40,
  },
};
