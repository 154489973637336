import React from 'react';
import { Loader } from 'semantic-ui-react';
import { Text } from 'components';

import './healthValueLine.scss';

const HealthValueLineWrapper = ({
  title,
  children,
  value,
  isLoading,
  createdAt,
  subValues,
  onClick,
  color = 'green',
}) => {
  return (
    <div className="hvl" onClick={onClick}>
      {isLoading ? (
        <div className="hvl__loader">
          <Loader active size="small" />
        </div>
      ) : (
        <>
          <Text size="micro" bold color="lightGrey">
            {createdAt}
          </Text>
          <div className="hvl__text-wrapper">
            <Text dark>{title}</Text>
            <Text
              dark
              size="big"
              uppercase
              color="inherit"
              className={`hvl__text-wrapper__value hvl__text-wrapper__value--${color}`}
              // className={`hvl__text-wrapper__value hvl__text-wrapper__value--green`}
            >
              {value.text}
            </Text>
          </div>
          {children}
          {subValues && (
            <div className="hvl__sub-values">
              {subValues.map((subVal) => (
                <div className="hvl__sub-value">
                  <Text size="micro">{subVal.text}</Text>
                  <Text bold>{subVal.value}</Text>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HealthValueLineWrapper;
