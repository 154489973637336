import { patientOverviewClient } from '../patientOverviewClient';

const baseUrl = 'medicalProfile/provider/familyHistory';
async function addFamilyHistory(userId, familyHistory) {
  const response = await patientOverviewClient.post(
    '/medicalProfile/provider/create',
    {
      userId,
      familyHistory,
    }
  );

  return response.data;
}

async function updateFamilyHistory(userId, familyHistory) {
  const response = await patientOverviewClient.post(`${baseUrl}/update`, {
    ...familyHistory,
    userId,
  });
  return response.data;
}

async function deleteFamilyHistory(userId, id) {
  const response = await patientOverviewClient.post(`${baseUrl}/delete`, {
    id,
    userId
  });
  return response.data;
}

const clientFamilyHistoryApi = {
  addFamilyHistory,
  updateFamilyHistory,
  deleteFamilyHistory,
};

export default clientFamilyHistoryApi;
