import React, { useEffect, useState } from 'react';
import { Text } from 'components';
import { Icon as SIcon } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';

import HealthValueLine from 'features/HealthValueLine/HealthValueLine';
import RiskAssessmentDonut from 'features/RiskAssessmentDonut/RiskAssessmentDonut';
import RiskFactor from './component/RiskFactor/RiskFactor';

import NormalIcon from 'assets/range-icons/normal.png';
import DetectedIcon from 'assets/range-icons/detected.png';

import './riskAssessment.scss';

const ranges1 = [
  {
    text: 'Low',
    range: [0, 10],
    subText: '<10%',
    color: 'green',
  },
  {
    text: 'Intermediate',
    range: [11, 20],
    subText: '<19%',
    color: 'orange',
  },
  { text: 'High', range: [21, 100], subText: '>20%', color: 'red' },
];

const RiskAssessment = ({
  modelName,
  data: iData,
  clientView,
  icon,
  isPreview,
  label
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [data, setData] = useState({});
  const [riskFactors, setRiskFactors] = useState([]);
  const [nonManageFactors, setNonManageFactors] = useState([]);
  const [donutRiskValue, setDonutRiskValue] = useState(0);
  const [ranges, setRanges] = useState(ranges1);

  useEffect(() => {
    if(!iData) return;
    const formattedData = { ...iData };
    formattedData.score = Math.round(iData.score * 1000) / 10;
    setData(formattedData);
    formatFactors(formattedData);
    getRiskAssDonutValue(formattedData);
    formatRanges(formattedData.reference_range);
  }, [iData]);

  const formatRanges = (ranges) => {
    if (!ranges) return setRanges([]);
    const rangez = Object.keys(ranges).map((key, index) => {
      const color = getColor(key.toLowerCase()).color;
      const values = [
        Math.round(ranges[key][0] * 10000) / 100,
        Math.round(ranges[key][1] * 10000) / 100,
      ];

      return {
        text: key,
        range: values,
        subText:
          index === Object.keys(ranges).length - 1
            ? values[0] + ' - ' + values[1] + '%'
            : '<' + values[1] + '%',
        color,
      };
    });

    setRanges(rangez);
  };

  const formatFactors = (formattedData) => {
    const factors = formatRiskFactors(
      formattedData?.risk_contributor?.changeable_variables
    );
    setRiskFactors(factors);

    const nonFactors = formatRiskFactors(
      formattedData?.risk_contributor?.unchangeable_variables
    );
    setNonManageFactors(nonFactors);
  };

  const formatRiskFactors = (changableVars) => {
    if (!changableVars) return [];
    const factors = [];
    // const changableVars = formattedData?.risk_contributor?.changeable_variables;
    Object.keys(changableVars).forEach((key) => {
      if (changableVars[key].risk_factor !== 0)
        factors.push(changableVars[key]);
    });

    factors.sort((a, b) => b.risk_factor - a.risk_factor);

    return factors;
  };

  const getRiskAssDonutValue = (formattedData) => {
    if (!formattedData?.risk_contributor) return;
    // const changableVars = Object.values(
    //   formattedData?.risk_contributor?.changeable_variables
    // );
    const unChangableVars = Object.values(
      formattedData?.risk_contributor?.unchangeable_variables
    );

    const reduceSumFunc = (ac, obj) => ac + obj.risk_factor;
    // const changableVarsSum = changableVars.reduce(reduceSumFunc, 0);
    const unChangableVarsSum = unChangableVars.reduce(reduceSumFunc, 0);

    setDonutRiskValue(unChangableVarsSum * 100);
  };

  const getLabel = () => {
    switch (modelName) {
      case 'cardiovascular':
        return 'Cardiovascular Disease';
      case 'hypertension':
        return 'Hypertension';
      case 'coronaryHeartDisease':
        return 'Coronary Heart Disease';
      case 'liver':
        return 'Non-Alcoholic Fatty Liver';
      case 'diabetes':
        return 'Diabetes';
      case 'kidney_stage3':
        return 'Kidney Stage 3';
      case 'kidney_stage5':
        return 'Kidney Stage 5';
      default:
        return label || '';
    }
  };

  // Yo Alfred, check this out
  const getColor = (status) => {
    if (status === 'low' || status === 'normal') return { color: 'green', colorCode: '#00c48c' };
    if (status === 'borderline') return { color: 'yellow', colorCode: '#FFD443' };
    if (status === 'intermediate') return { color: 'orange', colorCode: '#ffaf3e' };
    return { color: 'red', colorCode: '#ff6666' };
  };

  return (
    <div className={`risk-model ${clientView && 'risk-model--small'}`}>
      <div className="risk-model__header">
        <img className="risk-model__header__icon" alt="ic" src={icon} />
        <div className="risk-model__header__text">
          <Text dark size="big" className="mb-1">
            {getLabel()}
          </Text>
          <Text size="tiny" bold>
            {data.period} risk forecast based on {data.age_group} age group
          </Text>
        </div>
      </div>
      <div className="risk-model__graph-wrapper">
        <div
          className={`risk-model__square-value risk-model__square-value--${
            (isPreview ? 'border-' : '') +
            getColor(data?.risk_score_status?.toLowerCase()).color
          }`}
        >
          <Text
            color={
              isPreview
                ? getColor(data?.risk_score_status?.toLowerCase()).colorCode
                : 'white'
            }
            size="tiny"
            style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
          >
            Your risk is
          </Text>
          <div className="risk-model__square-value__pct">
            <Text
              color={
                isPreview
                  ? getColor(data?.risk_score_status?.toLowerCase()).colorCode
                  : 'white'
              }
              dark
              size="huge"
            >
              {data?.score || 0}
            </Text>
            <Text
              color={
                isPreview
                  ? getColor(data?.risk_score_status?.toLowerCase()).colorCode
                  : 'white'
              }
              bold
            >
              %
            </Text>
          </div>
          <Text
            color={
              isPreview
                ? getColor(data?.risk_score_status?.toLowerCase()).colorCode
                : 'white'
            }
            size="big"
            dark
          >
            {data?.risk_score_status}
          </Text>
        </div>
        <div className="risk-model__graph">
          <HealthValueLine
            value={{
              value: data?.score,
              text: 'normal',
              unit: '%',
            }}
            ranges={ranges}
            color={getColor(data?.risk_score_status?.toLowerCase()).color}
          />
        </div>
      </div>
      <div className="risk-model__risk-donut">
        <Text dark className="risk-model__risk-donut__header mb-2">
          <SIcon name="list ul" />
          Breakdown of Contributing Risk Factor
        </Text>
        <Text size="tiny" justify>
          Risk factors can be classified into two categories: "manageable" and
          "non-manageable". Manageable risk factors refer to those that can be
          improved through lifestyle changes or a combination of methods. On the
          other hand, non-manageable risk factors encompass factors such as age,
          gender, family history, and other inherent characteristics that cannot
          be altered.
        </Text>
        <div className="risk-model__risk-donut-graph">
          {isMobile ? (
            <RiskAssessmentDonut
              graphSize={90}
              riskPercentageValue={donutRiskValue || 0}
              actualRiskValue={data?.score || 0}
              barHeight={25}
              barWidth={5}
              // numOfBars={50}
            />
          ) : (
            <RiskAssessmentDonut
              wrapperSize={170}
              graphSize={120}
              subGraphSize={120}
              riskPercentageValue={donutRiskValue || 0}
              actualRiskValue={data?.score || 0}
              barHeight={33}
              barWidth={8}
              // numOfBars={50}
            />
          )}
        </div>
      </div>
      <div className="risk-model__risk-facts">
        <Text dark className="risk-model__risk-facts__header">
          <img
            className="risk-model__risk-facts__icon"
            src={NormalIcon}
            alt="icon"
          />
          Manageable Contributing Risk Factors
        </Text>
        {/* <Text size="tiny">
          Manageable risk factors refer to those that can be improved through
          lifestyle changes or a combination of methods.
        </Text> */}
        {!!riskFactors.length ? (
          <div className="risk-model__risk-facts__bio">
            {riskFactors &&
              riskFactors.map((riskFactor) => (
                <RiskFactor
                  data={riskFactor}
                  score={data?.score || 0}
                  diseaseName={getLabel()}
                />
              ))}
          </div>
        ) : (
          <Text className="mt-4 mb-4">
            We have no recommendations for health improvements, keep up the
            healthy habits!
          </Text>
        )}
      </div>

      {!!nonManageFactors.length && (
        <div className="risk-model__risk-facts">
          <Text dark className="risk-model__risk-facts__header">
            <img
              className="risk-model__risk-facts__icon"
              src={DetectedIcon}
              alt="icon"
            />
            Non-manageable Contributing Risk Factors
          </Text>
          {/* <Text size="tiny">
            Non-manageable risk factors encompass factors such as age, gender,
            family history, and other inherent characteristics that cannot be
            altered.
          </Text> */}

          <div className="risk-model__risk-facts__bio">
            {nonManageFactors &&
              nonManageFactors.map((riskFactor) => (
                <RiskFactor
                  data={riskFactor}
                  score={data?.score}
                  diseaseName={getLabel()}
                  isNonManageable={true}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RiskAssessment;
