import React from 'react';

import './linearLoader.scss';

const LinearLoader = () => {
  return (
    <div className="linear-activity">
      <div class="linear-activity__indeterminate"></div>
    </div>
  );
};

export default LinearLoader;
