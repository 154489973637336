import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    width="inherit"
    height="inherit"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill={props.color}>
      <path d="M12.5 12a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0-10a4 4 0 1 1 0 8 4 4 0 0 1 0-8ZM12.5 14a9.01 9.01 0 0 0-9 9 1 1 0 1 0 2 0 7 7 0 1 1 14 0 1 1 0 0 0 2 0 9.01 9.01 0 0 0-9-9Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={props.color} transform="translate(.5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
