import { SET_TEMPLATES } from './templatesManagement.actions';

const initialState = {
  templates: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TEMPLATES:
      return { ...state, templates: action.payload };

    default:
      return state;
  }
};
