import React from 'react';

const SvgStarOutline = props => (
  <svg width="1em" height="1em" viewBox="0 0 31 30" {...props}>
    <path
      stroke="#1b1b1b"
      strokeWidth={3}
      d="M15.5 23.2l-7.935 3.222.612-8.543-5.516-6.55 8.313-2.058L15.5 2l4.526 7.27 8.313 2.058-5.516 6.551.612 8.543z"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgStarOutline;
