import React from 'react';

const SvgAngleCaretRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      d="M15.9003 9.88006L11.3103 5.29006C11.1229 5.10381 10.8695 4.99927 10.6053 4.99927C10.3411 4.99927 10.0877 5.10381 9.90029 5.29006C9.80656 5.38302 9.73217 5.49362 9.6814 5.61548C9.63063 5.73734 9.60449 5.86805 9.60449 6.00006C9.60449 6.13207 9.63063 6.26278 9.6814 6.38464C9.73217 6.5065 9.80656 6.6171 9.90029 6.71006L14.5003 11.2901C14.594 11.383 14.6684 11.4936 14.7192 11.6155C14.77 11.7373 14.7961 11.868 14.7961 12.0001C14.7961 12.1321 14.77 12.2628 14.7192 12.3846C14.6684 12.5065 14.594 12.6171 14.5003 12.7101L9.90029 17.2901C9.71199 17.477 9.60567 17.7312 9.60473 17.9965C9.6038 18.2619 9.70831 18.5168 9.89529 18.7051C10.0823 18.8934 10.3364 18.9997 10.6018 19.0006C10.8671 19.0016 11.122 18.897 11.3103 18.7101L15.9003 14.1201C16.4621 13.5576 16.7777 12.7951 16.7777 12.0001C16.7777 11.2051 16.4621 10.4426 15.9003 9.88006Z"
      fill="#1C1C1C"
    />
  </svg>
);
export default SvgAngleCaretRight;
