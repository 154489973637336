import { patientOverviewClient } from '../patientOverviewClient';

const baseUrl = 'medicalProfile/provider/allergies';
async function addAllergy(userId, allergy) {
  const response = await patientOverviewClient.post(
    'medicalProfile/provider/create',
    {
      userId,
      allergy,
    }
  );

  return response.data;
}

async function updateAllergy(userId, allergy) {
  const response = await patientOverviewClient.post(`${baseUrl}/update`, {
    ...allergy,
    userId,
  });
  return response.data;
}

async function deleteAllergy(userId, id) {
  const response = await patientOverviewClient.post(`${baseUrl}/delete`, {
    id,
    userId
  });
  return response.data;
}

const clientAllergyApi = {
  addAllergy,
  updateAllergy,
  deleteAllergy,
};

export default clientAllergyApi;
