import React, { useEffect, useState } from 'react';
import { Modal, Icon as SIcon } from 'semantic-ui-react';

import { Text, Button, Icon } from 'components';

const PatientResultsDeleteModal = ({
  selectedRows,
  showModal,
  setShowModal,
  onDeleteResults,
  deleteLoading,
}) => {
  const [resultIdsToDelete, setResultIdsToDelete] = useState([]);
  const [resultsToDelete, setResultsToDelete] = useState([]);
  useEffect(() => {
    setResultIdsToDelete(Object.keys(selectedRows));
    setResultsToDelete(Object.values(selectedRows));
  }, [selectedRows]);

  return (
    <Modal
      className="drm"
      open={showModal}
      onClose={() => setShowModal(false)}
      closeIcon
      size="tiny"
    >
      <Modal.Header className="drm__header">
        <div className="drm__icon mr-2">
          <Icon name="alert" />
        </div>
        <Text
          size="large"
          id="patient_app.results_delete_reports"
          className="drm__header-text"
          dark
        >
          Delete Report
        </Text>
      </Modal.Header>
      <Modal.Content>
        {resultsToDelete?.map((result) => (
          <div className="drm__file mb-3">
            <Text size="small" bold color="#5b53f3">
              <SIcon name="file pdf" color="red" size="" />{' '}
              {result?.pdf?.at(0)?.name || 'N.A'}
            </Text>
          </div>
        ))}
        <div className="drm__text-wrapper">
          <Text
            size="medium"
            id="patient_app.result_delete_report_alert"
            className="drm__text mb-4"
          >
            Are you sure you want to permanently delete the selected report(s)?
          </Text>
        </div>
        <div className="drm__button-wrapper">
          <Button
            size="fullWidth"
            isLoading={deleteLoading}
            color="red"
            className="drm__button"
            onClick={() => onDeleteResults(resultIdsToDelete)}
          >
            <div className="drm__icon mr-2 mt-2">
              <Icon name="close" color="white" />
            </div>
            <Text color="inherit" id="patient_app.result_delete">
              Delete
            </Text>
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default PatientResultsDeleteModal;
