import React from 'react';

import { Text } from '../../../../../components';

import './dropdownOption.scss';

const DropdownOptions = ({ text, role }) => {
  return (
    <div className="drpdwn-opt">
      <Text size="tiny" bold>
        {text}
      </Text>
      <Text size="tinniest" className="mt-1" color="lightGrey">
        {role}
      </Text>
    </div>
  );
};

export default DropdownOptions;
