import React from 'react';

const SvgDiagonalArrowDown = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 17" {...props}>
    <g
      stroke="#1b1b1b"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path d="M16.121 6.121l-.213 8.3a.5.5 0 01-.487.487l-8.3.213M15.387 14.387L2.782 1.782" />
    </g>
  </svg>
);

export default SvgDiagonalArrowDown;
