export const A4_SIZE_FORMAT = 'A4';
export const A5_SIZE_FORMAT = 'A5';
export const PAPER_SIZE_OPTIONS = [
  {
    key: 'A4',
    value: 'A4',
    text: 'A4',
  },
  {
    key: 'A5',
    value: 'A5',
    text: 'A5',
  },
];
