// import * as React from 'react';

// function Templates(props) {
//   return (
//     <svg width={26} height={26} viewBox="0 0 26 26" fill="none" {...props}>
//       <path
//         d="M12.988.5C6.088.5.5 6.1.5 13s5.588 12.5 12.488 12.5C19.9 25.5 25.5 19.9 25.5 13S19.9.5 12.988.5zm4.037 19.238L13 17.313l-4.025 2.425c-.475.287-1.063-.138-.938-.675L9.1 14.488l-3.537-3.063c-.413-.363-.188-1.05.362-1.1l4.675-.4 1.825-4.313a.622.622 0 011.15 0l1.825 4.3 4.675.4c.55.05.775.738.35 1.1l-3.538 3.063 1.063 4.588a.62.62 0 01-.925.675z"
//         fill="#1b1b1b"
//       />
//     </svg>
//   );
// }

// export default Templates;

import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={23}
      height={23}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M13.104 4.177l1.828 3.65c.179.36.524.608.925.665l4.088.589c1.01.145 1.412 1.37.681 2.07l-2.956 2.841c-.29.28-.423.681-.354 1.076l.698 4.01c.172.992-.884 1.749-1.787 1.28l-3.654-1.895a1.248 1.248 0 00-1.146 0l-3.654 1.894c-.903.47-1.959-.287-1.786-1.28l.697-4.01a1.203 1.203 0 00-.354-1.075l-2.956-2.84c-.731-.701-.33-1.926.68-2.071l4.089-.589c.4-.057.747-.306.926-.664l1.827-3.651c.452-.903 1.756-.903 2.208 0z"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
