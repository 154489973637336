import React from 'react';
import moment from 'moment';

import { Text } from 'components';

import HealthValueLine from 'features/HealthValueLine/HealthValueLine';
import HealthValueLineWrapper from 'features/HealthValueLine/HealthValueLinesWrapper';

import history from '../../../../history';

import '../../bmiPage/bmiPage.scss';

const pulseOxRange = [
  { text: 'Normal', range: [96, 100], subText: '≥96', color: 'green' },
  {
    text: 'Borderline',
    range: [95, 95],
    subText: '95',
    color: 'yellow',
  },
  {
    text: 'Moderate',
    range: [93, 94],
    subText: '93-94',
    color: 'orange',
  },
  { text: 'High Risk', range: [0, 92], subText: '≤92', color: 'red2' },
];

const perfusionRange = [
  { range: [0, 0.4], text: 'Abnormal', subText: '0-0.4', color: 'red' },
  { range: [0.4, 20], text: 'Normal', subText: '0.4-20', color: 'green' },
  {
    range: [20, 30],
    text: 'Abnormal',
    subText: '>20',
    color: 'red',
  },
];

const heartRateRange = [
  { text: 'High Risk', subText: '<40', range: [29, 39], color: 'red2' },
  { text: 'Normal', subText: '40-100', range: [40, 100], color: 'green' },
  {
    text: 'Borderline',
    subText: '101-109',
    range: [101, 109],
    color: 'yellow',
  },
  {
    text: 'Moderate',
    subText: '110-129',
    range: [110, 129],
    color: 'orange',
  },
  {
    text: 'High Risk',
    subText: '≥130',
    range: [130, 200],
    color: 'red2',
  },
];

const oxyColors = {
  Normal: { color: 'green' },
  Borderline: { color: 'yellow' },
  'High Risk': { color: 'red' },
  'Very High Risk': { color: 'red2' },
  'Low Heart Rate': { color: 'orange' },
};

const OxyPage = ({ row, index, isClient }) => {
  return (
    <div className="bmi-page__body__list__item">
      <HealthValueLineWrapper
        onClick={
          isClient
            ? () => {}
            : () =>
                history.push({
                  pathname: `/p/oxy/edit/${row.id}`,
                  state: { data: row },
                })
        }
        color={oxyColors[row.risk]?.color || 'green'}
        key={index + row.id}
        title="Pulse OX (SpO2) -"
        createdAt={moment(row.date).format('DD MMM YYYY, HH:mm a')}
        value={{
          value: row.o2,
          text: row.risk,
        }}
      >
        <div style={{ display: 'flex' }}>
          <div className="bmi-page__body__line-graph-text">
            <Text size="micro">Pulse Ox (SpO2)</Text>
            <Text bold>{row.o2}%</Text>
            <Text size="nano" color="lightGrey">
              Percentage
            </Text>
          </div>
          <HealthValueLine
            isReversed
            key={index + row.id}
            value={{
              value: row.o2,
              text: row.risk,
            }}
            ranges={pulseOxRange}
          />
        </div>
        {(!!row.pi || row.pi) === 0 && (
        <div style={{ display: 'flex' }}>
          <div className="bmi-page__body__line-graph-text">
            <Text size="micro">Perfusion Index</Text>
            <Text bold>{row.pi}%</Text>
            <Text size="nano" color="lightGrey">
              Percentage
            </Text>
          </div>
          <HealthValueLine
            key={index + row.id}
            value={{
              value: row.pi,
              text: row.risk,
            }}
            ranges={perfusionRange}
          />
        </div>
        )}
        <div style={{ display: 'flex' }}>
          <div className="bmi-page__body__line-graph-text">
            <Text size="micro">Heart Rate</Text>
            <Text bold>{row.pulse} bpm</Text>
            <Text size="nano" color="lightGrey">
              Beats Per Min
            </Text>
          </div>
          <HealthValueLine
            key={index + row.id}
            value={{
              value: row.pulse,
              text: row.risk,
            }}
            ranges={heartRateRange}
          />
        </div>
      </HealthValueLineWrapper>
    </div>
  );
};

export default OxyPage;
