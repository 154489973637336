import {
  SET_USER,
  SET_USER_CLIENTS,
  SET_CLIENT_INFORMATION,
  SET_CLIENT_BRANDING,
  SET_CLIENT_CLINICS,
  SET_DOCUMENT_TYPES,
  SET_DEV_MODE,
  SET_SHOW_SIDE_BAR,
  SET_CUSTOM_URL_CLIENT,
  SET_CLINIC_OPTIONS,
  SET_LAB_CLINICS,
  SET_REFRESH_TOKEN_COUNT,
  SET_TEMP_STATE,
} from './generalState.actions';

const getTemplates = (client) => {
  const options = [
    {
      text: 'COVID-19 Test Result',
      value: {
        name: 'COVID-19 Test Result',
        type: 'PCR_TEST',
        image:
          'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/medicalCourses/pcr-text.jpeg',
      },
    },
    {
      text: 'Medical certificate for service at sea',
      value: {
        name: 'Medical certificate for service at sea',
        type: 'PEME',
        image:
          'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/medicalCourses/peme.png',
      },
    },
    {
      text: 'Fitness to Travel',
      value: {
        name: 'Fitness to Travel',
        type: 'FFT',
        image:
          'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/medicalCourses/peme.png',
      },
    },
  ];

  if (client.id === 'zHAzw9fw2H4cGE8udj50')
    options.push({
      text: 'COVID-19 Vaccine',
      value: {
        name: 'COVID-19 Vaccine',
        type: 'COVID_VAC',
        image:
          'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/medicalCourses/pcr-text.jpeg',
      },
    });

  return options;
};

const initialState = {
  clientInformation: {},
  user: {},
  clientBranding: {},
  userClients: [],
  clientClinics: [],
  templates: [],
  documentTypes: [],
  labClinics: [],
  docTypesObj: {},
  isDevMode: false,
  hasDeltaResults: false,
  showSideBar: true,
  customUrlClient: null,
  refreshCount: 0,
  tempState: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };

    case SET_USER_CLIENTS:
      return { ...state, userClients: action.payload };

    case SET_CLIENT_INFORMATION:
      return {
        ...state,
        clientInformation: action.payload,
        templates: getTemplates(action.payload),
      };

    case SET_CLIENT_BRANDING:
      return { ...state, clientBranding: action.payload };

    case SET_CLIENT_CLINICS:
      return { ...state, clientClinics: action.payload };

    case SET_DOCUMENT_TYPES: {
      const docTypesObj = {};
      action.payload.forEach((doc) => {
        docTypesObj[doc.subType] = doc.name;
      });
      return { ...state, documentTypes: action.payload, docTypesObj };
    }

    case SET_DEV_MODE:
      return { ...state, isDevMode: action.payload };

    case SET_SHOW_SIDE_BAR:
      return { ...state, showSideBar: action.payload };

    case SET_CUSTOM_URL_CLIENT:
      return { ...state, customUrlClient: action.payload };

    case SET_CLINIC_OPTIONS:
      return { ...state, clinicOptions: action.payload };
    
    case SET_LAB_CLINICS:
      return { ...state, labClinics: action.payload };
    
    case SET_REFRESH_TOKEN_COUNT:
      return { ...state, refreshCount: action.payload };
    
      case SET_TEMP_STATE:
      return { ...state, tempState: action.payload };

    default:
      return state;
  }
};
