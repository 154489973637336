import { fhirClient } from '../fhirClient';
import bundleResourceApi from '../bundle/bundleResourceApi';
import patientFhirMapper from '../patient/patientFhirMapper';
import axiosInstance from '../../axiosInstance';

const defaultSearchParams = {
  _count: 15,
};

const defaultSearchOptions = {
  includeEncounter: true,
};

async function getObservations(
  searchParams = defaultSearchParams,
  searchOptions = defaultSearchOptions
) {
  const response = await fhirClient.get('/Observation', {
    params: {
      ...searchParams,
      ...defaultSearchParams,
    },
  });
  const observations = response.data;
  const { includeEncounter } = searchOptions;

  if (includeEncounter) {
    return getObservationWithEncounter(observations);
  }
  return observations;
}

async function getObservationWithEncounter(bundle = { entry: [] }) {
  if (!bundle?.entry.length) {
    return bundle;
  }
  const encounterIds = bundle.entry
    .map((entry) => entry.resource.encounter?.reference)
    .filter(Boolean);

  if (!encounterIds.length) {
    return bundle;
  }

  const encounterResources = await bundleResourceApi.getResources(encounterIds);

  if (!encounterResources?.entry.length) {
    return bundle;
  }

  const patientResourcesMap = encounterResources.entry.reduce((acc, entry) => {
    acc[`Patient/${entry.resource.id}`] = entry.resource;
    return acc;
  }, {});

  const bundleWithPatient = bundle.entry.map((entry) => {
    let patient = patientResourcesMap[entry.resource.subject?.reference];
    if (!patient) {
      patient =
        patientResourcesMap[`Patient/${entry.resource.subject?.reference}`];
    }
    if (patient) {
      entry.resource.patient =
        patientFhirMapper.convertToDisplayedPatient(patient);
    }
    return entry;
  });
  bundle.entry = bundleWithPatient;
  return bundle;
}

async function getObservationById(id = '') {
  const response = await fhirClient.get(`/Observation/${id}`);
  const observation = response.data;
  return observation;
}

async function getObservationByPatientId(id = '') {
  const response = await fhirClient.get(`/Observation?patient=${id}`);
  const observations = response.data;
  return observations;
}

async function getAnomalyResults(ids = []) {
  if (!ids.length) {
    return null;
  }
  try {
    const resultsResponse = await fhirClient.get('/anomaly', {
      params: {
        ids: ids,
      },
    });

    return resultsResponse.data?.reduce((acc, result) => {
      if (result?.observation_id) {
        acc[result.observation_id] = result;
      }
      return acc;
    }, {});
  } catch (error) {
    console.log(error);
    return null;
  }
}

async function getCategoryDescriptions(categoryIds = []) {
  if (!categoryIds.length) {
    return null;
  }
  try {
    const resultsResponse = await axiosInstance.get('result/categories', {
      params: {
        categories: categoryIds,
      },
    });

    return resultsResponse.data.reduce((acc, result) => {
      if (result?.categoryId) {
        acc[result.categoryId] = result;
      }
      return acc;
    }, {});
  } catch (error) {
    console.log(error);
    return {};
  }
}

const observationApi = {
  getObservations,
  getObservationById,
  getObservationByPatientId,
  getAnomalyResults,
  getCategoryDescriptions,
};

export { observationApi };
