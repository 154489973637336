import React, { useCallback, useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';

import {
  Text,
  Switch,
  ErrorToast,
  TextArea,
  Icon,
  Button,
  Select,
  Input,
} from 'components';
import AdditionalReportsTableModal from './AdditionalReports_TableModal';

import patientReactSessionApi from 'api/patientReactSession';
import resultApi from 'api/result/resultApi';
import history from '../../../../../../../history';

import './additionalReports_Table.scss';

const reportTypeOptions = {
  diagnostic: [
    { text: 'CT-scan', value: 'CT-scan' },
    { text: 'ECG', value: 'ECG' },
    { text: 'Imaging', value: 'Imaging' },
    { text: 'MRI', value: 'MRI' },
    { text: 'Ultrasound', value: 'Ultrasound' },
    { text: 'X-Ray', value: 'X-Ray' },
  ],
  pulmonary: [
    {
      text: 'Cardiopulmonary exercise testing (CPET)',
      value: 'Cardiopulmonary exercise testing (CPET)',
    },
    { text: 'Spirometry', value: 'Spirometry' },
  ],
  audio: [
    { text: 'Audio Acuity Test', value: 'Audio Acuity Test' },
    { text: 'Visual Acuity Test', value: 'Visual Acuity Test' },
  ],
};

const AdditionalReportsTable = ({
  reports = [],
  activeList = '',
  pSessionId,
  fetchAdditionalReports,
  onDelete
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [additionalReports, setAdditionalReports] = useState(reports);
  const [loadingReportId, setLoadingReportId] = useState(null);
  const [textAreaFocusId, setTextAreaFocusId] = useState(null);
  const [editedDoctorsNotes, setEditedDotorsNotes] = useState(null);
  const [reportFileType, setReportFileType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalReport, setModalReport] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    setAdditionalReports(reports);
  }, [reports]);

  const updateAdditionalReport = async (id, payload) => {
    try {
      setLoadingReportId(id);
      await resultApi.updateResult(id, payload);
      await fetchAdditionalReports();
      setLoadingReportId(null);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoadingReportId(null);
    }
  };

  const removeReportFromSession = async (reportId) => {
    if (!pSessionId || !reportId) return;
    try {
      const payload = { resultIdsToRemove: [reportId] };
      await patientReactSessionApi.updatePatientReactSession(
        pSessionId,
        payload
      );
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const deleteAdditionalReport = async (id) => {
    try {
      setLoadingReportId(id);
      await resultApi.deleteResult(id);
      setLoadingReportId(null);
      await fetchAdditionalReports();
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setLoadingReportId(null);
    }
  };

  const onToggleAbnormality = async (id, anomalyStatus) => {
    const payload = {};
    if (anomalyStatus === 'normal') {
      payload.anomalyStatus = 'abnormal';
    } else if (anomalyStatus === 'abnormal') {
      payload.anomalyStatus = 'normal';
    } else {
      payload.anomalyStatus = 'abnormal';
    }
    await updateAdditionalReport(id, payload);
    setLoadingReportId(null);
  };

  const onChangeDoctorsNotes = (value) => {
    setEditedDotorsNotes(value);
  };

  const onChangeReportType = async (id, pdf, value) => {
    await updateAdditionalReport(id, {
      pdf: [{ id: pdf._id, type: value }],
    });
  };

  const onBlurTextArea = async (e) => {
    e.stopPropagation();
    await updateAdditionalReport(textAreaFocusId, {
      doctorNote: editedDoctorsNotes,
    });
    setTextAreaFocusId(null);
  };

  const deleteSessionReport = async (bundleId, reportId) => {
    setDeleteLoading(true);
    await removeReportFromSession(bundleId || reportId);
    await deleteAdditionalReport(reportId);
    if(onDelete) await onDelete();
    setDeleteLoading(false);
    setShowModal(false);
  };

  const onFocusTextArea = async (id, doctorsNotes) => {
    setTextAreaFocusId(id);
    if (textAreaFocusId !== id) setEditedDotorsNotes(doctorsNotes);
  };

  const onShowModal = async (report) => {
    setShowModal(true);
    setModalReport(report);
  };

  const renderTableData = () => {
    return additionalReports.map((report, index) => {
      return (
        <>
          <Table.Row key={report.id}>
            <Table.Cell>
              <Link to={report.fhirId ? `/results/fhir/${report.fhirId}` : `/results/${report.id}`} target="_blank" rel="noopener noreferrer">
                <Text color="blue" size="small">{report?.pdf?.at(0)?.name || 'N.A'}</Text>
              </Link> 
            </Table.Cell>
            {activeList !== 'result' && (
              <Table.Cell>
                {report?.type !== 'other' ? (
                  <Select
                    border
                    search
                    size={isMobile ? 'fullWidth' : 'small'}
                    options={reportTypeOptions[report.type]}
                    value={report.pdf?.at(0)?.type}
                    onChange={(e, { value }) =>
                      onChangeReportType(report.id, report.pdf?.at(0), value)
                    }
                    disabled={report.id === loadingReportId}
                  />
                ) : (
                  <Input
                    placeholder="Report Type"
                    border
                    size={isMobile ? 'fullWidth' : 'small'}
                    value={reportFileType || report.pdf?.at(0)?.type || ''}
                    onChange={(e) => setReportFileType(e.target.value)}
                    onBlur={() =>
                      onChangeReportType(
                        report.id,
                        report.pdf?.at(0),
                        reportFileType
                      )
                    }
                    disabled={report.id === loadingReportId}
                  />
                )}
              </Table.Cell>
            )}
            <Table.Cell>
              <Text size="small">
                {moment(report.createdAt).format('DD MMM YYYY, HH:mm ')}
              </Text>
            </Table.Cell>
            <Table.Cell>
              <Switch
                disabled={report.id === loadingReportId}
                onChange={() =>
                  onToggleAbnormality(report.id, report?.anomalyStatus)
                }
                checked={report?.anomalyStatus === 'abnormal'}
                className="add-reps-t__toggle"
              />
            </Table.Cell>
            {activeList !== 'result' && (
              <Table.Cell>
                <Text size="small">
                  <TextArea
                    className={`add-reps-t__text-area ${
                      textAreaFocusId === report.id &&
                      'add-reps-t__text-area--selected'
                    }`}
                    placeholder="Write your notes here..."
                    disabled={report.id === loadingReportId}
                    onChange={(e) => onChangeDoctorsNotes(e.target.value)}
                    onFocus={() =>
                      onFocusTextArea(report.id, report.doctorNote)
                    }
                    onBlur={(e) => onBlurTextArea(e)}
                    showErrorMessage={false}
                    value={
                      textAreaFocusId === report.id
                        ? editedDoctorsNotes
                        : report.doctorNote
                    }
                  />
                </Text>
              </Table.Cell>
            )}
            <Table.Cell>
              <Button
                size="fluid"
                color="none"
                style={{ background: 'none' }}
                onClick={() => onShowModal(report)}
                inverted
              >
                <Icon name="close" color="#FF6767" />
              </Button>
            </Table.Cell>
          </Table.Row>
        </>
      );
    });
  };

  return (
    <div className="add-reps-t">
      {/* modal */}
      {showModal && (
        <AdditionalReportsTableModal
          report={modalReport}
          showModal={showModal}
          setShowModal={setShowModal}
          deleteSessionReport={deleteSessionReport}
          deleteLoading={deleteLoading}
        />
      )}
      {/* end modal */}
      <Table className="add-reps-t" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>File Name</Table.HeaderCell>
            {activeList !== 'result' && (
              <Table.HeaderCell>Report Type</Table.HeaderCell>
            )}
            <Table.HeaderCell>Uploaded / Reported Date</Table.HeaderCell>
            <Table.HeaderCell>Abnormalities</Table.HeaderCell>
            {activeList !== 'result' && (
              <Table.HeaderCell>Doctor’s Notes</Table.HeaderCell>
            )}
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{renderTableData()}</Table.Body>
      </Table>
      {!additionalReports.length && (
          <div className='add-reps-t__no-results'>
            <Text dark>No reports</Text>
          </div>
        )}
    </div>
  );
};

export default withRouter(AdditionalReportsTable);
