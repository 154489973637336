import { AdminRoles } from 'constant';
import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

const initialContext = {
  id: null,
  name: null,
  email: null,
  role: null,
  centerId: null,
  instructorId: null,
};

const UserContext = createContext(initialContext);

export const UserProvider = ({ children }) => {
  const user = useSelector(state => state.general.user);
  return (
    <UserContext.Provider value={{ ...initialContext, ...user }}>
      {children}
    </UserContext.Provider>
  );
};

export const AllowAdminTo = ({
  children,
  yes: Yes = () => <>{children}</>,
  no: No = () => null,
}) => {
  const user = useContext(UserContext);
  if (AdminRoles.has(user.role)) {
    return <Yes />;
  }
  return <No />;
};

export const useUser = () => useContext(UserContext);
