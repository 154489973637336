// https://ned.dev.demo.api.riverr.ai/v1/p-react-session
// import axios from './testAxios';
import axios from './axiosInstance';

const methods = {
  async getPReactSessionByPat(patientId) {
    return axios.get(`p-react-session/patient/${patientId}`);
  },
  async getPatientReactSessionById(id) {
    return axios.get(`p-react-session/${id}`);
  },
  async getPatientReactSessions(id) {
    return axios.get(`p-react-session/session/${id}`);
  },
  async getPatientReactSessionsByEmail(email) {
    return axios.get(`p-react-session/patient-email/${email}`);
  },
  async bulkCreateReactSession(data) {
    return axios.post('p-react-session/bulk', { ...data });
  },
  async bulkReviewReactSession(ids) {
    return axios.get('p-react-session/bulk-reviewed', { params: { ids } });
  },
  async updatePatientReactSession(id, payload) {
    return axios.put(`p-react-session/${id}`, { ...payload });
  },
  async issueReport({ id, isSendEmail }) {
    return axios.post(`p-react-session/issue-report/${id}`, { isSendEmail });
  },
  async completeQuestionnaire(sessionId, data) {
    return axios.post('p-react-session/complete-questionnaire', {
      sessionId,
      data,
    });
  },
  async resendQuestionnaire(sessionIds) {
    return axios.post('p-react-session/resend-questionnaire', { sessionIds });
  },
  async getPatientReactSessionEmailActivity(
    clientId,
    patientId,
    sessionId,
    type = 'patient-react-flow'
  ) {
    return axios.post('p-react-session/email-activity', {
      clientId,
      patientId,
      sessionId,
      type,
    });
  },
  async syncPlatoLabResult(data) {
    return axios.post('p-react-session/plato-pdf-note', data);
  },
  async syncSgimedLabResult(data) {
    return axios.post('p-react-session/sgimed-pdf-note', data);
  },
};

export default methods;
