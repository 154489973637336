import { Text, Button } from 'components';
import { Icon, Dropdown } from 'semantic-ui-react';

const Actions = ({ markAsRead, isPatientApp, isDownloading, toggleModal, togglePdfModals, selectedRows, setShowDeleteResultsModal, setToggleuploadReport, hideUpload }) => {
  
  const onUploadResultClick = () => {
    setToggleuploadReport(true)
  }

return (
  <>
    {!isPatientApp ? (
      <Dropdown
      floating
      direction="right"
      icon={null}
      trigger={
        <Button
        color="blue"
        disabled={isDownloading}
        isLoading={isDownloading}
        >
          <Text id="results_inbox.actions" color="white">
            Actions
          </Text>
          <Icon
            style={{ position: 'absolute', right: '3px' }}
            name="dropdown"
            color="white"
          />
        </Button>
      }>
        <Dropdown.Menu>
            <Dropdown.Item
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => markAsRead(true)}
            >
              <Icon name="envelope open outline" />
              <Text id="results_inbox.mark_as_read" size="small" bold>
                  Mark as read
              </Text>
            </Dropdown.Item>
            <Dropdown.Item
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => markAsRead(false)}
            >
              <Icon name="envelope outline" />
              <Text id="results_inbox.mark_as_unread" size="small" bold>
                  Mark as unread
              </Text>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => toggleModal(true)}
            >
              <Icon name="paper plane outline" />
              <Text id="results_inbox.send_lab_report" size="small" bold>
                  Send lab report(s)
              </Text>
            </Dropdown.Item>
            {!hideUpload && (
            <Dropdown.Item
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={onUploadResultClick}
            >
              <Icon name="upload" />
              <Text
              id="results_inbox.upload_lab_reports"
              size="small"
              bold
              >
                Upload lab report(s)
              </Text>
            </Dropdown.Item>
            )}
            <Dropdown.Item
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => togglePdfModals(true)}
            >
              <Icon name="download" />
              <Text
                  id="results_inbox.download_lab_reports"
                  size="small"
                  bold
              >
                Download lab report(s)
              </Text>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
            disabled={!Boolean(Object.keys(selectedRows)?.length)}
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => setShowDeleteResultsModal(true)}
            >
              <Icon name="close" style={{ color: '#ff5252' }} />
              <Text
                id="results_inbox.delete_lab_reports"
                size="small"
                color="red"
                bold
              >
                Delete lab report(s)
              </Text>
            </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ) : (
      <Button
      onClick={() => setToggleuploadReport(true)}
      color="blue"
      size="fullWidth"
      >
        <Text id="results_inbox.upload_lab_result_pdf" color="inherit">
          Upload lab result PDF
        </Text>
      </Button>
    )}
  </>
)}

export default Actions;