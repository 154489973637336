import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { signOutUser } from '../auth/authentication';

const apolloConfig = {
  envUrls: {
    //  local: 'http://localhost:8080/api/v1/graphql',
    local: process.env.REACT_APP_API_V1_END_POINT + '/graphql',
    development: process.env.REACT_APP_API_V1_END_POINT + '/graphql',
    staging: process.env.REACT_APP_API_V1_END_POINT + '/graphql',
    production: process.env.REACT_APP_API_V1_END_POINT + '/graphql',
  },
};

const httpLink = new HttpLink({
  uri:
    window.location.hostname === 'localhost'
      ? apolloConfig.envUrls.local
      : apolloConfig.envUrls.production,
});

// eslint-disable-next-line no-unused-vars
const authLink = setContext((request, previousContext) => ({
  headers: {
    // TODO: get this once the user has logged in
    clientId: localStorage.getItem('DELTA_CLIENT_ID') || '',
    adminToken: localStorage.getItem('AUTH_TOKEN') || '',
    adminId: localStorage.getItem('USER_SAFE_ID') || '',
    Authorization: JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token
      ? `Bearer ${JSON.parse(localStorage.getItem('AUTH_TOKEN')).token}`
      : null,
  },
}));

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log('networkError', networkError);
  if (networkError && networkError.statusCode === 401) {
    signOutUser();
  } else if (graphQLErrors && graphQLErrors[0].message.includes('Not auth')) {
    signOutUser();
  }

  if (graphQLErrors) {
    // eslint-disable-next-line array-callback-return
    graphQLErrors.map(({ message, locations, path }) => {
      window.console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
  if (networkError) window.console.log(`[Network error]: ${networkError}`);
});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink.concat(httpLink)),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  // enables  query cancellation
  queryDeduplication: false,
});
