import React from 'react';
import cx from 'classnames';
import { Text } from '../index';

import './dataWrapper.scss';

const DataWrapper = ({ label, data, textArea, multi, subText, className }) => {
  const renderMulti = () => {
    if (!data) return '';
    return data.map(thing => (
      <Text className="course-overview-details__data__data__multi" color="grey">
        {thing}
      </Text>
    ));
  };

  return (
    <div
      className={cx('course-overview-details__data', className, {
        'course-overview-details__data__text-area': textArea,
      })}
    >
      <div className="course-overview-details__data__label">
        <Text size="small" color="lightGrey">
          {label}
        </Text>
      </div>
      <div className="course-overview-details__data__data">
        {multi ? (
          renderMulti()
        ) : (
          <Text uppercase={textArea ? false : true}>
            <p>{data}</p>
          </Text>
        )}
      </div>
      {subText && (
        <Text size="tiny" color="lightGrey">
          {subText}
        </Text>
      )}
    </div>
  );
};

export default DataWrapper;
