import { fhirClient } from '../fhirClient';
import bundleResourceRequest from './bundleBodyRequest';

async function getResources(resourceUrls = []) {
  if (!resourceUrls?.length) {
    console.error('getResources: resourceUrls is empty');
    return null;
  }
  const uniqueResourceUrls = [...new Set(resourceUrls.filter(Boolean))];
  const bundleRequestData = bundleResourceRequest.generateBundleGetResources(uniqueResourceUrls);
  const config = {
    method: 'post',
    url: ``,
    headers: {
      'Content-Type': 'application/json',
    },
    data: bundleRequestData,
  };

  try {
    const response = await fhirClient(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const bundleResourceApi = {
  getResources,
};

export default bundleResourceApi;
