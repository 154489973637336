import React, { useCallback, useState } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import { Button } from 'components';

import './qrCodeModal.scss';

const QRCodeModal = ({ show, hide, url, header }) => {
  const [isDownloading, setDownloading] = useState(false);
  const hideModal = useCallback(() => {
    hide(false);
  }, [hide]);

  const downloadQR = async () => {
    setDownloading(true);
    const element = document.getElementById('qrElem'),
      canvas = await html2canvas(element),
      data = canvas.toDataURL('image/jpg'),
      link = document.createElement('a');

    link.href = data;
    link.download = 'referral-QRCode.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloading(false);
  };

  return (
    <>
      <Modal closeIcon open={show} onClose={() => hideModal(false)} size="mini">
        <Modal.Header>{header || 'Referral QR Code'}</Modal.Header>
        <Modal.Content>
          <div id="qrElem" className="qrCode__wrapper">
            <QRCode value={url} />
          </div>
          <Button
            textId="add_patient.download_qr_code"
            type="submit"
            size="fullWidth"
            color="blue"
            className="mt-5"
            onClick={(e) => downloadQR(e)}
            isLoading={isDownloading}
            disabled={isDownloading}
          >
            <Icon name="download" /> Download QR
          </Button>
        </Modal.Content>
      </Modal>
    </>
  );
};
export default QRCodeModal;
