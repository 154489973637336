import React from 'react';
import moment from 'moment-timezone';
import cx from 'classnames';

import './singPDT.scss';

const SerologyCert = props => {
  return (
    <div ref={props.certRef}>
      <div className="sing-pdt">
        {props.certificate.logoImage && (
          <div className="sing-pdt__logo-wrapper">
            <img
              className="sing-pdt__logo-wrapper__logo"
              src={props.certificate.logoImage}
              alt="logo"
            />
          </div>
        )}
        <h1 className="sing-pdt__header">
          MEMO ON SEROLOGY
          {props.certificate.subtype === 'SEROLOGY' && ' (IgM antibody)'}
        </h1>
        <section>
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Name of Person:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.holderName}
            </div>
          </div>
          {props.certificate.ssn && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">NRIC/FIN Number:</div>
              <div className="sing-pdt__row__data">{props.certificate.ssn}</div>
            </div>
          )}
          {props.certificate.passNo && (
            <div className="sing-pdt__row">
              <div className="sing-pdt__row__label">Passport Number:</div>
              <div className="sing-pdt__row__data">
                {props.certificate.passNo}
              </div>
            </div>
          )}
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Nationality:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.nationality}
            </div>
          </div>
          <div className="sing-pdt__row">
            <div className="sing-pdt__row__label">Date of Birth:</div>
            <div className="sing-pdt__row__data">
              {props.certificate.issuedAt < '2021-02-18T09:03:00.000Z'
                ? moment(props.certificate.dateOfBirth).format('DD mmm YYYY')
                : moment
                    .utc(props.certificate.dateOfBirth)
                    .format('DD MMMM YYYY')}
            </div>
          </div>
        </section>

        <section className="sing-pdt__info-text">
          <p>To whom it may concern,</p>
          <p>
            The above-mentioned has undergone Serology
            {props.certificate.subtype === 'SEROLOGY'
              ? ' (IgM antibody) '
              : ' '}
            using a Venous Blood Method on{' '}
            {moment
              .utc(props.certificate.collectionDate)
              .clone()
              .tz('Asia/Singapore')
              .format('DD MMMM YYYY')}{' '}
            at{' '}
            {moment
              .utc(props.certificate.collectionDate)
              .clone()
              .tz('Asia/Singapore')
              .format('HH:mm')}{' '}
            SGT by {props.certificate.issuerName} and has tested{' '}
            <span className="sing-pdt__info-text__result">
              {props.certificate.result}
            </span>
            . This test result was reported by {props.certificate.labName} on{' '}
            {moment
              .utc(props.certificate.issuedAt)
              .clone()
              .tz('Asia/Singapore')
              .format('DD MMMM YYYY')}
            .
          </p>
          <p>
            Travellers should note that they are subject to the country or
            region’s requirements prior to travel.
          </p>
          <p>Thank you.</p>
        </section>
        <div className="sing-pdt__signature-wrapper">
          {props.certificate.physicianSignature && (
            <img
              className="sing-pdt__signature-wrapper__sig"
              src={props.certificate.physicianSignature}
              alt="signature"
            />
          )}
          {props.certificate.stampImage && (
            <img
              className="sing-pdt__stamp"
              src={props.certificate.stampImage}
              alt="stamp"
            />
          )}
        </div>

        <section
          className={cx('sing-pdt__bottom-names', {
            'sing-pdt__bottom-names--mt-3':
              props.certificate.physicianSignature,
          })}
        >
          <p>
            <span style={{ fontWeight: 'bold' }}>Name of Doctor:</span>{' '}
            {props.certificate.physicianName}
            <br />
            <span style={{ fontWeight: 'bold' }}>MCR No.:</span>{' '}
            {props.certificate.physicianLicense}
          </p>
        </section>

        {props.showQrCode && (
          <div className="sing-pdt__qr-code">
            <img
              className="sing-pdt__qr-code__img"
              src={props.certificate.qrCodeImage}
              alt="qrcode"
            />
          </div>
        )}
        {props.certificate.watermarkImage && (
          <img
            className="sing-pdt__watermark"
            src={props.certificate.watermarkImage}
            alt="watermark"
          />
        )}
      </div>
    </div>
  );
};

export default SerologyCert;