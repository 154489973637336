import * as yup from 'yup';
import moment from 'moment';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const schema = yup.object().shape({
  firstName: yup.string().required('required').max(255),
  lastName: yup.string().required('required').max(255),
  gender: yup.string().required('required'),
  dateOfBirth: yup
    .string()
    .test('age should be greater than 18', (val) => {
      const age =
        moment().toDate().getFullYear() -
        moment(val, 'DD-MM-YYYY').toDate().getFullYear();
      return age >= 18;
    })
    .required('required'),
  nationality: yup.string().required('required'),
  ssn: yup.string().required('required').min(7).max(22),
  passNo: yup
    .string()
    .notRequired()
    .min(7)
    .max(22)
    .nullable()
    .transform((value) => (!!value ? value : null)),
  language: yup.string().required('required'),
  phoneNumber: yup.string(),
  email: yup.string().email().required('required'),
  ethnicity: yup.string().required('required'),
  weight: yup.string().required('required'),
  height: yup.string().required('required'),
  smoker: yup.string().required('required'),
});

export const editSchema = yup.object().shape({
  firstName: yup.string().required('required').max(255),
  lastName: yup.string().required('required').max(255),
  gender: yup.string().required('required'),
  dateOfBirth: yup
    .string()
    .test('age should be greater than 18', (val) => {
      const age =
        moment().toDate().getFullYear() -
        moment(val, 'DD-MM-YYYY').toDate().getFullYear();
      return age >= 18;
    })
    .required('required'),
  nationality: yup.string().required('required'),
  ssn: yup.string().required('required').min(7).max(22),
  passNo: yup
    .string()
    .notRequired()
    .min(7)
    .max(22)
    .nullable()
    .transform((value) => (!!value ? value : null)),
  language: yup.string().required('required'),
  phoneNumber: yup.string(),
  email: yup.string().email().required('required'),
  ethnicity: yup.string().required('required'),
});

export const completeProfile1Schema = yup.object().shape({
  ethnicity: yup.string(),
  nationality: yup.string(),
  ssn: yup
    .string()
    .notRequired()
    .min(7)
    .max(22)
    .nullable()
    .transform((value) => (!!value ? value : null)),
  passNo: yup
    .string()
    .notRequired()
    .min(7)
    .max(22)
    .nullable()
    .transform((value) => (!!value ? value : null)),
  language: yup.string(),
});

export const completeAddressSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .test('phone is vaild', 'Invalid phone number', (val) => {
      return val ? isValidPhoneNumber(val) : true;
    }),
  country: yup.string(),
  city: yup.string(),
  address: yup.string(),
  zip: yup.string(),
});
