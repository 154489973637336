import React from 'react';

const SvgCopyIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 42 37" {...props}>
    <g fill="none" fillRule="evenodd" stroke={props.color}>
      <path d="M20 1v6a1 1 0 001 1h6" strokeWidth={3} />
      <path
        d="M5 1.5a.5.5 0 00-.5.5v28a.5.5 0 00.5.5h21a.5.5 0 00.5-.5V7.934a.5.5 0 00-.111-.315l-4.801-5.933a.5.5 0 00-.389-.186H5z"
        strokeWidth={3}
      />
      <path
        d="M14 6.5a.5.5 0 00-.5.5v28a.5.5 0 00.5.5h21a.5.5 0 00.5-.5V12.934a.5.5 0 00-.111-.315l-4.801-5.933a.5.5 0 00-.389-.186H14z"
        strokeWidth={3}
        fill="#FFF"
      />
      <path d="M29 6v7a1 1 0 001 1h7" strokeWidth={3} />
      <path
        d="M18 12h11M8.917 7H17M18 17.167h12.211M8.929 12.167H14M17.875 22.333h11.314M8.875 17.333h5.005M17.875 28h8.305M9.05 23h4.767"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </g>
  </svg>
);

SvgCopyIcon.defaultProps = {
  color: '#1b1b1b',
};

export default SvgCopyIcon;
