import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const schema = yup.object().shape({
  relationship: yup.string().required('required'),
  fullName: yup.string().required('required'),
  title: yup.string().required('required'),
  emphoneNumber: yup
    .string()
    .test('phone is vaild', 'Invalid phone number', (val) => {
      return isValidPhoneNumber(val);
    })
    .required('This field is required'),
  emlanguage: yup.string().required('required'),
});
