import React from 'react';
import { withRouter } from 'react-router-dom';

import { Text, Button } from 'components';

import './formSuccessPage.scss';

const FormSucessPage = () => {
  return (
    <div className="rf-succ-p">
      {/* <Text dark size="bigger" className="mb-3">ReACT Questionnaire</Text> */}
      <div className="rf-succ-p__info">
        <Text dark>
            Thank you for completing the questionnaire!
        </Text>
        <Text className="mt-3" size="small">
            The information provided will help us better assess any potential health risks
        </Text>

        <Button className="mt-4" color="blue" onClick={() => {window.open('','_self').close()}}>
            Close this window
        </Button>
      </div>
    </div>
  );
};

export default withRouter(FormSucessPage);
