import React from 'react';

import { Text, Input } from 'components';

const HealthDetail = ({
  title,
  field,
  value,
  unit,
  isEdit,
  onChange,
  hasValue,
  errors,
}) => {
  return (
    <div className="patient-details__vitals__section">
      <Text bold size="tiny" className="mb-3">
        {title}
      </Text>
      <div className="patient-details__vitals__section__value">
        {!isEdit ? (
          <Text
            bold
            size="bigger"
            className={!value ? 'mr-2' : 'mr-1'}
            center
            color={!value && 'lightGrey'}
            style={{
              minWidth: '25px',
            }}
          >
            {value || '--'}
          </Text>
        ) : (
          <div>
            <Input
              className="patient-details__vitals__section__input mr-1"
              border
              size="tiny"
              onChange={(event) =>
                onChange({ field, value: event.target?.value })
              }
              invalid={errors[field]?.message}
              errorMessage={
                <Text size="micro" color="red">
                  {errors[field]?.type || ''}
                </Text>
              }
            />
            {value && (
              <Text size="tinniest" color="lightGrey" className="mt-1">
                Prev: {value}
                {unit}
              </Text>
            )}
          </div>
        )}
        <Text size="tiny" color="lightGrey">
          {unit}
        </Text>
      </div>
    </div>
  );
};

export default HealthDetail;
