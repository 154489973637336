import gql from 'graphql-tag';

export const createFacility = gql`
  mutation facilitySet(
    $clientId: ID!
    $name: String!
    $email: String
    $phoneNumber: String
    $address: String!
    $city: String!
    $zip: String!
    $geoLocation: String!
    $country: String!
    $isDefault: Boolean!
  ) {
    facilitySet(
      clientId: $clientId
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      address: $address
      city: $city
      country: $country
      zip: $zip
      geoLocation: $geoLocation
      isDefault: $isDefault
    ) {
      success
      message
    }
  }
`;
export const updateFacility = gql`
  mutation facilityUpdate(
    $id: ID!
    $clientId: ID!
    $name: String!
    $email: String
    $phoneNumber: String
    $address: String!
    $city: String!
    $zip: String!
    $geoLocation: String!
    $country: String!
    $isDefault: Boolean!
  ) {
    facilityUpdate(
      id: $id
      clientId: $clientId
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      address: $address
      city: $city
      country: $country
      zip: $zip
      geoLocation: $geoLocation
      isDefault: $isDefault
    ) {
      success
      message
    }
  }
`;

export const deleteFacility = gql`
  mutation deleteFacility($id: ID!) {
    deleteFacility(id: $id)
  }
`;
