// import React from 'react';

// function Calendar(props) {
//   return (
//     <svg
//       width={props.width}
//       height={props.height}
//       viewBox="0 0 30 30"
//       fill="none"
//       {...props}
//     >
//       <path
//         d="M25 3.75h-1.25V2.5c0-.688-.563-1.25-1.25-1.25-.688 0-1.25.563-1.25 1.25v1.25H8.75V2.5c0-.688-.563-1.25-1.25-1.25-.688 0-1.25.563-1.25 1.25v1.25H5a2.507 2.507 0 00-2.5 2.5v20c0 1.375 1.125 2.5 2.5 2.5h20c1.375 0 2.5-1.125 2.5-2.5v-20c0-1.375-1.125-2.5-2.5-2.5zm-1.25 22.5H6.25C5.562 26.25 5 25.687 5 25V10h20v15c0 .688-.563 1.25-1.25 1.25z"
//         fill="#1b1b1b"
//       />
//     </svg>
//   );
// }

// export default Calendar;

import * as React from 'react';

function Calendar(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.608 10.972h20.795M19.183 15.528h.01M14.005 15.528h.011M8.818 15.528h.01M19.183 20.062h.01M14.005 20.062h.011M8.818 20.062h.01M18.718 2.333v3.84M9.293 2.333v3.84"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M18.945 4.176H9.066C5.64 4.176 3.5 6.084 3.5 9.592V20.15c0 3.564 2.14 5.517 5.566 5.517h9.868c3.437 0 5.566-1.92 5.566-5.428V9.592c.01-3.508-2.118-5.416-5.555-5.416z"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Calendar.defaultProps = {
  width: 27,
  height: 27,
};

export default Calendar;
