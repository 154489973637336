import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Icon, Text } from 'components';
import 'chartjs-adapter-moment';

import './resultLineGraph.scss';

const ResultLineGraph = ({
  graphPoints,
  unit,
  latest,
  average,
  range,
  getValueRangeItem,
  canvasPadding,
  smallTexts,
  observation,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!graphPoints) return;
    const lastRange = range?.[range.length - 1];

    const sortedGraphPoints = [...graphPoints].sort(
      (p1, p2) => Number(p1?.value) - Number(p2?.value)
    );
    const yAxisMax = Math.ceil(
      Number(sortedGraphPoints?.at(-1)?.value) +
        Number(sortedGraphPoints?.at(-1)?.value) * 0.2
    );

    let lastValue = lastRange?.range?.[1];
    if (Number(lastValue) === 99999) {
      lastValue = Number(lastRange?.range?.[0]) + Number(lastRange?.range?.[0]);
    }

    let labels = [
      ...graphPoints?.map((p) => moment(p?.date).format('DD MMM YY')),
    ];

    let chartData = {
      labels,
      datasets: [
        {
          label: 'Sample Data',
          data: graphPoints?.map((p) => Number(p?.value).toFixed(2)),
          backgroundColor: 'rgba(75,192,192,0)',
          borderColor: 'black',
          borderWidth: 1,
          lineTension: 0,
          pointRadius: 4.5,
          pointHoverRadius: 4.5,
          pointBorderWidth: 2,
          pointBackgroundColor: graphPoints.map((p) =>
            getValueRangeItem(Number(p?.value).toFixed(2), 'colorCode')
          ),
          pointBorderColor: graphPoints.map((p) =>
            getValueRangeItem(Number(p?.value).toFixed(2), 'colorCode')
          ),
          datalabels: {
            labels: {
              value: {
                align: 'end',
                anchor: 'end',
                color: 'black',
                font: {
                  weight: '500',
                  size: 12,
                },
              },
            },
          },
        },
        {
          type: 'line',
          label: 'Y axis status dataset',
          data: [...range.map((r) => Number(r?.range?.[0])), yAxisMax].sort(
            (a, b) => Number(a) - Number(b)
          ),
          datalabels: {
            labels: {
              display: false,
            },
          },
          backgroundColor: 'red',
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 10,
          borderColor: 'gray',
          segment: {
            borderColor: (ctx) => {
              // console.log(
              //   [
              //     ...range.map((r) => Number(r.range[0])).sort(),
              //     Number(sortedGraphPoints.at(-1).value),
              //   ],
              //   ctx,
              //   ctx.p0.parsed.y,
              //   ctx.p0.parsed.y,
              //   getValueRangeItem(Number(ctx.p0.parsed.y).toFixed(2), 'colorCode')
              // );
              return getValueRangeItem(
                Number(ctx.p0.parsed.y).toFixed(2),
                'colorCode'
              );
            },
          },
          yAxisID: 'y',
          xAxisID: 'x2',
        },
        // {
        //   type: 'bar',
        //   label: 'Bar Dataset',
        //   data: [5],
        //   backgroundColor: 'yellow',
        //   yAxisID: 'y2',
        //   xAxisID: 'x2',
        // },
        // {
        //   type: 'bar',
        //   label: 'Bar Dataset',
        //   data: [7.8],
        //   backgroundColor: 'green',
        //   yAxisID: 'y2',
        //   xAxisID: 'x2',
        // },
      ],
    };
    const ctx = chartRef.current.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'line',
      data: chartData,
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          tooltip: { enabled: false },
          legend: {
            display: false,
          },
        },
        aspectRatio: 5 / 3,
        layout: {
          padding: {
            top: canvasPadding?.top || 0,
            right: canvasPadding?.right || 0,
            bottom: canvasPadding?.bottom || 0,
            left: canvasPadding?.left || 0,
          },
        },
        scales: {
          x: {
            display: true,
            offset: true,
            grid: {
              drawTicks: true,
              drawOnChartArea: false,
            },
          },
          y: {
            beginAtZero: true,
            offset: true,
            max: yAxisMax,
            min: 0,
            grid: {
              borderDash: [2, 2],
              borderWidth: 0,
            },
            ticks: {
              display: true,
              autoSkip: false,
            },
          },
          x2: {
            display: false,
            type: 'linear',
            labels: Array(range.length + 1).fill(0),
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
          // y2: {
          //   display: false,
          //   offset: true,
          //   position: 'left',
          //   suggestedMax: lastValue,
          //   min: 0,
          //   grid: {
          //     display: false,
          //   },
          //   ticks: {
          //     autoSkip: false,
          //   },
          // },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [graphPoints]);

  return (
    <div className="result-line-graph">
      <div className="result-line-graph__info">
        <div className="result-line-graph__info__header">
          <Text
            dark
            size={smallTexts ? 'tiny' : 'normal'}
            style={{ textWrap: 'nowrap', marginBottom: '25px' }}
          >
            {observation?.display || ''}
          </Text>
          <div className="result-line-graph__info__header__trend">
          <Text
            bold
            size={smallTexts ? 'tiny' : 'normal'}
            style={{ textWrap: 'nowrap' }}
          >
            Your Trend
          </Text>
          <div className="result-line-graph__info__header__icon">
            <Icon name="trend" width={smallTexts ? '40' : undefined} />
          </div>
          </div>
        </div>
        <div className="result-line-graph__info__data">
          <div className="result-line-graph__info__data__title">
            <Text size={smallTexts ? 'tinniest' : 'tiny'}>
              Latest,{' '}
              {moment(graphPoints[graphPoints.length - 1].date).format(
                'DD MMM YY'
              )}
            </Text>
          </div>
          <div className="result-line-graph__info__data__item">
            <div className="result-line-graph__info__data__item__icon-wrapper">
              <img
                className="result-line-graph__info__data__item__icon"
                src={getValueRangeItem(latest, 'icon')}
                alt="icon"
              />
            </div>
            <div className="result-line-graph__info__data__item__score">
              <Text
                center
                dark
                bold
                size={smallTexts ? 'tiny' : 'medium'}
                color={getValueRangeItem(latest, 'colorCode')}
              >
                {Number(latest)?.toFixed(2)}
              </Text>
              <Text
                className="ml-1"
                center
                bold
                size={smallTexts ? 'tinniest' : 'tinniest'}
              >
                {unit}
              </Text>
            </div>
          </div>
        </div>
        <div className="result-line-graph__info__data">
          <div className="result-line-graph__info__data__title">
            <Text size={smallTexts ? 'tinniest' : 'tiny'}>Average</Text>
          </div>
          <div className="result-line-graph__info__data__item">
            <div className="result-line-graph__info__data__item__icon-wrapper">
              <img
                className="result-line-graph__info__data__item__icon"
                src={getValueRangeItem(average, 'icon')}
                alt="icon"
              />
            </div>
            <div className="result-line-graph__info__data__item__score">
              <Text
                center
                dark
                bold
                size={smallTexts ? 'tiny' : 'medium'}
                color={getValueRangeItem(average, 'colorCode')}
              >
                {Number(average).toFixed(2)}
              </Text>
              <Text
                className="ml-1"
                center
                size={smallTexts ? 'tinniest' : 'tinniest'}
                bold
              >
                {unit}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="result-line-graph__canvas-wrapper">
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default ResultLineGraph;
