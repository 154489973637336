import moment from 'moment';

import { Text } from 'components';

import EmergencyIdSVG from 'assets/rhc/emergencyContact/emergency-id.svg';

import './patientEmergencyCard.scss';

const PatientEmergencyCard = ({ userDetails = {} }) => {

return (
  <div className="pat-emergency-card">
    <img className="pat-emergency-card__background" src={EmergencyIdSVG} />
    <div className="pat-emergency-card__details">
      <Text size="tiny" bold color="black">
        {userDetails.id}
      </Text>
      <Text
        className="pat-emergency-card__details__name"
        size="bigish"
        bold
        color="black"
      >
        {userDetails?.metadata?.name}
      </Text>
      <div className="pat-emergency-card__details__row">
        <Text light size="tiny" color="black">
          {moment(userDetails?.metadata?.dateOfBirth, 'MMMM DD, YYYY').format(
            'MMMM DD, YYYY'
          )}
        </Text>
        <Text bold size="tiny" color="black" className="ml-2">
          {moment().diff(userDetails?.metadata?.dateOfBirth, 'years', false)}{' '}
          years old
        </Text>
      </div>
      <div className="pat-emergency-card__details__row">
        <Text light size="tiny" color="black" id="patient_app.card_blood_type">
          Blood Type
        </Text>
        <Text bold size="tiny" color="black" className="ml-2">
          {userDetails?.bloodType || ''}
        </Text>
      </div>
      <div className="pat-emergency-card__details__row">
        <Text light size="tiny" color="black" id="patient_app.pref_language">
          Pref. Language
        </Text>
        <Text bold size="tiny" color="black" className="ml-2">
          {userDetails?.metadata?.language || ''}
        </Text>
      </div>
    </div>
  </div>
);}

export default PatientEmergencyCard;