import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';

import conditionApi from 'api/patientOverview/conditionApi';

import { Select } from 'components';

const ConditionInput = ({
  onChange = () => {},
  value = null,
  invalid,
  label = 'Condition',
  optional = false,
  multiple = false,
  placeholder = 'Search for your condition',
  labelTextId = '',
}) => {
  const [conditionOptions, setConditionOptions] = useState([]);

  useEffect(() => {
    fetchConditions({});
  }, []);

  const handleSearchChange = async (e, { searchQuery }) => {
    handleSearch(searchQuery);
  };

  const handleSearch = useCallback(
    debounce((search) => fetchConditions({ search }), 500),
    []
  );

  const fetchConditions = async ({ search = '' }) => {
    const allConditions = await conditionApi.getAll({ limit: 50, search });
    const meds = allConditions.map((condition) => {
      return {
        key: condition.name,
        text: condition.name,
        value: condition.name,
      };
    });

    setConditionOptions(meds);
  };

  return (
    <Select
      size="fullWidth"
      search
      optional={optional}
      label={label}
      labelTextId={labelTextId}
      multiple={multiple}
      placeholder={placeholder}
      onSearchChange={handleSearchChange}
      border
      value={value}
      onChange={(e, { value }) => {
        onChange(value);
      }}
      options={conditionOptions}
      invalid={invalid}
      showErrorMessage={false}
    />
  );
};

export default ConditionInput;
