import React from 'react';

const SvgMessage = props => (
  <svg width="1em" height="1em" viewBox="0 0 32 21" {...props}>
    <g stroke="#1b1b1b" strokeWidth={3} fill="none" fillRule="evenodd">
      <rect x={1.5} y={1.5} width={29} height={18} rx={3} />
      <path d="M1 2l14.006 10.14a2 2 0 002.365-.015L31 2" />
    </g>
  </svg>
);

export default SvgMessage;
