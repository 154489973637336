import { createAxiosInstance } from './axiosInstance';

import { PDF_EXTRACTOR_END_POINT } from '../constants';
import store from '../store';

const axiosInstance = createAxiosInstance(PDF_EXTRACTOR_END_POINT);


const requestHandler = (request) => {
  if (
    localStorage.getItem('AUTH_TOKEN') &&
    JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token
  ) {
    request.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token
    }`;
  }
  const clientId = store?.getState()?.general?.user?.currentClientId;
  request.headers['x-client-id'] = clientId || 'zHAzw9fw2H4cGE8udj50';
  return request;
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);


export default axiosInstance;

