import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import resultApi from '../../../../api/result/resultApi';
import './ObservationTableHl7.css';

const getFlagColor = (flag) => {
  const flagColors = {
    '<': '#FFE5E5', // Light Red
    '>': '#FFE5E5', // Light Red
    'A': '#FFEBCC', // Light Orange
    'AA': '#FFD6D6', // Darker Light Red
    'B': '#E5F2FF', // Light Blue
    'D': '#FFE5E5', // Light Red
    'H': '#FFF4F4', // Updated color for H
    'HH': '#FFD6D6', // Darker Light Red
    'I': '#E5FFE5', // Light Green
    'L': '#FFEBCC', // Light Orange
    'LL': '#FFD6D6', // Darker Light Red
    'MS': '#E5F2FF', // Light Blue
    'N': '#fff', // Default color
    'null': '#f6f7f8', // Default color
    'R': '#FFE5FF', // Light Purple
    'S': '#E5FFE5', // Light Green
    'U': '#FFE5E5', // Light Red
    'VS': '#CCFFCC', // Darker Light Green
    'W': '#FFE5FF', // Light Purple
  };

  return flagColors[flag] || '#f6f7f8'; // Default to #f6f7f8 if flag not found
};

const formatReferenceRange = (range) => {
  return range.split('\\.br\\').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < range.split('\\.br\\').length - 1 && <br />}
    </React.Fragment>
  ));
};

const flagMeanings = {
  '<': 'Below absolute low-off instrument scale',
  '>': 'Above absolute high-off instrument scale',
  'A': 'Abnormal',
  'AA': 'Very abnormal',
  'B': 'Better',
  'D': 'Significant change down',
  'H': 'Above high normal',
  'HH': 'Above upper panic limits',
  'I': 'Intermediate',
  'L': 'Below low normal',
  'LL': 'Below lower panic limits',
  'MS': 'Moderately sensitive',
  'N': 'Normal',
  'null': 'No range defined, or normal ranges don\'t apply',
  'R': 'Resistant',
  'S': 'Sensitive',
  'U': 'Significant change up',
  'VS': 'Very sensitive',
  'W': 'Worse (use when direction not relevant)'
};

const ObservationTableHl7 = ({ orderId }) => {
  const [observationResults, setObservationResults] = useState([]);
  const [summary, setSummary] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);

  useEffect(() => {
    const fetchObservations = async () => {
      const response = await resultApi.getHL7ObservationsByOrderId(orderId);
      const summaryArray = Object.entries(response.abnormalFlags).map(([key, value]) => ({
        flag: key,
        count: value,
        meaning: flagMeanings[key] || 'Unknown'
      }));
      setSummary(summaryArray);
      setObservationResults(response.observationRequests);
    };
    fetchObservations();
  }, [orderId]);

  const toggleFilter = (event, flag) => {
    event.preventDefault(); // Prevent default behavior
    setActiveFilter(prevFilter => prevFilter === flag ? null : flag);
  };

  const filteredResults = observationResults.map(category => ({
    ...category,
    observationResults: category.observationResults.filter(result => 
      !activeFilter || result.flag === activeFilter
    )
  })).filter(category => category.observationResults.length > 0);

  const getCategorySummary = (category) => {
    const flagCounts = category.observationResults.reduce((acc, result) => {
      acc[result.flag] = (acc[result.flag] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(flagCounts)
      .filter(([flag]) => flag !== 'null' && flag !== '')
      .map(([flag, count]) => ({ flag, count, meaning: flagMeanings[flag] || 'Unknown' }));
  };

  const totalCount = summary.reduce((acc, { count }) => acc + count, 0);

  return (
    <>
      <div className='biomarker-overview__summary'>
        <h3>Overall Summary:</h3>
        <div className="summary-grid">
          <div 
            className={`summary-item summary-total ${activeFilter === null ? 'active' : ''}`}
            onClick={(e) => toggleFilter(e, null)}
          >
            <span className="summary-count">{totalCount}</span>
            <span className="summary-meaning">Biomarkers</span>
          </div>
          {summary.map(({ flag, count, meaning }) => (
            <div 
              key={flag} 
              className={`summary-item ${activeFilter === flag ? 'active' : ''}`}
              onClick={(e) => toggleFilter(e, flag)}
            >
              <span className="summary-flag" style={{ backgroundColor: getFlagColor(flag) }}>{flag}</span>
              <span className="summary-count">{count}</span>
              <span className="summary-meaning">{meaning}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="table-container">
        <Table celled compact className="fixed-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="col-test-code">Test Code</Table.HeaderCell>
              <Table.HeaderCell className="col-test">Test</Table.HeaderCell>
              <Table.HeaderCell className="col-result">Result</Table.HeaderCell>
              <Table.HeaderCell className="col-units">Units</Table.HeaderCell>
              <Table.HeaderCell className="col-reference">Reference Range</Table.HeaderCell>
              <Table.HeaderCell className="col-flag">Flag</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filteredResults.map((category, catIndex) => (
              <React.Fragment key={`category-${catIndex}`}>
                <Table.Row>
                  <Table.Cell colSpan="6" className="category-row">
                    <div className="category-header">
                      <h3>{category.category}</h3>
                      <div className="category-summary summary-grid">
                        {getCategorySummary(category).map(({ flag, count, meaning }) => (
                          <div 
                            key={flag} 
                            className="summary-item"
                          >
                            <span className="summary-flag" style={{ backgroundColor: getFlagColor(flag) }}>{flag}</span>
                            <span className="summary-count">{count}</span>
                            <span className="summary-meaning">{meaning}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>
                {category.observationResults.map((result, index) => (
                  <Table.Row key={`${category.setId}-${index}`}>
                    <Table.Cell style={{ backgroundColor: getFlagColor(result.flag) }}>{result.testCode}</Table.Cell>
                    <Table.Cell style={{ backgroundColor: getFlagColor(result.flag) }}>{result.test}</Table.Cell>
                    <Table.Cell style={{ backgroundColor: getFlagColor(result.flag) }}>{result.result}</Table.Cell>
                    <Table.Cell style={{ backgroundColor: getFlagColor(result.flag) }}>{result.unit}</Table.Cell>
                    <Table.Cell style={{ backgroundColor: getFlagColor(result.flag) }}>{formatReferenceRange(result.referenceRange)}</Table.Cell>
                    <Table.Cell style={{ backgroundColor: getFlagColor(result.flag) }}>
                      {result.flag}
                      {result.flag && <div className="flag-meaning">{flagMeanings[result.flag] || 'Unknown'}</div>}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
      </div>
    </>
  );
};

export default ObservationTableHl7;
