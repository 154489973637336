import axiosInstance from '../pdfnetAxiosInstance';

async function genLabReportPdf(reportId, clientId) {
  try {
    const res = await axiosInstance.post('/lab-report/create', {
      reportId,
      clientId,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getPdfDownloadUrl(reportId) {
  try {
    const res = await axiosInstance.post('/lab-report/get-pdf-download-url', {
      reportId,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function generateReactReport(reportId, clientId, isSendEmail) {
  try {
    const res = await axiosInstance.post('/react-report/create', {
      reportId,
      clientId,
      isSendEmail
    });
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getSmartPdfDownloadUrl(reportId) {
  try {
    const res = await axiosInstance.post('/react-report/get-pdf-download-url', {
      reportId,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getSignedUrl(reportId) {
  try {
    const res = await axiosInstance.post('/react-report/get-signed-url', {
      reportId,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const pdfnetApi = {
  generateReactReport,
  genLabReportPdf,
  getPdfDownloadUrl,
  getSmartPdfDownloadUrl,
  getSignedUrl,
};

export default pdfnetApi;
