function sortByDateFunction(a, b) {
  const aDate = a?.date || a?.createdAt;
  const bDate = b?.date || b?.createdAt;

  if (aDate === null || aDate === undefined) return 1;
  if (bDate === null || bDate === undefined) return -1;
  return new Date(bDate).getTime() - new Date(aDate).getTime();
}

export {
  sortByDateFunction,
}
